// @flow
import React from 'react';
import {Modal} from 'react-bootstrap';
import Button, {COLORS} from 'common/componentsV2/Button';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import InfoSection from '../../dataManager/viewStreamSummaryModalV2/infoTab/InfoSection';
import InfoToCsv from './InfoToCsv';
import './StreamItButton.module.scss';

type PropTypes = {
  isOpen: boolean,
  className: String,
  dataStreamId: String,
  dataStreamName: String,
  onClose: Function,
  onStreamData: Function,
  onShouldRewind: Function,
  shouldRewindState: boolean,
};

const shouldRewindMenuOptions = [
  {
    value: false,
    text: 'Collect data from now on',
    warning: '',
  },
  {
    value: true,
    text: 'Collect historical data',
    warning:
      'Recollecting the data means all existing metrics will be deleted from this stream, and new metrics will' +
      ' be created based on the new data.',
  },
];

class SelectStreamItOrCreateAlertsFlowModal extends React.PureComponent {
  props: PropTypes;

  state = {};

  render() {
    let selectedIndex = shouldRewindMenuOptions.findIndex((val) => val.value === this.props.shouldRewindState);
    selectedIndex = selectedIndex === -1 ? 0 : selectedIndex;

    return (
      <Modal
        show={this.props.isOpen}
        dialogClassName={this.props.className ? `bc ${this.props.className}` : 'bc'}
        onHide={this.props.onClose}
      >
        <Modal.Header bsClass="bc-modal-header">
          <Modal.Title>Update stream with data?</Modal.Title>
          <button type="button" className="btn btn-flat btn-icon-36 btn-secondary" onClick={this.props.onClose}>
            <i className="icon icn-icon-table-delete" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-message">
            <div styleName="bottom-spacer">
              By default, Anodot will start collecting data according to your changes from this point on.
              <br />
              You can choose to collect historical data as well:
              <br />
            </div>
            <div styleName="select-live-wrapper">
              <SelectAndt
                automationId="shoulRewindStateMenu"
                extraClassName="alerts-dropdown-btn"
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_NOT_HIGHLIGHTED}
                options={shouldRewindMenuOptions}
                optionHeight={40}
                getOptionLabel={(val) => val.text}
                getOptionValue={(val) => val.value}
                value={shouldRewindMenuOptions[selectedIndex]}
                onChange={(item) => this.props.onShouldRewind(item.value)}
              />
              <div styleName="error-msg">{shouldRewindMenuOptions[selectedIndex].warning}</div>
            </div>
            <div styleName="bold bottom-spacer">Affected Areas</div>
            <div>
              Updating this data stream will also affect the following components:
              <br />
              <br />
            </div>
            <div>
              <InfoSection dataStreamId={this.props.dataStreamId} />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <span styleName="btn-left">
            <Button colorSchema={COLORS.GRAY_300} text="Cancel" onClick={this.props.onClose} />
          </span>

          <span styleName="btn-center">
            <InfoToCsv dataStreamId={this.props.dataStreamId} dataStreamName={this.props.dataStreamName} />
          </span>

          <span styleName="btn-right">
            <Button colorSchema={COLORS.BLUE_500} text="Stream" onClick={this.props.onStreamData} />
          </span>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SelectStreamItOrCreateAlertsFlowModal;
