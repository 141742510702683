import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const anoToken = makeAsyncReducer(actions.fetchMParticleAnoToken, {defaultData: '', errorHandler: bcErrorHandler});
const eventTypes = makeAsyncReducer(actions.fetchMParticleEventTypes, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});
const eventMetadata = makeAsyncReducer(actions.fetchMParticleEventMetadata, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

export default combineReducers({
  anoToken,
  eventTypes,
  eventMetadata,
});
