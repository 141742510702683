// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setSqlStreamClearAllDiametrics as setSqlStreamClearAllDiametricsAction,
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
  setSqlStreamDiametricsChange as setSqlStreamDiametricsChangeAction,
  removeSqlStreamDiametrics as removeSqlStreamDiametricsAction,
  setSelectedStreamKeyVal as setSelectedStreamKeyValAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';
import SqlTablePreview from '../sqlData/SqlTablePreview';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  timeDefinitionArr: Array,
  timestampType: String,
  filterTextInput: String,
  setSqlStreamClearAllDiametrics: Function,
  setStreamDiametricsEditorFilterTextInput: Function,
  setSqlStreamDiametricsChange: Function,
  removeSqlStreamDiametrics: Function,
  setSelectedStreamKeyVal: Function,
  isStreamUpdating: Boolean,
  sqlStreamType: String,
};

class SqlDimensionsAndMetricsEditor extends React.PureComponent {
  props: PropTypes;

  render() {
    const {
      metrics,
      dimensions,
      filterTextInput,
      filteredUnassignedColumns,
      timestampType,
      timeDefinitionArr,
      removeSqlStreamDiametrics,
      setStreamDiametricsEditorFilterTextInput,
      setSqlStreamDiametricsChange,
      setSqlStreamClearAllDiametrics,
      isStreamUpdating,
      setSelectedStreamKeyVal,
      sqlStreamType,
    } = this.props;

    return (
      <DimensionsAndMetricsEditor
        dimensions={dimensions}
        metrics={metrics}
        filteredUnassignedColumns={filteredUnassignedColumns}
        timeDefinitionArr={timeDefinitionArr}
        timestampType={timestampType}
        onTimestampTypeChange={setSelectedStreamKeyVal}
        filterTextInput={filterTextInput}
        isStreamUpdating={isStreamUpdating}
        setStreamClearAllDiametrics={setSqlStreamClearAllDiametrics}
        setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
        setStreamDiametricsChange={setSqlStreamDiametricsChange}
        removeStreamDiametrics={removeSqlStreamDiametrics}
        preview={sqlStreamType === 'table' ? <SqlTablePreview /> : null}
      />
    );
  }
}

export default connect(
  (state) => ({
    dimensions: selectors.getSqlStreamSelectedDimensions(state),
    metrics: selectors.getSqlStreamSelectedMetrics(state),
    timeDefinitionArr: selectors.getSqlStreamSelectedTimestampColumnArr(state),
    timestampType: selectors.getSqlStreamSelectedTimestampType(state),
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    filteredUnassignedColumns: selectors.getFilteredSqlStreamUnAssignedColumns(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
    sqlStreamType: selectors.getStreamType(state),
  }),
  {
    setSqlStreamClearAllDiametrics: setSqlStreamClearAllDiametricsAction,
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
    setSqlStreamDiametricsChange: setSqlStreamDiametricsChangeAction,
    removeSqlStreamDiametrics: removeSqlStreamDiametricsAction,
    setSelectedStreamKeyVal: setSelectedStreamKeyValAction,
  },
)(SqlDimensionsAndMetricsEditor);
