import {getUniqueId} from '../guid';

const getIsEmptyFunction = (tree) =>
  tree.root && tree.root.function === '' && tree.root.children.length === 1 && tree.root.children[0].type === 'metric';

// eslint-disable-next-line no-unused-vars
// const getIsNoFunction = (tree) => tree.root && (tree.root.type === 'metric' || getIsEmptyFunction(tree));

const getCompositeObject = (previewOptions, tree, isShowComposites, scalarTransforms) => ({
  name: {
    auto: true,
    prefix: null,
  },
  displayOnly: true,
  filter: {
    function: previewOptions.sort,
    parameters: [
      {
        name: 'Top N',
        value: previewOptions.show,
      },
    ],
    children: [],
    id: getUniqueId(),
    type: 'function',
  },
  expressionTree: getIsEmptyFunction(tree) ? {root: tree.root.children[0]} : tree,
  scalarTransforms: scalarTransforms || null,
  context: 'tobedone', // TODO: add logic here
  excludeComposites: !isShowComposites,
});

export default getCompositeObject;
