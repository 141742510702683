// @flow
import React from 'react';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import Button, {COLORS} from 'common/componentsV2/Button';
import './StreamsLimitModal.module.scss';

type PropTypes = {
  scenario: String,
  onClose: Function,
  isOpen: Boolean,
};

export default class StreamsLimitModal extends React.PureComponent {
  props: PropTypes;

  content = '';

  render() {
    if (this.props.scenario === 'incomplete') {
      this.content =
        'You cannot create a new data stream because you have reached the limit of incomplete data' +
        ' streams in your account. To create a new data stream, delete other incomplete streams first.';
    } else {
      this.content =
        'You cannot create a new data stream because you have reached the limit of active data streams' +
        ' in your account. To create a new data stream, delete unneeded streams or contact Anodot to extend your' +
        ' account’s stream limit.';
    }

    return (
      <Modal isOpen={this.props.isOpen} onClose={this.props.onClose} size={SIZES.SMALL} isCloseButtonHidden>
        <div styleName="modal-content">
          <h5>Cannot Create Data Stream</h5>
          <p>{this.content}</p>
          <Button colorSchema={COLORS.BLUE_500} text="Got It" onClick={this.props.onClose} />
        </div>
      </Modal>
    );
  }
}
