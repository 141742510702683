import React, {PureComponent} from 'react';
import {components} from 'react-select2';

type PropTypes = {
  // accepted values by the original Select
  children: any,
};

export default class Placeholder extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <components.Placeholder className="andt-dropdown-placeholder" {...this.props}>
        {this.props.children}
      </components.Placeholder>
    );
  }
}
