import {combineReducers} from 'redux';
import * as actions from 'metrics/store/actions';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import resolutions from './resolutionReducer';
import validationMeta from './validationMetaReducer';
import {fetchMetricsTotal} from './fetchMetricsTotal';
import {composeReducers} from '../../../common/utils/reducers';

const root = combineReducers({
  fetchMetricsTotal,
  resolutions,
  validationMeta,
  customerMetricsCount: makeAsyncReducer(actions.fetchCustomerMetricsCount, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  fetchOriginTitleCache: makeAsyncReducer(actions.fetchOriginTitleCache, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  fetchPropAndVal: makeAsyncReducer(actions.fetchPropAndVal, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  fetchPropAndValListApi: makeAsyncReducer(actions.fetchPropAndValListApi, {
    shouldDestroyData: true,
    includeUpdateAt: true,
  }),
  fetchPropsApi: makeAsyncReducer(actions.fetchPropsApi, {
    shouldDestroyData: true,
    includeUpdateAt: true,
  }),
  execute: makeAsyncReducer(actions.execute, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  validate: makeAsyncReducer(actions.validate, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  fetchFunctionsMetaData: makeAsyncReducer(actions.fetchFunctionsMetaDataApi, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  fetchComposites: makeAsyncReducer(actions.fetchComposites, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  fetchLastExpressionSearches: makeAsyncReducer(actions.fetchLastExpressionSearches, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  fetchLastUsedMeasures: makeAsyncReducer(actions.fetchLastUsedMeasures, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  createComposite: makeAsyncReducer(actions.createComposite, {
    shouldDestroyData: true,
    includeUpdateAt: true,
  }),
  fetchPropertiesApi: makeAsyncReducer(actions.fetchPropertiesApi, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  fetchCompositeName: makeAsyncReducer(actions.fetchCompositeName, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
  fetchOriginInfo: makeAsyncReducer(actions.fetchOriginInfo, {
    shouldDestroyData: false,
    includeUpdateAt: true,
  }),
});

export default composeReducers(root, (state, action) => {
  if (action.type === actions.resetValidation.TYPE) {
    return root(undefined, {type: 'no-action'});
  }
  return state;
});
