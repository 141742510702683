// @flow
import React, {PureComponent} from 'react';
import {Modal} from 'react-bootstrap';
import './DataTablePreviewModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  onChange: Function,
  sqlFillActionValue: string,
};

export default class SqlSettingsModal extends PureComponent {
  props: PropTypes;

  handleClose = () => {
    this.props.onClose();
  };

  handleChange = (e) => {
    this.props.onChange(e.target.checked);
  };

  render() {
    const {isOpen, onClose, sqlFillActionValue} = this.props;

    return (
      <div>
        <Modal show={isOpen} dialogClassName="bc" onHide={onClose} bsSize="small" backdrop="static">
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span>Import Settings</span>
            </Modal.Title>
            <button type="button" className="btn btn-flat btn-icon-36 btn-secondary" onClick={this.handleClose}>
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div styleName="root">
              <div>
                <div className="cb2">
                  <input
                    type="checkbox"
                    name="missingDimPolicy"
                    id="missingDimPolicy"
                    checked={sqlFillActionValue === 'fill'}
                    onChange={this.handleChange}
                  />
                  <label htmlFor="missingDimPolicy">
                    Replace empty cells
                    <div styleName="label-secondary">Fill with &apos;unknown&apos;</div>
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-flat btn-secondary" onClick={onClose}>
              OK
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}
