// @flow
import React from 'react';
import EmptyMessage, {IMAGE_TYPES} from 'common/componentsV2/table/EmptyMessage';
import '../../../common/componentsV2/table/EmptyMessage.module.scss';

type PropTypes = {
  alertsCount: Number,
};

export default class AlertConsoleEmptyState extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <div styleName="container">
        {this.props.alertsCount === 0 ? (
          <EmptyMessage
            imageType={IMAGE_TYPES.NO_ALERTS}
            title="No Alerts Yet"
            subtitle="Start by creating the first alert"
          />
        ) : (
          <EmptyMessage
            imageType={IMAGE_TYPES.ALERTS_FILTERED_OUT}
            title="No Alerts to Show"
            subtitle="Change Filters to See Alerts"
          />
        )}
      </div>
    );
  }
}
