import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {palette} from 'app/styles/theme';

const MinimizeTitle = ({isLoading, title}: PropTypes) => {
  if (isLoading || !title) {
    return null;
  }

  return (
    <TypographyBox width="90%" variant="h5" color={palette.gray['400']} mt={0.5} isEllipsis>
      {title}
    </TypographyBox>
  );
};

MinimizeTitle.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
};

export default memo(MinimizeTitle);
