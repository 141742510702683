import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const bookmarks = makeAsyncReducer(actions.fetchAdobeBookmarks, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const reportDescription = makeAsyncReducer(actions.fetchAdobeReportDescription, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

export default combineReducers({
  bookmarks,
  reportDescription,
});
