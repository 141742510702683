import React, {Fragment, memo, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {components} from 'react-select2';
import Grid from '@material-ui/core/Grid';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {ALERT_ACTION_PARAMS, INITIAL_ACTION, MENU_OPTIONS_ALERT_ACTION_ITEM} from '../services/service';
import * as selectors from '../store/selectors';

const useStyles = makeStyles(({palette}) => ({
  main: {
    padding: '12px 10px',
    position: 'relative',
    borderRadius: 10,
    marginBottom: 12,
    backgroundColor: palette.gray['100'],
    cursor: 'default',
  },
  liner: {
    borderBottom: `1px solid ${palette.gray['300']}`,
  },
  dropdownIndicator: {
    fontSize: 18,
    color: palette.gray['500'],
  },
}));

const AlertActionItem = memo((props: PropTypes) => {
  const classes = useStyles();
  const alertActions = useSelector(selectors.getAlertActionsView);
  const [action, setAction] = useState(INITIAL_ACTION);
  const onChange = useCallback(
    (event, selection) => {
      event.stopPropagation();
      props.onChange(selection.value, action.id, action.usedInAlerts);
    },
    [action],
  );

  useEffect(() => {
    const findAction = alertActions.find((a) => a.id === props.id);
    setAction((prevState) => ({...prevState, ...findAction}));
  }, [alertActions]);

  return (
    <Fragment>
      <Grid container className={classes.main} style={{marginTop: props.isPrimary ? 0 : 12}}>
        <Grid item sm={12}>
          <Grid container>
            <Grid item sm={11}>
              <div className="text16med lineHeight_16 ellipsis">{action[ALERT_ACTION_PARAMS.ACTION_NAME]}</div>
            </Grid>
            <Grid item sm={1} style={{display: 'flex', justifyContent: 'flex-end'}}>
              <SelectAndt
                appendToBody={false}
                type={TYPE_SIMPLE}
                theme={THEME_TRANSPARENT}
                options={MENU_OPTIONS_ALERT_ACTION_ITEM}
                direction={DIRECTION_LEFT}
                menuWidth={200}
                automationId="assetsMoreOptions"
                customComponent={{
                  Option: (p) => (
                    <components.Option {...p}>
                      <div onClick={(e) => onChange(e, p)}>{p.children}</div>
                    </components.Option>
                  ),
                  DropdownIndicator: () => (
                    <i
                      className={['icon icn-general16-3dot', classes.dropdownIndicator].join(' ')}
                      onClick={(e) => e.stopPropagation()}
                    />
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm={12} style={{marginTop: 8}}>
          <Grid container>
            <Grid item sm={4}>
              <TypographyBox variant="subtitle3" isEllipsis css={{maxWidth: 300}}>
                {action[ALERT_ACTION_PARAMS.BTN_NAME]}
              </TypographyBox>
            </Grid>

            <Grid item sm={8}>
              <TypographyBox variant="hintText" isEllipsis css={{padding: '0 4px', maxWidth: 300}}>
                {action[ALERT_ACTION_PARAMS.DATA][ALERT_ACTION_PARAMS.DATA_URL]}
              </TypographyBox>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {props.isPrimary && (
        <Grid container>
          <Grid item sm={12}>
            <Box className={classes.liner} />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
});

AlertActionItem.propTypes = {
  id: PropTypes.string.isRequired,
  isPrimary: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AlertActionItem;
