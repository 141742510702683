// @flow
import React, {PureComponent} from 'react';
import SearchBox from 'common/components/SearchBox';
import MetricName from 'metrics/components/metricName/MetricName';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import {AutoSizer, List} from 'react-virtualized';
import Loader from 'common/components/Loader';
import {isNil} from 'lodash';
import AlertStatusIcon from '../alertContent/AlertStatusIcon';

import './SnoozeActionMetrics.module.scss';

type PropTypes = {
  filteredMetrics: Array,
  metricsFilter: string,
  isLoading: boolean,
  isSnoozeAllInSnoozedMetrics: boolean,
  isStlAllInMetrics: Boolean,
  alertType: String,

  metricsFilterChanged: Function,
  setSnoozeIndicationForMetric: Function,
  setSTLIndicationForMetric: Function,
};

class SnoozeActionMetrics extends PureComponent {
  props: PropTypes;

  state = {
    firstLaunch: true,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.filteredMetrics !== this.props.filteredMetrics && this.list) {
      this.list.recomputeRowHeights(0);
      const allSnoozeNull = this.props.filteredMetrics.every((i) => !i.snooze);
      const allStopLearningNull = this.props.filteredMetrics.every((i) => !i.stopLearning);
      if (this.state.firstLaunch) {
        if (allSnoozeNull) {
          this.onCheckAllMetricsClick(true);
        }
        if (allStopLearningNull) {
          this.onCheckAllStlClick(true);
        }
        /* eslint-disable-next-line react/no-did-update-set-state */
        this.setState({firstLaunch: false});
      }
    }
  }

  isMetricSnoozed = (metric) => !isNil(metric.snooze);

  isPausedLearning = (metric) => !isNil(metric.stopLearning);

  snoozeClicked = (metric) => () => {
    this.props.setSnoozeIndicationForMetric({metricId: metric.id, isSnoozed: !this.isMetricSnoozed(metric)});
  };

  pausedLearningClicked = (metric) => () => {
    this.props.setSTLIndicationForMetric({metricId: metric.id, isStopLearning: !this.isPausedLearning(metric)});
  };

  noRowsRenderer = () => <div styleName="no-results">no metrics found</div>;

  rowRenderer = (rowInfo /* , index, isScrolling, isVisible, style */) => {
    const metric = this.props.filteredMetrics[rowInfo.index];
    const isSnoozed = this.isMetricSnoozed(metric);
    const isPausedLearning = this.isPausedLearning(metric);

    return (
      <div key={metric.id} style={rowInfo.style} automation-id="alertConsole" styleName="metric-item">
        <div styleName="metric-wrapper">
          <div styleName="status">
            <AlertStatusIcon status={metric.status} />
          </div>
          <MetricName metric={metric} isSingleLine />
        </div>

        <i
          styleName="snooze-checkbox"
          className={['icon', isSnoozed ? 'icn-icon-checkbox-full' : 'icn-icon-checkbox'].join(' ')}
          onClick={this.snoozeClicked(metric)}
          role="button"
        />

        {this.props.alertType === 'anomaly' && (
          <i
            styleName="stl-checkbox"
            className={['icon', isPausedLearning ? 'icn-icon-checkbox-full' : 'icn-icon-checkbox'].join(' ')}
            onClick={this.pausedLearningClicked(metric)}
            role="button"
          />
        )}
      </div>
    );
  };

  onCheckAllMetricsClick = (isChecked) => {
    this.props.setSnoozeIndicationForMetric({isAll: true, isSnoozed: isChecked});
  };

  onCheckAllStlClick = (isChecked) => {
    this.props.setSTLIndicationForMetric({isAll: true, isStopLearning: isChecked});
  };

  render() {
    const {
      metricsFilter,
      filteredMetrics,
      metricsFilterChanged,
      isLoading,
      isSnoozeAllInSnoozedMetrics,
      isStlAllInMetrics,
      alertType,
    } = this.props;

    return (
      <div styleName="root">
        <div styleName="metrics-header">Choose Metrics</div>

        <SearchBox filter={metricsFilter} onFilter={metricsFilterChanged} isDisabled={isLoading} />

        <div styleName="metrics">
          <div styleName="metrics-titles">
            <div styleName={alertType === 'anomaly' ? 'title-wrapper' : 'title-wrapper alone'}>
              <span>Snooze</span>
              <i
                className={['icon', isSnoozeAllInSnoozedMetrics ? 'icn-icon-checkbox-full' : 'icn-icon-checkbox'].join(
                  ' ',
                )}
                onClick={this.onCheckAllMetricsClick.bind(this, !isSnoozeAllInSnoozedMetrics)}
                role="button"
              />
            </div>

            {alertType === 'anomaly' && (
              <div styleName="title-wrapper stl">
                <span>Pause learning</span>
                <i
                  className={['icon', isStlAllInMetrics ? 'icn-icon-checkbox-full' : 'icn-icon-checkbox'].join(' ')}
                  onClick={this.onCheckAllStlClick.bind(this, !isStlAllInMetrics)}
                  role="button"
                />
              </div>
            )}
          </div>

          <div styleName="metrics-list">
            {isLoading && <Loader size="small" />}

            {!isLoading && (
              <AutoSizer>
                {({height, width}) => (
                  <List
                    ref={(list) => {
                      this.list = list;
                    }}
                    height={height}
                    rowCount={filteredMetrics.length}
                    rowHeight={38}
                    rowRenderer={this.rowRenderer}
                    noRowsRenderer={this.noRowsRenderer}
                    width={width}
                    style={{overflowY: 'scroll'}}
                  />
                )}
              </AutoSizer>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default SnoozeActionMetrics;
