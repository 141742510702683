// @flow
import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';
// import {selectors as profileSelectors} from 'profile';
import {get, isEqual} from 'lodash';
import * as bcSelectors from 'bc/store/selectors';
// eslint-disable-next-line import/no-cycle
import {ASCENDANT, SORT_BY_TITLE} from '../components/CubesSorting';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export const {getCubesRoot} = commonSelectors;
// export const {getOrganizationSettingsBC, getIsMeLoading} = profileSelectors;

//* ** schemas
export const getSchemas = createSelector(
  getCubesRoot,
  (cubeRoot) => cubeRoot.schemas,
);

export const getIsSchemasLoading = createSelector(
  getSchemas,
  (schemas) => schemas.isLoading,
);

export const getSchemasItems = createSelector(
  getSchemas,
  (schemas) => schemas.data || EMPTY_ARRAY,
);

export const getSchemasSort = createSelector(
  getCubesRoot,
  (cubeRoot) => cubeRoot.sort || {label: null, value: null},
);

export const getSchemasOrder = createSelector(
  getCubesRoot,
  (cubeRoot) => cubeRoot.order || '',
);

export const getSchemaItemsWithCubes = createSelector(
  getSchemasItems,
  getSchemasSort,
  getSchemasOrder,
  bcSelectors.getDataStreamsItems,
  (schemaItems, sort, order, dataStreamsItems) => {
    const sortFunc = (a, b) => {
      if (sort === SORT_BY_TITLE.value) {
        if (a.name < b.name) {
          if (order === ASCENDANT) {
            return -1;
          }
          return 1;
        }
        if (a.name > b.name) {
          if (order === ASCENDANT) {
            return 1;
          }
          return -1;
        }
        return 0;
      }
      return null;
      // TODO - implement sort by number of metrics
    };

    const filtered = schemaItems
      .filter((item) => item.schemaCubesWrapper.cubes)
      .sort((a, b) => {
        if (a.streamSchemaWrapper.schema.name < b.streamSchemaWrapper.schema.name) {
          return -1;
        }
        if (a.streamSchemaWrapper.schema.name > b.streamSchemaWrapper.schema.name) {
          return 1;
        }
        return 0;
      });

    const orderedCubes = filtered.map((item) => {
      const i = item;
      let dataStream = null;
      if (i.schemaCubesWrapper.cubes.topLevelCubes) {
        const tlcArr = Object.values(i.schemaCubesWrapper.cubes.topLevelCubes).sort((a, b) => sortFunc(a, b));
        const tlcObj = {};
        tlcArr.forEach((tlc) => {
          tlcObj[tlc.id] = tlc;
        });
        i.schemaCubesWrapper.cubes.topLevelCubes = tlcObj;
        const schemaId = i.streamSchemaWrapper.schema.id;

        if (dataStreamsItems && dataStreamsItems.length > 0) {
          dataStream = dataStreamsItems.find((data) => data.id === schemaId);
        }
      }
      return {
        ...i,
        type: dataStream ? dataStream.type : null,
      };
    });
    return orderedCubes || EMPTY_ARRAY;
  },
);

//* ** selected schema\cube
export const getSelectedCubeId = createSelector(
  getCubesRoot,
  (cubeRoot) => cubeRoot.selectedCubeId,
);

export const getSelectedSchemaId = createSelector(
  getCubesRoot,
  (cubeRoot) => cubeRoot.selectedSchemaId,
);

export const getCurrentNumMetricsIsLoading = createSelector(
  getCubesRoot,
  (cubesRoot) => cubesRoot.currentNumMetricsIsLoading,
);

export const getSelectedSchemaRoot = createSelector(
  getSchemasItems,
  getSelectedSchemaId,
  (schemas, selectedSchemaId) => schemas.find((schema) => schema.streamSchemaWrapper.schema.id === selectedSchemaId),
);

export const getSelectedSchema = createSelector(
  getSelectedSchemaRoot,
  (schemaRoot) => (schemaRoot ? schemaRoot.streamSchemaWrapper.schema : EMPTY_OBJECT),
);

export const getSelectedSchemaCubes = createSelector(
  getSelectedSchemaRoot,
  (schemaRoot) => (schemaRoot ? schemaRoot.schemaCubesWrapper.cubes : EMPTY_OBJECT),
);

export const getSelectedSchemaTopLevelCubes = createSelector(
  getSelectedSchemaCubes,
  (schemaCubes) => (schemaCubes ? get(schemaCubes, 'topLevelCubes', EMPTY_OBJECT) : EMPTY_OBJECT),
);

export const getSelectedTopLevelCube = createSelector(
  getSelectedSchemaCubes,
  getSelectedCubeId,
  (schemaCubes, selectedCubeId) => (schemaCubes ? get(schemaCubes.topLevelCubes, selectedCubeId, null) : null),
);

export const getExpandedCube = createSelector(
  getCubesRoot,
  (cubeRoot) => cubeRoot.expandedCube,
);

// for editable cube
export const getEditableTopCube = createSelector(
  getCubesRoot,
  (cubeRoot) => cubeRoot.editableTopCube || EMPTY_OBJECT,
);

export const getSelectedSchemaFilteredPossibleDimensions = createSelector(
  getSelectedSchema,
  getEditableTopCube,
  (schema, editableCube) =>
    schema.dimensions && editableCube.dimensions
      ? schema.dimensions
          .filter((d) => !editableCube.dimensions.includes(d))
          .map((item) => ({
            id: item,
            name: item,
          }))
      : EMPTY_ARRAY,
);

export const getSelectedSchemaFilteredSelectedDimensions = createSelector(
  getEditableTopCube,
  (editableCube) =>
    editableCube
      ? get(editableCube, 'dimensions', EMPTY_ARRAY).map((item) => ({
          id: item,
          name: item,
        }))
      : EMPTY_ARRAY,
);

export const getSelectedCubePreview = createSelector(
  getCubesRoot,
  (cubeRoot) => cubeRoot.selectedCubePreview,
);

export const getSelectedCubePreviewIsLoading = createSelector(
  getSelectedCubePreview,
  (cubePreview) => cubePreview.isLoading,
);

export const getSelectedCubePreviewData = createSelector(
  getSelectedCubePreview,
  (cubePreview) => cubePreview.data || EMPTY_OBJECT,
);

export const getSelectedCubePreviewCubeLevelCount = createSelector(
  getSelectedCubePreviewData,
  (cubePreviewData) => cubePreviewData.cubeLevelCount || EMPTY_ARRAY,
);

export const getSearchCubeString = createSelector(
  getCubesRoot,
  (cubeRoot) => cubeRoot.searchCubeString,
);

export const getIsDuplicated = createSelector(
  getSelectedSchemaTopLevelCubes,
  getEditableTopCube,
  (selectedSchemaTopLevelCubes, editableTopCube) => {
    Object.keys(selectedSchemaTopLevelCubes).forEach((key) => {
      const cube = selectedSchemaTopLevelCubes[key];
      if (
        cube.id !== editableTopCube.id &&
        (cube.measure === editableTopCube.measure && isEqual(cube.dimensions.sort(), editableTopCube.dimensions.sort()))
      ) {
        return true;
      }
      return null;
    });
    return false;
  },
);
