import React from 'react';
import Accounts from './accounts/Accounts';
import Scheduler from '../../scheduler/Scheduler';
import DimensionsAndMetrics from './dimensionsAndMetrics/DimensionsAndMetrics';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

import './GoogleAnalyticsStreamEditor.module.scss';

const GoogleAnalyticsStreamEditor = () => (
  <div styleName="root" automation-id="googleAnalyticsStreamEditor" className="shell-col">
    <StreamEditorSteps>
      <Accounts />
      <DimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <Scheduler />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default GoogleAnalyticsStreamEditor;
