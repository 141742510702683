// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useTooltipStyles} from 'topologyGeneral/services/mapService';

const SiteInfoPanel = ({site, isHidden}: PropTypes) => {
  if (isHidden || !site) {
    return null;
  }

  const classes = useTooltipStyles();

  return (
    <Fragment>
      <Box display="flex" mb={0.25}>
        <Box component="span" className={classes.title14Bold} mr={0.5}>
          {site.title}
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Latitude
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {site.geoPoint[0]}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Longitude
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {site.geoPoint[1]}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Domain
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {site.domain || '-'}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Address
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {site.address || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Zipcode
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {site.zipcode || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Customer
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {site.customer || '-'}
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

SiteInfoPanel.propTypes = {
  site: PropTypes.objectOf(PropTypes.any).isRequired,
  isHidden: PropTypes.bool,
};

SiteInfoPanel.defaultProps = {
  isHidden: false,
};

export default SiteInfoPanel;
