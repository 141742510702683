import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import userEventsHandler from 'userEvents/services/errors';
import * as actions from 'userEvents/store/actions';

const initialState = {};

const EMPTY_ARRAY = [];

const eventsCategoriesReducer = composeReducers(
  makeAsyncReducer(actions.fetchUserEventsCategories, {dataProp: 'items', defaultData: EMPTY_ARRAY}),
  {
    newUserEventCategory: makeAsyncReducer(actions.createUserEventsCategories, {
      defaultData: EMPTY_ARRAY,
      errorHandler: userEventsHandler,
    }),
  },
  {deleteUserEventsCategory: makeAsyncReducer(actions.deleteUserEventsCategory, {})},

  (state = initialState, {type, payload, meta}) => {
    switch (type) {
      case actions.createUserEventsCategories.success.TYPE:
        return {
          ...state,
          items: [payload, ...state.items],
        };
      case actions.deleteUserEventsCategory.success.TYPE:
        return {
          ...state,
          items: state.items.filter((i) => i.id !== meta),
        };
      default:
        return state;
    }
  },
);

export default eventsCategoriesReducer;
