// @flow
import React, {PureComponent, Fragment} from 'react';
import {get, isEmpty} from 'lodash';
import './QueryEditorModal.module.scss';

type PropTypes = {
  previewData: Object,
  isPreviewDataLoading: boolean,
  previewDataError: Object,
};

export default class QueryPreview extends PureComponent {
  props: PropTypes;

  render() {
    const {isPreviewDataLoading, previewData, previewDataError} = this.props;

    return (
      <Fragment>
        {!isPreviewDataLoading && isEmpty(previewDataError) && isEmpty(previewData) && (
          <div styleName="info-wrap">
            <div styleName="info">Click Run to see the actual query</div>
          </div>
        )}
        {!isPreviewDataLoading && !isEmpty(previewData) && previewData.query && (
          <div styleName="query">{previewData.query}</div>
        )}
        {!isPreviewDataLoading && isEmpty(previewData) && !isEmpty(previewDataError) && (
          <div styleName="query">{Object.values(get(previewDataError, 'additionalInfo', {}))[0]}</div>
        )}
      </Fragment>
    );
  }
}
