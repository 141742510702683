import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';
import simulationFilters from './simulationFiltersReducer';
import alertDefinition from './alertDefinitionReducer';
import influencingMetricsInfo from './influencingMetricsInfoReducer';
import simulationFiltersIsDirty from './simulationFiltersIsDirtyReducer';
import simulationFiltersIsLoadingEstimations from './simulationFiltersIsLoadingEstimations';
import simulationFiltersValidationResult from './simulationFiltersValidationResultReducer';
import alertManagerReducer from './alertManagerReducer';
import simpleAlertReducer from './simpleAlertReducer';
import * as actions from '../actions';

const alertEditRoot = combineReducers({
  simulationFilters,
  simulationFiltersIsDirty,
  simulationFiltersValidationResult,
  simulationFiltersIsLoadingEstimations,
  alertDefinition,
  influencingMetricsInfo,
});

const alertEdit = composeReducers(alertEditRoot, (state, action) => {
  if (action.type === actions.resetExpressionBuilder.TYPE) {
    return alertEditRoot(undefined, {type: 'no-action'});
  }
  return state;
});

// views
export default combineReducers({
  alertEdit,
  alertManager: alertManagerReducer,
  simpleAlert: simpleAlertReducer,
});
