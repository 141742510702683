import {composeReducers} from 'common/utils/reducers';
import {combineReducers} from 'redux';

// const EMPTY_ARRAY = [];

// eslint-disable-next-line no-unused-vars
const main = composeReducers((state = {}, {type, payload}) => ({}));

export default combineReducers({
  main,
});
