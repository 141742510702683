import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';

const fetchS3CsvFormatAnalysis = makeAsyncEpic(actions.fetchS3CsvFormatAnalysis, api.fetchS3CsvFormatAnalysis);
const fetchS3Preview = makeAsyncEpic(actions.fetchS3Preview, api.fetchS3Preview);

const setS3StreamFilePathAndNamePattern = (action$, {getState}) =>
  action$
    .ofType(actions.setS3StreamFilePathAndNamePattern.TYPE)
    .switchMap(() => [selectors.getSelectedDataStream(getState())])
    .flatMap((stream) => [
      actions.fetchS3CsvFormatAnalysis({
        dataSourceId: stream.dataSourceId,
        path: stream.path,
        fileNameSuffix: stream.fileNameSuffix,
        fileNamePrefix: stream.fileNamePrefix || '',
        fileNamePattern: stream.fileNamePattern || 'yyyyMMddHH',
        fileFormat: stream.fileFormat,
      }),
    ]);

const setS3StreamAnalysisSchema = (action$) =>
  action$
    .ofType(actions.fetchS3CsvFormatAnalysis.success.TYPE)
    .flatMap((action) => [actions.setS3StreamDiametricsAnalysisSchema(action.payload)]);

const setS3StreamUiStateDataValues = (action$, {getState}) =>
  action$
    .ofType(actions.setS3StreamDiametricsAnalysisSchema.TYPE)
    .switchMap(() => [selectors.getSelectedDataStream(getState())])
    .flatMap((stream) => [
      actions.setS3StreamUiKeyVal({
        path: stream.path,
        fileNameSuffix: stream.fileNameSuffix,
        fileNamePrefix: stream.fileNamePrefix,
        fileNamePattern: stream.fileNamePattern,
      }),
    ]);

const setS3StreamFileFormat = (action$, {getState}) =>
  action$.ofType(actions.setS3StreamFileFormat.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());

    return [
      actions.fetchS3CsvFormatAnalysis({
        dataSourceId: stream.dataSourceId,
        fileFormat: action.payload.fileFormat,
        path: stream.path,
        fileNameSuffix: stream.fileNameSuffix,
        fileNamePrefix: stream.fileNamePrefix || '',
        fileNamePattern: stream.fileNamePattern || 'yyyyMMddHH',
      }),
    ];
  });

const S3Epic = combineEpics(
  setS3StreamFilePathAndNamePattern,
  fetchS3CsvFormatAnalysis,
  fetchS3Preview,
  setS3StreamAnalysisSchema,
  setS3StreamUiStateDataValues,
  setS3StreamFileFormat,
);
export default S3Epic;
