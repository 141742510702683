import React from 'react';
import SimpleRow from 'common/componentsV2/accordion/SimpleRow';

import './MetricsCardinalityStream.module.scss';

type PropTypes = {
  metricsCardinality: Object,
};

export default class MetricsCardinalityStream extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <ul styleName="container">
        {Object.keys(this.props.metricsCardinality).map((i) => (
          <li key={i}>
            <SimpleRow>
              <span>{i}</span>
              <span styleName="last">{this.props.metricsCardinality[i].cardinality}</span>
            </SimpleRow>
          </li>
        ))}
      </ul>
    );
  }
}
