import {makeAsyncAction, makeAction} from 'common/utils/simplifiedAsync';

const ACTION_PREFIX = 'anodot/admin.csPortal/';
const mac = (actionName) => makeAction(actionName, ACTION_PREFIX);
const macAsync = (actionName) => makeAsyncAction(actionName, ACTION_PREFIX);

export const changeTab = mac('CHANGE_TAB');
export const changeCustomer = mac('CHANGE_CUSTOMER');

export const fetchStreams = macAsync('FETCH_STREAMS');
export const fetchStreamDimensions = macAsync('FETCH_STREAM_DIMENSIONS');
export const fetchStats = macAsync('FETCH_STATS');

export const fetchStreamsReport = macAsync('FETCH_STREAMS_REPORT');
export const fetchTaskLog = macAsync('FETCH_TASK_LOG');
export const fetchKibanaLog = macAsync('FETCH_KIBANA_LOG');

export const flushMetrics = macAsync('FLUSH_METRICS');
export const searchMetrics = macAsync('SEARCH_METRICS');
export const deleteMetrics = macAsync('DELETE_METRICS');
export const getMetricsEps = macAsync('GET_METRICS_EPS');
export const generateTemplates = macAsync('GENERATE_TEMPLATE');

export const fetchAlertsErrors = macAsync('FETCH_ALERTS_ERRORS');
export const fetchCompositeErrors = macAsync('FETCH_COMPOSITE_ERRORS');
