// @flow
import React from 'react';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import {Box, makeStyles, useTheme} from '@material-ui/core';

export const COLOR_NAME = {
  GRAY: 'gray',
  GRAY_400: 'gray_400',
  GRAY_300: 'gray_300',
  GRAY_350: 'gray_350',
  GRAY_100: 'gray_100',
  PURPLE: 'purple',
  PURPLE_200: 'purple_200',
  AZURE: 'azure',
  YELLOW: 'yellow',
  MANGO: 'mango',
  BLUE: 'blue',
  NAVYBLUE: 'navyblue',
  EUCALIPTUS: 'eucaliptus',
  LIME: 'lime',
  LIME_400: 'lime_400',
  ROSE: 'rose',
  ROSE_400: 'rose_400',
  LILACH_400: 'lilach_400',
  LILACH_600: 'lilach_600',
  EGGPLANT: 'eggplant',
  TEAL: 'teal',
  TEAL_400: 'teal_400',
  TOMATO: 'tomato',
  TOMATO_400: 'tomato_400',
  MINT: 'mint',
  MINT_600: 'mint_600',
  WHITE: 'white',
  YELLOW_200: 'yellow_200',
  YELLOW_400: 'yellow_400',
  ORANGE_300: 'orange_300',
  ORANGE_400: 'orange_400',
  ORANGE: 'orange',
  RED: 'red',
  RED_200: 'red_200',
  RED_400: 'red_400',
  INDIGO: 'indigo',
  INDIGO_400: 'indigo_400',
  BLACK: 'black',
  BROWN: 'brown',
  BROWN_400: 'brown_400',
};

export const COLORS_PICKER = {
  [COLOR_NAME.GRAY]: {
    color: COLOR_NAME.GRAY,
    contrast: 500,
  },
  [COLOR_NAME.GRAY_400]: {
    color: COLOR_NAME.GRAY,
    contrast: 400,
    rgb: '137,149,160',
  },
  [COLOR_NAME.GRAY_300]: {
    color: COLOR_NAME.GRAY,
    contrast: 300,
  },
  [COLOR_NAME.GRAY_350]: {
    color: COLOR_NAME.GRAY,
    contrast: 350,
  },
  [COLOR_NAME.GRAY_100]: {
    color: COLOR_NAME.GRAY,
    contrast: 100,
  },
  [COLOR_NAME.PURPLE]: {
    color: COLOR_NAME.PURPLE,
    contrast: 500,
  },
  [COLOR_NAME.PURPLE_200]: {
    color: COLOR_NAME.PURPLE,
    contrast: 200,
  },
  [COLOR_NAME.AZURE]: {
    color: COLOR_NAME.AZURE,
    contrast: 500,
  },
  [COLOR_NAME.ROSE]: {
    color: COLOR_NAME.ROSE,
    contrast: 500,
  },
  [COLOR_NAME.YELLOW]: {
    color: COLOR_NAME.YELLOW,
    contrast: 500,
  },
  [COLOR_NAME.MANGO]: {
    color: COLOR_NAME.MANGO,
    contrast: 500,
  },
  [COLOR_NAME.NAVYBLUE]: {
    color: COLOR_NAME.NAVYBLUE,
    contrast: 500,
  },
  [COLOR_NAME.BLUE]: {
    color: COLOR_NAME.BLUE,
    contrast: 500,
    rgb: '38,113,255',
  },
  [COLOR_NAME.EUCALIPTUS]: {
    color: COLOR_NAME.EUCALIPTUS,
    contrast: 500,
    rgb: '0,147,78',
  },
  [COLOR_NAME.LIME]: {
    color: COLOR_NAME.LIME,
    contrast: 500,
  },
  [COLOR_NAME.LIME_400]: {
    color: COLOR_NAME.LIME,
    contrast: 400,
  },
  [COLOR_NAME.ROSE_400]: {
    color: COLOR_NAME.ROSE,
    contrast: 400,
  },
  [COLOR_NAME.LILACH_400]: {
    color: 'lilach',
    contrast: 400,
  },
  [COLOR_NAME.LILACH_600]: {
    color: 'lilach',
    contrast: 600,
    rgb: '140,13,175',
  },
  [COLOR_NAME.EGGPLANT]: {
    color: COLOR_NAME.EGGPLANT,
    contrast: 500,
    rgb: '128,6,97',
  },
  [COLOR_NAME.TEAL]: {
    color: COLOR_NAME.TEAL,
    contrast: 500,
  },
  [COLOR_NAME.TEAL_400]: {
    color: COLOR_NAME.TEAL,
    contrast: 400,
  },
  [COLOR_NAME.TOMATO]: {
    color: COLOR_NAME.TOMATO,
    contrast: 500,
    rgb: '255,86,83',
  },
  [COLOR_NAME.TOMATO_400]: {
    color: COLOR_NAME.TOMATO,
    contrast: 400,
  },
  [COLOR_NAME.WHITE]: {
    color: COLOR_NAME.WHITE,
    contrast: 500,
  },
  [COLOR_NAME.YELLOW_200]: {
    color: COLOR_NAME.YELLOW,
    contrast: 200,
  },
  [COLOR_NAME.YELLOW_400]: {
    color: COLOR_NAME.YELLOW,
    contrast: 400,
    rgb: '255,199,0',
  },
  [COLOR_NAME.YELLOW]: {
    color: COLOR_NAME.YELLOW,
    contrast: 500,
  },
  [COLOR_NAME.ORANGE_300]: {
    color: COLOR_NAME.ORANGE,
    contrast: 300,
  },
  [COLOR_NAME.ORANGE_400]: {
    color: COLOR_NAME.ORANGE,
    contrast: 400,
    rgb: '255,159,48',
  },
  [COLOR_NAME.ORANGE]: {
    color: COLOR_NAME.ORANGE,
    contrast: 500,
  },
  [COLOR_NAME.RED_200]: {
    color: COLOR_NAME.RED,
    contrast: 200,
  },
  [COLOR_NAME.RED_400]: {
    color: COLOR_NAME.RED,
    contrast: 400,
    rgb: '222,87,115',
  },
  [COLOR_NAME.RED]: {
    color: COLOR_NAME.RED,
    contrast: 500,
  },
  [COLOR_NAME.INDIGO]: {
    color: COLOR_NAME.INDIGO,
    contrast: 500,
  },
  [COLOR_NAME.INDIGO_400]: {
    color: COLOR_NAME.INDIGO,
    contrast: 400,
  },
  [COLOR_NAME.MINT]: {
    color: COLOR_NAME.MINT,
    contrast: 500,
  },
  [COLOR_NAME.MINT_600]: {
    color: COLOR_NAME.MINT,
    contrast: 600,
  },
  [COLOR_NAME.BLACK]: {
    color: COLOR_NAME.BLACK,
    contrast: 500,
  },
  [COLOR_NAME.BROWN]: {
    color: COLOR_NAME.BROWN,
    contrast: 500,
  },
  [COLOR_NAME.BROWN_400]: {
    color: COLOR_NAME.BROWN,
    contrast: 400,
  },
};

const useStyles = makeStyles((theme) => ({
  option: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 16,
    height: 16,
    color: theme.palette.white[500],
    borderRadius: 3,
    marginLeft: 8,
    marginTop: 8,
    cursor: 'pointer',
    '&:hover': {
      boxShadow: `0 0 0 2px ${theme.palette.blue[300]}`,
    },
  },
  button: {
    display: 'flex',
    width: ({buttonSize}) => buttonSize || 32,
    height: ({buttonSize}) => buttonSize || 32,
    borderRadius: ({buttonSize}) => (buttonSize ? buttonSize / 4 : 8),
    '&:hover': {
      boxShadow: `0 0 0 2px ${theme.palette.blue[300]}`,
    },
  },
  white: {
    boxShadow: `0 0 0 2px ${theme.palette.gray[300]}`,
  },
  wrapperOptionMenu: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: 4,
  },
  checkmark: {
    position: 'absolute',
    margin: 'auto',
  },
  restore: {
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 500,
    padding: '12px 0',
    marginLeft: 8,
  },
}));

const OptionComponent = ({data, isSelected}: {data: Object, isSelected: boolean}) => {
  const theme = useTheme();
  const classes = useStyles();
  return (
    <Box
      bgcolor={theme.palette[COLORS_PICKER[data].color][COLORS_PICKER[data].contrast]}
      className={`${classes.option} ${data === COLOR_NAME.WHITE ? classes.white : ''}`}
    >
      {isSelected && <i className="icon icn-general16-checkmark" />}
    </Box>
  );
};

const ColorPicker = ({
  value,
  onChange,
  options,
  customButton,
  buttonSize,
  menuWidth,
  withoutButton,
  handleRestore,
}: {
  value: String,
  onChange: Function,
  options: Array,
  customButton: React.Node,
  buttonSize: Number,
  menuWidth: number,
  withoutButton: boolean,
  handleRestore: Function,
}) => {
  const theme = useTheme();
  const classes = useStyles({buttonSize});
  const selectedColor = COLORS_PICKER[value]
    ? theme.palette[COLORS_PICKER[value].color][COLORS_PICKER[value].contrast]
    : theme.palette.white[500];
  const buttonComponent = (
    <Box className={`${classes.button} ${value === COLOR_NAME.WHITE ? classes.white : ''}`} bgcolor={selectedColor} />
  );

  if (withoutButton) {
    return (
      <div className={classes.wrapperOptionMenu}>
        {options.map((o) => (
          <div
            key={o}
            onClick={() => onChange(o)}
            className={`${classes.option} ${o === COLOR_NAME.WHITE ? classes.white : ''}`}
            style={{backgroundColor: theme.palette[COLORS_PICKER[o].color][COLORS_PICKER[o].contrast]}}
          >
            {value === o && <i className={`icon icn-general16-checkmark ${classes.checkmark}`} />}
          </div>
        ))}
        <div className={classes.restore} onClick={handleRestore}>
          Restore System color
        </div>
      </div>
    );
  }

  return (
    <FormDdlSelect
      options={options}
      selected={value}
      optionComponent={<OptionComponent />}
      button={customButton || buttonComponent}
      onChange={onChange}
      width={menuWidth}
      maxWidth={menuWidth}
      isFlexibleDDL
    />
  );
};

export default ColorPicker;
