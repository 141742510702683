import {get} from 'lodash';

export const getEditorValidationMessage = (stream, isAll = false) => {
  const isPristine = !get(stream, 'uiState.tablesViewsMetadata') && !get(stream, 'uiState.queryPreviewColumns');

  const res = [];
  if (!isPristine && (!stream.dimensions.length && !stream.metrics.length)) {
    res.push('At least one dimension or metric is required');
  }
  if (!isPristine && !stream.timestampColumn) {
    res.push('Timestamp definition is required');
  }
  if (!isAll && get(stream, 'uiState.selectedRadio') === 'template' && !stream.basedOnTemplateId) {
    res.push('Selection of use case is required');
  }
  if (isPristine && !isAll) {
    res.push('Choose a table or view');
  }

  if (isAll) {
    return res;
  }
  return res[0];
};

export const isSchemasSupportedBySql = (sqlSourceType) =>
  ['psql', 'redshift', 'snowflake', 'oracle', 'mssql', 'teradata', 'athena_sql'].includes(sqlSourceType);

export const isTemplateQuerySupported = (sqlSourceType) => ['snowflake'].includes(sqlSourceType);
