// @flow
import React from 'react';
import SmartTooltip from 'common/components/SmartTooltip';
import {serverAnomalyDirection, alertTypes} from 'alerts.management/services/alertsService';
import {isFinite} from 'lodash';
import {broadcast} from 'common/utils/angularServices';
import './StaticThresholdCondition.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import ConditionContainer from './ConditionContainer';

type PropTypes = {
  staticThreshold: Object,
  direction: String,
  alertType: String,
  onChange: Function,
  onClose: Function,
  id: String,
};

const lowerBounds = [
  {
    desc: 'smaller than',
    text: (
      <span>
        X <i className="icon icn-icon-triangle-func icon-flipped-horizontal bound-icon" />
      </span>
    ),
    value: 'LOWER',
  },
  {
    desc: 'smaller than or equal to',
    text: (
      <span>
        X <i className="icon icn-icon-triangle-equal-func icon-flipped-horizontal bound-icon" />
      </span>
    ),
    value: 'LOWER_EQUAL',
  },
];

const upperBounds = [
  {
    desc: 'larger than',
    text: (
      <span>
        X <i className="icon icn-icon-triangle-func bound-icon" />
      </span>
    ),
    value: 'UPPER',
  },
  {
    desc: 'larger than or equal to',
    text: (
      <span>
        X <i className="icon icn-icon-triangle-equal-func bound-icon" />
      </span>
    ),
    value: 'UPPER_EQUAL',
  },
];

class StaticThresholdCondition extends React.PureComponent {
  props: PropTypes;

  state = {
    isInvalidValues: false,
    lowerValueText: Number.isFinite(this.props.staticThreshold.minValue) ? this.props.staticThreshold.minValue : '',
    upperValueText: Number.isFinite(this.props.staticThreshold.maxValue) ? this.props.staticThreshold.maxValue : '',
  };

  componentDidMount() {
    this.setValidity(this.props.staticThreshold.maxValue, this.props.staticThreshold.minValue);
  }

  componentWillUnmount() {
    this.broadcastValidity(false);
  }

  setValidity = (maxValue, minValue) => {
    const maxVal = parseInt(maxValue, 10);
    const minVal = parseInt(minValue, 10);
    let isInvalidValues = false;

    let isRequired = false;

    if (isFinite(minVal) && isFinite(maxVal)) {
      isInvalidValues = minVal > maxVal;
    }

    if (!isFinite(minVal) && !isFinite(maxVal)) {
      isRequired = true;
    }

    this.setState({isInvalidValues, isRequired});
    this.broadcastValidity(isInvalidValues || isRequired);
  };

  broadcastValidity = (hasError) => {
    broadcast('e:andt.metrics.alerts.setClientThresholdError', {hasError});
  };

  parseValue = (val) => (Number.isFinite(parseFloat(val)) ? parseFloat(val) : null);

  handleChangeMax = (e) => {
    this.setState({upperValueText: e.target.value});
  };

  handleChangeMin = (e) => {
    this.setState({lowerValueText: e.target.value});
  };

  handleBlurMax = (e) => {
    const val = this.parseValue(e.target.value);
    this.props.onChange({maxValue: val});
    this.setValidity(val, this.props.staticThreshold.minValue);
  };

  handleBlurMin = (e) => {
    const val = this.parseValue(e.target.value);
    this.props.onChange({minValue: val});
    this.setValidity(this.props.staticThreshold.maxValue, val);
  };

  handleBoundChange = (isUpBounds, value) => {
    const toUpdate = {};
    if (isUpBounds) {
      toUpdate.upperBound = value;
    } else {
      toUpdate.lowerBound = value;
    }
    this.props.onChange(toUpdate);
  };

  render() {
    const {direction, staticThreshold, alertType, onClose, id} = this.props;
    const isInvalid = this.state.isInvalidValues || this.state.isRequired;
    const ttMesage = this.state.isInvalidValues ? (
      <span>
        Upper threshold is smaller <br /> than lower threshold
      </span>
    ) : (
      <span>
        At least one threshold <br /> is required
      </span>
    );

    const lowerBound = staticThreshold.lowerBound || lowerBounds[1].value;
    const lowerBoundText = lowerBounds.find((b) => b.value === lowerBound).desc;
    const upperBound = staticThreshold.upperBound || upperBounds[1].value;
    const upperBoundText = upperBounds.find((b) => b.value === upperBound).desc;

    return (
      <ConditionContainer title="Metric Value" onClose={onClose} id={id}>
        <div styleName="spacer" />
        <div styleName="containers-wrapper">
          {(alertType === alertTypes.static.value ||
            direction === serverAnomalyDirection.both ||
            direction === serverAnomalyDirection.up) && (
            <div styleName="input-container" automation-id="alertSettingStaticMetricValueLarger">
              <div styleName="sub-title">
                {this.props.alertType === alertTypes.anomaly.value ? (
                  <span>
                    Alert on an anomaly with direction UP if the metric value is{' '}
                    <span styleName="emphasize">{upperBoundText}</span> {this.state.upperValueText}
                  </span>
                ) : (
                  <span>
                    Alert if the metric value is <span styleName="emphasize">{upperBoundText}</span>{' '}
                    {this.state.upperValueText}
                  </span>
                )}
              </div>
              <div styleName="bounds">
                <ThresholdBounds isUpBounds bound={upperBound} onSelect={this.handleBoundChange} />

                <div styleName="input-ctrl-wrapper">
                  <SmartTooltip content={ttMesage}>
                    <input
                      type="number"
                      className={`dark-input ${isInvalid ? 'invalid' : ''}`}
                      placeholder="Value"
                      value={this.state.upperValueText}
                      onBlur={this.handleBlurMax}
                      onChange={this.handleChangeMax}
                    />
                  </SmartTooltip>
                </div>
              </div>
            </div>
          )}

          {(alertType === alertTypes.static.value ||
            direction === serverAnomalyDirection.both ||
            direction === serverAnomalyDirection.down) && (
            <div styleName="input-container" automation-id="alertSettingStaticMetricValueSmaller">
              <div styleName="sub-title">
                {this.props.alertType === alertTypes.anomaly.value ? (
                  <span>
                    Alert on an anomaly with direction DOWN if the metric value is{' '}
                    <span styleName="emphasize">{lowerBoundText}</span> {this.state.lowerValueText}
                  </span>
                ) : (
                  <span>
                    Alert if the metric value is <span styleName="emphasize">{lowerBoundText}</span>{' '}
                    {this.state.lowerValueText}
                  </span>
                )}
              </div>
              <div styleName="bounds">
                <ThresholdBounds isUpBounds={false} bound={lowerBound} onSelect={this.handleBoundChange} />

                <div styleName="input-ctrl-wrapper">
                  <SmartTooltip content={ttMesage}>
                    <input
                      type="number"
                      className={`dark-input ${isInvalid ? 'invalid' : ''}`}
                      placeholder="Value"
                      value={this.state.lowerValueText}
                      onBlur={this.handleBlurMin}
                      onChange={this.handleChangeMin}
                    />
                  </SmartTooltip>
                </div>
              </div>
            </div>
          )}
        </div>
      </ConditionContainer>
    );
  }
}

export default StaticThresholdCondition;

// eslint-disable-next-line react/no-multi-comp
class ThresholdBounds extends React.PureComponent {
  props: {
    isUpBounds: Boolean,
    bound: String,
    onSelect: Function,
  };

  render() {
    const bounds = this.props.isUpBounds ? upperBounds : lowerBounds;
    const selectedIndex = bounds.findIndex((val) => val.value === this.props.bound);

    return (
      <SelectAndt
        id={`ddl-threshold-${this.props.isUpBounds ? 'up' : 'down'}`}
        className="andt-dropdown"
        options={bounds}
        menuWidth={125}
        buttonHeight={50}
        getOptionLabel={(val) => val.text}
        getOptionValue={(val) => val.value}
        type={TYPE_NO_SEARCH}
        theme={THEME_LIGHT}
        value={bounds[selectedIndex]}
        onChange={(v) => this.props.onSelect(this.props.isUpBounds, v.value)}
        placeholder={null}
        automationId="staticThresholdCondition"
      />
    );
  }
}
