/* eslint-disable max-len */
import {get, stringifyQS} from 'common/utils/http';

const root = 'api/v1/insightsPanel';

export const getAlertsDistribution = ({payload}) => get(`${root}/alerts-distribution?${stringifyQS(payload)}`);
export const getOpenAlerts = ({payload}) => get(`${root}/alerts-open?${stringifyQS(payload)}`);
export const fetchMetricsUsage = () => get(`${root}/metrics-usage`);
export const fetchFeedbackDistribution = ({payload}) => get(`${root}/feedback-stats?${stringifyQS(payload)}`);
export const fetchAlertsTriggered = ({payload}) => get(`${root}/triggered-histogram?${stringifyQS(payload)}`);
export const fetchFeedbackPerMeasure = ({payload}) => get(`${root}/feedback-stats?${stringifyQS(payload)}`);
export const fetchNoFeedbackAlerts = ({payload}) => get(`${root}/no-feedback-alerts?${stringifyQS(payload)}`);
