// flow
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {commasSepNumber} from 'common/utils/numbers';
import * as selectors from 'insightsPanel/store/selectors';
import * as actions from 'insightsPanel/store/actions';
import {segmentClickEvent} from 'common/store/actions';
import {fadeOpacity, shouldFetchData} from 'insightsPanel/services/constants';

import './OpenAlerts.module.scss';

type PropTypes = {
  title: String,
  onFilterAlerts: Function,
};

const OpenAlerts = (props: PropTypes) => {
  const openAlerts = useSelector(selectors.getOpenAlerts);
  const openAlertsData = useSelector(selectors.getOpenAlertsData);
  const isLoading = useSelector(selectors.getIsLoadingOpenAlerts);
  const isPanelOpen = useSelector(selectors.getIsPanelOpen);
  const numLastDays = useSelector(selectors.getNumLastDays);
  const dispatch = useDispatch();
  const [showData, setShowData] = useState(false);
  const isFirstRun = useRef(true);
  let timer;

  useEffect(() => {
    const params = {
      numLastDays: numLastDays.value,
    };
    if (isPanelOpen && shouldFetchData(openAlerts)) {
      dispatch(actions.getOpenAlerts(params));
    }
  }, [isPanelOpen]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const params = {
      numLastDays: numLastDays.value,
    };
    dispatch(actions.getOpenAlerts(params));
  }, [numLastDays]);

  useEffect(() => {
    if (openAlertsData) {
      // for some reason Transition duration does not work.
      timer = setTimeout(() => setShowData(true), fadeOpacity.duration);
    }
  }, [openAlertsData]);

  useEffect(
    () => () => {
      clearTimeout(timer);
    },
    [isPanelOpen],
  );

  const segmentTracking = (name) => {
    dispatch(
      segmentClickEvent({
        category: 'Insights Panel',
        name,
      }),
    );
  };

  const clickOnStatus = () => {
    props.onFilterAlerts({status: 'OPEN'});
    segmentTracking('Open Alerts Widget - Open Alerts ');
  };

  const clickOnSeverity = () => {
    props.onFilterAlerts({status: 'OPEN', severities: 'CRITICAL'});
    segmentTracking('Open Alerts Widget - Critical Alerts');
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && openAlertsData && (
        <Transition in={showData} timeout={0}>
          {(state) => (
            <Fragment>
              <header
                styleName="header"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state], marginBottom: '15px'}}
              >
                {props.title}
              </header>
              <div
                styleName="counters-container"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}
              >
                {openAlertsData.all === 0 ? (
                  <div styleName="no-open-alerts">
                    <span>No Open Alerts</span>
                  </div>
                ) : (
                  <Fragment>
                    <SmartTooltip content="Show Open Alerts" delay={300}>
                      <div styleName="counter-wrapper" onClick={clickOnStatus}>
                        <span styleName="number">{commasSepNumber(openAlertsData.all)}</span>
                        <span styleName="text">
                          Open
                          <br />
                          Alerts
                        </span>
                      </div>
                    </SmartTooltip>
                    <SmartTooltip content="Show Critical Alerts" delay={300}>
                      <div styleName="counter-wrapper red" onClick={clickOnSeverity}>
                        <span styleName="number">{commasSepNumber(openAlertsData.critical)}</span>
                        <br />
                        <span styleName="text">
                          Critical
                          <br />
                          Alerts
                        </span>
                      </div>
                    </SmartTooltip>
                  </Fragment>
                )}
              </div>
            </Fragment>
          )}
        </Transition>
      )}
    </div>
  );
};

export default OpenAlerts;
