/* eslint-disable */

import L from 'leaflet';

// Class definition
const D3SvgOverlay = L.Layer.extend({
  version: '2.2',

  _undef(a) {
    return typeof a === 'undefined';
  },

  _options(options) {
    if (this._undef(options)) {
      return this.options;
    }
    options.zoomHide = this._undef(options.zoomHide) ? false : options.zoomHide;
    options.zoomDraw = this._undef(options.zoomDraw) ? true : options.zoomDraw;
    options.moveDraw = this._undef(options.moveDraw) ? true : options.moveDraw;
    return (this.options = options);
  },

  _disableLeafletRounding() {
    this._leaflet_round = L.Point.prototype._round;
    L.Point.prototype._round = function() {
      return this;
    };
  },

  _enableLeafletRounding() {
    L.Point.prototype._round = this._leaflet_round;
  },

  draw() {
    this._disableLeafletRounding();
    this._drawCallback(this.selection, this.projection, this.map.getZoom());
    this._enableLeafletRounding();
  },

  initialize(drawCallback, options) {
    // (Function(selection, projection)), (Object)options
    this._options(options || {});
    this._drawCallback = drawCallback;
  },

  // Handler for "viewreset"-like events, updates scale and shift after the animation
  _zoomChange(evt) {
    this._disableLeafletRounding();
    const newZoom = this._undef(evt.zoom) ? this.map._zoom : evt.zoom; // "viewreset" event in Leaflet has not zoom/center parameters like zoomanim
    this._zoomDiff = newZoom - this._zoom;
    this._scale = Math.pow(2, this._zoomDiff);
    this.projection.scale = this._scale;
    this._shift = this.map.latLngToLayerPoint(this._wgsOrigin)._subtract(this._wgsInitialShift.multiplyBy(this._scale));

    const shift = ['translate(', this._shift.x, ',', this._shift.y, ') '];
    const scale = ['scale(', this._scale, ',', this._scale, ') '];
    this._rootGroup.attr('transform', shift.concat(scale).join(''));

    if (this.options.zoomDraw && !this.options.moveDraw) {
      this.draw();
    }
    this._enableLeafletRounding();
  },

  onAdd(map) {
    this.map = map;
    const _layer = this;

    // SVG element
    if (L.version < '1.0') {
      map._initPathRoot();
      this._svg = d3.select(map._panes.overlayPane).select('svg');
      this._rootGroup = this._svg.append('g');
    } else {
      this._svg = L.svg();
      map.addLayer(this._svg);
      this._rootGroup = d3.select(this._svg._rootGroup).classed('d3-overlay', true);
    }
    this._rootGroup.classed('leaflet-zoom-hide', this.options.zoomHide);
    this.selection = this._rootGroup;

    // Init shift/scale invariance helper values
    this._pixelOrigin = map.getPixelOrigin();
    this._wgsOrigin = L.latLng([0, 0]);
    this._wgsInitialShift = this.map.latLngToLayerPoint(this._wgsOrigin);
    this._zoom = this.map.getZoom();
    this._shift = L.point(0, 0);
    this._scale = 1;

    // Create projection object
    this.projection = {
      latLngToLayerPoint(latLng, zoom) {
        zoom = _layer._undef(zoom) ? _layer._zoom : zoom;
        const projectedPoint = _layer.map.project(L.latLng(latLng), zoom)._round();
        return projectedPoint._subtract(_layer._pixelOrigin);
      },
      latLngToLayerPointNoRounding(latLng, zoom) {
        zoom = _layer._undef(zoom) ? _layer._zoom : zoom;
        const projectedPoint = _layer.map.project(L.latLng(latLng), zoom);
        return projectedPoint._subtract(_layer._pixelOrigin);
      },
      layerPointToLatLng(point, zoom) {
        zoom = _layer._undef(zoom) ? _layer._zoom : zoom;
        const projectedPoint = L.point(point).add(_layer._pixelOrigin);
        return _layer.map.unproject(projectedPoint, zoom);
      },
      unitsPerMeter: (256 * Math.pow(2, _layer._zoom)) / 40075017,
      map: _layer.map,
      layer: _layer,
      scale: 1,
    };
    this.projection._projectPoint = function(x, y) {
      const point = _layer.projection.latLngToLayerPoint(new L.LatLng(y, x));
      this.stream.point(point.x, point.y);
    };
    this.projection.pathFromGeojson = d3.geoPath().projection(d3.geoTransform({point: this.projection._projectPoint}));

    // Compatibility with v.1
    this.projection.latLngToLayerFloatPoint = this.projection.latLngToLayerPoint;
    this.projection.getZoom = this.map.getZoom.bind(this.map);
    this.projection.getBounds = this.map.getBounds.bind(this.map);
    this.selection = this._rootGroup;

    if (L.version < '1.0') map.on('viewreset', this._zoomChange, this);

    map.on('moveend', this.draw, this);

    // Initial draw
    this.draw();
  },

  // Leaflet 1.0
  getEvents() {
    return {zoomend: this._zoomChange};
  },

  onRemove(map) {
    if (L.version < '1.0') {
      map.off('viewreset', this._zoomChange, this);
      this._rootGroup.remove();
    } else {
      this._svg.remove();
    }
    map.off('moveend', this.draw, this);
  },

  addTo(map) {
    map.addLayer(this);
    return this;
  },
});

// Factory method
const d3SvgOverlay = function(drawCallback, options) {
  return new D3SvgOverlay(drawCallback, options);
};
L.D3SvgOverlay = D3SvgOverlay;
L.d3SvgOverlay = d3SvgOverlay;

export default L;
