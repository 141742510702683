// @flow
import React from 'react';
import {Cell} from 'fixed-data-table-2';
import {omit, isFinite} from 'lodash';
import SmartTooltip from 'common/components/SmartTooltip';
import {abbrNum} from 'common/utils/numbers';

type PropTypes = {
  cellData: any,
  rowIndex: Number,
  callHeaderName: String,
  isWhat: boolean,
  isIgnored: boolean,
};

const DataCell = (props: PropTypes) => {
  let cellClass = props.isWhat ? 'what-metric ' : '';
  cellClass += props.isIgnored ? 'ignored-cell' : '';
  const automationId = `cellInfo-${props.callHeaderName}-${props.rowIndex}`;

  return (
    <Cell
      {...omit(props, 'cellData', 'isWhat', 'isIgnored', 'automationId')}
      className={cellClass}
      automation-id={automationId}
    >
      {props.isWhat ? <NumberCell {...props} /> : <DimensionCell {...props} />}
    </Cell>
  );
};

export default DataCell;

const NumberCell = (props: {cellData: any}) => {
  const displayNum = isFinite(parseFloat(props.cellData)) ? abbrNum(parseFloat(props.cellData)) : '';

  return (
    <SmartTooltip placement="left" hideIfEqualTo={parseFloat(displayNum)} content={parseFloat(props.cellData)}>
      <div>{displayNum}</div>
    </SmartTooltip>
  );
};

const DimensionCell = (props: {cellData: any}) => (
  <SmartTooltip placement="top" content={props.cellData}>
    <div className={props.cellData === null ? 'is-null' : ''}>{props.cellData === null ? 'NULL' : props.cellData}</div>
  </SmartTooltip>
);
