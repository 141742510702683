// @flow
import React, {Fragment} from 'react';
import './TopologyMap.module.scss';

const AccessNodeItem = (props: {d: Object}) => {
  const node = {...props.d};
  if (node.type === 'ABR') {
    node.interfaces = node.interfaces.filter((inter) => inter.isAnomalous || inter.isUnknownAno);
  }

  return (
    <div styleName="tp-content">
      <div styleName="row" className="ellipsis">
        <span>Device:</span>
        <span>{` ${node.hostName}`}</span>
        {node.isNodeMetric ? <div styleName="inter-indicator bad" /> : null}
      </div>
      <div styleName="row" className="ellipsis">
        <span>{`ZIPcode: ${node.zipcode}, ${node.houseNumber}${node.isHeuristicWasUsed ? ' (heuristic)' : ''}`}</span>
      </div>
      <div styleName="row" className="ellipsis">
        <span>{`Status: ${node.statusName}`}</span>
      </div>
      {node.type !== 'ABR' ? (
        <div styleName="row" className="ellipsis">
          <span>{`Ring: ${node.ringOspfAreaList.join(', ')}`}</span>
        </div>
      ) : null}
      {node.type === 'ABR' && node.anomalousOspfArea ? (
        <div styleName="row" className="ellipsis">
          <span>Ring:</span>
          <span>{` ${node.anomalousOspfArea.join(', ')}`}</span>
        </div>
      ) : null}
      {node.interfaces && node.interfaces.length ? (
        <Fragment>
          <div styleName="row" className="ellipsis">
            <span>Interfaces:</span>
          </div>
          {node.interfaces.map((inter) => {
            if (inter.isUnknownAno) {
              return (
                <div styleName="row inter-row" className="ellipsis" key={`interface-${inter.interface}`}>
                  <span>{inter.interface}</span>
                  <div styleName="inter-indicator bad" />
                </div>
              );
            }
            return (
              <div styleName="row inter-row" className="ellipsis" key={`interface-${inter.interface}`}>
                <span>{`${inter.interface} <-> ${inter.connectedHost}/${inter.connectedInterface}`}</span>
                <div styleName={`inter-indicator ${inter.isAnomalous ? 'bad' : 'good'}`} />
              </div>
            );
          })}
        </Fragment>
      ) : null}
    </div>
  );
};

const NodeListTooltipBody = (props: {nodes: Array}) =>
  props.nodes.map((n, index) => (
    <Fragment key={`nnn-${n.id}`}>
      <AccessNodeItem d={n} />
      {index + 1 !== props.nodes.length ? <div key={`sep-${n.id}`} styleName="sep" /> : null}
    </Fragment>
  ));

const TPTooltip = (props: {id: String, nodes: Array, text: String}) => {
  const isTextMode = !!props.text;

  return (
    <Fragment>
      <div id={props.id} styleName={`tp-tooltip ${isTextMode ? 'text-tooltip' : ''}`}>
        {isTextMode ? (
          <div styleName="tp-content">
            <div>{props.text}</div>
          </div>
        ) : (
          <NodeListTooltipBody nodes={props.nodes} />
        )}
      </div>
      <div styleName={`tp-arrow-wrapper ${isTextMode ? 'text-tooltip' : ''}`}>
        <div styleName="tp-arrow-down" />
      </div>
    </Fragment>
  );
};

export default TPTooltip;
