import React from 'react';
import {useField} from 'react-final-form';
import {TypographyBox} from 'common/componentsV2/boxTools';

const FieldError = ({name}: {name: string}) => {
  const {
    meta: {touched, error},
  } = useField(name);
  return touched && error ? (
    <TypographyBox variant="subtitle2" lineHeight="normal" mt={0.5} color="red.500">
      {error}
    </TypographyBox>
  ) : null;
};

export default FieldError;
