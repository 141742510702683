// @flow
import React from 'react';
import './HexagonalProgressBar.module.scss';

type PropTypes = {
  percents: number,
};

const HexagonalProgressBar = (props: PropTypes) => {
  let dashOffset = 300;
  if (props.percents) {
    dashOffset = 300 - 3 * props.percents;
  }

  return (
    <svg styleName="root" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path styleName="bar-bg" d="M50,5 L90,25 L90,75 L50,95 L10,75, L10,25 L50,5" />
      <path
        styleName="bar-progress"
        d="M50,5 L90,25 L90,75 L50,95 L10,75, L10,25 L50,5"
        style={{strokeDashoffset: dashOffset}}
      />

      <text styleName="text" fontSize="22" x="55" y="60" textAnchor="middle">
        {props.percents}%
      </text>
    </svg>
  );
};

export default HexagonalProgressBar;
