// @flow
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {isEmpty} from 'lodash';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';
import {getIsAssetsFiltersBarOpen} from 'profile/store/selectors';
import {getActiveFilters, getSelectedFilterSearchQuery} from 'assets/store/selectors';
import {setAssetsFilters, setAssetsMngFiltersIsOpen, setResetFilters} from 'assets/store/actions';
import {fetchUsers} from 'admin.users/store/actions';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import OwnerFilter from 'assets/components/filter/OwnerFilter';
import TypeFilter from 'assets/components/filter/TypeFilter';
import SearchBox from 'common/componentsV2/SearchBox';

const useStyles = makeStyles(({palette}) => ({
  filtersRow: {
    width: '100%',
    marginBottom: 20,
  },
  filtersRowHeader: {
    fontSize: 14,
    fontWeight: 400,
    marginBottom: 8,
    color: palette.gray['500'],
  },
}));

const FiltersWrapper = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isFiltersBarOpen = useSelector(getIsAssetsFiltersBarOpen);
  const activeFilters = useSelector(getActiveFilters);
  const searchQuery = useSelector(getSelectedFilterSearchQuery);

  useEffect(() => {
    dispatch(fetchUsers());
  }, []);

  const onCloseFiltersClicked = () => {
    dispatch(setAssetsMngFiltersIsOpen(false));
  };

  const onFilterChange = (queryObj) => {
    dispatch(setAssetsFilters(queryObj));
  };

  const resetFilters = () => {
    dispatch(setResetFilters());
  };

  return (
    <FiltersPanel
      isOpen={isFiltersBarOpen}
      isClearAll={!isEmpty(activeFilters)}
      onClearAll={resetFilters}
      isSaveView={false}
      isShareLink={false}
      onClose={onCloseFiltersClicked}
    >
      <Box className={classes.filtersRow}>
        <SearchBox
          onFilter={(value) => onFilterChange({searchQuery: value})}
          filter={searchQuery}
          placeHolder="Search Assets"
        />
      </Box>
      <Box className={classes.filtersRow}>
        <Box className={classes.filtersRowHeader}>Assets Type</Box>
        <TypeFilter setQueryParams={onFilterChange} />
      </Box>
      <Box className={classes.filtersRow}>
        <Box className={classes.filtersRowHeader}>Owner</Box>
        <OwnerFilter setQueryParams={onFilterChange} />
      </Box>
    </FiltersPanel>
  );
};

export default FiltersWrapper;
