// @flow
import React from 'react';
import {pollingIntervals} from 'bc/services/dataStreamService';
import {intersection} from 'lodash';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

import './SchedulerEditorModal.module.scss';

type PropTypes = {
  title: string,
  pollingInterval: number,
  allowedValues: Array,
  onChange: Function,
};

const pollingIntervalsValues = Object.values(pollingIntervals);

export default class PollingInterval extends React.PureComponent {
  props: PropTypes;

  filteredPolValues = pollingIntervalsValues.filter((interval) => {
    const allowedValues = this.props.allowedValues ? this.props.allowedValues : Object.keys(pollingIntervals);
    return intersection(allowedValues, [interval.key]).length > 0;
  });

  render() {
    const {title, pollingInterval, onChange} = this.props;
    const selectedIndex = this.filteredPolValues.findIndex((val) => val.key === pollingInterval);

    return (
      <div>
        <div styleName="titleWrapper">{title || 'Collect Files Every'}:</div>
        <div>
          <SelectAndt
            id="schedulerEditorModalPollingInterval"
            automationId="schedulerEditorModalPollingInterval"
            options={this.filteredPolValues}
            getOptionLabel={(val) => val.labelShort}
            getOptionValue={(val) => val.key}
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_NOT_HIGHLIGHTED}
            value={this.filteredPolValues[selectedIndex]}
            onChange={(val) => onChange(val.key)}
            placeholder=""
            optionHeight={40}
            buttonWidth={180}
            isClearable={false}
          />
        </div>
      </div>
    );
  }
}
