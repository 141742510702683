import React from 'react';
import FuData from './fileData/FuData';
import FuDimensionsAndMetrics from './fileDimensionsAndMetrics/FuDimensionsAndMetrics';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

import './FileUploadStreamEditor.module.scss';

const FileUploadStreamEditor = () => (
  <div styleName="root" className="shell-col">
    <StreamEditorSteps>
      <div className="shell-col">
        <StreamProperties />
        <FuData />
      </div>
      <FuDimensionsAndMetrics />
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default FileUploadStreamEditor;
