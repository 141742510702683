import React, {useState, useEffect, Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Box, makeStyles} from '@material-ui/core';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import Button, {COLORS} from 'common/componentsV2/Button';
import {palette} from 'app/styles/theme';
import {getProfileId} from 'profile/store/selectors';
import {getUsersData} from 'admin.users/store/selectors';
import {getInvModalTrigger} from '../../store/selectors';
import {postAcknowledge} from '../../store/actions';

const useActionBarStyles = makeStyles(() => ({
  iconBtn: {
    height: '36px',
    width: '36px',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  displayAck: {
    display: 'flex',
    alignItems: 'center',
    height: '36px',
    borderRadius: '6px',
    paddingRight: '10px',
    paddingLeft: '10px',
    marginRight: '12px',
    backgroundColor: palette.blue['300'],
    color: palette.blue['500'],
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 500,
  },
  displayAckIcon: {
    marginRight: '4px',
  },
}));

const AckListTooltip = ({names}: {names: Array}) => (
  <Box>
    {names.map((n) => (
      <Fragment>
        <span>{n}</span>
        <br />
      </Fragment>
    ))}
  </Box>
);

const Acknowledged = () => {
  const classes = useActionBarStyles();

  const dispatch = useDispatch();
  const profileId = useSelector(getProfileId);
  const trigger = useSelector(getInvModalTrigger);
  const users = useSelector(getUsersData);
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const [ackNameList, setAckNameList] = useState([]);
  const [ackNamesTooltip, setAckNamesTooltip] = useState(null);
  const [ackDisplayName, setAckDisplayName] = useState('');

  useEffect(() => {
    let displayName = '';
    let isMeAck = false;
    const nameList = [];
    if (trigger.stars && users.length) {
      trigger.stars.forEach((starId) => {
        const findUser = users.find((user) => user._id === starId);
        if (findUser) {
          if (findUser._id === profileId) {
            isMeAck = true;
            displayName = `${findUser.firstName} ${findUser.lastName[0]}`;
            nameList.unshift(`${findUser.firstName} ${findUser.lastName}`);
          } else {
            displayName = displayName || `${findUser.firstName} ${findUser.lastName[0]}`;
            nameList.push(`${findUser.firstName} ${findUser.lastName}`);
          }
        }
      });
    }
    setIsAcknowledged(isMeAck);
    setAckDisplayName(displayName);
    setAckNameList(nameList);
  }, [trigger, users]);

  useEffect(() => {
    if (!ackNameList.length) {
      setAckNamesTooltip(null);
    } else {
      setAckNamesTooltip(<AckListTooltip names={ackNameList} />);
    }
  }, [ackNameList]);

  const ackButtonClicked = () => {
    setIsAcknowledged((val) => {
      const newVal = !val;
      if (newVal) {
        dispatch(
          postAcknowledge({
            triggerId: trigger.id,
            action: 'add',
            userId: profileId,
          }),
        );
      } else {
        dispatch(
          postAcknowledge({
            triggerId: trigger.id,
            action: 'remove',
            userId: profileId,
          }),
        );
      }
      return newVal;
    });
  };

  return (
    <Box display="flex" alignItems="center">
      {ackDisplayName && (
        <Tooltip content={ackNamesTooltip} type={TYPES.SMALL}>
          <Box className={classes.displayAck}>
            <i className={`icon icn-general16-checkmark ${classes.displayAckIcon}`} />
            {`${ackDisplayName}${ackNameList.length === 1 ? '.' : `, +${ackNameList.length - 1}`}`}
          </Box>
        </Tooltip>
      )}
      <Tooltip content={isAcknowledged ? 'Remove Acknowledge' : 'Acknowledge this alert'} type={TYPES.SMALL}>
        <Box component="span">
          <Button
            colorSchema={isAcknowledged ? COLORS.BLUE_500 : COLORS.GHOST_BLUE}
            icon="icn-action16-acknowledge"
            extraClassName={classes.iconBtn}
            onClick={ackButtonClicked}
          />
        </Box>
      </Tooltip>
    </Box>
  );
};

export default Acknowledged;
