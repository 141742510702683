// @flow
import React from 'react';
import Box from '@material-ui/core/Box';
import './OverlayButton.module.scss';

type PropTypes = {
  iconClassName: string,
  title: Node,
  disabled: boolean,
  onClick: Function,
};

const OverlayButton = ({iconClassName, title, disabled, onClick}: PropTypes) => {
  const disabledClass = disabled ? 'disabled' : '';

  return (
    <Box
      automation-id="dataCollectorOverlayButton"
      styleName={`button ${disabledClass}`}
      className={`btn btn-flat btn-icon ${disabledClass}`}
      onClick={onClick}
    >
      <Box className={`icon ${iconClassName}`} styleName="icon" pr={0.5} />
      <Box styleName="title">{title}</Box>
    </Box>
  );
};

export default OverlayButton;
