export const ALERT_ACTION_PARAMS = {
  ACTION_ID: 'id',
  ACTION_TYPE: 'actionType',
  ACTION_NAME: 'actionName',
  BTN_NAME: 'btnName',
  DATA: 'data',
  DATA_URL: 'url',
  USED_IN_ALARMS: 'usedInAlerts',
};

export const commonStyle = {
  listHeight: 340,
  headerHeight: 70,
};

export const INITIAL_ACTION = {
  [ALERT_ACTION_PARAMS.ACTION_ID]: null,
  [ALERT_ACTION_PARAMS.ACTION_TYPE]: 'OUTSIDE_LINK',
  [ALERT_ACTION_PARAMS.ACTION_NAME]: '',
  [ALERT_ACTION_PARAMS.BTN_NAME]: '',
  [ALERT_ACTION_PARAMS.DATA]: {
    [ALERT_ACTION_PARAMS.DATA_URL]: '',
  },
  [ALERT_ACTION_PARAMS.USED_IN_ALARMS]: 0,
};

export const MENU_OPTIONS_ALERT_ACTION_ITEM = [
  {value: 'setAsPrimary', label: 'Make primary action'},
  {value: 'removeActionFromAlert', label: 'Remove from alert'},
  {value: 'editAction', label: 'Edit'},
];

export const MENU_OPTIONS_ACTION_ITEM = [
  {value: 'editAction', label: 'Edit', icon: 'icn-action16-edit'},
  {value: 'deleteAction', label: 'Delete', icon: 'icn-action16-delete'},
];

export const SORTED_OPTIONS = [
  {value: 'modified', label: 'Created Recently'},
  {value: 'usedInAlerts', label: 'Used In Alerts'},
];

export const ICONS_TYPES = {
  OUTSIDE_LINK: 'icn-nav16-goto',
};
