import {get} from 'lodash';
import {createSelector} from 'reselect';
import {getPageVisited} from 'profile/store/selectors';

export const getOnBoarding = (state) => state.onBoarding;

export const getTasks = createSelector(
  getOnBoarding,
  (onBoarding) => get(onBoarding, 'views.tasks', []),
);

// getCompllitedTask
export const getMergedTasks = createSelector(
  getTasks,
  getPageVisited,
  (tasks, pagesVisited) =>
    tasks.map((task) => ({...task, missionAccomplished: pagesVisited.some((i) => i.name === task.url)})),
);

export const getIsAllTasksAccomplished = createSelector(
  getMergedTasks,
  (tasks) => tasks.every((task) => task.missionAccomplished),
);
