// @flow
import React, {PureComponent} from 'react';
import Highlighter from 'react-highlight-words';
import AvatarBadge, {COLORS} from 'common/componentsV2/AvatarBadge';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import AddToGroupDropdown from './AddToGroupDropdown';
import './UsersTable2.module.scss';

type PropTypes = {
  user: Object,
  searchString: string,
  isAnodot: boolean,
};

export default class RowHeader extends PureComponent {
  props: PropTypes;

  state = {
    isOver: false,
  };

  handleOver = () => {
    this.setState({isOver: true});
  };

  handleOut = () => {
    this.setState({isOver: false});
  };

  render = () => {
    const {user, isAnodot, searchString} = this.props;

    return (
      <div
        styleName={['table-row-header', user.status ? 'user-disabled' : ''].join(' ')}
        role="none"
        onMouseEnter={this.handleOver}
        onMouseLeave={this.handleOut}
      >
        <div styleName="col-checkbox" />
        <div styleName="col-avatar">
          <AvatarBadge text={user.abbr} colorSchema={user.defaultGroup ? user.defaultGroup.colorSchema : COLORS.GRAY} />
        </div>
        <div styleName="col-name">
          <div className="ellipsis">
            <Highlighter autoEscape searchWords={[searchString]} textToHighlight={user.name} />
          </div>
        </div>
        <div styleName="col-role">{user.role}</div>
        {isAnodot && (
          <div styleName="col-organization">
            <Tooltip content={user.organization} type={TYPES.SMALL} delay={500}>
              <div className="ellipsis">{user.organization}</div>
            </Tooltip>
          </div>
        )}
        {!isAnodot && (!user.groups || !user.groups.length) && (
          <div styleName="col-groups">{this.state.isOver ? <AddToGroupDropdown user={user} /> : null}</div>
        )}
        {!isAnodot && user.groups && (
          <div styleName="col-groups">
            <div styleName="def-group-name" className="ellipsis">
              {user.groups}
            </div>
            {user.extraGroups && (
              <Tooltip content={user.extraGroups.tooltip} type={TYPES.SMALL} delay={500}>
                <div>{`, ${user.extraGroups.text}`}</div>
              </Tooltip>
            )}
          </div>
        )}
        <div styleName="col-email">
          <Tooltip content={user.email} type={TYPES.SMALL} delay={500}>
            <div className="ellipsis">
              <Highlighter autoEscape searchWords={[searchString]} textToHighlight={user.email} />
            </div>
          </Tooltip>
        </div>
        <div styleName="col-status">{user.status ? 'Disabled' : 'Active'}</div>
        <div styleName="col-lastActive">{user.lastActiveText}</div>
      </div>
    );
  };
}
