import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/bc.cubes/');
const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/bc.cubes/');

//* **cubes
export const fetchSchemas = macAsync('FETCH_CUBES');
export const createEditableTopCube = mac('CREATE_EDITABLE_TOP_CUBE');
export const setEditableTopCubeKeyVal = mac('SET_EDITABLE_TOP_CUBE_KEY_VAL');
export const createCube = macAsync('CREATE_CUBE');
export const updateCubes = macAsync('CREATE_CUBES');

export const setSelectedCube = mac('SET_SELECTED_CUBE');
export const setSelectedSchema = mac('SET_SELECTED_SCHEMA');
export const setSelectedSchemaKeyVal = mac('SET_SELECTED_SCHEMA_KEY_VAL'); // yet not in use

//* **cubes preview
export const fetchCubePreview = macAsync('FETCH_CUBE_PREVIEW_DATA');

//* **cubes manager
export const setCubesManagerFilterTextInput = mac('SET_CUBE_MANAGER_FILTER_TEXT_INPUT');

//* **cubes manager list
export const editCube = mac('EDIT_CUBE');
export const deleteCube = mac('DELETE_CUBE');

// Deletes all cubes in a stream - used to delete the last cube
export const deleteAllCubes = macAsync('DELETE_ALL_CUBES');
export const newCube = mac('NEW_CUBE');
export const duplicateCube = mac('DUPLICATE_CUBE');
export const setExpandedCube = mac('SET_OPEN_CUBE');
export const searchCube = mac('SEARCH_CUBE');
export const setSearchStringCube = mac('SET_SEARCH_STRING_CUBE');
export const setSortCube = mac('SET_SORT_CUBE');
export const setOrderCube = mac('SET_ORDER_CUBE');
export const updateCubeDescription = mac('UPDATE_CUBE_DESCRIPTION');
export const cancelCubeEdition = mac('CANCEL_CUBE_EDITION');
export const fetchCubesCount = macAsync('GET_CUBES_COUNT');
