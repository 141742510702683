import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';
import {composeReducers} from '../../../common/utils/reducers';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const csvFormatAnalysis = makeAsyncReducer(actions.fetchFuCsvFormatAnalysis, {defaultData: EMPTY_ARRAY});
const fileProperties = makeAsyncReducer(actions.fetchFuFileProperties, {defaultData: EMPTY_ARRAY});

export default combineReducers({
  csvFormatAnalysis,
  fileProperties,
  filePreview: composeReducers(
    makeAsyncReducer(actions.fetchFuPreview, {defaultData: EMPTY_OBJECT}),

    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_OBJECT};
        case actions.fetchFuCsvFormatAnalysis.success.TYPE:
          return {data: EMPTY_OBJECT};
        default:
          return state;
      }
    },
  ),
});
