import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    '&::-webkit-scrollbar': {
      width: '5px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      border: '1px solid transparent',
      borderRadius: '1.5px',
      backgroundClip: 'content-box',
      '&:hover': {
        borderWidth: 0,
      },
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.gray[200],
    },
  },
}));

const TinyScrollBox = React.forwardRef((props: {className: String}, ref) => {
  const classes = useStyles();
  return <Box {...props} ref={ref} className={`${classes.root} ${props.className || ''}`} />;
});

// eslint-disable-next-line react/no-multi-comp
export const TinyScrollDiv = React.forwardRef((props: {className: String}, ref) => {
  const classes = useStyles();
  return <div {...props} ref={ref} className={`${classes.root} ${props.className || ''}`} />;
});

export default TinyScrollBox;
