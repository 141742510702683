import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';

const simulationFiltersValidationResultReducer = composeReducers(
  makeAsyncReducer(actions.validateSelectedAlertConditions),
  (state = {}, {type}) => {
    switch (type) {
      default:
        return state;
    }
  },
);

export default simulationFiltersValidationResultReducer;
