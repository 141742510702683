// @flow
import React, {Fragment} from 'react';
import './StyleGuideComponents.module.scss';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';

export default class InputSection extends React.PureComponent {
  state = {
    isSmallOpen: false,
    isMediumOpen: false,
    isLargeOpen: false,
  };

  openSmall = () => {
    this.setState({
      isSmallOpen: true,
    });
  };

  openMedium = () => {
    this.setState({
      isMediumOpen: true,
    });
  };

  openLarge = () => {
    this.setState({
      isLargeOpen: true,
    });
  };

  onClose = () => {
    this.setState({
      isSmallOpen: false,
      isMediumOpen: false,
      isLargeOpen: false,
    });
  };

  render() {
    const {isSmallOpen, isMediumOpen, isLargeOpen} = this.state;

    return (
      <Fragment>
        <h4>Modal</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5 onClick={this.openSmall}>Small and static (click to open)</h5>
            <Modal isOpen={isSmallOpen} size={SIZES.SMALL} isStatic onClose={this.onClose}>
              Static modal - pure string child
            </Modal>
          </div>

          <div styleName="item">
            <h5 onClick={this.openMedium}>Medium and close button hidden (click to open)</h5>
            <Modal isOpen={isMediumOpen} onClose={this.onClose} isCloseButtonHidden>
              <h3>Medium and close button hidden (click to open)</h3>
            </Modal>
          </div>

          <div styleName="item">
            <h5 onClick={this.openLarge}>Large (click to open)</h5>
            <Modal isOpen={isLargeOpen} size={SIZES.LARGE} onClose={this.onClose}>
              <div styleName="chk-violet">styled div as child</div>
            </Modal>
          </div>
        </div>
      </Fragment>
    );
  }
}
