import {combineEpics} from 'redux-observable';
import * as actions from '../actions';

const setCustomersFilters = (action$) =>
  action$.ofType(actions.setCustomersFilters.TYPE).flatMap(({payload}) => {
    const filters = {...payload};

    return [actions.setCustomersFiltersKeyVal(filters)];
  });

const filtersEpics = combineEpics(setCustomersFilters);

export default filtersEpics;
