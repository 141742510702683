import {useEffect} from 'react';

const useClickOutside = (ref, handler, exceptions, parentsExceptions) => {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      const hasParentWithClass = (parentClass, child) => {
        let node = child.parentNode;
        while (node != null) {
          if (node.classList && node.classList.contains(parentClass)) {
            return true;
          }
          node = node.parentNode;
        }
        return false;
      };

      if (parentsExceptions && parentsExceptions.some((i) => hasParentWithClass(i, event.target))) {
        return;
      }

      if (
        exceptions &&
        exceptions.some((i) => event.target.innerText === i || event.target.id === i || event.target.className === i)
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler]);
};

export default useClickOutside;
