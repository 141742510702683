// @flow

import React from 'react';

import './ConceptPanel.module.scss';

const DeselectIcon = () => <div styleName="deselect" className="icon icn-icon-white-close" />;

const ConceptPanel = ({id, uiName, onRemoveItem}: any) => (
  <div styleName="panel" automation-id="diametricsItem">
    <span className="ellipsis">{uiName}</span>
    <button
      type="button"
      className="btn btn-flat btn-icon btn-blue-light"
      automation-id={`remove-item-${uiName}`}
      onClick={() => setTimeout(() => onRemoveItem(id), 0)}
    >
      <DeselectIcon />
    </button>
  </div>
);

export default ConceptPanel;
