import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';

const gsDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  switch (type) {
    case actions.setGoogleStorageStreamKeyVal.TYPE: {
      const payloadInner = {...payload};
      return {...redWrapper(payloadInner), isStreamNeedUpdating: true};
    }

    case actions.setGoogleStorageStreamUiKeyVal.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const streamModifications = {
        uiState: {
          ...stream.uiState,
          ...payload,
        },
      };
      return redWrapper(streamModifications);
    }

    case actions.applyGoogleStorageStreamUiState.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      return {...redWrapper({...stream, ...payload})};
    }

    default:
      return state;
  }
});

export default gsDataStreamReducer;
