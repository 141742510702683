import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {getUniqueId} from 'common/utils/guid';
import {Observable} from 'rxjs';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

const getSchemaColType = (tableType) => {
  switch (tableType) {
    case 'int':
    case 'integer':
    case 'number':
      return 'metric';
    case 'other':
      return 'dimension';
    default:
      return 'dimension';
  }
};

const fetchSalesforceObjects = makeAsyncEpic(actions.fetchSalesforceObjects, api.fetchSalesforceObjects);
const fetchSalesforceObjectData = makeAsyncEpic(actions.fetchSalesforceObjectData, api.fetchSalesforceObjectData);
const fetchSalesforceVerifyQuery = makeAsyncEpic(actions.fetchSalesforceVerifyQuery, api.fetchSalesforceVerifyQuery);

const fetchSalesforceVerifyQuerySuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchSalesforceVerifyQuery.success.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    const res = {
      query: action.payload.query,
      customQuery: true,
      schema: {
        columns: [],
        sourceColumns: [],
      },
      metrics: [],
      dimensions: [],
      timestampColumn: null,
      historicalDateRange: null,
      timeDefinition: null,
      uiState: {
        eventMetadata: action.payload,
        unAssignedColumns: [],
      },
    };

    if (stream.uiState && stream.uiState.id) {
      res.uiState.id = stream.uiState.id;
    }

    action.payload.streamSchema.forEach((field) => {
      res.schema.sourceColumns.push({
        id: field.path,
        name: field.path,
      });
      if (field.possibleTypes.includes('dimension')) {
        res.dimensions.push(field.path);
        res.schema.columns.push({
          id: getUniqueId(),
          sourceColumn: field.path,
          name: field.path,
          type: 'dimension',
        });
      } else {
        res.uiState.unAssignedColumns.push(field.path);
      }
    });
    return [actions.setSalesforceStreamKeyVal(res)];
  });

const fetchSalesforceObjectDataSuccess = (action$) =>
  action$
    .ofType(actions.fetchSalesforceObjectData.success.TYPE)
    .flatMap((action) => [actions.setSalesforceObjectData(action.payload)]);

const setSalesforceStreamQuery = (action$) =>
  action$.ofType(actions.setSalesforceStreamQuery.TYPE).flatMap((action) => {
    const res = {
      query: action.payload.query,
      customQuery: true,
      schema: {
        columns: [],
        sourceColumns: [],
      },
      metrics: [],
      dimensions: [],
      timestampColumn: null,
      historicalDateRange: null,
      uiState: {
        tablesViewsMetadata: action.payload.queryPreviewColumns,
        unAssignedColumns: [],
        selectedRadio: 'query',
      },
    };
    action.payload.queryPreviewColumns.forEach((item) => {
      if (['number', 'integer', 'int'].indexOf(item.type) !== -1) {
        // eslint-disable-next-line no-param-reassign
        item.possibleTypes = ['number', 'timestamp'];
      }
      if (item.type !== 'timestamp') {
        res.schema.columns.push({
          id: getUniqueId(),
          sourceColumn: item.name,
          name: item.name,
          type: getSchemaColType(item.type),
        });
        res.schema.sourceColumns.push({
          id: item.name,
          name: item.name,
        });
        if (item.type === 'other') {
          res.dimensions.push(item.name);
        } else {
          res.metrics.push(item.name);
        }
      } else if (item.type === 'timestamp' && !res.timestampColumn) {
        res.timestampColumn = item.name;
      } else {
        res.uiState.unAssignedColumns.push(item.name);
      }
    });

    return [actions.setSelectedStreamKeyVal(res)];
  });

const fetchSalesforceQueryPreview = (action$, {getState}) =>
  action$.ofType(actions.fetchSalesforceQueryPreview.TYPE).switchMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());

    return api
      .fetchSalesforceQueryPreview({
        payload: {
          query: action.payload.query,
          timeZone: stream.timeZone,
          dataSourceId: stream.dataSourceId,
          pollingInterval: stream.pollingInterval.toUpperCase(),
        },
      })
      .map((payload) => actions.fetchSalesforceQueryPreview.success(payload, action.meta))
      .catch((error) => Observable.of(actions.fetchSalesforceQueryPreview.failure(error, action.meta)));
  });

const salesforceEpic = combineEpics(
  fetchSalesforceObjects,
  fetchSalesforceObjectData,
  fetchSalesforceObjectDataSuccess,
  fetchSalesforceVerifyQuerySuccess,
  fetchSalesforceQueryPreview,
  fetchSalesforceVerifyQuery,
  setSalesforceStreamQuery,
);

export default salesforceEpic;
