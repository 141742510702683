// @flow
import React, {useRef, useCallback} from 'react';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import './TagChipsInput.module.scss';

const getChipItem = (value, items) => {
  const valueTrimed = value.trim();
  if (!valueTrimed) {
    return null;
  }
  if (items.some((item) => item.label === valueTrimed)) {
    return null;
  }
  return {
    label: valueTrimed,
  };
};

type PropTypes = {
  items: Array,
  value: String,
  automationId: String,
  onValueChange: Function,
  onRemoveItem: Function,
  onAddItems: Function,
};

const TagChipsInput = ({items, value, automationId, onValueChange, onRemoveItem, onAddItems}: PropTypes) => {
  const innerRef = useRef(null);

  const handleRootClick = useCallback(() => {
    if (innerRef) {
      innerRef.current.focus();
    }
  }, [innerRef]);

  const handleChange = useCallback(
    (e) => {
      onValueChange(e.target.value);
    },
    [onValueChange],
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === 'Backspace' && !e.target.value) {
        onRemoveItem(items[items.length - 1]);
      }

      if (['Enter', 'Tab', ',', ' '].includes(e.key)) {
        e.preventDefault();
        const chipItem = getChipItem(value, items);
        if (chipItem) {
          onAddItems([chipItem]);
        }
      }
    },
    [onRemoveItem, onAddItems],
  );

  const handlePaste = useCallback(
    (e) => {
      e.preventDefault();

      const paste = e.clipboardData.getData('text');
      const tags = paste.split(',');
      const tagsToAdd = [];

      if (tags.length === 1) {
        onValueChange(tags[0].trim());
      } else {
        tags.forEach((tag) => {
          const trimedTag = tag.trim();

          const chipItem = getChipItem(trimedTag, items.concat(tagsToAdd));
          if (chipItem) {
            tagsToAdd.push({
              label: trimedTag,
              value: trimedTag,
            });
          }
        });
        onAddItems(tagsToAdd);
      }
    },
    [onValueChange, onAddItems],
  );

  return (
    <div styleName="root" onClick={handleRootClick}>
      {items.map((item) => (
        <span key={`tag-chip-${item.id}`}>
          <InnerChip expression={item} onRemoveClicked={onRemoveItem} />
        </span>
      ))}
      <input
        type="text"
        ref={innerRef}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onPaste={handlePaste}
        value={value}
        automation-id={automationId}
        placeholder={!items.length ? 'Seperate tags by comma' : ''}
        styleName="input-field"
      />
    </div>
  );
};
export default TagChipsInput;
