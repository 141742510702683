// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import * as commonSelectors from 'profile/store/selectors';
import {setSelectedStreamKeyVal, setIsTimeZoneConfirmModalOpen} from 'bc/store/actions';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import TimeZone from 'bc/components/streams/editor/common/TimeZone';
import SchedularDateRange from 'bc/components/streams/editor/common/SchedularDateRange';
import PollingInterval from 'bc/components/streams/editor/common/PollingInterval';
import {allowedPollingIntervalsByFileDatePattern} from 'bc/services/genericStorageService';
import {getAllowedRangeTypesByPollingInterval} from 'bc/services/dataStreamService';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import './S3SchedularEditor.module.scss';

type PropTypes = {
  dataStream: Object,
  setSelectedStreamKeyVal: Function,
  setIsTimeZoneConfirmModalOpen: Function,
  isS3ShortIntervalEnabled: boolean,
  isBcUnlimitedFileUploadTimeRange: boolean,
};

class S3SchedularEditor extends React.PureComponent {
  props: PropTypes;

  state = {
    isTimeZoneConfirmModalOpen: false,
    newTimeZoneObj: null,
  };

  closeAbortConfirmModal = () => {
    this.setState({isTimeZoneConfirmModalOpen: false});
    this.props.setSelectedStreamKeyVal({timeZone: this.props.dataStream.timeDefinition.timeZone});
  };

  onConfirmTimeZoneChange = () => {
    this.props.setSelectedStreamKeyVal(this.state.newTimeZoneObj);
    this.setState({isTimeZoneConfirmModalOpen: false});
  };

  openConfirmTimeZoneModal = () => {
    this.props.setIsTimeZoneConfirmModalOpen(true);
    this.setState({isTimeZoneConfirmModalOpen: true});
  };

  pollingChanged = (val) => {
    this.props.setSelectedStreamKeyVal({pollingInterval: val});
  };

  dateRangeChanged = (val) => {
    this.props.setSelectedStreamKeyVal({historicalDateRange: val});
  };

  timeZoneChanged = (obj) => {
    if (
      !this.props.dataStream.timeDefinition.timeZone ||
      this.props.dataStream.timeDefinition.timeZone === obj.timeZone
    ) {
      this.props.setSelectedStreamKeyVal(obj);
    } else {
      this.setState({newTimeZoneObj: obj});
      this.openConfirmTimeZoneModal();
    }
  };

  valueChanged = (e) => {
    const val = parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0;
    this.props.setSelectedStreamKeyVal({maxMissingFiles: val});
  };

  getAllowedPollingIntervalValues = () => {
    if (this.props.isS3ShortIntervalEnabled && this.props.dataStream.fileNamePattern === 'yyyyMMddHHmm') {
      return ['daily', 'h12', 'h8', 'h6', 'h4', 'h3', 'h2', 'hourly', 'm15', 'm5', 'm1'];
    }
    return allowedPollingIntervalsByFileDatePattern[this.props.dataStream.fileNamePattern];
  };

  render() {
    return (
      <div styleName="root" automation-id="s3SchedulerEditor">
        <PanelHeader title="Schedule File Collection" />
        <div styleName="inner-root">
          <div styleName="item" automation-id="s3SchedulerEditorItem">
            <span styleName="item-title" id="editorPannel">
              Collect Files Every
            </span>
            <span styleName="item-description">Set the collection interval.</span>
            <PollingInterval
              pollingInterval={this.props.dataStream.pollingInterval}
              allowedValues={this.getAllowedPollingIntervalValues()}
              styleName="item-width"
              onChange={this.pollingChanged}
            />
          </div>

          <div styleName="item" automation-id="s3SchedulerEditorItem">
            <span className="ellipsis" styleName="item-title">
              Ignore Files Older Than
            </span>
            <div styleName="item-width">
              <SchedularDateRange
                dateRange={this.props.dataStream.historicalDateRange}
                styleName="item-width"
                title="Time span"
                isUnix
                allowedRangeTypes={getAllowedRangeTypesByPollingInterval(
                  this.props.dataStream.pollingInterval,
                  this.props.isBcUnlimitedFileUploadTimeRange,
                )}
                onChange={this.dateRangeChanged}
              />
            </div>
          </div>
        </div>
        <div styleName="inner-root">
          <div styleName="item" automation-id="s3SchedulerEditorItem">
            <span className="ellipsis" styleName="item-title">
              Files Time Zone
            </span>
            <span styleName="item-description">Process records according to this time zone.</span>
            <TimeZone
              timeZone={this.props.dataStream.timeZone}
              extraClassName="blue-style"
              styleName="item-width"
              onChange={this.timeZoneChanged}
            />
          </div>

          <div styleName="item collect-on">
            <span className="ellipsis" styleName="item-title">
              Lagging Files Policy
            </span>
            <span styleName="item-description">
              Files might arrive late.
              <br />
              Set the number of intervals to wait for lagging files.
            </span>
            <input type="number" onChange={this.valueChanged} min="0" value={this.props.dataStream.maxMissingFiles} />
          </div>
        </div>

        {this.state.isTimeZoneConfirmModalOpen && (
          <ConfirmationModal
            onClose={this.closeAbortConfirmModal}
            onConfirm={this.onConfirmTimeZoneChange}
            title="Timezone Changed"
            // eslint-disable-next-line max-len
            message="You have chosen a Timezone different than the data records’ timezone. This could result in ignored rows in the streaming process. Press OK to confirm, or cancel to revert to the records’ timezone."
            isOpen={this.state.isTimeZoneConfirmModalOpen}
            buttons={['Cancel', 'OK']}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    isS3ShortIntervalEnabled: commonSelectors.getIsS3ShortIntervalEnabled(state),
    isBcUnlimitedFileUploadTimeRange: commonSelectors.getBcUnlimitedFileUploadTimeRange(state),
  }),
  {
    setSelectedStreamKeyVal,
    setIsTimeZoneConfirmModalOpen,
  },
)(S3SchedularEditor);
