// @flow

import React from 'react';

import ConceptButton from './ConceptButton';

import './ConceptGroup.module.scss';

const ConceptGroup = ({selectedGroup, onAddItem, onRemoveItem, concepts}: any) => (
  <div styleName="group">
    {concepts
      .filter(({group}) => group === selectedGroup)
      .sort((a, b) => a.uiName.localeCompare(b.uiName))
      .map(({id, uiName, selected, disabled, description}) => (
        <ConceptButton
          key={id}
          {...{
            id,
            selected,
            disabled,
            description,
            onAddItem,
            onRemoveItem,
          }}
        >
          {uiName}
        </ConceptButton>
      ))}
  </div>
);

export default ConceptGroup;
