// @flow
import React from 'react';
import './MembersItem.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

type PropTypes = {
  item: Object, // {label, id}
  className?: String,
  labelKey?: String,
  onRemoveClick: Function,
};

export default class PropertiesSection extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    className: '',
    labelKey: 'label',
  };

  removeClicked = () => {
    this.props.onRemoveClick(this.props.item.id);
  };

  render() {
    const {item, className, labelKey} = this.props;

    return (
      <div className={className} styleName="root">
        <div styleName="top-border" />
        <span className="ellipsis">{item[labelKey]}</span>
        <Tooltip content="Remove From Group" type={TYPES.SMALL} placement="top">
          <div
            className="icon icn-general16-closea"
            styleName="remove-btn"
            onClick={this.removeClicked}
            role="presentation"
          />
        </Tooltip>
      </div>
    );
  }
}
