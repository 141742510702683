// @flow
import React, {PureComponent} from 'react';
import {alertConditionTypes} from 'alerts.management/services/alertsService';
import './AddConditionBtn.module.scss';
import SelectAndt, {DIRECTION_CENTER, THEME_LIGHT, TYPE_SIMPLE} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  availableConditions: Array,
  onSelectCondition: Function,
};

class AddConditionBtn extends PureComponent {
  props: PropTypes;

  handleDropdownSelect = (evKey) => {
    this.props.onSelectCondition(evKey, {});
  };

  getSortedConditions = () => {
    const sortedConditions = this.props.availableConditions.sort(
      (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()) || a.order,
    );
    const minPartIndex = sortedConditions.findIndex(
      (c) => c.dataObj.type === alertConditionTypes.minParticipatingMetrics,
    );
    const maxPartIndex = sortedConditions.findIndex(
      (c) => c.dataObj.type === alertConditionTypes.maxParticipatingMetrics,
    );
    if (minPartIndex >= 0 && maxPartIndex >= 0) {
      sortedConditions.splice(maxPartIndex + 1, 0, sortedConditions.splice(minPartIndex, 1)[0]);
    }
    return sortedConditions;
  };

  render() {
    return (
      <div styleName="main select-menu">
        <span styleName="title">Additional Conditions</span>
        <SelectAndt
          id="ddl-alerts-condition"
          styleName="plus-dropdown"
          options={this.getSortedConditions()}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.dataObj}
          type={TYPE_SIMPLE}
          theme={THEME_LIGHT}
          menuWidth={300}
          direction={DIRECTION_CENTER}
          onChange={(item) => this.handleDropdownSelect(item.dataObj)}
          automationId="addCondition"
          customComponent={{
            DropdownIndicator: () => <i className="icon icn-icon-tablepopup-plus" styleName="drop-icon-btn" />,
          }}
        />
      </div>
    );
  }
}

export default AddConditionBtn;
