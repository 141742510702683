import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const alertDefinitionReducer = composeReducers((state = {data: {}}, {type, payload}) => {
  switch (type) {
    case actions.setAlertDefinition.TYPE: {
      return {...state, data: payload};
    }

    default:
      return state;
  }
});

export default alertDefinitionReducer;
