import React, {PureComponent} from 'react';
import {components} from 'react-select2';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  children: any,
  data: Object,
};

export default class OptionWithIcon extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <components.Option {...this.props}>
        <div>
          <div styleName="dropdown-option-icon" className={['icon', this.props.data.icon].join(' ')} />
          {this.props.children}
        </div>
      </components.Option>
    );
  }
}
