export const CHART_TYPE = {
  LINE: 'line',
  COLUMN: 'column',
  AREA: 'area',
  SPLINE: 'spline',
};

export const STACKING = {
  NORMAL: 'normal',
  NONE: 'none',
  PERCENT: 'percent',
};

export const Y_AXIS_TYPE = {
  LOGARITHMIC: 'logarithmic',
  LINEAR: 'linear',
};
