import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/investigation/');
const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/investigation/');

/* eslint-disable import/prefer-default-export */
export const fetchTriggeredAlertApi = macAsync('FETCH_TRIGGERED_ALERT_API');
export const resetFetchTriggeredAlertApi = mac('RESET_FETCH_TRIGGERED_ALERT_API');
export const postAcknowledge = macAsync('POST_ACKNOWLEDGE');

export const setIsOpen = mac('SET_IS_OPEN');
export const setIsMinimize = mac('SET_IS_MINIMIZE');
export const setAnomalyId = mac('SET_ANOMALY_ID');
export const setTriggerId = mac('SET_TRIGGER_ID');
export const setFeedback = mac('SET_FEEDBACK');
export const deleteFeedback = mac('DELETE_FEEDBACK');
export const updateFeedback = mac('UPDATE_FEEDBACK');
export const setFeedbackRequest = macAsync('SET_FEEDBACK_REQUEST');
export const deleteFeedbackRequest = macAsync('DELETE_FEEDBACK_REQUEST');
export const updateFeedbackRequest = macAsync('UPDATE_FEEDBACK_REQUEST');
export const setInvestigationSettings = mac('SET_INVESTIGATION_SETTINGS');

export const fetchAlertMetricDataPoints = macAsync('FETCH_METRIC_DATA_POINTS');

export const setIncidentFilter = mac('SET_INCIDENT_FILTER');
export const setCorrelationsFilter = mac('SET_CORRELATIONS_FILTER');

export const fetchAnomaliesTokenMapApi = macAsync('FETCH_ANOMALIES_TOKEN_MAP_API');
export const resetFetchAnomaliesTokenMapApi = mac('RESET_FETCH_ANOMALY_METRICS_API');
export const fetchAnomaliesTokenMapApiCorrelations = macAsync('FETCH_ANOMALIES_TOKEN_MAP_API_CORRELATIONS');
export const resetFetchAnomaliesTokenMapApiCorrelations = macAsync('RESET_FETCH_ANOMALIES_TOKEN_MAP_API_CORRELATIONS');

export const fetchAnomalyMetricsApi = macAsync('FETCH_ANOMALY_METRICS_API');

export const fetchAlertMetrics = macAsync('FETCH_ALERT_METRICS_API');
export const fetchAllMetrics = macAsync('FETCH_ALL_METRICS_API');
export const fetchAnomalyMetrics = mac('FETCH_METRICS');
export const resetFetchAlertMetrics = mac('RESET_FETCH_ALERT_METRICS_API');
export const resetFetchAllMetrics = mac('RESET_FETCH_ALL_METRICS_API');

export const getAnomalyMetrics = mac('GET_ANOMALY_METRICS');
export const getMoreAnomalyMetrics = mac('GET_MORE_ANOMALY_METRICS');

export const fetchTimeLine = macAsync('FETCH_TIMELINE');
export const sendComment = macAsync('SEND_COMMENT');
