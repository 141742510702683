import {combineReducers} from 'redux';
import groupsReducer from './groupsReducer';
import usersReducer from './usersReducer';
import filtersReducer from './filtersReducer';

// views
export default combineReducers({
  groups: groupsReducer,
  users: usersReducer,
  filters: filtersReducer,
});
