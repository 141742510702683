// @flow
import React from 'react';
import {Box, useTheme, makeStyles} from '@material-ui/core';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';

const useStyles = makeStyles((theme) => ({
  option: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: 8,
    marginTop: 8,
    marginLeft: 8,
    '&:hover': {
      backgroundColor: theme.palette.gray[200],
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 32,
    height: 32,
    fill: theme.palette.gray[500],
    '&:hover': {
      fill: theme.palette.blue[500],
    },
  },
}));

const OptionComponent = ({data, isSelected}: {data: Object, isSelected: boolean}) => {
  const theme = useTheme();
  const classes = useStyles();
  const IconComponent = data.icon;
  return (
    <Box className={classes.option}>
      <Box fill={isSelected ? theme.palette.blue[500] : theme.palette.gray[500]} width={32} height={32}>
        <IconComponent />
      </Box>
    </Box>
  );
};

const IconPicker = ({value, onChange, options}: {value: String, onChange: Function, options: Array<Object>}) => {
  const classes = useStyles();
  const ButtonIcon = options.find((option) => option.value === value.value).icon;
  const buttonComponent = (
    <div className={classes.button}>
      <ButtonIcon />
    </div>
  );

  return (
    <FormDdlSelect
      options={options}
      selected={value}
      optionComponent={<OptionComponent />}
      button={buttonComponent}
      onChange={onChange}
      width={160}
      maxWidth={255}
      height={120}
      isFlexibleDDL
      buttonWidth={32}
    />
  );
};

export default IconPicker;
