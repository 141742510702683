// @flow
import React, {Fragment} from 'react';
import {debounce, intersection} from 'lodash';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';
import FormDdlMultiSelectItem from 'common/componentsV2/ddl/menuItems/FormDdlMultiSelectItem';
import FormDdlHeaderItem from 'common/componentsV2/ddl/menuItems/FormDdlHeaderItem';
import FormDdlSearchBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSearchBar';
import './MultiSelectFormDdl.module.scss';

const EMPTY_ARRAY = [];

type PropTypes = {
  options: Array,
  selectedOptions: Array, // array of selected items values
  onSelect: Function,
  componentId?: string, // used in onSelect as 2nd optional param to identify which ddl returned it
  optionValueKey?: string, // default is 'value'
  optionLabelKey?: string, // default is 'label'
  placeholder?: string,
  optionsHeader?: string,
  maxSelectedItems?: number,
  appendToElementById?: string,
};

export default class MultiSelectFormDdl extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    componentId: null,
    optionValueKey: 'value',
    optionLabelKey: 'label',
    placeholder: null,
    optionsHeader: null,
    maxSelectedItems: null,
    appendToElementById: null,
  };

  constructor(props) {
    super(props);
    const {optionValueKey, optionLabelKey, placeholder} = this.props;

    this.optionLabelKey = optionLabelKey || 'label';
    this.optionValueKey = optionValueKey || 'value';
    this.placeholder = placeholder || 'Select...';
  }

  state = {
    isOpen: false,
    checkedList: EMPTY_ARRAY,
    selectedList: EMPTY_ARRAY,
    searchVal: '',
  };

  onToggle = (isOpen) => {
    const {selectedOptions} = this.props;

    if (isOpen) {
      this.setState({
        isOpen,
        checkedList: selectedOptions || EMPTY_ARRAY,
        selectedList: selectedOptions || EMPTY_ARRAY,
      });
    } else {
      this.setState({
        isOpen,
      });
    }
  };

  onClearAllClicked = () => {
    this.setState({checkedList: EMPTY_ARRAY});
  };

  onApplyClicked = () => {
    const {onSelect, componentId} = this.props;
    const {checkedList} = this.state;

    onSelect(checkedList, componentId);

    this.setState({
      isOpen: false,
    });
  };

  onMultiItemClicked = (res) => {
    const {maxSelectedItems} = this.props;
    const {checkedList} = this.state;
    let newCheckedList = null;
    const isUnderCap = maxSelectedItems ? checkedList.length < maxSelectedItems : true;

    if (res.isSelected && isUnderCap) {
      newCheckedList = [...checkedList, res.value];
    } else {
      newCheckedList = checkedList.filter((key) => key !== res.value);
    }

    this.setState({checkedList: newCheckedList});
  };

  onSearchChanged = debounce((val) => {
    this.setState({
      searchVal: val,
    });
  }, 200);

  getButtonComponent = () => {
    const {selectedOptions, options} = this.props;

    const selectOptionsItems = options.filter((option) => selectedOptions.indexOf(option[this.optionValueKey]) !== -1);

    const selectedNameList = selectOptionsItems.map((option) => option[this.optionLabelKey]);
    const btnLabel = selectedNameList.length > 0 ? selectedNameList.join('. ') : this.placeholder;

    return (
      <div styleName="dark-green-btn">
        <span>{btnLabel}</span>
        <i className="icon icn-icon-white-dropdown" />
      </div>
    );
  };

  getPopoverComponent = () => {
    const {options, optionsHeader} = this.props;
    const {checkedList, selectedList, searchVal} = this.state;

    return (
      <Fragment>
        <FormDdlSearchBar onChange={this.onSearchChanged} />
        {optionsHeader ? <FormDdlHeaderItem label={optionsHeader} value={searchVal} /> : null}
        <div styleName="select-items-wrapper">
          {options.map((option) => (
            <FormDdlMultiSelectItem
              onClick={this.onMultiItemClicked}
              key={option[this.optionValueKey]}
              value={option[this.optionValueKey]}
              label={option[this.optionLabelKey]}
              searchString={searchVal}
              isSelected={checkedList.indexOf(option[this.optionValueKey]) !== -1}
            />
          ))}
        </div>
        <FormDdlActionBar
          onApply={this.onApplyClicked}
          onClearAll={this.onClearAllClicked}
          styleName="action-bar-position"
          isVisible
          isApplyDisabled={
            checkedList.length === selectedList.length &&
            intersection(checkedList, selectedList).length === checkedList.length
          }
          isClearAllDisabled={!checkedList.length}
        />
      </Fragment>
    );
  };

  render() {
    const {isOpen} = this.state;

    return (
      <FormDdl
        buttonComponent={this.getButtonComponent()}
        onToggle={this.onToggle}
        isOpen={isOpen}
        appendToElementById={this.props.appendToElementById}
        popoverComponent={this.getPopoverComponent()}
      />
    );
  }
}
