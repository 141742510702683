// @flow
import React from 'react';
import './VertCellHeader.module.scss';

type PropTypes = {
  type: String,
  isDisabled: boolean,
};

export const VertCellHeader = (props: PropTypes) => (
  <div styleName="header" automation-id="streamTableVertCellHeader">
    <div styleName="top" />
    <div styleName={props.isDisabled ? 'bottom disabled' : 'bottom'}>
      <div styleName={props.type === 'metric' ? 'header-sep-metric' : 'header-sep-dimension'} />
      <div styleName={`text header-${props.type}`}>{props.type === 'metric' ? 'Measures' : 'Dimensions'}</div>
    </div>
  </div>
);

export const VertCell = (props: PropTypes) => (
  <div styleName={props.isDisabled ? 'vert-cell-wrapper disabled' : 'vert-cell-wrapper'}>
    <div styleName={`vert-cell vert-cell-${props.type}`} />
  </div>
);
