import {makeAsyncEpic} from 'common/utils/simplifiedAsync';

import * as api from '../../service/api';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';

import 'rxjs/add/observable/of';

import * as actions from '../actions';

// eslint-disable-next-line import/prefer-default-export
export const fetchConfigurationFile = makeAsyncEpic(actions.fetchConfigurationFile, api.fetchConfigurationFile);
