// @flow

import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import SmartTooltip from 'common/components/SmartTooltip';

import {conceptsInConflictWith} from 'bc/store/selectors';

import {objectiveOffsetOf} from './positioning';

import './ConceptButton.module.scss';
import makeConceptTooltip from './makeConceptToolip';

type PropTypes = {
  id: string,
  selected: boolean,
  disabled: boolean,
  children: Object,
  description: string,
  onAddItem: Function,
  onRemoveItem: Function,
  doesNotWorkWith: Function,
};

class ConceptButton extends PureComponent {
  props: PropTypes;

  state = {
    isMouseOver: false,
  };

  componentDidMount() {
    const buttonEl = this.btn;
    const placement = objectiveOffsetOf(buttonEl).left < window.innerWidth / 2 ? 'right' : 'left';
    this.setState({placement});
  }

  handleMouseOver = () => {
    this.setState({isMouseOver: true});
  };

  handleMouseOut = () => {
    this.setState({isMouseOver: false});
  };

  render() {
    const {id, selected, disabled, children, description, onAddItem, onRemoveItem, doesNotWorkWith}: any = this.props;
    const {placement, isMouseOver} = this.state;
    const {handleMouseOver, handleMouseOut} = this;

    let styleName = 'button-disabled';
    if (!disabled) {
      styleName = selected ? 'button-active' : 'button-inactive';
    }
    const button = (
      <button
        automation-id={`diametricsItem-${children}`}
        type="button"
        ref={(btn) => {
          this.btn = btn;
        }}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={disabled ? undefined : () => (selected ? onRemoveItem(id) : onAddItem(id))}
        styleName={styleName}
      >
        {children}
      </button>
    );

    return isMouseOver ? (
      <SmartTooltip
        placement={placement}
        id={`tooltip-for-${id}`}
        content={makeConceptTooltip({
          id,
          description,
          disabled,
          doesNotWorkWith,
        })}
      >
        {button}
      </SmartTooltip>
    ) : (
      button
    );
  }
}

const enhance = connect((state) => ({
  doesNotWorkWith: conceptsInConflictWith(state),
}));

export default enhance(ConceptButton);
