import React from 'react';
import Button, {COLORS} from 'common/componentsV2/Button';
import {Box} from '@material-ui/core';
import Show from './Show';

const ShowMoreBlock = ({
  executeSimulation,
  isSimulation,
  currentLength,
  totalAmount,
  isInvalid,
  limit,
}: {
  executeSimulation: Function,
  isSimulation: boolean,
  currentLength: number,
  totalAmount: number,
  isInvalid: boolean,
  limit: number,
}) => {
  if (currentLength >= totalAmount) {
    return (
      <Box mb={2} flexShrink={0}>
        No more results
      </Box>
    );
  }

  if (currentLength >= limit) {
    return (
      <Box mb={2} flexShrink={0}>
        You have reached limit
      </Box>
    );
  }

  if (isSimulation && !isInvalid) {
    return (
      <Box display="flex" justifyContent="center" m={4} flexShrink={0} className="showMoreArea">
        <Button
          colorSchema={COLORS.BLUE_500}
          text="Show More Metrics"
          onClick={() => executeSimulation(currentLength, 40)}
        />
      </Box>
    );
  }

  return (
    <Box display="flex" justifyContent="center" m={4} flexShrink={0} className="showMoreArea">
      <Show />
    </Box>
  );
};

export default ShowMoreBlock;
