import React, {useCallback, useState} from 'react';
import {isEmpty} from 'lodash';
import {useField} from 'react-final-form';
import MailChipsInput from 'common/componentsV2/MailChipsInput';

const EMPTY_ARRAY = [];

const validate = (value, values) => {
  const errorItem = value.find((item) => item.error);
  if (errorItem) {
    return errorItem.error;
  }
  if (isEmpty(values.email) && isEmpty(values.members)) {
    return 'At least one member or email is mandatory';
  }
  return undefined;
};

const Email = () => {
  const [inputValue, setInputValue] = useState('');

  const {
    input: {value, onChange, onBlur},
    meta,
  } = useField('email', {validate, defaultValue: EMPTY_ARRAY});

  const addMailChip = useCallback(
    (item) => {
      setInputValue('');
      onChange([...value, item]);
    },
    [value, onChange, setInputValue],
  );

  const removeMailChip = useCallback(
    (item) => {
      onChange(value.filter((email) => email.value !== item.value));
    },
    [onChange, value],
  );

  return (
    <div>
      <div>Add by Email address</div>
      <MailChipsInput
        fullWidth
        value={inputValue}
        items={value}
        automationId="addUsersEmails"
        onAddItem={addMailChip}
        onRemoveItem={removeMailChip}
        onValueChange={setInputValue}
        onBlur={onBlur}
      />
      {meta.touched && meta.error && <div className="color_red-500 fontWeight_500">{meta.error}</div>}
    </div>
  );
};

export default Email;
