import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

const fetchCoralogixMetrics = makeAsyncEpic(actions.fetchCoralogixMetrics, api.fetchCoralogixMetrics);

const fetchCoralogixLabels = makeAsyncEpic(actions.fetchCoralogixLabels, api.fetchCoralogixLabels);

const fetchCoralogixMeasures = makeAsyncEpic(actions.fetchCoralogixMeasures, api.fetchCoralogixMeasures);

const setCoralogixLabels = (action$) =>
  action$
    .ofType(actions.fetchCoralogixLabels.success.TYPE)
    .flatMap((action) => [actions.setCoralogixLabels(action.payload)]);

const setCoralogixMeasures = (action$) =>
  action$
    .ofType(actions.fetchCoralogixMeasures.success.TYPE)
    .flatMap((action) => [actions.setCoralogixMeasures(action.payload)]);

const applyCoralogixStreamFilters = (action$, {getState}) =>
  action$.ofType(actions.applyCoralogixStreamFilters.TYPE).flatMap(() => {
    const stream = selectors.getSelectedDataStream(getState());
    const res = {
      filter: [],
    };

    stream.uiState.uiFilters.forEach((filter) => {
      if (filter.label !== '') {
        if (filter.selectedValue.length > 1) {
          const filterValKey = filter.isInclude ? 'values' : 'notValues';
          res.filter.push({
            key: filter.label,
            [filterValKey]: filter.selectedValue,
          });
        } else {
          const filterValKey = filter.isInclude ? 'value' : 'notValue';
          res.filter.push({
            key: filter.label,
            [filterValKey]: filter.selectedValue[0] || '',
          });
        }
      }
    });

    return [actions.setSelectedStreamKeyVal(res)];
  });

const coralogixEpic = combineEpics(
  fetchCoralogixMetrics,
  fetchCoralogixLabels,
  fetchCoralogixMeasures,
  setCoralogixLabels,
  setCoralogixMeasures,
  applyCoralogixStreamFilters,
);

export default coralogixEpic;
