// @flow
import React, {Fragment} from 'react';
import './EditCustomerForm.module.scss';
import {Field, useField, useForm} from 'react-final-form';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import {SELF_SERVICE_PACKAGE} from 'admin.customers/services/customersService';

type ConditionPropTypes = {
  when: String,
  is: Boolean,
  children: React.Node,
};
const Condition = ({when, is, children}: ConditionPropTypes) => (
  <Field name={when} subscription={{value: true}}>
    {({input: {value}}) => (value === is ? children : null)}
  </Field>
);

const getSelectedValue = (val) => {
  return SELF_SERVICE_PACKAGE.find((option) => option.id === val);
};

const EditCustomerFormSierra = () => {
  const {
    input: {value: isNew},
  } = useField('isNew');
  const form = useForm();
  const toggleSelfService = (selfService) => {
    // Clear package if self service is turned off
    if (selfService.value) {
      form.change('customer.settings.sierra.selfServicePackage', null);
    } else {
      // Clear Google OAuth
      form.change('customer.settings.auth.oauth.google.enable', false);
    }
    selfService.onChange(!selfService.value);
  };
  return (
    <Fragment>
      <div styleName="title">Sierra</div>
      <Field name="customer.settings.sierra.selfService">
        {({input}) => (
          <div styleName="row">
            <div styleName="label">Self Service</div>
            <ToggleSwitch
              name={input.name}
              theme="blue"
              isChecked={input.value}
              disabled={!isNew && !input.value}
              onToggle={() => {
                toggleSelfService(input);
              }}
            />
          </div>
        )}
      </Field>
      <Condition when="customer.settings.sierra.selfService" is>
        <Field name="customer.settings.sierra.selfServicePackage">
          {({input}) => (
            <div styleName="row">
              <div styleName="label">Self Service Package</div>
              <SelectAndt
                automationId="selectSelfServicePackage"
                type={TYPE_NEW_NO_SEARCH}
                theme={THEME_NOT_HIGHLIGHTED}
                onChange={(opt) => {
                  input.onChange(opt.id);
                }}
                value={getSelectedValue(input.value)}
                options={SELF_SERVICE_PACKAGE}
                placeholder="Required"
                buttonWidth={230}
                optionHeight={40}
                minMenuHeight={120}
                getOptionLabel={(val) => val.text}
                getOptionValue={(val) => val.id}
              />
            </div>
          )}
        </Field>
      </Condition>
    </Fragment>
  );
};
export default EditCustomerFormSierra;
