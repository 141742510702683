import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';
import {composeReducers} from '../../../common/utils/reducers';

const EMPTY_ARRAY = [];

export default combineReducers({
  fetchLookupTables: composeReducers(
    makeAsyncReducer(actions.fetchLookupTables, {defaultData: EMPTY_ARRAY}),

    (state, {type, payload}) => {
      switch (type) {
        case actions.fetchLookupTablePreview.success.TYPE:
          return {...state, payload};
        case actions.createLookupTable.success.TYPE:
          return {...state, data: [...state.data, payload]};
        case actions.deleteLookupTable.success.TYPE:
          return {...state, data: state.data.filter((i) => i.id !== payload)};
        case actions.updateLookupTable.success.TYPE:
          return {...state, data: [payload, ...state.data.filter((i) => i.id !== payload.id)]};
        default:
          return state;
      }
    },
  ),
  createLookupTable: composeReducers(makeAsyncReducer(actions.createLookupTable, {defaultData: EMPTY_ARRAY})),
  deleteLookupTable: composeReducers(makeAsyncReducer(actions.deleteLookupTable, {defaultData: EMPTY_ARRAY})),
  updateLookupTable: composeReducers(makeAsyncReducer(actions.updateLookupTable, {defaultData: EMPTY_ARRAY})),
  fetchLookupTablePreview: composeReducers(
    makeAsyncReducer(actions.fetchLookupTablePreview, {defaultData: EMPTY_ARRAY}),
  ),
});
