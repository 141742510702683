import React, {useMemo} from 'react';
import Select from 'react-select2';
import {DIRECTION_LEFT, THEME_TRANSPARENT} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import OptionWithIcon from 'common/componentsV2/ddl/selectAndt/innerComponents/OptionWithIcon';
import * as style from 'common/componentsV2/ddl/selectAndt/SelectAndtStyling';
import Control from 'common/componentsV2/ddl/selectAndt/innerComponents/Control';
import MenuList from 'common/componentsV2/ddl/selectAndt/innerComponents/MenuList';

const DropdownIndicator = (p) => (
  <div className="display_flex alignItems_center height_18">
    <i {...p.innerProps} className="icon icn-general16-3dot" />
  </div>
);

const components = {
  Control,
  MenuList,
  Option: OptionWithIcon,
  DropdownIndicator,
};

const getIsOptionDisabled = (option) => {
  const optionHeaderTypesArr = ['HEADER', 'header'];
  return (
    option.disabled || option.isHeader || optionHeaderTypesArr.indexOf(option.type) !== -1 || option.category === true
  );
};

const ThreeDotsDropDown = ({
  options,
  onChange,
  menuWidth,
  menuPortalTarget,
}: {
  options: Array,
  onChange: Function,
  menuWidth: string,
  menuPortalTarget: React.Node,
}) => {
  const styles = useMemo(() => style.simpleStyle(THEME_TRANSPARENT, DIRECTION_LEFT, menuWidth, undefined), [menuWidth]);
  return (
    <Select
      isSearchable={false}
      className="select-menu"
      styles={styles}
      components={components}
      menuPlacement="auto"
      minMenuHeight={200}
      maxMenuHeight={300}
      maxHeight={300}
      optionHeight={40}
      isOptionDisabled={getIsOptionDisabled}
      hideSelectedOptions={false}
      animationDuration={1}
      captureMenuScroll
      menuShouldScrollIntoView={false}
      options={options}
      onChange={onChange}
      menuPortalTarget={menuPortalTarget || null}
    />
  );
};

export default React.memo(ThreeDotsDropDown);
