import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {composeReducers} from 'common/utils/reducers';
import * as actions from 'topologyGeneral/store/actions';

const EMPTY_OBJECT = {};

const dataReducer = combineReducers({
  fetchTopologyMapRegions: makeAsyncReducer(actions.fetchTopologyMapRegions, {
    defaultData: EMPTY_OBJECT,
  }),
  fetchTopologyMapRegionSitesList: makeAsyncReducer(actions.fetchTopologyMapRegionSitesList, {
    defaultData: EMPTY_OBJECT,
    shouldDestroyData: false,
  }),
  fetchTopologyAlerts: makeAsyncReducer(actions.fetchTopologyAlerts, {
    defaultData: EMPTY_OBJECT,
  }),
  fetchTopologyAnomalies: makeAsyncReducer(actions.fetchTopologyAnomalies, {
    defaultData: EMPTY_OBJECT,
  }),
  fetchMetricDataPoints: composeReducers((state = {}, {type, payload, meta}) => {
    switch (type) {
      case actions.getTopologyAlerts.TYPE: {
        return EMPTY_OBJECT;
      }

      case actions.getTopologyAnomalies.TYPE: {
        return EMPTY_OBJECT;
      }

      case actions.fetchMetricDataPoints.TYPE: {
        return {
          ...state,
          [meta.chartId]: {
            ...state[meta.chartId],
            isLoading: true,
          },
        };
      }

      case actions.fetchMetricDataPoints.success.TYPE: {
        return {
          ...state,
          [meta.chartId]: {
            ...state[meta.chartId],
            isLoading: false,
            data: payload,
          },
        };
      }

      case actions.fetchMetricDataPoints.failure.TYPE: {
        return {
          ...state,
          [meta.chartId]: {
            ...state[meta.chartId],
            isLoading: false,
            data: null,
            error: payload,
          },
        };
      }

      default:
        return state;
    }
  }),
});

export default dataReducer;
