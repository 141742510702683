import {combineReducers} from 'redux';
import data from './dataReducer';
import expressionBuilder from './expressionBuilderReducer';
import chartDisplayProperties from './chartDisplayPropertiesReducer';
import viewState from './viewState';

export default combineReducers({
  views: combineReducers({
    viewState,
    expressionBuilder,
    chartDisplayProperties,
  }),
  data,
});
