// @flow
import React, {memo} from 'react';
import PropTypes from 'prop-types';
import {palette} from 'app/styles/theme';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {getFormattedDateFull, getFormattedTime} from 'common/utils/dateService';

const Date = ({startDate, timeZoneName}: PropTypes) => {
  const formattedDate = getFormattedDateFull(startDate, timeZoneName);
  const time = getFormattedTime(startDate, timeZoneName);

  return (
    <SmartTooltip content={formattedDate}>
      <span className="ellipsis" style={{color: palette.gray['400'], cursor: 'default'}}>
        {time}
      </span>
    </SmartTooltip>
  );
};

Date.propTypes = {
  startDate: PropTypes.number.isRequired,
  timeZoneName: PropTypes.string.isRequired,
};

export default memo(Date);
