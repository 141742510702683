// @flow
import React from 'react';
import './Loader.module.scss';

type PropTypes = {
  value: String,
  className: String,
  size: String,
};

const Loader = (props: PropTypes) => {
  let size = '';
  switch (props.size) {
    case 'small':
      size = ' loader-sm';
      break;
    case 'large':
      size = ' loader-lg';
      break;
    case 'extra-large':
      size = ' loader-xlg';
      break;
    default:
  }

  return (
    <div styleName={`wrapper ${size}`} className={props.className ? props.className : ''} {...props}>
      <span styleName="text">{props.value}</span>
      <div className={`preloader loading ${size}`}>
        <span className="slice" />
        <span className="slice" />
        <span className="slice" />
        <span className="slice" />
        <span className="slice" />
        <span className="slice" />
      </div>
    </div>
  );
};

export default Loader;
