// @flow
import React from 'react';
import {Modal} from 'react-bootstrap';
import './ErrorModal.module.scss';

type PropTypes = {isOpen: boolean, title: string, errorMsg: string, children: any, onClose: Function};

const ErrorModal = (props: PropTypes) => (
  <Modal show={props.isOpen} dialogClassName="bc" onHide={props.onClose}>
    <Modal.Header bsClass="bc-modal-header">
      <Modal.Title>{props.title}</Modal.Title>
      <button type="button" className="btn btn-flat btn-icon-36 btn-secondary" onClick={props.onClose}>
        <i className="icon icn-icon-table-delete" />
      </button>
    </Modal.Header>

    <Modal.Body>
      <div className="modal-body-message">
        {props.errorMsg}
        {props.children}
      </div>
    </Modal.Body>

    <Modal.Footer>
      <button type="button" className="btn btn-flat btn-primary" onClick={props.onClose}>
        OK
      </button>
    </Modal.Footer>
  </Modal>
);

export default ErrorModal;
