// @flow
import React from 'react';
import DropdownButton from './DropdownButton';
import './DropdownColumn.module.scss';

type PropTypes = {
  title: String,
  colId: String,
  originalTitle: String,
  hasDropdown: Boolean,
  children: Node,
  isDropdownVisible: Function,
  renameTitle: Function,
  className: String,
  type: String,
  isHidden: Boolean,
};

class DropdownColumn extends React.PureComponent {
  props: PropTypes;

  state = {titleReadOnly: true, value: this.props.title};

  titleClicked = (e) => {
    this.setState({titleReadOnly: false});
    e.target.select();
  };

  titleBlured = () => {
    if (!this.state.titleReadOnly) {
      this.setState({titleReadOnly: true});
      if (!this.state.value) {
        const title = this.props.title || this.props.originalTitle;
        this.setState({value: title});
        this.props.renameTitle({colId: this.props.colId, key: 'name', value: title});
        return;
      }

      if (this.state.value !== this.props.title) {
        const runStreamPreview =
          this.state.value[0] !== this.props.title[0] && (this.state.value[0] === '#' || this.props.title[0] === '#');
        this.props.renameTitle(
          {colId: this.props.colId, key: 'name', value: this.state.value.trim()},
          {runStreamPreview},
        );
      }
    }
  };

  inputKeyUp = (e) => {
    if (e.keyCode === 13) {
      e.target.blur();
    }
  };

  render() {
    const {title, originalTitle, hasDropdown, children, isDropdownVisible, className, type, isHidden} = this.props;
    const notOriginalTitle = originalTitle && title && title !== originalTitle;
    let inputStyle = notOriginalTitle ? `title not-original-${type}` : 'title';
    inputStyle = isHidden ? 'title hidden' : inputStyle;

    return (
      <div className={className} automation-id="dataCollectorColumn">
        {type === 'date' ? (
          <span>{this.state.value}</span>
        ) : (
          <input
            type="text"
            automation-id="dataCollectorDropdownColumnTextbox"
            onClick={(e) => this.titleClicked(e)}
            value={this.state.value}
            onChange={(e) => this.setState({value: e.target.value})}
            onBlur={() => this.titleBlured()}
            onKeyUp={(event) => this.inputKeyUp(event)}
            readOnly={this.state.titleReadOnly}
            className="ellipsis"
            styleName={inputStyle}
          />
        )}
        {notOriginalTitle && (
          <span className="ellipsis" styleName="origin">
            Origin: {originalTitle}
          </span>
        )}
        {hasDropdown && this.state.titleReadOnly && (
          <DropdownButton isDropdownVisible={isDropdownVisible}>{children}</DropdownButton>
        )}
      </div>
    );
  }
}

export default DropdownColumn;
