import React from 'react';
import FacebookAdsData from './data/FacebookAdsData';
import FacebookAdsSchedular from './schedular/FacebookAdsSchedular';
import DimensionsAndMetrics from './dimensionsAndMetrics/DimensionsAndMetrics';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

const FacebookAdsStreamEditor = () => (
  <div automation-id="googleAdsStreamEditor" className="shell-col">
    <StreamEditorSteps>
      <FacebookAdsData />
      <DimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <FacebookAdsSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default FacebookAdsStreamEditor;
