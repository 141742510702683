import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useTheme, makeStyles} from '@material-ui/core/styles';
import {Box, fade} from '@material-ui/core';
import {segmentClickEvent} from '../store/actions';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexBasis: '100%',
    margin: 2,
    borderRadius: 4,
    padding: 4,
    fontWeight: 500,
  },
}));

type PropTypes = {
  items: Array,
  value: String,
  onChange: Function,
  automationId: string,
  height: number,
};

const IconTabsBar = ({items, automationId, value, onChange, height = 78}: PropTypes) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = useStyles();

  const handleClick = useCallback(
    (item) => {
      dispatch(segmentClickEvent({type: 'click', name: item.label}));
      onChange(item.value);
    },
    [onChange],
  );

  return (
    <Box height={height + 8} borderRadius={6} bgcolor="gray.200" padding={0.5} width={1}>
      <Box m={-0.25} display="flex">
        {items.map((item, index) => {
          const isItemActive = value === item.value;
          return (
            <Box
              automation-id={`${automationId}_${index}`}
              onClick={() => handleClick(item)}
              key={item.value}
              className={classes.wrapper}
              height={height}
              bgcolor={isItemActive ? fade(theme.palette.blue[500], 0.7) : 'transparent'}
              color={isItemActive ? theme.palette.white[500] : theme.palette.gray[500]}
              css={{
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: isItemActive ? fade(theme.palette.blue[500], 0.7) : theme.palette.blue[300],
                },
              }}
            >
              {isItemActive ? <item.onImage /> : <item.offImage />}
              <Box mt={1}>{item.label}</Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default IconTabsBar;
