// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {SITE_TYPES} from 'topologyGeneral/components/mapNodeIcons/SiteSvg';
import {getSiteCentralIcon, useTooltipStyles, MAP_ITEMS_COLOR_PALETTE} from 'topologyGeneral/services/mapService';

const CellsInterfacesSection = ({items}: {items: Array}) => {
  if (!items || !items.length) {
    return null;
  }

  const classes = useTooltipStyles();

  const getCellItem = (cell) => {
    return (
      <Box display="flex" alignItems="center">
        <Box
          className={classes.statusIndicator}
          bgcolor={cell.isError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
          mr={0.5}
        >
          &nbsp;
        </Box>
        <Box component="span" className={classes.title14Normal}>
          {cell.name}
        </Box>
      </Box>
    );
  };

  const rows = [];
  const totalCells = items.length;
  rows.push(
    <Box display="flex" mt={1} key="n-t-row1">
      {items.slice(0, 3).map((cell, index) => {
        return (
          <Box
            className={classes.nodeItemWrapper}
            pr={index === 2 ? 0 : 0.75}
            flexGrow={index === 2 ? 0 : 1}
            key={`t-c-${cell.id}`}
          >
            {getCellItem(cell)}
          </Box>
        );
      })}
    </Box>,
  );
  if (totalCells > 3) {
    if (totalCells < 7) {
      rows.push(
        <Box display="flex" mt={1} key="n-t-row1">
          {items.slice(3, 6).map((cell, index) => {
            return (
              <Box
                className={classes.nodeItemWrapper}
                pr={index === 5 ? 0 : 0.75}
                flexGrow={index === 5 ? 0 : 1}
                key={`t-c-${cell.id}`}
              >
                {getCellItem(cell)}
              </Box>
            );
          })}
        </Box>,
      );
    } else if (totalCells > 6) {
      rows.push(
        <Box display="flex" mt={1} key="n-t-row2">
          {items.slice(3, 5).map((cell) => {
            return (
              <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1} key={`t-c-${cell.id}`}>
                {getCellItem(cell)}
              </Box>
            );
          })}
          <Box className={classes.nodeItemWrapper}>
            <Box component="span" className={classes.title12Bold} pl={1.5}>{`+ ${totalCells - 5} More`}</Box>
          </Box>
        </Box>,
      );
    }
  }

  return <Fragment>{rows}</Fragment>;
};

const NodesTooltip = ({nodes, cells, regionName}: PropTypes) => {
  const classes = useTooltipStyles();
  const node = nodes[0];
  const isRouter = getSiteCentralIcon(node.keywords) === SITE_TYPES.router;
  const nodeCells = [...cells.topWing, ...cells.rightWing, ...cells.leftWing].filter(
    (c) => node.cellIds.indexOf(c.id) !== -1,
  );
  const nodeInterfaces = node.interfaces || [];

  return (
    <Box className="gtp-tp-wrapper" pt={1.5} pb={1.5} pl={2} pr={2} borderRadius="6px">
      <Box display="flex" mb={0.25}>
        <Box component="span" className={classes.title12BoldLight} mr={0.5}>
          {regionName}
        </Box>
      </Box>
      <Box display="flex">
        <Box component="span" className={classes.title14Bold} mr={0.5}>
          {node.name}
        </Box>
        <Box
          component="span"
          className={classes.alertsBadge}
          pr={0.75}
          pl={0.75}
          bgcolor={node.isError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
        >
          {node.alertsTotal || 0}
        </Box>
        <Box component="span" className={classes.title12BoldLight} pl={0.25}>
          Alerts
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Type
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.type || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Status
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.status || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0} flexGrow={0}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Vendor
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.vendor || '-'}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Domain
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.domain ? node.domain.join(', ') : '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Description
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.description || '-'}
            </Box>
          </Box>
        </Box>
        {isRouter ? (
          <Box className={classes.nodeItemWrapper} pr={0} flexGrow={0}>
            <Box display="flex" flexDirection="column">
              <Box component="span" className={classes.title12BoldLight}>
                IP Address
              </Box>
              <Box component="span" className={classes.title14Normal}>
                {node.ip || '-'}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={classes.nodeItemWrapper} pr={0} flexGrow={0}>
            <Box display="flex" flexDirection="column">
              <Box component="span" className={classes.title12BoldLight}>
                Related Node
              </Box>
              <Box component="span" className={classes.title14Normal}>
                {node.relatedNodeName || '-'}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {nodeCells && nodeCells.length ? (
        <Fragment>
          <Box className={classes.horizontalDivider} />
          <Box display="flex" mb={1}>
            <Box component="span" className={classes.title12Bold} mr={0.5}>
              Cells
            </Box>
          </Box>
          <CellsInterfacesSection items={nodeCells} />
        </Fragment>
      ) : null}
      {nodeInterfaces && nodeInterfaces.length ? (
        <Fragment>
          <Box className={classes.horizontalDivider} />
          <Box display="flex" mb={1}>
            <Box component="span" className={classes.title12Bold} mr={0.5}>
              Interfaces
            </Box>
          </Box>
          <CellsInterfacesSection items={nodeInterfaces} />
        </Fragment>
      ) : null}
    </Box>
  );
};

NodesTooltip.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
  cells: PropTypes.objectOf(PropTypes.any),
  regionName: PropTypes.string,
};

NodesTooltip.defaultProps = {
  cells: {
    leftWing: [],
    rightWing: [],
    topWing: [],
  },
  regionName: '',
};

export default NodesTooltip;
