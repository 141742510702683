/* eslint-disable */
import {useState, useEffect, useCallback, useMemo} from 'react';
// eslint-disable-next-line no-unused-vars
import {groupBy, flatten, omit, get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useQueryParams, NumberParam, StringParam} from 'use-query-params';
import * as dateRangeService from 'common/utils/dateRangeService';
import {makeFiltersPayload, parseFiltersPayload} from 'dashboards/utils';
import {updateDashboardUserSettings} from 'dashboards/store/actions';
import {getDashboardUserSettings} from 'dashboards/store/selectors';
import {getAutoRefreshInterval} from 'dashboards/hooks/useDashboardSync';
import {getProfileId} from '../../profile/store/selectors';

const FiltersParam = {
  encode: (filters) => {
    if (!filters.length) {
      return undefined;
    }
    return JSON.stringify(
      Object.entries(groupBy(flatten(filters), 'key')).reduce((acc, [key, item]) => {
        acc[key] = item.map((t) => t.value).join(',');
        return acc;
      }, {})
    )
      .replace('{', '')
      .replace('}', '')
      .replaceAll('":"', '"_"')
      .replaceAll(',', '.');
  },
  decode: (arrayStr) =>
    arrayStr
      ? Object.entries(JSON.parse(`{${arrayStr.replaceAll('"_"', '":"').replaceAll('.', ',')}}`)).map(([key, arr]) =>
          arr.split(',').map((value) => ({key, value}))
        )
      : [],
};

const getDateParams = (dateRange) => {
  if (dateRange.constRange === 'c') {
    return dateRange;
  }
  if (dateRange.constRange === 'r') {
    return {constRange: 'r', relativeLast: dateRange.relativeLast, relativeNext: dateRange.relativeNext};
  }
  return {
    constRange: dateRange.constRange,
    startDate: undefined,
    endDate: undefined,
    relativeLast: undefined,
    relativeNext: undefined,
  };
};

const useTileSync = ({dashboardData, tileId, dateRange, filters, timeScale}) => {
  const dispatch = useDispatch();
  const [initialTileState, setInitialTileState] = useState(null);

  const userId = useSelector(getProfileId);
  const [query, setQuery] = useQueryParams({
    constRange: StringParam,
    startDate: NumberParam,
    endDate: NumberParam,
    relativeLast: NumberParam,
    relativeNext: NumberParam,
    filters: FiltersParam,
    timeScale: StringParam,
    autoRefreshInterval: NumberParam,
    invitationId: StringParam,
  });

  const {data: dashboardUserSettings} = useSelector(getDashboardUserSettings);

  const dashboardSettings = {
    selectorsFilter: dashboardData.selectorsFilter || {selectors: []},
  };

  const initializeTile = (ignoreQuery) => {
    const computedQuery = ignoreQuery ? {} : query;
    const queryDateRange = omit(computedQuery, ['filters', 'timeScale', 'autoRefreshInterval']);
    let computedDateRange = dateRangeService.getDateValue(dashboardData.dateRange);
    if (dateRange) {
      computedDateRange = dateRange;
    }
    if (queryDateRange.constRange) {
      computedDateRange = dateRangeService.getDateValue(queryDateRange);
    }

    const queryParamsSettings = {
      selectorsFilter: computedQuery.filters ? {selectors: makeFiltersPayload(computedQuery.filters)} : {},
    };

    const computedFilters = parseFiltersPayload(
      get(dashboardSettings, 'selectorsFilter.selectors', []).map((selector) => {
        const queryLevelSelector = get(queryParamsSettings, 'selectorsFilter.selectors', []).find(
          (item) => item.name === selector.name
        );
        if (queryLevelSelector) {
          return queryLevelSelector;
        }
        const tileLevelSelector = (filters.selectors || []).find((item) => item.name === selector.name);
        if (tileLevelSelector) {
          return tileLevelSelector;
        }
        const userLevelSelector = get(dashboardUserSettings, 'selectorsFilter.selectors', []).find(
          (item) => item.name === selector.name
        );
        if (userLevelSelector) {
          return userLevelSelector;
        }
        return selector;
      })
    );

    const initial = {
      dateRange: computedDateRange,
      filters: computedFilters || [],
      timeScale: computedQuery.timeScale || timeScale,
      autoRefreshInterval: getAutoRefreshInterval(computedQuery.autoRefreshInterval),
    };
    setInitialTileState({
      timeScale: initial.timeScale,
      dateRange: initial.dateRange,
      filters: initial.filters,
      autoRefreshInterval: initial.autoRefreshInterval,
    });

    setQuery({
      ...getDateParams(initial.dateRange),
      filters: initial.filters,
      timeScale: initial.timeScale,
      autoRefreshInterval: initial.autoRefreshInterval.value,
      invitationId: computedQuery.invitationId,
    });
  };

  useEffect(() => {
    if (dashboardData && dashboardUserSettings && !initialTileState) {
      initializeTile();
    }
  }, [dashboardUserSettings, dashboardData]);

  const syncTile = useCallback(
    (values, isSubmit) => {
      setQuery({
        ...getDateParams(values.dateRange),
        timeScale: values.timeScale,
        filters: values.filters,
        autoRefreshInterval: values.autoRefreshInterval.value,
        invitationId: query.invitationId,
      });
      if (isSubmit) {
        dispatch(
          updateDashboardUserSettings({
            dashboardId: dashboardData._id,
            userId,
            ...dashboardUserSettings,
            tiles: {
              ...(dashboardUserSettings.tiles || {}),
              [tileId]: {
                dateRange: getDateParams(values.dateRange),
                selectorsFilter: filters ? {selectors: makeFiltersPayload(values.filters)} : {},
                timeScale: values.timeScale,
              },
            },
          })
        );
        /*
        dispatch(
          updateDashboard({
            ...dashboardData,
            tiles: dashboardData.tiles.map((tile) => {
              if (tile.id === tileId) {
                return {
                  ...tileDataFullSize,
                  dateRange: getDateParams(values.dateRange),
                  selectorsFilter: filters ? {selectors: makeFiltersPayload(values.filters)} : {},
                  timeScale: getTimeScale(values.timeScale),
                };
              }
              return tile;
            }),
          }),
        );
         */
      }
    },
    [tileId, dashboardData, filters, dashboardUserSettings, userId]
  );
  return {initialTileState, syncTile, initializeTile};
};

export default useTileSync;
