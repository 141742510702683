// @flow

import React from 'react';

import Loader from 'common/components/Loader';

import './simpleCollectionMng.module.scss';

type PropTypes = {
  items: Array<Object>,
  rowIdentifierName: string,
  name: string,
  onDelete: Function,
  itemNode: Node,
  titlesNode: Node,
  isLoading: boolean,
};

export default class RowsContainer extends React.PureComponent {
  props: PropTypes;

  state = {
    newItem: false,
  };

  componentDidUpdate(prevProps) {
    const {items} = this.props;

    if (prevProps.items.length && items.length > prevProps.items.length) {
      this.setNewItem(true);

      this.cancelTimeoutId = setTimeout(() => {
        this.setNewItem(false);
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.cancelTimeoutId) {
      clearTimeout(this.cancelTimeoutId);
    }
  }

  setNewItem = (val) => {
    this.setState({newItem: val});
  };

  sorting = (activeTitle) => {
    const {items} = this.props;

    const sortBy = Object.keys(activeTitle)[0];

    if (activeTitle[sortBy]) {
      items.sort((a, b) => a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase()));
    } else if (activeTitle[sortBy] === false) {
      items.sort((a, b) => b[sortBy].toLowerCase().localeCompare(a[sortBy].toLowerCase()));
    }

    this.forceUpdate();
  };

  render() {
    const {newItem} = this.state;
    const {items, rowIdentifierName, name, itemNode, titlesNode, onDelete, isLoading} = this.props;
    const titles = [
      {name: '', sortby: ''},
      {name: 'Name', sortBy: 'name'},
      {name: 'Owner', sortBy: 'owner'},
      {name: 'Image-URL', sortBy: 'imageUrl'},
    ];

    return (
      <div styleName="main-container">
        {!isLoading && (
          <div styleName="count-items">
            <span>
              Count: <b>{items.length}</b>
            </span>
          </div>
        )}

        <div styleName="titles">{React.cloneElement(titlesNode, {onSorting: this.sorting, titles})}</div>

        {isLoading ? (
          <div styleName="loader-wrapper">
            <Loader size="small" />
          </div>
        ) : (
          <ul styleName={newItem ? 'items-container new-item' : 'items-container'}>
            {items.map((item) => (
              <li key={item[rowIdentifierName]}>
                {React.cloneElement(itemNode, {...item})}
                {item.owner !== 'anodot' && (
                  <button
                    type="button"
                    className="btn btn-flat btn-icon"
                    onClick={() => onDelete(item[rowIdentifierName], item[name])}
                  >
                    <span className="x-icon-18-black" />
                  </button>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}
