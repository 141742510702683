import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/profile/');
const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/profile/');

export const setMe = mac('SET_ME');
export const fetchMe = macAsync('FETCH_ME');
export const signOut = macAsync('SIGN_OUT');

export const updateMeLastActive = macAsync('UPDATE_ME_LAST_ACTIVE');

export const updateUserSettings = macAsync('UPDATE_USER_SETTINGS');

export const updateUserNameOnly = macAsync('UPDATE_USER_NAME_ONLY');

export const updateMe = macAsync('UPDATE_ME');

export const dismissOnBoarding = mac('DISMISS_ON_BOARDING');

export const skippedMission = mac('SKIPPED_MISSION');

export const timeZoneChanged = mac('TIMEZONE-CHANGED');
