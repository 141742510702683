import React from 'react';
import IconTabsBar from 'common/componentsV2/IconTabsBar';
import {CHART_TYPE} from '../seriesPropertiesConsts';

import {ReactComponent as AreaGray} from './graph_type_area_gray.svg';
import {ReactComponent as AreaWhite} from './graph_type_area_white.svg';
import {ReactComponent as BarGray} from './graph_type_bar_gray.svg';
import {ReactComponent as BarWhite} from './graph_type_bar_white.svg';
import {ReactComponent as LineGray} from './graph_type_line_gray.svg';
import {ReactComponent as LineWhite} from './graph_type_line_white.svg';
import {ReactComponent as SplineGray} from './graph_type_spline_gray.svg';
import {ReactComponent as SplineWhite} from './graph_type_spline_white.svg';

const CHART_TYPES = [
  {
    label: 'Line',
    value: CHART_TYPE.LINE,
    onImage: LineWhite,
    offImage: LineGray,
  },
  {
    label: 'Column',
    value: CHART_TYPE.COLUMN,
    onImage: BarWhite,
    offImage: BarGray,
  },
  {
    label: 'Area',
    value: CHART_TYPE.AREA,
    onImage: AreaWhite,
    offImage: AreaGray,
  },
  {
    label: 'Spline',
    value: CHART_TYPE.SPLINE,
    onImage: SplineWhite,
    offImage: SplineGray,
  },
];

type PropTypes = {
  onChange: Function,
  chartType: String,
};

const ChartType = ({onChange, chartType}: PropTypes) => (
  <IconTabsBar automationId="alertType" value={chartType} items={CHART_TYPES} onChange={onChange} height={53} />
);

export default ChartType;
