// @flow
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useQueryParams, StringParam} from 'use-query-params';

import {getIsCustomersFiltersBarOpen} from 'profile/store/selectors';
import {setCustomersFiltersIsOpen} from 'admin.customers/store/actions';

import CustomerSearchQueryFilter from 'admin.customers/components/customerFilters/CustomerSearchQueryFilter';
import CustomerStatusFilter from 'admin.customers/components/customerFilters/CustomerStatusFilter';
import FiltersPanel from 'common/componentsV2/FiltersPanel';
import CustomerPayingFilter from 'admin.customers/components/customerFilters/CustomerPayingFilter';
import CustomerSelfServiceFilter from 'admin.customers/components/customerFilters/CustomerSelfServiceFilter';
import {DEFAULT_QUERY_PARAMS} from 'admin.customers/services/customersService';
import './CustomersFilters.module.scss';

const CustomersFilters = () => {
  const dispatch = useDispatch();
  const isFiltersBarOpen = useSelector(getIsCustomersFiltersBarOpen);
  const [, setQueryParams] = useQueryParams({
    paying: StringParam,
    selfService: StringParam,
    searchQuery: StringParam,
    status: StringParam,
  });
  const resetFilters = useCallback(() => {
    setQueryParams({
      paying: DEFAULT_QUERY_PARAMS.paying,
      selfService: DEFAULT_QUERY_PARAMS.selfService,
      searchQuery: DEFAULT_QUERY_PARAMS.searchQuery,
      status: DEFAULT_QUERY_PARAMS.status,
    });
  }, [setQueryParams]);
  const onCloseFiltersClicked = useCallback(() => {
    dispatch(setCustomersFiltersIsOpen(false));
  }, [setCustomersFiltersIsOpen]);

  return (
    <FiltersPanel
      isOpen={isFiltersBarOpen}
      onClearAll={resetFilters}
      isClearAll
      isSaveView={false}
      isShareLink={false}
      onClose={onCloseFiltersClicked}
    >
      <div styleName="filters-row">
        <CustomerSearchQueryFilter />
      </div>
      <div styleName="filters-row">
        <span styleName="filters-row-header">Status</span>
        <CustomerStatusFilter />
      </div>
      <div styleName="filters-row">
        <span styleName="filters-row-header">Paying</span>
        <CustomerPayingFilter />
      </div>
      <div styleName="filters-row">
        <span styleName="filters-row-header">Self Service</span>
        <CustomerSelfServiceFilter />
      </div>
    </FiltersPanel>
  );
};

export default CustomersFilters;
