import {combineEpics} from 'redux-observable';
import * as api from 'alerts.management/services/api';
import 'rxjs/add/observable/empty';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/Observable';
import * as actions from '../actions';

const fetchInfluencingMetricsInfo = (action$, {getState}) =>
  action$.ofType(actions.fetchInfluencingMetricsInfo.TYPE).mergeMap((action) =>
    api
      .fetchInfluencingMetricsInfo(action, getState)
      .map((payload) => actions.fetchInfluencingMetricsInfo.success(payload, action.meta))
      .catch((error) => Observable.of(actions.fetchInfluencingMetricsInfo.failure(error, action.meta))),
  );

const influencingMetricsInfoEpic = combineEpics(fetchInfluencingMetricsInfo);
export default influencingMetricsInfoEpic;
