// @flow
import React from 'react';
import './DeleteMetricsConfirmation.module.scss';

type PropTypes = {
  shouldDeleteMetrics: Boolean,
  onShouldDeleteMetricsChange: Function,
};

class DeleteMetricsConfirmation extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <div className="cb2" styleName="delete-metrics">
        <input
          type="checkbox"
          automation-id="dataCollectorDeleteMetricCheckbox"
          name="shouldDeleteMetrics"
          id="shouldDeleteMetrics"
          checked={this.props.shouldDeleteMetrics}
          onChange={this.props.onShouldDeleteMetricsChange}
        />
        <label styleName="delete-metrics-label" htmlFor="shouldDeleteMetrics">
          Do you want to delete the metrics as well?
        </label>
      </div>
    );
  }
}

export default DeleteMetricsConfirmation;
