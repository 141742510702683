// @flow
import React, {useMemo} from 'react';
import {keyBy} from 'lodash';
import ConfModal from 'common/componentsV2/modal/ConfModal';

type PropsType = {
  onClose: Function,
  onConfirm: Function,
  isOpen: boolean,
  dashboardData: Object,
  id: string,
  isLoading: boolean,
};

const DeleteTileModal = ({onClose, isOpen, dashboardData, id, onConfirm, isLoading}: PropsType) => {
  const tile = dashboardData.tiles.find((item) => item.id === id) || {title: {text: ''}};
  const alertMap = useMemo(() => keyBy(dashboardData.tileAlerts, 'tileId'), [dashboardData]);
  const {alertId} = alertMap[id] || {};

  return (
    <ConfModal
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={() => onConfirm(id)}
      confirmText={alertId ? 'Delete & Detach Alert' : 'Delete'}
      title={
        alertId ? 'This tile has an alert connected to it' : `Delete ${tile.title.text ? tile.title.text : 'tile'}?`
      }
      isAnimation={false}
      isAsync
      isLoading={isLoading}
    >
      {alertId ? (
        <div className="fontSize14 lineHeight_18 mb_5">
          {/* eslint-disable-next-line max-len */}
          Deleting this tile will detach the alert (and won’t delete it). This cannot be undone, are you sure you want
          to delete this tile?
        </div>
      ) : (
        <div className="fontSize14 lineHeight_18 mb_2">
          {/* eslint-disable-next-line max-len */}
          {`You are about to delete ${
            tile.title.text ? `'${tile.title.text}'` : 'this tile'
          }. Are you sure you want to proceed?`}
        </div>
      )}
    </ConfModal>
  );
};

export default DeleteTileModal;
