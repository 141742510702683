// @flow
import React from 'react';
import './alertContent.module.scss';

const AlertScore = (props: {value: number}) => {
  const {value} = props;
  return (
    <div styleName="score">
      <span className="ellipsis">{value}</span>
    </div>
  );
};

export default AlertScore;
