// @flow
import React from 'react';
import './Spinner.module.scss';

type PropTypes = {
  color: string,
  size: number,
};

/**
 * for none svg version use the following (like in the per app loader at index.html)
 *
 <div class="lds-spinner" style="width:100%;height:100%">
 <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
 </div>
 */

export const SIZES = {
  XX_BIG_150: 150,
  XX_BIG_100: 100,
  X_BIG_90: 90,
  BIG_60: 60,
  MEDIUM_50: 50,
  SMALL_30: 30,
};

export default class Spinner extends React.PureComponent {
  props: PropTypes;

  getRectUnit = (rotate, color, begin, key) => (
    <g key={key} transform={`rotate(${rotate} 50 50)`}>
      <rect x="46.5" y="24" rx="9.3" ry="4.8" width="7" height="12" fill={color}>
        <animate attributeName="opacity" values="1;0" keyTimes="0;1" dur="1s" begin={begin} repeatCount="indefinite" />
      </rect>
    </g>
  );

  getAllRect = (color) => {
    const ret = [];
    const total = 10;
    for (let i = 0; i < total; i++) {
      const unit = this.getRectUnit((360 / total) * i, color, `-${0.9 - i / total}s`, `key-${i}`);
      ret.push(unit);
    }
    return ret;
  };

  render() {
    const {color, size} = this.props;
    const innerSvg = this.getAllRect(color);

    return (
      <div
        style={{
          height: `${size}px`,
          width: `${size}px`,
        }}
      >
        <svg
          width={`${size}px`}
          height={`${size}px`}
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
          style={{background: 'none'}}
        >
          {innerSvg}
        </svg>
      </div>
    );
  }
}
