// @flow
import React, {PureComponent} from 'react';
import {Calendar as ReactCalender} from 'react-date-range';
import {omit} from 'lodash';
import './Calender.module.scss';

type PropTypes = {date: Date, onChange: Function};

class Calender extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.state = {
      showDateRange: props.date,
    };
  }

  showDateRangeChanged = (dateRange) => {
    this.setState({
      showDateRange: dateRange,
    });
  };

  render() {
    const props = omit(this.props, ['onShownDateChange']);

    return (
      <div styleName="root">
        <div styleName="month-yy">
          {this.state.showDateRange.toLocaleDateString('en-GB', {
            month: 'short',
            year: '2-digit',
          })}
        </div>
        <div styleName="dd-mm-yyyy">{props.date.toLocaleDateString('en-GB')}</div>
        <ReactCalender {...props} onShownDateChange={this.showDateRangeChanged} />
      </div>
    );
  }
}

export default Calender;
