export const allowedFilterKeys = {
  status: {
    all: {
      label: 'All',
      value: 'all',
    },
    enabled: {
      label: 'Enabled',
      value: 'enabled',
    },
    disabled: {
      label: 'Disabled',
      value: 'disabled',
    },
  },
};

export const DEFAULT_QUERY_PARAMS = {
  searchQuery: '',
  status: 'all',
  groups: null,
  customers: null,
  roles: null,
};

// Limit number of users per request in order to reduce opportunity for spamming/phishing
// Please note that this should match server limit on create-users API
export const MAX_USERS_FOR_INVITE = 10;
