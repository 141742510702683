// @flow
import React, {Fragment} from 'react';
import './FunctionInformation.module.scss';
import FormDdl from '../../ddl/multiSelectFormDdl/FormDdl';

type PropTypes = {
  data: Object,
};
export default class FunctionInformation extends React.PureComponent {
  props: PropTypes;

  state = {
    isOpen: false,
  };

  onToggle = () => {
    this.setState((prevState) => ({isOpen: !prevState.isOpen}));
  };

  onMouseOver = () => {
    this.setState({isOpen: true});
  };

  onMouseOut = () => {
    this.timeout = setTimeout(() => this.setState({isOpen: false}), 400);
    // this.setState({isOpen: false});
  };

  onInfoPanelOver = () => {
    window.clearTimeout(this.timeout);
  };

  onInfoPanelOut = () => {
    // this.timeout = setTimeout(() => this.setState({isOpen: false}), 400);
    this.setState({isOpen: false});
  };

  handleLinkClick = (e) => {
    window.open(this.props.data.helpResourceURI, '_blank');
    e.nativeEvent.stopImmediatePropagation();
    e.nativeEvent.stopPropagation();
    e.stopPropagation();
    return false;
  };

  render() {
    const {data} = this.props;
    return (
      <FormDdl
        overflowable
        buttonComponent={
          <div
            onMouseEnter={this.onMouseOver}
            onMouseLeave={this.onMouseOut}
            styleName="info-icon"
            className="icon icn-general16-info"
          />
        }
        popoverContainerClassName="info-panel-container"
        popoverComponent={
          <div
            styleName="info-panel"
            className="info-panel"
            onMouseEnter={this.onInfoPanelOver}
            onMouseLeave={this.onInfoPanelOut}
          >
            <div styleName="bridge" />
            <div styleName="title">{data.displayName}</div>
            <div>{data ? data.extendedDescription : ''}</div>
            {data.example && (
              <Fragment>
                <div styleName="example-title">Example:</div>
                <div styleName="example-box">{data.example}</div>
              </Fragment>
            )}
            <div onMouseUp={this.handleLinkClick} styleName="read-more">
              Learn More
            </div>
          </div>
        }
        exceptionsClickOutside={['info-panel']}
        placement="bottom"
        verticalOffset={50}
        onToggle={this.onToggle}
        position="extreme-right"
        horizontalOffset={75}
        isOpen={this.state.isOpen}
      />
    );
  }
}
