import {useEffect, useRef} from 'react';
import {isEqual} from 'lodash';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useDeepCompareEffect = (callback, deeplyNestedObject) => {
  const prevDeeplyNestedObject = usePrevious(deeplyNestedObject);
  useEffect(() => {
    if (!isEqual(prevDeeplyNestedObject, deeplyNestedObject)) {
      callback();
    }
  }, [deeplyNestedObject, prevDeeplyNestedObject]);
};

export default useDeepCompareEffect;
