import React from 'react';
import MetricsView from 'admin.cs-portal/components/views/metrics/MetricsView';
import StreamsView from 'admin.cs-portal/components/views/streams/StreamsView';
import ErrorsView from 'admin.cs-portal/components/views/errors/ErrorsView';

export const tabs = [
  {
    value: 'metrics',
    label: 'Metrics',
    componentName: 'MetricsView',
    active: true,
  },
  {
    value: 'streams',
    label: 'Streams',
    componentName: 'StreamsView',
    active: false,
  },
  {
    value: 'errors',
    label: 'Errors',
    componentName: 'ErrorsView',
    active: false,
  },
];

export const viewsToRender = {
  MetricsView: (props) => <MetricsView {...props} />,
  StreamsView: (props) => <StreamsView {...props} />,
  ErrorsView: (props) => <ErrorsView {...props} />,
};
