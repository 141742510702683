// @flow
/* eslint-disable no-shadow */
import React from 'react';

import GroupButton from './GroupButton';
import './ConceptGroups.module.scss';

const groupedConcepts = (concepts) =>
  concepts.reduce(
    // eslint-disable-next-line no-return-assign
    (result, {group, uiName}) => (
      // eslint-disable-next-line no-sequences,no-param-reassign
      (result[group] = result[group] || []), result[group].push(uiName), result
    ),
    {},
  );
const flatGroups = (groups) => Object.entries(groups).map(([group, concepts]) => ({group, concepts}));

const ConceptGroups = ({concepts, selectedGroup, setSelectedGroup}: any) => (
  <div styleName="groups">
    {flatGroups(groupedConcepts(concepts))
      .sort((a, b) => a.group.localeCompare(b.group))
      .map(({group, concepts}) => (
        <GroupButton
          key={group}
          {...{
            group,
            concepts,
            selectedGroup,
            setSelectedGroup,
          }}
        />
      ))}
  </div>
);

export default ConceptGroups;
