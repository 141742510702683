// @flow
import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Box, makeStyles} from '@material-ui/core';
import {titleTab} from 'common/componentsV2/customDateRange/CustomDateRangeModal';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import {
  predefinedPresets,
  businessPresets,
  createCustomLabel,
  createRelativeLabel,
  DEFAULT_RANGE_OPTIONS,
} from 'common/utils/dateRangeService';
import {getPredefinedDateUserSetting, userSettingIsLoading} from 'profile/store/selectors';
import AsyncButton from 'common/componentsV2/AsyncButton';
import {updatePredefinedDatesUserSettings} from 'common/components/dateTime/store/actions';

type PropsType = {
  data: Object,
  isSelected: boolean,
  isIndividual: boolean,
  list: Array,
  onChange: Function,
};

const useStyles = makeStyles(({palette}) => ({
  option: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 8,
    paddingRight: 16,
    cursor: 'default',
    '&:hover $iconClose': {
      visibility: 'visible',
    },
  },
  ellipsis: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  inner: {
    padding: '16px 5px 5px 24px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  innerOverflow: {
    flexGrow: 1,
    overflow: 'auto',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    cursor: 'pointer',
    '& i': {
      color: ({isSelected}) => (isSelected ? palette.blue[500] : palette.gray[500]),
    },
    '&:hover i': {
      color: palette.blue[500],
    },
  },
  iconClose: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    cursor: 'pointer',
    visibility: 'hidden',
  },
}));

const Option = ({data, isSelected, isIndividual = false, list, onChange}: PropsType) => {
  const classes = useStyles({isSelected});

  const handleSelect = () => {
    if (isIndividual) {
      onChange(
        list.map((d) => {
          if (d.id === data.id) {
            return {
              ...data,
              isSelected: !data.isSelected,
            };
          }
          return d;
        }),
      );
    } else if (isSelected) {
      onChange(list.filter((d) => d !== data.key));
    } else {
      onChange([...list, data.key]);
    }
  };

  const handleDelete = () => {
    onChange(list.filter((d) => d.id !== data.id));
  };

  const title =
    data.text ||
    (data.startDate && createCustomLabel(data.startDate, data.endDate)) ||
    (data.relativeLast && createRelativeLabel(data.relativeLast, data.relativeNext));

  return (
    <Box className={classes.option}>
      <Box className={classes.icon} onClick={handleSelect}>
        <i className={`icon ${isSelected ? 'icn-action16-favorite' : 'icn-action16-unfavorite'}`} />
      </Box>
      <TypographyBox variant="subtitle1" ml={0.5} className={classes.ellipsis}>
        {title}
      </TypographyBox>
      {isIndividual && (
        <Box className={classes.iconClose} onClick={handleDelete}>
          <i className="icon icn-general16-closea" />
        </Box>
      )}
    </Box>
  );
};

const TimePreset = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const dataPredefinedDatesUser = useSelector(getPredefinedDateUserSetting);
  const isLoadingUserSetting = useSelector(userSettingIsLoading);
  const [predefinedDateState, setPredefinedDateState] = useState(
    dataPredefinedDatesUser.preDefinedDates || DEFAULT_RANGE_OPTIONS,
  );
  const [customDateState, setCustomDateState] = useState(dataPredefinedDatesUser.customDates || []);

  useEffect(() => {
    setPredefinedDateState(dataPredefinedDatesUser.preDefinedDates || DEFAULT_RANGE_OPTIONS);
    setCustomDateState(dataPredefinedDatesUser.customDates);
  }, [dataPredefinedDatesUser]);
  const handleSave = () => {
    dispatch(
      updatePredefinedDatesUserSettings({
        preDefinedDates: predefinedDateState,
        customDates: customDateState,
      }),
    );
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
      {titleTab('Time Presets')}
      <TypographyBox variant="subtitle2" my={1} flexShrink={0} fontStyle="italic">
        These will show up in the time frame dropdown in dashboards
      </TypographyBox>
      <Box className={classes.inner}>
        <Box display="flex" pb={1} flexShrink={0}>
          <TypographyBox variant="hintText" width={0.45}>
            Anodot Presets
          </TypographyBox>
          <TypographyBox variant="hintText" width={0.55}>
            Your Presets
          </TypographyBox>
        </Box>
        <Box className={classes.innerOverflow}>
          <TinyScrollBox display="flex" height={1}>
            <Box width={0.45}>
              {predefinedPresets.map((o) => (
                <Option
                  key={o.key}
                  data={o}
                  list={predefinedDateState}
                  isSelected={predefinedDateState.includes(o.key)}
                  onChange={setPredefinedDateState}
                />
              ))}
              <TypographyBox variant="hintText" my={1}>
                Business Presets
              </TypographyBox>
              {businessPresets.map((o) => (
                <Option
                  key={o.key}
                  data={o}
                  list={predefinedDateState}
                  isSelected={predefinedDateState.includes(o.key)}
                  onChange={setPredefinedDateState}
                />
              ))}
            </Box>
            <Box width={0.55}>
              {customDateState.map((o) => (
                <Option
                  key={o.id}
                  data={o}
                  isSelected={o.isSelected}
                  isIndividual
                  list={customDateState}
                  onChange={setCustomDateState}
                />
              ))}
            </Box>
          </TinyScrollBox>
        </Box>
      </Box>
      <Box display="flex" alignSelf="flex-end" flexShrink={0} mt={3}>
        <AsyncButton
          colorSchema="blue.500"
          type="submit"
          onClick={handleSave}
          text="Apply"
          isLoading={isLoadingUserSetting}
        />
      </Box>
    </Box>
  );
};

export default TimePreset;
