import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const simulationFiltersIsLoadingEstimations = composeReducers((state = {isLoading: false}, {type}) => {
  switch (type) {
    case actions.setSelectedAlertMinDeltaEstimation.TYPE:
      return {...state, isLoading: false};
    case actions.setSelectedAlertMinDeltaAutoBoolVal.TYPE:
      return {...state, isLoading: true};

    default:
      return state;
  }
});

export default simulationFiltersIsLoadingEstimations;
