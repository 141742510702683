const sumOffsets = (a, b) => ({
  left: a.left + b.left,
  top: a.top + b.top,
});

export const relativeParentOf = (el) =>
  getComputedStyle(el).position === 'relative' ? el : relativeParentOf(el.offsetParent);

export const objectiveOffsetOf = (el) =>
  el
    ? sumOffsets(
        {
          left: el.offsetLeft,
          top: el.offsetTop,
        },
        objectiveOffsetOf(el.offsetParent),
      )
    : {left: 0, top: 0};
