import * as actions from '../actions';

export default (state = {}, {type, payload}) => {
  switch (type) {
    case actions.setViewStateProperty.TYPE: {
      return {
        ...state,
        ...payload,
      };
    }
    case actions.refetchExecuteData.TYPE: {
      return {
        ...state,
        refetchExecuteData: !state.refetchExecuteData,
      };
    }
    default:
      return state;
  }
};
