import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {get, isEqual, keyBy} from 'lodash';
import {immutableMergeIn, immutableSetIn} from 'common/utils/immulableHelpers';
import * as actions from '../actions';

const initialState = {dashboardData: {}, chartData: {}, chartDataLoading: {}, chartActualData: {}};
const dataReducer = composeReducers(
  {
    fetchDashboardV1: composeReducers(makeAsyncReducer(actions.fetchDashboardV1, {defaultData: null})),
    fetchDashboardListV1: composeReducers(makeAsyncReducer(actions.fetchDashboardListV1, {defaultData: null})),
    fetchDashboards: makeAsyncReducer(actions.fetchDashboards, {defaultData: null}),
    fetchDashboardsV1: makeAsyncReducer(actions.fetchDashboardsV1, {defaultData: null}),
    fetchDashboardUserSettings: makeAsyncReducer(actions.fetchDashboardUserSettings, {defaultData: null}),
    updateDashboardUserSettings: makeAsyncReducer(actions.updateDashboardUserSettings, {defaultData: null}),
    copyTile: makeAsyncReducer(actions.copyTile, {defaultData: null}),
    createDuplicateDashboards: makeAsyncReducer(actions.createDuplicateDashboard, {defaultData: null}),
    updateDashboardSettings: makeAsyncReducer(actions.updateDashboardSettings, {defaultData: null}),
    deleteDashboard: makeAsyncReducer(actions.deleteDashboard, {defaultData: null}),
    getAllFilters: makeAsyncReducer(actions.getAllFilters, {defaultData: null}),
    getAnonymousInvitation: makeAsyncReducer(actions.getAnonymousInvitation, {defaultData: null}),
    revokeAnonymousInvitations: makeAsyncReducer(actions.revokeAnonymousInvitations, {defaultData: null}),
    getTriggeredAlerts: makeAsyncReducer(actions.getTriggeredAlerts, {defaultData: null}),
    uploadDashboard: makeAsyncReducer(actions.uploadDashboard, {defaultData: null}),
    addTile: makeAsyncReducer(actions.addTile, {defaultData: null}),
  },
  // eslint-disable-next-line complexity
  (state = initialState, {type, payload, meta}) => {
    let immutableUpdateFunc;
    let newState;
    switch (type) {
      case actions.fetchDatapoints.TYPE:
        return immutableSetIn(state, ['chartDataLoading', meta.chartId, meta.expressionTreeId], true);
      case actions.fetchDatapoints.success.TYPE:
        newState = immutableSetIn(state, ['chartDataLoading', meta.chartId, meta.expressionTreeId], false);
        newState = immutableSetIn(newState, ['chartActualData', meta.chartId, meta.expressionTreeId], payload);
        immutableUpdateFunc = meta.cacheOnlyLast ? immutableSetIn : immutableMergeIn;
        return immutableUpdateFunc(newState, ['chartData', meta.chartId, meta.expressionTreeId], {
          [meta.cachingKey]: payload,
          lastKey: meta.cachingKey,
        });
      case actions.setChartActualData.TYPE:
        return immutableSetIn(state, ['chartActualData', meta.chartId, meta.expressionTreeId], payload);
      case actions.clearChartCache.TYPE:
        newState = immutableSetIn(state, ['chartActualData', meta.chartId], {});
        return immutableSetIn(newState, ['chartData', meta.chartId], {});
      case actions.fetchDashboard.TYPE:
      case actions.fetchDashboardV1.TYPE: {
        return {
          ...state,
          dashboardData: {
            ...state.dashboardData,
            [payload.id]: {
              ...(state.dashboardData[payload.id] || {}),
              isLoading: true,
            },
          },
        };
      }
      case actions.updateDashboard.TYPE: {
        return {
          ...state,
          dashboardData: {
            ...state.dashboardData,
            [payload._id]: {
              ...(state.dashboardData[payload._id] || {}),
              isLoading: true,
            },
          },
        };
      }
      case actions.fetchDashboard.success.TYPE:
      case actions.fetchDashboardV1.success.TYPE:
      case actions.setDashboardData.TYPE: {
        return {
          ...state,
          dashboardData: {
            ...state.dashboardData,
            [payload._id]: {
              isLoading: false,
              data: {
                ...(state.dashboardData[payload._id] || {}).data,
                ...payload,
              },
            },
          },
        };
      }
      case actions.updateDashboard.success.TYPE: {
        const existingTilesMap = keyBy(get(state, `dashboardData[${payload._id}].data.tiles`, []), 'id');

        return {
          ...state,
          dashboardData: {
            ...state.dashboardData,
            [payload._id]: {
              isLoading: false,
              data: {
                ...payload,
                // links to previous objects allow shallow comparison to work correct for not updated tiles after dashboard update
                tiles: payload.tiles.map((tile) =>
                  isEqual(tile, existingTilesMap[tile.id]) ? existingTilesMap[tile.id] : tile,
                ),
              },
            },
          },
        };
      }
      case actions.updateDashboardSettings.success.TYPE: {
        const id = payload._id;
        const dashboardType = meta.isV1 ? 'fetchDashboardsV1' : 'fetchDashboards';
        const transformDashboards = get(state, `${dashboardType}.data.dashboards`, []).map((dashboard) => {
          if (id === dashboard._id) {
            return payload;
          }
          return dashboard;
        });

        return {
          ...state,
          [dashboardType]: {
            ...state[dashboardType],
            data: {
              ...state[dashboardType].data,
              dashboards: transformDashboards,
            },
          },
          dashboardData: {
            ...state.dashboardData,
            [payload._id]: {
              isLoading: false,
              data: {
                ...state.dashboardData[payload._id].data,
                ...payload,
              },
            },
          },
        };
      }
      case actions.updateDashboardUserSettings.success.TYPE: {
        return {
          ...state,
          updateDashboardUserSettings: {
            isLoading: false,
            data: payload,
          },
          fetchDashboardUserSettings: {
            isLoading: false,
            data: payload,
          },
        };
      }
      case actions.createDuplicateDashboard.success.TYPE: {
        const dashboards = get(state, 'fetchDashboards.data.dashboards', []);
        return {
          ...state,
          fetchDashboards: {
            ...state.fetchDashboards,
            data: {
              ...state.fetchDashboards.data,
              dashboards: [...dashboards, payload],
            },
          },
        };
      }
      case actions.uploadDashboard.success.TYPE: {
        const dashboardType = meta.isV2 ? 'fetchDashboards' : 'fetchDashboardsV1';
        const transformDashboards = get(state, `${dashboardType}.data.dashboards`, []);
        return {
          ...state,
          [dashboardType]: {
            ...state[dashboardType],
            data: {
              ...state[dashboardType].data,
              dashboards: [...transformDashboards, payload],
            },
          },
        };
      }
      case actions.deleteDashboard.success.TYPE: {
        const id = meta.dashboardId;
        const dashboardType = meta.isV1 ? 'fetchDashboardsV1' : 'fetchDashboards';
        const transformDashboards = get(state, `${dashboardType}.data.dashboards`, []).filter(
          (dashboard) => id !== dashboard._id,
        );
        return {
          ...state,
          [dashboardType]: {
            ...state[dashboardType],
            data: {
              ...state[dashboardType].data,
              dashboards: transformDashboards,
              totalDashboards: state[dashboardType].data.totalDashboards - 1,
            },
          },
        };
      }
      case actions.setFavorite.success.TYPE: {
        const id = meta.dashboardId;
        const dashboardType = meta.isV1 ? 'fetchDashboardsV1' : 'fetchDashboards';
        const transformDashboards = get(state, `${dashboardType}.data.dashboards`, []).filter(
          (dashboard) => id !== dashboard._id,
        );
        return {
          ...state,
          [dashboardType]: {
            ...state[dashboardType],
            data: {
              ...state[dashboardType].data,
              dashboards: [...transformDashboards, payload],
            },
          },
        };
      }
      default:
        return state;
    }
  },
);

export default dataReducer;
