import {intersection, difference} from 'lodash';
import * as actions from 'admin.users/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';

const usersReducer = composeReducers(
  makeAsyncReducer(actions.fetchUsers, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  makeAsyncReducer(actions.fetchUsersEnforced, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload, meta}) => {
    /* eslint-disable no-param-reassign */
    const addUserGroup = (user, groupId) => {
      if (!user.groups || user.groups.length === 0) {
        user.groups = [groupId];
        user.defaultGroup = groupId;
      } else {
        user.groups.push(groupId);
      }
    };

    const removeUserGroup = (user, groupId) => {
      if (!user.groups || user.groups.length === 1) {
        user.groups = [];
        user.defaultGroup = '';
      } else {
        user.groups = user.groups.filter((g) => g !== groupId);
        user.defaultGroup = user.defaultGroup === groupId ? '' : user.defaultGroup;
      }
    };
    /* eslint-enable no-param-reassign */

    const red = (item, payloadInner) => ({...item, ...payloadInner});

    const findUserIndexById = (id) => state.data.findIndex((item) => item._id === id);

    const updateUserById = (id, obj) => {
      const index = findUserIndexById(id);
      return {
        ...state,
        data: reduceArrayItem(red, state.data, index, obj),
      };
    };

    switch (type) {
      case actions.updateUser.TYPE: {
        const {isOpen, editingUserId, orgName, ...rest} = payload;
        return updateUserById(payload._id, rest);
      }

      case actions.updateUserStatus.TYPE: {
        const findUser = state.data.find((user) => user._id === payload.userId);
        const updatedUser = {...findUser, disabled: payload.body.disabled};
        return updateUserById(payload.userId, updatedUser);
      }

      case actions.createGroup.success.TYPE: {
        if (!payload.users.length) {
          return state;
        }

        const updateUsers = state.data.filter((user) => intersection(payload.users, [user._id]).length !== 0);
        const oldUsers = state.data.filter((user) => intersection(payload.users, [user._id]).length === 0);

        updateUsers.forEach((upUser) => {
          addUserGroup(upUser, payload._id);
        });

        return {
          ...state,
          data: [...oldUsers, ...updateUsers],
        };
      }

      case actions.updateGroup.TYPE: {
        const usersToRemove = difference(meta.groupUsersIds, payload.group.users);
        const usersToAdd = difference(payload.group.users, meta.groupUsersIds);
        const allUserIds = [...usersToAdd, ...usersToRemove];

        if (!allUserIds.length) {
          return state;
        }

        const addGroupToUsers = state.data.filter((user) => intersection(usersToAdd, [user._id]).length !== 0);
        const removeGroupFromUsers = state.data.filter((user) => intersection(usersToRemove, [user._id]).length !== 0);
        const oldUsers = state.data.filter((user) => intersection(allUserIds, [user._id]).length === 0);

        addGroupToUsers.forEach((upUser) => {
          addUserGroup(upUser, payload.group.id);
        });

        removeGroupFromUsers.forEach((upUser) => {
          removeUserGroup(upUser, payload.group.id);
        });

        return {
          ...state,
          data: [...oldUsers, ...addGroupToUsers, ...removeGroupFromUsers],
        };
      }

      case actions.deleteGroup.success.TYPE: {
        if (!payload.users.length) {
          return state;
        }

        const updateUsers = state.data.filter((user) => intersection(payload.users, [user._id]).length !== 0);
        const oldUsers = state.data.filter((user) => intersection(payload.users, [user._id]).length === 0);

        updateUsers.forEach((upUser) => {
          removeUserGroup(upUser, payload._id);
        });

        return {
          ...state,
          data: [...oldUsers, ...updateUsers],
        };
      }

      case actions.addGroupsToUser.success.TYPE: {
        return updateUserById(payload.user._id, payload.user);
      }

      case actions.deleteUser.TYPE: {
        return {
          ...state,
          data: state.data.filter((user) => user._id !== payload.userId),
        };
      }

      case actions.createUsers.success.TYPE: {
        if (payload.users) {
          return {
            ...state,
            data: [...state.data, ...payload.users],
          };
        }
        return state;
      }

      case actions.editUsersBulkApi.success.TYPE: {
        if (payload.users && payload.users.length) {
          const newData = state.data.map((user) => {
            const findUser = payload.users.find((resUser) => resUser._id === user._id);

            if (findUser && (payload.action === 'groups' || payload.action === 'primaryGroup')) {
              return {...user, groups: findUser.groups, defaultGroup: findUser.defaultGroup};
            }

            if (findUser && (payload.action === 'disable' || payload.action === 'enable')) {
              return {...user, disabled: findUser.disabled};
            }

            return {...user};
          });

          return {
            ...state,
            data: newData,
          };
        }
        return state;
      }

      case actions.deleteUsersBulkApi.success.TYPE: {
        if (payload.success && payload.usersDeleted.length) {
          const newData = state.data.filter((user) => payload.usersDeleted.indexOf(user._id) === -1);

          return {
            ...state,
            data: newData,
          };
        }
        return state;
      }

      default:
        return state;
    }
  },
);

export default usersReducer;
