import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import {composeReducers} from '../../../common/utils/reducers';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const kinesisFormatAnalysis = makeAsyncReducer(actions.fetchKinesisStreamAnalysis, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

export default combineReducers({
  kinesisFormatAnalysis,
  kinesisPathsAndValues: composeReducers(
    makeAsyncReducer(actions.fetchKinesisPathsAndValues, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
});
