// @flow
import React, {Fragment} from 'react';
import './StyleGuideComponents.module.scss';
import WarningSign from 'common/componentsV2/WarningSign';

export default class WarningSection extends React.Component {
  render() {
    return (
      <Fragment>
        <h4>Warning</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Warning Yellow</h5>
            <WarningSign warningLevel="alert-1-warning-yellow" />
          </div>
          <div styleName="item">
            <h5>Warning Red</h5>
            <WarningSign warningLevel="alert-2-warning-red" />
          </div>
        </div>
      </Fragment>
    );
  }
}
