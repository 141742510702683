// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';

const BackButton = ({text, onClick}: PropTypes) => (
  <Box display="flex" alignItems="center" css={{cursor: 'pointer'}} onClick={onClick}>
    <i className="icn-arrow16-arrowleft" />
    <Box component="span" fontSize={12} height={21} pl={0.5} fontWeight={500}>
      {text}
    </Box>
  </Box>
);

BackButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

BackButton.defaultProps = {
  text: 'Back',
};

export default BackButton;
