// @flow
import React, {Fragment} from 'react';
import './StyleGuideComponents.module.scss';
import MultiButton from 'common/componentsV2/multiButton/MultiButton';

const MULTI_OPTIONS = [
  {
    label: 'First',
    value: 'op1',
  },
  {
    label: 'Second',
    value: 'op2',
  },
  {
    label: 'Third',
    value: 'op3',
  },
  {
    label: 'Forth',
    value: 'op4',
  },
  {
    label: 'Fifth',
    value: 'op5',
  },
];

export default class MultiButtonSection extends React.PureComponent {
  state = {
    multi1: null,
    multi2: null,
  };

  render() {
    return (
      <Fragment>
        <h4>Multi button section</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Multi button</h5>
            <MultiButton
              options={MULTI_OPTIONS}
              value={this.state.multi1}
              onChange={(val) => this.setState({multi1: val})}
            />
          </div>
          <div styleName="item">
            <h5>Multi button disabled</h5>
            <MultiButton
              options={MULTI_OPTIONS}
              value={this.state.multi2}
              onChange={(val) => this.setState({multi2: val})}
              isDisabled
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
