// @flow
import React, {PureComponent} from 'react';

import OuterRow from 'common/componentsV2/accordion/OuterRow';
import './AccordionSection.module.scss';

type PropTypes = {
  children: Object,
  isOpen: Boolean,
  isLoading?: Boolean,
  label: String,
  titles: Array,
  onClick: Function,
};

export default class AccordionSection extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    isLoading: false,
  };

  render() {
    const {isOpen, titles, children, isLoading, onClick, label} = this.props;

    return (
      <div styleName="container">
        <OuterRow
          titles={titles}
          onClick={() => (isLoading ? null : onClick(label))}
          isOpen={isOpen}
          isLoading={isLoading}
        />
        {isOpen && children}
      </div>
    );
  }
}
