import React from 'react';
import ErrorsSummary from './ErrorsSummary';
import ErrorsTables from './ErrorsTables';

export default class ErrorsView extends React.PureComponent {
  render() {
    return (
      <div>
        <ErrorsSummary />
        <ErrorsTables />
      </div>
    );
  }
}
