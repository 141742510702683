// @flow
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getCustomersDataCount} from 'admin.customers/store/selectors';
import {getFilteredCustomersSectionCustomersListCount} from 'admin.customers/store/filterSelector';
import {fetchCustomers} from 'admin.customers/store/actions';
import CustomersTable from 'admin.customers/components/customersTable/CustomersTable';
import CustomerStatusModal from 'admin.customers/components/customersTable/CustomerStatusModal';
import DeleteCustomerModal from 'admin.customers/components/customersTable/DeleteCustomerModal';
import './CustomersSections.module.scss';

const CustomersSection = () => {
  const dispatch = useDispatch();
  const allCustomersCount = useSelector(getCustomersDataCount);
  const filteredCount = useSelector(getFilteredCustomersSectionCustomersListCount);

  useEffect(() => {
    dispatch(fetchCustomers());
  }, []);

  return (
    <div styleName="customer-section">
      <div styleName="header">
        <span>Customers</span>
        <span styleName="counter">
          {allCustomersCount === filteredCount ? `(${allCustomersCount})` : `(${filteredCount}/${allCustomersCount})`}
        </span>
      </div>
      <CustomersTable />
      <CustomerStatusModal />
      <DeleteCustomerModal />
    </div>
  );
};

export default CustomersSection;
