import React from 'react';
import ParquetData from './ParquetData/ParquetData';
import ParquetDimensionsAndMetrics from './parquetDimensionsAndMetrics/ParquetDimensionsAndMetrics';
import ParquetSchedular from './parquetSchedular/ParquetSchedular';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

import './ParquetStreamEditor.module.scss';

const ParquetStreamEditor = () => (
  <div styleName="root" className="shell-col">
    <StreamEditorSteps>
      <ParquetData />
      <ParquetDimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <ParquetSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default ParquetStreamEditor;
