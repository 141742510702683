// @flow

import React from 'react';
import {Popover} from 'react-bootstrap';
import SearchBox from 'common/components/SearchBox';

const AddConceptPopover = ({filter, onFilter, children, ...rest}: any) => (
  <Popover {...rest} id="add-concept-popover">
    <SearchBox {...{filter, onFilter}} />
    {children}
  </Popover>
);

export default AddConceptPopover;
