import React from 'react';

import './UsagePoint.module.scss';

type PropTypes = {
  label: String,
  value: String,
};

export default class UsagePoint extends React.PureComponent {
  props: PropTypes;

  formatNumber = (number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  render() {
    const {value, label} = this.props;
    const isNumber = Number.isInteger(value);

    return (
      <div styleName={isNumber ? 'container' : 'container bool'}>
        {isNumber ? (
          <h3>{this.formatNumber(value)}</h3>
        ) : (
          <div styleName={value ? 'bool-indicator negative' : 'bool-indicator positive'} />
        )}
        <span>{label}</span>
      </div>
    );
  }
}
