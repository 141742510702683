import React from 'react';
import PropTypes from 'prop-types';
import {palette} from 'app/styles/theme';
import Grid from '@material-ui/core/Grid';
import {TypographyBox} from 'common/componentsV2/boxTools';
import SelectAndt, {
  DIRECTION_LEFT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {components} from 'react-select2';
import {makeStyles} from '@material-ui/core/styles';
import {ALERT_ACTION_PARAMS, MENU_OPTIONS_ACTION_ITEM} from '../services/service';

const useStyles = makeStyles(() => ({
  main: {
    border: 'solid 2px transparent',
    marginBottom: 8,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 16px',
    borderRadius: 10,
    backgroundColor: palette.gray['100'],
    cursor: 'default',
    '&:hover': {
      backgroundColor: palette.gray['200'],
    },
  },
  dropdownIndicator: {
    fontSize: 18,
    color: palette.gray['500'],
  },
  menuIcon: {
    marginRight: 4,
  },
  moreOptionWrapper: {
    position: 'absolute',
    right: 2,
    top: 5,
  },
}));

const ActionItem = (props: PropTypes) => {
  const classes = useStyles();
  const onMenuChange = (event, selection) => {
    event.stopPropagation();
    props.onChange(selection.value, props.action.id, props.action.usedInAlerts);
  };

  return (
    <Grid
      container
      className={classes.main}
      onClick={() => props.onAddActionToMarkedList(props.action)}
      style={{borderColor: props.isActive ? palette.blue['500'] : null}}
    >
      <Grid item sm={12}>
        <Grid container>
          <Grid item sm={11}>
            <div className="text16med lineHeight_16 ellipsis">{props.action[ALERT_ACTION_PARAMS.ACTION_NAME]}</div>
          </Grid>

          <Grid item sm={1} className={classes.moreOptionWrapper}>
            <SelectAndt
              appendToBody={false}
              type={TYPE_SIMPLE}
              theme={THEME_TRANSPARENT}
              options={MENU_OPTIONS_ACTION_ITEM}
              direction={DIRECTION_LEFT}
              menuWidth={200}
              automationId="assetsMoreOptions"
              customComponent={{
                Option: (p) => (
                  <components.Option {...p}>
                    <div onClick={(e) => onMenuChange(e, p)}>
                      <i className={['icon', classes.menuIcon, p.data.icon].join(' ')} />
                      {p.children}
                    </div>
                  </components.Option>
                ),
                DropdownIndicator: () => (
                  <i
                    className={['icon icn-general16-3dot', classes.dropdownIndicator].join(' ')}
                    onClick={(e) => e.stopPropagation()}
                  />
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12} style={{marginTop: 8}}>
        <Grid container>
          <Grid item sm={3}>
            <div className="text14reg lineHeight_14 ellipsis">{props.action[ALERT_ACTION_PARAMS.BTN_NAME]}</div>
          </Grid>

          <Grid item sm={7}>
            <TypographyBox variant="hintText" isEllipsis css={{padding: '0 4px'}}>
              {props.action[ALERT_ACTION_PARAMS.DATA][ALERT_ACTION_PARAMS.DATA_URL]}
            </TypographyBox>
          </Grid>

          <Grid item sm={2} style={{display: 'flex', justifyContent: 'flex-end'}}>
            <TypographyBox variant="subtitle2" css={{padding: '0 4px', color: palette.gray[400]}}>
              Used in {props.action.usedInAlerts} alerts
            </TypographyBox>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ActionItem.propTypes = {
  action: PropTypes.objectOf(PropTypes.any).isRequired,
  onAddActionToMarkedList: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default ActionItem;
