import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {composeReducers} from 'common/utils/reducers';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const accounts = makeAsyncReducer(actions.fetchGoogleAnalyticsAccounts, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});
const properties = makeAsyncReducer(actions.fetchGoogleAnalyticsProperties, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});
const metadata = makeAsyncReducer(actions.fetchMetadata, {
  defaultData: EMPTY_OBJECT,
  errorHandler: bcErrorHandler,
});

const cubes = makeAsyncReducer(actions.fetchCubes, {
  defaultData: EMPTY_OBJECT,
  errorHandler: bcErrorHandler,
});

const views = composeReducers(
  makeAsyncReducer(actions.fetchGoogleAnalyticsViews, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
  (state, {type, payload}) => {
    switch (type) {
      case actions.fetchGoogleAnalyticsViews.TYPE:
        if (!payload.propertyId) {
          return {...state, isLoading: false, items: EMPTY_ARRAY};
        }
        return state;
      default:
        return state;
    }
  },
);

const segments = composeReducers(
  makeAsyncReducer(actions.fetchGaSegments, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
  (state, {type}) => {
    switch (type) {
      default:
        return state;
    }
  },
);

const extraUiState = (
  state = {
    dimensionsInputVal: '',
    metricsInputVal: '',
  },
  {type, payload},
) => {
  switch (type) {
    case actions.setSelectedStreamGaExtraUiStateKeyVal.TYPE:
      return {...state, ...payload};
    default:
      return state;
  }
};

const templates = composeReducers((state = {isSavingNewTemplate: false, data: EMPTY_ARRAY}, {type, payload}) => {
  switch (type) {
    case actions.createStreamTemplate.TYPE:
      return {...state, isSavingNewTemplate: true, error: null};
    case actions.createStreamTemplate.success.TYPE:
      return {
        ...state,
        isSavingNewTemplate: false,
        error: null,
        data: [...state.data, payload],
      };
    case actions.createStreamTemplate.failure.TYPE:
      return {...state, isSavingNewTemplate: false, error: payload};

    default:
      return state;
  }
}, makeAsyncReducer(actions.fetchStreamTemplates, {defaultData: EMPTY_ARRAY}));

export default combineReducers({
  accounts,
  properties,
  views,
  segments,
  templates,
  extraUiState,
  metadata,
  cubes,
});
