import {get} from 'lodash';

const errorHandler = (codesList = []) => (payload) => {
  if (!payload) {
    return null;
  }
  if (payload.andtErrorCode) {
    // if the server knows about this error.
    if (codesList[payload.andtErrorCode]) {
      // if the UI knows about this error.
      return {
        ...codesList[payload.andtErrorCode],
        moreInfo: payload.uMessage,
      };
    }

    const moreInfo =
      // for future API support (currently only additioanlInfo exists)
      // this errorHandler emits toasts/growl that accepts moreInfo
      payload.moreInfo ||
      // data collectors, file upload error (cannot parse),
      // additionalInfo is an object of key<string>:value<string> with messages
      // we take the first one
      Object.values(get(payload, 'additionalInfo') || {})[0];

    return {
      title: payload.uMessage || payload.message,
      description: payload.description || moreInfo ? ' ' : null, // hack to support more info action in snackBar
      moreInfo,
    };
  }

  // if the UI and the server don't know about the error.
  return {moreInfo: payload.uMessage || payload.message};
};

export default errorHandler;
