import {useState, useCallback} from 'react';

export default function useDebounce(callback, delay) {
  const [timeout, setTimeoutRef] = useState();

  const cancel = useCallback(() => {
    if (timeout) {
      clearTimeout(timeout);
    }
  }, [timeout]);

  const debounced = useCallback(
    (...args) => {
      cancel();
      if (delay) {
        setTimeoutRef(setTimeout(() => callback(...args), delay));
      } else {
        callback(...args);
      }
    },
    [cancel, callback, delay],
  );

  return [debounced, cancel];
}
