import React from 'react';
import PropTypes from 'prop-types';
import './OptionComponentAutocomplete.module.scss';

const OptionComponentAutocomplete = (props: PropTypes) => (
  <div
    styleName={[
      'container',
      props.isSelected ? 'selected' : '',
      props.data.isDisabled ? 'disabled' : '',
      props.data.type === 'HEADER' ? 'header' : '',
    ].join(' ')}
    className="form-option idle-external-click"
  >
    <div styleName="label" title={props.data.title || ''}>
      {props.data.label || props.data.text}
    </div>
  </div>
);

OptionComponentAutocomplete.propTypes = {
  data: PropTypes.objectOf(PropTypes.any),
  isSelected: PropTypes.bool,
};

OptionComponentAutocomplete.defaultProps = {
  data: {},
  isSelected: false,
};
export default OptionComponentAutocomplete;
