// @flow
import React from 'react';
import Checkbox from 'common/componentsV2/Checkbox';
import './FormDdlItems.module.scss';

type PropTypes = {
  onClick: Function,
  label: String,
  searchString: String,
  isSelected: boolean,
  value: any,
};

export default class FormDdlMultiSelectItem extends React.PureComponent {
  props: PropTypes;

  onClick = () => {
    const {value, onClick, isSelected} = this.props;
    onClick({
      isSelected: !isSelected,
      value,
    });
  };

  render() {
    const {label, searchString, isSelected} = this.props;

    return (
      <Checkbox
        isChecked={isSelected}
        text={label}
        styleName="multi-root"
        highlightText={searchString}
        onChange={this.onClick}
      />
    );
  }
}
