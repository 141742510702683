// @flow
import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {get} from 'lodash';
import {Box} from '@material-ui/core';
import RadioButtonsList from 'common/componentsV2/RadioButtonsList';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import BigDropdownButton from 'common/componentsV2/ddl/multiSelectFormDdl/BigDropdownButton';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import Button, {COLORS} from 'common/componentsV2/Button';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import {getDataDashboards, getDashboardsIdOptions} from 'dashboards/store/selectors';
import {fetchDashboards, updateDashboard, fetchDashboard} from 'dashboards/store/actions';
import useAsyncAction from 'common/utils/useAsyncAction';
import {findFreeSpace, makeUpdateTilePayload, generateLayout} from 'dashboards/utils';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import {getUniqueId} from 'common/utils/guid';

const EMPTY_ARRAY = [];

const renderToDashboardOptions = [
  {label: 'Duplicate in this dashboard', value: OPTION_TILES_VALUES.DUPLICATE},
  {label: 'Duplicate to another dashboard', value: OPTION_TILES_VALUES.COPY},
];

type PropsType = {
  onClose: Function,
  layout: Array<Object>,
  data: Object,
  dashboardId: string,
  isOwnerUser: boolean,
};

const DuplicateTileModal = ({onClose, layout, data, dashboardId, isOwnerUser}: PropsType) => {
  const dispatch = useDispatch();
  const [type, setType] = useState(renderToDashboardOptions[isOwnerUser ? 0 : 1]);
  const isCopy = type.value === OPTION_TILES_VALUES.COPY;
  const dashboard = useDashboardState(dashboardId);
  const [selectedDashboard, setSelectedDashboard] = useState({value: '', label: ''});
  const copyToDashboard = selectedDashboard ? useDashboardState(selectedDashboard.value) : null;
  const dataDashboards = useSelector(getDataDashboards);
  const dashboardsIdOptions = useSelector(getDashboardsIdOptions).filter((option) => option.value !== dashboardId);
  useEffect(() => {
    if (dataDashboards.length === 0) {
      dispatch(fetchDashboards());
    }
  }, []);
  const updateDashboardAsync = useAsyncAction(updateDashboard, isCopy && copyToDashboard ? copyToDashboard : dashboard);
  const handleType = (option) => {
    setType(option);
  };

  const handleChangeSelected = (option) => {
    dispatch(fetchDashboard({id: option.value}));
    setSelectedDashboard(option);
  };

  const handleAsyncSubmit = () => {
    const dashboardData = isCopy && copyToDashboard ? copyToDashboard.data : dashboard.data;
    const layoutFromAnotherDashboard = isCopy ? generateLayout(get(dashboardData, 'tiles', EMPTY_ARRAY)) : null;
    const position = findFreeSpace(isCopy ? layoutFromAnotherDashboard : layout, {
      x: data.sizeX,
      y: data.sizeY,
    });
    const tile = {
      ...data,
      id: getUniqueId(),
      row: position.y,
      col: position.x,
      title: {
        text: `${data.title.text} - copy`,
      },
    };

    return (
      updateDashboardAsync(
        makeUpdateTilePayload(dashboardData, tile, null, isCopy ? layoutFromAnotherDashboard : layout),
      )
        .then(() => {
          onClose();
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error))
    );
  };

  return (
    <Modal isOpen onClose={onClose} size={SIZES.SMALL} isAnimation={false} isCloseButtonHidden height={233}>
      <Box display="flex" flexDirection="column" height={1}>
        <div className="text16med lineHeight_16">Duplicate Tile</div>
        <Box mt={2}>
          <RadioButtonsList
            options={isOwnerUser ? renderToDashboardOptions : [renderToDashboardOptions[1]]}
            value={type.value}
            onChange={handleType}
            direction="column"
          />
        </Box>
        <Box mb={4}>
          {type.value === OPTION_TILES_VALUES.COPY && (
            <Box mt={1.5} width={232}>
              <FormDdlSelect
                options={dashboardsIdOptions}
                selected={selectedDashboard}
                isUseSearch
                button={
                  <BigDropdownButton
                    placeholder="Select dashboard"
                    label={selectedDashboard ? selectedDashboard.label : ''}
                    isPristine={selectedDashboard.value === ''}
                  />
                }
                optionComponent={<OptionComponentSimple />}
                onChange={handleChangeSelected}
              />
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="flex-end" flexGrow={1}>
          <Box mr={1}>
            <Button text="Cancel" colorSchema={COLORS.GRAY_300} onClick={onClose} />
          </Box>
          <Button
            isDisabled={
              dashboard.isLoading ||
              (selectedDashboard && copyToDashboard.isLoading) ||
              (isCopy && selectedDashboard.value === '')
            }
            text="Duplicate"
            colorSchema={COLORS.BLUE_500}
            onClick={handleAsyncSubmit}
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default DuplicateTileModal;
