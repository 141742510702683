import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const metrics = makeAsyncReducer(actions.fetchCoralogixMetrics, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const labels = makeAsyncReducer(actions.fetchCoralogixLabels, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const measures = makeAsyncReducer(actions.fetchCoralogixMeasures, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

export default combineReducers({
  metrics,
  labels,
  measures,
});
