import {combineReducers} from 'redux';
import * as actions from 'topologyLeaflet/store/actions';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {composeReducers} from 'common/utils/reducers';

const EMPTY_OBJECT = {};

export default combineReducers({
  fetchTopologyMap: makeAsyncReducer(actions.fetchTopologyMap, {
    defaultData: EMPTY_OBJECT,
  }),
  fetchTopologyAlerts: makeAsyncReducer(actions.fetchTopologyAlerts, {
    defaultData: EMPTY_OBJECT,
  }),
  fetchTopologyAnomalies: makeAsyncReducer(actions.fetchTopologyAnomalies, {
    defaultData: EMPTY_OBJECT,
  }),
  fetchTopologyAlert: composeReducers(
    makeAsyncReducer(actions.fetchTopologyAlert, {
      defaultData: EMPTY_OBJECT,
    }),
    (state, {type}) => {
      switch (type) {
        case actions.clearSpecialIssue.TYPE: {
          return {data: EMPTY_OBJECT};
        }
        default:
          return state;
      }
    },
  ),
  fetchTopologyAnomaly: composeReducers(
    makeAsyncReducer(actions.fetchTopologyAnomaly, {
      defaultData: EMPTY_OBJECT,
    }),
    (state, {type}) => {
      switch (type) {
        case actions.clearSpecialIssue.TYPE: {
          return {data: EMPTY_OBJECT};
        }
        default:
          return state;
      }
    },
  ),
  fetchMetricDataPoints: composeReducers((state = {}, {type, payload, meta}) => {
    switch (type) {
      case actions.setIntoLeftPanelView.TYPE: {
        if (payload.issuesType || payload.constRange) {
          return EMPTY_OBJECT;
        }
        return state;
      }

      case actions.fetchMetricDataPoints.TYPE: {
        return {
          ...state,
          [meta.key]: {
            ...state[meta.key],
            isLoading: true,
          },
        };
      }

      case actions.fetchMetricDataPoints.success.TYPE: {
        return {
          ...state,
          [meta.key]: {
            ...state[meta.key],
            isLoading: false,
            data: payload,
          },
        };
      }

      case actions.fetchMetricDataPoints.failure.TYPE: {
        return {
          ...state,
          [meta.key]: {
            ...state[meta.key],
            isLoading: false,
            data: null,
            error: payload,
          },
        };
      }

      default:
        return state;
    }
  }),
});
