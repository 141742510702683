// @flow
// @flow
import React from 'react';
import SmartTooltip from 'common/components/SmartTooltip';

type PropTypes = {
  displayProperties: Object,
  prefixSuffixForegroundColor: String,
  foregroundColor: String,
  displayValue: String,
  unitsText: String,
  metricName: String,
  popupShowDelay: number,
};

const GaugeInner = (props: PropTypes) => {
  const {
    displayProperties,
    prefixSuffixForegroundColor,
    foregroundColor,
    displayValue,
    unitsText,
    metricName,
    popupShowDelay,
  } = props;

  const {mainValue, prefix, suffix, orientation} = displayProperties;
  const isLeftRightOrientation = orientation === 'leftRight';
  const centerClass = `shell gauge-center ${isLeftRightOrientation ? 'gauge-horizontal' : 'gauge-vertical'}`;
  const prefixClass = `prefix ${isLeftRightOrientation ? 'prefix-horizontal' : 'prefix-vertical'}`;
  const suffixClass = `suffix ${isLeftRightOrientation ? 'suffix-horizontal' : 'suffix-vertical'}`;
  const tooltip = <span className="nowrapping">{metricName}</span>;

  return (
    <div className="gauge">
      <SmartTooltip placement="top" content={tooltip} delayShow={popupShowDelay}>
        <div className={centerClass}>
          {prefix.text && (
            <div
              className={prefixClass}
              style={{fontSize: `${prefix.fontSize / 100}em`, color: prefixSuffixForegroundColor}}
            >
              {prefix.text}
            </div>
          )}
          <div className="main-value" style={{fontSize: `${mainValue.fontSize / 100}em`, color: foregroundColor}}>
            {displayValue}
          </div>
          {unitsText && (
            <div className="units" style={{fontSize: `${mainValue.fontSize / 100 / 2}em`, color: foregroundColor}}>
              {unitsText}
            </div>
          )}
          {suffix.text && (
            <div
              className={suffixClass}
              style={{fontSize: `${suffix.fontSize / 100}em`, color: prefixSuffixForegroundColor}}
            >
              {suffix.text}
            </div>
          )}
        </div>
      </SmartTooltip>
    </div>
  );
};

export default GaugeInner;
