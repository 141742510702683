// @flow
import React from 'react';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import EditCustomerForm from './EditCustomerForm';
import './EditCustomerForm.module.scss';

type PropTypes = {
  isOpen: Boolean,
  onClose: Function,
};
const EditCustomerModal = ({isOpen, onClose}: PropTypes) => {
  return (
    <Modal
      classStyle="customer-edit-modal"
      id="customer-edit-modal"
      isOpen={isOpen}
      onClose={onClose}
      size={SIZES.LARGE}
      isStatic
    >
      <EditCustomerForm onClose={onClose} />
    </Modal>
  );
};
export default EditCustomerModal;
