// @flow
import React from 'react';
import './StreamEditorPanelHeader.module.scss';

type PropTypes = {
  title: String,
  children: any,
};

const StreamEditorPanelHeader = (props: PropTypes) => (
  <div>
    <div styleName="root" className="shell">
      <div className="ellipsis" styleName="title">
        {props.title}
      </div>
      <div styleName="actions" className="shell-col">
        {props.children}
      </div>
    </div>
    <hr styleName="hr" />
  </div>
);

export default StreamEditorPanelHeader;
