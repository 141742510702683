import React from 'react';
// eslint-disable-next-line max-len
import SalesforceDimensionsAndMetrics from 'bc/components/streams/editor/byType/salesforce/salesforceDimensionsAndMetrics/SalesforceDimensionsAndMetrics';
// eslint-disable-next-line max-len
import SalesforceSchedular from 'bc/components/streams/editor/byType/salesforce/salesforceSchedular/SalesforceSchedular';
import SalesforceContext from 'bc/components/streams/editor/byType/salesforce/SalesforceContext';
import StreamEditorSteps from 'bc/components/streams/editor/StreamEditorSteps';
import StreamEditorPanel from 'bc/components/streams/editor/StreamEditorPanel';
import StreamProperties from 'bc/components/streams/editor/StreamProperties';
import './SalesforceStreamEditor.module.scss';

const SalesforceStreamEditor = () => (
  <div className="shell-col">
    <StreamEditorSteps>
      <div styleName="left-container">
        <StreamProperties />
        <SalesforceContext />
        <SalesforceSchedular />
      </div>
      <SalesforceDimensionsAndMetrics />
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default SalesforceStreamEditor;
