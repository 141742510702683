// @flow
import React from 'react';
import {COLORS} from '../GroupsItem';
import './GroupColorPicker2.module.scss';

type PropTypes = {
  onClick: Function,
  selectedColor?: String,
  className?: String,
};

export default class GroupColorPicker extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    selectedColor: '',
    className: '',
  };

  onColorClicked = (color) => {
    this.props.onClick(color);
  };

  renderItems = () =>
    Object.values(COLORS).map((color) => (
      <ColorItem
        onClick={this.onColorClicked}
        color={color}
        key={color}
        isSelected={this.props.selectedColor === color}
      />
    ));

  render() {
    return (
      <div styleName="root" className={this.props.className}>
        {this.renderItems()}
      </div>
    );
  }
}

const ColorItem = (props: {onClick: Function, isSelected: boolean, color: String}) => {
  const itemClicked = () => {
    props.onClick(props.color);
  };

  return (
    <div styleName={`color-item ${props.color}`} role="presentation" onClick={itemClicked}>
      {props.isSelected && <i className="icon icn-general16-checkmark" />}
    </div>
  );
};
