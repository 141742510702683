import React, {Component} from 'react';

type PropTypes = {
  // accepted values by the original Select
  selectProps: any,
  getStyles: Function,
};

export default class DropdownIndicatorCaret extends Component {
  props: PropTypes;

  shouldComponentUpdate(nextProps) {
    return this.props.selectProps.menuIsOpen !== nextProps.selectProps.menuIsOpen;
  }

  render() {
    const style = this.props.getStyles('dropdownIndicator', this.props);

    if (this.props.selectProps.menuIsOpen) {
      style.transform = 'scaleY(-1)';
    }
    return <span style={style} />;
  }
}
