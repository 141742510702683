// @flow
import React from 'react';
import './ExpressionBuilder.module.scss';
import {noop} from 'lodash';
import ExpressionItem from './ExpressionItem';
import Chip, {TYPE} from './Chip';
import {getUniqueId} from '../../utils/guid';

type PropTypes = {
  expression: Array<ExpressionItem>,
};

export default class ExpressionLineDisplay extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <div styleName="expression-line-container">
        {this.props.expression.map((expItem: ExpressionItem) => (
          <Chip
            key={expItem.value || `-${getUniqueId()}`}
            type={expItem.value === 'Measures' ? TYPE.MEASURE : TYPE.FILTER}
            expressionItem={expItem}
            onClick={noop}
            onClickOutside={noop}
            onInnerChipRemoveClicked={noop}
            isFocus={false}
            textInput={<div />}
            isShowChildrenOnly={expItem.label === 'Measures'}
            displayOnly
          />
        ))}
      </div>
    );
  }
}
