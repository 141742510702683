import {combineReducers} from 'redux';
import data from './dataReducer';
import dnsTrackerPage from './pageReducer';

export default combineReducers({
  views: combineReducers({
    dnsTrackerPage,
  }),
  data,
});
