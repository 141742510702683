import {makeAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'expressionBuilder/alerts/');
// const macAsync = (actionName) => makeAsyncAction(actionName, 'expressionBuilder/alerts/');

// Reset used to put the Store in the initial state
export const resetExpressionBuilder = mac('RESET_EXPRESSION_BUILDER');
// Set a whole new expression
export const setExpression = mac('SET_EXPRESSION');
// Actually the user's search input from the main text-field
export const setMainSearchQuery = mac('SET_MAIN_QUERY');
// Text from the user inside the chip text-field
export const setSecondarySearchQuery = mac('SET_SECONDARY_SEARCH_QUERY');
// focused chip value
export const setCurrentSelectedKey = mac('SET_CURRENT_SELECTED_KEY');
// takes out a child from a key
export const removeInnerValue = mac('REMOVE_INNER_CHIP');
// adds a child value to a key
export const addInnerValue = mac('ADD_INNER_VALUE');
// add new key chip
export const addValue = mac('ADD_VALUE');
