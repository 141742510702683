import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {info} from 'common/utils/notifications/notificationsService';
import * as api from '../services/api';
import * as actions from './actions';
import * as selectors from './selectors';

const fetchCustomers = makeAsyncEpic(actions.fetchCustomers, api.fetchCustomers);
const fetchDefaults = makeAsyncEpic(actions.fetchDefaults, api.fetchDefaults);
const updateCustomersAndGlobal = makeAsyncEpic(actions.updateCustomersAndGlobal, api.updateCustomersAndGlobal);

const fetchCustomersSuccesses = (action$) =>
  action$.ofType(actions.fetchCustomers.success.TYPE).switchMap(() => [actions.fetchDefaults()]);

const fetchDefaultsSuccesses = (action$, {getState}) =>
  action$.ofType(actions.fetchDefaults.success.TYPE).switchMap(() => {
    const customersAndGlobal = selectors.getCustomersAndGlobal(getState());
    const defaults = selectors.getDefaults(getState());

    return [
      actions.mergeCustomers({
        customersAndGlobal,
        defaults,
      }),
      actions.mergeGlobal({
        customersAndGlobal,
        defaults,
      }),
      actions.checkIfAll(),
    ];
  });

const updateCustomersAndGlobalSuccess = (action$) =>
  action$.ofType(actions.updateCustomersAndGlobal.success.TYPE).switchMap(() => {
    const infoMessage = {
      title: 'Feature Flags were updated!',
      description: 'Please double check your changing. Thanks',
      moreInfo: false,
      settings: {
        uid: 'updateCustomersAndGlobalSuccess',
      },
    };
    return [info(infoMessage), actions.fetchCustomers()];
  });

const toggleFeatureCustomer = (action$) =>
  action$.ofType(actions.toggleFeatureCustomer.TYPE).switchMap(() => [actions.checkIfAll()]);

const toggleAll = (action$) => action$.ofType(actions.toggleAll.TYPE).switchMap(() => [actions.checkIfAll()]);

export default combineEpics(
  fetchCustomers,
  fetchDefaults,
  updateCustomersAndGlobal,
  updateCustomersAndGlobalSuccess,
  toggleAll,
  fetchCustomersSuccesses,
  fetchDefaultsSuccesses,
  toggleFeatureCustomer,
);
