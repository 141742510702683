// @flow
import React from 'react';
import DataSourceModal from 'bc/components/dataSourceTypes/dataSourceModal/DataSourceModal';
import '../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  isViewOnly: boolean,
  isEditable: boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,
};

export default class GeneralSourceModal extends React.PureComponent {
  props: PropTypes;

  onClose = () => {
    this.props.onClose();
  };

  render() {
    const {isOpen, source, sourceType, isViewOnly, isEditable} = this.props;
    return (
      <DataSourceModal
        isOpen={isOpen}
        isProcessing={false}
        isViewOnly={isViewOnly}
        isRenameEnabled={isEditable}
        source={source}
        sourceType={sourceType}
        logoImageClass="source-logo-s3"
        onClose={this.onClose}
      >
        <div styleName="inputs-wrapper" />
      </DataSourceModal>
    );
  }
}
