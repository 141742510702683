// @flow
import React from 'react';
import {connect} from 'react-redux';
import {getTypeDetails} from 'bc/services/bcTypes';
import * as selectors from 'bc/store/selectors';
import * as profileSelectors from 'profile/store/selectors';
import {updateDataSource, createDataSource, resetAddSourceError} from 'bc/store/actions';
import DataSourceModal from '../../DataSourceModal';
import '../../DataSourceModal.module.scss';

type PropTypes = {
  isOpen: boolean,
  isViewOnly: boolean,
  isEditable: boolean,
  sourceType: Object,
  source: Object,
  onClose: Function,

  // connect
  me: Object,
  isLoading: boolean,
  sourceError: Object,

  updateDataSource: Function,
  createDataSource: Function,
  resetAddSourceError: Function,
};

class AdobeSourceModal extends React.PureComponent {
  props: PropTypes;

  // eslint-disable-next-line no-extra-boolean-cast
  state = !!this.props.source
    ? {
        sharedSecret: this.props.source.sharedSecret,
        userName: this.props.source.userName,
        sourceName: this.props.source.name,
      }
    : {
        sharedSecret: '',
        userName: '',
        sourceName: `${this.props.me.firstName} ${this.props.me.lastName} ${new Date().getTime().toString()}`,
      };

  onCloseInternal = () => {
    this.props.resetAddSourceError();
    this.props.onClose();
  };

  createBtnClicked = () => {
    if (this.isUpdate) {
      this.props.updateDataSource({
        ...this.props.source,
        sharedSecret: this.state.sharedSecret,
        name: this.state.sourceName,
      });
      this.props.onClose();
      return;
    }
    this.props.createDataSource({
      type: getTypeDetails(this.props.sourceType.type).type,
      name: this.state.sourceName,
      userName: this.state.userName,
      sharedSecret: this.state.sharedSecret,
    });
  };

  render() {
    const {isOpen, isLoading, sourceError, source, sourceType, isViewOnly, isEditable} = this.props;
    const {userName, sharedSecret, sourceName} = this.state;

    return (
      <DataSourceModal
        isOpen={isOpen}
        isContinueDisabled={!userName || !sharedSecret || !sourceName}
        isProcessing={isLoading}
        isViewOnly={isViewOnly}
        isRenameEnabled={isEditable}
        source={source}
        sourceType={sourceType}
        logoImageClass="source-logo-adobe"
        onClose={this.onCloseInternal}
        onContinue={this.createBtnClicked}
      >
        <div styleName="inputs-wrapper">
          <input
            type="text"
            onChange={(e) => this.setState({sourceName: e.target.value})}
            placeholder="Source Name"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={sourceName}
          />

          {(!this.isUpdate || isViewOnly) && (
            <input
              type="text"
              onChange={(e) => this.setState({userName: e.target.value})}
              placeholder="username:org"
              readOnly={isViewOnly}
              disabled={isViewOnly}
              value={userName}
            />
          )}

          <input
            type="text"
            onChange={(e) => this.setState({sharedSecret: e.target.value})}
            placeholder="Shared Secret"
            readOnly={isViewOnly}
            disabled={isViewOnly}
            value={sharedSecret}
          />

          {!isLoading && sourceError ? (
            <div styleName="error-wrapper">
              <span styleName="error-message">
                <i className="icon icn-warning-icon" /> {sourceError.uMessage}
              </span>
            </div>
          ) : null}
        </div>
      </DataSourceModal>
    );
  }
}

export default connect(
  (state) => ({
    me: profileSelectors.getUserProfile(state),
    isLoading: selectors.getAddDataSourceState(state).isLoading,
    sourceError: selectors.getAddDataSourceState(state).error,
  }),
  {
    createDataSource,
    updateDataSource,
    resetAddSourceError,
  },
)(AdobeSourceModal);
