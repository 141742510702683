// @flow
import React from 'react';
import Highlighter from 'react-highlight-words';
import './SqlDataSelect.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  onSearchValueChanged: Function,
  onChange: Function,
  isLoading: boolean,
  disabled: boolean,
  items: Array,
  defaultValue: Object,
  title: string,
  automationId: string,
};

class SqlDataSelect extends React.PureComponent {
  props: PropTypes;

  searchVal = '';

  renderOption = ({focusedOption, focusOption, key, option, selectValue, style, valueArray}) => {
    const className = ['UnitsSelectOption'];
    if (option === focusedOption) {
      className.push('UnitsSelectFocusedOption');
    }
    if (option.disabled) {
      className.push('UnitsSelectDisabledOption');
    }
    if (valueArray && valueArray.indexOf(option) >= 0) {
      className.push('UnitsSelectSelectedOption');
    }

    const events =
      option.disabled || option.category
        ? {}
        : {
            onClick: () => selectValue(option),
            onMouseOver: () => focusOption(option),
          };

    if (option.category) {
      return (
        <div styleName={option.category ? 'category' : ''} className={className.join(' ')} key={key} style={style}>
          <span>{option.name}</span>
        </div>
      );
    }
    return (
      <div className={className.join(' ')} key={key} style={style} {...events}>
        <Highlighter autoEscape searchWords={[this.searchVal]} textToHighlight={option.name} />
      </div>
    );
  };

  renderValue = (option) => (
    <span className={['item-selected', !option.name ? 'item-none-selected' : ''].join(' ')}>
      <span className="item ellipsis">{option.name}</span>
      <span className="item-msg">{this.props.title}</span>
    </span>
  );

  onInputChange = (inputValue) => {
    this.searchVal = inputValue;
    if (typeof this.props.onSearchValueChanged === 'function') {
      this.props.onSearchValueChanged(inputValue);
    }
  };

  onChange = (newValue) => {
    this.props.onChange(newValue);
  };

  onClose = () => {
    this.searchVal = '';
    if (typeof this.props.onSearchValueChanged === 'function') {
      this.props.onSearchValueChanged(this.searchVal);
    }
  };

  render() {
    const selectedIndex = this.props.items.findIndex((val) => this.props.defaultValue === val.name);
    return (
      <div styleName="root">
        <SelectAndt
          type={TYPE_SEARCH}
          theme={THEME_LIGHT}
          onChange={this.onChange}
          options={this.props.items}
          value={this.props.items[selectedIndex]}
          placeholder={this.props.title}
          optionHeight={40}
          disabled={this.props.disabled}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.name}
          automationId={this.props.automationId}
        />
      </div>
    );
  }
}

export default SqlDataSelect;
