import {composeReducers} from 'common/utils/reducers';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const initialState = {
  order: {column: 'title', direction: 'asc'},
  filters: {searchQuery: '', owner: null, type: null},
};

const tables = composeReducers((state = initialState, {type, payload}) => {
  switch (type) {
    case actions.setFilesListOrder.TYPE:
      return {
        ...state,
        order: {...payload},
      };
    case actions.setAssetsFilters.TYPE:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...payload,
        },
      };
    case actions.setResetFilters.TYPE:
      return {
        ...state,
        filters: {
          ...initialState.filter,
        },
      };
    case actions.setScrollbarWidth.TYPE: {
      const scrollbarWidth = payload ? payload.offsetWidth - payload.clientWidth : 0;
      return {...state, scrollbarWidth};
    }
    default:
      return state;
  }
});

export default combineReducers({
  tables,
});
