import React, {useEffect, useRef} from 'react';
import {Box} from '@material-ui/core';
import {getDateValue} from 'common/utils/dateRangeService';
import {isEqual} from 'lodash';
import {useField} from 'react-final-form';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';

const useInterval = (callback, delay) => {
  const savedCallbackRef = useRef();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const handler = (...args) => savedCallbackRef.current(...args);

    if (delay !== null) {
      const intervalId = setInterval(handler, delay);
      return () => clearInterval(intervalId);
    }
  }, [delay]);
};

const INFINITY = -1;
const NOW = 0.001;

export const autoRefreshOptions = [
  {value: INFINITY, label: 'Off', badge: '-'},
  {value: NOW, label: 'Refresh Now', badge: '-'},
  {value: 60, label: 'Every Minute', badge: '1m'},
  {value: 60 * 5, label: 'Every 5 Minutes', badge: '5m'},
  {value: 60 * 10, label: 'Every 10 Minutes', badge: '10m'},
  {value: 60 * 15, label: 'Every 15 Minutes', badge: '15m'},
  {value: 60 * 30, label: 'Every 30 Minutes', badge: '30m'},
  {value: 60 * 60, label: 'Every Hour', badge: '1h'},
];

const AutoRefreshContainer = () => {
  const {
    input: {value, onChange},
  } = useField('dateRange', {isEqual});

  const {
    input: {value: selected, onChange: onChangeSelected},
  } = useField('autoRefreshInterval');

  const handleChange = (selectedOption) => {
    if (selectedOption.value === NOW) {
      onChange(getDateValue(value));
    } else {
      onChangeSelected(selectedOption);
    }
  };

  useInterval(() => {
    if (selected.value !== INFINITY) {
      const noModalsOpened = document.getElementsByClassName('MuiBackdrop-root').length === 0;
      if (noModalsOpened) {
        onChange(getDateValue(value));
      }
    }
  }, selected.value * 1000);

  const buttonComponent = (
    <Box
      fontSize={18}
      display="flex"
      alignItems="center"
      bgcolor={selected.value === INFINITY ? 'gray.300' : 'blue.300'}
      borderRadius={6}
      padding="10px"
      height={35}
      color={selected.value !== INFINITY ? 'blue.500' : undefined}
    >
      <i className="icon icn-general16-refresh" />
    </Box>
  );

  return (
    <FormDdlSelect
      options={autoRefreshOptions}
      button={buttonComponent}
      selected={selected}
      optionComponent={<OptionComponentSimple />}
      onChange={handleChange}
      height={240}
      width={150}
      position="right"
      automationId="autoRefresh"
    />
  );
};

export default AutoRefreshContainer;
