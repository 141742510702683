// @flow
import React from 'react';

import SmartTooltip from 'common/components/SmartTooltip';

import './userEventsRow.module.scss';

type PropsType = {
  imageUrl: string,
  name: string,
  owner: string,
};

export default class UserEventsRow extends React.PureComponent {
  props: PropsType;

  render() {
    const {imageUrl, name, owner} = this.props;

    return (
      <div styleName="container">
        <div>
          <img src={imageUrl} alt="" />
        </div>
        <div>
          <p className="ellipsis">{name}</p>
        </div>

        <div>
          <p className="ellipsis">{owner}</p>
        </div>

        <div>
          <SmartTooltip content={imageUrl} placement="top">
            <p className="ellipsis">{imageUrl}</p>
          </SmartTooltip>
        </div>
      </div>
    );
  }
}
