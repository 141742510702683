/* eslint-disable max-len */
import {get, post, delete_, put} from 'common/utils/http';

const metricsRoot = 'api/v1/reports/metrics';
const reportsRoot = 'api/v1/reports';
const bcRoot = 'api/v1/bc/admin';

export const fetchStreams = ({payload}) =>
  get(
    `${metricsRoot}/cardinality/streams?id=${payload._id}&startTime=${payload.startTime}&endTime=${payload.endTime}&sort=size`,
  );

export const fetchStreamDimensions = (payload) =>
  get(
    `${metricsRoot}/cardinality?id=${payload._id}&stream=${payload.streamName}&startTime=${payload.startTime}&endTime=${payload.endTime}`,
  );

export const fetchStats = ({payload}) => get(`${metricsRoot}/stats?userId=${payload._id}`);

export const fetchStreamsReport = ({payload}) => get(`${bcRoot}/streams-report?userId=${payload._id}`);

export const fetchTaskLog = ({payload}) => get(`${bcRoot}/task-log?streamId=${payload._id}`);

export const fetchKibanaLog = ({payload}) =>
  get(`${bcRoot}/session-log?streamId=${payload.stream_id}&sessionId=${payload.session_id}`);

export const flushMetrics = ({payload}) => post(`${reportsRoot}/flush?userId=${payload._id}`, {});

export const getMetricsEps = ({payload}) => get(`${reportsRoot}/eps?token=${payload.customer_token}&rollup=short`);

export const searchMetrics = ({payload}) =>
  post(`api/v1/search/metrics?token=${payload.token}&includeComposite=true&compositeCount=true`, payload.body);

export const deleteMetrics = ({payload}) => delete_(`api/v1/metrics?token=${payload.token}`, payload.body);

export const downloadCustomerMetricsFile = (params) =>
  get(`api/v1/reports/customer/metrics/names?userId=${params.userId}`, {responseType: 'arraybuffer'});

export const fetchAlertsErrors = ({payload}) => get(`api/v1/reports/alerts?token=${payload.token}`);

export const fetchCompositeErrors = ({payload}) => get(`api/v1/reports/errors/composite?userId=${payload._id}`);

export const generateTemplates = ({payload}) =>
  put(`api/v1/customers/${payload.customerId}/generateTemplates`, payload);
