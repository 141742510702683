import {get} from 'lodash';
import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {getUniqueId} from 'common/utils/guid';

const gaDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  switch (type) {
    case actions.setSelectedStreamMetricsAndDimensions.TYPE:
    case actions.setSelectedStreamGaAccount.TYPE:
    case actions.setSelectedStreamGaProperty.TYPE:
    case actions.setSelectedStreamGaView.TYPE:
      return redWrapper();
    case actions.applyTemplateOnSelectedStream.TYPE:
      return redWrapper({basedOnTemplateId: payload});
    case actions.setSelectedStreamGaSegment.TYPE: {
      let mod = {};
      const stream = state.streams.data[getSelectedIndex()];
      if (payload.length > 0) {
        // some segment selected
        if (!stream.schema.columns.find((item) => item.sourceColumn === 'ga:segment')) {
          // this is the first segment to be added
          mod = {
            ...stream,
            schema: {
              columns: [
                ...stream.schema.columns,
                {
                  id: getUniqueId(),
                  sourceColumn: 'ga:segment',
                  name: 'Segment',
                  type: 'dimension',
                  hidden: false,
                },
              ],
              sourceColumns: [
                ...stream.schema.sourceColumns,
                {
                  id: 'ga:segment',
                  name: 'ga:segment',
                },
              ],
            },
            dimensions: [...stream.dimensions, 'ga:segment'],
            segments: payload.map((item) => get(item, 'id', item)),
          };
        } else {
          // this is NOT the first segment to be added
          mod = {
            ...stream,
            segments: payload.map((item) => get(item, 'id', item)),
          };
        }
      } else {
        // no segments selected
        mod = {
          schema: {
            columns: [...stream.schema.columns.filter((item) => item.sourceColumn !== 'ga:segment')],
            sourceColumns: [...stream.schema.sourceColumns.filter((item) => item.id !== 'ga:segment')],
          },
          dimensions: [...stream.dimensions.filter((item) => item !== 'ga:segment')],
          segments: [],
        };
      }
      return redWrapper(mod);
    }
    default:
      return state;
  }
});

export default gaDataStreamReducer;
