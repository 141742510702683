/* eslint-disable no-unused-vars */
import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const ACTION_PREFIX = 'anodot/dashboards/';
const mac = (actionName) => makeAction(actionName, ACTION_PREFIX);
const macAsync = (actionName) => makeAsyncAction(actionName, ACTION_PREFIX);

// eslint-disable-next-line import/prefer-default-export
export const fetchDashboardUserSettings = macAsync('FETCH_DASHBOARD_USER_SETTINGS');
export const updateDashboardUserSettings = macAsync('UPDATE_DASHBOARD_USER_SETTINGS');
export const fetchDashboardsTotal = macAsync('FETCH_DASHBOARDS_TOTAL');
export const updateFilterBtn = mac('UPDATE_FILTER_BTN');
export const fetchDashboards = macAsync('FETCH_DASHBOARDS');
export const fetchDashboardsV1 = macAsync('FETCH_DASHBOARDS_V1');
export const fetchDashboard = macAsync('FETCH_SINGLE_DASHBOARD');
export const updateDashboard = macAsync('UPDATE_DASHBOARD');
export const addTile = macAsync('ADD_TILE_DASHBOARD');
export const createDuplicateDashboard = macAsync('CREATE_DUPLICATE_DASHBOARD');
export const updateDashboardSettings = macAsync('UPDATE_DASHBOARD_SETTINGS');
export const deleteDashboard = macAsync('DELETE_DASHBOARD');
export const setFavorite = macAsync('SET_FAVORITE');
export const fetchDataDashboardsManagementPage = macAsync('FETCH_DATA_DASHBOARDS_MANAGEMENT');
export const fetchDatapoints = macAsync('FETCH_DATAPOINTS');
export const setChartActualData = mac('SET_CHART_ACTUAL_DATA');
export const clearChartCache = mac('CLEAR_CHART_CACHE');
export const fetchLastViewDashboards = macAsync('FETCH_LAST_VIEW_DASHBOARDS');
export const setLastViewDashboard = macAsync('SET_LAST_VIEW_DASHBOARD');
export const isStatTileModal = mac('IS_STAT_TILE_MODAL');
export const setDashboardData = mac('SET_DASHBOARD_DATA');
export const copyTile = macAsync('COPY_TILE');
export const getAllFilters = macAsync('DASHBOARD_GET_ALL_FILTERS');
export const uploadDashboard = macAsync('UPLOAD_DASHBOARD');
export const displayDashboardSnackBar = mac('DISPLAY_DASHBOARD_SNACK_BAR');

// Metric Explorer
export const fetchDashboardV1 = macAsync('FETCH_DASHBOARD_V1');
export const fetchDashboardListV1 = macAsync('FETCH_DASHBOARD_LIST_V1');
export const fetchDashboardListV2 = macAsync('FETCH_DASHBOARD_LIST_V2');
export const updateDashboardV1 = macAsync('UPDATE_DASHBOARD_V1');
export const getAnonymousInvitation = macAsync('GET_ANONYMOUS_INVITATION');
export const revokeAnonymousInvitations = macAsync('REVOKE_ANONYMOUS_INVITATIONS');
export const getAnonymousUser = macAsync('GET_ANONYMOUS_USER');
export const getTriggeredAlerts = macAsync('GET_TRIGGERED_ALERTS');
