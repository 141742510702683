// @flow

import React from 'react';

import ConceptsPanelHeader from './ConceptsPanelHeader';
import ConceptPanel from './ConceptPanel';

import './ConceptsPanel.module.scss';

const makeSelectedList = (list: [string], selected: boolean) => list.map((i) => ({...i, selected}));
const combinePossibleAndSelected = (possible, selected) => [
  ...makeSelectedList(possible, false),
  ...makeSelectedList(selected, true),
];

const ConceptsPanel = ({title, possibleConcepts, selectedConcepts, onAddItem, onRemoveItem}: any) => (
  <div styleName="panel">
    <ConceptsPanelHeader
      {...{
        title,
        onAddItem,
        onRemoveItem,
        concepts: combinePossibleAndSelected(possibleConcepts, selectedConcepts),
      }}
    />
    <div styleName="scroller">
      {selectedConcepts
        .sort((a, b) => a.uiName.localeCompare(b.uiName))
        .map(({id, type, uiName}) => (
          <ConceptPanel
            key={id}
            {...{
              id,
              type,
              uiName,
              onRemoveItem,
            }}
          />
        ))}
    </div>
  </div>
);

export default ConceptsPanel;
