import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const simulationFiltersIsDirtyReducer = composeReducers(
  (
    state = {
      isDirty: false,
      influencingIsDirty: false,
    },
    {type},
  ) => {
    switch (type) {
      case actions.setSelectedAlertType.TYPE:
        return {...state, isDirty: false};

      case actions.setSelectedAlertSignificance.TYPE:
      case actions.setSelectedAlertMinDuration.TYPE:
      case actions.setSelectedAlertMinDurationScale.TYPE:
      case actions.setSelectedAlertDirection.TYPE:
      case actions.setSelectedAlertAutoTuneByAnodot.TYPE:
      case actions.setSelectedAlertStaticThreshold.TYPE:
      case actions.setSelectedAlertMinDeltaAbs.TYPE:
      case actions.setSelectedAlertMinDeltaPercent.TYPE:
      case actions.setSelectedAlertMinDeltaAutoBoolVal.TYPE:
      case actions.addConditionToSelectedAlert.TYPE:
      case actions.setSelectedAlertInfluencingMetrics.TYPE:
      case actions.setSelectedAlertInfluencingMetricsSubCondition.TYPE:
      case actions.addSelectedAlertInfluencingMetricsSubCondition.TYPE:
      case actions.removeSelectedAlertInfluencingMetricsSubCondition.TYPE:
      case actions.removeConditionFromSelectedAlert.TYPE:
      case actions.setSelectedAlertInfluencingMetricsSubConditionV2.TYPE:
        return {...state, isDirty: true};

      default:
        return state;
    }
  },
);

export default simulationFiltersIsDirtyReducer;
