import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';

const fuDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  switch (type) {
    case actions.setFuStreamFileFormat.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      return {...redWrapper({...stream, uiState: null, fileFormat: {...stream.fileFormat, ...payload}})};
    }

    default:
      return state;
  }
});

export default fuDataStreamReducer;
