// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import {getOwners, getSelectedFilterOwner} from 'assets/store/selectors';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  setQueryParams: Function,
};

const OwnerFilter = ({setQueryParams}: PropTypes) => {
  const ownerList = useSelector(getOwners);
  const selectedOwners = useSelector(getSelectedFilterOwner);

  const onChange = (items) => {
    const ownerVal = items.length ? items.map((item) => item.value).join(',') : null;
    setQueryParams({owner: ownerVal});
  };

  return (
    <SelectAndt
      type={TYPE_NEW_MULTI}
      maxMenuHeight={175}
      minMenuHeight={75}
      buttonWidth={215}
      theme={THEME_HIGHLIGHTED}
      onChange={onChange}
      options={ownerList}
      value={selectedOwners}
      isMulti
      optionHeight={40}
      isClearable={false}
      closeMenuOnSelect={false}
      disabled={false}
      automationId="assetsOwnerFilter"
      placeholder="Select"
    />
  );
};

export default OwnerFilter;
