// @flow
import React from 'react';
import './MultiButton.module.scss';
import Button, {COLORS, HEIGHTS} from '../Button';

type PropTypes = {
  value: string,
  isDisabled: boolean,
  onClick: Function,
  isSelected: boolean,
  automationId: string,
  color: string,
};

export default class MultiButtonUnit extends React.PureComponent {
  props: PropTypes;

  render() {
    const {value, onClick, isDisabled, isSelected, color, automationId} = this.props;
    return (
      <Button
        automationId={automationId}
        colorSchema={isSelected ? COLORS.BLUE_500 : COLORS.TRANSPARENT}
        colorBar={color}
        height={HEIGHTS.TIGHT30}
        text={value}
        onClick={onClick}
        isDisabled={isDisabled}
        className="multi"
        extraClassName={isSelected ? 'is-selected' : ''}
      />
    );
  }
}
