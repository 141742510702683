// @flow
import React, {useState, useRef} from 'react';
import {get} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import * as selectors from 'metrics/store/selectors';
import * as metricsActions from 'metrics/store/actions';
import MultiLevelDropdown from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/MultiLevelDropdown';
import OptionComponent from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/OptionComponent';
import MultiOptionComponent from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/MultiOptionComponent';
import ButtonListedComponent from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlList/ButtonListedComponent';

const makeInputLabel = (value) => {
  const allItem = value.find((item) => item.value === '*');
  if (allItem) {
    return allItem.key;
  }
  return value.map((dim) => dim.label).join(', ');
};

const DimensionsInput = ({value, onChange, filter}: {value: Array, onChange: Function, filter: Array}) => {
  const dispatch = useDispatch();
  const fetchKey = useRef();
  const [firstSelected, setFirstSelected] = useState({});
  const cacheData = useSelector(selectors.getCacheData);
  const isFetchPropsLoading = useSelector(selectors.getFetchPropIsLoading);
  const isFetchPropAndValListApiLoading = useSelector(selectors.getIsFetchPropAndValApiListLoading);

  const fetchDimensionsList = () => {
    const fetchPayload = {
      expression: '',
      listDescription: [
        {
          valueType: 'dimensions',
          count: 50,
        },
      ],
      filter,
      search: '',
    };
    fetchKey.current = JSON.stringify(fetchPayload);
    dispatch(metricsActions.fetchPropAndValList(fetchPayload, fetchKey.current));
  };

  const fetchProps = (key) => {
    dispatch(
      metricsActions.fetchProps(
        {
          expression: '',
          filter: [],
          properties: [key],
          size: 50,
        },
        key,
      ),
    );
  };

  const dimensionsList = get(cacheData.find((obj) => obj.index === fetchKey.current), 'fetchedData', [])
    .slice(1)
    .map((item) => item.value);

  const options = dimensionsList.map((item) => ({
    value: item,
    label: item,
    key: item,
    multi: [
      {
        key: item,
        value: '*',
        label: 'All (*)',
      },
      ...get(cacheData.find((obj) => obj.index === item), 'fetchedData', []).map((prop) => ({
        key: item,
        value: prop.value,
        label: prop.value,
      })),
    ],
  }));

  return (
    <MultiLevelDropdown
      isAsyncData
      onToggle={fetchDimensionsList}
      optionComponent={<OptionComponent />}
      multiOptionComponent={<MultiOptionComponent />}
      onFirstSelect={(item) => {
        fetchProps(item.value);
        setFirstSelected(item);
        onChange([]);
      }}
      options={options}
      onSelect={onChange}
      value={value}
      rowHeight={40}
      isUseSearch
      width={352}
      buttonWidth={200}
      height={330}
      disabled={false}
      isFirstPanelLoading={isFetchPropAndValListApiLoading}
      isSecondPanelLoading={isFetchPropsLoading}
      button={<ButtonListedComponent placeholder="Select Dimensions" text={makeInputLabel(value)} />}
      secondLevelTitle={firstSelected.label}
      isMulti
    />
  );
};

export default React.memo(DimensionsInput);
