// @flow
import React, {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Button, {
  COLORS as BUTTON_COLORS,
  HEIGHTS as BUTTON_HEIGHTS,
  WIDTHS as BUTTON_WIDTHS,
} from 'common/componentsV2/Button';
import Tooltip from 'common/componentsV2/Tooltip';
import {
  toggleCustomerChangeStatusConfirmationModal,
  toggleCustomerDeleteConfirmationModal,
} from 'admin.customers/store/actions';
import './CustomersActionToolbar.module.scss';

type PropTypes = {
  customer: Object,
};

const CustomersActionToolbar = ({customer}: PropTypes) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const onEditClicked = useCallback(() => {
    history.push(`/admin/customers/${customer.id}${history.location.search}`);
  }, [customer.id]);
  const onToggleStatus = useCallback(() => {
    dispatch(
      toggleCustomerChangeStatusConfirmationModal({
        isOpen: true,
        customerId: customer.id,
        action: customer.status ? 'enable' : 'disable',
      }),
    );
  }, [customer]);
  const onDelete = useCallback(() => {
    dispatch(
      toggleCustomerDeleteConfirmationModal({
        isOpen: true,
        customerId: customer.id,
      }),
    );
  }, [customer]);

  const enableDisableButton = customer.status ? (
    <div styleName="btn">
      <Tooltip content="Enable" delay={300}>
        <Button
          colorSchema={BUTTON_COLORS.GHOST_LIGHT_BLUE}
          text="Enable"
          onClick={onToggleStatus}
          height={BUTTON_HEIGHTS.TIGHT30}
          width={BUTTON_WIDTHS.NARROW}
          automationId="customerEnableButton"
        />
      </Tooltip>
    </div>
  ) : (
    <div styleName="btn">
      <Tooltip content="Disable" delay={300}>
        <Button
          colorSchema={BUTTON_COLORS.GHOST_LIGHT_BLUE}
          text="Disable"
          onClick={onToggleStatus}
          height={BUTTON_HEIGHTS.TIGHT30}
          width={BUTTON_WIDTHS.NARROW}
          automationId="customerDisableButton"
        />
      </Tooltip>
    </div>
  );

  const deleteButton = customer.status && (
    <div styleName="btn">
      <Tooltip content="Delete" delay={300}>
        <Button
          colorSchema={BUTTON_COLORS.GHOST_LIGHT_BLUE}
          icon="icn-action16-delete"
          onClick={onDelete}
          height={BUTTON_HEIGHTS.TIGHT30}
          width={BUTTON_WIDTHS.NARROW}
          automationId="customerDeleteButton"
        />
      </Tooltip>
    </div>
  );

  return (
    <div styleName="action-toolbox">
      <div styleName="btn">
        <Tooltip content="Edit" delay={300}>
          <Button
            colorSchema={BUTTON_COLORS.BLUE_500}
            text="Edit"
            onClick={onEditClicked}
            icon="icn-action16-edit"
            height={BUTTON_HEIGHTS.TIGHT30}
            width={BUTTON_WIDTHS.REGULAR}
            automationId="customerEditButton"
          />
        </Tooltip>
      </div>
      {enableDisableButton}
      {deleteButton}
    </div>
  );
};

export default CustomersActionToolbar;
