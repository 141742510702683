// @flow
import React, {PureComponent} from 'react';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {getFormattedHumanDuration} from 'common/utils/dateService';
import './alertContent.module.scss';

type PropTypes = {
  duration: number,
};

class AlertDuration extends PureComponent {
  props: PropTypes;

  render() {
    const {duration} = this.props;
    const formattedRange = getFormattedHumanDuration(duration);
    const formattedMini = getFormattedHumanDuration(duration, 'short');

    return (
      <div className="alert-duration-wrapper">
        <SmartTooltip placement="top" content={formattedRange} delay={400}>
          <span className="ellipsis">{formattedMini}</span>
        </SmartTooltip>
      </div>
    );
  }
}

export default AlertDuration;
