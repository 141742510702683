import {get} from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const getEditorValidationMessage = (stream) => {
  if (!stream.adAccountId) {
    return 'Facebook Ads account is mandatory';
  }
  if (!stream.reportType) {
    return 'Facebook Ads report type is mandatory';
  }
  if (!stream.basedOnTemplateId) {
    return 'Facebook Ads stream template is mandatory';
  }
  if (!get(stream, 'metrics.length')) {
    return 'Data stream should have at least one metric';
  }
  return null;
};
