// @flow
import {has} from 'lodash';
import {getUniqueId} from 'common/utils/guid';

/**
 * Perform a visit on each tree node
 * @param root the root node
 * @param childPropertyName the name of the children property
 * @param a callback for each node visited
 */
export const treeVisitor = (root, childPropertyName, callback, fatherPropertyName = null) => {
  const locTreeVisitor = function(locRoot, locChildPropertyName, locCallback, level) {
    locCallback(locRoot, level);
    if (locRoot[locChildPropertyName] && locRoot[locChildPropertyName].length) {
      locRoot[locChildPropertyName].forEach((node) => {
        locTreeVisitor(
          fatherPropertyName && node[fatherPropertyName] ? node[fatherPropertyName] : node,
          locChildPropertyName,
          locCallback,
          level + 1,
        );
      });
    }
  };

  locTreeVisitor(root, childPropertyName, callback, 0);
};

export const findExpressionInTree = (root) => {
  let expressionNode;
  treeVisitor(root, 'children', (childNode) => {
    if (childNode.searchObject) {
      expressionNode = childNode;
    }
  });
  return expressionNode;
};

export const hasFunctionRoot = (treeModel) => has(treeModel, 'expressionTree.root.function');

export const getEmptyFunction = (treeId) => {
  const res = {
    children: [],
    function: '',
    id: getUniqueId(),
    parameters: [],
    type: 'function',
  };
  if (treeId) {
    res.treeId = treeId;
  }
  return res;
};

export const getDefaultFilterSortFunction = () => ({
  children: [],
  id: getUniqueId(),
  function: 'alphanumeric',
  type: 'function',
  parameters: [{name: 'Top N', value: 10}],
});

export const getRatioPairFunction = (children) => {
  const res = {
    children,
    function: 'ratioPairs',
    id: getUniqueId(),
    parameters: [],
    type: 'function',
  };
  return res;
};

export const getRootExpressionWithGroupByFunction = (aggregationValue, groupByProperties, searchObjectExpression) => {
  const res = {
    children: [searchObjectExpression],
    function: 'groupBy',
    id: getUniqueId(),
    parameters: [
      {
        name: 'Aggregation',
        value: aggregationValue,
      },
      {
        name: 'Group By',
        value: groupByProperties,
      },
    ],
    type: 'function',
  };
  return res;
};
