import React from 'react';
import AwsCurData from './awsCurData/AwsCurData';
import DimensionsAndMetrics from './awsCurDimensionsAndMetrics/DimensionsAndMetrics';
import AwsCurSchedular from './awsCurSchedular/AwsCurSchedular';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

import './AwsCurStreamEditor.module.scss';

/* eslint max-len: ["error", { "code": 200 }] */

const AwsCurStreamEditor = () => (
  <div styleName="root" className="shell-col">
    <div styleName="input-description">
      AWS CUR (Cost and Usage Reports) are created as .csv files, compressed using zip or gzip, and stored in your
      Amazon S3 bucket.
      <br />
      This is the expected structure of the report path:
      <br />
      &lt;example-report-prefix&gt;/&lt;example-report-name&gt;/yyyymmdd-yyyymmdd/&lt;assemblyId&gt;/&lt;example-report-name&gt;-&lt;file-number&gt;.csv.&lt;zip|gz&gt;
    </div>

    <StreamEditorSteps>
      <AwsCurData />
      <DimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <AwsCurSchedular />
      </div>
    </StreamEditorSteps>
  </div>
);

export default AwsCurStreamEditor;
