// @flow
import React from 'react';
import {subConditionsMeta, subConditionTypes} from 'alerts.management/services/alertsService';
import './SubConditions.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const EMPTY_OBJECT = {};
const EMPTY_ARRAY = [];
const EMPTY_STRING = '';

export default class SubConditions extends React.PureComponent {
  props: {
    subConditions: Array,
    id: String,
    onChangeSubCondition: Function,
    onRemoveSubCondition: Function,
  };

  getBetweenBounds = (value) => {
    switch (value) {
      case 'UPPER_EQUAL_AND_LOWER_EQUAL':
        return {lowerBound: 'UPPER_EQUAL', upperBound: 'LOWER_EQUAL'};
      case 'UPPER_EQUAL_AND_LOWER':
        return {lowerBound: 'UPPER_EQUAL', upperBound: 'LOWER'};
      case 'UPPER_AND_LOWER_EQUAL':
        return {lowerBound: 'UPPER', upperBound: 'LOWER_EQUAL'};
      case 'UPPER_AND_LOWER':
        return {lowerBound: 'UPPER', upperBound: 'LOWER'};
      default:
        return EMPTY_OBJECT;
    }
  };

  getSubConditionComponentsByType = (subCondition, index) => {
    switch (subCondition.type) {
      case subConditionTypes.above:
        return (
          <div styleName="components-row">
            <SubConditionBounds
              index={index.toString()}
              type={subCondition.type}
              onSelect={(v) =>
                this.props.onChangeSubCondition({
                  subPayload: {upperBound: v},
                  index,
                  id: this.props.id,
                })
              }
              value={subCondition.upperBound}
            />
            <input
              styleName="regular-input"
              type="number"
              className="dark-input"
              placeholder="value"
              value={subCondition.maxValue || EMPTY_STRING}
              onChange={(e) =>
                this.props.onChangeSubCondition({
                  subPayload: {maxValue: e.target.value},
                  index,
                  id: this.props.id,
                })
              }
            />
          </div>
        );

      case subConditionTypes.below:
        return (
          <div styleName="components-row">
            <SubConditionBounds
              index={index.toString()}
              type={subCondition.type}
              onSelect={(v) =>
                this.props.onChangeSubCondition({
                  subPayload: {lowerBound: v},
                  index,
                  id: this.props.id,
                })
              }
              value={subCondition.lowerBound}
            />
            <input
              styleName="regular-input"
              type="number"
              className="dark-input"
              placeholder="value"
              value={subCondition.minValue || EMPTY_STRING}
              onChange={(e) =>
                this.props.onChangeSubCondition({
                  subPayload: {minValue: e.target.value},
                  index,
                  id: this.props.id,
                })
              }
            />
          </div>
        );

      case subConditionTypes.between:
        return (
          <div styleName="components-row">
            <SubConditionBounds
              index={index.toString()}
              type={subCondition.type}
              onSelect={(v) =>
                this.props.onChangeSubCondition({
                  subPayload: this.getBetweenBounds(v),
                  index,
                  id: this.props.id,
                })
              }
              value={`${subCondition.lowerBound}_AND_${subCondition.upperBound}`}
            />
            <input
              styleName="regular-input"
              type="number"
              className="dark-input"
              placeholder="value"
              value={subCondition.maxValue || EMPTY_STRING}
              onChange={(e) =>
                this.props.onChangeSubCondition({
                  subPayload: {maxValue: e.target.value},
                  index,
                  id: this.props.id,
                })
              }
            />
            <input
              styleName="regular-input"
              type="number"
              className="dark-input"
              placeholder="value"
              value={subCondition.minValue || EMPTY_STRING}
              onChange={(e) =>
                this.props.onChangeSubCondition({
                  subPayload: {minValue: e.target.value},
                  index,
                  id: this.props.id,
                })
              }
            />
          </div>
        );

      default:
        return null;
    }
  };

  render() {
    const {onChangeSubCondition, onRemoveSubCondition, id} = this.props;

    return (
      <div styleName="root">
        {(this.props.subConditions || EMPTY_ARRAY).map((b, i) => (
          <div key={i.toString()} styleName="components-row">
            <SubConditionFunc
              index={i.toString()}
              type={b.type}
              onSelect={(v) => onChangeSubCondition({subPayload: {type: v}, index: i, id})}
            />

            {this.getSubConditionComponentsByType(b, i)}

            {i > 0 && (
              <i
                styleName="remove-condition"
                className="icon icn-general16-closea"
                onClick={() => onRemoveSubCondition({id, index: i})}
              />
            )}
          </div>
        ))}
      </div>
    );
  }
}

const SubConditionFunc = (props: {index: String, type: String, onSelect: Function}) => {
  const selectedIndex = subConditionsMeta.findIndex((val) => val.value === props.type);
  return (
    <SelectAndt
      id={`ddl-threshold-${props.index}`}
      automationId="subConditionFunc"
      className="andt-dropdown"
      styleName="sub-condition-bound-ddl"
      options={subConditionsMeta}
      getOptionLabel={(val) => val.text}
      getOptionValue={(val) => val.value}
      type={TYPE_NO_SEARCH}
      theme={THEME_LIGHT}
      value={subConditionsMeta[selectedIndex]}
      onChange={(item) => props.onSelect(item.value)}
      placeholder="Function"
    />
  );
};

const SubConditionBounds = (props: {index: String, type: String, value: String, onSelect: Function}) => {
  const {boundsBy} = subConditionsMeta.find((b) => b.value === props.type);
  const selectedIndex = boundsBy.findIndex((val) => props.value === val.value);

  return (
    <SelectAndt
      id={`ddl-bounds-${props.index}`}
      className="andt-dropdown"
      styleName="sub-condition-bound-ddl"
      options={boundsBy}
      getOptionLabel={(val) => val.text}
      getOptionValue={(val) => val.value}
      type={TYPE_NO_SEARCH}
      theme={THEME_LIGHT}
      value={boundsBy[selectedIndex]}
      onChange={(item) => props.onSelect(item.value)}
      placeholder={null}
      automationId="subConditionBounds"
    />
  );
};
