// flow
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import SmartTooltip from 'common/componentsV2/Tooltip';
import * as selectors from 'insightsPanel/store/selectors';
import * as actions from 'insightsPanel/store/actions';
import PieChart from 'charts/pieChart/PieChart';
import {FEEDBACK} from 'charts/pieChart/pieChartSettingsService';
import {shouldFetchData, fadeOpacity} from 'insightsPanel/services/constants';
import './FeedbackDistribution.module.scss';
import {segmentClickEvent} from '../../../common/store/actions';

type PropTypes = {
  title: String,
  onFilterAlerts: Function,
};

const FeedbackDistribution = (props: PropTypes) => {
  const feedbackDistribution = useSelector(selectors.getFeedbackDistribution);
  const feedbackDistributionData = useSelector(selectors.getFeedbackDistributionData);
  const isLoading = useSelector(selectors.getIsLoadinggeFeedbackDistribution);
  const isPanelOpen = useSelector(selectors.getIsPanelOpen);
  const numLastDays = useSelector(selectors.getNumLastDays);
  const dispatch = useDispatch();
  const [showData, setShowData] = useState(false);
  const isFirstRun = useRef(true);
  let timer;

  useEffect(() => {
    const params = {
      numLastDays: numLastDays.value,
    };
    if (isPanelOpen && shouldFetchData(feedbackDistribution)) {
      dispatch(actions.fetchFeedbackDistribution(params));
    }
  }, [isPanelOpen]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const params = {
      numLastDays: numLastDays.value,
    };
    dispatch(actions.fetchFeedbackDistribution(params));
  }, [numLastDays]);

  useEffect(() => {
    if (feedbackDistributionData) {
      // for some reason Transition duration does not work.
      timer = setTimeout(() => setShowData(true), fadeOpacity.duration);
    }
  }, [feedbackDistributionData]);

  useEffect(
    () => () => {
      clearTimeout(timer);
    },
    [isPanelOpen],
  );

  const segmentTracking = (name) => {
    dispatch(
      segmentClickEvent({
        category: 'Insights Panel',
        name,
      }),
    );
  };

  const clickOnFeedback = (event) => {
    let feedback = null;

    if (typeof event === 'object') {
      feedback = event.point.type;
    }
    if (typeof event === 'string') {
      feedback = event;
    }
    if (feedback === 'goodCatch') {
      props.onFilterAlerts({positiveFeedback: true});
    } else if (feedback === 'notInteresting') {
      props.onFilterAlerts({negativeFeedback: true});
    }
    segmentTracking(`Feedback Per Measure Widget - ${feedback}`);
  };

  const goodCatch = feedbackDistributionData.find((i) => i.id === 'goodCatch').percentage;
  const notInteresting = feedbackDistributionData.find((i) => i.id === 'notInteresting').percentage;

  const tooltipFormatter = {
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      return `<div class="pie-chart-tooltip">${Math.round(this.percentage)}%${' '}(${this.y})${' - '}${this.key}</div>`;
    },
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && feedbackDistributionData && (
        <Transition in={showData} timeout={0}>
          {(state) => (
            <Fragment>
              <header styleName="header" style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}>
                {props.title}
              </header>
              <div styleName="container">
                {goodCatch === 0 && notInteresting === 0 ? (
                  <div styleName="no-feedback" />
                ) : (
                  <PieChart
                    onClickEvent={clickOnFeedback}
                    id="insightsPanelMetricsUsage"
                    height={61}
                    width={61}
                    data={feedbackDistributionData}
                    theme={FEEDBACK}
                    tooltipFormatter={tooltipFormatter}
                  />
                )}

                <SmartTooltip content="Show Alerts" delay={300}>
                  <div
                    onClick={() => clickOnFeedback('goodCatch')}
                    styleName="legend positive"
                    style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}
                  >
                    <div styleName="number">
                      <span>{goodCatch}%</span>
                      <i className="icon icn-general24-like" />
                    </div>

                    <span styleName="text">Good Catch</span>
                  </div>
                </SmartTooltip>

                <SmartTooltip content="Show Alerts" delay={300}>
                  <div
                    onClick={() => clickOnFeedback('notInteresting')}
                    styleName="legend"
                    style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}
                  >
                    <div styleName="number">
                      <span>{notInteresting}%</span>
                      <i className="icon icn-general24-dislike" />
                    </div>
                    <span styleName="text">Not Interesting</span>
                  </div>
                </SmartTooltip>
              </div>
            </Fragment>
          )}
        </Transition>
      )}
    </div>
  );
};

export default FeedbackDistribution;
