import React from 'react';
import {useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import {getSortFiltersList} from 'metrics/store/selectors';

import SelectAndt, {THEME_BLUE_LEAN_MODIFIED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  value: any,
  change: Function,
  placeholder: string,
};

const Sort = (props: PropTypes) => {
  const {
    input: {value, onChange},
  } = useField('sort');
  const definitions = useSelector(getSortFiltersList);

  const getValue = () => {
    const ddValue = props.value ? props.value : value;
    let ret = definitions.find((item) => item.value === ddValue);
    if (props.value === null) {
      ret = null;
    }
    return ret;
  };

  const handleChange = (val) => {
    if (props.change) {
      props.change(val.value, 'sort');
    } else {
      onChange(val.value);
    }
  };

  return (
    <SelectAndt
      id="sortField"
      automationId="sortField"
      extraClassName="alerts-dropdown-btn"
      options={definitions}
      optionHeight={40}
      menuWidth={170}
      type={TYPE_NEW_NO_SEARCH}
      theme={THEME_BLUE_LEAN_MODIFIED}
      onChange={handleChange}
      value={getValue()}
      placeholder={props.placeholder || 'Auto'}
    />
  );
};

export default Sort;
