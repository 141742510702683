// @flow

import React, {PureComponent} from 'react';

import './SearchBox.module.scss';

const SearchIcon = () => <div styleName="icon-button" className="icon icn-icon-search" />;
const ClearIcon = () => <div styleName="icon-button clear" className="icon icn-icon-table-delete" />;

class SearchBox extends PureComponent {
  props: {
    isAutoStretch: boolean,

    filter: string,
    onFilter: Function,
    isBlueBackground: boolean,
    autoFocus?: Boolean,
    analytics?: Object,
    placeholder?: String,
  };

  static defaultProps = {
    autoFocus: false,
    analytics: null,
    placeholder: null,
  };

  state = {
    isActive: false,
  };

  onClearSearchExpresion = (event) => {
    event.preventDefault();
    this.props.onFilter('');
  };

  render() {
    const {filter, onFilter, isBlueBackground, autoFocus, analytics, placeholder, isAutoStretch} = this.props;
    const {isActive} = this.state;

    return (
      <div
        styleName={`search-box${isActive ? ' active' : ''}${isBlueBackground ? ' blue-bg' : ''}`}
        className="search-box-wrapper"
      >
        <input
          styleName={isAutoStretch ? 'auto-stretch' : ''}
          type="text"
          automation-id="dataCollectorSearchTextbox"
          autoFocus={autoFocus}
          value={filter}
          onChange={(ev) => onFilter(ev.target.value)}
          onFocus={() => this.setState({isActive: true})}
          onBlur={() => this.setState({isActive: false})}
          placeholder={placeholder || 'Search'}
          className="analytics-input"
          data-andt-analytics-category={analytics ? analytics.category : null}
          data-andt-analytics-action={analytics ? analytics.action : null}
        />
        <div className="shell" styleName="add-on">
          {filter.length > 0 && (
            <button type="button" className="btn btn-flat btn-icon" onClick={this.onClearSearchExpresion}>
              {filter.length > 0 && <ClearIcon />}
            </button>
          )}
          {!filter && <SearchIcon />}
        </div>
      </div>
    );
  }
}

export default SearchBox;
