import {composeReducers} from 'common/utils/reducers';

const initialState = [
  {
    id: 'alertsSubscribe',
    url: 'alerts',
    condition: 'alertConfigurationsTotal',
    conditionLoading: 'isLoadingAlertConfigurationsTotal',
    title: {
      withData: 'Subscribe to Alerts',
      noData: 'Create Your First Alert',
    },
    description: {
      withData:
        'Anodot alerts you on anomalies that matter to you,\n' +
        'when they matter to you.\n' +
        'Subscribe to alerts and get notified in your email inbox.',
      noData:
        'Stay on top of your data - create an alert on metrics that are important to you,\n' +
        'and get email notifications on anomalies or missing data.',
    },
    missionAccomplished: false,
    imgClass: 'img-1',

    btnTitle: 'See Alerts',
    btnLink: 'alerts',
    gauges: [
      {
        componentName: 'AlertsConfigurationGauge',
        description: 'Alerts\nSet up',
      },
      {
        componentName: 'AlertsTriggeredGauge',
        description: 'Alerts triggered\nin the last 24 hours',
      },
    ],
  },
  {
    id: 'checkDashboards',
    url: 'dashboards',
    condition: 'fetchDashboardTotal',
    conditionLoading: 'isLoadingFetchDashboardTotal',
    title: {
      withData: 'Check Out Dashboards',
      noData: 'Create Your First Dashboard',
    },
    description: {
      withData:
        'Dashboards are created by you (and other users) to get better visualization of specific data,\n' +
        'and share it with other stakeholders.',
      noData:
        'Create your first dashboard to get better visualization of specific data you’re interested in.\n' +
        'Add widgets like charts, meters or text and organize them however you like.',
    },
    missionAccomplished: false,
    imgClass: 'img-2',
    btnTitle: 'Dashboards',
    btnLink: 'dashboards',
    gauges: [
      {
        componentName: 'DashboardsGauge',
        description: 'Dashboards\nSet up',
      },
    ],
  },
  {
    id: 'metricSearch',
    url: 'metrics',
    condition: 'fetchMetricsTotal',
    conditionLoading: 'isLoadingMetricsTotal',
    title: {
      withData: 'Try out the Metric Search',
      noData: 'Try out the Metric Search',
    },
    description: {
      withData:
        'The Metric Search allows you to search for any metric you’re interested in,\n' +
        'within all the data that your company streams into Anodot.\n' +
        'You can play with different dimensions, group metrics, apply filters or functions and much more.',
      noData:
        'The Metric Search allows you to search for any metric you’re interested in,\n' +
        'within all the data that your company streams into Anodot.\n' +
        'You can play with different dimensions, group metrics, apply filters or functions and much more.',
    },
    missionAccomplished: false,
    imgClass: 'img-3',
    btnTitle: 'Metric Search',
    btnLink: 'metrics/composite',
    gauges: [
      {
        componentName: 'MetricsGauge',
        description: 'Metrics\nin Anodot',
      },
    ],
  },
  {
    id: 'dataSources',
    url: 'bc',
    condition: 'fetchActiveStreamsTotal',
    conditionLoading: 'isDataStreamsLoading',
    title: {
      withData: 'Look at the Data Sources',
      noData: 'Start streaming data into Anodot',
    },
    description: {
      withData:
        'Data sources are where Anodot gets all your data from.\n' +
        'Review the existing data sources or add a new one.',
      noData:
        'Data sources are where Anodot gets all your data from.\n' +
        'We support various source types and integrations.\n' +
        'Start streaming your data by setting up the first data source' +
        '- we can’t wait to show you what we can do with your data.',
    },
    missionAccomplished: false,
    imgClass: 'img-4',
    btnTitle: 'Streams',
    btnLink: 'r/bc/data-manager',
    gauges: [
      {
        componentName: 'StreamsGauge',
        description: 'Data\nSources',
      },
    ],
  },
];

const pageReducer = (state = initialState) => state;

export default composeReducers(pageReducer);
