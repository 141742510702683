// @flow
import React, {Fragment} from 'react';
import './StyleGuideComponents.module.scss';
import SwitchButton from 'common/componentsV2/SwitchButton';

export default class SwitchButtonSection extends React.PureComponent {
  state = {
    sw1: true,
    sw2: false,
  };

  onSwitch1 = (res) => {
    const newState = {};
    newState[res.eventKey] = res.value;
    this.setState(newState);
  };

  onSwitch2 = (checked) => {
    this.setState({
      sw2: checked,
    });
  };

  render() {
    return (
      <Fragment>
        <h4>Switch Button</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Regular Switch Button</h5>
            <SwitchButton isChecked={this.state.sw1} eventKey="sw1" onChange={this.onSwitch1} />
            <SwitchButton isChecked={this.state.sw2} onChange={this.onSwitch2} />
          </div>
        </div>
      </Fragment>
    );
  }
}
