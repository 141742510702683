import {useCallback} from 'react';
import {parseQS, stringifyQS} from 'common/utils/http';
import {useSelector} from 'react-redux';
import * as appSelectors from 'app/store/selectors';
import {get, keyBy} from 'lodash';

const useV1LinkMaker = () => {
  const appNavBarFilteredItems = useSelector(appSelectors.getAppNavBarFilteredItems);
  return useCallback(
    (id) => {
      const itemsMap = keyBy(appNavBarFilteredItems, 'id');
      let url = get(itemsMap, 'DASHBOARDS.lastVisitedurl');
      if (url) {
        if (url.indexOf(id) === -1) {
          const query = parseQS(url);
          const newTabs = `${query.tabs};${id}`;
          url = `${url.split('?')[0]}?${stringifyQS({
            ...query,
            tabs: newTabs,
            activeTab: newTabs.split(';').length - 1,
          })}`;
        } else {
          const query = parseQS(url);
          url = `${url.split('?')[0]}?${stringifyQS({
            ...query,
            activeTab: query.tabs.split(';').indexOf(id),
          })}`;
        }
      }
      return url || `${window.location.origin}/#!/dashboards?activeTab=1&tabs=main;${id}`;
    },
    [appNavBarFilteredItems],
  );
};

export default useV1LinkMaker;
