import {makeAsyncAction, makeAction} from 'common/utils/simplifiedAsync';

const ACTION_PREFIX = 'anodot/impact/';
const mac = (actionName) => makeAction(actionName, ACTION_PREFIX);
const macAsync = (actionName) => makeAsyncAction(actionName, ACTION_PREFIX);

export const setFilesListOrder = mac('SET_FILES_LIST_ORDER');
export const setAssetsFilters = mac('SET_ASSETS_FILTERS');
export const setResetFilters = mac('SET_RESET_FILTERS');
export const setScrollbarWidth = mac('SET_SCROLLBAR_WIDTH');
export const setAssetsMngFiltersIsOpen = mac('SET_ASSETS_MNG_FILTERS_IS_OPEN');
export const displaySnackBar = mac('DISPLAY_SNACK_BAR');

export const addDynamicRoutingFile = mac('ADD_DYNAMIC_ROUTING_FILE');
export const updateDynamicRoutingFile = mac('UPDATE_DYNAMIC_ROUTING_FILE');

export const getDynamicRoutingFiles = macAsync('GET_DYNAMIC_ROUTING_FILES');
export const uploadDynamicRoutingFile = macAsync('UPLOAD_DYNAMIC_ROUTING_FILE');
export const replaceDynamicRoutingFile = macAsync('REPLACE_DYNAMIC_ROUTING_FILE');
export const deleteDynamicRoutingFile = macAsync('DELETE_DYNAMIC_ROUTING_FILE');
export const viewDynamicRoutingFile = macAsync('VIEW_DYNAMIC_ROUTING_FILE');
