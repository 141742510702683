import React from 'react';
import {useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import {getInvModalTrigger} from '../../store/selectors';

const MORE_ACTIONS_OPTIONS = [{value: 'editAlert', label: 'Edit alert'}];

const MoreActions = () => {
  const trigger = useSelector(getInvModalTrigger);

  function goTo(value) {
    switch (value) {
      case 'editAlert':
        window.open(`/#!/r/alert-manager/edit/${trigger.alertConfigurationId}/settings`);
        break;
      default:
    }
  }
  return (
    <Box ml={1.5}>
      <FormDdlSelect
        automationId="inv-header-more-actions"
        position="right"
        width={135}
        options={MORE_ACTIONS_OPTIONS}
        // selected={value}
        button={<div className="icon icn-icon-3-dots" />}
        optionComponent={<OptionComponentSimple />}
        onChange={(e) => goTo(e.value)}
      />
    </Box>
  );
};

export default MoreActions;
