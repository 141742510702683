import React, {PureComponent} from 'react';
import {components} from 'react-select2';
import UnoMultiValue from './UnoMultiValue';
import DelimiterMultiValue from './DelimiterMultiValue';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  selectProps: any,
  children: any,
  isMulti: boolean,
};

export default class ValueContainer extends PureComponent {
  props: PropTypes;

  renderValue = () => {
    const {selectProps, isMulti} = this.props;
    const computedValue = selectProps.appliedValue || selectProps.value;
    if (selectProps.showValue === false) {
      return (
        <div className="andt-dropdown-uno-multivalue" automation-id="dropdown-multi-val">
          <span styleName="custom-value">{selectProps.placeholder}</span>
        </div>
      );
    }
    if (!computedValue && !selectProps.length && selectProps.placeholder && selectProps.placeholder !== '') {
      return <div className="andt-dropdown-placeholder">{selectProps.placeholder}</div>;
    }
    if (isMulti && computedValue && computedValue.length === 0) {
      return <div className="andt-dropdown-placeholder">{selectProps.placeholder}</div>;
    }
    if (isMulti && !selectProps.multiDelimiter) {
      return <UnoMultiValue selectProps={selectProps} />;
    }
    if (isMulti && selectProps.multiDelimiter) {
      return <DelimiterMultiValue selectProps={selectProps} />;
    }
    if (!computedValue) {
      return <div className="andt-dropdown-placeholder">{selectProps.placeholder}</div>;
    }
    return <div styleName="custom-value">{computedValue ? selectProps.getOptionLabel(computedValue) : ''}</div>;
  };

  render() {
    return (
      <components.ValueContainer className="andt-dropdown-value-container" {...this.props}>
        {this.renderValue()}
        {this.props.children}
      </components.ValueContainer>
    );
  }
}
