import {useDispatch, useSelector} from 'react-redux';

import {fetchSimulationData} from 'alerts.management/store/actions';
import * as amSelectors from 'alerts.management/store/selectors';
import useDeepCompareEffect from 'common/utils/useDeepCompareEffect';
import {stringifyQS} from 'common/utils/http';

const defaultEvents = {
  filter: {
    categories: [],
    q: {
      expression: [
        {
          type: 'property',
          key: 'source',
          value: 'NOT anodot',
        },
      ],
    },
  },
  aggregation: {
    aggregationField: null,
    resolution: null,
    topEventSize: 0,
    maxBuckets: 100,
  },
};

const useExecuteSimulation = ({compositeObject, conditions}) => {
  const dispatch = useDispatch();
  const fetchSimulationResponse = useSelector(amSelectors.getFetchSimulationData);

  useDeepCompareEffect(() => {
    if (compositeObject) {
      dispatch(
        fetchSimulationData({
          body: {
            settings: {
              type: 'anomaly',
              rollup: 'medium',
              aggregateAnomalies: true,
              conditions,
              userEventsRequest: defaultEvents,
              isInfluencingEvents: false,
            },
            query: {
              composite: compositeObject,
            },
          },
          urlExt: stringifyQS({
            index: 0,
            size: 1,
          }),
        }),
      );
    }
  }, [compositeObject, conditions]);
  return fetchSimulationResponse;
};

export default useExecuteSimulation;
