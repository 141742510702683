import React, {useCallback} from 'react';
import {isEqual} from 'lodash';
import {useField} from 'react-final-form';

import DateRangesDdl, {THEME} from 'common/components/dateTime/DateRangesDdl';

const DateRanges = () => {
  const {
    input: {value, onChange},
  } = useField('dateRange', {isEqual});

  const {
    input: {onChange: onChangeIsAutoDateRange},
  } = useField('isAutoDateRange');

  const onChangeHandler = useCallback((data) => {
    onChangeIsAutoDateRange(false);
    onChange(data);
  }, []);

  return <DateRangesDdl dateRange={value} onChange={onChangeHandler} theme={THEME.BLUE_LEAN} />;
};

export default DateRanges;
