import React, {PureComponent} from 'react';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  selectProps: any,
};

export default class DelimiterMultiValue extends PureComponent {
  props: PropTypes;

  render() {
    const {value, appliedValue, getOptionLabel, multiDelimiter} = this.props.selectProps;
    const getValueText = (values) => {
      if (!values.length) {
        return '';
      }
      return values.map((val) => getOptionLabel(val)).join(multiDelimiter);
    };

    return (
      <div className="andt-dropdown-delimiter-multivalue" automation-id="dropdown-multi-val">
        <span styleName="custom-value">{getValueText(appliedValue || value)}</span>
      </div>
    );
  }
}
