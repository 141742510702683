// @flow
import React from 'react';
import './NoData.module.scss';

type PropTypes = {
  className: String,
  isNoStreamImage: boolean,
  text: String,
  textStyle: String,
  title: String,
};

const NoData = (props: PropTypes) => (
  <div styleName="root" className={props.className ? props.className : ''}>
    <div styleName={props.isNoStreamImage ? 'not-found-streams-image2' : 'not-found-streams-image'} />
    {props.title ? <div styleName="title">{props.title}</div> : null}
    <span styleName={props.textStyle ? props.textStyle : 'text'}>{props.text ? props.text : 'No Data'}</span>
  </div>
);

export default NoData;
