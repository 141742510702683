import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {getUniqueId} from 'common/utils/guid';
import {Observable} from 'rxjs';
import * as commonActions from 'common/store/actions';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

const getSchemaColType = (tableType) => {
  switch (tableType) {
    case 'int':
    case 'integer':
    case 'number':
      return 'metric';
    case 'other':
      return 'dimension';
    default:
      return 'dimension';
  }
};

const fetchMysqlDataSourceListDatabases = makeAsyncEpic(
  actions.fetchMysqlDataSourceListDatabases,
  api.fetchMysqlDataSourceListDatabases,
);
const fetchSqlTablesViewsList = makeAsyncEpic(actions.fetchSqlTablesViewsList, api.fetchSqlTablesViewsList);
const fetchSqlSchemasList = makeAsyncEpic(actions.fetchSqlSchemasList, api.fetchSqlSchemasList);
const fetchSqlTableData = makeAsyncEpic(actions.fetchSqlTableData, api.fetchSqlTableData);
const fetchSqlTablePreview = makeAsyncEpic(actions.fetchSqlTablePreview, api.fetchSqlTablePreview);
const fetchSqlVerifyQuery = makeAsyncEpic(actions.fetchSqlVerifyQuery, api.fetchSqlVerifyQuery);
const fetchSqlQueryTemplates = makeAsyncEpic(actions.fetchSqlQueryTemplates, api.fetchStreamTemplates);
const fetchAthenaWorkgroupsList = makeAsyncEpic(actions.fetchAthenaWorkgroupsList, api.fetchAthenaWorkgroupsList);
const fetchAthenaWorkgroupDescription = makeAsyncEpic(
  actions.fetchAthenaWorkgroupDescription,
  api.fetchAthenaWorkgroupDescription,
);
const fetchAthenaDatabasesList = makeAsyncEpic(actions.fetchAthenaDatabasesList, api.fetchAthenaDatabasesList);

const fetchTimestreamDataSourceListDatabases = makeAsyncEpic(
  actions.fetchTimestreamDataSourceListDatabases,
  api.fetchTimestreamDataSourceListDatabases,
);

const setSqlStreamQuery = (action$, {getState}) =>
  action$.ofType(actions.setSqlStreamQuery.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    const res = {
      basedOnTemplateId: action.payload.basedOnTemplateId || '',
      query: action.payload.query,
      customQuery: true,
      schema: {
        columns: [],
        sourceColumns: [],
      },
      metrics: [],
      dimensions: [],
      timestampColumn: null,
      timestampType: null,
      uiState: {
        ...stream.uiState,
        tablesViewsMetadata: action.payload.queryPreviewColumns,
        unAssignedColumns: [],
        editEnabled: true,
        customQuery: true,
        selectedRadio: action.payload.basedOnTemplateId ? 'template' : 'query',
      },
    };
    action.payload.queryPreviewColumns.forEach((item) => {
      if (['number', 'integer', 'int'].indexOf(item.type) !== -1) {
        // eslint-disable-next-line no-param-reassign
        item.possibleTypes = ['number', 'timestamp'];
      }
      if (item.type !== 'timestamp') {
        let exsistInSource = false;
        res.schema.sourceColumns.forEach((sourceItem) => {
          if (sourceItem.id === item.name) {
            exsistInSource = true;
          }
        });
        if (!exsistInSource) {
          res.schema.columns.push({
            id: getUniqueId(),
            sourceColumn: item.name,
            name: item.name,
            type: getSchemaColType(item.type),
          });
          res.schema.sourceColumns.push({
            id: item.name,
            name: item.name,
          });
          if (item.type === 'other') {
            res.dimensions.push(item.name);
          } else {
            res.metrics.push(item.name);
          }
        }
      } else if (item.type === 'timestamp' && !res.timestampColumn) {
        res.timestampColumn = item.name;
        res.timestampType = item.type === 'timestamp' ? 'timestamp' : 'epoch_seconds';
      } else {
        res.uiState.unAssignedColumns.push(item.name);
      }
    });

    // add concat columns from previous run
    if (stream && stream.schema && stream.schema.columns) {
      stream.schema.columns.forEach((item) => {
        if (item && item.transform && item.transform.name === 'const') {
          res.schema.columns.push(item);
        }
      });
    }

    return [actions.setSelectedStreamKeyVal(res)];
  });

const fetchSqlQueryTemplatePreview = (action$, {getState}) =>
  action$.ofType(actions.fetchSqlQueryTemplatePreview.TYPE).switchMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    const meta = {...action.payload};

    return api
      .fetchSqlQueryPreview({
        payload: {
          streamType: stream.type,
          query: action.payload.query,
          dataSourceId: stream.dataSourceId,
          timeZone: stream.timeZone,
          pollingInterval: stream.pollingInterval,
          historyRange: stream.historicalDateRange.constRange,
        },
      })
      .map((payload) => actions.fetchSqlQueryTemplatePreview.success(payload, meta))
      .catch((error) => Observable.of(actions.fetchSqlQueryTemplatePreview.failure(error, meta)));
  });

const fetchSqlQueryTemplatePreviewSuccess = (action$) =>
  action$.ofType(actions.fetchSqlQueryTemplatePreview.success.TYPE).switchMap((action) => [
    actions.setSqlStreamQuery({
      basedOnTemplateId: action.meta.basedOnTemplateId,
      query: action.meta.query,
      useLegacySQL: action.meta.useLegacySQL,
      queryPreviewColumns: action.payload.columns,
    }),
  ]);

const fetchSqlQueryPreview = (action$, {getState}) =>
  action$.ofType(actions.fetchSqlQueryPreview.TYPE).switchMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());

    return api
      .fetchSqlQueryPreview({
        payload: {
          streamType: stream.type,
          query: action.payload.query,
          dataSourceId: stream.dataSourceId,
          timeZone: stream.timeZone,
          pollingInterval: stream.pollingInterval,
          historyRange: stream.historicalDateRange.constRange,
        },
      })
      .map((payload) => actions.fetchSqlQueryPreview.success(payload, action.meta))
      .catch((error) => Observable.of(actions.fetchSqlQueryPreview.failure(error, action.meta)));
  });

const fetchSqlQueryPreviewSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchSqlQueryPreview.success.TYPE).flatMap(() => {
    const stream = selectors.getSelectedDataStream(getState());
    if (stream) {
      return [
        commonActions.segmentClickEvent({
          category: `Data collectors - ${stream.type}`,
          name: 'Query editor - OK',
        }),
      ];
    }
    return [];
  });

const fetchSqlQueryPreviewFailure = (action$, {getState}) =>
  action$.ofType(actions.fetchSqlQueryPreview.failure.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    if (stream) {
      return [
        commonActions.segmentClickEvent({
          category: `Data collectors - ${stream.type}`,
          name: `Query editor - FAIL: ${action.payload.message}`,
        }),
      ];
    }
    return [];
  });

const setSqlStreamSchemaName = (action$, {getState}) =>
  action$
    .ofType(actions.setSqlStreamSchemaName.TYPE)
    .switchMap(() => [selectors.getSelectedDataStream(getState())])
    .flatMap((stream) => [
      actions.fetchSqlTablesViewsList({
        type: stream.type,
        dataSourceId: stream.dataSourceId,
        schemaName: stream.schemaName ? stream.schemaName : '',
        customQuery: false,
      }),
    ]);

const setSqlStreamTableName = (action$, {getState}) =>
  action$
    .ofType(actions.setSqlStreamTableName.TYPE)
    .switchMap(() => [selectors.getSelectedDataStream(getState())])
    .flatMap((stream) => [
      actions.fetchSqlTableData({
        type: stream.type,
        dataSourceId: stream.dataSourceId,
        tableName: stream.tableName,
        schemaName: stream.schemaName ? stream.schemaName : '',
      }),
    ]);

const fetchSqlTableDataSuccess = (action$) =>
  action$.ofType(actions.fetchSqlTableData.success.TYPE).flatMap((action) => [actions.setSqlTableData(action.payload)]);

const SqlEpic = combineEpics(
  fetchMysqlDataSourceListDatabases,
  fetchSqlSchemasList,
  fetchSqlTablePreview,
  fetchSqlTableData,
  fetchSqlTablesViewsList,
  fetchSqlQueryPreview,
  fetchSqlQueryPreviewSuccess,
  fetchSqlTableDataSuccess,
  fetchSqlQueryPreviewFailure,
  fetchSqlVerifyQuery,
  setSqlStreamSchemaName,
  setSqlStreamTableName,
  setSqlStreamQuery,
  fetchSqlQueryTemplates,
  fetchSqlQueryTemplatePreview,
  fetchSqlQueryTemplatePreviewSuccess,
  fetchAthenaWorkgroupsList,
  fetchAthenaWorkgroupDescription,
  fetchAthenaDatabasesList,
  fetchTimestreamDataSourceListDatabases,
);
export default SqlEpic;
