import {clone, forEach} from 'lodash';
import cleanExpressionTree from './cleanExpressionTree';

function cleanNodeArray(arr) {
  if (!arr) {
    return;
  }
  const newArr = clone(arr);
  forEach(arr, function(node, index) {
    newArr[index] = cleanExpressionTree(node);
  });
  // eslint-disable-next-line consistent-return
  return newArr;
}

export default cleanNodeArray;
