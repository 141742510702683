import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as api from 'admin.users/services/api';
import {updateUserSettings} from 'profile/store/actions';
import {getMeAppSettings} from 'profile/store/selectors';
import * as actions from '../actions';

const fetchGroupsEditNewUser = makeAsyncEpic(actions.fetchGroupsEditNewUser, api.fetchGroups);
const fetchGroups = makeAsyncEpic(actions.fetchGroups, api.fetchGroups);
const createGroup = makeAsyncEpic(actions.createGroup, api.createGroup);
const addGroupsToUser = makeAsyncEpic(actions.addGroupsToUser, api.addGroupsToUser);
const updateGroup = makeAsyncEpic(actions.updateGroup, api.updateGroup);
const deleteGroup = makeAsyncEpic(actions.deleteGroup, api.deleteGroup);

const getGroupAssetsCount = (action$) =>
  action$.ofType(actions.getGroupAssetsCount.TYPE).flatMap(({payload}) => {
    if (payload) {
      return [
        actions.fetchOwnerDashboardsCount(payload),
        actions.fetchOwnerAnoboardsCount(payload),
        actions.fetchOwnerAlertsCount(payload),
      ];
    }
    return [];
  });

const setGroupSectionIsOpen = (action$, {getState}) =>
  action$.ofType(actions.setGroupSectionIsOpen.TYPE).flatMap(({payload}) => {
    const currentAppSettings = getMeAppSettings(getState());

    const appSettings = {
      ...currentAppSettings,
      usersGroups: {
        ...currentAppSettings.usersGroups,
        isGroupSectionOpen: payload,
      },
    };

    return [updateUserSettings(appSettings)];
  });

const groupsEpics = combineEpics(
  fetchGroupsEditNewUser,
  fetchGroups,
  createGroup,
  addGroupsToUser,
  updateGroup,
  deleteGroup,
  getGroupAssetsCount,
  setGroupSectionIsOpen,
);

export default groupsEpics;
