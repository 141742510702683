import {getUniqueId} from '../guid';

function getEmptyExpression() {
  return {
    searchObject: {expression: []},
    children: [],
    type: 'metric',
    id: getUniqueId(),
  };
}

export default getEmptyExpression;
