import {combineReducers} from 'redux';
import data from './dataReducer';
import trainTrackerPage from './pageReducer';

export default combineReducers({
  views: combineReducers({
    trainTrackerPage,
  }),
  data,
});
