// @flow
import React, {useCallback, useMemo} from 'react';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

import './SchedulerEditorModal.module.scss';

type PropTypes = {
  title: String,
  aggregationInterval: String,
  pollingInterval: String,
  onChange: Function,
};

const AggregationInterval = ({title, aggregationInterval, pollingInterval, onChange}: PropTypes) => {
  const options = useMemo(() => {
    // value can be smaller or equal to pollingInterval
    const aggIntervalsArr = [];
    aggIntervalsArr.push({label: '1 Minute', value: 1});

    if (pollingInterval.indexOf('d') === 0) {
      aggIntervalsArr.push({label: '5 Minutes', value: 5});
      aggIntervalsArr.push({label: '1 Hour', value: 60});
      aggIntervalsArr.push({label: '1 Day', value: 60 * 24});
    }
    if (pollingInterval.indexOf('h') === 0) {
      aggIntervalsArr.push({label: '5 Minutes', value: 5});
      aggIntervalsArr.push({label: '1 Hour', value: 60});
    }
    if (pollingInterval.indexOf('m') === 0 && pollingInterval !== 'm1') {
      aggIntervalsArr.push({label: '5 Minutes', value: 5});
    }
    return aggIntervalsArr;
  }, [pollingInterval]);

  const saveValue = useCallback(
    (val) => {
      onChange(val);
    },
    [onChange],
  );

  let selectedIndex = options.length > 1 ? options.findIndex((val) => val.value === aggregationInterval) : 0;
  if (selectedIndex === -1) {
    selectedIndex = options.length - 1;
  }

  return (
    <div>
      <div styleName="titleWrapper">{title || 'Bucketing Options'}:</div>
      <div>
        <SelectAndt
          id="schedulerEditorModalHistoricalDateRange"
          automationId="NotificationDelayType"
          options={options}
          getOptionLabel={(val) => val.label}
          getOptionValue={(val) => val.value}
          type={TYPE_NEW_NO_SEARCH}
          theme={THEME_NOT_HIGHLIGHTED}
          value={options[selectedIndex]}
          onChange={(val) => saveValue(val.value)}
          placeholder={title || 'Bucketing Options'}
          optionHeight={40}
          buttonWidth={180}
          isClearable={false}
        />
      </div>
    </div>
  );
};

export default AggregationInterval;
