// @flow
import React from 'react';
import {fileFormats} from 'bc/services/parquetService';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  onChange: Function,
  value: String,
};

export default class ParquetFileFormat extends React.PureComponent {
  props: PropTypes;

  onMenuItemClick = (item) => {
    this.props.onChange(item.value);
  };

  render() {
    const {value} = this.props;
    const optionCurrent = fileFormats.findIndex((option) => option.value === value);
    return (
      <SelectAndt
        id="parquetFormatPicker"
        automationId="parquetFormatPicker"
        options={fileFormats}
        type={TYPE_NO_SEARCH}
        theme={THEME_LIGHT}
        value={fileFormats[optionCurrent]}
        onChange={this.onMenuItemClick}
        placeholder="File Format"
      />
    );
  }
}
