// @flow
import React from 'react';
import {MenuItem} from 'react-bootstrap';

type PropTypes = {
  key: string,
};

export default class AndtMenuItem extends React.PureComponent {
  props: PropTypes;

  render() {
    return <MenuItem {...this.props} href="#" />;
  }
}

// const AndtMenuItem = (props: PropTypes) => {
//
//   return (
//     <div className="analytics" >
//       <MenuItem {...props} href="javascript:void(0);" />
//     </div>
//   );
// };
//
// export default AndtMenuItem;
