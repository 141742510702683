import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as api from '../services/api';
import * as actions from './actions';

const getOpenAlerts = makeAsyncEpic(actions.getOpenAlerts, api.getOpenAlerts);
const getAlertsDistribution = makeAsyncEpic(actions.getAlertsDistribution, api.getAlertsDistribution);
const fetchUsage = makeAsyncEpic(actions.fetchMetricsUsage, api.fetchMetricsUsage);
const fetchFeedbackDistribution = makeAsyncEpic(actions.fetchFeedbackDistribution, api.fetchFeedbackDistribution);
const fetchAlertsTriggered = makeAsyncEpic(actions.fetchAlertsTriggered, api.fetchAlertsTriggered);
const fetchFeedbackPerMeasure = makeAsyncEpic(actions.fetchFeedbackPerMeasure, api.fetchFeedbackPerMeasure);
const fetchNoFeedbackAlerts = makeAsyncEpic(actions.fetchNoFeedbackAlerts, api.fetchNoFeedbackAlerts);

export default combineEpics(
  getOpenAlerts,
  getAlertsDistribution,
  fetchUsage,
  fetchFeedbackDistribution,
  fetchAlertsTriggered,
  fetchFeedbackPerMeasure,
  fetchNoFeedbackAlerts,
);
