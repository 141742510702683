import React from 'react';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import CoralogixData from './coralogixData/CoralogixData';
import CoralogixFiltering from './coralogixData/CoralogixFiltering';
import CoralogixDimensionsAndMetrics from './coralogixDimensionsAndMetrics/CoralogixDimensionsAndMetrics';
import CoralogixSchedular from './corlogixSchedular/CoralogixSchedular';
import StreamProperties from '../../StreamProperties';

import './coralogixData/CoralogixData.module.scss';

const CoralogixStreamEditor = () => (
  <div className="shell-col">
    <StreamEditorSteps>
      <div styleName="left-container">
        <StreamProperties />
        <CoralogixData />
        <CoralogixSchedular />
      </div>
      <div styleName="middle-container">
        <CoralogixFiltering />
      </div>
      <CoralogixDimensionsAndMetrics />
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default CoralogixStreamEditor;
