import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as actions from 'userSettings/store/actions';
import * as api from 'userSettings/services/api';

const updatePasswordRequest = makeAsyncEpic(actions.updatePasswordRequest, api.updatePassword);
const updateSSO = makeAsyncEpic(actions.updateSSO, api.updateSso);
const verifyDomains = makeAsyncEpic(actions.verifyDomains, api.verifyDomains);
const fetchRefreshTokens = makeAsyncEpic(actions.fetchRefreshTokens, api.fetchRefreshTokens);
const refreshTokens = makeAsyncEpic(actions.refreshTokens, api.refreshTokens);
const revokeToken = makeAsyncEpic(actions.revokeToken, api.revokeToken);

export default combineEpics(
  updatePasswordRequest,
  updateSSO,
  verifyDomains,
  fetchRefreshTokens,
  refreshTokens,
  revokeToken,
);
