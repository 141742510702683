// @flow
import React from 'react';
import {Modal} from 'react-bootstrap';

const MIN_LENGTH = 2;

type PropTypes = {
  onClose: Function,
  isOpen: boolean,
};

class SaveAsTemplateModal extends React.PureComponent {
  props: PropTypes;

  state = {newTemplateName: ''};

  handleClose = (isOk) => {
    this.props.onClose(isOk ? this.state.newTemplateName : null);
    this.setState({newTemplateName: ''});
  };

  handleChange = (e) => {
    this.setState({newTemplateName: e.target.value});
  };

  render() {
    return (
      <div>
        <Modal show={this.props.isOpen} dialogClassName="bc" onHide={this.props.onClose}>
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>Save as Template</Modal.Title>
            <button
              type="button"
              className="btn btn-flat btn-icon-36 btn-secondary"
              onClick={() => this.handleClose(false)}
            >
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-body-message">Save the stream query as new template?</div>
            <input type="text" minLength={MIN_LENGTH} value={this.state.newTemplateName} onChange={this.handleChange} />
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-flat btn-secondary" onClick={() => this.handleClose(false)}>
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-flat btn-primary"
              disabled={this.state.newTemplateName.length < MIN_LENGTH}
              onClick={() => this.handleClose(true)}
            >
              OK
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default SaveAsTemplateModal;
