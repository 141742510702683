// @flow
import React from 'react';

type PropTypes = {
  className: String,
};

const Loading = (props: PropTypes) => (
  <div className={props.className ? `loading ${props.className}` : 'loading'}>
    <i className="icon ion-load-c spin" />
  </div>
);

export default Loading;
