// @flow
import React, {Fragment} from 'react';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import './StyleGuideComponents.module.scss';

export default class TooltipSection extends React.PureComponent {
  render() {
    const content = (
      <div>
        <p>
          <strong>Lorem Ipsum</strong>
        </p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
        <p>
          <strong>Donec</strong>
        </p>
        <p>dignissim velit sit amet fringilla sollicitudin.</p>
      </div>
    );
    return (
      <Fragment>
        <h4>Tooltips</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Small Tooltip</h5>
            <Tooltip content="Lorem ipsum dolor sit amet" type={TYPES.SMALL}>
              <p>Hover here with your mouse to see the tooltip</p>
            </Tooltip>
          </div>

          <div styleName="item">
            <h5>Small Tooltip Bottom</h5>
            <Tooltip content="Lorem ipsum dolor sit amet" type={TYPES.SMALL} placement="bottom">
              <div>
                <p>Hover here with your mouse to see the tooltip</p>
                <p>(this could be top, left, right or bottom)</p>
              </div>
            </Tooltip>
          </div>

          <div styleName="item">
            <h5>Expand Info Tooltip</h5>
            <Tooltip
              // eslint-disable-next-line max-len
              content="tag-WEB-class-UserProfileController-method-ddtag-WEB-class-UserProfileController-method-dd tag-WEB-class-UserProfileController-method-ddt"
              type={TYPES.INFO}
            >
              <p>Hover here with your mouse to see the tooltip</p>
            </Tooltip>
          </div>

          <div styleName="item">
            <h5>Large Tooltip</h5>
            <Tooltip content={content} type={TYPES.LARGE}>
              <p>Hover here with your mouse to see the tooltip</p>
            </Tooltip>
          </div>
        </div>
      </Fragment>
    );
  }
}
