import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import Button, {COLORS} from 'common/componentsV2/Button';

const useStyles = makeStyles(() => ({
  btnsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const ConfirmationMessageModal = (props: PropTypes) => {
  const classes = useStyles();

  return (
    <Modal
      onClose={props.onClose}
      isOpen={props.isModalOpen}
      size={MODAL_SIZES.SMALL}
      isStatic={false}
      isCloseButtonHidden
    >
      <Grid container>
        <Grid item sm={12}>
          <div className="text16med lineHeight_16 mb_1">This link is being used</div>
        </Grid>
        <Grid item sm={12}>
          <div className="text14reg lineHeight_18 mb_6">
            This link is being used in {props.beingUsed} alerts. Deleting it will remove the link from all of these
            alerts. This action can’t be undone.
          </div>
        </Grid>
        <Grid item sm={12} className={classes.btnsContainer}>
          <Button text="Cancel" onClick={props.onClose} colorSchema={COLORS.GRAY_300} />
          <Box ml={2}>
            <Button text={props.btnText} onClick={props.onClick} colorSchema={props.btnColor} />
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

ConfirmationMessageModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  beingUsed: PropTypes.number.isRequired,
  btnText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  btnColor: PropTypes.string.isRequired,
};

export default ConfirmationMessageModal;
