// @flow
import React from 'react';
import {Provider} from 'react-redux';
import {getConfiguredStore} from 'configureStore';
import SimulationFilters from './SimulationFilters';

type PropTypes = {
  filter: Object,
  alertDefinitionData: Object,
  simEstimations: Object,
};

/*
 We need to provide the store before exposing components, so we wrap with a provider
 */

const SimulationFiltersWrapper = (props: PropTypes) => (
  <Provider store={getConfiguredStore()}>
    <SimulationFilters
      filter={props.filter}
      alertDefinitionData={props.alertDefinitionData}
      simEstimations={props.simEstimations}
    />
  </Provider>
);

export default SimulationFiltersWrapper;
