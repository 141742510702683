import React from 'react';

export const TriangleSvg = ({
  fillColor,
  strokeColor,
  strokeDashArray,
  size,
}: {
  fillColor: String,
  strokeColor: String,
  strokeDashArray: String,
  size: Number,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size - 2} viewBox="0 0 28 26">
    <g fill="none" fillRule="evenodd">
      <polygon
        points="14,0 28,24 0,24"
        strokeDasharray={strokeDashArray || '0'}
        strokeWidth="2"
        fill={fillColor}
        stroke={strokeColor}
      />
    </g>
  </svg>
);

export const HexagonSvg = ({
  fillColor,
  strokeColor,
  strokeDashArray,
  size,
}: {
  fillColor: String,
  strokeColor: String,
  strokeDashArray: String,
  size: Number,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size + 2} viewBox="0 0 24 24">
    <g fill="none" fillRule="evenodd">
      <polygon
        points="12,0 1.584,6 1.584,18 12,24 22.416,18 22.416,6"
        strokeWidth="2"
        fill={fillColor}
        stroke={strokeColor}
        strokeDasharray={strokeDashArray || '0'}
      />
    </g>
  </svg>
);

export const CircleSvg = ({
  fillColor,
  strokeColor,
  strokeDashArray,
  radius,
}: {
  fillColor: String,
  strokeColor: String,
  strokeDashArray: String,
  radius: Number,
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={radius * 2} height={radius * 2} viewBox="0 0 22 22">
    <g fill="none" fillRule="evenodd">
      <circle
        cx="11"
        cy="11"
        r={radius}
        strokeWidth="2"
        fill={fillColor}
        stroke={strokeColor}
        strokeDasharray={strokeDashArray || '0'}
      />
    </g>
  </svg>
);
