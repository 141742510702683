// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useQueryParams} from 'use-query-params';
import {QUERY_PARAM_MAP} from 'topologyGeneral/services/sidePanelService';
import SearchBox from 'common/componentsV2/SearchBox';
import './SidePanel.module.scss';

const SearchSubPanel = ({isVisible}: PropTypes) => {
  const [queryParams, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);

  const onFilter = (val) => {
    let newSearchQuery = val;
    if (!newSearchQuery) {
      newSearchQuery = undefined;
    }
    setQueryParams({searchQuery: newSearchQuery});
  };

  if (!isVisible) {
    return null;
  }

  return (
    <Fragment>
      <Box pr={2} pl={2} pb={2} pt={1} styleName="search-wrapper">
        <SearchBox onFilter={onFilter} filter={queryParams.searchQuery} automationId="tpSearchFilter" />
      </Box>
    </Fragment>
  );
};

SearchSubPanel.propTypes = {
  // mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
  // nodeHoveredId: PropTypes.string,
  isVisible: PropTypes.bool,
};

SearchSubPanel.defaultProps = {
  // nodeHoveredId: undefined,
  isVisible: true,
};

export default SearchSubPanel;
