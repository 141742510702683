// @flow
import React from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import {setSelectedEditor} from 'bc/store/actions';
import {getEditorValidationMessage} from 'bc/services/fuService';
import {isStreamEditable} from 'bc/services/dataStreamService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';
import {get} from 'lodash';

type PropTypes = {
  dataStream: Object,
  dimensions: Array<Object>,
  metrics: Array<Object>,
  timeDefinition: Object,
  setSelectedEditor: Function,
};

class FuDimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  setSelectedEditor = function() {
    this.props.setSelectedEditor({
      type: 'dm_fu',
      editorHeight: 'calc(100% - 210px)',
    });
  };

  render() {
    const {dataStream, dimensions, metrics, timeDefinition} = this.props;
    const isLoading = !get(dataStream, 'uiState.analysisResult');
    const isEditable = isStreamEditable(dataStream.state) && get(dataStream, 'uiState.analysisResult.fileSchema');

    return (
      <DimensionsAndMetrics
        dataStream={dataStream}
        dimensions={dimensions}
        metrics={metrics}
        timeDefinition={timeDefinition}
        isLoading={isLoading}
        isEditable={isEditable}
        validatorFunction={getEditorValidationMessage}
        onSelectEditor={() => this.setSelectedEditor()}
      />
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getFileStreamSelectedDimensions(state),
    metrics: selectors.getFileStreamSelectedMetrics(state),
    timeDefinition: selectors.getFileStreamSelectedTimeDefinitionCol(state),
  }),
  {
    setSelectedEditor,
  },
)(FuDimensionsAndMetrics);
