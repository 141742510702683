import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import {composeReducers} from '../../../common/utils/reducers';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const tablesViewsList = makeAsyncReducer(actions.fetchSqlTablesViewsList, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});
const schemasList = makeAsyncReducer(actions.fetchSqlSchemasList, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});
const tablesViewsMetadata = makeAsyncReducer(actions.fetchSqlTableData, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});
const queryTemplates = makeAsyncReducer(actions.fetchSqlQueryTemplates, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const root = combineReducers({
  schemasList,
  tablesViewsList,
  tablesViewsMetadata,
  fetchSqlTableData: composeReducers(
    makeAsyncReducer(actions.fetchSqlTableData, {
      defaultData: EMPTY_ARRAY,
      errorHandler: bcErrorHandler,
    }),
  ),

  mysqlDatabasesList: composeReducers(
    makeAsyncReducer(actions.fetchMysqlDataSourceListDatabases, {
      defaultData: EMPTY_ARRAY,
      errorHandler: bcErrorHandler,
    }),
    (state, {type}) => {
      switch (type) {
        case actions.resetMysqlDataSourceListDatabases.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),

  tablePreview: composeReducers(
    makeAsyncReducer(actions.fetchSqlTablePreview, {defaultData: EMPTY_OBJECT, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
        case actions.setSqlStreamTableName.TYPE:
        case actions.setSqlStreamSchemaName.TYPE:
          return {data: EMPTY_OBJECT};
        default:
          return state;
      }
    },
  ),

  preview: composeReducers(
    makeAsyncReducer(actions.fetchSqlQueryPreview, {defaultData: EMPTY_OBJECT, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),

  verifyQuery: composeReducers(
    makeAsyncReducer(actions.fetchSqlVerifyQuery, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),

  queryTemplates,
  queryTemplatePreview: composeReducers(
    makeAsyncReducer(actions.fetchSqlQueryTemplatePreview, {defaultData: EMPTY_OBJECT, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_OBJECT};
        default:
          return state;
      }
    },
  ),

  athenaWorkgroupsList: composeReducers(
    makeAsyncReducer(actions.fetchAthenaWorkgroupsList, {
      defaultData: EMPTY_ARRAY,
      errorHandler: bcErrorHandler,
    }),
    (state, {type}) => {
      switch (type) {
        case actions.resetAthenaWorkgroupList.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),

  athenaWorkgroupDescription: composeReducers(
    makeAsyncReducer(actions.fetchAthenaWorkgroupDescription, {
      defaultData: EMPTY_OBJECT,
      errorHandler: bcErrorHandler,
    }),
    (state, {type}) => {
      switch (type) {
        case actions.resetAthenaWorkgroupDescription.TYPE:
          return {data: EMPTY_OBJECT};
        default:
          return state;
      }
    },
  ),

  athenaDatabasesList: composeReducers(
    makeAsyncReducer(actions.fetchAthenaDatabasesList, {
      defaultData: EMPTY_ARRAY,
      errorHandler: bcErrorHandler,
    }),
    (state, {type}) => {
      switch (type) {
        case actions.resetAthenaDatabasesList.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),

  timestreamDatabasesList: composeReducers(
    makeAsyncReducer(actions.fetchTimestreamDataSourceListDatabases, {
      defaultData: EMPTY_ARRAY,
      errorHandler: bcErrorHandler,
    }),
    (state, {type}) => {
      switch (type) {
        case actions.resetTimestreamDataSourceListDatabases.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
});

export default composeReducers(root, (state, action) => {
  if (action.type === actions.resetSqlStreamData.TYPE) {
    return root(undefined, {type: 'no-action'});
  }
  return state;
});
