import React, {Fragment} from 'react';
import './SettingMenusSelectTree.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

type PropTypes = {
  expressions: Array,
  onSelectionChange: Function,
  selected: Number,
};

const SettingMenusSelectTree = ({expressions, onSelectionChange, selected}: PropTypes) => {
  const options = expressions.map((expression, index) => {
    let isEnabled = true;
    const {root} = expression.expressionTree;
    if (
      root.type === 'function' &&
      root.function === '' &&
      root.children[0].type === 'metric' &&
      root.children[0].searchObject.expression.length === 0
    ) {
      isEnabled = false;
    }
    return {
      letter: String.fromCharCode(index + 65),
      enabled: isEnabled,
    };
  });

  const handleClick = (index, enabled) => {
    if (enabled) {
      onSelectionChange(index);
    }
  };

  const renderOption = (option, index) => (
    <div
      key={option.letter}
      styleName={[
        'option',
        String(selected) === String(index) ? 'selected' : '',
        option.enabled ? '' : 'disabled',
      ].join(' ')}
      onClick={() => {
        handleClick(index, option.enabled);
      }}
    >
      {option.letter}
    </div>
  );

  return (
    <div styleName="container">
      {options.map((option, index) => (
        <Fragment>
          {option.enabled ? (
            <div>{renderOption(option, index)}</div>
          ) : (
            <Tooltip content="Please enter an expression to change graph settings" type={TYPES.SMALL}>
              {renderOption(option, index)}
            </Tooltip>
          )}
        </Fragment>
      ))}
    </div>
  );
};

export default SettingMenusSelectTree;
