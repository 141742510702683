// @flow
import React from 'react';
import './GadsDropdown.module.scss';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  isLoading: boolean,
  items: Array,
  selectedAccounts: Array,
  isDisabled: boolean,
  onChange: Function,
  ddlId: string,
  ddlTitle: string,
};

export default class GadsDropdown extends React.PureComponent {
  props: PropTypes;

  onChange = (items) => {
    const itemsVal = items.length ? items.map((item) => String(item.id)) : null;
    let timeZoneVal = items.length ? items.map((item) => item.timeZone) : null;
    timeZoneVal = timeZoneVal && timeZoneVal.length ? timeZoneVal[0] : null;
    this.props.onChange({
      clientCustomerId: itemsVal,
      timezone: timeZoneVal,
    });
  };

  render() {
    const {ddlId, isLoading, items, isDisabled, selectedAccounts, ddlTitle} = this.props;

    return (
      <div styleName="root">
        <div>
          <SelectAndt
            id={ddlId}
            disabled={(isLoading !== false && !items.length) || isDisabled}
            options={items}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.id}
            type={TYPE_NEW_MULTI_NO_SEARCH}
            theme={THEME_HIGHLIGHTED}
            value={isLoading ? null : selectedAccounts}
            onChange={this.onChange}
            optionHeight={40}
            isClearable={false}
            closeMenuOnSelect={false}
            menuWidth={280}
            buttonWidth={200}
            isMulti
            placeholder={isLoading !== false ? 'Loading...' : ddlTitle}
            automationId={ddlTitle}
          />
        </div>
      </div>
    );
  }
}
