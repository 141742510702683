// @flow
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/delay';

import {error, info} from 'common/utils/notifications/notificationsService';
import {clipboardCopied} from 'common/utils/notifications/generalNotificationCodes';
import {copyToClipboard as doCopyToClipboard} from 'common/utils/angularServices';
import {copyToClipBoard as copyToClipBoardAction} from '../actions';

// eslint-disable-next-line import/prefer-default-export
export const copyToClipboard = (action$) =>
  action$.ofType(copyToClipBoardAction.TYPE).map(({payload}) => {
    let err;
    doCopyToClipboard(payload.text, payload.event, null, (err_) => {
      err = err_;
    });
    const clipCode = payload.description
      ? {...clipboardCopied, description: payload.description, title: payload.title}
      : clipboardCopied;
    return err ? error(err) : info(clipCode);
  });
