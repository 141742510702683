// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {MAP_ITEMS_COLOR_PALETTE, useTooltipStyles} from 'topologyGeneral/services/mapService';

const LocCellsSection = ({cells, nodesMap}: {cells: Array, nodesMap: Object}) => {
  if (!cells || !cells.length) {
    return null;
  }

  const classes = useTooltipStyles();

  const getCellItem = (cell, nodeName) => {
    return (
      <Box display="flex" pb={1} key={`loc-c-${cell.id}`}>
        <Box display="flex" flexDirection="column" pr={1.5}>
          <Box component="span" className={classes.title12BoldLight}>
            {nodeName} {cell.domain.join(',')}
          </Box>
          <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
            <Box
              className={classes.statusIndicator}
              bgcolor={cell.isError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
              mr={0.5}
            >
              &nbsp;
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {cell.name}
            </Box>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flexShrink={0} pr={1.5}>
          <Box component="span" className={classes.title12BoldLight}>
            Alerts
          </Box>
          <Box component="span" className={classes.title14Normal}>
            -
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" flexShrink={0}>
          <Box component="span" className={classes.title12BoldLight}>
            Band
          </Box>
          <Box component="span" className={classes.title14Normal}>
            {cell.band}
          </Box>
        </Box>
      </Box>
    );
  };

  const rows = [];
  const totalCells = cells.length;
  if (totalCells < 7) {
    cells.forEach((cell) => rows.push(getCellItem(cell, nodesMap[cell.relatedNodeId])));
  } else {
    cells.slice(0, 5).forEach((cell) => rows.push(getCellItem(cell, nodesMap[cell.relatedNodeId])));
    rows.push(
      <Box display="flex" justifyContent="center" key="loc-c-more">
        <Box component="span" className={classes.title12Bold}>{`+ ${totalCells - 5} More`}</Box>
      </Box>,
    );
  }

  return <Fragment>{rows}</Fragment>;
};

const AzimuthTooltip = ({cells, nodes}: PropTypes) => {
  const classes = useTooltipStyles();

  const azimuthLacValArr = cells.reduce(
    (acc, curVal) => {
      if (acc.azimuth.indexOf(curVal.azimuth) === -1) {
        acc.azimuth.push(curVal.azimuth);
      }
      if (acc.lac.indexOf(curVal.lac) === -1) {
        acc.lac.push(curVal.lac);
      }
      return acc;
    },
    {azimuth: [], lac: []},
  );

  const nodeMap = {};
  nodes.forEach((node) => {
    nodeMap[node.id] = node.name;
  });

  return (
    <Box className="gtp-tp-wrapper" pt={1.5} pb={1.5} pl={2} pr={2} borderRadius="6px">
      <Box display="flex" alignItems="center">
        <Box component="span" className={classes.title12BoldLight} mr={0.5}>
          Azimuth
        </Box>
        <Box component="span" className={classes.title14Normal} mr={0.5}>
          {azimuthLacValArr.azimuth.join(', ')}
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Box component="span" className={classes.title12BoldLight} mr={0.5}>
          LAC
        </Box>
        <Box component="span" className={classes.title14Normal} mr={0.5}>
          {azimuthLacValArr.lac.join(', ')}
        </Box>
      </Box>
      {cells && cells.length ? (
        <Fragment>
          <Box className={classes.horizontalDivider} />
          <LocCellsSection cells={cells} nodesMap={nodeMap} />
        </Fragment>
      ) : null}
    </Box>
  );
};

AzimuthTooltip.propTypes = {
  cells: PropTypes.arrayOf(PropTypes.object).isRequired,
  nodes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AzimuthTooltip;
