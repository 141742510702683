const DEFAULT_INTERVALS_PERIODS = [
  {
    label: 'Refresh Now',
    title: '',
    value: 'now',
    id: 'now',
  },
  {
    label: 'Off',
    title: 'Off',
    value: undefined,
    id: 'off',
  },
  {
    label: 'Every 1 min.',
    title: '1 minute',
    value: 1000 * 60,
    id: '1min',
  },
  {
    label: 'Every 5 min.',
    title: '5 minute',
    value: 1000 * (60 * 5),
    id: '5min',
  },
  {
    label: 'Every 10 min.',
    title: '10 minute',
    value: 1000 * (60 * 10),
    id: '10min',
  },
  {
    label: 'Every 15 min.',
    title: '15 minute',
    value: 1000 * (60 * 15),
    id: '15min',
  },
  {
    label: 'Every 30 min.',
    title: '30 minute',
    value: 1000 * (60 * 30),
    id: '30min',
  },
  {
    label: 'Every 1 hr.',
    title: '1 hour',
    value: 1000 * (60 * 60),
    id: '1hour',
  },
];

export default DEFAULT_INTERVALS_PERIODS;
