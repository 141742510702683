import {makeAsyncAction, makeAction} from 'common/utils/simplifiedAsync';

const ACTION_PREFIX = 'anodot/admin.insightPanel/';
const mac = (actionName) => makeAction(actionName, ACTION_PREFIX);
const macAsync = (actionName) => makeAsyncAction(actionName, ACTION_PREFIX);

export const setIsPanelOpen = mac('SET_IS_PANEl_OPEN');
export const setNumLastDays = mac('SET_NUM_LAST_DAYS');
export const getOpenAlerts = macAsync('GET_OPEN_ALERTS');
export const getAlertsDistribution = macAsync('GET_ALERTS_DISTRIBUTION');
export const fetchMetricsUsage = macAsync('FETCH_METRICS_USAGE');
export const fetchFeedbackDistribution = macAsync('FETCH_FEEDBACK_DISTRIBUTION');
export const fetchAlertsTriggered = macAsync('FETCH_ALERTS_TRIGGERED');
export const fetchFeedbackPerMeasure = macAsync('FETCH_FEEDBACK_PER_MEASURE');
export const fetchNoFeedbackAlerts = macAsync('FETCH_NO_FEEDBACK_ALERTS');
export const updateInsightsPanelTime = mac('UPDATE_INSIGHTS_PANEL_TIME');
