// @flow
import React from 'react';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './UsersModals.module.scss';

type PropTypes = {
  isDelegate: boolean,
  usersList: Array,
  selectedDelegateUser: Object,
  onIsDelegateChange: Function,
  onUserDelegateSelect: Function,
};

export default class DelegateUserSection extends React.PureComponent {
  props: PropTypes;

  setIsUserDelegate = (e) => {
    if (e.target.value === 'no') {
      this.props.onIsDelegateChange(false);
    } else {
      this.props.onIsDelegateChange(true);
    }
  };

  userDefaultSelected = (item) => {
    this.props.onUserDelegateSelect(item.id);
  };

  render() {
    const {isDelegate, usersList, selectedDelegateUser} = this.props;

    return (
      <div styleName="delegate-row">
        <div styleName="header">What would you like to do:</div>
        <label key="no">
          <input type="radio" name="delegate" value="no" checked={!isDelegate} onChange={this.setIsUserDelegate} />
          <span>Delete all of these entities from Anodot</span>
        </label>
        <div styleName="delegate">
          <label key="yes">
            <input type="radio" name="delegate" value="yes" checked={!!isDelegate} onChange={this.setIsUserDelegate} />
            <span>Delegate them to:</span>
          </label>
          <SelectAndt
            automationId="DeleteUserDelegate"
            type={TYPE_NEW_SEARCH}
            theme={THEME_BLUE_LEAN}
            onChange={this.userDefaultSelected}
            options={usersList}
            value={selectedDelegateUser}
            placeholder="Select Another User"
            optionHeight={40}
            buttonWidth={200}
            menuWidth={300}
            disabled={!isDelegate}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.id}
          />
        </div>
      </div>
    );
  }
}
