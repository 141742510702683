// @flow
import React from 'react';
import {resolutionTypes} from 'metrics/services/metricsService';
import {subConditionsMeta, subConditionTypes} from 'alerts.management/services/alertsService';
import {get} from 'lodash';
import './Descriptor.module.scss';

const Descriptor = (props: {
  what: String,
  matchingProperties: String,
  timeScale: String,
  subConditions: String,
  isDuring: Boolean,
  isSingle: Boolean,
  type: String,
}) => {
  const {what, matchingProperties, timeScale, subConditions, isDuring, isSingle, type} = props;
  const timeScaleText = get(resolutionTypes[timeScale], 'text', '(#3)');
  const duringText = isDuring ? 'during' : 'before';
  const subConditionsText = subConditions
    .map((c) => {
      const typeMeta = subConditionsMeta.find((a) => a.value === c.type);
      switch (c.type) {
        case subConditionTypes.above: {
          let boundsByText = get(typeMeta.boundsBy.find((a) => a.value === c.upperBound), 'moreText', '');
          boundsByText = boundsByText ? `${boundsByText} ` : '';
          return c.maxValue && `${typeMeta.text} ${boundsByText}${c.maxValue}`;
        }
        case subConditionTypes.below: {
          let boundsByText = get(typeMeta.boundsBy.find((a) => a.value === c.lowerBound), 'moreText', '');
          boundsByText = boundsByText ? `${boundsByText} ` : '';
          return c.minValue && `${typeMeta.text} ${boundsByText}${c.minValue}`;
        }
        case subConditionTypes.between:
          return c.maxValue && c.minValue && `${typeMeta.text} ${c.maxValue} and ${c.minValue}`;
        default:
      }
      return null;
    })
    .filter((a) => !!a)
    .join(' OR ');
  const propsText = matchingProperties.join(', ');
  const propsElm = propsText ? (
    <span styleName="emphasize"> {propsText} </span>
  ) : (
    <span styleName="missing-val">(#2) </span>
  );
  const whatElm = what ? <span styleName="emphasize"> {what} </span> : <span styleName="missing-val">(#1) </span>;
  const subConditionsElm = subConditionsText ? (
    <span styleName="emphasize"> {subConditionsText} </span>
  ) : (
    <span styleName="missing-val">(#4) </span>
  );

  return (
    <div styleName="descriptor">
      {isSingle && (
        <div styleName="descriptor-text">
          Trigger the alert only when the metric {whatElm}
          is {subConditionsElm}
          in the last <span styleName="emphasize"> {timeScaleText} </span>
          <span styleName="emphasize">{duringText} </span>
          {type === 'anomaly' ? 'the anomaly' : null}
          {type === 'noData' ? 'data stopped flowing' : null}
        </div>
      )}
      {!isSingle && (
        <div styleName="descriptor-text">
          Trigger the alert only when the metrics {whatElm}
          that match the alert metrics by properties {propsElm}
          are {subConditionsElm}
          in the last <span styleName="emphasize"> {timeScaleText} </span>
          <span styleName="emphasize">{duringText} </span>
          {type === 'anomaly' ? 'the anomaly' : null}
          {type === 'noData' ? 'data stopped flowing' : null}
        </div>
      )}
    </div>
  );
};

export default Descriptor;
