// @flow
import React, {Fragment, useCallback} from 'react';
import {useHistory, useRouteMatch} from 'react-router-dom';
import Customers from 'admin.customers/pages/Customers';
import EditCustomerModal from 'admin.customers/components/EditCustomer/EditCustomerModal';

const CustomersRouter = () => {
  const history = useHistory();
  const {
    params: {id},
  } = useRouteMatch('/admin/customers/:id?');

  const onClose = useCallback(() => history.push(`/admin/customers${history.location.search}`), [history]);

  return (
    <Fragment>
      <Customers />
      <EditCustomerModal isOpen={!!id} onClose={onClose} />
    </Fragment>
  );
};

export default CustomersRouter;
