import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const accounts = makeAsyncReducer(actions.fetchFacebookAdsAccounts, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const reports = makeAsyncReducer(actions.fetchFacebookAdsReports, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const templates = makeAsyncReducer(actions.fetchFacebookAdsTemplates, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

export default combineReducers({
  accounts,
  reports,
  templates,
});
