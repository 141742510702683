// @flow
import React from 'react';

const EMPTY_ARRAY = [];
const makeConceptTooltip = ({id, description, disabled, doesNotWorkWith}: any) => {
  let conflictLists = null;

  if (disabled) {
    const conflict = doesNotWorkWith ? doesNotWorkWith(id) : {metrics: EMPTY_ARRAY, dimensions: EMPTY_ARRAY};
    const More = ({list, limit = 10}: any) => (
      <div className="conflict-list">
        {list.map(({idInner, uiName}) => (
          <span key={idInner}>{uiName}</span>
        ))}
        {list.length > limit && <span>and more...</span>}
      </div>
    );
    const ConflictList = ({list, title}: {list: Array, title: string}) =>
      list.length > 0 && (
        <div>
          <div>{title}</div>
          <More list={list} />
        </div>
      );

    conflictLists = (
      <div>
        <hr />
        <ConflictList title="Does not work with the following metrics:" list={conflict.metrics} />
        <ConflictList title="Does not work with the following dimensions:" list={conflict.dimensions} />
      </div>
    );
  }

  return (
    <div className="concept-tooltip">
      <div>{description}</div>
      {conflictLists}
    </div>
  );
};

export default makeConceptTooltip;
