import {clone, get, setWith} from 'lodash';
import {useRef} from 'react';
import {useField} from 'react-final-form';
import {CHART_TYPE, STACKING} from 'metrics/components/metricExplorer/seriesPropertiesConsts';

const processValue = (option) => {
  let rawValue = option;

  if (option.target) {
    rawValue = option.target.value;
  } else if (typeof option === 'object') {
    rawValue = option.value;
  }
  return rawValue === '' || rawValue === undefined ? null : rawValue;
};

// to check the concept read https://github.com/rtfeldman/seamless-immutable
export const immutableSetIn = (obj, path, data) => setWith(clone(obj), path, data, clone);

const useByTreeExpManager = () => {
  const byTreeExp = useRef();

  const {
    input: {value: byTreeExpValue, onChange: onByTreeExpChange},
  } = useField('byTreeExp');

  const {
    input: {value: byTreeExpId, onChange: onByTreeExpIdChange},
  } = useField('byTreeExpId');

  byTreeExp.current = byTreeExpValue[byTreeExpId]
    ? byTreeExpValue
    : {...byTreeExpValue, [byTreeExpId]: byTreeExpValue['*']};

  if (
    get(byTreeExp, `current[${byTreeExpId}].type`) === CHART_TYPE.COLUMN &&
    get(byTreeExp, `current[${byTreeExpId}].stacking`) === null
  ) {
    onByTreeExpChange(
      immutableSetIn(byTreeExp.current, `${byTreeExpId}.stacking`, processValue({value: STACKING.NORMAL})),
    );
  }

  // TODO: use memoization
  const makeByTreeExpChangeHandler = (path) => (option) =>
    onByTreeExpChange(immutableSetIn(byTreeExp.current, `${byTreeExpId}.${path}`, processValue(option)));

  return {
    makeByTreeExpChangeHandler,
    onByTreeExpIdChange,
    byTreeExp: byTreeExp.current[byTreeExpId],
    byTreeExpMap: byTreeExpValue,
    byTreeExpId,
  };
};

export default useByTreeExpManager;
