import {composeReducers} from 'common/utils/reducers';
import {DEFAULT_QUERY_PARAMS} from 'admin.users/services/usersAndGroupsService';
import * as actions from '../actions';

const initialState = {
  ...DEFAULT_QUERY_PARAMS,
};

const groupsReducer = composeReducers((state = {...initialState}, {type, payload}) => {
  switch (type) {
    case actions.setUsersGroupsFiltersKeyVal.TYPE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
});

export default groupsReducer;
