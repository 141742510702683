// @flow

import React from 'react';

import ConceptButton from './ConceptButton';

import './FilteredConcepts.module.scss';

const renderSubstring = (text, index, length) => (
  <span>
    <span>{text.substring(0, index)}</span>
    <span styleName="highlight">{text.substr(index, length)}</span>
    <span>{text.substring(index + length)}</span>
  </span>
);

const lowercaseIndexOf = (text, search) => text.toLowerCase().indexOf(search.toLowerCase());
const renderFilter = (text, filter) => renderSubstring(text, lowercaseIndexOf(text, filter), filter.length);

const FilteredConcepts = ({filter, concepts, onAddItem, onRemoveItem}: any) => (
  <div styleName="filtered-concepts">
    {concepts
      .filter(({uiName}) => filter.length === 0 || lowercaseIndexOf(uiName, filter) >= 0)
      .sort((a, b) => a.uiName.localeCompare(b.uiName))
      .map(({id, uiName, group, selected, disabled, description}) => (
        <ConceptButton
          key={id}
          {...{
            id,
            selected,
            disabled,
            description,
            onAddItem,
            onRemoveItem,
          }}
        >
          <div className="shell">
            <span styleName="concept-name">{disabled ? uiName : renderFilter(uiName, filter)}</span>
            <span styleName="group-name">
              <span styleName="group-name-text">{group}</span>
              <div className="icon icn-icon-dimmet-group" styleName="group-button-icon" />
            </span>
          </div>
        </ConceptButton>
      ))}
  </div>
);

export default FilteredConcepts;
