import {BehaviorSubject, Subject} from 'rxjs';
import {actions} from './common';
import {fetchOriginTitleCache} from './metrics/store/actions';
import * as profileActions from './profile/store/actions';
import * as alertManagementActions from './alerts.management/store/actions';
/* eslint-disable no-param-reassign */
const initSubscriptions = (app, store) => {
  let meObservable = null;
  meObservable = new BehaviorSubject();
  const meToObservable = new BehaviorSubject();
  const segmentCauseEventObservable = new BehaviorSubject();
  const metricsOriginTitleCacheObservable = new Subject();
  const timeZoneObservable = new Subject();
  const setAutoVolumeConditionObservable = new Subject();
  // eslint-disable-line no-param-reassign
  app.setMe = (me) => meObservable.next(me);
  app.segmentPageInit = (i) => meToObservable.next(i);
  app.segmentCauseEvent = (i) => segmentCauseEventObservable.next(i);
  app.fetchOriginTitlesCache = (i) => metricsOriginTitleCacheObservable.next(i);
  app.setTimeZone = (tzName) => timeZoneObservable.next(tzName);
  app.setAutoVolumeCondition = (updatedVolumeCondition) =>
    setAutoVolumeConditionObservable.next(updatedVolumeCondition);

  segmentCauseEventObservable.subscribe(() => {
    store.dispatch(actions.segmentCauseEvent(segmentCauseEventObservable));
  });

  meToObservable.subscribe(() => {
    store.dispatch(actions.segmentPageInit(meToObservable));
  });

  metricsOriginTitleCacheObservable.subscribe(() => {
    store.dispatch(fetchOriginTitleCache());
  });

  timeZoneObservable.subscribe(() => {
    store.dispatch(profileActions.timeZoneChanged());
  });

  setAutoVolumeConditionObservable.subscribe((updatedVolumeCondition) => {
    store.dispatch(alertManagementActions.setAutoVolumeConditionValues(updatedVolumeCondition));
  });

  store.dispatch(actions.appInit(meObservable));
};
/* eslint-enable */

export default initSubscriptions;
