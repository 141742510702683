// @flow
import React from 'react';
import './ButtonListedComponent.module.scss';

type PropTypes = {
  text: String,
  placeholder: String,
  // connect
};

export default class ButtonListedComponent extends React.PureComponent {
  props: PropTypes;

  state = {};

  render() {
    return (
      <div styleName={['container', this.props.text ? 'value' : 'placeholder'].join(' ').trim()}>
        <span>{this.props.text || this.props.placeholder}</span>
        <i styleName="triangle" className="icon icn-arrow16-triangledown" />
      </div>
    );
  }
}
