// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import {getEditorValidationMessage} from 'bc/services/bqService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';
import {get} from 'lodash';

type PropTypes = {
  dataStream: Object,
  dimensions: Array,
  metrics: Array,
  timestampColumn: string,
  setSelectedEditor: Function,
};

class BqDimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  setSelectedEditor = () => {
    this.props.setSelectedEditor({
      type: 'dm_bigquery',
      editorHeight: 'calc(100% - 210px)',
    });
  };

  render() {
    const {dataStream, dimensions, metrics, timestampColumn} = this.props;
    const isEditable =
      (isEditRunning(dataStream.id) || isStreamEditable(dataStream.state)) &&
      get(dataStream, 'uiState.queryPreviewColumns');

    return (
      <DimensionsAndMetrics
        dataStream={dataStream}
        dimensions={dimensions}
        metrics={metrics}
        timeDefinition={{name: timestampColumn}}
        isLoading={false}
        isEditable={isEditable}
        validatorFunction={getEditorValidationMessage}
        onSelectEditor={() => this.setSelectedEditor()}
      />
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getBqStreamSelectedDimensions(state),
    metrics: selectors.getBqStreamSelectedMetrics(state),
    timestampColumn: selectors.getSelectedDataStream(state).timestampColumn,
  }),
  {
    setSelectedEditor,
  },
)(BqDimensionsAndMetrics);
