import treeVisitor from './treeVisitor';

function findFuncInTree(root, funcName) {
  let func;
  treeVisitor(root, 'children', function(childNode) {
    if (childNode.function === funcName) {
      func = childNode;
    }
  });
  return func;
}

export default findFuncInTree;
