import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';
import {composeReducers} from '../../../common/utils/reducers';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const csvFormatAnalysis = makeAsyncReducer(actions.fetchGoogleStorageCsvFormatAnalysis, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

export default combineReducers({
  csvFormatAnalysis,
  filePreview: composeReducers(
    makeAsyncReducer(actions.fetchGoogleStoragePreview, {defaultData: EMPTY_OBJECT}),

    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_OBJECT};
        case actions.fetchGoogleStorageCsvFormatAnalysis.success.TYPE:
          return {data: EMPTY_OBJECT};
        default:
          return state;
      }
    },
  ),
  projects: composeReducers(
    makeAsyncReducer(actions.fetchGoogleStorageProjects, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
  buckets: composeReducers(
    makeAsyncReducer(actions.fetchGoogleStorageBuckets, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
        case actions.setGoogleStorageStreamProjectId.TYPE:
          return {isLoading: state.isLoading, data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
});
