import React from 'react';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

const SelectStandart = ({
  onChange,
  value,
  options,
  id,
}: {
  id: string,
  onChange: Function,
  value: Object,
  options: Array,
}) => (
  <SelectAndt
    id={id}
    automationId={id}
    extraClassName="alerts-dropdown-btn"
    options={options}
    optionHeight={40}
    type={TYPE_NEW_NO_SEARCH}
    theme={THEME_NOT_HIGHLIGHTED}
    onChange={onChange}
    value={value}
    placeholder="All"
  />
);

export default SelectStandart;
