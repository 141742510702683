import React from 'react';
import StreamEditorPanel from 'bc/components/streams/editor/StreamEditorPanel';
import StreamEditorSteps from 'bc/components/streams/editor/StreamEditorSteps';
import StreamProperties from 'bc/components/streams/editor/StreamProperties';
import EventTypes from './EventTypes';
import MParticleDimensionsAndMetrics from './mParticleDimensionsAndMetrics/MParticleDimensionsAndMetrics';
import './MParticleStreamEditor.module.scss';

const MParticleStreamEditor = () => (
  <div className="shell-col">
    <StreamEditorSteps>
      <div styleName="left-container">
        <StreamProperties />
        <EventTypes />
      </div>
      <MParticleDimensionsAndMetrics />
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default MParticleStreamEditor;
