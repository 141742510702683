// @flow

import React from 'react';
import {components} from 'react-select2';
import SelectAndt, {
  TYPE_SIMPLE,
  DIRECTION_CENTER,
  THEME_TRANSPARENT,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import OptionWithIcon from 'common/componentsV2/ddl/selectAndt/innerComponents/OptionWithIcon';
import './UGFooterGeneral.module.scss';

type PropTypes = {
  onApply: Function,
};

export default class UGFooterMore extends React.PureComponent {
  props: PropTypes;

  handlePauseChange = (val) => {
    this.props.onApply(val);
  };

  render() {
    return (
      <SelectAndt
        type={TYPE_SIMPLE}
        theme={THEME_TRANSPARENT}
        onChange={this.handlePauseChange}
        options={[
          {value: 'enable', label: 'Enable', icon: 'icn-action16-resume'},
          {value: 'primaryGroup', label: 'Primary Group', icon: 'icn-general16-group'},
        ]}
        direction={DIRECTION_CENTER}
        menuWidth={200}
        automationId="footer-more"
        menuPlacement="top"
        customComponent={{
          Option: OptionWithIcon,
          DropdownIndicator: (p) => (
            <div {...p.innerProps} styleName="footer-button-like">
              <i className="icn-general16-3dot" />
              <span>More</span>
            </div>
          ),
          IndicatorsContainer: (p) => (
            <div style={{height: '35px', display: 'flex'}}>
              <components.IndicatorsContainer {...p}>{p.children}</components.IndicatorsContainer>
            </div>
          ),
        }}
      />
    );
  }
}
