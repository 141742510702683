import React, {PureComponent} from 'react';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  innerProps: any,
};

export default class DropDownIndicatorDot extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <i
        {...this.props.innerProps}
        style={{cursor: 'pointer'}}
        styleName="click-area-ripple"
        className="icon icn-icon-3-dots ripple"
      />
    );
  }
}
