import treeVisitor from './treeVisitor';

function findExpressionInTree(root) {
  let expressionNode;
  treeVisitor(root, 'children', function(childNode) {
    if (childNode.searchObject) {
      expressionNode = childNode;
    }
  });
  return expressionNode;
}

export default findExpressionInTree;
