import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';
import {CHART_TYPE, STACKING, Y_AXIS_TYPE} from '../../components/metricExplorer/seriesPropertiesConsts';

const chartDisplayProperties = composeReducers(
  (
    state = {
      chartType: CHART_TYPE.LINE,
      chartStackingOption: STACKING.NONE,
      yAxisOption: Y_AXIS_TYPE.LINEAR,
      lowerRange: '',
      higherRange: '',
      opposite: false,
      isStateDirty: false,
    },
    {type, payload},
  ) => {
    switch (type) {
      case actions.setChartType.TYPE: {
        let stacking = STACKING.NONE;
        if (payload === CHART_TYPE.COLUMN) {
          stacking = STACKING.NORMAL;
        }
        return {
          ...state,
          chartType: payload,
          isStateDirty: true,
          chartStackingOption: stacking,
        };
      }
      case actions.setChartStackingOption.TYPE: {
        return {
          ...state,
          chartStackingOption: payload,
          isStateDirty: true,
        };
      }
      case actions.setYAxisOption.TYPE: {
        return {
          ...state,
          yAxisOption: payload,
          isStateDirty: true,
        };
      }
      case actions.setLowerRange.TYPE: {
        return {
          ...state,
          lowerRange: payload,
          isStateDirty: true,
        };
      }
      case actions.setHigherRange.TYPE: {
        return {
          ...state,
          higherRange: payload,
          isStateDirty: true,
        };
      }
      case actions.setYAxisDirection.TYPE: {
        return {
          ...state,
          opposite: payload,
          isStateDirty: true,
        };
      }

      case actions.updateSettings.TYPE: {
        return {
          ...state,
          isStateDirty: false,
        };
      }
      default:
        return state;
    }
  },
);

export default chartDisplayProperties;
