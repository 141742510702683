import React, {useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useField} from 'react-final-form';
import {fetchFunctionsMetaData} from 'metrics/store/actions';
import SimpleExpression from './SimpleExpression';

type PropTypes = {
  isRatioEnabled: boolean,
  isGroupByEnabled: boolean,
  isSeriesFunctionEnabled: boolean,
};

const SimpleExpressionContainer = ({isRatioEnabled, isGroupByEnabled, isSeriesFunctionEnabled}: PropTypes) => {
  const {
    input: {value: simpleExpressionTree, onChange},
  } = useField('simpleExpressionTree');

  const dispatch = useDispatch();
  // eslint-disable-next-line max-len
  const onChangeHandler = useCallback((expressionTree) => onChange({...simpleExpressionTree, expressionTree}), [
    simpleExpressionTree,
  ]);

  useEffect(() => {
    dispatch(fetchFunctionsMetaData());
  }, []);

  return (
    <SimpleExpression
      value={simpleExpressionTree.expressionTree}
      onChange={onChangeHandler}
      isGroupByEnabled={isGroupByEnabled}
      isRatioEnabled={isRatioEnabled}
      isSeriesFunctionEnabled={isSeriesFunctionEnabled}
    />
  );
};

export default SimpleExpressionContainer;
