// @flow
import React, {Fragment} from 'react';
import './StyleGuideComponents.module.scss';
import SeverityMarker from 'common/componentsV2/SeverityMarker';

export default class SeveritySection extends React.Component {
  render() {
    return (
      <Fragment>
        <h4>Severity</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Severity Critical</h5>
            <SeverityMarker severity="critical" />
          </div>
          <div styleName="item">
            <h5>Severity High</h5>
            <SeverityMarker severity="high" />
          </div>
          <div styleName="item">
            <h5>Severity Medium</h5>
            <SeverityMarker severity="medium" />
          </div>
          <div styleName="item">
            <h5>Severity Low</h5>
            <SeverityMarker severity="low" />
          </div>
          <div styleName="item">
            <h5>Severity Info</h5>
            <SeverityMarker severity="info" />
          </div>
        </div>
      </Fragment>
    );
  }
}
