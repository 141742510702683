// @flow
import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {StringParam, useQueryParams} from 'use-query-params';
import {setCustomersFilters as setCustomersFiltersAction} from 'admin.customers/store/actions';
import {ALLOWED_FILTER_KEYS} from 'admin.customers/services/customersService';
import PageLayout from 'common/componentsV2/PageLayout';
import CustomersPageHeader from 'admin.customers/components/CustomersPageHeader';
import CustomersSection from 'admin.customers/components/CustomersSection';
import CustomersFilters from 'admin.customers/components/CustomersFilters';
import './Customers.module.scss';

const Customers = () => {
  const dispatch = useDispatch();
  const [queryParams] = useQueryParams({
    paying: StringParam,
    selfService: StringParam,
    searchQuery: StringParam,
    status: StringParam,
  });

  useEffect(() => {
    dispatch(
      setCustomersFiltersAction({
        paying:
          queryParams.paying && ALLOWED_FILTER_KEYS.paying[queryParams.paying]
            ? ALLOWED_FILTER_KEYS.paying[queryParams.paying].value
            : 'all',
        selfService:
          queryParams.selfService && ALLOWED_FILTER_KEYS.selfService[queryParams.selfService]
            ? ALLOWED_FILTER_KEYS.selfService[queryParams.selfService].value
            : 'all',
        status:
          queryParams.status && ALLOWED_FILTER_KEYS.status[queryParams.status]
            ? ALLOWED_FILTER_KEYS.status[queryParams.status].value
            : 'all',
        searchQuery: queryParams.searchQuery || '',
      }),
    );
  }, [queryParams]);

  return (
    <div styleName="page-footer-container">
      <div styleName="page-container">
        <PageLayout header={<CustomersPageHeader />}>
          <div styleName="page-content-wrapper">
            <div styleName="main">
              <CustomersFilters />
              <CustomersSection />
            </div>
          </div>
        </PageLayout>
      </div>
    </div>
  );
};

export default Customers;
