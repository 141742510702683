import {useRef, useMemo} from 'react';
import {isEqual} from 'lodash';

/* for debug purpose
function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
      }
    });
  }
  return changes(object, base);
}
 */

const useDeepCompareMemoize = (value) => {
  const ref = useRef(value);
  if (!isEqual(value, ref.current)) {
    // console.log(difference(value, ref.current), 'difference debug')
    ref.current = value;
  }
  return ref.current;
};

function useDeepCompareMemo(callback, dependencies) {
  return useMemo(callback, useDeepCompareMemoize(dependencies));
}

export default useDeepCompareMemo;
