/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import * as actions from 'bc/store/actions';
import * as commonActions from 'common/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {get} from 'lodash';
import {getUniqueId} from 'common/utils/guid';

const awsCurDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  // gets sources ids used by items recursively
  const getSourceIdsArr = (sourcesIdArr, item) => {
    if (item.sourceColumn) {
      if (!sourcesIdArr.includes(item.sourceColumn)) {
        sourcesIdArr.push(item.sourceColumn);
      }
    } else if (item.transform && item.transform.input) {
      item.transform.input.forEach((a) => {
        getSourceIdsArr(sourcesIdArr, a);
      });
    }
    return sourcesIdArr;
  };

  const getOriginalName = (item) => {
    if (item.transform && item.transform.input) {
      return getSourceIdsArr([], item)[0];
    }
    if (item.sourceColumn) {
      return item.sourceColumn;
    }
    return item.name;
  };

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  let res = {};
  const stream = state.streams.data[getSelectedIndex()];

  switch (type) {
    case actions.fetchAwsTemplates.TYPE:
      return {...redWrapper({...stream, uiState: {...stream.uiState, templatesIsLoading: true}})};
    case actions.fetchAwsCurReports.TYPE:
      return {...redWrapper({...stream, uiState: {...stream.uiState, reportsIsLoading: true}})};
    case actions.fetchAwsCurManifest.TYPE:
      return {...redWrapper({...stream, uiState: {...stream.uiState, manifestIsLoading: true}})};

    case actions.setAwsTemplates.TYPE:
      return {...redWrapper({...stream, uiState: {...stream.uiState, templates: payload, templatesIsLoading: false}})};

    case actions.setAwsCurManifest.TYPE:
      if (stream.basedOnTemplateId) {
        const template = stream.uiState.templates.find((t) => t.id === stream.basedOnTemplateId);
        if (!template) {
          return state;
        }

        res = {
          schema: {
            columns: get(stream.schema, 'columns', []).filter((item) => item.name.indexOf('resourceTags') > -1),
            sourceColumns: get(stream.schema, 'sourceColumns', []).filter(
              (item) => item.name.indexOf('resourceTags') > -1,
            ),
          },
          filters: template.filters || [],
          uiState: {
            ...stream.uiState,
            manifests: payload,
            manifestsIsLoading: false,
          },
        };

        const manifestsItems = payload.columns || [];

        manifestsItems.forEach((item) => {
          const itemName = `${item.category}/${item.name}`;
          if (template.dimensions.includes(itemName)) {
            res.schema.columns.push({
              id: getUniqueId(),
              sourceColumn: itemName,
              name: itemName,
              type: 'dimension',
              hidden: false,
            });
            res.schema.sourceColumns.push({
              id: itemName,
              name: itemName,
            });
          } else if (template.metrics.includes(itemName)) {
            const newMetricCol = {
              id: getUniqueId(),
              sourceColumn: itemName,
              name: itemName,
              type: 'metric',
              targetType: template.types[itemName] || '',
              hidden: false,
            };
            const metricTags = template.units[itemName];
            if (metricTags) {
              newMetricCol.metricTags = {unit: metricTags};
            }
            res.schema.columns.push(newMetricCol);
            res.schema.sourceColumns.push({
              id: itemName,
              name: itemName,
            });
          }
        });

        // / add items from old stream
        if (stream.schema) {
          // apply changed to items from template and tags
          res.schema.columns.forEach((resItem) => {
            stream.schema.columns.forEach((oldItem) => {
              const originName = getOriginalName(oldItem);
              if (originName === resItem.name) {
                if (originName !== oldItem.name) {
                  resItem.name = oldItem.name;
                }
                if (oldItem.transform) {
                  resItem.transform = oldItem.transform;
                }
                if (typeof oldItem.sourceColumn === 'undefined') {
                  delete resItem.sourceColumn;
                }
              }
            });
          });

          // / add user items from old schema
          const dimetrics = stream.metrics.concat(stream.dimensions);
          stream.schema.columns.forEach((oldItem) => {
            const originName = getOriginalName(oldItem);
            if (!dimetrics.includes(originName)) {
              if (oldItem.transform && oldItem.transform.name === 'const') {
                res.schema.columns.push(oldItem);
              }
              if (oldItem.transform && oldItem.transform.input && oldItem.transform.input.length > 1) {
                let shouldPush = true;
                getSourceIdsArr([], oldItem).forEach((itemName) => {
                  if (!dimetrics.includes(itemName)) {
                    shouldPush = false;
                  }
                });
                if (shouldPush) {
                  res.schema.columns.push(oldItem);
                }
              }
            }
          });
        }

        return {...redWrapper(res), isStreamNeedUpdating: false};
      }
      return {...redWrapper({...stream, uiState: {...stream.uiState, manifests: payload, manifestIsLoading: false}})};

    case actions.setAwsCurReports.TYPE:
      return {...redWrapper({...stream, uiState: {...stream.uiState, reports: payload, reportsIsLoading: false}})};

    case actions.applyAwsCurTemplate.TYPE:
      const template = stream.uiState.templates.find((t) => t.id === payload);
      commonActions.segmentClickEvent({
        category: 'stream-aws-cur',
        name: `use-case-selected: ${template.name}`,
      });
      const manifestsItems =
        stream.uiState.manifests && stream.uiState.manifests.columns ? stream.uiState.manifests.columns : [];

      res = {
        basedOnTemplateId: payload,
        schema: {
          columns: get(stream.schema, 'columns', []).filter((item) => item.name.indexOf('resourceTags') > -1),
          sourceColumns: get(stream.schema, 'sourceColumns', []).filter(
            (item) => item.name.indexOf('resourceTags') > -1,
          ),
        },
        dimensions: template.dimensions.concat(stream.dimensions.filter((item) => item.indexOf('resourceTags') > -1)),
        metrics: template.metrics,
        filters: template.filters || [],
      };

      manifestsItems.forEach((item) => {
        const itemName = `${item.category}/${item.name}`;
        if (template.dimensions.includes(itemName)) {
          res.schema.columns.push({
            id: getUniqueId(),
            sourceColumn: itemName,
            name: itemName,
            type: 'dimension',
            hidden: false,
          });
          res.schema.sourceColumns.push({
            id: itemName,
            name: itemName,
          });
        } else if (template.metrics.includes(itemName)) {
          const newMetricCol = {
            id: getUniqueId(),
            sourceColumn: itemName,
            name: itemName,
            type: 'metric',
            targetType: template.types[itemName] || '',
            hidden: false,
          };
          const metricTags = template.units[itemName];
          if (metricTags) {
            newMetricCol.metricTags = {unit: metricTags};
          }
          res.schema.columns.push(newMetricCol);
          res.schema.sourceColumns.push({
            id: itemName,
            name: itemName,
          });
        }
      });

      // / add items from old stream
      if (stream.schema) {
        // apply changed to items from template and tags
        res.schema.columns.forEach((resItem) => {
          stream.schema.columns.forEach((oldItem) => {
            const originName = getOriginalName(oldItem);
            if (originName === resItem.name) {
              if (originName !== oldItem.name) {
                resItem.name = oldItem.name;
              }
              if (oldItem.transform) {
                resItem.transform = oldItem.transform;
              }
              if (typeof oldItem.sourceColumn === 'undefined') {
                delete resItem.sourceColumn;
              }
            }
          });
        });

        // / add user items from old schema
        const dimetrics = res.metrics.concat(res.dimensions);
        stream.schema.columns.forEach((oldItem) => {
          const originName = getOriginalName(oldItem);
          if (!dimetrics.includes(originName)) {
            if (oldItem.transform && oldItem.transform.name === 'const') {
              res.schema.columns.push(oldItem);
            }
            if (oldItem.transform && oldItem.transform.input && oldItem.transform.input.length > 1) {
              let shouldPush = true;
              getSourceIdsArr([], oldItem).forEach((itemName) => {
                if (!dimetrics.includes(itemName)) {
                  shouldPush = false;
                }
              });
              if (shouldPush) {
                res.schema.columns.push(oldItem);
              }
            }
          }
        });
      }
      return {...redWrapper(res), isStreamNeedUpdating: false};

    case actions.applyAwsCurTags.TYPE:
      res = {
        schema: {
          columns: get(stream.schema, 'columns', []).filter((item) => item.name.indexOf('resourceTags') < 0),
          sourceColumns: get(stream.schema, 'sourceColumns', []).filter(
            (item) => item.name.indexOf('resourceTags') < 0,
          ),
        },
        dimensions: stream.dimensions.filter((item) => item.indexOf('resourceTags') < 0) || [],
      };

      payload.forEach((item) => {
        res.schema.columns.push({
          id: getUniqueId(),
          sourceColumn: item.id,
          name: item.id,
          type: 'dimension',
          hidden: false,
        });
        res.schema.sourceColumns.push({
          id: item.id,
          name: item.id,
        });
        res.dimensions.push(item.id);
      });
      return {...redWrapper(res)};

    default:
      return state;
  }
});

export default awsCurDataStreamReducer;
