import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  value: {
    maxWidth: ({maxWidth}) => (maxWidth ? `${maxWidth}px` : 'none'),
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
}));

const ChannelField = ({label, value, maxWidth}: {label: string, value: string, maxWidth: number}) => {
  const classes = useStyles({maxWidth});
  return (
    <div>
      <div className="fontWeight_500 color_gray-400">{label}</div>
      <div className={classes.value}>{value || '-'}</div>
    </div>
  );
};

export default ChannelField;
