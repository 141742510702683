import React from 'react';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {Box} from '@material-ui/core';

type PropTypes = {
  children: React.Node,
  label: string,
  icon?: string,
  labelColor: string,
  borderColor: string,
  automationId: string,
  extraStyle: Object,
};

const FiltersBlock = ({children, label, icon, borderColor, labelColor, automationId, extraStyle}: PropTypes) => (
  <Box
    css={extraStyle}
    automation-id={automationId}
    borderRadius={10}
    border="solid 2px"
    borderColor={borderColor || 'grey.300'}
    padding={2}
  >
    <div className="mb_2 display_flex alignItems_center">
      {icon && <Box color={labelColor} mr={1} fontSize="h2.fontSize" className={`icon ${icon}`} />}
      <TypographyBox variant="h2" color={labelColor}>
        {label}
      </TypographyBox>
    </div>
    {children}
  </Box>
);

FiltersBlock.defaultProps = {
  icon: null,
};

export default FiltersBlock;
