/* eslint-disable */

const deletePropFromObj = (obj, deleteThisKey) => {
  if (Array.isArray(obj)) {
    obj.forEach((element) => deletePropFromObj(element, deleteThisKey));
  } else if (typeof obj === 'object') {
    for (const key in obj) {
      const value = obj[key];
      if (key === deleteThisKey) {
        delete obj[key];
      } else {
        deletePropFromObj(value, deleteThisKey);
      }
    }
  }
};

export default deletePropFromObj;
