import 'rxjs/add/operator/do';
import 'rxjs/add/operator/switch';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import * as actions from 'common/store/actions';
import * as metricsActions from 'metrics/store/actions';

export default (action$) =>
  action$
    .ofType(actions.appInit.TYPE)
    .map((action) => {
      const {payload: meObserverable} = action;
      return meObserverable;
    })
    .switch()
    .flatMap((me) => [metricsActions.setMetricsResolutions(me)]);
