import errorHandler from 'common/utils/notifications/errorHandler';
import {VALID_CSV_VALUES} from 'assets/services/service';

export const fileUploadErrorCodes = {
  10000: {
    title: 'Incorrect file type',
    description: `Please upload a file of type ${VALID_CSV_VALUES.label}. `,
    moreInfo: false,
    settings: {
      uid: '10000',
    },
  },
  10001: {
    title: 'File is too large',
    description: `File size should be no larger than ${VALID_CSV_VALUES.size / 1000000} MB. `,
    moreInfo: false,
    settings: {
      uid: '10001',
    },
  },
  10002: {
    title: 'Different file name',
    description: 'The file name should be the same as the file you are replacing.',
    moreInfo: false,
    settings: {
      uid: '10002',
    },
  },
  10003: {
    title: 'Different file name',
    description: 'A file with this name already exists. Please choose a different file name.',
    moreInfo: false,
    settings: {
      uid: '10003',
    },
  },
  24005: {
    title: 'File was not Added.',
    description: 'A file with this name already exists. Please choose a different file name.',
    moreInfo: false,
  },
};

export const fileUploadErrorHandler = errorHandler(fileUploadErrorCodes);
