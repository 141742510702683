// @flow
import React, {useMemo, useRef} from 'react';
import {Box} from '@material-ui/core';
import CollapsedMetricsList from 'metrics/components/metricExplorer/CollapsedMetricsList';
import {get} from 'lodash';

type PropTypes = {
  metrics: Array,
  isLoading: Boolean,
  validationMessage: String,
  isZoomLoading: boolean,
  timeScale: string,
  onMouseMove: Function,
  onChartResize: Function,
  tileData: Object,
  isResize: boolean,
  setResize: Function,
  isFullSize: boolean,
  dateRange: Object,
  dashboardId: String,
  chartId: String,
  byTreeExp: Object,
};

const TileChartTemplate = ({
  metrics,
  isLoading,
  validationMessage,
  timeScale,
  isZoomLoading,
  chartId,
  onMouseMove,
  tileData,
  isResize,
  setResize,
  onChartResize,
  isFullSize,
  byTreeExp,
  dateRange,
  dashboardId,
}: PropTypes) => {
  const wrapperRef = useRef();
  const isLegendEnabled = get(tileData, 'lineChart.legend.enabled', false) || !!isFullSize;
  const isLegendRight = get(tileData, 'lineChart.legend.position') === 'right' && !isFullSize;
  const staticLine = get(tileData, 'lineChart.staticLine');
  const legendSize = useMemo(
    () => ({
      w: get(tileData, 'lineChart.legend.horizontalRatio'),
      h: get(tileData, 'lineChart.legend.ratio'),
    }),
    [tileData],
  );

  return (
    <Box
      automation-id="dashboard-add-tile-preview-area"
      display="flex"
      flexDirection="column"
      alignItems="center"
      height={1}
      flexGrow={1}
      width={1}
      overflow="hidden"
    >
      {!isLoading && validationMessage && (
        <Box flexShrink={0} fontSize={14} color="gray.600" bgcolor="blue.100" padding={1.5} borderRadius={6} m={2}>
          {validationMessage}
        </Box>
      )}
      {!validationMessage && (
        <Box display="flex" flexDirection="column" position="relative" height={1} mt={0.5} width={1} ref={wrapperRef}>
          <CollapsedMetricsList
            isExtendedTooltip
            onChartResize={onChartResize}
            dateRange={dateRange}
            chartId={chartId}
            isZoomLoading={isZoomLoading}
            timeScale={timeScale}
            metrics={metrics}
            isLoading={isLoading}
            legendSize={legendSize}
            isLegendEnabled={isLegendEnabled}
            isLegendRight={isLegendRight}
            isTile
            onMouseMove={onMouseMove}
            isResize={isResize}
            setResize={setResize}
            byTreeExp={byTreeExp}
            isFullSize={isFullSize}
            staticLine={staticLine}
            dashboardId={dashboardId}
            tileData={tileData}
          />
        </Box>
      )}
    </Box>
  );
};

export default React.memo(TileChartTemplate);
/* eslint-enable */
