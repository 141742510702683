// @flow
import React, {PureComponent} from 'react';

import './TimeInput.module.scss';

type PropTypes = {date: Date, onChange: Function};

const isDigitKeyCode = (keyCode) => (keyCode > 47 && keyCode < 58) || (keyCode > 95 && keyCode < 106);

class TimeInput extends PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    this.state = {
      isPM: this.props.date.getHours() > 11,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.date !== state.prevDate) {
      return {
        isPM: props.date.getHours() > 11,
        prevDate: props.date,
      };
    }
    return null;
  }

  componentDidMount() {
    this.setTimeFromDateProp(this.props.date);
  }

  componentDidUpdate(prevProps) {
    if (this.props.date !== prevProps.date) {
      this.setTimeFromDateProp(this.props.date);
    }
  }

  setHoursRef = (el) => {
    this.hoursElm = el;
  };

  setMinutesRef = (el) => {
    this.minutesElm = el;
  };

  setTimeFromDateProp = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    let curHr;
    if (hours === 0) {
      curHr = 12;
    } else {
      curHr = hours < 13 ? hours : hours - 12;
    }

    this.hoursElm.value = curHr < 10 ? `0${curHr}` : curHr;
    this.minutesElm.value = minutes < 10 ? `0${minutes}` : minutes;
  };

  hoursOnChange = (e) => {
    if (e.target.value && e.target.value > 12) {
      e.target.value = 12;
    }
    this.dateChanged();

    if (+e.target.selectionStart === 2) {
      this.minutesElm.focus();
      setTimeout(() => {
        this.minutesElm.setSelectionRange(0, 0);
      });
    }
  };

  hoursOnKeyDown = (e) => {
    switch (e.keyCode) {
      case 37: // left arrow;
      case 8: // back btn
        return;
      case 39: // right arrow;
        if (e.target.selectionStart === 2) {
          setTimeout(() => {
            this.minutesElm.setSelectionRange(0, 0);
          });
        }
        break;
      default:
    }

    if (e.target.selectionStart === 2) {
      this.minutesElm.focus();
      return;
    }

    if (e.keyCode > 57 && !isDigitKeyCode(e.keyCode)) {
      e.preventDefault();
    }

    if (isDigitKeyCode(e.keyCode) && e.target.value.length === 2 && e.target.selectionStart < 2) {
      this.hoursElm.setSelectionRange(e.target.selectionStart, e.target.selectionStart + 1);
    }
  };

  minutesOnChange = (e) => {
    if (e.target.value && e.target.value > 59) {
      e.target.value = 59;
    }
    this.dateChanged();
  };

  minutesOnKeyDown = (e) => {
    switch (e.keyCode) {
      case 37: // left arrow;
      case 8: // back btn
        if (e.target.selectionStart === 0 && e.target.selectionEnd === 0) {
          this.hoursElm.focus();
          setTimeout(() => {
            this.hoursElm.setSelectionRange(2, 2);
          });
          return;
        }
        break;
      default:
    }

    if (e.keyCode > 57 && !isDigitKeyCode(e.keyCode)) {
      e.preventDefault();
    }

    if (isDigitKeyCode(e.keyCode) && e.target.value.length === 2 && e.target.selectionStart === 2) {
      e.preventDefault();
    }

    if (isDigitKeyCode(e.keyCode) && e.target.value.length === 2 && e.target.selectionStart < 2) {
      this.minutesElm.setSelectionRange(e.target.selectionStart, e.target.selectionStart + 1);
    }
  };

  hoursOnBlur = (e) => {
    if (e.target.value && parseInt(e.target.value, 10) < 10) {
      e.target.value = `0${parseInt(e.target.value, 10)}`;
    }
  };

  minutesOnBlur = (e) => {
    if (e.target.value && parseInt(e.target.value, 10) < 10) {
      e.target.value = `0${parseInt(e.target.value, 10)}`;
    }

    setTimeout(() => {
      this.minutesElm.setSelectionRange(0, 0);
    });
  };

  toggleAmPm = () => {
    const {isPM} = this.state;
    this.setState(
      {
        isPM: !isPM,
      },
      () => {
        this.dateChanged();
      },
    );
  };

  dateChanged = () => {
    const newDate = this.props.date; // new Date(this.props.date);
    const hrVal = this.hoursElm.value ? parseInt(this.hoursElm.value, 10) : 0;
    let hr;
    if (hrVal === 12) {
      hr = this.state.isPM ? 12 : 0;
    } else {
      hr = this.state.isPM ? hrVal + 12 : hrVal;
    }
    newDate.setHours(hr);
    newDate.setMinutes(this.minutesElm.value ? parseInt(this.minutesElm.value, 10) : 0);
    newDate.setSeconds(0);
    this.props.onChange(newDate);
  };

  render() {
    return (
      // we use className as placeholder so we can style from outside
      <div styleName="root" className="andt-time-input">
        <input
          type="text"
          className="light-placeholder"
          ref={this.setHoursRef}
          maxLength="2"
          placeholder="12"
          onChange={this.hoursOnChange}
          onKeyDown={this.hoursOnKeyDown}
          onBlur={this.hoursOnBlur}
        />
        :
        <input
          type="text"
          className="light-placeholder"
          ref={this.setMinutesRef}
          maxLength="2"
          placeholder="00"
          onKeyDown={this.minutesOnKeyDown}
          onChange={this.minutesOnChange}
          onBlur={this.minutesOnBlur}
        />
        <button type="button" className="btn btn-flat" styleName="am-pm-btn" onClick={this.toggleAmPm}>
          {this.state.isPM ? 'PM' : 'AM'}
        </button>
      </div>
    );
  }
}

export default TimeInput;
