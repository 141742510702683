/* eslint-disable no-console */
// @flow
import React, {Fragment} from 'react';
import './StyleGuideComponents.module.scss';
import Input from '../../common/componentsV2/Input';

export default class InputSection extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <h4>Input</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Normal, no placeholder</h5>
            <Input onChange={(e) => console.log(e.target.value)} />
          </div>

          <div styleName="item">
            <h5>Normal, with placeholder</h5>
            <Input onChange={(e) => console.log(e.target.value)} placeHolder="This is a placeholder" />
          </div>

          <div styleName="item">
            <h5>Disabled</h5>
            <Input onChange={(e) => console.log(e.target.value)} isDisabled />
          </div>

          <div styleName="item">
            <h5>Disabled with value</h5>
            <Input onChange={(e) => console.log(e.target.value)} isDisabled value="This is a test" />
          </div>

          <div styleName="item">
            <h5>Validation Error</h5>
            <Input onChange={(e) => console.log(e.target.value)} isInvalid />
          </div>
        </div>
      </Fragment>
    );
  }
}
