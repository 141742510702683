import React from 'react';
import {useSelector} from 'react-redux';
import {getSortFiltersList} from 'metrics/store/selectors';

import SelectAndt, {THEME_BLUE_LEAN_MODIFIED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  value: any,
  onChange: Function,
  placeholder: string,
};

const Sort = ({placeholder, value, onChange}: PropTypes) => {
  const definitions = useSelector(getSortFiltersList);

  return (
    <SelectAndt
      id="sortField"
      automationId="sortField"
      extraClassName="alerts-dropdown-btn"
      options={definitions}
      optionHeight={40}
      menuWidth={170}
      type={TYPE_NEW_NO_SEARCH}
      theme={THEME_BLUE_LEAN_MODIFIED}
      onChange={(option) => onChange(option.value)}
      value={value && definitions.find((item) => item.value === value)}
      placeholder={placeholder || 'Auto'}
    />
  );
};

export default Sort;
