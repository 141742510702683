// @flow
import React, {Fragment} from 'react';
import {Box} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import Input from 'common/componentsV2/Input';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropsType = {
  label: String,
  value: Object,
  onChange: Function,
  scaleValue: String,
  onChangeScaleValue: Function,
};

const options = [
  {
    label: 'Hours',
    value: 'hours',
  },
  {
    label: 'Days',
    value: 'days',
  },
  {
    label: 'Weeks',
    value: 'weeks',
  },
];

const RelativeField = ({label, value, onChange, scaleValue, onChangeScaleValue}: PropsType) => (
  <Fragment>
    <Box display="flex" alignItems="center">
      <TypographyBox
        variant="h3"
        fontSize={18}
        color="gray.500"
        mr={1}
        width={120}
        display="flex"
        justifyContent="flex-start"
      >
        {label}
      </TypographyBox>
      <Box mr={1} width={80}>
        <Input
          type="number"
          automationId="customDateRangeRelativeField"
          onChange={(e) => onChange(e.target.value)}
          style={{height: 36}}
          value={value}
          fullSize
        />
      </Box>
      <SelectAndt
        automationId="customDateRangeRelativeScale"
        onChange={(item) => onChangeScaleValue(item.value)}
        value={options.find((item) => item.value === scaleValue)}
        options={options}
        optionHeight={40}
        buttonWidth={100}
        type={TYPE_NEW_NO_SEARCH}
        theme={THEME_BLUE_LEAN}
      />
    </Box>
  </Fragment>
);

export default RelativeField;
