// @flow
import React from 'react';
import {Modal} from 'react-bootstrap';

type PropTypes = {
  isOpen: boolean,
  className: String,
  title: String,
  message: Node,
  children: any,
  onClose: Function,
  onStreamData: Function,
  onCreateAlerts: Function,
};

class SelectStreamItOrCreateAlertsFlowModal extends React.PureComponent {
  props: PropTypes;

  state = {};

  render() {
    return (
      <Modal
        show={this.props.isOpen}
        dialogClassName={this.props.className ? `bc ${this.props.className}` : 'bc'}
        onHide={this.props.onClose}
      >
        <Modal.Header bsClass="bc-modal-header">
          <Modal.Title>{this.props.title}</Modal.Title>
          <button type="button" className="btn btn-flat btn-icon-36 btn-secondary" onClick={this.props.onClose}>
            <i className="icon icn-icon-table-delete" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-body-message">
            {this.props.message}
            {this.props.children}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-flat btn-secondary" onClick={this.props.onStreamData}>
            Stream Data
          </button>
          <button type="button" className="btn btn-flat btn-primary" onClick={this.props.onCreateAlerts}>
            Create Alerts
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default SelectStreamItOrCreateAlertsFlowModal;
