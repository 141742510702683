// @flow
import React from 'react';

type PropTypes = {
  failOnAbsence: Boolean,
  onSetSuppress: Function,
};

export default class FailOnAbsence extends React.PureComponent {
  props: PropTypes;

  handleChange = () => {
    const {onSetSuppress, failOnAbsence} = this.props;
    onSetSuppress(failOnAbsence);
  };

  render() {
    const {failOnAbsence} = this.props;

    return (
      <div className="cb2">
        <input id="suppress" type="checkbox" onChange={this.handleChange} checked={failOnAbsence} />
        <label htmlFor="suppress">Do not send the alert if the influencing metrics are not found</label>
      </div>
    );
  }
}
