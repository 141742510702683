import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const addDimensions = (state, payload, meta) =>
  state.map((stream) => {
    if (meta === stream.name) {
      return {
        ...stream,
        dimensions: {
          ...payload,
        },
      };
    }
    return stream;
  });

const fetchStreams = composeReducers(
  makeAsyncReducer(actions.fetchStreams, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload, meta}) => {
    switch (type) {
      case actions.fetchStreams.success.TYPE:
        return {
          ...state,
          data: payload.streams,
        };
      case actions.fetchStreamDimensions.TYPE:
        return {
          ...state,
          data: addDimensions(state.data, null, meta),
        };
      case actions.fetchStreamDimensions.success.TYPE:
        return {
          ...state,
          data: addDimensions(state.data, payload, meta),
        };
      default:
        return state;
    }
  },
);

const fetchStats = composeReducers(
  makeAsyncReducer(actions.fetchStats, {
    defaultData: {metrics: EMPTY_OBJECT, rate: EMPTY_OBJECT},
    includeUpdateAt: true,
    shouldSpread: true,
  }),
  (state, {type, payload}) => {
    switch (type) {
      case actions.fetchStats.success.TYPE:
        return {
          ...state,
          metrics: payload.metrics,
          rate: payload.rate,
        };
      default:
        return state;
    }
  },
);

const fetchStreamsReport = composeReducers(
  makeAsyncReducer(actions.fetchStreamsReport, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.fetchStreamsReport.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const fetchTaskLog = composeReducers(
  makeAsyncReducer(actions.fetchTaskLog, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.fetchTaskLog.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const fetchKibanaLog = composeReducers(
  makeAsyncReducer(actions.fetchKibanaLog, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.fetchKibanaLog.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const flushMetrics = composeReducers(
  makeAsyncReducer(actions.flushMetrics, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.flushMetrics.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const searchMetrics = composeReducers(
  makeAsyncReducer(actions.searchMetrics, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.searchMetrics.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const deleteMetrics = composeReducers(
  makeAsyncReducer(actions.deleteMetrics, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.deleteMetrics.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const getMetricsEps = composeReducers(
  makeAsyncReducer(actions.getMetricsEps, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.getMetricsEps.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const generateTemplates = composeReducers(
  makeAsyncReducer(actions.generateTemplates, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type}) => {
    switch (type) {
      case actions.generateTemplates.success.TYPE:
        return {
          ...state,
          success: true,
        };
      case actions.generateTemplates.failure.TYPE:
        return {
          ...state,
          success: false,
        };
      default:
        return state;
    }
  },
);

const fetchAlertsErrors = composeReducers(
  makeAsyncReducer(actions.fetchAlertsErrors, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.fetchAlertsErrors.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const fetchCompositeErrors = composeReducers(
  makeAsyncReducer(actions.fetchCompositeErrors, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state = {}, {type, payload}) => {
    switch (type) {
      case actions.fetchCompositeErrors.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

export default combineReducers({
  fetchStreams,
  // fetchStreamDimensions,
  fetchStats,
  fetchStreamsReport,
  fetchTaskLog,
  fetchKibanaLog,
  flushMetrics,
  searchMetrics,
  deleteMetrics,
  getMetricsEps,
  generateTemplates,
  fetchAlertsErrors,
  fetchCompositeErrors,
});
