import {combineReducers} from 'redux';
import {emptyReducer} from 'common/utils/reducers';
import dataReducer from './dataReducer';

const root = combineReducers({
  views: emptyReducer,
  data: dataReducer,
});

export default root;
