// @flow
import React from 'react';
import './AlertInfoWidget1.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

type PropTypes = {
  title: String,
  value: String,
  valueNode: Node,
  icon: String,
  tooltip?: String,
  valueClass?: String,
};

export default class AlertInfoWidget extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    tooltip: '',
    valueClass: '',
  };

  render() {
    const {title, value, icon, tooltip, valueClass, valueNode} = this.props;

    const cssClass = valueClass;
    return (
      <div styleName="box">
        <div styleName={`top-line ${cssClass}`}>
          <div className={`icon ${icon}`} />
          <div styleName="box-name">{title}</div>
        </div>

        {tooltip ? (
          <Tooltip content={tooltip} type={TYPES.SMALL}>
            <div styleName={`bottom-line ${cssClass}`} className="ellipsis">
              {valueNode || <span>{value}</span>}
            </div>
          </Tooltip>
        ) : (
          <div styleName={`bottom-line ${cssClass}`} className="ellipsis">
            {valueNode || <span>{value}</span>}
          </div>
        )}
      </div>
    );
  }
}
