const scrollTo = (parentNode, scrollToNode, behavior = 'smooth') => {
  if (parentNode && parentNode.current !== null && scrollToNode && scrollToNode.current !== null) {
    parentNode.current.scrollTo({
      top: scrollToNode.current.offsetTop,
      left: 0,
      behavior,
    });
  }
};

export default scrollTo;
