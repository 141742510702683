// @flow
import React from 'react';
import './MenuSectionSearch.module.scss';

type PropTypes = {
  options: Array<String>,
  onChange: Function,
  selectedIndex: number,
};

export default class MenuSectionSearch extends React.PureComponent {
  props: PropTypes;

  handleOnClick = (index) => {
    this.props.onChange(this.props.options[index]);
  };

  render() {
    return (
      <div styleName="container">
        {this.props.options.map((option, index) => (
          <div
            automation-id={`metricExplorerSearchAll${option.charAt(0).toUpperCase() + option.slice(1)}`}
            key={option}
            styleName={['option', index === this.props.selectedIndex ? 'selected' : null].join(' ').trim()}
            onClick={() => this.handleOnClick(index)}
          >
            <span styleName={index === this.props.selectedIndex ? 'selected-option' : ''}>{option}</span>
          </div>
        ))}
      </div>
    );
  }
}
