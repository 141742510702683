// @flow
import React from 'react';
import './TopFeedbackerItem.module.scss';

type PropTypes = {
  name: String,
  feedbackCount: Number,
  index: Number,
};

const getEmoji = (index) => {
  switch (index) {
    case 0:
      return {emoji: '😀️', ariaLabel: '1st place'};
    case 1:
      return {emoji: '🙂', ariaLabel: '2s place'};
    case 2:
      return {emoji: '😌', ariaLabel: '3rd place'};
    default:
      return {emoji: '🙂', ariaLabel: '1st place'};
  }
};

const TopFeedbackerItem = (props: PropTypes) => {
  const {name, feedbackCount, index} = props;
  const emojiPlace = getEmoji(index);
  return (
    <div styleName="item-wrapper">
      <div styleName="mask">
        <span styleName="emoji" role="img" aria-label={emojiPlace.ariaLabel}>
          {emojiPlace.emoji}
        </span>
        <span styleName="bg-emoji" />
        <span styleName="shadow" />
      </div>
      <div styleName="name">
        <span className="ellipsis">
          {index === 0 && (
            <span role="img" aria-label="1st place">
              {' '}
              🏆{' '}
            </span>
          )}
          {name}
        </span>
      </div>
      <span styleName="count">{feedbackCount} Feedback</span>
    </div>
  );
};

export default TopFeedbackerItem;
