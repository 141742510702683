// @flow
import React, {Fragment} from 'react';
import './LabelAndValueCol.module.scss';

type PropTypes = {
  title: string,
  additionalTitle: Node,
  children: Node,
  isBreakLine: boolean,
  isSmallTitle: boolean,
};

export default class LabelAndValueCol extends React.PureComponent {
  props: PropTypes;

  render() {
    const {title, children, additionalTitle, isBreakLine} = this.props;

    return (
      <Fragment>
        <header styleName="header">
          <h3 styleName={['title', this.props.isSmallTitle ? 'small' : ''].join(' ')}>{title}</h3>
          {additionalTitle}
        </header>
        <div styleName={['value', isBreakLine ? 'break-line' : ''].join(' ').trim()}>{children}</div>
      </Fragment>
    );
  }
}
