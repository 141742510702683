// @flow
import React from 'react';
import Box from '@material-ui/core/Box';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {prettifyImpactNumber, CURRENCY_OPTIONS} from 'impact/services/constants';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {createTheme, themesOptions} from './impactTheme';

const ImpactChip = (props: {
  impact: Object,
  icon?: Boolean,
  text?: String,
  tooltipContent?: String,
  themeName?: String,
}) => {
  const {impactSum, positive, currency} = props.impact;
  const currencyObj = CURRENCY_OPTIONS[currency];
  const theme = createTheme(props.themeName);
  const style = theme(currencyObj.direction, positive);

  return (
    <SmartTooltip placement="top" content={props.tooltipContent}>
      <Box p={1} style={style.container}>
        {props.icon && (
          <TypographyBox style={style.iconWrapper}>
            <i className="icn-general16-impact" style={style.icon} />
          </TypographyBox>
        )}
        {props.text && (
          <TypographyBox>
            <span style={style.text}>{props.text}</span>
          </TypographyBox>
        )}
        <TypographyBox style={style.impactValue}>
          <span>{currencyObj.symbol}</span>
          <span>{prettifyImpactNumber(impactSum)}</span>
        </TypographyBox>
      </Box>
    </SmartTooltip>
  );
};

ImpactChip.defaultProps = {
  icon: false,
  text: null,
  tooltipContent: 'Impact',
  themeName: themesOptions.tiny,
};

export default ImpactChip;
