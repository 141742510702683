// @flow
import React from 'react';
import ConditionContainer from './ConditionContainer';
import './UpdatePolicyCondition.module.scss';

const options = [
  {
    value: true,
    text: 'YES',
  },
  {
    value: false,
    text: 'NO',
  },
];

type PropTypes = {
  updatePolicyInterval: Object,
  onChange: Function,
};

const UpdatePolicyCondition = (props: PropTypes) => (
  <ConditionContainer title="Send Updates on Alert">
    <div styleName="buttons-container">
      {options.map((s) => (
        <button
          type="button"
          key={s.value}
          automation-id={`editAlert${s.value}Button`}
          className={`btn btn-raised btn-outline btn-outline-large ${
            s.value === props.updatePolicyInterval.enabled ? 'btn-outline-light' : 'btn-outline-dark'
          }`}
          onClick={() =>
            props.onChange({
              value: props.updatePolicyInterval.value,
              enabled: s.value,
            })
          }
        >
          {s.text}
        </button>
      ))}
    </div>
    {props.updatePolicyInterval.enabled && (
      <div styleName="form-container">
        <div styleName="sub-title">
          Minimum intervals between updates in
          {` ${props.updatePolicyInterval.scaleText}`}
        </div>
        <div styleName="input-container">
          <input
            type="number"
            automation-id="editAlertSendUpdatesTextbox"
            className="dark-input"
            min={props.updatePolicyInterval.min}
            step={props.updatePolicyInterval.step}
            value={props.updatePolicyInterval.value}
            onKeyDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            onChange={(e) =>
              props.onChange({
                value: e.target.value,
                enabled: props.updatePolicyInterval.enabled,
              })
            }
          />
        </div>
      </div>
    )}
  </ConditionContainer>
);

export default UpdatePolicyCondition;
