import {resolutionTypes} from 'metrics/services/metricsService';
import {composeReducers} from 'common/utils/reducers';
import {intersection, cloneDeep} from 'lodash';
import * as actions from '../actions';

const resolutions = composeReducers((state = {types: resolutionTypes}, {type, payload}) => {
  switch (type) {
    case actions.setMetricsResolutions.TYPE: {
      if (payload && payload.organization && payload.organization.kind !== 'anodot') {
        const rollupToResolutionKey = {
          SHORTROLLUP: 'short',
          MEDIUMROLLUP: 'medium',
          LONGROLLUP: 'long',
          LONGLONGROLLUP: 'longlong',
          WEEKLY: 'weekly',
        };
        const resolutionsClone = cloneDeep(resolutionTypes);
        Object.keys(rollupToResolutionKey).forEach((rollup) => {
          resolutionsClone[rollupToResolutionKey[rollup]].enabled =
            intersection(payload.organization.serverConfiguration.rollups, [rollup]).length === 1;
        });

        return {
          types: resolutionsClone,
        };
      }

      return {
        types: resolutionTypes,
      };
    }

    default:
      return state;
  }
});

export default resolutions;
