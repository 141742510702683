// @flow

import React from 'react';
import './EmptyMessage.module.scss';

export const IMAGE_TYPES = {
  NO_ALERTS: 'noAlerts',
  ALERTS_FILTERED_OUT: 'alertsFilteredOut',
  NO_TRIGGERED: 'empty',
};

type PropTypes = {
  imageType: String,
  title: String,
  subtitle: String,
};

export default class EmptyMessage extends React.PureComponent {
  props: PropTypes;

  render() {
    const d1 =
      'M51 5.826C51 2.622 53.604 0 56.789 0h74.424C134.396 0 137 2.622 137 5.826v54.348c0 3.204-2.604 ' +
      '5.826-5.787 5.826h-57.16c.013-16.66-7.51-28.085-23.053-33V5.826zM71 18h44a2 2 0 0 0 0-4H71a2 2 0 0 0 0 ' +
      '4zm0 12h20c1.11 0 2-.895 2-2s-.89-2-2-2H71c-1.11 0-2 .895-2 2s.89 2 2 2zM28 98H.325A.33.33 0 0 1 0 ' +
      '97.675v-8.776a6.096 6.096 0 0 1 4.41-5.842c3.667-1.031 5.94-4.455 5.94-18.325s8.825-24.96 ' +
      '21.465-28.616l.952-.278.058-.997C33.047 31.005 36.2 28 39.999 28c3.801 0 6.954 3.005 7.176 ' +
      '6.841l.058.997.954.278a30 30 0 0 1 2.502.847c11.283 4.405 18.959 13.908 18.959 27.769 0 13.86 2.271 17.29 ' +
      '5.946 18.327 2.595.734 4.406 3.134 4.406 5.84v8.774a.33.33 0 0 1-.323.327H52l-.196 1.16c-.85 5.04-5.923 ' +
      '8.84-11.805 8.84-5.88 0-10.952-3.8-11.8-8.84L28 98z';

    const d2 =
      'M80.772 64.437a18.774 18.774 0 0 1-8.923 2.24C61.44 66.677 53 58.242 53 47.838 53 37.434 61.44 29 ' +
      '71.85 29c10.41 0 18.848 8.434 18.848 18.838 0 4.273-1.423 8.213-3.821 11.374l17.95 17.95a4.006 4.006 0 1 ' +
      '1-5.665 5.665l-18.39-18.39zM67 24.463C56.049 26.653 47.795 36.358 47.795 48a24.009 24.009 0 0 0 14.332 ' +
      '22H51.778l-.195 1.16C50.736 76.2 45.686 80 39.828 80c-5.855 0-10.905-3.8-11.75-8.84L27.88 70H.324A.33.33 0 ' +
      '0 1 0 69.675v-8.776c0-2.702 1.806-5.104 4.392-5.842 3.65-1.031 5.914-4.455 5.914-18.325s8.787-24.96 ' +
      '21.373-28.616l.949-.278.057-.997C32.906 3.005 36.045 0 39.828 0c3.785 0 6.924 3.005 7.146 ' +
      '6.841l.057.997.95.278c.848.247 1.68.53 2.491.847 7.529 2.952 13.444 8.193 16.528 15.5z';

    const d3 =
      'M.006 36.144L.003 36 81.245 36c1.55 0 2.708.278 3.686.801.977.523 1.745 1.29 2.268 2.268.523.978.801 ' +
      '2.136.801 3.686v.49c0 1.55-.278 2.708-.801 3.686-.523.977-1.29 1.745-2.268 ' +
      '2.268-.978.523-2.136.801-3.686.801l-78.158.001C1.186 45.758.093 41.074.006 36.144zM32.755 16h56.49c1.55 0 ' +
      '2.708.278 3.686.801.977.523 1.745 1.29 2.268 2.268.523.978.801 2.136.801 3.686v.49c0 1.55-.278 2.708-.801 ' +
      '3.686-.523.977-1.29 1.745-2.268 2.268-.978.523-2.136.801-3.686.801h-56.49c-1.55 ' +
      '0-2.708-.278-3.686-.801-.977-.523-1.745-1.29-2.268-2.268-.523-.978-.801-2.136-.801-3.686v-.49c0-1.55.278-' +
      '2.708.801-3.686.523-.977 1.29-1.745 2.268-2.268.978-.523 2.136-.801 3.686-.801zM61.755 56h32.49c1.55 0 ' +
      '2.708.278 3.686.801.977.523 1.745 1.29 2.268 2.268.523.978.801 2.136.801 3.686v.49c0 1.55-.278 2.708-.801 ' +
      '3.686-.523.977-1.29 1.745-2.268 2.268-.978.523-2.136.801-3.686.801h-32.49c-1.55 ' +
      '0-2.708-.278-3.686-.801-.977-.523-1.745-1.29-2.268-2.268-.523-.978-.801-2.136-.801-3.686v-.49c0-1.55.278-' +
      '2.708.801-3.686.523-.977 1.29-1.745 2.268-2.268.978-.523 2.136-.801 3.686-.801z';

    const svgWidth = this.props.imageType === IMAGE_TYPES.NO_ALERTS ? 137 : 106;
    const svgHeight = this.props.imageType === IMAGE_TYPES.NO_ALERTS ? 108 : 84;
    const svgViewBox = this.props.imageType === IMAGE_TYPES.NO_ALERTS ? '0 0 137 108' : '0 0 106 84';

    if (this.props.imageType === IMAGE_TYPES.NO_TRIGGERED) {
      return (
        <div styleName="error-message-no-triggered">
          <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="101" height="71" viewBox="0 0 101 71">
              <g fill="none" fillRule="evenodd">
                <circle cx="35.5" cy="35.5" r="35.5" fill="#F4F7FB" />
                <path fill="#D4D9E0" d={d3} />
              </g>
            </svg>
          </div>
          <h3>{this.props.title}</h3>
          <h5>{this.props.subtitle}</h5>
        </div>
      );
    }

    return (
      <div styleName="error-message">
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width={svgWidth} height={svgHeight} viewBox={svgViewBox}>
            <path fill="#E9E9F0" fillRule="evenodd" d={this.props.imageType === IMAGE_TYPES.NO_ALERTS ? d1 : d2} />
          </svg>
        </div>
        <h3>{this.props.title}</h3>
        <h5>{this.props.subtitle}</h5>
      </div>
    );
  }
}
