import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

export default combineReducers({
  salesforceObjects: composeReducers(
    makeAsyncReducer(actions.fetchSalesforceObjects, {
      defaultData: EMPTY_ARRAY,
      errorHandler: bcErrorHandler,
      dataProp: 'items',
    }),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
  salesforceObjectData: composeReducers(
    makeAsyncReducer(actions.fetchSalesforceObjectData, {
      defaultData: EMPTY_ARRAY,
      errorHandler: bcErrorHandler,
      dataProp: 'items',
    }),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
  preview: composeReducers(
    makeAsyncReducer(actions.fetchSalesforceQueryPreview, {defaultData: EMPTY_OBJECT, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
  verifyQuery: composeReducers(
    makeAsyncReducer(actions.fetchSalesforceVerifyQuery, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
});
