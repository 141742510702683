import {createSelector} from 'reselect';
import {getTopologyMap} from 'common/store/selectors';
import {
  DEFAULT_FILTER_OPTIONS,
  ISSUE_SELECTED_FILTER_OPTIONS,
  SEARCH_ITEM_SELECTED_FILTER_OPTIONS,
} from 'topologyLeaflet/services/tpMapService';
import {PANEL_TABS} from 'topologyLeaflet/services/leftPanelService';
import {intersection, isEmpty, isString, uniq, differenceWith} from 'lodash';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const ISSUES_OPTIONS = [
  {
    label: 'Open Alerts',
    value: 'alerts',
  },
  {
    label: 'Open Anomalies',
    value: 'anomalies',
  },
];

// Data section
export const getTopologyMapDataSection = createSelector(
  getTopologyMap,
  (tpMap) => tpMap.data || EMPTY_OBJECT,
);

export const getTMFetchTopologyMap = createSelector(
  getTopologyMapDataSection,
  (tpData) => tpData.fetchTopologyMap || EMPTY_OBJECT,
);

export const getTMFetchTopologyMapIsLoading = createSelector(
  getTMFetchTopologyMap,
  (fetchTM) => fetchTM.isLoading,
);

export const getTMFetchTopologyMapData = createSelector(
  getTMFetchTopologyMap,
  (fetchTM) => fetchTM.data || EMPTY_OBJECT,
);

export const getTMFetchTopologyMapDataNodes = createSelector(
  getTMFetchTopologyMapData,
  (tmData) => tmData.nodes || EMPTY_ARRAY,
);

export const getTMFetchTopologyMapDataLinks = createSelector(
  getTMFetchTopologyMapData,
  (tmData) => tmData.links || EMPTY_ARRAY,
);

export const getTMFetchTopologyMapDataRings = createSelector(
  getTMFetchTopologyMapData,
  (tmData) => tmData.rings || EMPTY_ARRAY,
);

export const getTMFetchTopologyMapDataRingLinks = createSelector(
  getTMFetchTopologyMapData,
  (tmData) => tmData.ringLinks || EMPTY_ARRAY,
);

export const getTMFetchTopologyMapDataDate = createSelector(
  getTMFetchTopologyMapData,
  (tmData) => tmData.date || '',
);

export const getTMFetchTopologyAlerts = createSelector(
  getTopologyMapDataSection,
  (tpData) => tpData.fetchTopologyAlerts || EMPTY_OBJECT,
);

export const getTMFetchTopologyAlertsIsLoading = createSelector(
  getTMFetchTopologyAlerts,
  (fetchTM) => fetchTM.isLoading,
);

export const getTMFetchTopologyAlertsData = createSelector(
  getTMFetchTopologyAlerts,
  (fetchTM) => fetchTM.data,
);

export const getTMFetchTopologyAnomalies = createSelector(
  getTopologyMapDataSection,
  (tpData) => tpData.fetchTopologyAnomalies || EMPTY_OBJECT,
);

export const getTMFetchMetricDataPoints = createSelector(
  getTopologyMapDataSection,
  (tpData) => tpData.fetchMetricDataPoints || EMPTY_OBJECT,
);

export const getTMFetchTopologyAnomaliesIsLoading = createSelector(
  getTMFetchTopologyAnomalies,
  (fetchTM) => fetchTM.isLoading,
);

export const getTMFetchTopologyAnomaliesData = createSelector(
  getTMFetchTopologyAnomalies,
  (fetchTM) => fetchTM.data,
);

export const getTMFetchTopologyIssuesIsLoading = createSelector(
  getTMFetchTopologyAlertsIsLoading,
  getTMFetchTopologyAnomaliesIsLoading,
  (alertsIsLoading, anomaliesIsLoading) => alertsIsLoading || anomaliesIsLoading,
);

export const getTMFetchTopologyAlert = createSelector(
  getTopologyMapDataSection,
  (tpData) => tpData.fetchTopologyAlert || EMPTY_OBJECT,
);

export const getTMFetchTopologyAlertIsLoading = createSelector(
  getTMFetchTopologyAlert,
  (fetchTM) => fetchTM.isLoading,
);

export const getTMFetchTopologyAlertData = createSelector(
  getTMFetchTopologyAlert,
  (fetchTM) => fetchTM.data,
);

export const getTMFetchTopologyAlertCombinedAlertGroups = createSelector(
  getTMFetchTopologyAlertData,
  getTMFetchTopologyAlertsData,
  (alertData, alertsData) => {
    const alertGroups = [];
    if (alertData && alertData.alertGroups && alertData.alertGroups.length) {
      alertGroups.push(...alertData.alertGroups);
    }
    if (alertsData && alertsData.alertGroups && alertsData.alertGroups.length) {
      alertGroups.push(...alertsData.alertGroups);
    }
    return alertGroups;
  },
);

export const getTMFetchTopologyAnomaly = createSelector(
  getTopologyMapDataSection,
  (tpData) => tpData.fetchTopologyAnomaly || EMPTY_OBJECT,
);

export const getTMFetchTopologyAnomalyIsLoading = createSelector(
  getTMFetchTopologyAnomaly,
  (fetchTM) => fetchTM.isLoading,
);

export const getTMFetchTopologyAnomalyData = createSelector(
  getTMFetchTopologyAnomaly,
  (fetchTM) => fetchTM.data,
);

export const getTMFetchTopologyAnomaliesCombined = createSelector(
  getTMFetchTopologyAnomaliesData,
  getTMFetchTopologyAnomalyData,
  (anoData, ano) => {
    const anomalies = [];
    if (anoData.anomalies && anoData.anomalies.length) {
      anomalies.push(...anoData.anomalies);
    }
    if (!isEmpty(ano)) {
      anomalies.push({...ano});
    }
    return anomalies;
  },
);

export const getTMFetchTopologySpecialIssuesIsLoading = createSelector(
  getTMFetchTopologyAlertIsLoading,
  getTMFetchTopologyAnomalyIsLoading,
  (alertIsLoading, anomalyIsLoading) => alertIsLoading || anomalyIsLoading,
);

// Views section
export const getTopologyMapViewsSection = createSelector(
  getTopologyMap,
  (tpMap) => tpMap.views || EMPTY_OBJECT,
);

export const getTMLeftPanel = createSelector(
  getTopologyMapViewsSection,
  (tpViews) => tpViews.leftPanel,
);

export const getTMLeftPanelIssuesType = createSelector(
  getTMLeftPanel,
  (leftPanel) => leftPanel.issuesType,
);

export const getIssuesTypeOptions = createSelector(() => ISSUES_OPTIONS);

export const getSelectedIssuesTypeOption = createSelector(
  getIssuesTypeOptions,
  getTMLeftPanelIssuesType,
  (options, issuesType) => options.find((item) => item.value === issuesType),
);

export const getTMLeftPanelIssuesList = createSelector(
  getTMLeftPanel,
  (leftPanel) => leftPanel.issues || EMPTY_ARRAY,
);

export const getTMLeftPanelSearchQuery = createSelector(
  getTMLeftPanel,
  (leftPanel) => leftPanel.searchQuery,
);

export const getTMLeftPanelIssuesListFiltered = createSelector(
  getTMLeftPanelIssuesList,
  getTMLeftPanelSearchQuery,
  (issues, searchQuery) => {
    if (!searchQuery || searchQuery.length < 3 || !issues || !issues.length) {
      return {
        items: EMPTY_ARRAY,
        total: 0,
      };
    }

    const filteredIssues = [];
    issues.forEach((issue) => {
      if (issue.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        filteredIssues.push({...issue});
        return;
      }

      const isMetricProp = issue.metrics.some((metric) =>
        metric.properties.some((metProp) => {
          if (metProp.key === 'host' && metProp.value.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
            return true;
          }

          if (
            metProp.key === 'ospf_area' &&
            (metProp.value
              .replaceAll('_', '.')
              .toLowerCase()
              .indexOf(searchQuery) !== -1 ||
              metProp.value.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1)
          ) {
            return true;
          }
          return false;
        }),
      );

      if (isMetricProp) {
        filteredIssues.push({...issue});
      }
    });

    return {
      items: filteredIssues,
      total: filteredIssues.length,
    };
  },
);

export const getTMLeftPanelSelectedIssueId = createSelector(
  getTMLeftPanel,
  (leftPanel) => leftPanel.selectedIssueId,
);

export const getTMLeftPanelSpecialIssue = createSelector(
  getTMLeftPanel,
  (leftPanel) => leftPanel.specialIssue,
);

export const getTMLeftPanelSpecialIssueNotFound = createSelector(
  getTMFetchTopologyAlertIsLoading,
  getTMFetchTopologyAnomalyIsLoading,
  getTMFetchTopologyAlertData,
  getTMFetchTopologyAnomalyData,
  getTMLeftPanelSpecialIssue,
  (alertIsLoading, anomalyIsLoading, alertData, anomalyData, specialIssue) => {
    if (!specialIssue) {
      if (alertIsLoading === false && !isEmpty(alertData)) {
        return true;
      }
      if (anomalyIsLoading === false && !isEmpty(anomalyData)) {
        return true;
      }
    }
    return false;
  },
);

export const getTMLeftPanelSelectedIssue = createSelector(
  getTMLeftPanelIssuesList,
  getTMLeftPanelSpecialIssue,
  getTMLeftPanelSelectedIssueId,
  (issues, specialIssue, selectedId) => issues.find((issue) => issue.id === selectedId) || specialIssue || null,
);

export const getTMLeftPanelConstRange = createSelector(
  getTMLeftPanel,
  (leftPanel) => leftPanel.constRange,
);

export const getTMLeftPanelTabId = createSelector(
  getTMLeftPanel,
  (leftPanel) => leftPanel.tabId,
);

export const getTMLeftPanelAssets = createSelector(
  getTMLeftPanel,
  (leftPanel) => leftPanel.assets,
);

export const getTMLeftPanelSearchSelectedItemId = createSelector(
  getTMLeftPanel,
  (leftPanel) => leftPanel.searchSelectedItemId,
);

export const getTMLeftPanelSearchSelectedAsset = createSelector(
  getTMLeftPanelAssets,
  getTMLeftPanelSearchSelectedItemId,
  (assets, selectedAssetId) => assets.find((asset) => asset.id === selectedAssetId) || null,
);

export const getTMLeftPanelAssetsFiltered = createSelector(
  getTMLeftPanelAssets,
  getTMLeftPanelSearchQuery,
  getTMLeftPanelSearchSelectedItemId,
  (assets, searchQuery, searchSelectedItemId) => {
    if (!assets || !assets.length) {
      return {
        items: EMPTY_ARRAY,
        total: 0,
      };
    }

    const filteredAssets = [];
    const findSelectedItem = assets.find((asset) => asset.id === searchSelectedItemId);
    if (findSelectedItem) {
      filteredAssets.push(findSelectedItem);
    }
    if (!searchQuery || searchQuery.length < 3) {
      return {
        items: filteredAssets,
        total: filteredAssets.length,
      };
    }
    assets.forEach((asset) => {
      if (asset.id === searchSelectedItemId) {
        return;
      }
      if (asset.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
        filteredAssets.push({
          id: asset.id,
          name: asset.name,
          type: asset.type,
          styles: asset.styles,
        });
        return;
      }

      if (asset.extra && asset.extra.length) {
        asset.extra.forEach((extraProp) => {
          if (Array.isArray(extraProp.value)) {
            const fVal = extraProp.value.find((item) => item.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1);
            if (fVal) {
              filteredAssets.push({
                id: asset.id,
                name: asset.name,
                type: asset.type,
                styles: asset.styles,
                additional: {
                  label: extraProp.label,
                  value: fVal,
                },
              });
            }
          } else if (isString(extraProp.value)) {
            if (extraProp.value.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1) {
              filteredAssets.push({
                id: asset.id,
                name: asset.name,
                type: asset.type,
                styles: asset.styles,
                additional: {
                  label: extraProp.label,
                  value: extraProp.value,
                },
              });
            }
          }
        });
      }
    });

    return {
      items: filteredAssets.slice(0, 50),
      total: filteredAssets.length,
    };
  },
);

export const getTMLeftPanelSearchResultIsEmpty = createSelector(
  getTMLeftPanelIssuesListFiltered,
  getTMLeftPanelAssetsFiltered,
  (filteredIssuesList, filteredAssetsList) => !filteredIssuesList.total && !filteredAssetsList.total,
);

const fillResWithMetricHost = (res, metrics, nodes) => {
  metrics.forEach((metric) => {
    const hostFound = metric.properties.find((prop) => prop.key === 'host');
    const interfaceFound = metric.properties.find((prop) => prop.key === 'interface');
    const ospfAreaFound = metric.properties.find((prop) => prop.key === 'ospf_area');
    const isNodeMetric = ['system_cpu', 'system_memory', 'system_temperature'].some(
      (item) => metric.what.toLowerCase().indexOf(item) !== -1,
    );
    if (hostFound) {
      const resItem = {
        host: hostFound.value,
        interface: interfaceFound ? interfaceFound.value : null,
        ospfArea: ospfAreaFound ? ospfAreaFound.value.replace(/[_]/gi, '.') : null,
        isNodeMetric,
      };

      // finding the ospfArea from the connectedHost via interface of the ABR - if ospfArea prop is missing
      if (resItem.host.toLowerCase().indexOf('abr') !== -1 && !resItem.ospfArea && resItem.interface) {
        let foundNode = null;
        nodes.some((node) => {
          if (node.namesList && node.namesList.indexOf(resItem.host) !== -1) {
            foundNode = node.nodes.find((n) => n.hostName === resItem.host);
            if (foundNode) {
              return true;
            }
          } else if (node.hostName === resItem.host) {
            foundNode = node;
            return true;
          }
          return false;
        });

        if (foundNode) {
          const findInterface = foundNode.interfaces.find((inter) => inter.interface === resItem.interface);
          if (findInterface) {
            let connectedNode = null;
            nodes.some((node) => {
              if (node.namesList && node.namesList.indexOf(findInterface.connectedHost) !== -1) {
                connectedNode = node.nodes.find((n) => n.hostName === findInterface.connectedHost);
                if (connectedNode) {
                  return true;
                }
              } else if (node.hostName === findInterface.connectedHost) {
                connectedNode = node;
                return true;
              }
              return false;
            });

            if (connectedNode) {
              // eslint-disable-next-line prefer-destructuring
              resItem.ospfArea = connectedNode.ringOspfAreaList[0];
            }
          }
        }
      }

      res.push(resItem);
    }
  });
};

export const getSelectedIssuesAnomalousHostsList = createSelector(
  getTMFetchTopologyMapDataNodes,
  getTMFetchTopologyAlertCombinedAlertGroups,
  getTMFetchTopologyAnomaliesCombined,
  getTMLeftPanelIssuesType,
  getTMLeftPanelSelectedIssueId,
  (nodes, alertGroups, anomalies, issueType, issueId) => {
    if (
      !nodes.length ||
      (issueType === 'anomalies' && (!anomalies || !anomalies.length)) ||
      (issueType === 'alerts' && (!alertGroups || !alertGroups.length))
    ) {
      return EMPTY_ARRAY;
    }
    const res = [];

    if (issueId) {
      if (issueType === 'anomalies') {
        const findSelectedAnomaly = anomalies.find((anomaly) => anomaly.id === issueId);
        if (findSelectedAnomaly) {
          fillResWithMetricHost(res, findSelectedAnomaly.metrics, nodes);
        }
      }

      if (issueType === 'alerts') {
        const findSelectedAlertGroup = alertGroups.find((alertGroup) => alertGroup.alerts[0].id === issueId);
        if (findSelectedAlertGroup) {
          findSelectedAlertGroup.alerts.forEach((alert) => {
            fillResWithMetricHost(res, alert.metrics, nodes);
          });
        }
      }
    } else {
      if (issueType === 'anomalies') {
        anomalies.forEach((anomaly) => {
          fillResWithMetricHost(res, anomaly.metrics, nodes);
        });
      }

      if (issueType === 'alerts') {
        alertGroups.forEach((alertGroup) => {
          alertGroup.alerts.forEach((alert) => {
            fillResWithMetricHost(res, alert.metrics, nodes);
          });
        });
      }
    }

    return res;
  },
);

export const getSelectedIssuesAnomalousRingsList = createSelector(
  getTMFetchTopologyMapDataNodes,
  getSelectedIssuesAnomalousHostsList,
  (nodes, anomalousHostsList) => {
    if (!nodes.length || !anomalousHostsList.length) {
      return [];
    }

    const anomalousHostNameList = anomalousHostsList.map((item) => item.host);

    const anomalousNodeList = [];
    const anomalousRingsNamesList = [];

    nodes.forEach((node) => {
      if (node.namesList) {
        const anomalousNameList = intersection(node.namesList, anomalousHostNameList);
        if (anomalousNameList.length !== 0) {
          anomalousNameList.forEach((anomalousName) => {
            const findNode = node.nodes.find((n) => n.name === anomalousName);
            if (findNode && findNode.ringOspfAreaList) {
              anomalousNodeList.push({...findNode});
            }
          });
        }
      } else if (anomalousHostNameList.indexOf(node.name) !== -1) {
        if (node.ringOspfAreaList) {
          anomalousNodeList.push({...node});
        }
      }
    });

    anomalousNodeList.forEach((anoNode) => {
      if (anoNode.type === 'ABR') {
        const anoAbrOspfAreas = anomalousHostsList
          .filter((item) => item.host === anoNode.hostName && item.ospfArea)
          .map((item) => item.ospfArea);
        const intersectOfAbrOspfAreas = intersection(anoNode.ringOspfAreaList, anoAbrOspfAreas);
        anomalousRingsNamesList.push(...intersectOfAbrOspfAreas);
      } else {
        anomalousRingsNamesList.push(...anoNode.ringOspfAreaList);
      }
    });

    return uniq(anomalousRingsNamesList);
  },
);

const processInterfaces = (interfaces, anoInterfaces) => {
  const localInterfaces = [];
  const processedInter = interfaces.map((inter) => {
    localInterfaces.push(inter.interface);
    const isAno = anoInterfaces.some((anoIn) => inter.interface.toLowerCase().indexOf(anoIn.toLowerCase()) !== -1);
    return isAno ? {...inter, isAnomalous: true} : {...inter};
  });
  const unknownAnomalousInterfaces = differenceWith(
    anoInterfaces,
    localInterfaces,
    (arrVal, othVal) => othVal.toLowerCase().indexOf(arrVal.toLowerCase()) !== -1,
  );
  unknownAnomalousInterfaces.forEach((unknownInter) => {
    processedInter.push({
      interface: unknownInter,
      isUnknownAno: true,
    });
  });

  return processedInter;
};

export const getProcessedNodes = createSelector(
  getTMFetchTopologyMapDataNodes,
  getSelectedIssuesAnomalousHostsList,
  getSelectedIssuesAnomalousRingsList,
  getTMLeftPanelTabId,
  getTMLeftPanelSearchSelectedAsset,
  getTMLeftPanelSelectedIssue,
  (nodes, anomalousHostsList, anomalousRingsList, tabId, selectedSearchItem, selectedIssue) => {
    if (!nodes.length) {
      return nodes;
    }

    const processedNodes = [];
    const anomalousHostNamesList = anomalousHostsList.map((item) => item.host);

    nodes.forEach((node) => {
      if (node.nodes) {
        const anoNodes = [];
        const otherNodes = [];
        node.nodes.forEach((n) => {
          if (anomalousHostNamesList.indexOf(n.name) !== -1) {
            const anomalousInterfaces = anomalousHostsList
              .filter((i) => i.host === n.hostName && i.interface)
              .map((i) => i.interface);
            const anomalousOspfArea = anomalousHostsList
              .filter((i) => i.host === n.hostName && i.ospfArea)
              .map((i) => i.ospfArea);
            const isNodeMetric = anomalousHostsList.filter((i) => i.host === n.hostName && i.isNodeMetric).length !== 0;
            const anoNode = {...n, isAnomalous: true, isNodeMetric};

            if (anomalousInterfaces && anomalousInterfaces.length) {
              anoNode.interfaces = processInterfaces(n.interfaces, anomalousInterfaces);
            }

            if (anomalousOspfArea && anomalousOspfArea.length) {
              anoNode.anomalousOspfArea = anomalousOspfArea;
            }

            anoNodes.push(anoNode);
          } else if (selectedIssue) {
            if (
              n.ringOspfAreaList &&
              n.ringOspfAreaList.length &&
              intersection(n.ringOspfAreaList, anomalousRingsList).length !== 0
            ) {
              otherNodes.push({...n});
            }
          } else {
            otherNodes.push({...n});
          }
        });

        if (anoNodes.length === 1) {
          processedNodes.push({
            ...node,
            isAnomalous: true,
            anomalousName: anoNodes[0].name,
            anomalousType: anoNodes[0].type,
            anomalousNodes: anoNodes,
            nodes: [...anoNodes, ...otherNodes],
            namesList: [...anoNodes, ...otherNodes].map((p) => p.name),
            ringOspfAreaList: anoNodes[0].ringOspfAreaList,
          });
        } else if (anoNodes.length > 1) {
          let anomalousType = 'CLUSTER';
          const ringOspfAreaList = [];
          anoNodes.forEach((n) => {
            if (n.type === 'ABR') {
              anomalousType = 'ABR';
            }
            if (n.type !== 'ABR' && n.ringOspfAreaList) {
              ringOspfAreaList.push(...n.ringOspfAreaList);
            }
          });
          processedNodes.push({
            ...node,
            isAnomalous: true,
            anomalousNameList: anoNodes.map((n) => n.name),
            anomalousNodes: anoNodes,
            nodes: [...anoNodes, ...otherNodes],
            namesList: [...anoNodes, ...otherNodes].map((p) => p.name),
            anomalousType,
            ringOspfAreaList,
          });
        } else if (selectedIssue) {
          if (otherNodes.length === 1) {
            processedNodes.push(otherNodes[0]);
          } else if (otherNodes.length > 1) {
            processedNodes.push({...node, nodes: otherNodes, namesList: otherNodes.map((p) => p.name)});
          }
        } else {
          processedNodes.push({...node, nodes: otherNodes, namesList: otherNodes.map((p) => p.name)});
        }
      } else if (anomalousHostNamesList.indexOf(node.name) !== -1) {
        const anomalousInterfaces = anomalousHostsList
          .filter((i) => i.host === node.hostName && i.interface)
          .map((i) => i.interface);
        const isNodeMetric = anomalousHostsList.filter((i) => i.host === node.hostName && i.isNodeMetric).length !== 0;
        if (anomalousInterfaces && anomalousInterfaces.length) {
          const interfaces = processInterfaces(node.interfaces, anomalousInterfaces);
          processedNodes.push({
            ...node,
            isAnomalous: true,
            isNodeMetric,
            anomalousName: node.name,
            anomalousType: node.type,
            interfaces,
            ringOspfAreaList: node.ringOspfAreaList,
          });
        } else {
          processedNodes.push({
            ...node,
            isAnomalous: true,
            isNodeMetric,
            anomalousName: node.name,
            anomalousType: node.type,
            ringOspfAreaList: node.ringOspfAreaList,
          });
        }
      } else if (selectedIssue) {
        if (
          node.ringOspfAreaList &&
          node.ringOspfAreaList.length &&
          intersection(node.ringOspfAreaList, anomalousRingsList).length !== 0
        ) {
          processedNodes.push({...node});
        }
      } else {
        processedNodes.push({...node});
      }
    });

    if (
      (tabId === PANEL_TABS.issues.id && !selectedIssue) ||
      (tabId === PANEL_TABS.search.id && !selectedIssue && !selectedSearchItem)
    ) {
      const processedNodes0 = [];

      processedNodes.forEach((n) => {
        if (n.highOrderType === 'ABR') {
          const filteredNodes = n.nodes.filter((nn) => nn.type === 'ABR');
          const filteredAnomalousNodes = (n.anomalousNodes || []).filter((nn) => nn.type === 'ABR');
          if (filteredAnomalousNodes.length) {
            processedNodes0.push({
              ...n,
              nodes: filteredNodes,
              namesList: filteredNodes.map((fn) => fn.name),
              anomalousNameList: filteredAnomalousNodes.map((fAn) => fAn.name),
              anomalousNodes: filteredAnomalousNodes,
            });
          } else {
            processedNodes0.push({
              ...n,
              nodes: filteredNodes,
              namesList: filteredNodes.map((fn) => fn.name),
              anomalousNameList: undefined,
              anomalousNodes: undefined,
              isAnomalous: false,
            });
          }
        } else {
          processedNodes0.push({...n});
        }
      });

      return processedNodes0;
    }

    return processedNodes;
  },
);

export const getProcessedLinks = createSelector(
  getTMFetchTopologyMapDataLinks,
  getSelectedIssuesAnomalousHostsList,
  (links, anomalousHostsList) => {
    if (!links.length || !anomalousHostsList.length) {
      return links;
    }

    const processedLinks = [];

    links.forEach((link) => {
      const findAnomalousLink = anomalousHostsList.find((item) => {
        if (
          item.host === link.to &&
          (item.interface && link.toInterface.toLowerCase().indexOf(item.interface.toLowerCase()) !== -1)
        ) {
          return true;
        }
        if (
          item.host === link.from &&
          (item.interface && link.fromInterface.toLowerCase().indexOf(item.interface.toLowerCase()) !== -1)
        ) {
          return true;
        }
        return false;
      });
      if (findAnomalousLink) {
        processedLinks.push({...link, isAnomalous: true});
      } else {
        processedLinks.push({...link});
      }
    });

    return processedLinks;
  },
);

export const getProcessedRings = createSelector(
  getTMFetchTopologyMapDataRings,
  getSelectedIssuesAnomalousRingsList,
  (rings, anomalousRingsList) => {
    if (!rings.length || !anomalousRingsList.length) {
      return rings;
    }
    const processedRings = [];

    rings.forEach((ring) => {
      if (ring.rings) {
        const anomalousRingNamesList = intersection(ring.namesList, anomalousRingsList);
        if (anomalousRingNamesList.length === 1) {
          const findRing = ring.rings.find((r) => r.name === anomalousRingNamesList[0]);
          processedRings.push({
            ...ring,
            isAnomalous: true,
            anomalousName: findRing.name,
          });
        }
        if (anomalousRingNamesList.length > 1) {
          const anomalousRings = ring.rings.filter((r) => anomalousRingNamesList.indexOf(r.name) !== -1);
          processedRings.push({
            ...ring,
            isAnomalous: true,
            anomalousNameList: anomalousRingNamesList,
            anomalousRings,
          });
        } else {
          processedRings.push({...ring});
        }
      } else if (anomalousRingsList.indexOf(ring.name) !== -1) {
        processedRings.push({
          ...ring,
          isAnomalous: true,
          anomalousName: ring.name,
        });
      } else {
        processedRings.push({...ring});
      }
    });

    return processedRings;
  },
);

export const getProcessedRingLinks = createSelector(
  getTMFetchTopologyMapDataRingLinks,
  getSelectedIssuesAnomalousRingsList,
  (ringLinks, anomalousRingsList) => {
    if (!ringLinks.length || !anomalousRingsList.length) {
      return ringLinks;
    }

    const processedLinks = [];

    ringLinks.forEach((link) => {
      if (anomalousRingsList.indexOf(link.to) !== -1) {
        processedLinks.push({...link, isAnomalous: true});
      } else {
        processedLinks.push({...link});
      }
    });

    return processedLinks;
  },
);

export const getTMFilterOptions = createSelector(
  getTMLeftPanelTabId,
  getTMLeftPanelSelectedIssueId,
  (tabId, issueId) => {
    if (issueId) {
      return ISSUE_SELECTED_FILTER_OPTIONS;
    }

    if (tabId === PANEL_TABS.search.id) {
      return SEARCH_ITEM_SELECTED_FILTER_OPTIONS;
    }

    return DEFAULT_FILTER_OPTIONS;
  },
);
