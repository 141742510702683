import React from 'react';
import {Box} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {ReactComponent as EmptyIcon} from '../../images/empty_noowner.svg';

const EmptyDashboard = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" mt={20}>
      <EmptyIcon width={205} height={122} />
      <TypographyBox variant="subtitle2" fontWeight="bold" fontSize={20} mt={1.5}>
        This Dashboard is Empty
      </TypographyBox>
    </Box>
  );
};

export default EmptyDashboard;
