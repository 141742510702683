import React, {useState} from 'react';
import {get} from 'lodash';
import {useSelector} from 'react-redux';
import {Backdrop, Box, Modal} from '@material-ui/core';
import Button, {COLORS} from 'common/componentsV2/Button';
import Input from 'common/componentsV2/Input';
import * as metricsSelectors from 'metrics/store/selectors';

const CompositeNameModal = ({
  isOpened,
  onClose,
  onConfirm,
  error,
  initialName,
}: {
  isOpened: boolean,
  onClose: Function,
  onConfirm: Function,
  error: string,
  initialName: string,
}) => {
  const [name, setName] = useState(initialName);

  const isErrorTitle = error === 'Metric title already exists.';
  const expressionTree = useSelector(metricsSelectors.getExpressionTree);
  const compositesList = useSelector(metricsSelectors.getComposites);

  const isCompositeNameTaken =
    name !== initialName &&
    (compositesList || []).some(
      (item) => get(item, 'composite.name.prefix') === `what=${name}.func=${expressionTree.root.function}`,
    );

  return (
    <Modal BackdropComponent={Backdrop} open={isOpened} onClose={onClose} className="display_flex">
      <Box
        automation-id="compositeNameModal"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        width={378}
        bgcolor="white.500"
        borderRadius={4}
        css={{boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)'}}
        margin="auto"
        p={3}
        pb={2}
      >
        <div>
          <div className="text16med lineHeight_16 mb_1">Save composite</div>
          <div>What are you measuring (what=)</div>
          <Input value={name} onChange={(event) => setName(event.target.value)} />
          {(isCompositeNameTaken || isErrorTitle) && (
            <Box mt={0.5} color="red.500" fontSize={14} fontWeight="bold">
              Composite with that name already exists
            </Box>
          )}
          {error && (
            <Box mt={0.5} color="red.500" fontSize={14} fontWeight="bold">
              {error}
            </Box>
          )}
        </div>
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button colorSchema={COLORS.GRAY_300} text="Cancel" onClick={onClose} />
          <Button
            colorSchema={COLORS.BLUE_500}
            isDisabled={!name || isCompositeNameTaken || isErrorTitle}
            onClick={() => onConfirm(name)}
            text="OK"
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default CompositeNameModal;
