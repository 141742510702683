import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const initialState = {
  listOrder: {column: 'name', direction: 'asc'},
  customerStatusModal: {
    isOpen: false,
    customerId: null,
    action: null,
  },
  deleteModal: {
    isOpen: false,
    customerId: null,
  },
};

const customersListReducer = composeReducers((state = {...initialState}, {type, payload}) => {
  switch (type) {
    case actions.setCustomersListOrder.TYPE: {
      return {
        ...state,
        listOrder: {...payload},
      };
    }
    case actions.createCustomer.success.TYPE:
    case actions.updateCustomer.success.TYPE:
      return {
        ...state,
        // Since update status is done as update action, need to make sure this modal is closed
        customerStatusModal: {
          isOpen: false,
          customerId: null,
          action: null,
        },
      };
    case actions.toggleCustomerChangeStatusConfirmationModal.TYPE: {
      return {
        ...state,
        customerStatusModal: {
          isOpen: payload.isOpen,
          customerId: payload.customerId || null,
          action: payload.action || null,
        },
      };
    }
    case actions.toggleCustomerDeleteConfirmationModal.TYPE: {
      return {
        ...state,
        deleteModal: {
          isOpen: payload.isOpen,
          customerId: payload.customerId || null,
        },
      };
    }
    case actions.deleteCustomer.success.TYPE: {
      return {
        ...state,
        deleteModal: {
          isOpen: false,
          customerId: null,
        },
      };
    }
    default:
      return state;
  }
});

export default customersListReducer;
