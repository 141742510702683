// @flow
import React from 'react';
import calcWidthInPer from 'common/componentsV2/accordion/widthCalc';

import './HeaderRow.module.scss';

type PropTypes = {
  titles: Array,
  leftAlignLastItem?: Boolean,
};

export default class HeaderRow extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    leftAlignLastItem: false,
  };

  render() {
    const {titles, leftAlignLastItem} = this.props;

    const style = {
      item: {
        width: calcWidthInPer(titles.length),
      },
    };

    const titleStyle = `titles${leftAlignLastItem ? ' left' : ''}`;

    return (
      <div styleName="container">
        <div styleName={titleStyle}>
          {titles.map((i) => (
            <span className="ellipsis" style={style.item} key={i}>
              {i}
            </span>
          ))}
        </div>
      </div>
    );
  }
}
