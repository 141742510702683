// @flow
import React from 'react';
import Loader from 'common/components/Loader';
import PopoverListItem from './PopoverListItem';
import './FilteredItemsList.module.scss';

const renderSubstring = (text, index, length) => (
  <span>
    <span>{text.substring(0, index)}</span>
    <span styleName="highlight">{text.substr(index, length)}</span>
    <span>{text.substring(index + length)}</span>
  </span>
);

const lowercaseIndexOf = (text, search) => text.toLowerCase().indexOf(search.toLowerCase());
const renderFilter = (text, filter) => renderSubstring(text, lowercaseIndexOf(text, filter), filter.length);

const FilteredConcepts = ({filter, maxSelections, concepts, isLoading, onAddItem, onRemoveItem}: any) => {
  // eslint-disable-next-line no-param-reassign,no-return-assign
  const selectedConceptsNumber = concepts.reduce((total, concept) => (total += concept.selected), 0);
  let canSelectMore = selectedConceptsNumber < maxSelections;

  const middleWareOnAddItem = (id) => {
    if (selectedConceptsNumber >= maxSelections) {
      canSelectMore = false;
      return;
    }
    canSelectMore = true;
    onAddItem(id);
  };
  return (
    <div styleName="filtered-concepts">
      {isLoading && <Loader size="small" />}

      {!isLoading &&
        concepts
          .filter(({uiName}) => filter.length === 0 || lowercaseIndexOf(uiName, filter) >= 0)
          .sort((a, b) => a.uiName.localeCompare(b.uiName))
          .map(({id, uiName, selected, disabled, description}) => (
            <PopoverListItem
              key={id}
              {...{
                id,
                uiName,
                selected,
                canSelectMore,
                disabled,
                description,
                middleWareOnAddItem,
                onRemoveItem,
              }}
            >
              <span styleName="item-name" className="ellipsis">
                {disabled ? uiName : renderFilter(uiName, filter)}
              </span>
            </PopoverListItem>
          ))}
    </div>
  );
};

export default FilteredConcepts;
