/* eslint-disable */
export function throttle(callback, limit) {
  let wait = false;
  return function() {
    if (!wait) {
      callback.apply(null, arguments);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, limit);
    }
  };
}

export const postLogReactError = (error, errorInfo) => {
  const url = 'api/v1/logger/react-errors';
  const token = window.localStorage['andt-token'];
  const request = new Request(url, {
    method: 'POST',
    body: JSON.stringify({
      error: error.toString(),
      errorInfo: errorInfo.componentStack,
    }),
    headers: {
      Accept: 'application/json, text/plain, */*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  return (
    fetch(request)
      .then((res) => res.json())
      // .then((data) => {})
      .catch((err) => {})
  );
};
/* eslint-enable */
