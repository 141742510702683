// @flow
import React from 'react';
import SwitchButton from 'common/componentsV2/SwitchButton';
import {noop} from 'lodash';
import './CreateBtnPopover.module.scss';

type PropTypes = {
  className?: string,
  ratioToggle?: Function,
  addRecipientsClick?: Function,
  createAnotherClick?: Function,
  isRatio: Boolean,
  createAnotherDisable: Boolean,
  internalClick: Function,
};

export default class CreateBtnPopover extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    className: '',
    ratioToggle: noop,
    addRecipientsClick: noop,
    createAnotherClick: noop,
  };

  onRatioOptionClick = () => {
    this.props.ratioToggle(!this.props.isRatio);
    this.props.internalClick();
  };

  onAddRecipientsClick = () => {
    this.props.addRecipientsClick();
    this.props.internalClick();
  };

  onCreateAnotherClick = () => {
    if (this.props.createAnotherDisable) {
      return;
    }
    this.props.createAnotherClick();
    this.props.internalClick();
  };

  render() {
    return (
      <div className={this.props.className} styleName="root">
        <div styleName="item" role="presentation" onClick={this.onRatioOptionClick}>
          <div styleName="item-inner">
            <i className="icon icn-general16-direction" />
            <span styleName="item-label">Ratio Alert</span>
            <span styleName="toggle-wrapper">
              <SwitchButton isChecked={this.props.isRatio} automationId="ratioAlert" />
            </span>
          </div>
        </div>
        <div styleName="item" role="presentation" onClick={this.onAddRecipientsClick}>
          <div styleName="item-inner">
            <i className="icon icn-action16-plusb" />
            Add Recipients
          </div>
        </div>
        <div styleName="item" role="presentation" onClick={this.onCreateAnotherClick}>
          <div styleName={['item-inner', this.props.createAnotherDisable ? 'disable' : ''].join(' ').trim()}>
            <i className="icon icn-action16-copy" />
            Create Another
          </div>
        </div>
      </div>
    );
  }
}
