// @flow
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Backdrop, Modal, makeStyles} from '@material-ui/core';
import {useSelector} from 'react-redux';
import AsyncButton from 'common/componentsV2/AsyncButton';
import * as actions from 'dashboards/store/actions';
import useAsyncAction from 'common/utils/useAsyncAction';
import {getDeleteDashboard} from 'dashboards/store/selectors';
import useDashboardState from 'dashboards/hooks/useDashboardState';

type PropsType = {
  onClose: Function,
  dashboardId: string,
};

const useStyles = makeStyles(({palette}) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    width: 420,
    height: 'fit-content',
    backgroundColor: palette.white[500],
    borderRadius: 7,
    boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.3)',
    margin: 'auto',
    padding: '16px 24px',
  },
  title: {
    fontSize: '16px',
    fontWeight: 500,
    marginBottom: '8px',
  },
  text: {
    fontSize: '14px',
    marginBottom: '40px',
  },
}));

const RevertModal = (props: PropsType) => {
  const classes = useStyles();
  const history = useHistory();
  const dashboard = useDashboardState(props.dashboardId);
  const getDeleteDashboardSelector = useSelector(getDeleteDashboard);
  const deleteDashboard = useAsyncAction(actions.deleteDashboard, getDeleteDashboardSelector);

  const handleConvert = () => {
    deleteDashboard(null, {dashboardId: props.dashboardId})
      .then(() => {
        history.replace('/dashboards');
        setTimeout(() => {
          window.location = `${window.location.origin}/#!/dashboards?activeTab=1&tabs=main;${dashboard.data.v1ID}`;
        }, 0);
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));
  };

  return (
    <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={props.onClose}>
      <div className={classes.wrapper}>
        <div className={classes.title}>Revert to old dashboard</div>
        <div className={classes.text}>
          You can revert to the old dashboard, this version of the dashboard will be deleted.
          <br />
          <br />
          You can convert the dashboard back to the new version at any time.
        </div>
        <div className="display_flex justifyContent_space-between alignItems_center alignSelf_flex-end">
          <div className="mr_1-5">
            <AsyncButton
              automationId="revertToOldVersion"
              text="Revert to Old Version"
              colorSchema="gray.300"
              isLoading={getDeleteDashboardSelector.isLoading}
              onClick={handleConvert}
            />
          </div>
          <AsyncButton automationId="keepNewVersion" text="Keep New Version" onClick={props.onClose} />
        </div>
      </div>
    </Modal>
  );
};

export default RevertModal;
