import {useEffect, useMemo, useRef} from 'react';
import {identity, pickBy, some} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';

const useAsyncAction = (actionCreator, result, validationCallback, cancelOnUnmount) => {
  const dispatch = useDispatch();
  const selector = useMemo(() => (typeof result === 'function' ? result : () => result), [result]);
  const response = useSelector(selector);
  const actionDeferred = useRef();

  useEffect(() => {
    if (response.isLoading === false && actionDeferred.current) {
      const errors = validationCallback ? validationCallback(response) : {};
      if (!some(errors, identity)) {
        actionDeferred.current.resolve();
      } else {
        actionDeferred.current.resolve(pickBy(errors, identity));
      }
    }
  }, [response.isLoading]);

  useEffect(
    () => () => {
      if (cancelOnUnmount && actionDeferred.current) {
        actionDeferred.current.resolve({isCanceled: true});
      }
    },
    [],
  );

  return (...args) =>
    new Promise((resolve, reject) => {
      dispatch(actionCreator(...args));
      actionDeferred.current = {resolve, reject};
    });
};

export default useAsyncAction;
