import {makeAsyncAction} from 'common/utils/simplifiedAsync';

const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/user.settings/');

export const updatePasswordRequest = macAsync('UPDATE_PASSWORD_REQUEST');
export const updateSSO = macAsync('UPDATE_SSO');
export const verifyDomains = macAsync('VERIFY_DOMAINS');
export const fetchRefreshTokens = macAsync('FETCH_REFRESH_TOKENS');
export const refreshTokens = macAsync('REFRESH_TOKENS');
export const revokeToken = macAsync('REVOKE_TOKENS');
