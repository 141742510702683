// @flow
import React from 'react';
import Button, {COLORS as BUTTON_COLORS, WIDTHS as BITTON_WIDTHS} from 'common/componentsV2/Button';
import Loader from 'common/components/Loader';
import './Templates.module.scss';

type PropTypes = {
  isLoading: boolean,
  items: Array,
  selectedItem: String,
  onChange: Function,
};

export default class Templates extends React.PureComponent {
  props: PropTypes;

  render() {
    const {isLoading, items, selectedItem, onChange} = this.props;

    return (
      <div styleName="root">
        {isLoading !== false && <Loader />}

        {items.map((item) => (
          <div key={item.id} styleName="button-wrap">
            <div>
              <Button
                colorSchema={selectedItem && selectedItem === item.id ? BUTTON_COLORS.BLUE_500 : BUTTON_COLORS.GRAY_200}
                width={BITTON_WIDTHS.REGULAR}
                height="tall"
                text={item.name}
                onClick={() => onChange(item.id)}
              />
            </div>
          </div>
        ))}
      </div>
    );
  }
}
