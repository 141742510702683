import {combineEpics} from 'redux-observable';
import simulationFiltersEpic from './simulationFiltersEpic';
import influencingMetricsInfoEpic from './influencingMetricsInfoEpic';
import alertsConfigurationsEpic from './alertsConfigurationsEpic';
import simulationEpic from './simulationEpic';

export default combineEpics(
  simulationFiltersEpic,
  influencingMetricsInfoEpic,
  alertsConfigurationsEpic,
  simulationEpic,
);
