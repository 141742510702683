// @flow

import React, {PureComponent} from 'react';

import PopoverButton from './PopoverButton';
import PopoverItems from './PopoverItems';

import FilteredItemsList from './FilteredItemsList';

import './PopoverContainer.module.scss';

const PlusIcon = () => (
  <div styleName="plus">
    <i className="icon icn-icon-white-plus" />
  </div>
);

type PropTypes = {
  title: string,
  subtitle: string,
  maxSelections: number,
  theme: string,
  concepts: Array<Object>,
  isLoading: boolean,
  isDisabled: boolean,
  isHidePanelTitle: boolean,
  onAddItem: Function,
  onRemoveItem: Function,
};

class PopoverContainer extends PureComponent {
  props: PropTypes;

  state = {
    alignWith: null,
    filter: '',
  };

  componentDidMount() {
    this.setState({alignWith: this.self});
  }

  setFilter = (filter) => this.setState({filter});

  render() {
    const {
      title,
      subtitle,
      maxSelections,
      concepts,
      isLoading,
      isDisabled,
      onAddItem,
      onRemoveItem,
      theme,
      isHidePanelTitle,
    } = this.props;
    const {alignWith, filter} = this.state;
    const {setFilter} = this;
    const isLightTheme = theme === 'light';

    const popoverBtnClassName = isLightTheme ? '' : 'btn-blue-light';
    const rootStyleName = isLightTheme ? ' light' : '';

    return (
      <header
        styleName={`header ${rootStyleName}`}
        ref={(self) => {
          this.self = self;
        }}
      >
        <div styleName="title-block">
          <h4 styleName={subtitle ? 'with-subtitle' : ''}>{title}</h4>
          {subtitle && <small>{subtitle}</small>}
        </div>
        {alignWith && (
          <PopoverButton
            alignwith={alignWith}
            minWwdth={350}
            widthfactor={0.5}
            disabled={isDisabled}
            onClose={() => {
              setFilter('');
            }}
            popover={
              <PopoverItems styleName="popover" placement="bottom" filter={filter} onFilter={setFilter}>
                <div styleName="panel-titles">{isHidePanelTitle ? '' : <div>{title}</div>}</div>

                <div styleName="panel-popover">
                  <FilteredItemsList
                    {...{
                      filter,
                      concepts,
                      maxSelections,
                      isLoading,
                      onAddItem,
                      onRemoveItem,
                    }}
                  />
                </div>
              </PopoverItems>
            }
            title={<PlusIcon />}
            className={`btn btn-flat btn-icon ${popoverBtnClassName}`}
          />
        )}
      </header>
    );
  }
}

export default PopoverContainer;
