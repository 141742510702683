// @flow
import React from 'react';
import './StringParameterInput.module.scss';
import {FunctionParameters} from 'anodot-objects-models';
import {Subject} from 'rxjs/Subject';

type PropTypes = {
  functionParameter: FunctionParameters,
  onValueChange: Function, // f = (value, isValid, [validationErrors]) => {...}
  value: string,
  onTouched: Function,
  isForceFocusIfAvailable: boolean,
};

export default class StringParameterInput extends React.PureComponent {
  props: PropTypes;

  state = {
    isError: false,
    isPristine: true,
    immediateValue: '',
  };

  componentDidMount() {
    this.setState({immediateValue: this.props.value || ''});
    this.inputSubject = new Subject()
      .debounceTime(300)
      .distinctUntilChanged((x, y) => x === y)
      .map((x) => this.onChange(x));

    this.inputSubjectSubscription = this.inputSubject.subscribe();
    setTimeout(() => {
      this.isValid = this.props.functionParameter.validateValue(this.props.value);
      this.inputSubject.next(this.props.value);
    }, 0);
  }

  componentWillUnmount() {
    this.inputSubjectSubscription.unsubscribe();
  }

  handleTyping = (e) => {
    const {value} = e.target;
    this.isValid = this.props.functionParameter.validateValue(value);
    this.setState({immediateValue: value || ''});
    this.inputSubject.next(value);
  };

  onChange = (val) => {
    this.props.onValueChange(val, this.isValid.isValid, this.isValid.validationErrors);
  };

  handleBlur = () => {
    const value = this.props.value ? this.props.value : '';
    this.setState({isPristine: false});
    const isValid = this.props.functionParameter.validateValue(value);
    this.setState({isError: !isValid.isValid});
    this.props.onTouched();
  };

  render() {
    return (
      <div styleName="render-string">
        <input
          styleName={['value-input', this.state.isError ? 'error' : '', this.state.isPristine ? 'pristine' : ''].join(
            ' ',
          )}
          onChange={this.handleTyping}
          value={this.state.immediateValue}
          placeholder={this.props.functionParameter.name}
          type="text"
          onBlur={this.handleBlur}
          autoFocus={this.props.isForceFocusIfAvailable}
        />
      </div>
    );
  }
}
