// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import {getSelectedFilterType} from 'assets/store/selectors';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_MULTI_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {FILTER_TYPES_OPTIONS} from 'assets/services/service';

type PropTypes = {
  setQueryParams: Function,
};

const TypeFilter = ({setQueryParams}: PropTypes) => {
  const typeList = FILTER_TYPES_OPTIONS;
  const selectedTypes = useSelector(getSelectedFilterType);

  const onChange = (items) => {
    const typeVal = items.length ? items.map((item) => item.value).join(',') : null;
    setQueryParams({type: typeVal});
  };

  return (
    <SelectAndt
      type={TYPE_NEW_MULTI_NO_SEARCH}
      maxMenuHeight={175}
      minMenuHeight={75}
      buttonWidth={215}
      theme={THEME_HIGHLIGHTED}
      onChange={onChange}
      options={typeList}
      value={selectedTypes}
      isMulti
      optionHeight={40}
      isClearable={false}
      closeMenuOnSelect={false}
      disabled={false}
      automationId="assetsTypeFilter"
      placeholder="Select"
    />
  );
};

export default TypeFilter;
