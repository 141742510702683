import React, {useMemo} from 'react';
import opsgenieOptions from 'channels/constants/opsgenieOptions';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';
import SelectStandart from 'common/componentsV2/ddl/SelectStandart';
import {isValidUrl} from 'common/utils/validation';

const options = [
  ...opsgenieOptions,
  {
    value: 'new',
    label: 'New URL',
  },
];

const validatePredefinedUrl = (value) => {
  return !value && 'Url is mandatory';
};

const validateUrl = (value, values) => {
  if (values.urlDropdown === 'new') {
    if (values.url === '') {
      return 'Url is mandatory';
    }
    if (!isValidUrl(value)) {
      return 'A valid url is required';
    }
  }
  return undefined;
};

const UrlDropdown = () => {
  const {
    input: {value: valueDropdown, onChange: onChangeDropdown},
  } = useField('urlDropdown', {validate: validatePredefinedUrl});

  const {
    input: {value, onChange, onBlur},
    meta,
  } = useField('url', {validate: validateUrl});

  const selectedOption = useMemo(() => options.find((item) => item.value === valueDropdown), [options, valueDropdown]);

  return (
    <div>
      <div>URL</div>
      <SelectStandart
        id="urlDropdown"
        onChange={(item) => onChangeDropdown(item.value)}
        value={selectedOption}
        options={options}
      />
      {valueDropdown === 'new' && (
        <div className="mt_2-5">
          <div>New URL</div>
          <Input
            automationId="url"
            placeHolder="Global=https://opsgenie.com"
            isInvalid={meta.touched && meta.error}
            invalidMessage={meta.error}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
          />
        </div>
      )}
    </div>
  );
};

export default UrlDropdown;
