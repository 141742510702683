// @flow
import React from 'react';
import './DiametricsDraggableItem.module.scss';

const convertTypeToIcon = (type) => {
  switch (type) {
    case 'metric':
    case 'number':
      return 'icn-numbers-icon';
    case 'time':
    case 'timestamp':
      return 'icn-time-stamp-icon';
    default:
      return 'icn-text-icon';
  }
};

const DiametricsDraggableItem = (props: {
  itemKey: any,
  name: String,
  style: String,
  onRemove: Function,
  possibleTypes: Array<String>,
}) => (
  <div styleName="list-item" style={props.style} automation-id={`DraggableItem-${props.name}`}>
    <div styleName="possible-types">
      {props.possibleTypes.map((t) => (
        <i key={`drag_${t}`} className={`icon ${convertTypeToIcon(t)}`} />
      ))}
    </div>
    <div styleName="name" className="ellipsis">
      {props.name}
    </div>
    {props.onRemove && (
      <button
        type="button"
        className="btn btn-flat btn-icon btn-blue-light"
        styleName="btn-remove"
        automation-id={`removeItem-${props.name}`}
        onClick={(ev) => {
          ev.preventDefault();
          props.onRemove(props.itemKey);
        }}
      >
        <i className="icon icn-icon-table-delete" />
      </button>
    )}
  </div>
);

export default DiametricsDraggableItem;
