import {composeReducers} from 'common/utils/reducers';
import {combineReducers} from 'redux';
import {tabs} from 'admin.cs-portal/services/csPortalService';
import * as actions from '../actions';

// const EMPTY_ARRAY = [];

const mainTabs = composeReducers((state = tabs, {type, payload}) => {
  switch (type) {
    case actions.changeTab.TYPE: {
      return state.map((i) => {
        if (i.value === payload.value) {
          return {...i, active: true};
        }
        return {...i, active: false};
      });
    }
    default:
      return state;
  }
});

const selectedCustomer = composeReducers((state = {}, {type, payload}) => {
  switch (type) {
    case actions.changeCustomer.TYPE:
      return {
        ...payload,
      };
    default:
      return state;
  }
});

const streamsDimensions = composeReducers((state = [], {type, payload, meta}) => {
  switch (type) {
    case actions.fetchStreamDimensions.success.TYPE: {
      const newStreamObj = {...payload, name: meta};
      return [...state, newStreamObj];
    }
    case actions.fetchStreams.TYPE:
      return [];

    default:
      return state;
  }
});

export default combineReducers({
  selectedCustomer,
  streamsDimensions,
  mainTabs,
});
