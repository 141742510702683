// @flow
import React from 'react';
import MetricsSummary from './MetricsSummary';
import MetricsActions from './MetricsActions';
import MetricsCardinality from './MetricsCardinality';

import './MetricsView.module.scss';

export default class MetricsView extends React.PureComponent {
  render() {
    return (
      <div>
        <div styleName="innerContainer">
          <MetricsSummary />
          <MetricsActions />
        </div>
        <MetricsCardinality />
      </div>
    );
  }
}
