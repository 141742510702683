// @flow
import React from 'react';
import CompositeBuilder from 'metrics/components/compositeBuilder/CompositeBuilder';
import './ExpressionBuilderHolder.module.scss';
import PreviewGraphs from './PreviewGraphs';

type PropTypes = {};

export default class ExpressionBuilderStyleGuide extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <div styleName="container">
        <div styleName="expression-builder-container">
          <CompositeBuilder />
        </div>
        <div styleName="preview">
          <PreviewGraphs />
        </div>
      </div>
    );
  }
}
