// @flow
import React from 'react';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import '../../../common/componentsV2/SelectChipsInput.module.scss';
import SelectAndt, {THEME_HIGHLIGHTED_TRANSPARENT, TYPE_NEW_MULTI} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import FormDdlActionBar from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlActionBar';

type PropTypes = {
  options: Array,
  value: String,
  onRemove: Function,
  onChange: Function,
  onError: Function, // onError(isError: boolean)
};

export default class SelectChipAdvancedValues extends React.PureComponent {
  props: PropTypes;

  state = {
    menuIsOpen: false,
  };

  onMenuOpen = () => {
    this.setState({menuIsOpen: true});
    this.props.onError(false);
  };

  onMenuClose = () => {
    this.setState({menuIsOpen: false});
    this.props.onError(false);
  };

  handleCreate = (val) => {
    const {value, onChange} = this.props;
    let isInvalid = val.indexOf(' ') > -1;
    const consValAsArr = val.split('=');
    if (consValAsArr.length !== 2) {
      isInvalid = true;
    }
    consValAsArr.forEach((v) => {
      if (v.length === 0) {
        isInvalid = true;
      }
    });
    if (!isInvalid) {
      onChange([...value, {label: val, value: consValAsArr[1]}]);
    } else {
      this.props.onError(true);
    }
  };

  render() {
    const {value, options, onRemove, onChange} = this.props;
    const {menuIsOpen} = this.state;
    return (
      <div styleName="wrapper">
        <SelectAndt
          isInputVisible
          automationId="selectChipsInput"
          type={TYPE_NEW_MULTI}
          theme={THEME_HIGHLIGHTED_TRANSPARENT}
          onChange={onChange}
          options={options}
          value={value}
          isMulti
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
          placeholder=""
          optionHeight={40}
          menuIsOpen={menuIsOpen}
          isClearable={false}
          closeMenuOnSelect={false}
          closeMenuOnScroll={false}
          forceResorting
          isCreatable
          onCreateOption={this.handleCreate}
          menuFooterComponent={
            <FormDdlActionBar isVisible isApplyDisabled={false} onApply={this.handleTagsApplyChanges} />
          }
          menuPlacement="bottom"
          customComponent={{
            DropdownIndicator: () => (
              <div styleName="root">
                {value.map((item) => (
                  <span key={`chip-${item.label}`} onClick={this.handleChipRemoved}>
                    <InnerChip expression={item} onRemoveClicked={onRemove} />
                  </span>
                ))}
              </div>
            ),
          }}
        />
      </div>
    );
  }
}
