import React, {Component} from 'react';
import {components} from 'react-select2';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  children: any,
  selectProps: any,
};

export default class SingleValueWithLabel extends Component {
  props: PropTypes;

  state = {
    initialState: true,
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({initialState: false});
    }, 0);
  }

  render() {
    if (this.props.selectProps.placeholder && this.props.selectProps.placeholder !== '') {
      let {showValue} = this.props.selectProps;
      if (typeof this.props.selectProps.showValue === 'undefined') {
        showValue = true;
      }

      if (showValue) {
        return (
          <components.SingleValue
            className="andt-dropdown-single-value"
            styleName={this.state.initialState ? 'dropdownNoSearchLight' : 'dropdownNoSearchLight selected'}
            {...this.props}
          >
            <div className={this.props.selectProps.theme}>{this.props.children}</div>
            <small className={this.props.selectProps.theme}>{this.props.selectProps.placeholder}</small>
          </components.SingleValue>
        );
      }
      return (
        <components.SingleValue
          className="andt-dropdown-single-value"
          styleName={[
            'no-value',
            this.state.initialState ? 'dropdownNoSearchLight' : 'dropdownNoSearchLight selected',
          ].join(' ')}
          {...this.props}
        >
          <div className={this.props.selectProps.theme}>{this.props.selectProps.placeholder}</div>
        </components.SingleValue>
      );
    }
    return (
      <components.SingleValue className="andt-dropdown-single-value" {...this.props}>
        <div style={{position: 'relative'}}>{this.props.children}</div>
      </components.SingleValue>
    );
  }
}
