import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Button, {COLORS} from 'common/componentsV2/Button';
import {updateSimulation} from 'metrics/store/actions';
import * as metricsSelectors from 'metrics/store/selectors';

const resolutions = [10, 50];

const Show = () => {
  const dispatch = useDispatch();
  const {show: value} = useSelector(metricsSelectors.getPreviewOptions);
  const onUpdateSimulation = useCallback(
    (payload) => {
      dispatch(updateSimulation(payload));
    },
    [dispatch],
  );

  const currentIndex = resolutions.indexOf(value);
  return currentIndex < resolutions.length - 1 ? (
    <Button
      colorSchema={COLORS.BLUE_500}
      text="Show More Metrics"
      onClick={() => onUpdateSimulation({show: resolutions[currentIndex + 1], cache: true})}
    />
  ) : null;
};

export default Show;
