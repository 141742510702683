// @flow
import React from 'react';
import './SideNavigationButton.module.scss';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';

type PropTypes = {
  onClick: Function,
  icon: string,
  isSelected: boolean,
  tooltipText: string,
};

export default class SideNavigationButton extends React.PureComponent {
  props: PropTypes;

  render() {
    const {onClick, icon, isSelected, tooltipText} = this.props;
    return (
      <button onClick={onClick} type="button" styleName={['nav-button', isSelected ? 'selected' : ''].join(' ')}>
        <Tooltip placement="right" content={tooltipText} type={TYPES.SMALL}>
          <div className={['icon', icon].join(' ')} />
        </Tooltip>
      </button>
    );
  }
}
