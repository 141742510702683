// @flow
import React from 'react';
import './Footer.module.scss';

type PropTypes = {
  rightChildren: any,
  centerChildren: any,
  leftChildren: any,
};

export default class Footer extends React.PureComponent {
  props: PropTypes;

  render() {
    const {rightChildren, centerChildren, leftChildren} = this.props;

    return (
      <div styleName="root">
        <div styleName="left">{leftChildren}</div>
        {centerChildren}
        <div styleName="right">{rightChildren}</div>
      </div>
    );
  }
}
