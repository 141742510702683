// @flow
import React, {PureComponent} from 'react';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {getFormattedDateFull, getFormattedDateMini} from 'common/utils/dateService';

type PropTypes = {
  startDate: number,
  timeZone: string,
  color: string,
};

class AlertDate extends PureComponent {
  props: PropTypes;

  render() {
    const {startDate, timeZone} = this.props;
    const formattedDate = getFormattedDateFull(startDate, timeZone);
    const formattedMini = getFormattedDateMini(startDate, timeZone);

    return (
      <div className="alert-date-wrapper">
        <SmartTooltip placement="top" content={formattedDate} delay={400}>
          <span className="ellipsis" style={{color: this.props.color || null, cursor: 'default', fontWeight: '400'}}>
            {formattedMini}
          </span>
        </SmartTooltip>
      </div>
    );
  }
}

export default AlertDate;
