// @flow
import React from 'react';
import {noop} from 'lodash';
import Tag from './Tag';
import './TagList.module.scss';

type PropTypes = {
  tags: [],
  isAllList: boolean,
  isDismissible?: boolean,
  onSeeAllClick: Function,
  onDismiss?: Function,
  isRow?: boolean,
};

export default class TagList extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    isDismissible: false,
    isRow: false,
    onDismiss: noop,
  };

  state = {
    isShowAllButton: false,
  };

  componentDidMount() {
    this.setState({isShowAllButton: this.props.tags.length > 6});
  }

  render() {
    const {tags, onSeeAllClick, isAllList, isDismissible, onDismiss, isRow} = this.props;
    return (
      <div styleName={['container', isRow ? 'row' : ''].join(' ')}>
        <div styleName={['title', isAllList ? 'all-list' : ''].join(' ')}>
          <span>{`Labels (${tags.length})`}</span>
          {!isAllList && this.state.isShowAllButton && (
            <button type="button" styleName="see-all" onClick={onSeeAllClick} href="#">
              See all
            </button>
          )}
        </div>
        <div styleName={['tags', isAllList ? 'all-list' : ''].join(' ')}>
          {tags.map((tag) => (
            <Tag
              key={`${tag}-${isAllList ? 'all-list' : 'partial-list'}-${tag}`}
              title={tag}
              isDismissible={isDismissible}
              onDismiss={onDismiss}
            />
          ))}
        </div>
      </div>
    );
  }
}
