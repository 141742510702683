export const CORALOGIX_FILTER_TYPES = [
  {
    label: 'Include',
    value: 'in',
  },
  {
    label: 'Exclude',
    value: 'notIn',
  },
];

export const CORALOGIX_AGGR_INTERVAL_TYPES = [
  {
    label: '1 Minute',
    value: 1,
  },
  {
    label: '5 Minutes',
    value: 5,
  },
  {
    label: '1 Hour',
    value: 60,
  },
  {
    label: '1 Day',
    value: 60 * 24,
  },
];

export const getEditorValidationMessage = (stream) => {
  if (stream.id === 'EditRunning') {
    return 'This stream is active, recollection is not enabled. Please duplicate the stream to change the collected data.';
  }
  if (!stream.metricName) {
    return 'Stream metric name is empty, select a metric to continue.';
  }
  if (!stream.metrics.length || !stream.dimensions.length) {
    return 'Data is loading.';
  }
  return null;
};

export const getAggrIntervalLabel = (val) => CORALOGIX_AGGR_INTERVAL_TYPES.find((v) => val === v.value).label;
