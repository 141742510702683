export const getEditorValidationMessage = (stream) => {
  if (stream.historicalDateRange === undefined) {
    return 'First report period is mandatory';
  }
  if (!stream.basedOnTemplateId) {
    return 'AWS CUR use case is mandatory';
  }
  return null;
};

export const getAggregation = (templateId, itemName) => {
  let countersArr = []; // Sum
  let gaugesArray = []; // Average

  switch (templateId) {
    case '51':
      countersArr = ['lineItem/BlendedCost', 'lineItem/UnblendedCost', 'lineItem/UsageAmount'];
      gaugesArray = ['lineItem/BlendedRate'];
      break;
    case '52':
      countersArr = ['lineItem/UsageAmount', 'lineItem/UnblendedCost'];
      break;
    case '53':
      countersArr = [
        'lineItem/UsageAmount',
        'lineItem/UnblendedCost',
        'savingsPlan/SavingsPlanEffectiveCost',
        'reservation/EffectiveCost',
      ];
      break;
    default:
      countersArr = [];
      gaugesArray = [];
  }

  if (countersArr.includes(itemName)) {
    return 'counter';
  }
  if (gaugesArray.includes(itemName)) {
    return 'gauge';
  }
  return '';
};

export const getMetricTagsUnit = (templateId, itemName) => {
  const metricTags = {
    unit: '',
  };

  switch (templateId) {
    case '51':
      if (['lineItem/BlendedCost', 'lineItem/UnblendedCost'].includes(itemName)) {
        metricTags.unit = 'USD';
        return metricTags;
      }
      break;
    case '52':
      if (['lineItem/UsageAmount', 'lineItem/UnblendedCost'].includes(itemName)) {
        metricTags.unit = 'USD';
        return metricTags;
      }
      break;
    case '53':
      if (
        ['lineItem/UnblendedCost', 'savingsPlan/SavingsPlanEffectiveCost', 'reservation/EffectiveCost'].includes(
          itemName,
        )
      ) {
        metricTags.unit = 'USD';
        return metricTags;
      }
      break;
    default:
      return null;
  }

  return null;
};
