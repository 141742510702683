import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {setOriginTitlesCache} from 'common/utils/angularServices';
import * as actions from 'metrics/store/actions';
import * as api from 'metrics/services/api';
import init from './init';
import expressionBuilderEpic from './expressionBuilderEpic';

const customerMetricsCount = makeAsyncEpic(actions.fetchCustomerMetricsCount, api.fetchCustomerMetricsCountLastDays);
const fetchMetricsTotal = makeAsyncEpic(actions.fetchMetricsTotal, api.fetchMetricsTotal);
const fetchOriginTitleCache = makeAsyncEpic(actions.fetchOriginTitleCache, api.fetchOriginTitleCache);

const fetchOriginTitleCacheSuccess = (action$) =>
  action$.ofType(actions.fetchOriginTitleCache.success.TYPE).flatMap((action) => {
    setOriginTitlesCache(action.payload);
    return [];
  });

export default combineEpics(
  init,
  customerMetricsCount,
  fetchMetricsTotal,
  fetchOriginTitleCache,
  fetchOriginTitleCacheSuccess,
  expressionBuilderEpic,
);
