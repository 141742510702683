// @flow
import React from 'react';
import {DropdownButton, MenuItem} from 'common/components/bootstrap/index';
import SmartTooltip from 'common/components/SmartTooltip';
import SectionHeader from './SectionHeader';
import './MatchingProperties.module.scss';

const EMPTY_ARRAY = [];

class MatchingProperties extends React.PureComponent {
  props: {
    influencingMetricsInfo: Object,
    matchingProperties: Array,
    id: String,
    setSelectedAlertInfluencingMetrics: Function,
  };

  addRemoveMatchingProperty = (prop) => {
    const {matchingProperties, id} = this.props;
    if (matchingProperties.find((p) => p === prop)) {
      this.props.setSelectedAlertInfluencingMetrics({
        id,
        matchingProperties: matchingProperties.filter((p) => p !== prop),
      });
    } else {
      this.props.setSelectedAlertInfluencingMetrics({id, matchingProperties: matchingProperties.concat([prop])});
    }
  };

  render() {
    const {influencingMetricsInfo, matchingProperties, id, setSelectedAlertInfluencingMetrics} = this.props;
    const tooltip = (
      <div>
        Choose Matching Properties
        <SmartTooltip
          content={
            <div styleName="matching-tooltip">
              <div styleName="matching-text">
                To create influencing metrics, you need to choose what dimensions will be matched together from the
                influencing metrics to the alert metrics
              </div>
              <div styleName="matching-gif" />
            </div>
          }
        >
          <span>
            {' '}
            <i className="icon andt-icon-help" />
          </span>
        </SmartTooltip>
      </div>
    );

    return (
      <div>
        <SectionHeader
          digit="2"
          title={tooltip}
          subTitle="Select the properties that match the influencing metrics to the alert metrics"
        />

        <div styleName="desc-comment">Many influencing metrics cannot be related to a single metric in the alert*</div>

        {influencingMetricsInfo.data.total !== 1 && (
          <div styleName="matching-props-container">
            <div styleName="matching-props-ddl-container">
              <DropdownButton
                id="matching-props-ddl"
                extraClassName="icon-dropdown btn-icon left-bubble-arrow cyan-scrollbar white-style"
                noCaret
                title="Matching Properties"
                styleName="matching-props-ddl"
                value={
                  <i
                    className={influencingMetricsInfo.isLoading ? 'icon ion-load-c spin' : 'icon icn-action16-plusa'}
                    styleName="drop-icon-btn"
                  />
                }
                onSelect={this.addRemoveMatchingProperty}
                disabled={
                  influencingMetricsInfo.isLoading &&
                  !(influencingMetricsInfo.data.matchingProperties || EMPTY_ARRAY).length
                }
              >
                {influencingMetricsInfo.isLoading !== true &&
                  (influencingMetricsInfo.data.matchingProperties || EMPTY_ARRAY).map((prop) => (
                    <MenuItem
                      key={prop}
                      className="menu-item-influence-metrics"
                      eventKey={prop}
                      active={!!matchingProperties.find((a) => a === prop)}
                    >
                      {prop}
                    </MenuItem>
                  ))}
                {!influencingMetricsInfo.isLoading &&
                  !(influencingMetricsInfo.data.matchingProperties || EMPTY_ARRAY).length && (
                    <MenuItem key="none" disabled eventKey="">
                      No Properties
                    </MenuItem>
                  )}
              </DropdownButton>
            </div>
          </div>
        )}

        {influencingMetricsInfo.data.total === 1 && (
          <div styleName="single-metric-props">
            A single metric was set in step 1 and will influence all alert metrics. Setting of matching properties is
            not required.
          </div>
        )}

        <div styleName="selected-props">
          {matchingProperties.map((p) => (
            <div styleName="selected-prop" key={p}>
              <span>{p}</span>
              <button
                type="button"
                className="btn btn-flat btn-icon"
                onClick={() =>
                  setSelectedAlertInfluencingMetrics({
                    id,
                    matchingProperties: matchingProperties.filter((a) => a !== p),
                  })
                }
              >
                <i className="icon icn-general16-closea" />
              </button>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default MatchingProperties;
