// @flow

import React from 'react';
import PanelHeader from 'bc/components/streams/editor/StreamEditorPanelHeader';
import ConceptsPanel from './ConceptsPanel';

import './GaDimetric.module.scss';

const GaDimetric = ({
  possibleMetrics,
  possibleDimensions,
  selectedMetrics,
  selectedDimensions,
  onClearAll /* onSaveAsTemplate, isUsingTemplate, */,
  onAddMetric,
  onRemoveMetric,
  onAddDimension,
  onRemoveDimension,
}: any) => (
  <div styleName="main">
    <PanelHeader title="Measures & Dimensions">
      <button type="button" className="btn btn-flat btn-blue" onClick={onClearAll}>
        Clear All
      </button>
      {/* <button
      className='btn btn-flat btn-blue'
      styleName="button"
      disabled={!!isUsingTemplate}
      onClick={onSaveAsTemplate}>
      Save as Template
      </button> */}
    </PanelHeader>
    <div styleName="concepts">
      <ConceptsPanel
        title="Measures"
        possibleConcepts={possibleMetrics}
        selectedConcepts={selectedMetrics}
        onAddItem={onAddMetric}
        onRemoveItem={onRemoveMetric}
      />
      <div styleName="vr" />
      <ConceptsPanel
        title="Dimensions"
        possibleConcepts={possibleDimensions}
        selectedConcepts={selectedDimensions}
        onAddItem={onAddDimension}
        onRemoveItem={onRemoveDimension}
      />
    </div>
  </div>
);

export default GaDimetric;
