// @flow
import React, {Fragment} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import {Box} from '@material-ui/core';
import SelectAndt, {THEME_BLUE_LEAN_MODIFIED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import CalendarRange from 'common/componentsV2/customDateRange/CalendarRange';
import TimeSettings from 'common/componentsV2/customDateRange/TimeSettings';
import RelativeField from 'common/componentsV2/customDateRange/RelativeField';
import {options, OPTIONS_VALUE, titleTab} from 'common/componentsV2/customDateRange/CustomDateRangeModal';
import AsyncButton from 'common/componentsV2/AsyncButton';
import * as dateRangeService from 'common/utils/dateRangeService';
import {updatePredefinedDatesUserSettings} from 'common/components/dateTime/store/actions';
import {getPredefinedDateUserSetting, userSettingIsLoading} from 'profile/store/selectors';
import {getUniqueId} from '../../utils/guid';

type PropsType = {
  onSelect: Function,
  timeZoneName: string,
  isUnix: boolean,
  onClose: Function,
};

const formatTime = (dateRangeValue, startTime, endTime, isTimeApply) => {
  const {
    value: {hour: startHour, min: startMin},
  } = startTime;
  const {
    value: {hour: endHour, min: endMin},
  } = endTime;
  const isCustomEndTime = isTimeApply && endHour !== 0 && endMin !== 0;
  const start = new Date(dateRangeValue.startDate).setHours(isTimeApply ? startHour : 0, startMin);
  const end =
    dateRangeValue.endDate !== ''
      ? new Date(dateRangeValue.endDate).setHours(
          isCustomEndTime ? endHour : 23,
          isCustomEndTime ? endMin : 59,
          isCustomEndTime ? 0 : 59,
          isCustomEndTime ? 0 : 999,
        )
      : new Date(start).setHours(23, 59, 59, 999);
  return {start, end};
};

const transformToSeconds = (value, scale) => {
  switch (scale) {
    case 'weeks':
      return value * 3600 * 24 * 7;
    case 'days':
      return value * 3600 * 24;
    case 'hours':
      return value * 3600;
    default:
      return value * 3600 * 24;
  }
};

const CustomDateRange = ({onSelect, timeZoneName, isUnix, onClose}: PropsType) => {
  const dispatch = useDispatch();
  const dataPredefinedDates = useSelector(getPredefinedDateUserSetting);
  const isLoading = useSelector(userSettingIsLoading);

  const {
    input: {value: rangeTypeValue, onChange: onChangeRangeType},
  } = useField('rangeType');
  const {
    input: {value: dateRangeValue, onChange: onChangeDateRange},
  } = useField('dateRange');
  const {
    input: {value: relativeLastValue, onChange: onChangeLastValue},
  } = useField('relativeLast');
  const {
    input: {value: relativeNextValue, onChange: onChangeNextValue},
  } = useField('relativeNext');
  const {
    input: {value: relativeLastScale, onChange: onChangeLastScaleValue},
  } = useField('relativeLastScale');
  const {
    input: {value: relativeNextScale, onChange: onChangeNextScaleValue},
  } = useField('relativeNextScale');
  const {
    input: {value: isTimeApply},
  } = useField('isTimeApply');
  const {
    input: {value: startTime},
  } = useField('startTime');
  const {
    input: {value: endTime},
  } = useField('endTime');

  const handleApply = () => {
    const {start, end} = formatTime(dateRangeValue, startTime, endTime, isTimeApply);

    if (rangeTypeValue.value === OPTIONS_VALUE.RANGE) {
      onSelect({
        dateRange: dateRangeService.getDateValue(
          {
            constRange: 'c',
            startDate: start,
            endDate: end,
          },
          isUnix,
          timeZoneName,
        ),
      });
    } else {
      onSelect({
        dateRange: dateRangeService.getDateValue(
          {
            constRange: 'r',
            relativeLast: transformToSeconds(relativeLastValue, relativeLastScale),
            relativeNext: transformToSeconds(relativeNextValue, relativeNextScale),
          },
          isUnix,
          timeZoneName,
        ),
      });
    }

    onClose();
  };

  const handleSavePreset = () => {
    const isRange = rangeTypeValue.value === OPTIONS_VALUE.RANGE;
    const isRelative =
      rangeTypeValue.value === OPTIONS_VALUE.RELATIVE || rangeTypeValue.value === OPTIONS_VALUE.RELATIVE_RANGE;
    const {start, end} = formatTime(dateRangeValue, startTime, endTime, isTimeApply);
    dispatch(
      updatePredefinedDatesUserSettings({
        preDefinedDates: dataPredefinedDates.preDefinedDates,
        customDates: [
          ...dataPredefinedDates.customDates,
          {
            value: isRange ? 'c' : 'r',
            startDate: isRange ? start : undefined,
            endDate: isRange ? end : undefined,
            relativeLast: isRelative ? transformToSeconds(relativeLastValue, relativeLastScale) : undefined,
            relativeNext: isRelative ? transformToSeconds(relativeNextValue, relativeNextScale) : undefined,
            text: isRange
              ? dateRangeService.createCustomLabel(start, end)
              : dateRangeService.createRelativeLabel(
                  transformToSeconds(relativeLastValue, relativeLastScale),
                  transformToSeconds(relativeNextValue, relativeNextScale),
                ),
            isSelected: true,
            id: getUniqueId(),
          },
        ],
      }),
    );
    handleApply();
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
      {titleTab('Custom Time Frame')}
      <Box mt={1.5}>
        <SelectAndt
          automationId="CustomDateRange"
          onChange={onChangeRangeType}
          value={rangeTypeValue}
          options={options}
          optionHeight={40}
          menuWidth={150}
          type={TYPE_NEW_NO_SEARCH}
          theme={THEME_BLUE_LEAN_MODIFIED}
        />
      </Box>
      {rangeTypeValue.value === OPTIONS_VALUE.RANGE && (
        <Fragment>
          <CalendarRange value={dateRangeValue} onChange={onChangeDateRange} />
          <TimeSettings />
        </Fragment>
      )}
      {rangeTypeValue.value === OPTIONS_VALUE.RELATIVE && (
        <Box mt={10} flexGrow={1}>
          <Box mt={1}>
            <RelativeField
              value={relativeLastValue}
              onChange={onChangeLastValue}
              scaleValue={relativeLastScale}
              onChangeScaleValue={(val) => {
                onChangeLastScaleValue(val);
                onChangeNextScaleValue(val);
              }}
              label="Show the last"
            />
          </Box>
        </Box>
      )}
      {rangeTypeValue.value === OPTIONS_VALUE.RELATIVE_RANGE && (
        <Box mt={10} flexGrow={1}>
          <Box mt={1}>
            <RelativeField
              value={relativeLastValue}
              onChange={onChangeLastValue}
              scaleValue={relativeLastScale}
              onChangeScaleValue={(val) => {
                onChangeLastScaleValue(val);
                onChangeNextScaleValue(val);
              }}
              label="Show the last"
            />
          </Box>
          <Box mt={3}>
            <RelativeField
              value={relativeNextValue}
              onChange={onChangeNextValue}
              scaleValue={relativeNextScale}
              onChangeScaleValue={(val) => {
                onChangeLastScaleValue(val);
                onChangeNextScaleValue(val);
              }}
              label="And the next"
            />
          </Box>
        </Box>
      )}
      <Box display="flex" alignSelf="flex-end" flexShrink={0} mt={3}>
        <Box mr={1.5}>
          <AsyncButton
            colorSchema="gray.300"
            onClick={handleSavePreset}
            text="Save as Preset"
            isDisabled={
              (!dateRangeValue.startDate && rangeTypeValue.value === OPTIONS_VALUE.RANGE) ||
              ((rangeTypeValue.value === OPTIONS_VALUE.RELATIVE ||
                rangeTypeValue.value === OPTIONS_VALUE.RELATIVE_RANGE) &&
                !relativeLastValue)
            }
            isLoading={isLoading}
          />
        </Box>
        <AsyncButton
          colorSchema="blue.500"
          type="submit"
          onClick={handleApply}
          text="Apply"
          isDisabled={
            (!dateRangeValue.startDate && rangeTypeValue.value === OPTIONS_VALUE.RANGE) ||
            ((rangeTypeValue.value === OPTIONS_VALUE.RELATIVE ||
              rangeTypeValue.value === OPTIONS_VALUE.RELATIVE_RANGE) &&
              !relativeLastValue)
          }
        />
      </Box>
    </Box>
  );
};

export default CustomDateRange;
