// @flow
import React from 'react';
import {fileCompressions} from 'bc/services/parquetService';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  onChange: Function,
  value: String,
};

export default class ParquetCompression extends React.PureComponent {
  props: PropTypes;

  onMenuItemClick = (item) => {
    this.props.onChange(item.value);
  };

  render() {
    const {value} = this.props;
    const optionCurrent = fileCompressions.findIndex((option) => option.value === value);
    return (
      <SelectAndt
        id="ParquetCompression"
        automationId="ParquetCompression"
        options={fileCompressions}
        type={TYPE_NO_SEARCH}
        theme={THEME_LIGHT}
        value={fileCompressions[optionCurrent]}
        onChange={this.onMenuItemClick}
        placeholder="File Compression"
      />
    );
  }
}
