import React, {PureComponent} from 'react';

type PropTypes = {
  // accepted values by the original Select
  getStyles: Function,
};

export default class DropdownIndicatorCaretNoTransform extends PureComponent {
  props: PropTypes;

  render() {
    const style = this.props.getStyles('dropdownIndicator', this.props);
    return <span style={style} className="icon icn-arrow16-triangledown" />;
  }
}
