import React from 'react';
import {Box, Grid, Typography} from '@material-ui/core';
import Button from '../Button';

const ExpandableAdd = ({
  label,
  children,
  onAdd,
  onRemove,
  automationId,
}: {
  label: String,
  children: Object,
  onAdd: Function,
  onRemove: Function,
  automationId: string,
}) => (
  <div automation-id={automationId}>
    <Box
      px={2}
      height={48}
      borderRadius={10}
      bgcolor="gray.100"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="h5">{label}</Typography>
      <Box mr={-2}>
        <Button
          colorSchema="transparent-blue"
          icon="icn-action16-plusb"
          text="Add"
          automationId={`${automationId}_add`}
          onClick={onAdd}
        />
      </Box>
    </Box>
    {children && (
      <Box mt={3}>
        <Grid container direction="column" spacing={3}>
          {children.map((child) => (
            <Grid automation-id={`${automationId}_itemContainer`} key={child.props.id} item>
              <Box
                position="relative"
                px={2}
                py={1.5}
                borderRadius={10}
                bgcolor="gray.100"
                css={{'&:hover > div:first-child > i': {opacity: 1}}}
              >
                <Box
                  automation-id={`${automationId}_remove`}
                  onClick={() => onRemove(child.props.id)}
                  color="gray.400"
                  position="absolute"
                  right={16}
                  top={12}
                  css={{cursor: 'pointer', '& > i': {opacity: 0, transition: '400ms'}}}
                >
                  <i className="icon icon icn-general16-closea" />
                </Box>
                {child}
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    )}
  </div>
);

export default ExpandableAdd;
