import 'rxjs/add/observable/of';
import {Observable} from 'rxjs/Observable';
import moment from 'moment';
import {noop} from 'lodash';

export const ACTION_PREFIX = '';
export const ACTION_ASYNC_REQUEST_SUFFIX = '@ASYNC_REQUEST';
export const ACTION_ASYNC_SUCCESS_SUFFIX = '@ASYNC_SUCCESS';
export const ACTION_ASYNC_FAILURE_SUFFIX = '@ASYNC_FAILURE';
export const ACTION_ASYNC_IGNORE_SUFFIX = '@ASYNC_IGNORE';

/**
 *
 * @param name
 * @param prefix
 * @returns {function(*, *): {type: string, payload: *, meta: *}}
 */
export function makeAction(name, prefix = ACTION_PREFIX) {
  const type = `${prefix}${name}`;
  const actionCreator = (payload, meta) => ({type, payload, meta});
  actionCreator.TYPE = type;
  return actionCreator;
}

/**
 *
 * @param baseName
 * @param prefix
 * @returns {function(*, *): {type: string, payload: *, meta: *}}
 */
export function makeAsyncAction(baseName, prefix = ACTION_PREFIX) {
  const actionCreator = makeAction(`${baseName}${ACTION_ASYNC_REQUEST_SUFFIX}`, prefix);
  actionCreator.success = makeAction(`${baseName}${ACTION_ASYNC_SUCCESS_SUFFIX}`, prefix);
  actionCreator.failure = makeAction(`${baseName}${ACTION_ASYNC_FAILURE_SUFFIX}`, prefix);
  actionCreator.ignore = makeAction(`${baseName}${ACTION_ASYNC_IGNORE_SUFFIX}`, prefix);

  return actionCreator;
}

/**
 *
 * @param actionCreator
 * @param options = {dataProp , shouldDestroyData, defaultData, shouldSpread})
 * @returns {Function}
 */
export function makeAsyncReducer(actionCreator, options) {
  const defaults = {
    dataProp: 'data',
    shouldDestroyData: true,
    defaultData: undefined,
    shouldSpread: false,
    includeUpdateAt: false, // should include last request epoch time
    errorHandler: noop,
  };
  const derivedOptions = Object.assign(defaults, options);

  /*
   isLoading states:
   -undefined: don't have data/init,
   -true: fetching data,
   -false: have data (even if it's empty data)
   */
  const defaultState = derivedOptions.shouldSpread
    ? {error: undefined, isLoading: undefined, ...(derivedOptions.defaultData || {})}
    : {error: undefined, isLoading: undefined, [derivedOptions.dataProp]: derivedOptions.defaultData};

  return function(state = defaultState, {type, payload}) {
    const oldUpdateAt = state.updateAt ? {updateAt: state.updateAt} : {};
    const updateAt = derivedOptions.includeUpdateAt ? {updateAt: moment().unix()} : {};
    const oldFailureAt = state.failureAt ? {failureAt: state.failureAt} : {};
    const failureAt = {failureAt: moment().unix()};

    switch (type) {
      case actionCreator.TYPE:
        return derivedOptions.shouldSpread
          ? {
              isLoading: true,
              ...((derivedOptions.shouldDestroyData && derivedOptions.defaultData) || {}),
              ...oldUpdateAt,
              ...oldFailureAt,
            }
          : {
              isLoading: true,
              [derivedOptions.dataProp]: derivedOptions.shouldDestroyData
                ? derivedOptions.defaultData
                : state[derivedOptions.dataProp],
              ...oldUpdateAt,
              ...oldFailureAt,
            };
      case actionCreator.success.TYPE:
        return derivedOptions.shouldSpread
          ? {isLoading: false, ...updateAt, ...payload}
          : {isLoading: false, ...updateAt, [derivedOptions.dataProp]: payload};
      case actionCreator.failure.TYPE:
        // eslint-disable-next-line no-param-reassign
        payload.errorHandler = derivedOptions.errorHandler;
        return {
          ...state,
          isLoading: false,
          error: payload,
          ...oldUpdateAt,
          ...failureAt,
        };
      case actionCreator.ignore.TYPE:
        return {
          ...state,
          isLoading: false,
          ...oldUpdateAt,
          ...oldFailureAt,
        };
      default:
        return state;
    }
  };
}

/**
 *
 * @param actionCreator
 * @param asyncFn
 * @returns {function(*): (*|Observable)}
 */
export function makeAsyncEpic(actionCreator, asyncFn, filterFn) {
  return (action$, {getState, dispatch}) =>
    action$
      .ofType(actionCreator.TYPE)
      .filter((action) => {
        const res = filterFn ? filterFn(action, getState, dispatch) : true;
        if (!res) {
          dispatch(actionCreator.ignore(action));
        }
        return res;
      })
      .switchMap((action) =>
        asyncFn(action, getState, dispatch)
          .map((payload) => actionCreator.success(payload, action.meta))
          .catch((error) => Observable.of(actionCreator.failure(error, action.meta))),
      );
}

/**
 *
 * @param actionCreator
 * @param asyncFn
 * @returns {function(*): (*|Observable)}
 */
export function makeFlatAsyncEpic(actionCreator, asyncFn, filterFn) {
  return (action$, {getState, dispatch}) =>
    action$
      .ofType(actionCreator.TYPE)
      .filter((action) => {
        const res = filterFn ? filterFn(action, getState, dispatch) : true;
        if (!res) {
          dispatch(actionCreator.ignore(action));
        }
        return res;
      })
      .flatMap((action) =>
        asyncFn(action, getState, dispatch)
          .map((payload) => actionCreator.success(payload, action.meta))
          .catch((error) => Observable.of(actionCreator.failure(error, action.meta))),
      );
}

export function reqTimeingFilterFanction(updatedAt, interval = 120) {
  const now = moment().unix();
  if (updatedAt && now - updatedAt < interval) {
    return false;
  }
  return true;
}
