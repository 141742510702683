// @flow
import React from 'react';
import * as dateRangeService from 'common/utils/dateRangeService';
import SelectAndt, {THEME_BLUE, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  onChange: Function,
  dateRange: Object,
  isUnix: boolean,
  title: String,
  timeZoneName: String,
  allowedRangeTypes?: Array,
  analytics?: Object,
  theme?: String,
  type?: String,
  showValue?: boolean,
  menuWidth?: Number,
  buttonWidth?: Number,
  optionsModifier: Function,
  isCustomAllowed: boolean,
};

const preDefinedDates = Object.values(dateRangeService.rangeTypes).filter(
  (r) => [dateRangeService.rangeTypes.c.key].indexOf(r.key) < 0,
);

class SchedularDateRange extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    allowedRangeTypes: null,
    analytics: null,
    theme: null,
    type: null,
    showValue: true,
    menuWidth: null,
    buttonWidth: null,
  };

  state = {
    preDefinedDatesState: preDefinedDates,
  };

  componentDidMount() {
    this.getPredefinedDateRange();
  }

  componentDidUpdate(prevProps) {
    const {allowedRangeTypes} = this.props;

    if (allowedRangeTypes && allowedRangeTypes.length && prevProps.allowedRangeTypes !== allowedRangeTypes) {
      this.getPredefinedDateRange();
    }
  }

  getPredefinedDateRange = () => {
    const {allowedRangeTypes, isCustomAllowed} = this.props;

    if (allowedRangeTypes && allowedRangeTypes.length) {
      const datesSourse = isCustomAllowed ? Object.values(dateRangeService.rangeTypes) : preDefinedDates;
      const newPredefinedDates = datesSourse.filter((r) => allowedRangeTypes.indexOf(r.key) !== -1);
      this.setState({preDefinedDatesState: newPredefinedDates});
    }
  };

  onMenuItemClick = (key) => {
    if (key === dateRangeService.rangeTypes.c.value) {
      this.openModal();
    } else {
      this.props.onChange(dateRangeService.getDateValue({constRange: key}, this.props.isUnix, this.props.timeZoneName));
    }
  };

  render() {
    const {
      title,
      dateRange,
      analytics,
      theme,
      type,
      showValue,
      menuWidth,
      buttonWidth,
      optionsModifier,
      isCustomAllowed,
    } = this.props;

    const {preDefinedDatesState} = this.state;
    const options = optionsModifier ? optionsModifier(preDefinedDatesState) : preDefinedDatesState;
    const selectedIndex = options.findIndex(
      (v) => v.key === dateRange.constRange || v.id === dateRange.constRange || v.value === dateRange.constRange,
    );
    const filteredOptions = isCustomAllowed ? options.filter((item) => item.key !== 'c') : options;

    return (
      <div
        role="button"
        onClick={(event) => event.preventDefault()}
        className="analytics"
        data-andt-analytics-category={analytics ? analytics.category : null}
        data-andt-analytics-label={analytics ? analytics.label : null}
      >
        <SelectAndt
          menuWidth={menuWidth}
          buttonWidth={buttonWidth}
          id="dateRangesPicker"
          automationId="dateRangesPicker"
          className="andt-dropdown"
          options={filteredOptions}
          getOptionLabel={(val) => val.text}
          getOptionValue={(val) => val.value}
          type={type || TYPE_NO_SEARCH}
          theme={theme || THEME_BLUE}
          value={options[selectedIndex]}
          onChange={(item) => this.onMenuItemClick(item.value)}
          placeholder={title || 'Date range'}
          showValue={showValue}
          maxMenuHeight={300}
          optionHeight={40}
        />
      </div>
    );
  }
}

export default SchedularDateRange;
