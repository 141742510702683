// @flow
import React from 'react';
import SeverityMarker from 'common/componentsV2/SeverityMarker';
import WarningSign from 'common/componentsV2/WarningSign';
import './InfoSection.module.scss';

type PropTypes = {
  title: String,
  owner: String,
  severity?: String,
  isNotification?: boolean,
  notification?: String,
  notificationLevel?: String,
  link: String,
};

export default class AlertsManagerHeader extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    severity: null,
    isNotification: true,
    notification: null,
    notificationLevel: null,
  };

  render() {
    const {severity, title, owner, notification, notificationLevel, isNotification, link} = this.props;

    return (
      <div styleName={['table-row', !isNotification ? 'no-notification' : ''].join(' ')}>
        <div styleName="col-title">
          {severity && (
            <span styleName="severity-wrapper">
              <SeverityMarker severity={severity} />
            </span>
          )}
          <div styleName="title">{title}</div>
        </div>
        <div styleName="col-owner">{owner}</div>
        {isNotification && (
          <div styleName="col-notification">
            <WarningSign notification={notification} warningLevel={notificationLevel} />
          </div>
        )}
        <div styleName="col-link">
          <a href={link} target="_blank" rel="noopener noreferrer">
            <div className="icon icn-nav16-goto" />
          </a>
        </div>
      </div>
    );
  }
}
