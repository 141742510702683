import errorHandler from 'common/utils/notifications/errorHandler';

const userEventsErrorCodes = {
  11014: {
    title: 'Process Failed',
    description: 'Could not retrieve image from URL, verify the URL is valid and you have access to the image',
    settings: {
      // optional
      autoDismiss: 5, // default is 5 sec
      position: 'bl', // default is bottom-left(bl)
    },
  },
};

export default errorHandler(userEventsErrorCodes);
