// @flow
import React, {useCallback, Fragment, useState} from 'react';
import {useRouteMatch, useHistory} from 'react-router-dom';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import DashboardSettingsModal from 'dashboards/components/modals/DashboardSettingsModal';
import DashboardDeleteModal from 'dashboards/components/modals/DashboardDeleteModal';
import DashboardsManagement from 'dashboards/pages/DashboardManagement';
import Dashboard from 'dashboards/pages/Dashboard';
import ConversionModal from 'dashboards/components/modals/ConversionModal';
import RevertModal from 'dashboards/components/modals/RevertModal';
import ImportModal from 'dashboards/components/modals/ImportModal';

const dashboardSettingsTypes = [OPTION_TILES_VALUES.NEW, OPTION_TILES_VALUES.SETTINGS, OPTION_TILES_VALUES.DUPLICATE];

const DashboardsRouter = () => {
  const history = useHistory();
  const [keyD, setKeyD] = useState(0);
  const {
    params: {param1, param2},
  } = useRouteMatch('/dashboards/:param1?/:param2?');

  const handleReset = useCallback(() => {
    setKeyD(keyD + 1);
  }, [keyD]);

  const isDashboardManagementModal = Object.values(OPTION_TILES_VALUES).includes(param1);

  const modalType = isDashboardManagementModal ? param1 : param2;
  const id = isDashboardManagementModal ? param2 : param1;

  const onClose = useCallback(
    () => history.push(isDashboardManagementModal ? '/dashboards' : `/dashboards/${id}${history.location.search}`),
    [isDashboardManagementModal, id],
  );

  const onCloseDelete = () => history.push(`/dashboards${history.location.search}`);

  let modalElement = null;

  if (modalType === OPTION_TILES_VALUES.DELETE) {
    modalElement = <DashboardDeleteModal onClose={onCloseDelete} dashboardId={id} />;
  } else if (modalType === OPTION_TILES_VALUES.CONVERT) {
    modalElement = <ConversionModal onClose={onClose} dashboardId={id} />;
  } else if (modalType === OPTION_TILES_VALUES.CREATE_TYPE) {
    modalElement = <ConversionModal onClose={onClose} dashboardId={id} isCreate />;
  } else if (modalType === OPTION_TILES_VALUES.REVERT) {
    modalElement = <RevertModal onClose={onClose} dashboardId={id} />;
  } else if (modalType === OPTION_TILES_VALUES.IMPORT) {
    modalElement = <ImportModal onClose={onClose} />;
  } else if (dashboardSettingsTypes.includes(modalType)) {
    modalElement = (
      <DashboardSettingsModal
        onClose={onClose}
        dashboardId={id}
        type={modalType}
        isDashboardManagementModal={isDashboardManagementModal}
      />
    );
  }

  return (
    <Fragment>
      {isDashboardManagementModal || !param1 ? (
        <DashboardsManagement />
      ) : (
        <Dashboard key={keyD} onReset={handleReset} />
      )}
      {modalElement}
    </Fragment>
  );
};

export default DashboardsRouter;
