// @flow
import React from 'react';
import {debounce} from 'lodash';
import './ConveyorBelt.module.scss';

type PropTypes = {
  children: any,
  itemWidth: number,
  itemHeight: number,
};

const EMPTY_ARRAY = [];

export default class ConveyorBelt extends React.PureComponent {
  props: PropTypes;

  state = {
    nextDisabled: false,
    prevDisabled: true,
    hideHandles: true,
  };

  componentDidMount() {
    this.movingRailElm.addEventListener('scroll', this.debouncedMovingRailScroll);
    window.addEventListener('resize', this.debouncedWindowResize);
    this.windowResize();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.children.length !== this.props.children.length) {
      this.windowResize();
    }
  }

  componentWillUnmount() {
    this.movingRailElm.removeEventListener('scroll', this.debouncedMovingRailScroll);
    window.removeEventListener('resize', this.debouncedWindowResize);
  }

  rootStyle = EMPTY_ARRAY;

  nextBtnStyle = EMPTY_ARRAY;

  prevBtnStyle = EMPTY_ARRAY;

  setMovingRailRef = (el) => {
    this.movingRailElm = el;
  };

  movingRailScroll = () => {
    const maxRight = this.props.itemWidth * this.props.children.length + 20 * (this.props.children.length - 1);
    const {scrollLeft} = this.movingRailElm;

    this.setState((prevState) => {
      const nextState = {};

      if (scrollLeft === 0) {
        if (!prevState.prevDisabled) {
          nextState.prevDisabled = true;
        }
        if (prevState.nextDisabled) {
          nextState.nextDisabled = false;
        }
      } else if (scrollLeft === maxRight - this.movingRailElm.clientWidth) {
        if (prevState.prevDisabled) {
          nextState.prevDisabled = false;
        }
        if (!prevState.nextDisabled) {
          nextState.nextDisabled = true;
        }
      } else {
        if (prevState.prevDisabled) {
          nextState.prevDisabled = false;
        }
        if (prevState.nextDisabled) {
          nextState.nextDisabled = false;
        }
      }

      return nextState;
    });
  };

  debouncedMovingRailScroll = debounce(this.movingRailScroll, 100);

  windowResize = () => {
    const maxRight = this.props.itemWidth * this.props.children.length + 20 * (this.props.children.length - 1);

    this.setState((prevState) => {
      const nextState = {};

      if (maxRight > this.movingRailElm.clientWidth) {
        if (prevState.hideHandles) {
          nextState.hideHandles = false;
        }
      } else if (!prevState.hideHandles) {
        nextState.hideHandles = true;
      }

      return nextState;
    });
  };

  debouncedWindowResize = debounce(this.windowResize, 100);

  nextButtonClicked = () => {
    this.movingRailElm.scrollTo({
      left: this.movingRailElm.scrollLeft + this.props.itemWidth + 20,
      behavior: 'smooth',
    });
  };

  prevButtonClicked = () => {
    this.movingRailElm.scrollTo({
      left: this.movingRailElm.scrollLeft - (this.props.itemWidth + 20),
      behavior: 'smooth',
    });
  };

  render() {
    const {children, itemHeight} = this.props;
    const {prevDisabled, nextDisabled, hideHandles} = this.state;

    this.rootStyle = ['conveyor-belt-root', hideHandles ? 'hide-handles' : ''];
    this.prevBtnStyle = ['button-area', prevDisabled ? 'disabled' : ''];
    this.nextBtnStyle = ['button-area', 'right-button-area', nextDisabled ? 'disabled' : ''];

    return (
      <div styleName={this.rootStyle.join(' ')}>
        <div styleName={this.prevBtnStyle.join(' ')}>
          <div styleName="arrow-button" onClick={this.prevButtonClicked}>
            <i className="icn-arrow16-arrowleft" />
          </div>
        </div>
        <div styleName="group-items-wrapper" style={{height: `${itemHeight}px`}}>
          <div styleName="moving-rail" ref={this.setMovingRailRef} style={{height: `${itemHeight + 20}px`}}>
            {children.map((child, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`child-${index}`} styleName="group-item" className={`child-${index}`}>
                {child}
              </div>
            ))}
          </div>
        </div>
        <div styleName={this.nextBtnStyle.join(' ')}>
          <div styleName="arrow-button" onClick={this.nextButtonClicked}>
            <i className="icn-arrow16-arrowright" />
          </div>
        </div>
      </div>
    );
  }
}
