import React, {useState, useMemo, useRef, useCallback} from 'react';
import Button, {COLORS} from 'common/componentsV2/Button';
import {Box, makeStyles, fade} from '@material-ui/core';
import useClickOutside from 'common/hooks/useClickOutside';
import DatePicker from './DatePicker';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 300,
    borderRadius: 6,
    boxShadow: '0 4px 12px -2px rgba(0, 0, 36, 0.25)',
    zIndex: 3,
    backgroundColor: theme.palette.white[500],
    padding: 4,
    position: 'absolute',
    left: 0,
    top: 21,
  },
  button: {
    cursor: 'pointer',
    padding: '0 5px',
    lineHeight: 1.25,
    display: 'flex',
    alignItems: 'center',
    borderRadius: '6px',
    backgroundColor: fade(theme.palette.blue[500], 0.1),
    fontSize: '16px',
    color: theme.palette.gray[500],
  },
  icon: {
    paddingLeft: '4px',
    color: 'rgba(38, 113, 255, 0.5)',
  },
  arrowRight: {
    position: 'absolute',
    cursor: 'pointer',
    right: 16,
    top: 0,
  },
  arrowLeft: {
    position: 'absolute',
    cursor: 'pointer',
    left: 16,
    top: 0,
  },
  buttonsWrapper: {
    marginTop: '30px',
    paddingBottom: '16px',
    width: '260px',
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
  reset: {
    cursor: 'pointer',
    fontSize: '12px',
    fontWeight: 500,
    color: theme.palette.gray[400],
  },
}));

const SingleDatePicker = ({
  value,
  appliedValue,
  onChange,
  onApply,
}: {
  value: number,
  appliedValue: number,
  onChange: Function,
  onApply: Function,
}) => {
  const [viewDate, setViewDate] = useState(value);

  const currentMonth = useMemo(() => new Date(viewDate).getMonth(), [viewDate]);
  const currentYear = useMemo(() => new Date(viewDate).getFullYear(), [viewDate]);

  const isDateApplied = new Date(appliedValue).setHours(0, 0, 0, 0) === new Date(value).setHours(0, 0, 0, 0);
  const isToday = new Date(appliedValue).setHours(0, 0, 0, 0) === new Date().setHours(0, 0, 0, 0);

  const formattedValue = useMemo(() => {
    if (isToday) {
      return 'Today';
    }
    return new Date(appliedValue)
      .toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
      .replace(' ', '. ');
  }, [appliedValue, isToday]);

  const setCurrentMonth = useCallback(
    (month) => {
      const updatedDate = new Date(viewDate);
      updatedDate.setMonth(month);
      setViewDate(updatedDate.getTime());
    },
    [setViewDate, viewDate],
  );

  const setCurrentYear = useCallback(
    (year) => {
      const updatedDate = new Date(viewDate);
      updatedDate.setFullYear(year);
      setViewDate(appliedValue);
    },
    [appliedValue, setViewDate, viewDate],
  );

  const rangeValue = useMemo(() => ({startDate: value}), [value]);

  const dropdownRef = useRef();
  const [isDropdownOpened, setDropdownOpened] = useState(false);
  useClickOutside(
    dropdownRef,
    () => {
      setViewDate(appliedValue);
      setDropdownOpened(false);
    },
    ['idle-external-click'],
    ['idle-external-click', 'andt-dropdown-menu-list-virtualize'],
  );

  const resetToToday = useCallback(() => {
    const now = new Date().getTime();
    onChange(now);
    if (!isToday) {
      onApply(now);
    }
    setViewDate(now);
    setDropdownOpened(false);
  }, [isToday, setViewDate, onChange, onApply, setDropdownOpened]);

  const onCancel = useCallback(() => {
    setViewDate(new Date().getTime());
    setDropdownOpened(false);
  }, [setDropdownOpened]);

  const onApplyLocal = useCallback(() => {
    onApply(value);
    setDropdownOpened(false);
  }, [value, onApply, setDropdownOpened]);

  const classes = useStyles();

  return (
    <Box position="relative" className="idle-external-click">
      <div className={classes.button} onClick={() => setDropdownOpened(true)}>
        {formattedValue}
        <i className={`icon icn-arrow16-triangledown ${classes.icon}`} />
      </div>
      {isDropdownOpened && (
        <Box ref={dropdownRef} className={classes.wrapper}>
          <Box position="relative" display="flex" flexDirection="column" alignItems="center" mt={2} width={1}>
            <Box className={classes.arrowRight} onClick={() => setCurrentMonth(currentMonth + 1)}>
              <i className="icon icn-arrow16-chevronright" />
            </Box>
            <Box className={classes.arrowLeft} onClick={() => setCurrentMonth(currentMonth - 1)}>
              <i className="icon icn-arrow16-chevronleft" />
            </Box>
            <DatePicker
              value={rangeValue}
              onChange={onChange}
              currentMonth={currentMonth}
              setCurrentMonth={setCurrentMonth}
              currentYear={currentYear}
              setCurrentYear={setCurrentYear}
            />
            <div className={classes.buttonsWrapper}>
              <div className={classes.reset} onClick={resetToToday}>
                Reset To Today
              </div>
              <div className="display_flex">
                <div className="mr_1-5">
                  <Button text="Cancel" colorSchema={COLORS.GRAY_400} onClick={onCancel} />
                </div>
                <Button isDisabled={isDateApplied} text="Apply" colorSchema={COLORS.BLUE_500} onClick={onApplyLocal} />
              </div>
            </div>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SingleDatePicker;
