import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const DEFAULT_STATE = {
  issues: null,
  specialIssue: null,
  assets: EMPTY_ARRAY,
};

const leftPanelReducer = composeReducers((state = DEFAULT_STATE, {type, payload}) => {
  switch (type) {
    case actions.getTopologyAlerts.TYPE:
    case actions.getTopologyAnomalies.TYPE: {
      return {...state, issues: null};
    }

    case actions.setIssuesList.TYPE: {
      return {...state, issues: payload};
    }

    case actions.setIntoLeftPanelView.TYPE: {
      return {...state, ...payload};
    }

    case actions.clearSpecialIssue.TYPE: {
      return {...state, specialIssue: null};
    }

    default:
      return state;
  }
});

export default leftPanelReducer;
