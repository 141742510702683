import React from 'react';
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  gradient: {
    width: '100%',
    zIndex: 1,
    height: '12px',
    position: 'absolute',
    backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255,255,255))',
  },
}));

const Gradient = () => {
  const styles = useStyles();
  return <div className={styles.gradient} />;
};

export default Gradient;
