// @flow
import React from 'react';
import {TypographyBox} from 'common/componentsV2/boxTools';

type PropTypes = {
  measure: String,
  css: Object,
};

const Measure = ({measure, css = {}}: PropTypes) => (
  <TypographyBox
    component="span"
    automation-id="impactMeasure"
    key={measure}
    variant="subtitle2"
    bgcolor="rgba(104,38,171,0.2)"
    borderRadius={4}
    color="purple.500"
    maxWidth="90%"
    css={{
      padding: '6px 8px',
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      ...css,
    }}
  >
    {measure}
  </TypographyBox>
);

export default Measure;
