import {createSelector} from 'reselect';
import {getAlertConfigurationsItems} from 'alerts.management/store/selectors';

const mergeActionInUsed = (alerts, actions) => {
  const counter = {};
  alerts.forEach((alert) => {
    if (alert.data.alertActions && alert.data.alertActions.length) {
      alert.data.alertActions.forEach((actionId) => {
        if (counter[actionId]) {
          counter[actionId] += 1;
        } else {
          counter[actionId] = 1;
        }
      });
    }
  });

  return actions.map((action) => {
    if (counter[action.id]) {
      return {
        ...action,
        usedInAlerts: counter[action.id],
      };
    }
    return {
      ...action,
      usedInAlerts: 0,
    };
  });
};

// *** Data ***
const getRoot = (state) => state.alertActions;

const getData = createSelector(
  getRoot,
  (root) => root.data,
);

const getView = createSelector(
  getRoot,
  (root) => root.view,
);

export const getAllAlertActions = createSelector(
  getData,
  (data) => data.allAlertActions,
);

export const getAllAlertActionsData = createSelector(
  getAllAlertActions,
  getAlertConfigurationsItems,
  (allAlertActions, alerts) => mergeActionInUsed(alerts, allAlertActions.data),
);

export const getAllAlertActionsIsLoading = createSelector(
  getAllAlertActions,
  (allAlertActions) => allAlertActions.isLoading,
);

export const getAllAlertActionsUpdateAt = createSelector(
  getAllAlertActions,
  (allAlertActions) => allAlertActions.updateAt,
);

export const getCreateAction = createSelector(
  getData,
  (data) => data.createAction,
);

export const getCreateActionData = createSelector(
  getCreateAction,
  (createAction) => createAction.data,
);

export const getCreateActionIsLoading = createSelector(
  getCreateAction,
  (createAction) => createAction.isLoading,
);

export const getUpdateAction = createSelector(
  getData,
  (data) => data.updateAction,
);

export const getUpdateActionData = createSelector(
  getUpdateAction,
  (updateAction) => updateAction.data,
);

export const getUpdateActionIsLoading = createSelector(
  getUpdateAction,
  (updateAction) => updateAction.isLoading,
);

export const getDeleteAction = createSelector(
  getData,
  (data) => data.deleteAction,
);

export const getDeleteActionData = createSelector(
  getDeleteAction,
  (deleteAction) => deleteAction.data,
);

export const getDeleteActionIsLoading = createSelector(
  getDeleteAction,
  (deleteAction) => deleteAction.isLoading,
);

// ** View **

export const getAlertActionsView = createSelector(
  getView,
  (view) => view.alertActions,
);
