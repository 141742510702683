// flow
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import * as selectors from 'insightsPanel/store/selectors';
import * as actions from 'insightsPanel/store/actions';
import ColumnChart from 'charts/columnChart/ColumnChart';
import {shouldFetchData, fadeOpacity} from 'insightsPanel/services/constants';
import './AlertsTriggered.module.scss';
import moment from 'moment';

type PropTypes = {
  title: String,
};

const AlertsTriggered = (props: PropTypes) => {
  const alertsTriggered = useSelector(selectors.getAlertsTriggered);
  const alertsTriggeredData = useSelector(selectors.getAlertsTriggeredData);
  const isLoading = useSelector(selectors.getIsLoadingAlertsTriggered);
  const isPanelOpen = useSelector(selectors.getIsPanelOpen);
  const numLastDays = useSelector(selectors.getNumLastDays);
  const dispatch = useDispatch();
  const [showData, setShowData] = useState(false);
  const isFirstRun = useRef(true);
  let timer;

  useEffect(() => {
    const params = {
      numLastDays: numLastDays.value,
    };
    if (isPanelOpen && shouldFetchData(alertsTriggered)) {
      dispatch(actions.fetchAlertsTriggered(params));
    }
  }, [isPanelOpen]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const params = {
      numLastDays: numLastDays.value,
    };
    dispatch(actions.fetchAlertsTriggered(params));
  }, [numLastDays]);

  useEffect(() => {
    if (alertsTriggeredData) {
      // for some reason Transition duration does not work.
      timer = setTimeout(() => setShowData(true), fadeOpacity.duration);
    }
  }, [alertsTriggeredData]);

  useEffect(
    () => () => {
      clearTimeout(timer);
    },
    [isPanelOpen],
  );

  const columnTooltipFormatter = {
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      return `<div class="column-chart-tooltip">${this.y} Alerts Triggered<br/> ${moment
        // eslint-disable-next-line react/no-this-in-sfc
        .unix(Object.keys(alertsTriggeredData)[this.point.index])
        .format('MMM, D')}</div>`;
    },
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && alertsTriggeredData && (
        <Transition in={showData} timeout={0}>
          {(state) => (
            <Fragment>
              <header styleName="header" style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}>
                {props.title}
              </header>
              <div styleName="container" style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state]}}>
                <ColumnChart
                  id="insightsPanelAlertsTriggered"
                  height={90}
                  width="100%"
                  data={alertsTriggeredData}
                  tooltipFormatter={columnTooltipFormatter}
                />
              </div>
            </Fragment>
          )}
        </Transition>
      )}
    </div>
  );
};

export default AlertsTriggered;
