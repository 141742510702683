import * as moment from 'moment';
import {isEqual, isObject, transform} from 'lodash';

/**
 * Perform a visit on each tree node
 * @param root the root node
 * @param childPropertyName the name of the children property
 * @param a callback for each node visited
 */
export const treeVisitor = (root, childPropertyName, callback, fatherPropertyName = null) => {
  const treeVisitorFunc = function(rootInner, childPropertyNameInner, callbackInner, level) {
    callbackInner(rootInner, level);
    if (rootInner[childPropertyNameInner] && rootInner[childPropertyNameInner].length) {
      rootInner[childPropertyNameInner].forEach((node) => {
        treeVisitorFunc(
          fatherPropertyName && node[fatherPropertyName] ? node[fatherPropertyName] : node,
          childPropertyNameInner,
          callbackInner,
          level + 1,
        );
      });
    }
  };

  treeVisitorFunc(root, childPropertyName, callback, 0);
};

export const cleanHash = (hash, onlyFirstName) => {
  if (!hash) {
    return 'undefined';
  }
  const tmpObj = {};
  const index = hash.indexOf('?');

  if (index > 0) {
    tmpObj['?'] = index;
  }
  const name = tmpObj['?'] ? hash.slice(0, tmpObj['?']) : hash;
  const cleanName = name.replace('#!/r/', '').replace('#!/', '');

  if (!onlyFirstName) {
    return !cleanName.endsWith('/') ? cleanName : cleanName.slice(0, -1);
  }
  const cleanNameIndex = cleanName.indexOf('/') === -1 ? cleanName.length : cleanName.indexOf('/');
  return cleanName.slice(0, cleanNameIndex);
};

export const humanizeHourOfTheDay = () => {
  const m = moment();

  if (!m || !m.isValid()) {
    return null;
  }

  const splitAfternoon = 12; // 24hr time to split the afternoon
  const splitEvening = 17; // 24hr time to split the evening
  const currentHour = parseFloat(m.format('HH'));

  if (currentHour >= splitAfternoon && currentHour <= splitEvening) {
    return 'afternoon';
  }
  if (currentHour >= splitEvening) {
    return 'evening';
  }
  return 'morning';
};

// number -> number
// x -> thousands count
// s -> decimal divider
// c -> thousands divider
// d -> decimal count
export const format = function(number, x, s, c, d = 0) {
  const num = Number(number).toFixed(d);
  const re = `\\d(?=(\\d{${x || 3}})+${num.indexOf(c) !== -1 ? '\\D' : '$'})`;
  return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), `$&${s || ','}`);
};

// return {number: number, posftix: string}
export const Humanize = {
  humanize(value, decimal = 0) {
    const mag = this.magnitude(value);
    const strValue = value.toString();
    if (mag <= 3) {
      return {
        number: value,
        postfix: '',
      };
    }

    if (mag > 3 && mag <= 6) {
      const magLength = mag - 3;
      return {
        number: Number(`${strValue.substr(0, magLength)}.${strValue.substr(magLength, decimal)}`),
        postfix: 'K',
      };
    }

    if (mag > 6 && mag <= 9) {
      const magLength = mag - 6;
      return {
        number: Number(`${strValue.substr(0, magLength)}.${strValue.substr(magLength, decimal)}`),
        postfix: 'M',
      };
    }

    if (mag > 9 && mag <= 12) {
      const magLength = mag - 9;
      return {
        number: Number(`${strValue.substr(0, magLength)}.${strValue.substr(magLength, decimal)}`),
        postfix: 'B',
      };
    }

    if (mag > 12 && mag <= 15) {
      const magLength = mag - 9;
      return {
        number: Number(`${strValue.substr(0, magLength)}.${strValue.substr(magLength, decimal)}`),
        postfix: 'T',
      };
    }

    return {
      number: value,
      postfix: '',
    };
  },

  magnitude(value) {
    let mag = 0;
    while (value > 1) {
      // eslint-disable-next-line no-plusplus
      mag++;
      // eslint-disable-next-line no-param-reassign
      value /= 10;
    }

    return mag;
  },
};

export const getMonths = (short = false) => {
  const formatMonth = short ? 'short' : 'long';
  return Array(12)
    .fill('')
    .map((_, mon) => new Date(2000, mon).toLocaleString({}, {month: formatMonth}));
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export const difference = (object, base) => {
  // eslint-disable-next-line no-shadow
  const changes = (object, base) => {
    return transform(object, (result, value, key) => {
      if (!isEqual(value, base[key])) {
        // eslint-disable-next-line no-param-reassign
        result[key] = isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
      }
    });
  };
  return changes(object, base);
};
