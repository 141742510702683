import {combineEpics} from 'redux-observable';
import {Observable} from 'rxjs/Observable';
import {get as _get} from 'lodash';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';

import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

// ***accounts
const fetchGaAccounts = makeAsyncEpic(actions.fetchGoogleAnalyticsAccounts, api.fetchGaAccounts);
const fetchCubes = makeAsyncEpic(actions.fetchCubes, api.fetchCubes);
const fetchMetadata = makeAsyncEpic(actions.fetchMetadata, api.fetchMetadata);

const setDefaultGaAccount = (action$, {getState}) =>
  action$
    .ofType(actions.fetchGoogleAnalyticsAccounts.success.TYPE)
    .filter(
      (action) =>
        _get(action, 'payload[0].id') &&
        selectors.getSelectedDataStream(getState()) &&
        !selectors.getGoogleAnalyticsSelectedAccount(getState()),
    )
    .flatMap((action) => [actions.setSelectedStreamGaAccount({accountId: _get(action, 'payload[0].id')})]);

const setGaAccount = (action$, {getState}) =>
  action$.ofType(actions.setSelectedStreamGaAccount.TYPE).flatMap((action) => [
    actions.fetchGoogleAnalyticsProperties({
      dataSourceId: selectors.getSelectedDataStream(getState()).dataSourceId,
      ...action.payload,
    }),
    actions.setSelectedStreamGaProperty({propertyId: null}),
  ]);

// ***properties
const fetchGaProperties = makeAsyncEpic(actions.fetchGoogleAnalyticsProperties, api.fetchGaProperties);

const setDefaultGaProperty = (action$, {getState}) =>
  action$
    .ofType(actions.fetchGoogleAnalyticsProperties.success.TYPE)
    .filter(
      (action) =>
        _get(action, 'payload[0].id') &&
        selectors.getSelectedDataStream(getState()) &&
        !selectors.getGoogleAnalyticsSelectedProperty(getState()),
    )
    .flatMap((action) => [actions.setSelectedStreamGaProperty({propertyId: _get(action, 'payload[0].id')})]);

const setGaProperty = (action$, {getState}) =>
  action$.ofType(actions.setSelectedStreamGaProperty.TYPE).flatMap((action) => [
    actions.fetchGoogleAnalyticsViews({
      dataSourceId: selectors.getSelectedDataStream(getState()).dataSourceId,
      accountId: selectors.getSelectedDataStream(getState()).accountId,
      ...action.payload,
    }),
    actions.setSelectedStreamGaView({viewId: null}),
  ]);

// ***views
const fetchGaViews = (action$) =>
  action$
    .ofType(actions.fetchGoogleAnalyticsViews.TYPE)
    .filter((action) => action.payload.accountId && action.payload.propertyId)
    .switchMap((action) => api.fetchGaViews(action.payload))
    .map((payload) => actions.fetchGoogleAnalyticsViews.success(payload))
    .catch((error) => Observable.of(actions.fetchGoogleAnalyticsViews.failure(error)));

const setDefaultGaView = (action$, {getState}) =>
  action$
    .ofType(actions.fetchGoogleAnalyticsViews.success.TYPE)
    .filter(
      (action) =>
        _get(action, 'payload[0].id') &&
        selectors.getSelectedDataStream(getState()) &&
        !selectors.getGoogleAnalyticsSelectedView(getState()),
    )
    .flatMap((action) => [actions.setSelectedStreamGaView({viewId: _get(action, 'payload[0].id')})]);

const getGaAccountsViewsProperties = (action$) =>
  action$.ofType(actions.getGoogleAnalyticsAccountsViewsProperties.TYPE).flatMap((action) => {
    const res = [];
    res.push(actions.fetchGoogleAnalyticsAccounts(action.payload.dataSourceId));
    if (action.payload.accountId) {
      res.push(
        actions.fetchGoogleAnalyticsProperties({
          dataSourceId: action.payload.dataSourceId,
          accountId: action.payload.accountId,
        }),
      );

      if (action.payload.propertyId) {
        res.push(actions.fetchGoogleAnalyticsViews({...action.payload}));
      }
    }
    return res;
  });

// ***segments
const fetchGaSegments = makeAsyncEpic(actions.fetchGaSegments, api.fetchGaSegments);

// ***templates
const fetchStreamTemplates = makeAsyncEpic(actions.fetchStreamTemplates, api.fetchStreamTemplates);
const setDefaultGaTemplate = (action$, {getState}) =>
  action$
    .ofType(actions.fetchStreamTemplates.success.TYPE)
    .filter(
      (action) =>
        _get(action, 'payload[0].id') &&
        selectors.getSelectedDataStream(getState()) &&
        !selectors.getGoogleAnalyticsQuerySelectedTemplate(getState()) &&
        !selectors.getSelectedDataStream(getState()).dimensions.length &&
        !selectors.getSelectedDataStream(getState()).metrics.length,
    )
    .flatMap(() => [actions.applyTemplateOnSelectedStream('1')]);

const saveNewTemplate = (action$) =>
  action$
    .ofType(actions.createStreamTemplate.TYPE)
    .switchMap((action) => api.createStreamTemplate(action.payload))
    .flatMap((payload) => [
      actions.createStreamTemplate.success(payload),
      actions.setSelectedStreamKeyVal({basedOnTemplateId: payload.id}),
    ])
    .catch((error) => Observable.of(actions.createStreamTemplate.failure(error)));

const applyGaTemplateToStream = (action$, {getState}) =>
  action$.ofType(actions.applyTemplateOnSelectedStream.TYPE).map((action) => {
    const template = selectors.getGoogleAnalyticsTemplatesItems(getState()).find((t) => t.id === action.payload);
    if (selectors.getSelectedDataStream(getState()).dimensions.includes('ga:segment')) {
      template.dimensions.push('ga:segment');
    }
    return actions.setSelectedStreamMetricsAndDimensions({
      dimensions: template.dimensions,
      metrics: template.metrics,
    });
  });

const googleAnalyticsEpic = combineEpics(
  fetchGaAccounts,
  fetchCubes,
  fetchMetadata,
  setGaAccount,
  setDefaultGaAccount,
  fetchGaProperties,
  setGaProperty,
  setDefaultGaProperty,
  fetchGaViews,
  setDefaultGaView,
  getGaAccountsViewsProperties,
  fetchStreamTemplates,
  fetchGaSegments,
  saveNewTemplate,
  applyGaTemplateToStream,
  setDefaultGaTemplate,
);
export default googleAnalyticsEpic;
