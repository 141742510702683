// @flow
import React from 'react';
import './StreamEditorSteps.module.scss';

type PropTypes = {children: any};

export default class StreamEditorSteps extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <div styleName="root" automation-id="streamTableSteps">
        {this.props.children}
      </div>
    );
  }
}
