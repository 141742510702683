// @flow
import Highlighter from 'react-highlight-words';
import React, {PureComponent} from 'react';
import LabelAndValueCol from 'common/componentsV2/LabelAndValueCol';
import './TableRowInfo.module.scss';

type PropTypes = {
  searchString: string,
  pr: Object,
};

export default class TableRowInfo extends PureComponent {
  props: PropTypes;

  state = {};

  render() {
    const {pr, searchString} = this.props;

    return (
      <div styleName="root">
        <div styleName="label-wrapper">
          <LabelAndValueCol title="Title">
            <Highlighter autoEscape searchWords={[searchString]} textToHighlight={pr.title} />
          </LabelAndValueCol>
        </div>

        <div styleName="label-wrapper label-description">
          <LabelAndValueCol title="Description">
            <Highlighter autoEscape searchWords={[searchString]} textToHighlight={pr.description} />
          </LabelAndValueCol>
        </div>

        <div styleName="col-container">
          <div styleName="col">
            <div styleName="label-wrapper">
              <LabelAndValueCol title="Link to BitBucket">
                <a href={pr.links.html.href} target="_blank" rel="noopener noreferrer">
                  Pull Request
                </a>
              </LabelAndValueCol>
            </div>

            <div styleName="label-wrapper">
              <LabelAndValueCol title="Destination Branch">
                <Highlighter autoEscape searchWords={[searchString]} textToHighlight={pr.destination.branch.name} />
              </LabelAndValueCol>
            </div>

            <div styleName="label-wrapper">
              <LabelAndValueCol title="Source Branch">
                <Highlighter autoEscape searchWords={[searchString]} textToHighlight={pr.source.branch.name} />
              </LabelAndValueCol>
            </div>
          </div>

          <div styleName="col">
            <div styleName="label-wrapper">
              <LabelAndValueCol title="Created">{pr.created_on}</LabelAndValueCol>
            </div>

            <div styleName="label-wrapper">
              <LabelAndValueCol title="Updated">{pr.updated_on}</LabelAndValueCol>
            </div>

            <div styleName="label-wrapper">
              <LabelAndValueCol title="Author">
                <div className="shell">
                  <div>
                    <Highlighter autoEscape searchWords={[searchString]} textToHighlight={pr.author.display_name} />
                  </div>
                  <img src={pr.author.links.avatar.href} styleName="avatar" alt="avatar" />
                </div>
              </LabelAndValueCol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
