// @flow
import React from 'react';
import {Modal} from 'react-bootstrap';
import {
  DELIMITERS,
  ESCAPE_CHARACTERS,
  QUOTE_CHARACTERS,
  THOUSANDS_SEPARATORS,
  DECIMAL_POINTS,
} from 'bc/services/fuService';
import {isEqual, isNil} from 'lodash';
import ToggleButton from 'common/components/ToggleButton';
import SettingsDdl from './SettingsDdl';
import './FileSettings.module.scss';

const EMPTY_STRING = '';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,
  onChange: Function,
  fileFormat: Object,
  fileName: String,
};

class FileSettings extends React.PureComponent {
  // eslint-disable-next-line react/sort-comp
  DEFAULT_STATE = {
    updateObj: {...this.props.fileFormat},
    validationObj: {},
    isValid: true,
  };

  props: PropTypes;

  state = this.DEFAULT_STATE;

  handleClose = (isOk) => {
    if (isOk && this.state.hasUpdates) {
      this.props.onChange(this.state.updateObj);
    }
    this.props.onClose();
    this.setState(this.DEFAULT_STATE);
  };

  handlePropChange = (updateObj) => {
    this.setState((prevState) => {
      const newUpdateObj = {...prevState.updateObj, ...updateObj};
      return {
        updateObj: newUpdateObj,
        hasUpdates: !isEqual(newUpdateObj, this.props.fileFormat),
        isValid: this.isValid(newUpdateObj),
      };
    });
  };

  isValid = (updateObj) => {
    const {delimiter, quoteChar, escapeChar, decimalPointChar, thousandsSeparatorChar} = updateObj;
    const validationObj = {
      delimiter: EMPTY_STRING,
      quoteChar: EMPTY_STRING,
      escapeChar: EMPTY_STRING,
      decimalPointChar: EMPTY_STRING,
      thousandsSeparatorChar: EMPTY_STRING,
    };
    if (isNil(delimiter) || delimiter.length !== 1) {
      validationObj.delimiter = 'Delimiter is mandatory and should be one character length';
    }

    if (!isNil(quoteChar) && (quoteChar.length !== 1 || quoteChar === delimiter)) {
      validationObj.quoteChar = 'Quote Character differ from delimiter and quote character';
    }

    if (!isNil(escapeChar) && (escapeChar.length !== 1 || escapeChar === quoteChar || escapeChar === delimiter)) {
      validationObj.escapeChar = 'Escape Character should differ from delimiter and quote character';
    }

    if (
      !isNil(decimalPointChar) &&
      (decimalPointChar.length !== 1 ||
        decimalPointChar === escapeChar ||
        decimalPointChar === quoteChar ||
        (isNil(escapeChar) && isNil(quoteChar) && decimalPointChar === delimiter))
    ) {
      validationObj.decimalPointChar = 'Decimal point character should differ from delimiter and quote character';
    }

    if (
      !isNil(thousandsSeparatorChar) &&
      (thousandsSeparatorChar.length !== 1 ||
        thousandsSeparatorChar === escapeChar ||
        thousandsSeparatorChar === quoteChar ||
        thousandsSeparatorChar === decimalPointChar ||
        (isNil(escapeChar) && isNil(quoteChar) && thousandsSeparatorChar === delimiter))
    ) {
      validationObj.thousandsSeparatorChar = 'Escape character should differ from delimiter and quote character';
    }
    this.setState({validationObj});
    return !Object.values(validationObj).some((a) => a !== EMPTY_STRING);
  };

  render() {
    const {isOpen, onClose, fileName} = this.props;
    const {updateObj, validationObj} = this.state;
    return (
      <div>
        <Modal show={isOpen} dialogClassName="bc file-settings" automation-id="fileSettingsModal" onHide={onClose}>
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span>Parsing And Import Settings</span>
            </Modal.Title>
            <button
              type="button"
              className="btn btn-flat btn-icon-36 btn-secondary"
              onClick={() => this.handleClose(false)}
            >
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <div styleName="root">
              <div styleName="file-name">{fileName}</div>

              <div styleName="sub-title">
                Anodot data analyzer has detected the parsing parameters below.
                <br />
                To continue, exit this screen. Alternatively, set your own parameters and click Apply.
              </div>

              <div styleName="row">
                <div styleName="section">
                  <div automation-id="fileSettingsFileDelimiter">
                    <SettingsDdl
                      value={updateObj.delimiter}
                      collection={DELIMITERS}
                      onChange={this.handlePropChange}
                      onChangePropName="delimiter"
                      placeHolder="Choose file delimiter"
                      validationMessage={validationObj.delimiter}
                    />
                  </div>
                </div>

                <div styleName="section">
                  <div automation-id="fileSettingsEscapeCharacter">
                    <SettingsDdl
                      value={updateObj.escapeChar}
                      collection={ESCAPE_CHARACTERS}
                      onChange={this.handlePropChange}
                      onChangePropName="escapeChar"
                      placeHolder="Choose escape character"
                      validationMessage={validationObj.escapeChar}
                    />
                  </div>
                </div>
              </div>

              <div styleName="row">
                <div styleName="section">
                  <div automation-id="fileSettingsQuoteCharacter">
                    <SettingsDdl
                      value={updateObj.quoteChar}
                      collection={QUOTE_CHARACTERS}
                      onChange={this.handlePropChange}
                      onChangePropName="quoteChar"
                      placeHolder="Choose quote character"
                      validationMessage={validationObj.quoteChar}
                    />
                  </div>
                </div>

                <div styleName="section">
                  <div automation-id="fileSettingsDecimalSeparator">
                    <SettingsDdl
                      value={updateObj.decimalPointChar}
                      collection={DECIMAL_POINTS}
                      onChange={this.handlePropChange}
                      onChangePropName="decimalPointChar"
                      placeHolder="Choose decimal separator"
                      validationMessage={validationObj.decimalPointChar}
                    />
                  </div>
                </div>
              </div>

              <div styleName="row">
                <div styleName="section">
                  <div automation-id="fileSettingsThousandsSeparator">
                    <SettingsDdl
                      value={updateObj.thousandsSeparatorChar}
                      collection={THOUSANDS_SEPARATORS}
                      onChange={this.handlePropChange}
                      onChangePropName="thousandsSeparatorChar"
                      placeHolder="Choose thousands separator"
                      validationMessage={validationObj.thousandsSeparatorChar}
                    />
                  </div>
                </div>

                <div styleName="section">
                  <div automation-id="fileSettingsHasHeaderButton">
                    <ToggleButton
                      value={updateObj.hasHeader}
                      text="Has header row"
                      onChangePropName="hasHeader"
                      onChange={this.handlePropChange}
                    />
                  </div>
                </div>
              </div>

              <div styleName="row">
                <div styleName="section">
                  <div automation-id="fileSettingsIgnoeEmptyButton">
                    <ToggleButton
                      value={updateObj.ignoreEmptyLines}
                      text="Ignore empty lines"
                      onChangePropName="ignoreEmptyLines"
                      onChange={this.handlePropChange}
                    />
                  </div>
                </div>

                <div styleName="section">
                  <div className="shell-col" automation-id="fileSettingsReplaceEmptyButton">
                    <ToggleButton
                      value={updateObj.shouldReplaceEmptyCells}
                      text="Replace empty cells"
                      onChangePropName="shouldReplaceEmptyCells"
                      onChange={this.handlePropChange}
                    />
                    <div styleName="toggle-info">Fill with &quot;unknown&quot;</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer styleName="footer">
            <button
              type="button"
              className="btn btn-flat btn-primary"
              disabled={!this.state.hasUpdates || !this.state.isValid}
              onClick={() => this.handleClose(true)}
            >
              APPLY
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default FileSettings;
