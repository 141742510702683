import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';

import * as actions from '../actions';
import * as api from '../../services/api';

const fetchGoogleAuctionsEmail = makeAsyncEpic(actions.fetchGoogleAuctionsEmail, api.fetchGoogleAuctionsEmail);
const fetchGoogleAuctionsTemplates = makeAsyncEpic(actions.fetchGoogleAuctionsTemplates, api.fetchStreamTemplates);

const fetchGoogleAuctionsStreamAnalysis = makeAsyncEpic(
  actions.fetchGoogleAuctionsStreamAnalysis,
  api.fetchGoogleAuctionsStreamAnalysis,
);

const fetchGoogleAuctionsStreamAnalysisSuccess = (action$) =>
  action$
    .ofType(actions.fetchGoogleAuctionsStreamAnalysis.success.TYPE)
    .flatMap((action) => [actions.setGoogleAuctionsDiametricsStreamAnalysisSchema(action.payload)]);

const googleAuctionsEpic = combineEpics(
  fetchGoogleAuctionsEmail,
  fetchGoogleAuctionsStreamAnalysis,
  fetchGoogleAuctionsTemplates,
  fetchGoogleAuctionsStreamAnalysisSuccess,
);

export default googleAuctionsEpic;
