import React, {PureComponent} from 'react';
import {components} from 'react-select2';

type PropTypes = {
  // accepted values by the original Select
  children: any,
  data: Object,
};

export default class MultiValueLabel extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <components.MultiValueLabel className="andt-dropdown-multivalue-label" {...this.props}>
        <div className={`ico icon ${this.props.data.icon}`} />
        <span automation-id="dropdown-chip">{this.props.children}</span>
      </components.MultiValueLabel>
    );
  }
}
