// @flow
import React from 'react';
import './HexagonalButton.module.scss';

type PropTypes = {
  onClick: Function,
  imgSrc: String,
  eventKey: String,
};

// eslint-disable-next-line import/prefer-default-export
export const HexagonalButton = (props: PropTypes) => {
  const patId = `image_${props.eventKey}`;
  const patUrl = `url("#image_${props.eventKey}")`;
  const locClick = () => props.onClick(props.eventKey);

  return (
    <svg styleName="root" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" version="1.1">
      <defs>
        <pattern id={patId} x="0" y="0" height="100" width="100" patternUnits="userSpaceOnUse">
          <image href={props.imgSrc} />
        </pattern>
      </defs>

      <polygon styleName="image-shadow" points="50,2 5,20 5,80 50,98 95,80 95,20" />
      <polygon styleName="image" points="50,2 5,20 5,80 50,98 95,80 95,20" fill={patUrl} onClick={locClick} />
    </svg>
  );
};
