import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {success, error} from 'common/utils/notifications/notificationsService';
import * as actions from 'assets/store/actions';
import {updateUserSettings} from 'profile/store/actions';
import {createLookupTable, deleteLookupTable} from 'bc/store/actions';
import * as selectors from 'profile/store/selectors';
import * as api from 'assets/services/api';
import {fileUploadErrorHandler} from 'assets/services/errorCodes';

const getDynamicRoutingFiles = makeAsyncEpic(actions.getDynamicRoutingFiles, api.getDynamicRoutingFiles);
const uploadDynamicRoutingFile = makeAsyncEpic(actions.uploadDynamicRoutingFile, api.uploadDynamicRoutingFile);
const replaceDynamicRoutingFile = makeAsyncEpic(actions.replaceDynamicRoutingFile, api.replaceDynamicRoutingFile);
const deleteDynamicRoutingFile = makeAsyncEpic(actions.deleteDynamicRoutingFile, api.deleteDynamicRoutingFile);
const viewDynamicRoutingFile = makeAsyncEpic(actions.viewDynamicRoutingFile, api.viewDynamicRoutingFile);

const displaySnackBar = (action$) =>
  action$.ofType(actions.displaySnackBar.TYPE).switchMap(({payload}) => {
    if (payload.type === 'success') {
      return [
        success({
          title: payload.msg,
          settings: {
            uid: 'routing_table_success_msg',
          },
        }),
      ];
    }
    if (payload.type === 'failure') {
      const errorData = fileUploadErrorHandler({andtErrorCode: payload.errorCode});
      return [error(errorData)];
    }
    return [];
  });

const addDynamicRoutingFile = (action$) =>
  action$.ofType(actions.addDynamicRoutingFile.TYPE).switchMap(({payload}) => {
    if (payload.file.errorCode) {
      return [actions.displaySnackBar({type: 'failure', errorCode: payload.file.errorCode})];
    }
    const data = new FormData();
    data.append('file', payload.file.data);
    return [actions.uploadDynamicRoutingFile(data)];
  });

const updateDynamicRoutingFile = (action$) =>
  action$.ofType(actions.updateDynamicRoutingFile.TYPE).switchMap(({payload}) => {
    if (payload.file.errorCode) {
      return [actions.displaySnackBar({type: 'failure', errorCode: payload.file.errorCode})];
    }
    const data = new FormData();
    data.append('file', payload.file.data);
    return [actions.replaceDynamicRoutingFile({data, id: payload.id})];
  });

const setUsersGroupsFiltersIsOpen = (action$, {getState}) =>
  action$
    .ofType(actions.setAssetsMngFiltersIsOpen.TYPE)
    .debounceTime(200)
    .distinctUntilChanged((x, y) => x.payload === y.payload)
    .flatMap(({payload}) => {
      const currentAppSettings = selectors.getMeAppSettings(getState());

      const appSettings = {
        ...currentAppSettings,
        assets: {
          ...currentAppSettings.assets,
          isFiltersBarOpen: payload,
        },
      };

      return [updateUserSettings(appSettings)];
    });

const uploadFileSuccess = (action$) =>
  action$
    .ofType(actions.uploadDynamicRoutingFile.success.TYPE, createLookupTable.success.TYPE)
    .switchMap(() => [
      actions.getDynamicRoutingFiles(),
      actions.displaySnackBar({type: 'success', msg: 'File upload successfully'}),
    ]);

const replaceFileSuccess = (action$) =>
  action$
    .ofType(actions.replaceDynamicRoutingFile.success.TYPE)
    .switchMap(() => [
      actions.getDynamicRoutingFiles(),
      actions.displaySnackBar({type: 'success', msg: 'File update successfully'}),
    ]);

const deleteSuccess = (action$) =>
  action$
    .ofType(actions.deleteDynamicRoutingFile.success.TYPE, deleteLookupTable.success.TYPE)
    .switchMap(() => [
      actions.getDynamicRoutingFiles(),
      actions.displaySnackBar({type: 'success', msg: 'File deleted successfully'}),
    ]);

export default combineEpics(
  displaySnackBar,
  setUsersGroupsFiltersIsOpen,
  getDynamicRoutingFiles,
  addDynamicRoutingFile,
  uploadDynamicRoutingFile,
  updateDynamicRoutingFile,
  replaceDynamicRoutingFile,
  deleteDynamicRoutingFile,
  viewDynamicRoutingFile,
  uploadFileSuccess,
  replaceFileSuccess,
  deleteSuccess,
);
