// @flow
import React, {Fragment, useCallback, useState} from 'react';
import {Box, makeStyles} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import Input from 'common/componentsV2/Input';
import DatePicker from 'common/componentsV2/customDateRange/DatePicker';
import {getFormattedLLDate} from 'common/utils/dateService';
import {correctTimestamp, correctTimestampUnix} from 'common/utils/dateRangeService';

const useStyles = makeStyles(() => ({
  arrowRight: {
    position: 'absolute',
    cursor: 'pointer',
    right: 0,
    top: 0,
  },
  arrowLeft: {
    position: 'absolute',
    cursor: 'pointer',
    left: 0,
    top: 0,
  },
}));

const formattedValue = (value) => {
  if (value !== '') {
    return getFormattedLLDate(correctTimestampUnix(value));
  }
  return value;
};

const CalendarRange = ({value, onChange}: {value: Object, onChange: Function}) => {
  const classes = useStyles();
  const [isStartMode, setIsStartMode] = useState(true);
  const actualMonth = new Date().getMonth();
  const actualYear = new Date().getFullYear();
  const endMonth = value.constRange === 'c' && value.endDate && new Date(correctTimestamp(value.endDate)).getMonth();
  const endYear = value.constRange === 'c' && value.endDate && new Date(correctTimestamp(value.endDate)).getFullYear();
  const [date, setDate] = useState({
    currentMonth: endMonth === 0 ? 0 : endMonth || actualMonth,
    currentYear: endYear || actualYear,
    currentMonthPrev:
      // eslint-disable-next-line no-nested-ternary
      endMonth === 0 || (!endMonth && actualMonth === 0)
        ? 11
        : endMonth === 1
        ? 0
        : (endMonth && endMonth - 1) || actualMonth - 1,
    currentYearPrev: endMonth === 0 || (!endMonth && actualMonth === 0) ? actualYear - 1 : actualYear,
  });

  const setSlide = useCallback(
    (direction) => {
      let updatedMonthPrev;
      let updatedYearPrev;
      if (direction === 'next') {
        updatedMonthPrev = date.currentMonthPrev === 11 ? 0 : date.currentMonthPrev + 1;
        updatedYearPrev = date.currentMonthPrev === 11 ? date.currentYearPrev + 1 : date.currentYearPrev;
      }
      if (direction === 'prev') {
        updatedMonthPrev = date.currentMonthPrev === 0 ? 11 : date.currentMonthPrev - 1;
        updatedYearPrev = date.currentMonthPrev === 0 ? date.currentYearPrev - 1 : date.currentYearPrev;
      }
      const updatedMonth = updatedMonthPrev === 11 ? 0 : updatedMonthPrev + 1;
      const updatedYear = updatedMonthPrev === 11 ? updatedYearPrev + 1 : updatedYearPrev;
      setDate({
        ...date,
        currentMonthPrev: updatedMonthPrev,
        currentYearPrev: updatedYearPrev,
        currentMonth: updatedMonth,
        currentYear: updatedYear,
      });
    },
    [date],
  );

  const onDatePickerChange = (pickedDate) => {
    if (isStartMode) {
      onChange({startDate: pickedDate, endDate: ''});
      setIsStartMode(!isStartMode);
    } else if (value.startDate > pickedDate) {
      onChange({startDate: pickedDate, endDate: ''});
    } else {
      onChange({startDate: value.startDate, endDate: pickedDate});
      setIsStartMode(!isStartMode);
    }
  };

  return (
    <Fragment>
      <div className="display_flex width_1 mt_1">
        <div className="display_flex justifyContent_center alignItems_center flexGrow_1">
          <TypographyBox variant="subtitle3" fontSize={18} lineHeight="21px" mr={1}>
            From
          </TypographyBox>
          <Box width={130} height={35}>
            <Input value={formattedValue(value.startDate)} fullSize isDisabled />
          </Box>
        </div>
        <div className="display_flex justifyContent_center alignItems_center flexGrow_1">
          <TypographyBox variant="subtitle3" fontSize={18} lineHeight="21px" mr={1}>
            To
          </TypographyBox>
          <Box width={130} height={35}>
            <Input value={formattedValue(value.endDate)} fullSize isDisabled />
          </Box>
        </div>
      </div>

      <Box position="relative" display="flex" justifyContent="center" mt={2} width={1} height={267}>
        <div className="mr_2-5">
          <DatePicker
            value={value}
            onChange={onDatePickerChange}
            currentMonth={date.currentMonthPrev}
            setCurrentMonth={useCallback(
              (month) =>
                setDate({
                  ...date,
                  currentMonthPrev: month,
                }),
              [date],
            )}
            currentYear={date.currentYearPrev}
            setCurrentYear={useCallback(
              (year) =>
                setDate({
                  ...date,
                  currentYearPrev: year,
                }),
              [date],
            )}
          />
        </div>
        <DatePicker
          value={value}
          onChange={onDatePickerChange}
          currentMonth={date.currentMonth}
          setCurrentMonth={useCallback(
            (month) =>
              setDate({
                ...date,
                currentMonth: month,
              }),
            [date],
          )}
          currentYear={date.currentYear}
          setCurrentYear={useCallback(
            (year) =>
              setDate({
                ...date,
                currentYear: year,
              }),
            [date],
          )}
        />
        <div className={classes.arrowRight} onClick={useCallback(() => setSlide('next'), [setSlide])}>
          <i className="icon icn-arrow16-chevronright" />
        </div>
        <div className={classes.arrowLeft} onClick={useCallback(() => setSlide('prev'), [setSlide])}>
          <i className="icon icn-arrow16-chevronleft" />
        </div>
      </Box>
    </Fragment>
  );
};

export default CalendarRange;
