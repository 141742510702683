import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const pageReducer = composeReducers((state = {searchString: ''}, {type, payload}) => {
  switch (type) {
    case actions.setPageKeyVal.TYPE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
});

export default pageReducer;
