// @flow
import TableListItem, {CHECKBOX_VISIBILITY_TYPES} from 'common/componentsV2/table/TableListItem';
import React from 'react';
import {useSelector} from 'react-redux';
import {getFiltersSearchQuery} from 'admin.customers/store/filterSelector';
import {noop} from 'lodash';
import CustomersActionToolbar from './CustomersActionToolbar';
import CustomersRowHeader from './CustomersRowHeader';
import './CustomersTable.module.scss';

const NOOP = () => {};

type PropTypes = {
  style: Object,
  customer: Object,
};

const CustomersTableRow = ({customer, style}: PropTypes) => {
  const searchString = useSelector(getFiltersSearchQuery);

  return (
    <div styleName="table-row">
      <TableListItem
        key={customer.id}
        style={style}
        headerComponent={<CustomersRowHeader customer={customer} searchString={searchString} />}
        expandedPanel={NOOP}
        checkboxVisibility={CHECKBOX_VISIBILITY_TYPES.alwaysHide}
        setSelectedItem={noop}
        isSelected={false}
        actionToolbarComponent={<CustomersActionToolbar customer={customer} />}
      />
    </div>
  );
};

export default CustomersTableRow;
