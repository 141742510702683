import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {getDashboardDataFactory} from 'dashboards/store/selectors';

const useDashboardState = (dashboardId) => {
  const selector = useMemo(() => getDashboardDataFactory(dashboardId), [dashboardId]);
  return useSelector(selector);
};

export default useDashboardState;
