// @flow

import React from 'react';
import SelectedItemsList from './SelectedItemsList';
import PopoverContainer from './PopoverContainer';
import './PopoverSelect.module.scss';

const makeSelectedList = (list: [string], selected: boolean, disabled: boolean) =>
  list.map((i) => ({
    ...i,
    selected,
    disabled,
    uiName: i.name,
  }));

const combinePossibleAndSelected = (possible, selected, disabled) => [
  ...makeSelectedList(possible, false, disabled),
  ...makeSelectedList(selected, true, false),
];

const PopoverSelect = ({
  title,
  subtitle,
  maxSelections,
  possibleConcepts,
  selectedConcepts,
  onAddItem,
  onRemoveItem,
  isLoading,
  className,
  theme,
  isHidePanelTitle,
  selectedItemComponent,
  isDisabled,
}: any) => {
  const CustomSelectedItemsList = selectedItemComponent;

  return (
    <div styleName="panel" className={className}>
      <PopoverContainer
        {...{
          title,
          subtitle,
          maxSelections,
          onAddItem,
          onRemoveItem,
          isLoading,
          theme,
          isHidePanelTitle,
          isDisabled,
          concepts: combinePossibleAndSelected(possibleConcepts, selectedConcepts, isDisabled),
        }}
      />
      <div styleName="scroller">
        {selectedConcepts
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(({id, name}) =>
            selectedItemComponent ? (
              <CustomSelectedItemsList key={id} {...{id, uiName: name, onRemoveItem}} />
            ) : (
              <SelectedItemsList key={id} {...{id, uiName: name, onRemoveItem}} />
            ),
          )}
      </div>
    </div>
  );
};
export default PopoverSelect;
