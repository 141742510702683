import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {NUMBER_OF_METRICS_DELTA} from 'admin.customers/services/customersService';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

export default combineReducers({
  customers: composeReducers(
    makeAsyncReducer(actions.fetchCustomers, {
      defaultData: EMPTY_ARRAY,
      includeUpdateAt: true,
      shouldDestroyData: false,
    }),
    (state = {}, {type, payload}) => {
      const red = (item, payloadInner) => ({...item, ...payloadInner});
      const findCustomerIndexById = (id) => state.data.findIndex((item) => item._id === id);

      const updateCustomerById = (id, obj) => {
        const index = findCustomerIndexById(id);
        return {
          ...state,
          data: reduceArrayItem(red, state.data, index, obj),
        };
      };
      switch (type) {
        case actions.updateCustomer.success.TYPE:
          return {
            ...updateCustomerById(state.editCustomersData._id, state.editCustomersData),
            editCustomersData: null,
          };
        case actions.createCustomer.success.TYPE:
          return {
            ...state,
            data: state.data.concat({...state.editCustomersData, _id: payload.customerId}),
            editCustomersData: null,
          };
        case actions.deleteCustomer.success.TYPE: {
          const newCustomers = state.data.filter((customer) => customer._id !== state.deleteCustomerId);
          return {
            ...state,
            data: newCustomers,
            deleteCustomerId: null,
          };
        }
        case actions.fetchEditCustomerData.success.TYPE:
          return {
            ...state,
            editCustomersData: payload,
          };
        case actions.createCustomer.TYPE:
        case actions.updateCustomer.TYPE:
          return {
            ...state,
            editCustomersData: payload.customer,
          };
        case actions.toggleCustomerChangeStatusConfirmationModal.TYPE:
          return {
            ...state,
            editCustomersData: null,
          };
        case actions.deleteCustomer.TYPE: {
          return {
            ...state,
            deleteCustomerId: payload.customerId,
          };
        }
        default:
          return state;
      }
    },
  ),
  customersActionsData: composeReducers((state = {}, {type, payload}) => {
    switch (type) {
      case actions.fetchCustomerDefaultSettings.TYPE:
        return {
          ...state,
          isLoadingDefaultSettings: true,
        };
      case actions.fetchEditCustomerData.TYPE:
        return {
          ...state,
          isLoadingCustomerData: true,
        };
      case actions.fetchCustomerDefaultSettings.success.TYPE:
        return {
          ...state,
          defaultSettings: {...payload, maxMetricsAllowed: payload.maxMetricsAllowed * NUMBER_OF_METRICS_DELTA},
          isLoadingDefaultSettings: false,
        };
      case actions.fetchEditCustomerData.success.TYPE:
        return {
          ...state,
          isLoadingCustomerData: false,
        };
      case actions.fetchEditCustomerData.failure.TYPE:
      case actions.fetchCustomerDefaultSettings.failure.TYPE:
        return {
          ...state,
          isLoadingDefaultSettings: false,
          isLoadingCustomerData: false,
        };
      case actions.createCustomer.TYPE:
      case actions.updateCustomer.TYPE:
        return {
          ...state,
          isSubmittingCustomerData: true,
        };
      case actions.updateCustomer.success.TYPE:
        return {
          ...state,
          isSubmittingCustomerData: false,
        };
      case actions.createCustomer.success.TYPE:
        return {
          ...state,
          isSubmittingCustomerData: false,
        };
      case actions.deleteCustomer.success.TYPE: {
        return {
          isSubmittingCustomerData: false,
        };
      }
      case actions.updateCustomer.failure.TYPE:
      case actions.createCustomer.failure.TYPE:
      case actions.deleteCustomer.failure.TYPE:
        return {
          ...state,
          isSubmittingCustomerData: false,
        };
      case actions.deleteCustomer.TYPE: {
        return {
          ...state,
          isSubmittingCustomerData: true,
        };
      }
      default:
        return state;
    }
  }),
});
