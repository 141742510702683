// @flow
import React from 'react';
import './ToggleButton.module.scss';

type PropTypes = {
  className: String,
  value: Boolean,
  text: String,
  onChange: Function,
  onChangePropName: String,
};

class ToggleButton extends React.PureComponent {
  props: PropTypes;

  handleClick = () => {
    this.props.onChange({[this.props.onChangePropName]: !this.props.value});
  };

  render() {
    return (
      <div styleName="root" className={this.props.className ? this.props.className : ''} onClick={this.handleClick}>
        <div styleName={`image ${this.props.value ? 'full-checkbox-image' : 'empty-checkbox-image'}`} />
        <span styleName="text">{this.props.text}</span>
      </div>
    );
  }
}

export default ToggleButton;
