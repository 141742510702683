import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/tpGeneral/');

const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/tpGeneral/');

export const setViewsKeyVal = mac('SET_VIEWS_KEY_VAL');

export const fetchTopologyMapRegions = macAsync('FETCH_TOPOLOGY_MAP_REGIONS');
export const fetchTopologyMapRegionSitesList = macAsync('FETCH_TOPOLOGY_MAP_REGION_SITES_LIST');

export const fetchTopologyAnomalies = macAsync('FETCH_TOPOLOGY_ANOMALIES');
export const getTopologyAnomalies = mac('GET_TOPOLOGY_ANOMALIES');
export const fetchTopologyAlerts = macAsync('FETCH_TOPOLOGY_ALERTS');
export const getTopologyAlerts = mac('GET_TOPOLOGY_ALERTS');

export const fetchTopologyAnomaly = macAsync('FETCH_TOPOLOGY_ANOMALY');
export const fetchTopologyAlert = macAsync('FETCH_TOPOLOGY_ALERT');
export const getSingleTopologyIssue = mac('GET_SINGLE_TOPOLOGY_ISSUE');

export const fetchMetricDataPoints = macAsync('FETCH_METRIC_DATA_POINTS');
