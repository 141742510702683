// @flow
import React from 'react';
import SelectAndt, {THEME_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './AccountsDropdown.module.scss';

type PropTypes = {
  isLoading: boolean,
  items: Array,
  selectedItem: Array,
  isDisabled: boolean,
  onChange: Function,
  ddlId: string,
  ddlTitle: string,
};

export default class AccountsDropdown extends React.PureComponent {
  props: PropTypes;

  render() {
    const {ddlId, isLoading, items, isDisabled, selectedItem, ddlTitle, onChange} = this.props;

    return (
      <div styleName="root">
        <div>
          <SelectAndt
            id={ddlId}
            disabled={(isLoading !== false && !items.length) || isDisabled}
            options={items}
            getOptionLabel={(val) => val.name}
            getOptionValue={(val) => val.id}
            type={TYPE_NEW_NO_SEARCH}
            theme={THEME_HIGHLIGHTED}
            value={isLoading ? null : selectedItem}
            onChange={onChange}
            optionHeight={40}
            isClearable={false}
            closeMenuOnSelect
            menuWidth={280}
            buttonWidth={200}
            placeholder={isLoading !== false ? 'Loading...' : ddlTitle}
            automationId={ddlTitle}
          />
        </div>
      </div>
    );
  }
}
