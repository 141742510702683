import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const initialState = {
  isLoading: false,
};

const passwordReducer = (state = initialState, {type}) => {
  switch (type) {
    case actions.updatePasswordRequest.TYPE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.updatePasswordRequest.success.TYPE:
    case actions.updatePasswordRequest.failure.TYPE:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default composeReducers(passwordReducer);
