import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as api from '../services/api';
import * as actions from './actions';
// import * as selectors from './selectors';

const fetchTrainTrackerData = makeAsyncEpic(actions.fetchTrainTrackerData, api.fetchTrainTrackerData);

// const fetchCustomersSuccesses = (action$) => action$.ofType(
//   actions.fetchCustomers.success.TYPE,
// )
//   .switchMap(() => [actions.fetchDefaults()]);

export default combineEpics(fetchTrainTrackerData);
