import {difference, get, isString} from 'lodash';
import {getEditorValidationMessage as fuGetEditorValidationMessage} from './fuService';

export const KINESIS_RECORD_TYPES = [
  {
    name: 'JSON',
    value: 'single_json',
  },
  {
    name: 'Multiple JSON records',
    value: 'multi_json',
  },
];

export const KINESIS_RECORD_TYPES_DELIMITER = [
  {
    name: 'No delimiter - Back to back',
    value: '_adjacent',
  },
  {
    name: 'Newline',
    value: '_new_line',
  },
  {
    name: 'Array of JSON records',
    value: '_array',
  },
];

export const KINESIS_FILTER_TYPES = [
  {
    name: 'Include',
    value: 'in',
  },
  {
    name: 'Exclude',
    value: 'notIn',
  },
];

export const convertUiFiltersToKinesisStreamFilters = (uiFilters) => {
  if (!uiFilters.length) {
    return [];
  }

  const filters = [];
  uiFilters.forEach((uiFilter) => {
    const {path, selectedValue, isInclude} = uiFilter;
    if (!path.trim()) {
      return;
    }

    if (!selectedValue) {
      filters.push({path});
      return;
    }

    const isMultiVal = selectedValue.indexOf(',') !== -1;
    let filterValKey = isInclude ? 'value' : 'notValue';
    let filterValValue = selectedValue.trim();
    if (isMultiVal) {
      filterValKey = isInclude ? 'values' : 'notValues';
      filterValValue = selectedValue.split(',').map((val) => val.trim());
    }

    filters.push({path, [filterValKey]: filterValValue});
  });

  return filters;
};

export const convertKinesisStreamFiltersToUiFilters = (ksFilters) => {
  if (!ksFilters || !ksFilters.length) {
    return null;
  }

  const filters = [];
  ksFilters.forEach((ksFilter) => {
    const {path} = ksFilter;
    const isInclude = !(ksFilter.notValue || ksFilter.notValues);
    let selectedValue = '';
    if (isInclude) {
      selectedValue = ksFilter.value;
      if (ksFilter.values) {
        selectedValue = ksFilter.values.join(', ');
      }
    } else {
      selectedValue = ksFilter.notValue;
      if (ksFilter.notValues) {
        selectedValue = ksFilter.notValues.join(', ');
      }
    }

    filters.push({path, isInclude, selectedValue});
  });

  return filters;
};

export const areKinesisStreamFiltersEquel = (curFilters, otherFilters) => {
  let same = true;
  if (curFilters.length !== otherFilters.length) {
    return false;
  }

  curFilters.forEach((curFilter, index) => {
    if (
      curFilter.path !== otherFilters[index].path ||
      curFilter.value !== otherFilters[index].value ||
      curFilter.notValue !== otherFilters[index].notValue ||
      (curFilter.values &&
        otherFilters[index].values &&
        difference(curFilter.values, otherFilters[index].values).length) ||
      (curFilter.notValues &&
        otherFilters[index].notValues &&
        difference(curFilter.notValues, otherFilters[index].notValues).length)
    ) {
      same = false;
    }
  });
  return same;
};

export const isKinesisDiametricsEditable = (dataStream) =>
  !get(dataStream.uiState, 'uiFilters', []).length ||
  !get(dataStream.uiState, 'uiFilters[0].path', null) ||
  !get(dataStream, 'uiState.recordType', null) ||
  (dataStream.recordType ===
    get(dataStream, 'uiState.recordType') + get(dataStream.uiState, 'recordTypeDelimiter', '') &&
    !!dataStream.filters &&
    !!get(dataStream.uiState, 'uiFilters', null) &&
    areKinesisStreamFiltersEquel(
      dataStream.filters,
      convertUiFiltersToKinesisStreamFilters(dataStream.uiState.uiFilters),
    ));

// gets sources ids used by items recursively
export const getSourceIdsArr = (sourcesIdArr, item) => {
  if (item.sourceColumn) {
    if (!sourcesIdArr.includes(item.sourceColumn)) {
      sourcesIdArr.push(item.sourceColumn);
    }
  } else if (item.transform && item.transform.input) {
    item.transform.input.forEach((a) => {
      getSourceIdsArr(sourcesIdArr, a);
    });
  }
  return sourcesIdArr;
};

export const getOriginalName = (item) => {
  if (item.transform && item.transform.input) {
    return getSourceIdsArr([], item)[0].name;
  }
  if (item.sourceColumn) {
    return item.sourceColumn;
  }
  return item.name !== undefined ? item.name : item.path;
};

export const getIsItemSourceMerged = (unifiedSchema, itemId, itemName) =>
  unifiedSchema.find(
    (a) =>
      (a.sourceColumn === itemId.toString() || getSourceIdsArr([], a)[0] === itemId) &&
      a.path !== itemName &&
      a.isMerged,
  );

export const findItemInSchema = (columns, item) =>
  columns.find(
    (a) =>
      a.sourceColumn === item.path ||
      (a.transform && a.transform.input && a.transform.input.length === 1 && item.path === getSourceIdsArr([], a)[0]),
  );

export const updateItemMatch = (stream, item) => {
  let itemMatch = {...item};
  if (stream.metrics.includes(item.path) || stream.dimensions.includes(item.path)) {
    itemMatch = {
      ...itemMatch,
      ...findItemInSchema(stream.schema && stream.schema.columns ? stream.schema.columns : [], item),
      isMerged: true,
    };
  } else if (itemMatch.type) {
    delete itemMatch.type;
  }
  return itemMatch;
};

export const isKinesisCollectEnabled = (dataStream) =>
  !get(dataStream.uiState, 'uiFilters', []).length ||
  !get(dataStream.uiState, 'uiFilters[0].path', null) ||
  !get(dataStream, 'uiState.recordType', null);

export const getEditorValidationMessage = (stream, isAll = false) => {
  const isPristine = !get(stream, 'uiState.analysisResult');
  const isDirty = !isKinesisDiametricsEditable(stream);
  const uiFilters = get(stream, 'uiState.uiFilters');
  const recordType = get(stream, 'uiState.recordType', '');
  const recordTypeDelimiter = get(stream, 'uiState.recordTypeDelimiter', '');
  const res = fuGetEditorValidationMessage(stream, isAll) || [];
  if (isString(res)) {
    return res;
  }

  if (!recordType) {
    res.push('Record type is required');
  }

  if (recordType && recordType === KINESIS_RECORD_TYPES[1].value && !recordTypeDelimiter) {
    res.push('Record delimiter is required');
  }

  if (!uiFilters) {
    res.push('Filter definition is required');
  }

  if (uiFilters && uiFilters.some((uiFilter) => !uiFilter.path)) {
    res.push('Filter path is required');
  }

  if (!isPristine && isDirty && !isAll) {
    res.push('Stream filters to collect mismatch');
  }

  if (isAll) {
    return res;
  }
  return res[0];
};
