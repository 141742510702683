// @flow
import React, {useCallback} from 'react';
import {isString} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {getEnableNewMetricExplorer} from 'profile/store/selectors';
import {useTheme} from '@material-ui/core/styles';
import {Box} from '@material-ui/core';

import * as actions from 'common/store/actions';
import Tooltip from 'common/componentsV2/Tooltip';

type PropTypes = {
  children: Node,
  tooltipMessage: String,
  successMessage: String,
  className: String,
  shouldUseIcon: Boolean,
  tooltipPlacement: String,
  copyStr: String,
  metricNameMenu: Node,
};

const CopyToClipboardButton = (props: PropTypes) => {
  const {
    tooltipPlacement,
    shouldUseIcon,
    className,
    tooltipMessage,
    children,
    copyStr: copyStrRaw,
    successMessage,
    metricNameMenu,
  } = props;

  const copyStr = isString(copyStrRaw) ? copyStrRaw : JSON.stringify(copyStrRaw);

  const dispatch = useDispatch();
  const theme = useTheme();
  const copyToClipBoard = useCallback((payload) => dispatch(actions.copyToClipBoard(payload)), []);
  const segmentClickEvent = useCallback((payload) => dispatch(actions.segmentClickEvent(payload)), []);

  const isNewMetricExplorerEnabled = useSelector(getEnableNewMetricExplorer);

  const copyButtonClicked = useCallback(
    (event) => {
      segmentClickEvent({type: 'click', name: 'copyToClipBoard'});
      copyToClipBoard({event, text: copyStr, description: successMessage});
    },
    [copyToClipBoard, copyStr, successMessage],
  );

  const extraRootClassName = className || '';
  const message = tooltipMessage || 'Copy to clipboard';

  let clickableElement = 'Copy';

  if (children) {
    clickableElement = (
      <div role="presentation" onClick={copyButtonClicked}>
        {children}
      </div>
    );
  } else if (shouldUseIcon) {
    clickableElement =
      metricNameMenu && isNewMetricExplorerEnabled ? (
        metricNameMenu
      ) : (
        <Tooltip placement={tooltipPlacement || 'top'} content={message}>
          <div role="presentation" onClick={copyButtonClicked}>
            <Box
              color="gray.400"
              css={{'&:hover': {color: theme.palette.blue[500]}}}
              className="icon icn-action16-copy"
            />
          </div>
        </Tooltip>
      );
  }

  return <div className={`copy-container-wrapper ${extraRootClassName}`}>{clickableElement}</div>;
};

export default CopyToClipboardButton;
