import {get} from 'lodash';

export const fileFormats = [
  {
    value: 'Parquet',
    label: 'Parquet',
  },
];

export const fileCompressions = [
  {
    value: 'none',
    label: 'None',
  },
  {
    value: 'Snappy',
    label: 'Snappy',
  },
];

export const getEditorValidationMessage = (stream, isAll = false) => {
  const isPristine = !get(stream, 'uiState.analysisResult');
  const res = [];
  const timePattern = get(stream.timeDefinition, 'timePattern');

  if (!isPristine && (!stream.dimensions.length && !stream.metrics.length)) {
    res.push('At least one dimension or metric is required');
  } else if (!isPristine && !get(stream.timeDefinition, 'name')) {
    res.push('Timestamp definition is required');
  }

  if (!isPristine && !timePattern) {
    res.push('Time pattern is required');
  }
  if (
    !isPristine &&
    timePattern &&
    !get(stream.timeDefinition, 'timeZone') &&
    !(
      timePattern.toLowerCase().indexOf('z') >= 0 ||
      ['epoch_seconds', 'epoch_millis', 'epoch_micros'].includes(timePattern)
    )
  ) {
    res.push('Timezone is required');
  }
  if (get(stream.uiState, 'analysisResult.fileErrors.length') && !isAll) {
    // only for the NEXT btn tt
    res.push('File parsing failed');
  }

  if (isAll) {
    return res;
  }
  return res[0];
};
