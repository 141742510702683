import GoogleOAuth2 from 'google-oauth2-web-client';
import {bcTypes} from 'bc/services/bcTypes';
import {get, isString} from 'lodash';
import {getEditorValidationMessage as fuGetEditorValidationMessage} from './fuService';

export const doGsAuthFlow = (clientId, createDataSource, me) => {
  let locOrigin = window.location.origin;
  if (locOrigin.indexOf('localhost:') === -1) {
    const baseDnsName = me.baseDNSName === 'anodot-test.com' ? 'ano-dev.com' : me.baseDNSName;
    locOrigin = `https://${me.clusterDNSPrefix}.${baseDnsName}`;
  }

  const go2 = new GoogleOAuth2({
    clientId,
    redirectUri: `${locOrigin}/api/v1/bc/signin/oauth/google/callback`,
    scope:
      // eslint-disable-next-line max-len
      'https://www.googleapis.com/auth/compute.readonly https://www.googleapis.com/auth/devstorage.read_only https://www.googleapis.com/auth/cloud-platform.read-only',
    responseType: 'code',
    accessType: 'offline',
    state: me.ownerOrganization || 'no-org-id',
    popupHeight: 600,
    popupWidth: 600,
  });
  go2.login(true, false);

  window.createDataSource = function(token) {
    createDataSource({
      code: token,
      type: bcTypes.google_storage.type,
      name: `Google Storage Data Source ${new Date().getTime().toString()}`,
      authenticationType: 'refresh_token',
      redirectURI: `${locOrigin}/api/v1/bc/signin/oauth/google/callback`,
    });
  };
};

export const getEditorValidationMessage = (stream, isAll = false) => {
  const isPristine = !get(stream, 'uiState.analysisResult');
  const res = fuGetEditorValidationMessage(stream, isAll) || [];
  if (isString(res)) {
    return res;
  }

  if (isPristine && !isAll) {
    res.push('Fill files to collect section');
  }
  // if (!isPristine && isDirty && !isAll) {
  //  res.push('Files to collect mismatch');
  // }

  if (isAll) {
    return res;
  }
  return res[0];
};
