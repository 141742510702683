import {composeReducers} from 'common/utils/reducers';
import * as actions from 'topologyGeneral/store/actions';

const EMPTY_ARRAY = [];

const DEFAULT_STATE = {
  regions: EMPTY_ARRAY,
};

const viewsReducer = composeReducers((state = DEFAULT_STATE, {type, payload}) => {
  switch (type) {
    case actions.setViewsKeyVal.TYPE: {
      return {...state, ...payload};
    }
    default:
      return state;
  }
});

export default viewsReducer;
