// @flow
import React, {Fragment} from 'react';
import Box from '@material-ui/core/Box';
import InputAuto from '../../alerts.management/components/editor/simulationArea/utils/InputAuto';

type PropTypes = {
  value: Number,
  setValue: Function,
};

const FactorSelector = (props: PropTypes) => {
  const setValue = (e) => {
    props.setValue({value: e.target.value, name: 'factor'});
  };

  return (
    <Fragment>
      <Box width="75px" height="35px" css={{'& input': {padding: '10px', textAlign: 'center'}}}>
        <InputAuto
          automationId="impactFactorSelectorInput"
          fullSize
          delay={1000}
          type="number"
          value={props.value}
          onChange={(e) => setValue(e)}
          additionalProps={{
            min: 0,
            max: 1000000,
          }}
        />
      </Box>
    </Fragment>
  );
};

export default FactorSelector;
