import {combineEpics} from 'redux-observable';
import {getAllowedRoles, isAnodot} from 'profile/store/selectors';
import * as actions from '../actions';

const setUsersGroupsFilters = (action$, {getState}) =>
  action$.ofType(actions.setUsersGroupsFilters.TYPE).flatMap(({payload}) => {
    const filters = {...payload};
    const curState = getState();
    const isAndt = isAnodot(curState);

    if (isAndt) {
      filters.groups = null;
    } else {
      filters.customers = null;
    }

    if (filters.roles) {
      const allowedRoles = getAllowedRoles(curState);

      const cleanFilterRoles = [];
      filters.roles.split(',').forEach((filtersRole) => {
        if (allowedRoles.some((r) => r.role === filtersRole)) {
          cleanFilterRoles.push(filtersRole);
        }
      });
      filters.roles = cleanFilterRoles.join(',') || null;
    }

    return [actions.setUsersGroupsFiltersKeyVal(filters)];
  });

const filtersEpics = combineEpics(setUsersGroupsFilters);

export default filtersEpics;
