// @flow
import React from 'react';
import SmartTooltip from 'common/components/SmartTooltip';

import './SettingsDdl.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {components} from 'react-select2';

const SettingsDdl = (props: {
  value: String,
  collection: Array<Object>,
  onChange: Function,
  onChangePropName: String,
  placeHolder: String,
  validationMessage: String,
}) => {
  const selectedIndex = props.collection.findIndex((val) => val.value === props.value);

  const optionComponent = (p) => (
    <components.Option {...p}>
      <div styleName="menu-item">
        <div>{p.data.text}</div>
        <div>{p.data.value}</div>
      </div>
    </components.Option>
  );

  const ddl = (
    <SelectAndt
      id="ddlTableDelimiter"
      automationId="tableDelimiter"
      className="andt-dropdown"
      options={props.collection}
      getOptionLabel={(val) => val.text}
      getOptionValue={(val) => val.value}
      type={TYPE_NO_SEARCH}
      theme={THEME_LIGHT}
      value={props.collection[selectedIndex]}
      onChange={(opt) => props.onChange({[props.onChangePropName]: opt.value})}
      placeholder={props.placeHolder}
      customComponent={{Option: optionComponent}}
    />
  );

  return props.validationMessage ? (
    <SmartTooltip placement="top" content={props.validationMessage}>
      {ddl}
    </SmartTooltip>
  ) : (
    ddl
  );
};

export default SettingsDdl;
