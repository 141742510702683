import {composeReducers} from 'common/utils/reducers';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const alertActions = composeReducers((state = EMPTY_ARRAY, {type, payload, meta}) => {
  switch (type) {
    case actions.updateActionsToAlerts.TYPE:
      return payload;
    case actions.updateAction.success.TYPE:
      return [payload[0], ...state.filter((i) => i.id !== payload[0].id)];
    case actions.deleteAction.success.TYPE:
      return state.filter((i) => i.id !== meta.id);
    case actions.setAsPrimary.TYPE:
      return [state.find((action) => action.id === payload), ...state.filter((action) => action.id !== payload)];
    case actions.removeActionFromAlert.TYPE:
      return state.filter((action) => action.id !== payload);
    default:
      return state;
  }
});

export default combineReducers({
  alertActions,
});
