export default [
  {
    value: 'https://api.opsgenie.com',
    label: 'Global=https://api.opsgenie.com',
  },
  {
    value: 'https://api.eu.opsgenie.com',
    label: 'Global=https://api.eu.opsgenie.com',
  },
];
