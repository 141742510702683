// @flow

import React, {PureComponent} from 'react';
import './TimeFormat.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  items: Array,
  onChange: Function,
  selectedItem: Object,
  isLoading: Boolean,
};

export default class TimeFormat extends PureComponent {
  props: PropTypes;

  state = {
    isLoading: false,
  };

  componentDidUpdate() {
    if (this.props.isLoading && this.state.isLoading) {
      this.setIsLoading(false);
    }
  }

  setIsLoading = (val) => {
    this.setState({isLoading: val});
  };

  handleOnChange = (newItem) => {
    const {selectedItem} = this.props;

    const item = newItem;
    if (newItem.value) {
      item.value = newItem.value.trim();
    }

    if (item.value && (!selectedItem || item.value !== selectedItem.value)) {
      this.setState({isLoading: true});
      this.props.onChange(item);
    }
  };

  render() {
    const {items, selectedItem} = this.props;
    const {isLoading} = this.state;
    const selectedIndex = selectedItem ? items.findIndex((val) => val.value === selectedItem.value) : 0;
    return (
      <div role="button" styleName="root" onClick={(event) => event.preventDefault()}>
        <SelectAndt
          type={TYPE_SEARCH}
          theme={THEME_LIGHT}
          onChange={this.handleOnChange}
          options={items}
          value={items[selectedIndex]}
          placeholder={null}
          isCreatable
          automationId="timeFormat"
          optionHeight={40}
          disabled={isLoading}
        />
      </div>
    );
  }
}
