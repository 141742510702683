import {forEach} from 'lodash';

function treeVisitor(root, childPropertyName, callback) {
  // eslint-disable-next-line no-shadow,no-underscore-dangle
  const _treeVisitor = function(root, childPropertyName, callback, level) {
    callback(root, level);
    if (root[childPropertyName] && root[childPropertyName].length) {
      forEach(root[childPropertyName], function(node) {
        _treeVisitor(node, childPropertyName, callback, level + 1);
      });
    }
  };

  _treeVisitor(root, childPropertyName, callback, 0);
}

export default treeVisitor;
