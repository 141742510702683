// @flow
import React, {PureComponent} from 'react';
import ConditionContainer from './ConditionContainer';
import './AbsPercentageConditionTemplate.module.scss';

type PropTypes = {
  title: String,
  subTitle: String,
  absValue: Number,
  percentValue: Number,
  id: String,
  isAbsOnlyNaturalNumbers: boolean,
  enableAutoFeature: boolean,
  enableAutoBoolVal: boolean,
  enableAutoIsLoading: boolean,
  onAutoBoolValChange: Function,
  onPercentChange: Function,
  onAbsChange: Function,
};

class AbsPercentageConditionTemplate extends PureComponent {
  props: PropTypes;

  state = {
    absVal: this.props.absValue === null ? '' : this.props.absValue.toString(),
    percVal: this.props.percentValue === null ? '' : this.props.percentValue.toString(),
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.absValue !== this.state.absVal) {
      this.setState({absVal: nextProps.absValue === null ? '' : nextProps.absValue});
    }
  }

  autoCheckedChanged = (event) => {
    const {target} = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    this.props.onAutoBoolValChange(value);
  };

  render() {
    const safeAutoBoolVal = !!this.props.enableAutoBoolVal;
    return (
      <ConditionContainer {...this.props}>
        <div className="shell-col" automation-id="alertSettingsPercentagePane">
          <div styleName="input-container">
            <input
              type="number"
              className="dark-input"
              styleName={['abs-input', this.props.enableAutoIsLoading ? 'abs-input-loading' : ''].join(' ')}
              placeholder="Absolute"
              disabled={safeAutoBoolVal}
              step={safeAutoBoolVal ? '0.1' : '1'}
              value={this.state.absVal}
              onBlur={(e) =>
                // eslint-disable-next-line radix
                this.props.onAbsChange(this.props.isAbsOnlyNaturalNumbers ? parseInt(e.target.value) : e.target.value)
              }
              onChange={(e) =>
                // eslint-disable-next-line radix
                this.setState({absVal: this.props.isAbsOnlyNaturalNumbers ? parseInt(e.target.value) : e.target.value})
              }
            />
            {this.props.enableAutoIsLoading && (
              <div className="shell" styleName="add-on loading-add-on">
                <i className="icon ion-load-c spin" />
              </div>
            )}
            {!safeAutoBoolVal && (
              <div styleName="percent-container">
                <input
                  type="number"
                  className="dark-input"
                  styleName="percent-input"
                  placeholder="Percentage"
                  min="0"
                  max="1000"
                  value={this.state.percVal}
                  onBlur={(e) => this.props.onPercentChange(e.target.value)}
                  onChange={(e) => this.setState({percVal: e.target.value})}
                />
                <div className="shell" styleName="add-on">
                  %
                </div>
              </div>
            )}
          </div>

          {this.props.enableAutoFeature && (
            <div className="cb2 analytics" styleName="auto-chk">
              <input
                type="checkbox"
                name="enableAutoFeature"
                id="enableAutoFeature"
                checked={safeAutoBoolVal}
                onChange={this.autoCheckedChanged}
              />
              <label htmlFor="enableAutoFeature">Auto calculate</label>
            </div>
          )}
        </div>
      </ConditionContainer>
    );
  }
}

export default AbsPercentageConditionTemplate;
