import React, {PureComponent} from 'react';
import {components} from 'react-select2';
import {isArray} from 'lodash';
import Tooltip, {TYPES} from '../../../Tooltip';

type PropTypes = {
  // accepted values by the original Select
  children: any,
  selectProps: any,
};

export default class Control extends PureComponent {
  props: PropTypes;

  render() {
    let automationIdStringSource = 'select';

    if (this.props.selectProps.automationId) {
      automationIdStringSource = this.props.selectProps.automationId;
    } else if (this.props.selectProps.placeholder) {
      automationIdStringSource = this.props.selectProps.placeholder;
    }

    const automationId = `${automationIdStringSource.split(' ').join('-')}-dropdown`;

    let values;
    if (isArray(this.props.selectProps.value)) {
      values = this.props.selectProps.value.join(', ');
    } else {
      values = this.props.selectProps.value;
    }

    if (this.props.selectProps.useTooltip) {
      return (
        <Tooltip content={values} type={TYPES.SMALL}>
          <div automation-id={automationId}>
            <components.Control className="andt-dropdown-control" {...this.props}>
              {this.props.children}
            </components.Control>
          </div>
        </Tooltip>
      );
    }
    return (
      <div automation-id={automationId}>
        <components.Control className="andt-dropdown-control" {...this.props}>
          {this.props.children}
        </components.Control>
      </div>
    );
  }
}
