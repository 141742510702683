import React, {PureComponent} from 'react';
import {components} from 'react-select2';
import Highlighter from 'react-highlight-words';
import FunctionInformation from 'common/componentsV2/ExpressionBuilderV2/FunctionForExpressionBuilder/FunctionInformation';
import {getFocusOptionSearchableEmitter} from '../communication';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  isFocused: boolean,
  innerProps: any,
  children: any,
  selectProps: any,
  data: Object,
};

export default class OptionSingleWithInfo extends PureComponent {
  props: PropTypes;

  render() {
    if (this.props.isFocused) {
      const i = this.props.innerProps.id.split('-');
      const index = i[i.length - 1];
      getFocusOptionSearchableEmitter().next(index);
    }
    if (this.props.data.type === 'header' || this.props.data.type === 'HEADER' || this.props.data.category === true) {
      return (
        <components.Option className="andt-dropdown-option" {...this.props}>
          <div className={this.props.data.headerClass}>
            <i className={`icon ${this.props.data.iconClass} ${this.props.data.icon}`} />
            <span>{this.props.children}</span>
          </div>
        </components.Option>
      );
    }
    return this.props.children ? (
      <components.Option className="andt-dropdown-option-info" {...this.props}>
        <div className={this.props.data.optionClass}>
          <Highlighter
            autoEscape
            searchWords={[this.props.selectProps.inputValue]}
            textToHighlight={this.props.children}
          />
        </div>
        {this.props.data.description && (
          <div className="info">
            <FunctionInformation data={this.props.data} />
          </div>
        )}
      </components.Option>
    ) : null;
  }
}
