import {rangeTypes} from 'common/utils/dateRangeService';
import {NumberParam, StringParam, withDefault} from 'use-query-params';

export const QUERY_PARAM_MAP = {
  issuesType: withDefault(StringParam, 'alerts'),
  selectedIssueId: withDefault(StringParam, undefined),
  constRange: withDefault(StringParam, rangeTypes.h3.key),
  searchQuery: withDefault(StringParam, undefined),
  searchSelectedItemId: withDefault(StringParam, undefined),
  tabId: withDefault(StringParam, 'issues'),
  startDate: withDefault(NumberParam, undefined),
  endDate: withDefault(NumberParam, undefined),
  relativeLast: withDefault(NumberParam, undefined),
  relativeNext: withDefault(NumberParam, undefined),
};

export const PANEL_TABS = {
  issues: {
    id: 'issues',
    label: 'Issues',
  },
  search: {
    id: 'search',
    label: 'Search',
  },
};

export const PANEL_SIZES = {
  tinyScrollBox: 358,
  issueWidth: 344,
};
