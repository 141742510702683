// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {SITE_TYPES} from 'topologyGeneral/components/mapNodeIcons/SiteSvg';
import {getSiteCentralIcon, MAP_ITEMS_COLOR_PALETTE, useTooltipStyles} from 'topologyGeneral/services/mapService';

const NodeInfoPanel = ({node, isHidden}: PropTypes) => {
  if (isHidden || !node) {
    return null;
  }

  const classes = useTooltipStyles();

  const isRouter = getSiteCentralIcon(node.keywords) === SITE_TYPES.router;

  return (
    <Fragment>
      <Box display="flex" mb={0.25}>
        <Box component="span" className={classes.title14Bold} mr={0.5}>
          {node.name}
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Type
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.type || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Status
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.status || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Vendor
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.vendor || '-'}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Domain
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.domain ? node.domain.join(', ') : '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Description
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {node.description || '-'}
            </Box>
          </Box>
        </Box>
        {isRouter ? (
          <Box className={classes.nodeItemWrapper} pr={0} flexGrow={1}>
            <Box display="flex" flexDirection="column">
              <Box component="span" className={classes.title12BoldLight}>
                IP Address
              </Box>
              <Box component="span" className={classes.title14Normal}>
                {node.ip || '-'}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box className={classes.nodeItemWrapper} pr={0} flexGrow={1}>
            <Box display="flex" flexDirection="column">
              <Box component="span" className={classes.title12BoldLight}>
                Related Node
              </Box>
              <Box component="span" className={classes.title14Normal}>
                {node.relatedNodeName || '-'}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      {isRouter && node.interfaces && node.interfaces.length ? (
        <Fragment>
          <Box className={classes.horizontalDivider} />
          <Box display="flex" mb={0.25}>
            <Box component="span" className={classes.title14Bold} mr={0.5}>
              {`Interfaces (${node.interfaces.length})`}
            </Box>
          </Box>
          <Box display="flex" mt={1} flexWrap="wrap">
            {node.interfaces.map((inter) => (
              <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1} width={121} minWidth={121}>
                <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
                  <Box
                    className={classes.statusIndicator}
                    bgcolor={inter.isError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
                    mr={0.5}
                  >
                    &nbsp;
                  </Box>
                  <Box component="span" className={classes.title14Normal}>
                    {inter.name}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Fragment>
      ) : null}
    </Fragment>
  );
};

NodeInfoPanel.propTypes = {
  node: PropTypes.objectOf(PropTypes.any),
  isHidden: PropTypes.bool,
};

NodeInfoPanel.defaultProps = {
  node: null,
  isHidden: false,
};

export default NodeInfoPanel;
