import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';
import ExpressionItem from '../ExpressionItem';

// const EMPTY_ARRAY = [];

export default composeReducers(
  (
    state = {
      expression: [], // expression being shown in the input line
      mainSearchQuery: '', // the user's text input on the main text-field
      secondarySearchQuery: '', // the user's text input inside the chip box
      currentSelectedKey: '', // focused chip value
    },
    {type, payload},
  ) => {
    switch (type) {
      case actions.setExpression.TYPE:
        return {
          ...state,
          expression: payload,
        };
      case actions.setMainSearchQuery.TYPE:
        return {
          ...state,
          mainSearchQuery: payload,
        };
      case actions.setSecondarySearchQuery.TYPE:
        return {
          ...state,
          secondarySearchQuery: payload,
        };
      case actions.setCurrentSelectedKey.TYPE: {
        // first, check if it is actually an 'unselect'. If it is so, check if there is some chip left without
        // any child. This is forbidden, so it will be deleted.
        let newExpression = [...state.expression];
        if (payload === '') {
          const orfanChipIndex = state.expression.findIndex((expressionItem) => expressionItem.itemValues.length === 0);
          if (orfanChipIndex > -1) {
            newExpression = [
              ...state.expression.slice(0, orfanChipIndex),
              ...state.expression.slice(orfanChipIndex + 1),
            ];
          }
        }
        return {
          ...state,
          currentSelectedKey: payload,
          expression: newExpression,
        };
      }
      case actions.removeInnerValue.TYPE: {
        // Will remove parent chip also, if there is no child to it.
        const parentIndex = state.expression.findIndex(
          (expressionItem: ExpressionItem) => expressionItem.value === payload.parent,
        );
        const parent: ExpressionItem = state.expression[parentIndex].duplicateExpression();
        parent.removeItemValue(payload);
        if (parent.itemValues.length > 0) {
          return {
            ...state,
            expression: [...state.expression.slice(0, parentIndex), parent, ...state.expression.slice(parentIndex + 1)],
          };
        }
        return {
          ...state,
          expression: [...state.expression.slice(0, parentIndex), ...state.expression.slice(parentIndex + 1)],
        };
      }
      case actions.addInnerValue.TYPE: {
        const parentIndex = state.expression.findIndex(
          (expressionItem: ExpressionItem) => expressionItem.value === state.currentSelectedKey,
        );
        const parent: ExpressionItem = state.expression[parentIndex].duplicateExpression();
        if (typeof payload === 'string') {
          parent.addItemValueByString(payload);
        } else {
          parent.addItemValue(payload);
        }
        return {
          ...state,
          expression: [...state.expression.slice(0, parentIndex), parent, ...state.expression.slice(parentIndex + 1)],
        };
      }
      case actions.addValue.TYPE: {
        return {
          ...state,
          expression: [...state.expression, payload],
          currentSelectedKey: payload.value,
        };
      }
      default:
        return state;
    }
  },
);
