// @flow
import React from 'react';
import {Box} from '@material-ui/core';

type PropTypes = {
  tabs: Array,
  selectedTabId: String,
  onTabClick: Function,
};

const TabItem = ({
  label,
  id,
  isSelected,
  onClick,
}: {
  label: String,
  id: String,
  isSelected: boolean,
  onClick: Function,
}) => {
  const clicked = () => {
    onClick(id);
  };

  return (
    <Box
      fontSize={14}
      fontWeight={400}
      lineHeight="20px"
      color={isSelected ? 'blue.500' : 'gray.500'}
      mr={2}
      position="relative"
      overflow="hidden"
      css={{cursor: 'pointer'}}
      onClick={clicked}
    >
      {label}
      <Box
        display={isSelected ? 'block' : 'none'}
        height={4}
        left={0}
        right={0}
        bottom={-2}
        borderRadius={2}
        position="absolute"
        bgcolor="blue.500"
      />
    </Box>
  );
};

const TabsBar = ({tabs, selectedTabId, onTabClick}: PropTypes) => (
  <Box display="flex">
    {(tabs || []).map((tab) => (
      <TabItem
        label={tab.label}
        id={tab.id}
        onClick={onTabClick}
        isSelected={selectedTabId === tab.id}
        key={`tab-bar-${tab.id}`}
      />
    ))}
  </Box>
);

export default TabsBar;
