// @flow
import React from 'react';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import {getUniqueId} from 'common/utils/guid';
import './MailChipInput.module.scss';

const EMAIL_VALIDATION_REGEX = /.+@.+\..+/;

export const getChipItem = (value, items) => {
  const valueTrimed = value.trim();

  if (!valueTrimed) {
    return null;
  }

  let error = '';
  if (!EMAIL_VALIDATION_REGEX.test(valueTrimed)) {
    error = 'Some mail addresses are not valid.';
  }
  if (items.some((item) => item.value === valueTrimed)) {
    error = 'Cannot send more than one invite per email.';
  }
  return {
    label: valueTrimed,
    value: valueTrimed,
    error,
    id: getUniqueId(),
  };
};

type PropTypes = {
  items: Array,
  value: string,
  automationId: string,
  onValueChange: Function,
  onRemoveItem: Function,
  onAddItem: Function,
  onBlur: Function,
  fullWidth: boolean,
};

export default class MailChipsInput extends React.PureComponent {
  props: PropTypes;

  setInputRef = (el) => {
    this.inputRef = el;
  };

  handleRootClick = () => {
    if (this.inputRef) {
      this.inputRef.focus();
    }
  };

  handleChange = (e) => {
    this.props.onValueChange(e.target.value);
  };

  addChipItem = () => {
    if (this.props.value) {
      const chipItem = getChipItem(this.props.value, this.props.items);
      if (chipItem) {
        this.props.onAddItem(chipItem);
      }
    }
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  handleKeyDown = (e) => {
    if (e.key === 'Backspace' && !e.target.value) {
      this.props.onRemoveItem(this.props.items[this.props.items.length - 1]);
    }

    if (['Enter', 'Tab', ',', ' '].includes(e.key)) {
      e.preventDefault();
      this.addChipItem();
    }
  };

  handlePaste = (e) => {
    e.preventDefault();

    const paste = e.clipboardData.getData('text');
    const emails = paste.split(',');
    const processedEmails = this.props.items.map((item) => ({...item}));

    if (emails.length === 1) {
      this.props.onValueChange(emails[0].trim());
    } else {
      emails.forEach((email) => {
        const trimedEmail = email.trim();

        const chipItem = getChipItem(trimedEmail, processedEmails);
        if (chipItem) {
          this.props.onAddItem(chipItem);
        }

        processedEmails.push({
          label: trimedEmail,
          value: trimedEmail,
        });
      });
    }
  };

  render() {
    const {value, items, onRemoveItem, automationId, fullWidth} = this.props;

    return (
      <div styleName={fullWidth ? 'root fullWidth' : 'root'} onClick={this.handleRootClick}>
        {items.map((item) => (
          <span key={`email-chip-${item.id}`} onClick={this.handleChipRemoved}>
            <InnerChip expression={item} onRemoveClicked={onRemoveItem} type={`mail ${item.error ? 'invalid' : ''}`} />
          </span>
        ))}
        <input
          type="text"
          ref={this.setInputRef}
          onChange={this.handleChange}
          onKeyDown={this.handleKeyDown}
          onPaste={this.handlePaste}
          onBlur={this.addChipItem}
          value={value}
          automation-id={automationId}
          placeholder={!items.length ? 'Seperate email addresses by using a comma' : ''}
          styleName="input-field"
        />
      </div>
    );
  }
}
