// @flow
import React, {Fragment} from 'react';
import SnackBar, {TYPES} from 'common/componentsV2/snackBar/SnackBar';
import './StyleGuideComponents.module.scss';

export default class SnackbarSection extends React.PureComponent {
  render() {
    const errorParams = {
      title: 'Title (Optional)',
      description: 'Description (Optional)',
      moreInfo: 'moreInfo (Optional)',
    };

    const warningParams = {
      title: 'Title (Optional)',
      moreInfo: 'moreInfo (Optional)',
    };

    const infoParams = {
      title: 'Title (Optional)',
      description: 'Description (Optional)',
    };

    const successParams = {
      title: 'Title (Optional)',
      description: 'Description (Optional)',
      moreInfo: 'moreInfo (Optional)',
      children: <span>Child (Optional)</span>,
    };

    return (
      <Fragment>
        <h4>SnackBars</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Error Snackbar</h5>
            <SnackBar notificationData={errorParams} isDismissible={false} type={TYPES.ERROR} />
          </div>

          <div styleName="item">
            <h5>Warning Snackbar</h5>
            <SnackBar notificationData={warningParams} isDismissible={false} type={TYPES.WARNING} />
          </div>

          <div styleName="item">
            <h5>Info Snackbar</h5>
            <SnackBar notificationData={infoParams} isDismissible={false} type={TYPES.INFO} />
          </div>

          <div styleName="item">
            <h5>Success Snackbar</h5>
            <SnackBar notificationData={successParams} isDismissible={false} type={TYPES.SUCCESS} />
          </div>
        </div>
      </Fragment>
    );
  }
}
