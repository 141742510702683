import {createSelector} from 'reselect';
import moment from 'moment';
import {getFormattedDateMini} from 'common/utils/dateService';

const EMPTY_ARRAY = [];

export const getTrainTracker = (state) => state.trainTracker;

// ****************Views*****************

export const getTrainTrackerViews = createSelector(
  getTrainTracker,
  (i) => i.views,
);

export const getTrainTrackerPage = createSelector(
  getTrainTrackerViews,
  (i) => i.trainTrackerPage,
);

export const getTrainTrackerExpandedPullReqId = createSelector(
  getTrainTrackerPage,
  (page) => page.expandedPullReqId,
);

export const getTrainTrackerSearchString = createSelector(
  getTrainTrackerPage,
  (page) => page.searchString,
);

// ****************Data*****************

export const getTrainTrackerData = createSelector(
  getTrainTracker,
  (i) => i.data,
);

export const getFetchedTrainTracker = createSelector(
  getTrainTrackerData,
  (i) => i.fetchTrainTracker,
);

export const getFetchedTrainTrackerIsLoading = createSelector(
  getFetchedTrainTracker,
  (i) => i.isLoading,
);

export const getFetchedTrainTrackerData = createSelector(
  getFetchedTrainTracker,
  (i) => i.data,
);

export const getFetchedTrainTrackerDataProcessed = createSelector(
  getFetchedTrainTrackerData,
  getTrainTrackerSearchString,
  (data, searchString) => {
    if (!data.pullRequests || !data.environments) {
      return EMPTY_ARRAY;
    }

    const sortedPrs = data.pullRequests
      .filter((pr) => {
        const str = (
          pr.description +
          pr.title +
          pr.destination.branch.name +
          pr.source.branch.name +
          pr.author.display_name
        ).toLowerCase();
        return str.includes(searchString.toLowerCase());
      })
      .sort((a, b) => moment(b.updated_on).unix() - moment(a.updated_on).unix());

    const res = sortedPrs.map((pr) => {
      const prWithEnv = {
        ...pr,
        updated_on: moment(pr.updated_on).format('lll'),
        updated_on_mini: getFormattedDateMini(moment(pr.updated_on).unix()),
        created_on: moment(pr.created_on).format('lll'),
        environments: [],
      };

      Object.keys(data.environments).forEach((env) => {
        const envPrs = data.environments[env].map((envPr) => envPr.prId);
        if (envPrs.includes(pr.id)) {
          prWithEnv.environments.push(env);
        }
      });

      return prWithEnv;
    });

    return res;
  },
);
