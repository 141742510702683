import {composeReducers} from 'common/utils/reducers';
import {DURATION_OPTIONS} from 'alerts.management/services/durationAndScaleService';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const initialDirection = [
  {
    label: 'Drops',
    value: 'down',
  },
];

const initialState = {
  firstMeasure: EMPTY_OBJECT,
  secondMeasure: EMPTY_OBJECT,
  selectedDimension: EMPTY_ARRAY,
  selectedDirection: initialDirection,
  selectedTimeScale: 'hours',
  selectedDurationValue: DURATION_OPTIONS.hours.find((option) => option.value === 1),
  selectedRecipients: EMPTY_ARRAY,
  isRatio: false,
  newAlertModalOpen: false,
};

const simpleAlertReducer = composeReducers((state = {...initialState}, {type, payload, meta}) => {
  switch (type) {
    case actions.simpleAlertReset.TYPE: {
      return {
        ...initialState,
        newAlertModalOpen: state.newAlertModalOpen,
      };
    }
    case actions.simpleAlertSetFirstMeasure.TYPE: {
      return {
        ...state,
        firstMeasure: payload,
        secondMeasure: EMPTY_OBJECT,
        selectedDimension: EMPTY_ARRAY,
        selectedDirection: initialDirection,
      };
    }
    case actions.simpleAlertSetSecondMeasure.TYPE: {
      return {
        ...state,
        secondMeasure: payload,
        selectedDimension: EMPTY_ARRAY,
        selectedDirection: initialDirection,
      };
    }
    case actions.simpleAlertSetDimensions.TYPE: {
      return {
        ...state,
        selectedDimension: payload,
      };
    }
    case actions.simpleAlertSetDirection.TYPE: {
      return {
        ...state,
        selectedDirection: payload,
      };
    }
    case actions.simpleAlertSetTimeValue.TYPE: {
      return {
        ...state,
        selectedDurationValue: payload,
      };
    }
    case actions.simpleAlertSetTimeScale.TYPE: {
      if (meta && meta.rollup) {
        return {
          ...state,
          selectedTimeScale: payload,
          selectedDurationValue: DURATION_OPTIONS[payload].find((option) => option.rollup === meta.rollup),
        };
      }
      return {
        ...state,
        selectedTimeScale: payload,
        selectedDurationValue: DURATION_OPTIONS[payload].find((option) =>
          payload === 'minutes' ? option.value === 5 : option.value === 1,
        ),
      };
    }
    case actions.simpleAlertSetRecipients.TYPE: {
      return {
        ...state,
        selectedRecipients: payload,
      };
    }
    case actions.simpleAlertSetIsRatio.TYPE: {
      return {
        ...state,
        isRatio: payload,
        selectedDimension: EMPTY_ARRAY,
      };
    }
    case actions.toggleSimpleAlertModal.TYPE: {
      return {
        ...state,
        newAlertModalOpen: payload,
      };
    }
    default:
      return state;
  }
});

export default simpleAlertReducer;
