// @flow
import React, {PureComponent, Fragment} from 'react';
import {TransitionGroup, CSSTransition} from 'react-transition-group';
import AlertListItem from './AlertListItem';
import './AlertGroupsList.module.scss';

type PropTypes = {
  alertGroups: Array<Object>,
  uiGroupName: string,
  isAlertsListScrolling: Boolean,
};

export default class AlertGroupsList extends PureComponent {
  props: PropTypes;

  render() {
    const {alertGroups, uiGroupName, isAlertsListScrolling} = this.props;

    return (
      <Fragment>
        {!!alertGroups.alertGroups.length && (
          <TransitionGroup>
            {alertGroups.alertGroups.map((group) => (
              <CSSTransition key={`fade_${group.id}`} exit enter timeout={500} classNames="add-remove-animate-row">
                <div key={group.id} styleName="grouped-alerts-panel" automation-id="alertConsolePanelItem">
                  <div styleName="grouped-alerts" id="groupedAlerts">
                    {group.alerts.map((alert, index) => (
                      <AlertListItem
                        key={alert.id}
                        id={`listItem_${index}`}
                        alert={alert}
                        uiGroupName={uiGroupName}
                        index={index}
                        isAlertsListScrolling={isAlertsListScrolling}
                      />
                    ))}
                  </div>
                </div>
              </CSSTransition>
            ))}
          </TransitionGroup>
        )}
      </Fragment>
    );
  }
}
