import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const initialState = {
  isGroupModalOpen: false,
  editingGroupId: null,
  groupName: '',
  selectedColor: null,
  selectedUsers: [],
  deleteModal: {
    isOpen: false,
    groupId: null,
    isDelegate: false,
    delegateUserId: 'andt-none',
  },
};

const groupsReducer = composeReducers((state = {...initialState}, {type, payload}) => {
  switch (type) {
    case actions.toggleGroupsModal.TYPE: {
      if (!payload.isOpen) {
        return {
          ...initialState,
        };
      }
      return {
        ...state,
        isGroupModalOpen: payload.isOpen,
        editingGroupId: payload.groupId || null,
      };
    }
    case actions.setGroupProps.TYPE: {
      return {...state, ...payload};
    }
    case actions.toggleDeleteGroupModal.TYPE: {
      return {
        ...state,
        deleteModal: {
          isOpen: payload.isOpen,
          groupId: payload.groupId || null,
          isDelegate: false,
          delegateUserId: 'andt-none',
        },
      };
    }
    case actions.setDeleteGroupModalKeyVal.TYPE: {
      return {
        ...state,
        deleteModal: {
          ...state.deleteModal,
          ...payload,
        },
      };
    }
    default:
      return state;
  }
});

export default groupsReducer;
