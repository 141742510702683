// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setSelectedStreamGaAccount,
  setSelectedStreamGaProperty,
  setSelectedStreamGaView,
  getGoogleAnalyticsAccountsViewsProperties as getGoogleAnalyticsAccountsViewsPropertiesAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable, isStreamRunning} from 'bc/services/dataStreamService';
import GaDropdown from './GaDropdown';

type PropTypes = {
  isLoadingAccounts: boolean,
  accounts: Array,
  account: Object,

  isLoadingProperties: boolean,
  properties: Array,
  property: Object,

  isLoadingViews: boolean,
  views: Array,
  view: Object,

  dataStream: Object,

  setSelectedStreamGaAccount: Function,
  setSelectedStreamGaProperty: Function,
  setSelectedStreamGaView: Function,
  getGoogleAnalyticsAccountsViewsProperties: Function,
};

class Accounts extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {
      getGoogleAnalyticsAccountsViewsProperties,
      dataStream: {dataSourceId, accountId, propertyId},
    } = this.props;
    getGoogleAnalyticsAccountsViewsProperties({
      dataSourceId,
      accountId,
      propertyId,
    });
  }

  render() {
    const isEditable = isStreamEditable(this.props.dataStream.state) || isStreamRunning(this.props.dataStream.state);

    return (
      <div className="shell-col">
        <PanelHeader title="Web Site Data" isEditable={isEditable} />
        <GaDropdown
          isLoading={this.props.isLoadingAccounts}
          items={this.props.accounts}
          item={this.props.account}
          disabled={!isEditable}
          onChange={(v) => this.props.setSelectedStreamGaAccount({accountId: v})}
          ddlId="ga_accounts_ddl"
          ddlTitle="Choose account"
          placeholder="Choose an account"
        />

        <GaDropdown
          isLoading={this.props.isLoadingProperties}
          items={this.props.properties}
          item={this.props.property}
          disabled={!isEditable}
          onChange={(v) => this.props.setSelectedStreamGaProperty({propertyId: v})}
          ddlId="ga_properties_ddl"
          ddlTitle="Choose property"
          placeholder="Choose a property"
        />

        <GaDropdown
          isLoading={this.props.isLoadingViews}
          items={this.props.views}
          item={this.props.view}
          disabled={!isEditable}
          onChange={(v) => this.props.setSelectedStreamGaView({viewId: v})}
          ddlId="ga_views_ddl"
          ddlTitle="Choose view"
          placeholder="Choose a view"
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isLoadingAccounts: selectors.getGoogleAnalyticsAccounts(state).isLoading,
    accounts: selectors.getGoogleAnalyticsAccountsItems(state),
    account: selectors.getGoogleAnalyticsSelectedAccount(state),

    isLoadingProperties: selectors.getGoogleAnalyticsProperties(state).isLoading,
    properties: selectors.getGoogleAnalyticsPropertiesItems(state),
    property: selectors.getGoogleAnalyticsSelectedProperty(state),

    isLoadingViews: selectors.getGoogleAnalyticsViews(state).isLoading,
    views: selectors.getGoogleAnalyticsViewsItems(state),
    view: selectors.getGoogleAnalyticsSelectedView(state),

    dataStream: selectors.getSelectedDataStream(state),
  }),
  {
    setSelectedStreamGaAccount,
    setSelectedStreamGaProperty,
    setSelectedStreamGaView,
    getGoogleAnalyticsAccountsViewsProperties: getGoogleAnalyticsAccountsViewsPropertiesAction,
  },
)(Accounts);
