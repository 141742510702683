import React, {useEffect} from 'react';
import Highcharts from 'highcharts';
import {getHchartConfig, ALERTS_TRIGGERED} from './columnChartSettingsService';

require('highcharts/highcharts-more')(Highcharts);
require('highcharts/modules/solid-gauge')(Highcharts);

type PropTypes = {
  id: String,
  width: Number,
  height: Number,
  data: Array<String>,
  tooltipFormatter: Object,
};

const ColumnChart = (props: PropTypes) => {
  let hchart = null;
  const {id, width, height, data, tooltipFormatter} = props;

  useEffect(() => {
    hchart = Highcharts.chart(id, getHchartConfig(data, ALERTS_TRIGGERED, true, tooltipFormatter));
    return () => {
      if (hchart) {
        hchart.destroy();
      }
    };
  }, [data]);

  return (
    <div
      id={id}
      style={{
        minWidth: width,
        maxWidth: width,
        height,
        margin: '0',
      }}
    >
      Chart is here
    </div>
  );
};

export default ColumnChart;
