import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const dataSourceTypes = composeReducers(
  (
    state = {
      filterTextActual: '',
      filterTextInput: '',
    },
    {type, payload},
  ) => {
    switch (type) {
      case actions.setDataSourceTypeFilterTextInput.TYPE:
        return {...state, filterTextInput: payload};
      case actions.setDataSourceTypeFilterText.TYPE:
        return {...state, filterTextActual: payload};
      default:
        return state;
    }
  },
  {types: makeAsyncReducer(actions.fetchDataSourceTypes, {defaultData: EMPTY_ARRAY})},
  {integrationTypes: makeAsyncReducer(actions.fetchDataSourceIntegrationTypes, {defaultData: EMPTY_ARRAY})},
);

export default dataSourceTypes;
