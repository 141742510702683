/* eslint-disable */
import {createSelector} from 'reselect';
import {get, isEmpty, omitBy} from 'lodash';
import {selectors as commonSelectors} from 'common';
import {DEFAULT_PARAMS} from 'dashboards/services/dashboardService';
import moment from 'moment';
import * as profileSelectors from 'profile/store/selectors';
import {getUserProfile, isAnodotAdmin, isCustomerAdmin} from 'profile/store/selectors';
import {cleanupSpecialChars} from 'metrics/services/metricsService';
import {
  getFullyFunctionalDataStreams,
  getSimpleAlertFirstMeasure,
  getSimpleAlertSecondMeasure,
} from 'alerts.management/store/selectors';
import {getCacheData} from 'metrics/store/selectors';
import {COLOR_NAME} from 'common/componentsV2/ColorPicker';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const {getDashboards} = commonSelectors;

// ****************Views*****************

// ****************Data*****************

export const getDataRoot = createSelector(
  getDashboards,
  (i) => i.data,
);

export const getViewRoot = createSelector(
  getDashboards,
  (i) => i.views,
);

export const getDashboardsV1 = createSelector(
  getDataRoot,
  (data) => get(data, 'fetchDashboardsV1', EMPTY_ARRAY),
);

export const getDashboardsV2 = createSelector(
  getDataRoot,
  (data) => get(data, 'fetchDashboards', EMPTY_ARRAY),
);

export const getDataDashboardsV1 = createSelector(
  getDashboardsV1,
  (data) => get(data, ['data', 'dashboards'], EMPTY_ARRAY),
);

export const getDataDashboardsV2 = createSelector(
  getDashboardsV2,
  (data) => get(data, ['data', 'dashboards'], EMPTY_ARRAY),
);

export const getDataDashboards = createSelector(
  getDataDashboardsV1,
  getDataDashboardsV2,
  (dataDashboardsV1, dataDashboardsV2) => {
    const computedDataDashboardV1 = dataDashboardsV1.map((d) => ({
      ...d,
      color: COLOR_NAME.GRAY_350,
      icon: 'DefaultOld',
      isV1: true,
    }));
    return [...computedDataDashboardV1, ...dataDashboardsV2];
  },
);

export const getTotalDashboards = createSelector(
  getDashboardsV1,
  getDashboardsV2,
  (dataDashboardV1, dataDashboardV2) => {
    const totalV1 = get(dataDashboardV1, ['data', 'totalDashboards'], 0);
    const totalV2 = get(dataDashboardV2, ['data', 'totalDashboards'], 0);
    return totalV1 + totalV2;
  },
);

export const getSortingDashboards = createSelector(
  getViewRoot,
  (view) => get(view, 'sorting', EMPTY_OBJECT),
);

export const getFetchDashboard = createSelector(
  getDataRoot,
  (data) => get(data, 'fetchDashboard', EMPTY_OBJECT),
);

export const getIsStatTileModal = createSelector(
  getViewRoot,
  (view) => get(view, 'isStatTileModal', false),
);

export const getChartDataPoints = createSelector(
  getDataRoot,
  (data) => data.chartData,
);

export const getChartDataLoading = createSelector(
  getDataRoot,
  (data) => data.chartDataLoading,
);

export const getChartActualData = createSelector(
  getDataRoot,
  (data) => data.chartActualData,
);

export const getChartDataPointsFactory = (chartId) =>
  createSelector(
    getChartDataPoints,
    (chartData) => get(chartData, chartId, EMPTY_OBJECT),
  );

export const getChartDataPointsLoadingFactory = (chartId) =>
  createSelector(
    getChartDataLoading,
    (chartDataLoading) => get(chartDataLoading, chartId, EMPTY_OBJECT),
  );

export const getExpressionErrorsFactory = (chartId, expressionTreeIds) =>
  createSelector(
    getChartActualData,
    (data) =>
      Object.entries(get(data, chartId, EMPTY_OBJECT))
        .filter(([key]) => {
          return !expressionTreeIds || expressionTreeIds.includes(key);
        })
        .reduce((acc, [key, value]) => {
          acc[key] = get(value, 'validation');
          return acc;
        }, {}),
  );

export const getChartData = createSelector(
  getDataRoot,
  (data) => get(data, 'chartData', EMPTY_OBJECT),
);

export const getUpdateDashboard = createSelector(
  getDataRoot,
  (data) => get(data, 'updateDashboard', EMPTY_OBJECT),
);

export const getUploadDashboard = createSelector(
  getDataRoot,
  (data) => get(data, 'uploadDashboard', EMPTY_OBJECT),
);

export const getUploadDashboardIsLoading = createSelector(
  getUploadDashboard,
  (data) => get(data, 'isLoading', false),
);

export const getCopyTile = createSelector(
  getDataRoot,
  (data) => get(data, 'copyTile', EMPTY_OBJECT),
);

export const getDashboardUserSettings = createSelector(
  getDataRoot,
  (data) => get(data, 'fetchDashboardUserSettings', EMPTY_OBJECT),
);

export const getUpdateDashboardUserSettings = createSelector(
  getDataRoot,
  (data) => get(data, 'updateDashboardUserSettings', EMPTY_OBJECT),
);

export const getCreateDuplicateDashboards = createSelector(
  getDataRoot,
  (data) => get(data, 'createDuplicateDashboards', EMPTY_OBJECT),
);

export const getUpdateDashboardSettings = createSelector(
  getDataRoot,
  (data) => get(data, 'updateDashboardSettings', EMPTY_OBJECT),
);

export const getDeleteDashboard = createSelector(
  getDataRoot,
  (data) => get(data, 'deleteDashboard', EMPTY_OBJECT),
);

export const getFetchDashboardsV1Loading = createSelector(
  getDashboardsV1,
  (data) => get(data, 'isLoading', false),
);

export const getFetchDashboardsV2Loading = createSelector(
  getDashboardsV2,
  (data) => get(data, 'isLoading', false),
);

export const getFetchDashboardsLoading = createSelector(
  getFetchDashboardsV1Loading,
  getFetchDashboardsV2Loading,
  (loadingV1, loadingV2) => {
    return [loadingV1, loadingV2].some((l) => l === true);
  },
);

export const getFetchDashboardTotal = createSelector(
  getViewRoot,
  (i) => get(i, 'fetchDashboardsTotal.data', 0),
);

export const getIsLoadingFetchDashboardTotal = createSelector(
  getViewRoot,
  (i) => get(i, 'fetchDashboardsTotal.isLoading', false),
);

export const getLastViewIsLoading = createSelector(
  getViewRoot,
  (i) => get(i, 'sorting.isLoading', false),
);

export const isOpenFilters = createSelector(
  profileSelectors.getMeAppSettings,
  (settings) => get(settings, 'dashboards.isFiltersBarOpen', true),
);

export const getQueryParamsData = createSelector(
  profileSelectors.getMeAppSettings,
  (appSettings) => get(appSettings, 'dashboards.queryParams', EMPTY_OBJECT),
);

export const getDashboardListV1 = createSelector(
  getViewRoot,
  (dashboards) => dashboards.dashboardListV1 || EMPTY_ARRAY,
);

export const getDashboardListV2 = createSelector(
  getViewRoot,
  (dashboards) => dashboards.dashboardListV2 || EMPTY_ARRAY,
);

export const getQueryParamsViews = createSelector(
  getViewRoot,
  (dashboards) => get(dashboards, 'queryParams', EMPTY_OBJECT),
);

export const getTagsOptions = createSelector(
  getDataDashboards,
  (dashboards) => {
    const tagsOptions = [];
    dashboards.forEach((dashboard) =>
      dashboard.tags.forEach((tag) => {
        if (!tagsOptions.find((existedTag) => existedTag.value === tag.id)) {
          tagsOptions.push({
            value: tag.id,
            label: tag.display,
          });
        }
      }),
    );
    tagsOptions.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    return tagsOptions;
  },
);

export const getTagsList = createSelector(
  getDataDashboards,
  (dashboards) => {
    const tags = [];
    dashboards.forEach((dashboard) => {
      if (dashboard.tags) {
        dashboard.tags.forEach((dashboardTag) => {
          const t = tags.find((tag) => tag.name === dashboardTag.display);
          if (t) {
            t.count += 1;
          } else {
            tags.push({name: dashboardTag.display, count: 1});
          }
        });
      }
    });
    tags.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    return tags;
  },
);

export const getChangedFilters = createSelector(
  getQueryParamsViews,
  (filters) => {
    const obj = omitBy(filters, (value, key) => value === DEFAULT_PARAMS[key]);
    return Object.keys(obj).map((item) => ({[item]: obj.item}));
  },
);

export const getTagsUpdatedAt = createSelector(
  getViewRoot,
  (dashboard) =>
    get(
      dashboard,
      'fetchDashboardsTags.updateAt',
      moment()
        .unix()
        .valueOf(),
    ),
);

export const getSimpleExpressionOptions = createSelector(
  getFullyFunctionalDataStreams,
  getCacheData,
  (streams, cacheData) => {
    const propsAndValList = cacheData.find((obj) => obj.index === 'simpleExpression');

    const measures = get(propsAndValList, 'fetchedData', [])
      .slice(1)
      .map((item) => item.value);

    const allMeasurementsList = measures.map((measure) => {
      const cleanMeasureName = cleanupSpecialChars(measure);
      return {
        value: cleanMeasureName,
        label: cleanMeasureName,
        streamName: measure,
        streamId: measure,
        dimensions: [],
        measure: cleanMeasureName,
        aggregation: '',
      };
    });
    const multiLevelMeasurementsList = [];
    const aggregation = {
      counter: 'sum',
      gauge: 'average',
    };
    (streams || []).forEach((item) => {
      const schemaId = item.id;
      const schemaName = item.name;
      const schemaMeasurements = item.schema.columns.filter((col) => col.type === 'metric' && !col.hidden);
      const schemaDimensions = item.schema.columns
        .filter((col) => col.type === 'dimension' && !col.hidden)
        .map((dim) => dim.name);

      schemaMeasurements.forEach((schemaMeasure) => {
        const cleanMeasureName = cleanupSpecialChars(schemaMeasure.name);
        allMeasurementsList.push({
          value: cleanMeasureName,
          label: cleanMeasureName,
          streamName: schemaName,
          streamId: schemaId,
          dimensions: schemaDimensions,
          measure: cleanMeasureName,
          aggregation: aggregation[schemaMeasure.targetType],
        });
      });
    });

    allMeasurementsList.forEach((measure) => {
      const index = multiLevelMeasurementsList.findIndex((item) => item.value === measure.value);

      if (index === -1) {
        multiLevelMeasurementsList.push(measure);
      } else if (multiLevelMeasurementsList[index].multi) {
        multiLevelMeasurementsList[index].multi.push({...measure, value: measure.streamId, label: measure.streamName});
      } else {
        multiLevelMeasurementsList.push({
          value: multiLevelMeasurementsList[index].value,
          label: multiLevelMeasurementsList[index].label,
          multi: [
            {
              ...multiLevelMeasurementsList[index],
              value: multiLevelMeasurementsList[index].streamId,
              label: multiLevelMeasurementsList[index].streamName,
            },
            {...measure, value: measure.streamId, label: measure.streamName},
          ],
        });
        multiLevelMeasurementsList.splice(index, 1);
      }
    });
    multiLevelMeasurementsList.forEach((multilevelMeasurement) => {
      if (multilevelMeasurement.multi) {
        multilevelMeasurement.multi.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
      }
    });
    multiLevelMeasurementsList.sort((a, b) => a.label.toLowerCase().localeCompare(b.label.toLowerCase()));
    return multiLevelMeasurementsList;
  },
);

export const getDimensions = createSelector(
  getSimpleAlertFirstMeasure,
  getSimpleAlertSecondMeasure,
  getCacheData,
  (firstMeasure, secondMeasure, cacheData) => {
    const dimensions1 = isEmpty(firstMeasure.dimensions)
      ? get(cacheData.find((obj) => obj.index === `dimensions-${firstMeasure.value}`), 'fetchedData', [])
          .slice(1)
          .map((item) => item.value)
      : firstMeasure.dimensions;
    const dimensions2 = isEmpty(secondMeasure.dimensions)
      ? get(cacheData.find((obj) => obj.index === `dimensions-${secondMeasure.value}`), 'fetchedData', [])
          .slice(1)
          .map((item) => item.value)
      : secondMeasure.dimensions;
    if (Object.keys(secondMeasure).length === 0) {
      return dimensions1;
    }
    const ret = [];
    dimensions1.forEach((dim1) => {
      dimensions2.forEach((dim2) => {
        if (dim1 === dim2) {
          ret.push(dim1);
        }
      });
    });
    return ret;
  },
);

export const getDashboardsIdOptions = createSelector(
  profileSelectors.getProfileId,
  getDataDashboards,
  profileSelectors.getUserProfile,
  (userId, dashboards, userData) =>
    dashboards
      .filter((d) => d.ownerUser === userId || get(userData, 'groups', []).includes(d.ownerUser))
      .map((dashboard) => ({value: dashboard._id, label: dashboard.name})),
);

export const getAllFilters = createSelector(
  getDataRoot,
  (data) => data.getAllFilters,
);

export const getDashboardDataFactory = (dashboardId) =>
  createSelector(
    getDataRoot,
    (data) => get(data, `dashboardData[${dashboardId}]`, EMPTY_OBJECT),
  );

export const getIsDashboardOwnerSelectorFactory = (dashboardId) =>
  createSelector(
    getDataRoot,
    getUserProfile,
    isAnodotAdmin,
    isCustomerAdmin,
    (data, userData, isAnodotA, isCustomerA) => {
      const dashboard = get(data, `dashboardData[${dashboardId}]`, EMPTY_OBJECT);
      return (
        userData._id === get(dashboard, 'data.ownerUser') ||
        get(userData, 'groups', []).includes(get(dashboard, 'data.ownerUser')) ||
        isAnodotA ||
        isCustomerA
      );
    },
  );

export const getAnonymousInvitation = createSelector(
  getDataRoot,
  (data) => data.getAnonymousInvitation,
);

export const revokeAnonymousInvitations = createSelector(
  getDataRoot,
  (data) => data.revokeAnonymousInvitations,
);

export const getTriggeredAlerts = createSelector(
  getDataRoot,
  (data) => data.getTriggeredAlerts,
);

export const getAddTile = createSelector(
  getDataRoot,
  (data) => data.addTile,
);
