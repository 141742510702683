import React from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';

const validate = (value) => {
  return !value && 'Jira User is mandatory';
};

const JiraUser = ({isCreate}: {isCreate: boolean}) => {
  const {
    input: {value, onChange, onBlur},
    meta,
  } = useField('jiraUser', {validate});
  return (
    <div>
      <div>JIRA User</div>
      <Input
        fullSize
        isDisabled={!isCreate}
        placeHolder="Select something"
        automationId="jiraUser"
        isInvalid={meta.touched && meta.error}
        invalidMessage={meta.error}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default JiraUser;
