// @flow
import React from 'react';
import './FormDdlItems.module.scss';

type PropTypes = {
  label: String,
};

export default class FormDdlHeaderItem extends React.PureComponent {
  props: PropTypes;

  render() {
    const {label} = this.props;

    return (
      <div styleName="header-root">
        <span>{label}</span>
      </div>
    );
  }
}
