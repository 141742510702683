import React, {PureComponent} from 'react';
import '../SelectAndt.module.scss';

type PropTypes = {
  // accepted values by the original Select
  selectProps: any,
};

export default class UnoMultiValue extends PureComponent {
  props: PropTypes;

  render() {
    const {value, getOptionLabel} = this.props.selectProps;
    const getValueText = (values) => {
      switch (values.length) {
        case 0:
          return '';
        case 1:
          return getOptionLabel(values[0]);
        default:
          return `${values.length} Selected`;
      }
    };

    return (
      <div className="andt-dropdown-uno-multivalue" automation-id="dropdown-multi-val">
        <span styleName="custom-value">{getValueText(value)}</span>
      </div>
    );
  }
}
