import {getSignedRequest, getS3DeleteRequest, downloadFile} from 'bc/services/fileUploadToAwsService';
import * as api from './api';

export const CSV_TYPES_ARR = [
  'text/plain',
  'application/vnd.ms-excel',
  'text/x-csv',
  'application/csv',
  'application/x-csv',
  'text/csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/tab-separated-values',
];

export const uploadLookup = async (
  file,
  successFunc,
  errorFunc,
  progressFunc,
  folder = null,
  action = null,
  updateFileAction = null,
) => {
  const payload = await api.getLookupSignedRequest().toPromise();

  if (payload) {
    const payloadArr = payload.split('//');
    const payloadArrSplit = payloadArr[1].split(/\/(.*)/); // split on the first '/'
    getSignedRequest(
      file,
      payloadArrSplit[0],
      payloadArrSplit[1],
      successFunc,
      errorFunc,
      progressFunc,
      folder,
      action,
      updateFileAction,
    );
  }
};

export const getDeleteRequest = (file, successFunc, errorFunc, folder) => {
  api.getLookupSignedRequest().promise.then((payload) => {
    if (payload) {
      const payloadArr = payload.split('//');
      const payloadArrSplit = payloadArr[1].split(/\/(.*)/); // split on the first '/'
      getS3DeleteRequest(file, payloadArrSplit[0], payloadArrSplit[1], successFunc, errorFunc, folder);
    }
  });
};

export const getDownloadRequest = (file, successFunc, errorFunc, folder) => {
  api.getLookupSignedRequest().promise.then((payload) => {
    if (payload) {
      const payloadArr = payload.split('//');
      const payloadArrSplit = payloadArr[1].split(/\/(.*)/); // split on the first '/'
      downloadFile(file, payloadArrSplit[0], payloadArrSplit[1], successFunc, errorFunc, folder);
    }
  });
};

export const getUsedLookupIdsList = (listArr, item) => {
  if (item.transform) {
    if (item.transform.name === 'replacelookuptable' || item.transform.name === 'filterByLookup') {
      if (!listArr.includes(item.transform.parameters[0])) {
        listArr.push(item.transform.parameters[0]);
      }
    }
    getUsedLookupIdsList(listArr, item.transform);
  }
  return listArr;
};

const isLookupIdUsedInStream = (isNameFound, item, id) => {
  if (isNameFound) {
    return true;
  }
  if (item.transform) {
    if (item.transform.name === 'replacelookuptable' || item.transform.name === 'filterByLookup') {
      if (item.transform.parameters[0] === id) {
        /* eslint no-param-reassign: 0 */
        isNameFound = true;
      }
    } else {
      isLookupIdUsedInStream(isNameFound, item.transform, id);
    }
  }
  return isNameFound;
};

export const getUsingStreamsNamesList = (dataStreams, id) => {
  const namesArr = [];
  dataStreams.forEach((stream) => {
    if (stream.schema && stream.schema.columns) {
      stream.schema.columns.forEach((item) => {
        if (isLookupIdUsedInStream(false, item, id)) {
          namesArr.push(stream.name);
        }
      });
    }
  });
  return namesArr;
};
