import {post, get} from 'common/utils/http';

const root = 'api/v1/';
const lastView = 'api/v1/lastview';

//* ** metrics
export const fetchCustomerMetricsCount = ({payload}) =>
  post(
    `${root}search/metrics`,
    payload || {
      data: {
        expression: [
          {
            type: 'search',
            value: '*',
          },
        ],
      },
      params: {
        from: 0,
        includeCombined: true,
        size: 1,
        compositeCount: true,
      },
    },
  );

export const fetchCustomerMetricsCountLastDays = () => get(`${root}metrics/countLastDays?days=4`);

export const fetchMetricsTotal = () => get(`${root}metrics/total`);

export const fetchOriginTitleCache = () => post(`${root}search/origin/info`);

// EXPRESSION BUILDER //

export const fetchPropAndVal = ({payload}) => post(`${root}search/metrics/propandval`, payload);
export const fetchPropAndValListApi = ({payload}) => post(`${root}search/metrics/propsList`, payload);
export const fetchPropsApi = ({payload}) => post(`${root}search/metrics/props`, payload);
export const execute = ({payload}) => post(`api/v1/metrics/composite/execute?${payload.urlExt}`, payload.body);
export const validate = ({payload}) => post(`api/v1/metrics/composite/validate${payload.url}`, payload.body);
export const fetchFunctionsMetaData = () => get('api/v1/metrics/composite/meta');
export const fetchPropertiesApi = ({payload}) => post('api/v1/metrics/composite/properties', payload);
export const fetchCompositeName = ({payload}) => post(`api/v1/metrics/composite/names?${payload.urlExt}`, payload.body);
export const fetchOriginInfo = () => post('api/v1/search/origin/info');
export const fetchComposites = () => get('api/v1/metrics/composite');
export const createComposite = ({payload}) => post('api/v1/metrics/composite', payload);

export const setLastExpression = ({payload, meta}) => post(`${lastView}/${meta.userId}`, payload);
// eslint-disable-next-line no-unused-vars
export const fetchLastExpressionSearches = ({payload, meta}) =>
  get(`${lastView}/${meta.userId}?type=expression-searches`);
// eslint-disable-next-line no-unused-vars
export const fetchLastUsedMeasures = ({payload, meta}) => get(`${lastView}/${meta.userId}?type=last-measures`);
