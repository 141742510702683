// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {makeStyles} from '@material-ui/core/styles';
import {TYPES} from 'assets/services/service';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import Button, {COLORS} from 'common/componentsV2/Button';

type PropTypes = {
  title: String,
  type: String,
  isOpen: Boolean,
  useBySources: Array<Object>,
  onClose: Function,
};

const useStyles = makeStyles((theme) => ({
  btnsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sourceName: {
    fontSize: 14,
    color: theme.palette.gray['500'],
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '90%',
    display: 'inline-block',
  },
  sourceLink: {
    fontSize: 14,
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '90%',
    display: 'inline-block',
  },
}));

const WarningMessageModal = (props: PropTypes) => {
  const classes = useStyles();

  const goTo = (id) => {
    const {origin} = window.location;
    window.open(`${origin}/#!/r/alert-manager/edit/${id}/settings`);
  };

  const renderTitle = () => {
    const sourceName = props.type === TYPES.LOOKUP ? 'Reference Table' : 'Dynamic Routing';
    return `Cannot delete ${sourceName}`;
  };

  const renderSubTitle = () => {
    const typeName = props.title;
    const sourceName = props.type === TYPES.LOOKUP ? 'Streams' : 'Alerts';
    const sourceNameShort = props.type === TYPES.LOOKUP ? 'lookup' : ' routing';
    const sourceLength = props.useBySources.length;

    return `This ${sourceNameShort} table is used by ${sourceLength} of ${sourceName} and cannot be deleted.
Remove it from these ${sourceName} and then delete ${typeName}.`;
  };

  const renderBody = (sourceName, sourceId = null) => {
    switch (props.type) {
      case TYPES.LOOKUP:
        return (
          <li style={{listStyle: 'none'}}>
            <TypographyBox variant="caption" className={classes.sourceName}>
              {sourceName}
            </TypographyBox>
          </li>
        );
      case TYPES.DYNAMIC_ROUTING:
        return (
          <li style={{listStyle: 'none'}}>
            <a onClick={() => goTo(sourceId)} className={classes.sourceLink}>
              {sourceName}
            </a>
          </li>
        );
      default:
        return '';
    }
  };

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} size={MODAL_SIZES.SMALL} isCloseButtonHidden isStatic={false}>
      <Grid container>
        <Grid item sm={8}>
          <TypographyBox variant="h5" mb={1}>
            {renderTitle()}
          </TypographyBox>
        </Grid>
        <Grid item sm={12}>
          <TypographyBox variant="body1" mb={3}>
            {renderSubTitle()}
          </TypographyBox>
        </Grid>
        <Grid item sm={12} mb={3}>
          {props.useBySources.map((i) => (
            <TypographyBox key={i.sourceId} variant="caption" mb={1}>
              {renderBody(i.sourceName, i.sourceId)}
            </TypographyBox>
          ))}
        </Grid>
        <Grid item sm={12} className={classes.btnsContainer}>
          <Button text="Got It" onClick={() => props.onClose()} colorSchema={COLORS.BLUE_500} />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default WarningMessageModal;
