// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import DataTablePreviewModal from 'bc/components/streams/editor/common/DataTablePreviewModal';
import {get} from 'lodash';
import {fetchGoogleStoragePreview as fetchGoogleStoragePreviewAction} from 'bc/store/actions';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,

  // connect
  dataStream: Object,
  fetchGoogleStoragePreview: Function,
  isLoading: Boolean,
  previewData: Object,
};

class GoogleStorageFilePreview extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {
      dataStream: {projectId, bucket, dataSourceId, fileFormat, path, fileNameSuffix, fileNamePrefix, fileNamePattern},
      previewData,
      isLoading,
      fetchGoogleStoragePreview,
    } = this.props;

    if (!(previewData.rows || isLoading)) {
      fetchGoogleStoragePreview({
        projectId,
        bucket,
        fileFormat,
        dataSourceId,
        path,
        fileNameSuffix,
        fileNamePrefix,
        fileNamePattern,
      });
    }
  }

  render() {
    const {isOpen, onClose, dataStream, previewData, isLoading, fetchGoogleStoragePreview} = this.props;

    return (
      <DataTablePreviewModal
        isOpen={isOpen}
        onClose={onClose}
        title="File Preview"
        subTitle={get(dataStream, 'uiState.analysisResult.fileName', '')}
        previewData={previewData}
        isLoading={isLoading}
        fetchPreview={fetchGoogleStoragePreview}
      />
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    previewData: selectors.getGoogleStorageFilePreviewData(state),
    isLoading: selectors.getGoogleStorageFilePreview(state).isLoading,
  }),
  {
    fetchGoogleStoragePreview: fetchGoogleStoragePreviewAction,
  },
)(GoogleStorageFilePreview);
