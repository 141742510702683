import React from 'react';
import StreamEditorPanel from '../../StreamEditorPanel';
import SqlData from './sqlData/SqlData';
import SqlDimensionsAndMetrics from './sqlDimensionsAndMetrics/SqlDimensionsAndMetrics';
import SqlSchedular from './sqlSchedular/SqlSchedular';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

const SqlStreamEditor = () => (
  <div className="shell-col">
    <StreamEditorSteps>
      <SqlData />
      <SqlDimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <SqlSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default SqlStreamEditor;
