// @flow
import React from 'react';
import {Box} from '@material-ui/core';

const ResetZoomButton = (props: {resetZoomClicked: Function}) => (
  <Box
    position="absolute"
    display="flex"
    alignItems="center"
    justifyContent="center"
    left={70}
    top={15}
    width={95}
    height={24}
    borderRadius={12}
    bgcolor="white.500"
    onClick={props.resetZoomClicked}
    fontSize={14}
    fontWeight={500}
    color="gray.500"
    css={{
      cursor: 'pointer',
      opacity: 0.9,
      boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.2)',
    }}
  >
    Reset Zoom
  </Box>
);
export default ResetZoomButton;
