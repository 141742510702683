/* eslint-disable max-len */
import {get, post, stringifyQS} from 'common/utils/http';

const root = 'api/v1/topology/';
const metricsRoot = 'api/v1/metrics/';

export const fetchTopologyMapRegions = () => get(`${root}map/regions`);
export const fetchTopologyMapRegionSitesList = ({payload}) => get(`${root}map/regions/${payload.regionId}/sites/list`);

export const fetchTopologyAnomalies = ({payload}) => get(`${root}anomalies?${stringifyQS(payload)}`);
export const fetchTopologyAlerts = ({payload}) =>
  get(`${root}alerts?size=50&status=OPEN&order=desc&sort=startTime&${stringifyQS(payload)}`);

export const fetchMetricDataPoints = ({payload}) =>
  post(
    `${metricsRoot}composite/execute?fromDate=${payload.startTime}&toDate=${payload.endTime}&startBucketMode=${payload.startBucketMode}&includeBaseline=${payload.includeBaseline}&index=0&size=1&maxDataPoints=500&resolution=${payload.resolution}`,
    payload.body,
  );

export const fetchTopologyAnomaly = ({payload}) =>
  get(`${root}anomalies/${payload.anomalyId}?${stringifyQS(payload.qs)}`);
export const fetchTopologyAlert = ({payload}) =>
  get(`${root}alerts/${payload.alertId}?size=100&order=desc&sort=startTime&${stringifyQS(payload.qs)}`);
