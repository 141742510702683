import {composeReducers} from 'common/utils/reducers';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const EMPTY_OBJECT = {};

const panel = composeReducers((state = {numLastDays: EMPTY_OBJECT}, {type, payload}) => {
  switch (type) {
    case actions.setIsPanelOpen.TYPE: {
      return {
        ...state,
        isPanelOpen: payload,
      };
    }
    case actions.setNumLastDays.TYPE: {
      return {
        ...state,
        numLastDays: payload,
      };
    }
    default:
      return state;
  }
});

export default combineReducers({panel});
