import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {composeReducers} from 'common/utils/reducers';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import * as actions from '../actions';

const EMPTY_ARRAY = [];
const accounts = makeAsyncReducer(actions.fetchGoogleAdsAccounts, {
  defaultData: EMPTY_ARRAY,
  errorHandler: bcErrorHandler,
});

const templates = composeReducers((state = {isSavingNewTemplate: false, data: EMPTY_ARRAY}, {type, payload}) => {
  switch (type) {
    case actions.createStreamTemplate.TYPE:
      return {...state, isSavingNewTemplate: true, error: null};
    case actions.createStreamTemplate.success.TYPE:
      return {
        ...state,
        isSavingNewTemplate: false,
        error: null,
        data: [...state.data, payload],
      };
    case actions.createStreamTemplate.failure.TYPE:
      return {...state, isSavingNewTemplate: false, error: payload};

    default:
      return state;
  }
}, makeAsyncReducer(actions.fetchGoogleAdsTemplates, {defaultData: EMPTY_ARRAY}));

export default combineReducers({
  accounts,
  templates,
});
