// @flow
import React from 'react';
import SmartTooltip from 'common/components/SmartTooltip';
import {durationScales} from 'metrics/services/metricsService';
import {alertTypes} from 'alerts.management/services/alertsService';
import {broadcast} from 'common/utils/angularServices';
import ConditionContainer from './ConditionContainer';
import './NoDataCondition.module.scss';

const options = [{value: true, text: 'YES'}, {value: false, text: 'NO'}];

type PropTypes = {
  noDataAlert: Boolean,
  noDataDuration: Object,
  alertType: String,
  onChange: Function,
  onEnabledChange: Function,
};

class NoDataSelector extends React.PureComponent {
  props: PropTypes;

  state = {noDataTooltip: '', noDataError: false};

  validateEventName = 'e:andt.metrics.alerts.validateNoDataDuration';

  formatDuration = () => {
    const {noDataDuration} = this.props;
    return parseInt(noDataDuration.value / durationScales[noDataDuration.scale].seconds, 10);
  };

  handleValueChange = (val) => {
    const {noDataDuration} = this.props;
    const value = val * durationScales[noDataDuration.scale].seconds;
    this.handleChange(value, noDataDuration.scale);
  };

  handleScaleChange = (scale) => {
    const {noDataDuration} = this.props;
    const value = (noDataDuration.value / durationScales[noDataDuration.scale].seconds) * durationScales[scale].seconds;
    this.handleChange(value, scale);
  };

  handleChange = (value, scale, isEnabled) => {
    const data = {
      value,
      isEnabled:
        isEnabled !== undefined
          ? isEnabled
          : this.props.noDataAlert || this.props.alertType === alertTypes.noData.value,
    };
    broadcast(this.validateEventName, data);
    this.setState({noDataTooltip: data.validationObject.validationMessage, noDataError: !data.validationObject.valid});
    this.props.onChange({value, scale});
  };

  handleYesNoClicked = (val) => {
    this.props.onEnabledChange(val);
    this.handleChange(this.props.noDataDuration.value, this.props.noDataDuration.scale, val);
  };

  render() {
    return (
      <ConditionContainer
        title="No Data"
        subTitle={
          <span>
            Send Alert when there is no data for at least{' '}
            {this.props.noDataAlert || this.props.alertType === alertTypes.noData.value ? (
              <span styleName="emphasize">
                {`${this.formatDuration()} ${durationScales[this.props.noDataDuration.scale].text.toLowerCase()}`}
              </span>
            ) : (
              `X ${Object.values(durationScales)
                .map((s) => s.text.toLowerCase())
                .join('/')}`
            )}
          </span>
        }
      >
        {this.props.alertType !== alertTypes.noData.value && (
          <div styleName="buttons-container-yes-no">
            {options.map((s) => (
              <button
                type="button"
                key={s.value}
                automation-id="alertSettingsNoData{s.value}Button"
                className={`btn btn-raised btn-outline btn-outline-large ${
                  s.value === this.props.noDataAlert ? 'btn-outline-light' : 'btn-outline-dark'
                }`}
                onClick={() => this.handleYesNoClicked(s.value)}
              >
                {s.text}
              </button>
            ))}
          </div>
        )}
        {(this.props.noDataAlert || this.props.alertType === alertTypes.noData.value) && (
          <div styleName={`input-container ${this.props.alertType === alertTypes.noData.value ? 'input-alone' : ''}`}>
            <SmartTooltip content={this.state.noDataError ? this.state.noDataTooltip : null}>
              <input
                type="number"
                min="0"
                automation-id="alertSettingsNoDataTextbox"
                className={`dark-input ${this.state.noDataError ? 'invalid' : ''}`}
                value={this.formatDuration()}
                onChange={(e) => this.handleValueChange(e.target.value)}
              />
            </SmartTooltip>
            <NoDataSelectorScale scale={this.props.noDataDuration.scale} onChange={this.handleScaleChange} />
          </div>
        )}
      </ConditionContainer>
    );
  }
}

export default NoDataSelector;

const NoDataSelectorScale = (props: {scale: Object, onChange: Function}) => (
  <div styleName="buttons-container">
    {Object.values(durationScales).map((s) => (
      <button
        type="button"
        key={s.value}
        automation-id={`alertSettingsNoData${s.value}Button`}
        className={`btn btn-raised btn-outline btn-outline-large ${
          s.value === props.scale ? 'btn-outline-light' : 'btn-outline-dark'
        }`}
        onClick={() => props.onChange(s.value)}
      >
        {s.shortText}
      </button>
    ))}
  </div>
);
