import {values} from 'lodash';

const units = {
  human: {
    label: 'Human',
    id: 'human',
    units: [
      {
        label: 'Users',
      },
      {
        label: 'Customers',
      },
      {
        label: 'Visitors',
      },
    ],
  },
  marketing: {
    label: 'Marketing',
    id: 'marketing',
    units: [
      {
        label: 'Clicks',
      },
      {
        label: 'Views',
      },
      {
        label: 'Hits',
      },
    ],
  },
  geography: {
    label: 'Geo',
    id: 'geo',
    units: [
      {
        label: 'Countries',
      },
      {
        label: 'Cities',
      },
      {
        label: 'Sites',
      },
    ],
  },
  Percent: {
    label: 'Percent',
    id: 'percent',
    units: [
      {
        label: '%',
      },
    ],
  },

  it: {
    label: 'IT',
    id: 'it',
    units: [
      {
        label: 'Jobs',
      },
      {
        label: 'Processes',
      },
    ],
  },
  communication: {
    label: 'Communication',
    id: 'communication',
    units: [
      {
        label: 'Events',
      },
      {
        label: 'Messages',
      },
      {
        label: 'Emails',
      },
      {
        label: 'Pages',
      },
      {
        label: 'Packets',
      },
      {
        label: 'Downloads',
      },
      {
        label: 'Uploads',
      },
      {
        label: 'Bits/Second',
      },
    ],
  },
  frequency: {
    label: 'Frequency',
    id: 'frequency',
    units: [
      {
        label: 'Hz',
      },
      {
        label: 'MHz',
      },
      {
        label: 'GHz',
      },
      {
        label: 'RPM',
      },
    ],
  },
  data: {
    label: 'Data',
    id: 'data',
    units: [
      {
        label: 'Files',
      },
      {
        label: 'Bytes',
      },
      {
        label: 'KBytes',
      },
      {
        label: 'MBytes',
      },
      {
        label: 'GBytes',
      },
      {
        label: 'TBytes',
      },
    ],
  },
  time: {
    label: 'Time',
    id: 'time',
    units: [
      {
        label: 'Milliseconds',
      },
      {
        label: 'Seconds',
      },
      {
        label: 'Minutes',
      },
      {
        label: 'Hours',
      },
      {
        label: 'Days',
      },
      {
        label: 'Weeks',
      },
      {
        label: 'Months',
      },
      {
        label: 'Years',
      },
    ],
  },
  distance: {
    label: 'Distance',
    id: 'distance',
    units: [
      {
        label: 'cm',
      },
      {
        label: 'M',
      },
      {
        label: 'KM',
      },
      {
        label: 'Feet',
      },
      {
        label: 'Yards',
      },
      {
        label: 'Miles',
      },
    ],
  },
  speed: {
    label: 'Speed',
    id: 'speed',
    units: [
      {
        label: 'Km/h',
      },
      {
        label: 'MPH',
      },
    ],
  },
  temperature: {
    label: 'Temperature',
    id: 'temperature',
    units: [
      {
        label: 'Centigrade',
      },
      {
        label: 'Fahrenheit',
      },
    ],
  },
  Currencies: {
    label: 'Currency',
    id: 'currency',
    units: [
      {
        label: 'USD',
      },
      {
        label: 'EUR',
      },
      {
        label: 'GBP',
      },
      {
        label: 'CHF',
      },
      {
        label: 'CAD',
      },
      {
        label: 'AUD',
      },
      {
        label: 'JPY',
      },
      {
        label: 'INR',
      },
      {
        label: 'ILS',
      },
    ],
  },
};

const getUnitCategoties = () => {
  const catArr = [];
  values(units).forEach((cat) => {
    catArr.push(cat.id);
  });
  return catArr;
};

const getCategoryDetails = (catId) => values(units).filter((category) => category.id === catId)[0];

export default () => {
  let unitList = [];
  const unitCats = getUnitCategoties();
  unitCats.forEach((item) => {
    const curCat = getCategoryDetails(item);
    unitList.push({
      category: true,
      label: curCat.label,
      disabled: true,
    });
    unitList = unitList.concat(curCat.units.map((obj) => ({...obj, catLabel: curCat.label})));
  });
  return unitList;
};
