// @flow
import React from 'react';
import './TokenParameterInput.module.scss';
import {isEmpty} from 'lodash';
import SelectAndt, {THEME_DARK_LEAN, TYPE_NEW_LEAN} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  functionProperties: Object,
  onValueChange: Function, // f = (value, isValid, [validationErrors], isPristine) => {...}
  value: string,
  onTouched: Function,
};

export default class SelectionParameterInput extends React.PureComponent {
  props: PropTypes;

  onChange = (val) => {
    this.props.onTouched();
    const value = val;
    this.props.onValueChange(value, true, []);
  };

  render() {
    return (
      <div styleName="render-string">
        <SelectAndt
          automationId="simpleAlertDimensions"
          type={TYPE_NEW_LEAN}
          theme={THEME_DARK_LEAN}
          onChange={this.onChange}
          options={this.props.functionProperties.optionalValues}
          value={isEmpty(this.props.value) ? null : this.props.value}
          placeholder="Select"
          optionHeight={40}
          isClearable={false}
          closeMenuOnSelect
          menuWidth={250}
          getOptionLabel={(val) => val}
          getOptionValue={(val) => val}
          onMenuOpen={this.props.onTouched}
          offsetTop={5}
        />
      </div>
    );
  }
}
