import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';
// import data from './dataReducer';
import views from './viewsReducer';
import * as actions from '../actions';

const root = combineReducers({
  views,
  // data,
});

/* GABPAC - 4/11/19  - keeps the pattern used all over the rest of the system
 * where we have some kind of view and api system of reducer. In this case there
 * will not be much use of data, since the system will take care of it. But since
 * it was not clear where the Expression Builder was going to, the pattern was kept */

const expressionBuilder = composeReducers(root, (state, action) => {
  if (action.type === actions.resetExpressionBuilder.TYPE) {
    return root(undefined, {type: 'no-action'});
  }
  return state;
});

export default expressionBuilder;
