// @flow
import React from 'react';
import {Box} from '@material-ui/core';
import {useTheme} from '@material-ui/core/styles';
import SmartTooltip from 'common/componentsV2/Tooltip';

type PropTypes = {
  templates: Array,
  selectedTemplateId: String,
  disabled: boolean,
  onTemplateEditClick: Function,
  onSelectClick: Function,
};

const QueryTemplateItem = ({
  disabled,
  label,
  tooltip,
  id,
  isSelected,
  onEditClick,
  onSelectClick,
}: {
  disabled: boolean,
  label: String,
  tooltip: String,
  id: String,
  isSelected: boolean,
  onEditClick: Function,
  onSelectClick: Function,
}) => {
  const theme = useTheme();

  const editClicked = (ev) => {
    onEditClick(id);
    ev.preventDefault();
  };

  const selectClicked = (ev) => {
    if (!ev.isDefaultPrevented()) {
      onSelectClick(id);
    }
  };

  let iconColor = isSelected ? 'blue.500' : 'gray.500';
  if (disabled) {
    iconColor = 'gray.350';
  }

  return (
    <SmartTooltip placement="top" content={tooltip} delayHide={0}>
      <Box
        height={32}
        width="100%"
        display="flex"
        alignItems="center"
        borderRadius="6px"
        pl={1}
        pr={1}
        css={{
          pointerEvents: disabled ? 'none' : 'auto',
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: theme.palette.blue['100'],
          },
        }}
        onClick={selectClicked}
      >
        <Box
          className={['icon', isSelected ? 'icn-selection16-radioon' : 'icn-selection16-radiooff'].join(' ')}
          color={iconColor}
          height={16}
          width={16}
          fontSize={16}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexShrink={0}
        />
        <Box
          className="ellipsis"
          component="span"
          fontSize={14}
          lineHeight={1}
          pr={0.75}
          pl={0.75}
          color={disabled ? 'gray.350' : 'gray.500'}
        >
          {label}
        </Box>
        <Box
          className="icon icn-action16-edit"
          fontSize={14}
          width={20}
          color={disabled ? 'gray.350' : 'gray.400'}
          css={{
            '&:hover': {
              color: theme.palette.blue['500'],
              cursor: 'pointer',
            },
          }}
          onClick={editClicked}
        />
      </Box>
    </SmartTooltip>
  );
};

const QueryTemplates = ({templates, selectedTemplateId, disabled, onTemplateEditClick, onSelectClick}: PropTypes) => (
  <Box
    display="flex"
    flexDirection="column"
    css={{
      cursor: disabled ? 'not-allowed' : 'default',
      color: '#c6c6c6',
    }}
  >
    {(templates || []).map((template) => (
      <QueryTemplateItem
        disabled={disabled}
        tooltip={template.description}
        label={template.name}
        id={template.id}
        onEditClick={onTemplateEditClick}
        onSelectClick={onSelectClick}
        isSelected={selectedTemplateId === template.id}
        key={`query-templates-${template.id}`}
      />
    ))}
  </Box>
);

export default QueryTemplates;
