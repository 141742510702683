// @flow
import React from 'react';
import InnerChip from 'common/componentsV2/ExpressionBuilderV2/InnerChip';
import './SelectChipsInput.module.scss';
import SelectAndt, {THEME_HIGHLIGHTED_TRANSPARENT, TYPE_NEW_MULTI} from './ddl/selectAndt/SelectAndt';
import FormDdlActionBar from './ddl/multiSelectFormDdl/FormDdlActionBar';

type PropTypes = {
  options: Array,
  value: String,
  onRemove: Function,
  onChange: Function,
  placeholder: String,
};

export default class SelectChipsInput extends React.PureComponent {
  props: PropTypes;

  state = {
    menuIsOpen: false,
  };

  onMenuOpen = () => {
    this.setState({menuIsOpen: true});
  };

  onMenuClose = () => {
    this.setState({menuIsOpen: false});
  };

  render() {
    const {value, options, onRemove, onChange, placeholder} = this.props;
    const {menuIsOpen} = this.state;

    return (
      <div styleName="wrapper">
        <SelectAndt
          isInputVisible
          automationId="selectChipsInput"
          type={TYPE_NEW_MULTI}
          theme={THEME_HIGHLIGHTED_TRANSPARENT}
          onChange={onChange}
          options={options}
          value={value}
          isMulti
          onMenuOpen={this.onMenuOpen}
          onMenuClose={this.onMenuClose}
          placeholder={placeholder || ''}
          optionHeight={40}
          menuIsOpen={menuIsOpen}
          isClearable={false}
          closeMenuOnSelect={false}
          closeMenuOnScroll={false}
          forceResorting
          isCreatable
          onCreateOption={this.handleCreate}
          menuFooterComponent={
            <FormDdlActionBar isVisible isApplyDisabled={false} onApply={this.handleTagsApplyChanges} />
          }
          menuPlacement="bottom"
          customComponent={{
            DropdownIndicator: () => (
              <div styleName="root">
                {value.map((item) => (
                  <span key={`chip-${item.label}`} onClick={this.handleChipRemoved}>
                    <InnerChip expression={item} onRemoveClicked={onRemove} />
                  </span>
                ))}
              </div>
            ),
          }}
        />
      </div>
    );
  }
}
