// @flow

import React, {useState, useEffect, useCallback} from 'react';
import {isEqual, get} from 'lodash';
import Box from '@material-ui/core/Box';
import FormDdl from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdl';
import Button, {WIDTHS, COLORS} from 'common/componentsV2/Button';
import {useSelector, useDispatch} from 'react-redux';
import {selectTreeBranch} from 'metrics/store/actions';
import {getExpressionTrees, getSelectedExpressionId, getAllExpressionsSelected} from 'metrics/store/selectors';
import {TypographyBox} from 'common/componentsV2/boxTools';
import RadioButtonsList from 'common/componentsV2/RadioButtonsList';
import useByTreeExpManager from 'common/hooks/useByTreeExpManager';
import InputAuto from 'alerts.management/components/editor/simulationArea/utils/InputAuto';
import ChartType from './ChartType';
import {STACKING, Y_AXIS_TYPE} from '../seriesPropertiesConsts';
import SettingMenusSelectTree from './SettingMenusSelectTree';

const stackingOptions = [
  {label: 'None', value: null},
  {label: 'Normal', value: STACKING.NORMAL},
  {label: 'Percent', value: STACKING.PERCENT},
];

const yAxisOptions = [
  {label: 'Linear', value: Y_AXIS_TYPE.LINEAR},
  {label: 'Logarithmic', value: Y_AXIS_TYPE.LOGARITHMIC},
];

const yAxisDirectionOptions = [{label: 'Left', value: false}, {label: 'Right', value: true}];

const ChartRenderSettingsMenu = () => {
  const [initialSettings, setInitialSettings] = useState();
  const [isSettingsMenuOpen, setSettingsMenuOpen] = useState(false);
  const dispatch = useDispatch();

  const {makeByTreeExpChangeHandler, onByTreeExpIdChange, byTreeExp, byTreeExpMap, byTreeExpId} = useByTreeExpManager();

  const expressionTrees = useSelector(getExpressionTrees);
  const selectedExpressionId = useSelector(getSelectedExpressionId);
  const isAllSelected = useSelector(getAllExpressionsSelected);

  useEffect(() => {
    onByTreeExpIdChange(isAllSelected ? expressionTrees[0].id : selectedExpressionId);
  }, [selectedExpressionId, isAllSelected]);

  useEffect(() => {
    if (isSettingsMenuOpen) {
      setInitialSettings(byTreeExpMap);
    }
  }, [isSettingsMenuOpen]);
  const isDirty = !isEqual(initialSettings, byTreeExpMap);

  const expressions = useSelector(getExpressionTrees);

  const handleChangeTree = useCallback(
    (val) => {
      const branchId = get(expressionTrees[val], 'expressionTree.root.id');
      if (branchId) {
        dispatch(selectTreeBranch({branchId, selectedExpressionId: expressionTrees[val].id}));
      }

      onByTreeExpIdChange(expressionTrees[val].id);
    },
    [expressionTrees],
  );

  const popOverComponent = (
    <Box width={300} padding={2} className="idle-external-click">
      <TypographyBox fontSize={18} color="gray.500" fontWeight={500} mb={1}>
        Chart Settings
      </TypographyBox>
      {expressions.length > 1 && (
        <div>
          <TypographyBox fontSize={14} color="gray.400" mb={0.5}>
            Select Expression
          </TypographyBox>
          <SettingMenusSelectTree
            expressions={expressions}
            onSelectionChange={handleChangeTree}
            selected={expressionTrees.findIndex((tree) => tree.id === byTreeExpId)}
          />
        </div>
      )}
      <TypographyBox fontSize={14} color="gray.400" mb={0.5}>
        Chart Type
      </TypographyBox>
      <ChartType chartType={byTreeExp.type} onChange={makeByTreeExpChangeHandler('type')} />
      <TypographyBox fontSize={14} color="gray.400" mb={0.5} mt={1.5}>
        Stacking
      </TypographyBox>
      <Box>
        <RadioButtonsList
          options={stackingOptions}
          value={byTreeExp.stacking}
          onChange={makeByTreeExpChangeHandler('stacking')}
          direction="column"
        />
      </Box>
      <TypographyBox fontSize={14} color="gray.400" mb={0.5} mt={1.5}>
        Y Axis
      </TypographyBox>
      <Box display="flex">
        <Box heigh={50} flex={1}>
          <RadioButtonsList
            options={yAxisOptions}
            value={byTreeExp.yAxis.type}
            onChange={makeByTreeExpChangeHandler('yAxis.type')}
            direction="column"
          />
        </Box>
        <Box heigh={50} flex={1}>
          <RadioButtonsList
            options={yAxisDirectionOptions}
            value={byTreeExp.yAxis.opposite}
            onChange={makeByTreeExpChangeHandler('yAxis.opposite')}
            direction="column"
          />
        </Box>
      </Box>
      <TypographyBox fontSize={14} color="gray.400" mb={0.5} mt={1.5}>
        Set a Specific Range
      </TypographyBox>
      <Box
        display="flex"
        flexDirection="row"
        css={{'& input': {minWidth: '0', width: '124px'}}}
        justifyContent="space-between"
      >
        <InputAuto
          automationId="metricExplorerChartSettingsFromRange"
          onChange={makeByTreeExpChangeHandler('yAxis.min')}
          allowEmpty
          type="number"
          placeHolder="From"
          value={byTreeExp.yAxis.min}
          delay={0}
        />
        <InputAuto
          automationId="metricExplorerChartSettingstToRange"
          onChange={makeByTreeExpChangeHandler('yAxis.max')}
          allowEmpty
          type="number"
          placeHolder="To"
          value={byTreeExp.yAxis.max}
          delay={0}
        />
      </Box>
      <Box display="flex" justifyContent="flex-end" mt={2}>
        <Button
          automationId="metricExplorerChartSettingsApplyButton"
          isDisabled={!isDirty}
          text="Apply"
          colorSchema={COLORS.BLUE_500}
          onClick={() => {
            setSettingsMenuOpen(false);
          }}
        />
      </Box>
    </Box>
  );

  const button = (
    <Button
      automationId="metricExplorerChartSettingsButton"
      fixedWidth={35}
      width={WIDTHS.NARROW_TIGHT}
      colorSchema={COLORS.GRAY_300}
      icon="icn-nav24-settings"
    />
  );

  return (
    <FormDdl
      popoverComponent={popOverComponent}
      buttonComponent={button}
      onToggle={(val) => setSettingsMenuOpen(val)}
      isOpen={isSettingsMenuOpen}
      width={300}
      position="right"
      horizontalOffset={40}
      maxHeight={1000}
    />
  );
};

export default ChartRenderSettingsMenu;
