/* eslint-disable */
import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from 'dashboards/store/actions';

const EMPTY_ARRAY = [];
const initialState = {
  isStatTileModal: false,
  dashboardListV1: EMPTY_ARRAY,
};

const viewReducer = composeReducers(
  {
    fetchDashboardsTotal: makeAsyncReducer(actions.fetchDashboardsTotal, {defaultData: 0}),
  },
  (state = initialState, {type, payload}) => {
    switch (type) {
      case actions.fetchLastViewDashboards.TYPE:
      case actions.setLastViewDashboard.TYPE: {
        return {
          ...state,
          sorting: {
            ...state.sorting,
            isLoading: true,
          },
        };
      }
      case actions.fetchLastViewDashboards.success.TYPE:
      case actions.setLastViewDashboard.success.TYPE: {
        return {
          ...state,
          sorting: {
            lastView: payload.items,
            isLoading: false,
          },
        };
      }
      case actions.isStatTileModal.TYPE: {
        return {
          ...state,
          isStatTileModal: payload,
        };
      }
      case actions.fetchDashboardListV1.success.TYPE: {
        return {
          ...state,
          dashboardListV1: payload,
        };
      }
      case actions.fetchDashboardListV2.success.TYPE: {
        return {
          ...state,
          dashboardListV2: payload,
        };
      }
      default:
        return state;
    }
  }
);

export default viewReducer;
