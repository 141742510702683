// @flow
import React, {Fragment, useEffect} from 'react';
import {makeStyles} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import Button, {COLORS} from 'common/componentsV2/Button';
import {useDropzone} from 'react-dropzone';

const useStyles = makeStyles(({palette}) => ({
  '@global': {
    '.dropzone': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
    },
  },
  dragDropContainer: {
    width: '100%',
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `2px dashed ${palette.gray[350]}`,
    color: palette.gray[350],
    lineHeight: '18px',
    marginBottom: 24,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  accepted: {
    color: palette.blue[500],
  },
  error: {
    color: palette.red[500],
  },
  fileItem: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
  },
  errorMessage: {
    fontSize: 12,
    lineHeight: '12px',
    paddingLeft: 17,
  },
}));

const FileLoader = ({
  fileType,
  errorMessageFileType,
  onChange,
}: {
  fileType: string,
  errorMessageFileType: string,
  onChange: Function,
}) => {
  const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({accept: fileType});
  const classes = useStyles();

  useEffect(() => {
    const formData = new FormData();
    formData.append('file', acceptedFiles[0]);
    onChange(formData);
  }, [acceptedFiles]);

  const accepted = acceptedFiles.map((file) => (
    <div key={file.path} className={classes.fileItem}>
      <i className={`icon icn-general16-checkmark ${classes.accepted}`} />
      <TypographyBox variant="body1" ml={0.5} isEllipsis>
        {file.path}
      </TypographyBox>
    </div>
  ));

  const rejected = fileRejections.map(({file, errors}) => (
    <Fragment>
      <div key={file.path} className={classes.fileItem}>
        <i className={`icon icn-general16-warning ${classes.error}`} />
        <TypographyBox variant="body1" ml={0.5}>
          {file.path}
        </TypographyBox>
      </div>
      <div className={`${classes.error} ${classes.errorMessage}`}>
        {errors[0].code === 'file-invalid-type' ? errorMessageFileType : ''}
      </div>
    </Fragment>
  ));

  return (
    <Fragment>
      <div>
        <div className={classes.dragDropContainer}>
          <div {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            <div className={classes.row}>
              <i className="icon icn-action16-download" />
              <TypographyBox variant="body1" color="gray.350" ml={0.5} mr={0.5}>
                Drag and drop or
              </TypographyBox>
              <Button text="Select File" colorSchema={COLORS.GHOST_LIGHT_BLUE} />
            </div>
          </div>
        </div>
      </div>
      <div>
        {accepted}
        {rejected}
      </div>
    </Fragment>
  );
};

export default FileLoader;
