// @flow
import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import ConfirmationMessageModal from 'assets/components/actions/ConfirmationMessageModal';
import WarningMessageModal from 'assets/components/actions/WarningMessageModal';
import {getAlertConfigurationsItems, getAlertConfigurationsIsLoading} from 'alerts.management/store/selectors';
import {deleteDynamicRoutingFile} from 'assets/store/actions';
import {fetchDataStreams, deleteLookupTable} from 'bc/store/actions';
import {fetchAlertConfigurations} from 'alerts.management/store/actions';
import {getUsedLookupIdsList} from 'bc/services/lookupService';
import {TYPES} from '../../services/service';
import {getDataStreamsItems, getIsDataStreamsLoading} from '../../../bc/store/selectors';

type PropTypes = {
  fileName: String,
  id: String,
  type: String,
  IsConfirmationMessageOpen: Boolean,
  onSetIsConfirmationMessageOpen: Function,
};

const DeleteFile = (props: PropTypes) => {
  const dispatch = useDispatch();
  const alertConfigurationsItems = useSelector(getAlertConfigurationsItems);
  const alertConfigurationsIsLoading = useSelector(getAlertConfigurationsIsLoading);
  const allDataStreams = useSelector(getDataStreamsItems);
  const isDataStreamsLoading = useSelector(getIsDataStreamsLoading);
  const [fileToDeleteId, setFileToDeleteId] = useState(null);
  const [isWarningModal, setIsWarningModal] = useState(null);
  const [useBySources, setUseBySources] = useState(null);

  const deleteFile = () => {
    if (props.type === TYPES.LOOKUP) {
      dispatch(deleteLookupTable({id: props.id}));
    } else if (props.type === TYPES.DYNAMIC_ROUTING) {
      dispatch(deleteDynamicRoutingFile({id: props.id}));
    }
    setUseBySources(null);
  };

  const routingUseItems = () =>
    alertConfigurationsItems.reduce((arr, alert) => {
      if (alert.data.dynamicRouting && alert.data.dynamicRouting.metaId === props.id) {
        const newValue = {
          sourceName: alert.data.title,
          sourceId: alert.data.id,
          filesIds: [alert.data.dynamicRouting.metaId],
        };
        arr.push(newValue);
      }
      return arr;
    }, []);

  const lookupUseItems = () => {
    const lookupIdsListArrBIG = [];

    if (allDataStreams) {
      allDataStreams.forEach((stream) => {
        const lookupIdsListArr = [];

        if (stream.schema && stream.schema.columns) {
          stream.schema.columns.forEach((item) => {
            lookupIdsListArr.concat(getUsedLookupIdsList(lookupIdsListArr, item));
          });
        }
        if (lookupIdsListArr.length) {
          lookupIdsListArrBIG.push({
            sourceName: stream.name,
            sourceId: new Date().getTime(),
            filesIds: lookupIdsListArr,
          });
        }
      });
    }
    const tablesUsingThisLookupId = [];
    lookupIdsListArrBIG.forEach((item) => {
      if (item.filesIds.includes(fileToDeleteId)) {
        tablesUsingThisLookupId.push(item);
      }
    });
    return tablesUsingThisLookupId;
  };

  const deleteOrWarningMsg = () => {
    if (!useBySources.length) {
      deleteFile();
    } else {
      setIsWarningModal(true);
    }
  };

  const checkIfFileInUse = () => {
    setFileToDeleteId(null);

    if (props.type === TYPES.LOOKUP) {
      setUseBySources(lookupUseItems());
    } else if (props.type === TYPES.DYNAMIC_ROUTING) {
      setUseBySources(routingUseItems());
    }
  };

  const fetchAppropriateData = () => {
    props.onSetIsConfirmationMessageOpen(false);
    setFileToDeleteId(props.id);

    if (props.type === TYPES.LOOKUP) {
      dispatch(fetchDataStreams());
    } else if (props.type === TYPES.DYNAMIC_ROUTING) {
      dispatch(fetchAlertConfigurations());
    }
  };

  useEffect(() => {
    if (useBySources) {
      deleteOrWarningMsg();
    }
  }, [useBySources]);

  useEffect(() => {
    if (fileToDeleteId && isDataStreamsLoading === false) {
      checkIfFileInUse();
    }
  }, [isDataStreamsLoading]);

  useEffect(() => {
    if (fileToDeleteId && alertConfigurationsIsLoading === false) {
      checkIfFileInUse();
    }
  }, [alertConfigurationsIsLoading]);

  return (
    <Fragment>
      <ConfirmationMessageModal
        isOpen={props.IsConfirmationMessageOpen}
        onClose={() => props.onSetIsConfirmationMessageOpen(false)}
        title={props.fileName}
        onAction={fetchAppropriateData}
      />

      <WarningMessageModal
        isSmall
        isOpen={isWarningModal}
        onClose={() => setIsWarningModal(false)}
        title={props.fileName}
        type={props.type}
        useBySources={useBySources || []}
      />
    </Fragment>
  );
};

export default DeleteFile;
