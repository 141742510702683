import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {Observable} from 'rxjs/Observable';
import {getBucketStartTimeEnabled} from 'profile/store/selectors';
import {getMetricResolutions} from 'metrics/store/selectors';
import * as mapService from 'topologyLeaflet/services/tpMapService';
import * as selectors from 'topologyLeaflet/store/selectors';
import * as actions from 'topologyLeaflet/store/actions';
import * as api from 'topologyLeaflet/services/api';

import Highcharts from 'highcharts';
import moment from 'moment';
import {processSeriesData, generateChartSeriesMetricModel} from 'charts/timeSeries/services/timeSeriesDataService';
import {startLoad, endLoad, pushSeries} from 'charts/timeSeries/services/timeSeriesHchartService';
import {getEmptyTree, getApiTreeForNode} from 'common/utils/angularServices';
import {getDateValue, rangeTypes} from 'common/utils/dateRangeService';
import {get} from 'lodash';
import * as chartActions from 'charts/timeSeries/store/actions';

const timeSeriesCharts = new Map();

const BUFFER_TIMES = {
  alerts: {
    '1m': 120,
    '5m': 600,
    '1h': 3600,
    '1d': 86400,
    '1w': 0,
  },
  anomalies: {
    '1m': 60 * 60 * 13,
    '5m': 60 * 60 * 13,
    '1h': 60 * 60 * 24 * 15,
    '1d': 60 * 60 * 24 * 15,
    '1w': 60 * 60 * 24 * 15,
  },
};

const processAnomalyIntervals = (anomalyIntervals) => {
  if (!anomalyIntervals || anomalyIntervals.length === 0) {
    return null;
  }

  return (anomalyIntervals || []).map((a) => ({
    startTime: a.startDate,
    endTime: a.endDate,
    duration: null,
    status: a.state,
    score: a.score,
    direction: a.directionUp ? 'UP' : 'DOWN',
    peak: a.peakValue,
    deltaAbsolute: a.absoluteDelta,
    deltaPercentage: a.percentageDelta,
  }));
};

const extractMetricAndProperties = (res, metrics, timeScale, issueType) => {
  metrics.forEach((metric) => {
    let startTime = null;
    let endTime = null;
    switch (issueType) {
      case 'alerts':
        startTime = metric.displayStartTime;
        endTime = metric.displayEndTime + (BUFFER_TIMES.alerts[timeScale] || 0);
        break;
      case 'anomalies':
        startTime = metric.currentAnomalyIntervals[0].startDate - (BUFFER_TIMES.anomalies[timeScale] || 0);
        endTime = Math.min(
          metric.currentAnomalyIntervals[0].endDate + (BUFFER_TIMES.anomalies[timeScale] || 0),
          Math.floor(moment.now() / 1000),
        );
        break;
      default:
    }

    res.push({
      metricId: metric.id,
      intervals: metric.intervals || null,
      currentAnomalyIntervals: processAnomalyIntervals(metric.currentAnomalyIntervals),
      otherAnomalyIntervals: processAnomalyIntervals(metric.otherAnomalyIntervals),
      what: metric.what,
      properties: metric.properties,
      tags: metric.tags,
      origin: metric.origin,
      name: metric.name,
      startTime,
      endTime,
    });
  });
};

const generateIssuesFromAlertGroups = (alertGroups, issueType, resolutions) => {
  if (!alertGroups || !alertGroups.length) {
    return [];
  }

  const issues = alertGroups.map((alertGroup) => {
    const isOpen = alertGroup.alerts[0].status === 'OPEN';
    const isNoData = alertGroup.alerts[0].type === 'noData';
    const isAnomaly = alertGroup.alerts[0].type === 'anomaly';
    const isStatic = alertGroup.alerts[0].type === 'static';
    const resolution = Object.values(resolutions).find((a) => a.value2 === alertGroup.alerts[0].timeScale).value;
    let total = 0;
    const metrics = [];
    alertGroup.alerts.forEach((alert) => {
      total += alert.summary.totalMetrics;
      extractMetricAndProperties(metrics, alert.metrics, alertGroup.alerts[0].timeScale, issueType);
    });

    let investigationUrl = null;
    if (isAnomaly) {
      investigationUrl = `/#!/anomalies?tabs=main;0&activeTab=1&anomalies=;0(${alertGroup.alerts[0].groupId})&duration=;1(1)&durationScale=;minutes(minutes)&delta=;1(1)&deltaType=;percentage(percentage)&resolution=;${resolution}(${resolution})&score=;0(0)&state=;both(both)&direction=;both(both)&alertId=;(${alertGroup.alerts[0].id})&sort=;significance(significance)&q=;()&constRange=;1h(c)&startDate=;0(0)&endDate=;0(0)`;
    }

    return {
      id: alertGroup.alerts[0].id,
      groupId: alertGroup.id,
      name: alertGroup.name,
      startTime: alertGroup.startTime,
      isOpen,
      isNoData,
      isStatic,
      total,
      totalText: 'METRICS',
      resolution,
      timeScale: alertGroup.alerts[0].timeScale,
      investigationUrl,
      metrics: metrics.slice(0, 20),
    };
  });

  return issues;
};

const generateIssuesFromAnomalies = (anomalies, issueType, resolutions) => {
  if (!anomalies || !anomalies.length) {
    return [];
  }

  const issues = anomalies.map((anomaly) => {
    const isOpen = anomaly.state === 'open';
    const timeScale = Object.values(resolutions).find((a) => a.value === anomaly.resolution).value2;
    const {total} = anomaly.metricsCount;
    const metrics = [];
    extractMetricAndProperties(metrics, anomaly.metrics, timeScale, issueType);

    return {
      id: anomaly.id,
      name: anomaly.metrics[0].what,
      startTime: anomaly.startDate,
      isOpen,
      total,
      totalText: 'METRICS',
      resolution: anomaly.resolution,
      timeScale,
      metrics: metrics.slice(0, 20),
    };
  });

  return issues;
};

const fetchTopologyMap = makeAsyncEpic(actions.fetchTopologyMap, api.fetchTopologyMap);
const fetchTopologyAnomalies = makeAsyncEpic(actions.fetchTopologyAnomalies, api.fetchTopologyAnomalies);
const fetchTopologyAlerts = makeAsyncEpic(actions.fetchTopologyAlerts, api.fetchTopologyAlerts);
const fetchTopologyAlert = makeAsyncEpic(actions.fetchTopologyAlert, api.fetchTriggeredAlert);
const fetchTopologyAnomaly = makeAsyncEpic(actions.fetchTopologyAnomaly, api.fetchTopologyAnomaly);

const getTopologyAlerts = (action$, {getState}) =>
  action$.ofType(actions.getTopologyAlerts.TYPE).switchMap(({payload}) => {
    const startBucketMode = getBucketStartTimeEnabled(getState());
    if (startBucketMode) {
      return [actions.fetchTopologyAlerts({...payload, startBucketMode})];
    }
    return [actions.fetchTopologyAlerts(payload)];
  });

const getTopologyAnomalies = (action$, {getState}) =>
  action$.ofType(actions.getTopologyAnomalies.TYPE).switchMap(({payload}) => {
    const startBucketMode = getBucketStartTimeEnabled(getState());
    if (startBucketMode) {
      return [actions.fetchTopologyAnomalies({...payload, startBucketMode})];
    }
    return [actions.fetchTopologyAnomalies(payload)];
  });

const fetchTopologyAnomaliesSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchTopologyAnomalies.success.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const issueType = selectors.getTMLeftPanelIssuesType(curState);
    const resolutions = getMetricResolutions(curState);
    if (!payload || !payload.anomalies || !payload.anomalies.length) {
      return [actions.setIssuesList([])];
    }

    const issues = generateIssuesFromAnomalies(payload.anomalies, issueType, resolutions);

    return [actions.setIssuesList(issues)];
  });

const fetchTopologyAlertsSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchTopologyAlerts.success.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const issueType = selectors.getTMLeftPanelIssuesType(curState);
    const resolutions = getMetricResolutions(curState);
    if (!payload || !payload.alertGroups || !payload.alertGroups.length) {
      return [actions.setIssuesList([])];
    }

    const issues = generateIssuesFromAlertGroups(payload.alertGroups, issueType, resolutions);

    return [actions.setIssuesList(issues)];
  });

const getTopologySpecialIssue = (action$, {getState}) =>
  action$.ofType(actions.getTopologySpecialIssue.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const startBucketMode = getBucketStartTimeEnabled(curState);
    if (payload.issuesType === 'alerts') {
      const dateRange = getDateValue(rangeTypes.y1.key, true);
      // const dateRange = getDateValue(payload.constRange, true); // -> to use the constRange param (from url)

      return [
        actions.fetchTopologyAlert(
          {
            triggerIds: payload.selectedIssueId,
            startBucketMode,
            startTime: dateRange.startDate,
          },
          {isSpecialIssue: true},
        ),
      ];
    }
    if (payload.issuesType === 'anomalies') {
      return [
        actions.fetchTopologyAnomaly(
          {
            anomalyId: payload.selectedIssueId,
            qs: {
              startBucketMode,
            },
          },
          {isSpecialIssue: true},
        ),
      ];
    }
    return [];
  });

const fetchTopologyAlertSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchTopologyAlert.success.TYPE).switchMap(({payload, meta}) => {
    if (!meta || !meta.isSpecialIssue) {
      return [];
    }

    const curState = getState();
    const issueType = selectors.getTMLeftPanelIssuesType(curState);
    const resolutions = getMetricResolutions(curState);
    if (!payload || !payload.alertGroups || !payload.alertGroups.length) {
      return [actions.setIntoLeftPanelView({specialIssue: null})];
    }

    const issues = generateIssuesFromAlertGroups(payload.alertGroups, issueType, resolutions);

    return [actions.setIntoLeftPanelView({specialIssue: issues[0]})];
  });

const fetchTopologyAnomalySuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchTopologyAnomaly.success.TYPE).switchMap(({payload, meta}) => {
    if (!meta || !meta.isSpecialIssue) {
      return [];
    }

    if (!payload || !payload.anomalies || !payload.anomalies.length) {
      return [actions.setIntoLeftPanelView({specialIssue: null})];
    }

    const curState = getState();
    const issueType = selectors.getTMLeftPanelIssuesType(curState);
    const resolutions = getMetricResolutions(curState);

    const issues = generateIssuesFromAnomalies(payload.anomalies, issueType, resolutions);

    return [actions.setIntoLeftPanelView({specialIssue: issues[0]})];
  });

const setMapBoundsIssueClicked = (action$, {getState}) =>
  action$.ofType(actions.setMapBoundsIssueClicked.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const issueId = selectors.getTMLeftPanelSelectedIssueId(curState);
    const nodes = selectors.getProcessedNodes(curState);

    if (!payload.map) {
      return [];
    }

    if (!issueId) {
      payload.map.setView(mapService.DEFAULT_MAP_CENTER, mapService.DEFAULT_MAP_ZOOM, {animate: true});
    } else {
      const bounds = mapService.getBoundsFromLeafletBoundsObject(payload.map.getBounds());
      const filteredNodes = mapService.filterNodes(
        mapService.MIN_NODES_ZOOM,
        bounds,
        nodes,
        mapService.ISSUE_SELECTED_FILTER_OPTIONS,
      );
      const nodesBounds = mapService.getNodesBounds(filteredNodes);

      if (!nodesBounds) {
        payload.map.setView(mapService.DEFAULT_MAP_CENTER, mapService.DEFAULT_MAP_ZOOM, {animate: false});
      } else if (nodesBounds.center) {
        payload.map.setView(nodesBounds.center, mapService.MIN_NODES_ZOOM, {animate: true});
      } else if (nodesBounds.maxPoint) {
        payload.map.fitBounds([nodesBounds.minPoint, nodesBounds.maxPoint], {
          maxZoom: mapService.MAX_NODES_ZOOM,
          padding: [50, 20],
          animate: true,
        });
      } else {
        payload.map.setView(mapService.DEFAULT_MAP_CENTER, mapService.DEFAULT_MAP_ZOOM, {animate: false});
      }
    }

    return [];
  });

const setMapBoundsSearchAssetClicked = (action$, {getState}) =>
  action$.ofType(actions.setMapBoundsSearchAssetClicked.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const selectedSearchItem = selectors.getTMLeftPanelSearchSelectedAsset(curState);
    const nodes = selectors.getProcessedNodes(curState);

    if (!payload.map) {
      return [];
    }

    if (!selectedSearchItem) {
      return [];
    }
    switch (selectedSearchItem.type) {
      case 'ABR':
      case 'SAS':
      case 'AN': {
        // eslint-disable-next-line no-underscore-dangle
        const zoom = payload.map._zoom > mapService.MIN_NODES_ZOOM ? payload.map._zoom : mapService.MIN_NODES_ZOOM;
        payload.map.setView(selectedSearchItem.latLng, zoom, {animate: true});
        break;
      }
      default: {
        const bounds = mapService.getBoundsFromLeafletBoundsObject(payload.map.getBounds());
        const filteredNodes = mapService.filterNodes(
          mapService.MIN_NODES_ZOOM,
          bounds,
          nodes,
          mapService.SEARCH_ITEM_SELECTED_FILTER_OPTIONS,
          selectedSearchItem,
        );
        const nodesBounds = mapService.getNodesBounds(filteredNodes);

        if (!nodesBounds) {
          payload.map.setView(mapService.DEFAULT_MAP_CENTER, mapService.DEFAULT_MAP_ZOOM, {animate: false});
        } else if (nodesBounds.center) {
          payload.map.setView(nodesBounds.center, mapService.MIN_NODES_ZOOM, {animate: true});
        } else if (nodesBounds.maxPoint) {
          payload.map.fitBounds([nodesBounds.minPoint, nodesBounds.maxPoint], {
            maxZoom: mapService.MAX_NODES_ZOOM,
            padding: [50, 20],
            animate: true,
          });
        } else {
          payload.map.setView(mapService.DEFAULT_MAP_CENTER, mapService.DEFAULT_MAP_ZOOM, {animate: false});
        }
      }
    }

    return [];
  });

const metricItemClicked = (action$, {getState}) =>
  action$.ofType(actions.metricItemClicked.TYPE).switchMap(({payload}) => {
    const curState = getState();
    const allNodes = selectors.getTMFetchTopologyMapDataNodes(curState);
    const hostProp = payload.metricItem.properties.find((p) => p.key === 'host');

    if (!hostProp || !payload.map) {
      return [];
    }

    const hostName = hostProp.value;
    const findNode = allNodes.find(
      (node) => node.hostName === hostName || (node.namesList && node.namesList.indexOf(hostName) !== -1),
    );

    if (findNode) {
      let zoom = payload.map.getZoom();
      zoom = zoom < mapService.MIN_NODES_ZOOM ? mapService.MIN_NODES_ZOOM : zoom;
      payload.map.setView(findNode.latLng, zoom, {animate: true});
    }

    return [];
  });

const fetchTopologyMapSuccess = (action$) =>
  action$.ofType(actions.fetchTopologyMap.success.TYPE).switchMap(({payload}) => {
    const createAssetObjectFromNode = (node) => ({
      id: node.id,
      name: node.hostName,
      type: node.type,
      styles: mapService.getStylesByNisStatus(node.status),
      latLng: node.latLng,
      extra: [
        {
          key: 'zipcode',
          label: 'ZIP Code',
          value: `${node.zipcode}, ${node.houseNumber}`,
        },
        {
          key: 'ring',
          label: 'OSPF Area',
          value: node.ringOspfAreaList,
        },
      ],
    });
    const createAssetObjectFromRing = (ring) => ({
      id: ring.id,
      name: ring.ospfArea,
      type: ring.type,
      styles: mapService.getStylesByNisStatus(ring.status),
      latLng: ring.latLng,
    });

    const assets = [];

    if (payload && payload.rings && payload.rings.length) {
      payload.rings.forEach((ring) => {
        if (ring.rings) {
          ring.rings.forEach((innerRing) => {
            assets.push(createAssetObjectFromRing(innerRing));
          });
        } else {
          assets.push(createAssetObjectFromRing(ring));
        }
      });
    }

    if (payload && payload.nodes && payload.nodes.length) {
      payload.nodes.forEach((node) => {
        if (node.nodes) {
          node.nodes.forEach((innerNode) => {
            assets.push(createAssetObjectFromNode(innerNode));
          });
        } else {
          assets.push(createAssetObjectFromNode(node));
        }
      });
    }

    return [actions.setIntoLeftPanelView({assets})];
  });

/**
 * Metric Charts and Data Points - Section
 */
const fetchMetricDataPoints = (action$, {getState}) =>
  action$.ofType(actions.fetchMetricDataPoints.TYPE).flatMap((action) => {
    const curState = getState();
    const selectedIssue = selectors.getTMLeftPanelSelectedIssue(curState);
    const startBucketMode = getBucketStartTimeEnabled(curState);
    const includeBaseline = false;

    const hchart = timeSeriesCharts.get(action.meta.chartId);
    const data = selectors.getTMFetchMetricDataPoints(curState)[action.meta.key];

    startLoad(hchart);

    const newMeta = {
      ...action.meta,
      isNoData: selectedIssue.isNoData,
    };

    if (data && data.data) {
      return [actions.fetchMetricDataPoints.success(data.data, newMeta)];
    }

    const res = getEmptyTree();
    res.expressionTree.root.searchObject = {
      ids: [action.payload],
    };

    const newAction = {
      ...action,
      payload: {
        startTime: action.meta.startTime,
        endTime: action.meta.endTime,
        includeBaseline,
        startBucketMode,
        resolution: selectedIssue.resolution,
        body: {
          composite: getApiTreeForNode(res.expressionTree.root, res, null, {
            issueId: selectedIssue.id,
            context: 'topologyIssuesPanel',
          }),
        },
      },
    };

    return api
      .fetchMetricDataPoints(newAction, getState)
      .map((payload) => actions.fetchMetricDataPoints.success(payload, newMeta))
      .catch((error) => Observable.of(actions.fetchMetricDataPoints.failure(error, newMeta)));
  });

const fetchMetricDataPointsSuccess = (action$) =>
  action$
    .ofType(actions.fetchMetricDataPoints.success.TYPE)
    .do(({payload, meta}) => {
      const hchart = timeSeriesCharts.get(meta.chartId);

      if (!hchart) {
        return; // console item was probably collapsed
      }
      if (!get(payload, 'metrics.length') || !payload.validation.passed) {
        endLoad(hchart, meta.startDate, meta.endDate);
        return;
      }

      const seriesProperties = {byTreeExp: []};
      const metricData = payload.metrics[0];
      const metricsModel = generateChartSeriesMetricModel(metricData);
      const processedSeriesData = processSeriesData(
        metricData.dataPoints,
        metricData.baseline,
        !meta.isNoData ? meta.intervals || meta.currentAnomalyIntervals : null,
        meta.otherAnomalyIntervals,
      );
      pushSeries(hchart, seriesProperties, metricsModel, processedSeriesData, true);

      endLoad(hchart, meta.startDate, meta.endDate);
    })
    .flatMap(() => []);

const fetchMetricDataPointsFailure = (action$) =>
  action$
    .ofType(actions.fetchMetricDataPoints.failure.TYPE)
    .do(({meta}) => {
      const hchart = timeSeriesCharts.get(meta.chartId);
      if (hchart) {
        return endLoad(hchart);
      }
      return null;
    })
    .flatMap(() => []);

const highChartCreated = (action$) =>
  action$
    .ofType(chartActions.highChartCreated.TYPE)
    .do((action) => {
      const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === action.payload.chartId);
      timeSeriesCharts.set(action.payload.chartId, hchart);
    })
    .flatMap(() => []);

const highChartDestroyed = (action$) =>
  action$
    .ofType(chartActions.highChartDestroyed.TYPE)
    .do((action) => {
      timeSeriesCharts.delete(action.payload.chartId);
    })
    .flatMap(() => []);

export default combineEpics(
  fetchTopologyMap,
  fetchTopologyMapSuccess,
  fetchTopologyAnomalies,
  fetchTopologyAlerts,
  getTopologyAnomalies,
  getTopologyAlerts,
  fetchTopologyAnomaliesSuccess,
  fetchTopologyAlertsSuccess,
  fetchTopologyAlert,
  fetchTopologyAnomaly,
  fetchTopologyAlertSuccess,
  fetchTopologyAnomalySuccess,
  getTopologySpecialIssue,
  setMapBoundsIssueClicked,
  metricItemClicked,
  setMapBoundsSearchAssetClicked,

  fetchMetricDataPoints,
  fetchMetricDataPointsSuccess,
  fetchMetricDataPointsFailure,
  highChartCreated,
  highChartDestroyed,
);
