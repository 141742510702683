import {combineReducers} from 'redux';
import tokens from './dataReducer';
import password from './password';

const root = combineReducers({
  tokens,
  password,
});

export default root;
