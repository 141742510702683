// @flow
import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import {makeStyles} from '@material-ui/core/styles';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Button, {COLORS} from 'common/componentsV2/Button';

type PropTypes = {
  title: String,
  isOpen: Boolean,
  onClose: Function,
  onAction: Function,
};

const useStyles = makeStyles(() => ({
  btnsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const ConfirmationMessageModal = (props: PropTypes) => {
  const classes = useStyles();

  return (
    <Modal onClose={props.onClose} isOpen={props.isOpen} size={MODAL_SIZES.SMALL} isStatic={false} isCloseButtonHidden>
      <Grid container>
        <Grid item sm={12}>
          <TypographyBox variant="h5" mb={1}>
            Delete Asset?
          </TypographyBox>
        </Grid>
        <Grid item sm={12}>
          <TypographyBox variant="body1" mb={6}>
            Are you sure you want to delete {props.title}
            ?
            <br />
            This action cannot be undone.
          </TypographyBox>
        </Grid>
        <Grid item sm={12} className={classes.btnsContainer}>
          <Button text="Cancel" onClick={props.onClose} colorSchema={COLORS.GRAY_300} />
          <Box ml={2}>
            <Button text="Delete" onClick={props.onAction} colorSchema={COLORS.RED_500} />
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ConfirmationMessageModal;
