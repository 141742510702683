import {composeReducers} from 'common/utils/reducers';
import {DEFAULT_QUERY_PARAMS} from 'admin.customers/services/customersService';
import * as actions from '../actions';

const initialState = {
  ...DEFAULT_QUERY_PARAMS,
};

const filtersReducer = composeReducers((state = {...initialState}, {type, payload}) => {
  switch (type) {
    case actions.setCustomersFiltersKeyVal.TYPE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
});

export default filtersReducer;
