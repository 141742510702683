// @flow
import React, {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import {SIZES} from 'common/componentsV2/modal/Modal';
import {
  getCustomersViewDeleteModal,
  getIsSubmittingCustomerData,
  getCustomersViewDeleteModalCustomerName,
} from 'admin.customers/store/selectors';
import {toggleCustomerDeleteConfirmationModal, deleteCustomer} from 'admin.customers/store/actions';
import './CustomersModals.module.scss';

const CustomerDeleteModal = () => {
  const dispatch = useDispatch();
  const {customerId, isOpen} = useSelector(getCustomersViewDeleteModal);
  const isSubmitting = useSelector(getIsSubmittingCustomerData);
  const customerName = useSelector(getCustomersViewDeleteModalCustomerName);

  const onModalClose = useCallback(() => {
    dispatch(toggleCustomerDeleteConfirmationModal({isOpen: false}));
  }, []);

  const onConfirm = useCallback(() => {
    dispatch(deleteCustomer({customerId}));
  }, [customerId]);

  return (
    <ConfModal
      isOpen={isOpen}
      onClose={onModalClose}
      onConfirm={onConfirm}
      confirmText="Delete Customer"
      title={`Delete customer: ${customerName}?`}
      size={SIZES.MEDIUM}
      isAsync
      isLoading={isSubmitting}
    >
      <div styleName="status-body">
        <p>Deleting a customer is permanent,</p>
        <p>Are you sure you want to continue?</p>
      </div>
    </ConfModal>
  );
};

export default CustomerDeleteModal;
