// @flow
import React, {useState} from 'react';
import {Form} from 'react-final-form';
import {Backdrop, Modal, Box, makeStyles} from '@material-ui/core';
import moment from 'moment';
import SideBarTabs from 'common/componentsV2/sideBarTabs/SideBarTabs';
import CustomDateRange from 'common/componentsV2/customDateRange/CustomDateRange';
import TimePreset from 'common/componentsV2/customDateRange/TimePreset';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';

const tabs = {
  customRange: {
    id: 0,
    label: 'Custom Time Frame',
  },
  timePresets: {
    id: 1,
    label: 'Time Presets',
  },
};

type PropsType = {
  isOpen: boolean,
  onClose: Function,
  onSelect: Function,
  dateRange: Object,
  timeZoneName: String,
  isUnix: boolean,
};
const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    position: 'absolute',
    margin: 'auto',
    width: 800,
    height: 500,
    top: '50%',
    right: 0,
    left: 0,
    transform: 'translateY(-50%)',
    backgroundColor: theme.palette.white[500],
    padding: '24px 24px 16px 16px',
    borderRadius: 6,
    boxShadow: '0 2px 7px 0 rgba(0, 0, 0, 0.2)',
  },
  closeBtn: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
}));

export const OPTIONS_VALUE = {
  RANGE: 'range',
  RELATIVE: 'relative',
  RELATIVE_RANGE: 'relative future',
};

export const options = [
  {
    label: 'Range',
    value: OPTIONS_VALUE.RANGE,
  },
  {
    label: 'Relative',
    value: OPTIONS_VALUE.RELATIVE,
  },
  {
    label: 'Relative + Future',
    value: OPTIONS_VALUE.RELATIVE_RANGE,
  },
];
export const titleTab = (title) => (
  <TypographyBox variant="h5" fontSize={18} lineHeight="21px" flexShrink={0}>
    {title}
  </TypographyBox>
);

export const optionsTime = [];
for (let hour = 0; hour < 24; hour++) {
  const full = moment({hour, minute: 0}).format('h:mm A');
  const half = moment({hour, minute: 30}).format('h:mm A');
  optionsTime.push({value: {hour, min: 0}, label: full});
  optionsTime.push({value: {hour, min: 30}, label: half});
}
optionsTime.push({value: {hour: 23, min: 59}, label: moment({hour: 23, minute: 59}).format('h:mm A')});

const getTimeValue = (hour, min) => {
  const computedMin = min < 30 && min >= 0 ? 0 : 30;
  return (
    optionsTime.find((o) => o.value.hour === hour && o.value.min === (min === 59 ? min : computedMin)) || optionsTime[0]
  );
};

const calculateScale = (value) => {
  if (!value) {
    return {
      value: 0,
      scale: 'days',
    };
  }
  const days = value / (3600 * 24);
  const weeks = value / (3600 * 24 * 7);
  const hours = value / 3600;
  const isDays = value % (3600 * 24) === 0;
  const isWeeks = value % (3600 * 24 * 7) === 0;

  return {
    // eslint-disable-next-line no-nested-ternary
    scale: isWeeks ? 'weeks' : isDays ? 'days' : 'hours',
    // eslint-disable-next-line no-nested-ternary
    value: isWeeks ? weeks : isDays ? days : hours,
  };
};

const initialTime = (date, defaultHour, defaultMin) =>
  getTimeValue(date ? new Date(date).getHours() : defaultHour, date ? new Date(date).getMinutes() : defaultMin);

const CustomDateRangeModal = ({isOpen, onClose, onSelect, dateRange, timeZoneName, isUnix}: PropsType) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(tabs.customRange.id);
  const isCustomRangeType = dateRange.constRange === 'c';

  const actionTab = (tabId) => {
    setActiveTab(() => tabId);
  };
  const renderBody = () => {
    switch (activeTab) {
      case tabs.customRange.id:
        return <CustomDateRange onSelect={onSelect} timeZoneName={timeZoneName} isUnix={isUnix} onClose={onClose} />;
      case tabs.timePresets.id:
        return <TimePreset />;
      default:
        return '';
    }
  };

  const {value: relativeLast, scale: relativeLastScale} = calculateScale(dateRange.relativeLast);

  const {value: relativeNext, scale: relativeNextScale} = calculateScale(dateRange.relativeNext);

  const initialRangeType = () => {
    if (dateRange.constRange === 'c') {
      return options.find((o) => o.value === OPTIONS_VALUE.RANGE);
    }

    if (dateRange.constRange === 'r' && dateRange.relativeLast && dateRange.relativeNext === 0) {
      return options.find((o) => o.value === OPTIONS_VALUE.RELATIVE);
    }

    if (dateRange.constRange === 'r') {
      return options.find((o) => o.value === OPTIONS_VALUE.RELATIVE_RANGE);
    }

    return options.find((o) => o.value === OPTIONS_VALUE.RELATIVE);
  };

  const initialDateRange = {
    rangeType: initialRangeType(),
    dateRange: {
      ...dateRange,
      startDate: isCustomRangeType ? dateRange.startDate : '',
      endDate: isCustomRangeType ? dateRange.endDate : '',
    },
    isTimeApply:
      isCustomRangeType &&
      (new Date(dateRange.startDate).getHours() > 0 ||
        new Date(dateRange.startDate).getMinutes() > 0 ||
        (new Date(dateRange.endDate).getHours() > 0 && new Date(dateRange.endDate).getMinutes() !== 59)),
    startTime: initialTime(isCustomRangeType ? dateRange.startDate : null, 0, 0),
    endTime: initialTime(isCustomRangeType ? dateRange.endDate : null, 23, 59),
    relativeLast,
    relativeLastScale,
    relativeNext,
    relativeNextScale,
  };
  return (
    <Modal open={isOpen} onClose={onClose} BackdropComponent={Backdrop} disableEnforceFocus>
      <Box className={classes.wrapper}>
        <Form initialValues={initialDateRange} onSubmit={() => null} keepDirtyOnReinitialize>
          {() => (
            <React.Fragment>
              <Box width={200} flexShrink={0}>
                <SideBarTabs tabs={Object.values(tabs)} selectedTabId={activeTab} onTabClick={actionTab} />
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="space-between" flexGrow={1} ml={2.5} width={1}>
                {renderBody()}
              </Box>
              <Box className={classes.closeBtn} onClick={onClose}>
                <i className="icon icn-general16-closea" />
              </Box>
            </React.Fragment>
          )}
        </Form>
      </Box>
    </Modal>
  );
};

export default CustomDateRangeModal;
