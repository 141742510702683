// @flow
import React from 'react';
import Loading from '../Loading';
import NoData from './NoData';
import GaugeInner from './GaugeInner';

type PropTypes = {
  isLoading: Boolean,
  isNoData: Boolean,
  backgroundColor: String,
};

const Gauge = (props: PropTypes) => {
  const {isLoading, isNoData, backgroundColor} = props;
  const isLoadingOrNotReady = isLoading !== false;
  const content = isNoData ? <NoData /> : <GaugeInner {...props} />;
  const loading = isLoadingOrNotReady ? <Loading /> : '';

  return (
    <div className="gauge-wrapper" style={{backgroundColor}}>
      {loading}
      <div className="gauge-container">{content}</div>
    </div>
  );
};

export default Gauge;
