// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  fetchFuCsvFormatAnalysis as fetchFuCsvFormatAnalysisAction,
  setFuStreamFileFormat as setFuStreamFileFormatAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import PanelHeader from 'bc/components/streams/editor/PanelHeader';
import {isStreamEditable} from 'bc/services/dataStreamService';
import FileSettings from 'bc/components/streams/editor/common/FileSettings/FileSettings';
import Loader from 'common/components/Loader';
import {abbrBytesToFileSize} from 'common/utils/numbers';
import SmartTooltip from 'common/components/SmartTooltip';
import {take} from 'lodash';
import FuFilePreview from './FuFilePreview';
import './FuData.module.scss';

type PropTypes = {
  dataStream: Object,
  analysisResult: Object,
  fetchFuCsvFormatAnalysis: Function,
  setFuStreamFileFormat: Function,
};

const EMPTY_ARRAY = [];

class FuData extends React.PureComponent {
  props: PropTypes;

  state = {isOpenFileSettingsModal: false, isOpenFilePreviewModal: false};

  componentDidMount() {
    const {
      fetchFuCsvFormatAnalysis,
      dataStream: {dataSourceId, fileFormat, uiState},
    } = this.props;
    if (!uiState) {
      fetchFuCsvFormatAnalysis({dataSourceId, fileFormat});
    }
  }

  settingsClicked = () => {
    const {isOpenFileSettingsModal} = this.state;
    this.setState({isOpenFileSettingsModal: !isOpenFileSettingsModal});
  };

  previewClicked = () => {
    const {isOpenFilePreviewModal} = this.state;
    this.setState({isOpenFilePreviewModal: !isOpenFilePreviewModal});
  };

  render() {
    const {
      fileFormat,
      fileName,
      fileSize,
      columnsCount,
      fileErrors,
      rowsErrors,
      fileSchema,
    } = this.props.analysisResult;
    const {setFuStreamFileFormat} = this.props;
    const isLoading = !fileName;
    const isEditable = isStreamEditable(this.props.dataStream.state) && !isLoading && fileSchema;

    if (isLoading) {
      return (
        <div className="shell-col" styleName="root">
          <PanelHeader title="File Data" isEditable={isEditable} />
          <Loader size="small" />
        </div>
      );
    }

    return (
      <div className="shell-col" automation-id="streamQueryFileData" styleName="root">
        <PanelHeader title="File Data" isEditable={isEditable}>
          <button
            type="button"
            className="btn btn-icon btn-icon btn-dark-blue"
            automation-id="fileUploadDataPreviewButton"
            disabled={!isEditable}
            onClick={this.previewClicked}
          >
            <i className="icon icn-view-icon" />
          </button>
          <button
            type="button"
            className="btn btn-icon btn-icon btn-dark-blue"
            automation-id="fileUploadDataSettingsButton"
            disabled={!isEditable}
            onClick={this.settingsClicked}
          >
            <i className="icon icn-settings-icon" />
          </button>
        </PanelHeader>

        {this.state.isOpenFileSettingsModal && (
          <FileSettings
            isOpen={this.state.isOpenFileSettingsModal}
            onClose={this.settingsClicked}
            onChange={setFuStreamFileFormat}
            fileFormat={fileFormat}
            fileName={fileName}
          />
        )}

        {this.state.isOpenFilePreviewModal && (
          <FuFilePreview isOpen={this.state.isOpenFilePreviewModal} onClose={this.previewClicked} />
        )}

        <div styleName="file-name">{fileName}</div>

        <div styleName="title">Summary</div>

        <div styleName="tabular-data">
          <span>File Size</span>
          <span>{abbrBytesToFileSize(fileSize)}</span>
        </div>

        <div styleName="tabular-data">
          <span>Number of Columns</span>
          <span>{columnsCount}</span>
        </div>

        {!fileSchema && (
          <div>
            <div styleName="title">File Error</div>
            <div styleName="error">
              <div className="ellipsis">CSV File could not be analyzed, Please verify file integrity</div>
            </div>
          </div>
        )}

        {(fileErrors || EMPTY_ARRAY).length > 0 && (
          <div>
            <div styleName="title">File Errors</div>
            {fileErrors.map((e) => (
              <div styleName="error" key={e.message}>
                <div className="ellipsis">{e.message}</div>
              </div>
            ))}
          </div>
        )}

        {rowsErrors.length > 0 && (
          <div>
            <div styleName="title">Row Errors</div>
            {take(rowsErrors, 20).map((e) => (
              <div styleName="error" className="shell" key={e.rowIdx}>
                <div styleName="row-error-num">{e.rowIdx}</div>
                {e.row ? (
                  <SmartTooltip placement="top" content={e.row}>
                    <div className="ellipsis">{e.message}</div>
                  </SmartTooltip>
                ) : (
                  <div className="ellipsis">{e.message}</div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    analysisResult: selectors.getFileStreamAnalysisResult(state),
  }),
  {
    fetchFuCsvFormatAnalysis: fetchFuCsvFormatAnalysisAction,
    setFuStreamFileFormat: setFuStreamFileFormatAction,
  },
)(FuData);
