// @flow
import React from 'react';
import SourceTypeListItem from './SourceTypeListItem';
import './GroupList.module.scss';

type PropTypes = {dataSourceTypes: Array, title: string};

const GroupList = (props: PropTypes) => (
  <div styleName="root">
    <div styleName="title">{props.title}</div>
    <div styleName="items">
      {props.dataSourceTypes.map((dataSourceType) => (
        <SourceTypeListItem key={dataSourceType.id} item={dataSourceType} />
      ))}
    </div>
  </div>
);

export default GroupList;
