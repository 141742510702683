/* eslint-disable */
// @flow
import React, {Fragment} from 'react';
import {DROP_OPTIONS} from 'impact/services/constants';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  value: String,
  setValue: Function,
};

const DropSelector = (props: PropTypes) => {
  const setValue = (value) => {
    props.setValue({value, name: 'drop'});
  };

  return (
    <Fragment>
      <SelectAndt
        id="impactDropSelector"
        automationId="impactDropSelector"
        extraClassName="alerts-dropdown-btn"
        optionHeight={40}
        menuWidth={150}
        type={TYPE_NEW_NO_SEARCH}
        theme={THEME_BLUE_LEAN}
        options={DROP_OPTIONS}
        onChange={(i) => setValue(i.value)}
        value={DROP_OPTIONS.find((i) => i.value === props.value)}
      />
    </Fragment>
  );
};

export default DropSelector;

/* eslint-enable */
