// @flow
import React from 'react';
import './SectionHeader.module.scss';

const SectionHeader = (props: {digit: String, title: any, subTitle: String, instruction: String}) => (
  <div styleName="sub-header">
    <div styleName="header-digit">{props.digit}</div>
    <div>
      <div styleName="header-title">
        {props.title} <span styleName="instruction">{props.instruction}</span>
      </div>
      <div styleName="header-sub-title" className="ellipsis">
        {props.subTitle}
      </div>
    </div>
  </div>
);

export default SectionHeader;
