import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import userEventsHandler from 'userEvents/services/errors';
import * as actions from 'userEvents/store/actions';

const initialState = {};
const EMPTY_ARRAY = [];

const eventsSourcesReducers = composeReducers(
  makeAsyncReducer(actions.fetchUserEventsSources, {dataProp: 'items', defaultData: EMPTY_ARRAY}),
  {
    addUserEventSource: makeAsyncReducer(actions.crateUserEventsSource, {
      defaultData: EMPTY_ARRAY,
      errorHandler: userEventsHandler,
    }),
  },
  {removeUserEventsSource: makeAsyncReducer(actions.deleteUserEventsSource, {})},

  (state = initialState, {type, payload, meta}) => {
    switch (type) {
      case actions.crateUserEventsSource.success.TYPE:
        return {
          ...state,
          items: [payload, ...state.items],
        };
      case actions.deleteUserEventsSource.success.TYPE:
        return {
          ...state,
          items: state.items.filter((i) => i.id !== meta),
        };
      default:
        return state;
    }
  },
);

export default eventsSourcesReducers;
