import {post, put, get} from 'common/utils/http';

const prefix = 'api/v1/customers';

export const updatePassword = ({payload}) => post('api/v1/password/change', payload);
export const updateSso = ({payload, meta}) => put(`${prefix}/${meta.organizationId}/sso`, payload);
export const verifyDomains = ({payload, meta}) => post(`${prefix}/${meta.organizationId}/sso/verify-domains`, payload);

export const fetchRefreshTokens = () => get('api/v1/refresh-tokens');
export const refreshTokens = ({payload}) => post('api/v1/refresh-tokens', payload);
// eslint-disable-next-line no-unused-vars
export const revokeToken = ({payload, meta}) => post(`api/v1/refresh-tokens/${meta.id}/revoke`);
