const getWindowLocationHref = () => window.location.href;

const getParameterByName = (name, url) => {
  /* eslint-disable no-useless-escape */
  const cleanName = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${cleanName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getParameterByUrl = (name) => {
  const url = getWindowLocationHref();
  return getParameterByName(name, url);
};

export const pageName = () => {
  const windowlLocation = getWindowLocationHref();
  const uriAndParams = windowlLocation.split('?');
  const pureUri = uriAndParams[0];
  const pureUriBreak = pureUri.split('/');
  return pureUriBreak[pureUriBreak.length - 1];
};

export const makeSupportLink = (url) => {
  const {isOnPremise} = window;
  return isOnPremise ? '/statics/support/onpremise_user_guide.pdf' : url;
};
