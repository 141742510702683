// @flow
import React, {useCallback} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SearchBox from 'common/componentsV2/SearchBox';
import * as filterSelector from 'admin.customers/store/filterSelector';
import {setCustomersFiltersKeyVal} from 'admin.customers/store/actions';
import {StringParam, useQueryParam} from 'use-query-params';

const CustomerSearchQueryFilter = () => {
  const dispatch = useDispatch();
  const searchQuery = useSelector(filterSelector.getFiltersSearchQuery);
  const [, setSearchQueryParam] = useQueryParam('searchQuery', StringParam);

  const onFilter = useCallback(
    (val) => {
      if (val === searchQuery) return;
      setSearchQueryParam(val || undefined);
      dispatch(setCustomersFiltersKeyVal({searchQuery: val}));
    },
    [dispatch, searchQuery],
  );

  return (
    <SearchBox onFilter={onFilter} filter={searchQuery} placeHolder="Search" automationId="customersSearchFilter" />
  );
};

export default CustomerSearchQueryFilter;
