// @flow
import React, {useCallback, Fragment, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {isEmpty} from 'lodash';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import {SIZES} from 'common/componentsV2/modal/Modal';
import {
  getCustomersViewStatusModal,
  getEditCustomerData,
  getIsLoadingCustomerData,
  getIsSubmittingCustomerData,
  getCustomersViewStatusModalCustomerName,
} from 'admin.customers/store/selectors';
import {
  toggleCustomerChangeStatusConfirmationModal,
  updateCustomer,
  fetchEditCustomerData,
} from 'admin.customers/store/actions';
import './CustomersModals.module.scss';

const CustomerStatusModal = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoadingCustomerData);
  const isSubmitting = useSelector(getIsSubmittingCustomerData);
  const {customerId, action, isOpen} = useSelector(getCustomersViewStatusModal);
  const customerName = useSelector(getCustomersViewStatusModalCustomerName);
  const customerData = useSelector(getEditCustomerData);

  useEffect(() => {
    if (customerId) dispatch(fetchEditCustomerData({customerId}));
  }, [customerId]);

  const onModalClose = useCallback(() => {
    dispatch(toggleCustomerChangeStatusConfirmationModal({isOpen: false}));
  }, []);

  const onConfirm = useCallback(() => {
    if (isEmpty(customerData)) return;
    dispatch(updateCustomer({customer: {...customerData, disabled: action === 'disable'}}));
  }, [customerId, customerData]);

  const confirmText = (
    <div styleName="status-body">
      {action === 'enable' ? (
        <Fragment>
          <p>You have chosen to enable the account.</p>
          <p>Enabling the account creates a new token which is required to resume sending metrics.</p>
          <br />
          <p>Are you sure you want to enable the customer?</p>
        </Fragment>
      ) : (
        <Fragment>
          <p>You have chosen to disable the account.</p>
          <p>Metrics cannot be sent and users cannot login to a disabled account.</p>
          <p>Enabling the account will require a new token to resume sending metrics.</p>
          <br />
          <p>Are you sure you want to disable the customer?</p>
        </Fragment>
      )}
    </div>
  );
  const actionText = action === 'enable' ? 'Enable' : 'Disable';

  return (
    <ConfModal
      isOpen={isOpen}
      onClose={onModalClose}
      onConfirm={onConfirm}
      confirmText={actionText}
      title={`${actionText} customer: ${customerName}?`}
      size={SIZES.MEDIUM}
      isAsync
      isLoading={isSubmitting || isLoading}
    >
      {confirmText}
    </ConfModal>
  );
};

export default CustomerStatusModal;
