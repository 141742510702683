import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';

import * as actions from '../actions';
import * as api from '../../services/api';

const fetchFacebookAdsAccounts = makeAsyncEpic(actions.fetchFacebookAdsAccounts, api.fetchFacebookAdsAccounts);
const fetchFacebookAdsReports = makeAsyncEpic(actions.fetchFacebookAdsReports, api.fetchFacebookAdsReports);
const fetchFacebookAdsTemplates = makeAsyncEpic(actions.fetchFacebookAdsTemplates, api.fetchFacebookAdsTemplates);

const facebookAdsEpic = combineEpics(fetchFacebookAdsAccounts, fetchFacebookAdsReports, fetchFacebookAdsTemplates);

export default facebookAdsEpic;
