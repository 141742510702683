import React, {useState} from 'react';
import SingleDatePicker from 'common/componentsV2/customDateRange/SingleDatePicker';
import {useField} from 'react-final-form';

const ReferenceDate = () => {
  const {
    input: {value: formValue, onChange: formOnChange},
  } = useField('referenceDate');

  const [value, onChange] = useState(formValue);

  return <SingleDatePicker appliedValue={formValue} value={value} onChange={onChange} onApply={formOnChange} />;
};

export default ReferenceDate;
