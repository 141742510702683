function getLineChartDefaultDisplayProperties() {
  return {
    type: 'line',
    stacking: null,
    yAxis: {
      opposite: false,
      max: null,
      min: null,
      type: 'linear',
      minorTickInterval: 'auto',
    },
  };
}

const getTreesDisplayPropertiesFromCompositeDefinition = function(expressionTreeModel) {
  return {
    all: getLineChartDefaultDisplayProperties(),
    byTreeExp: [
      {
        id: expressionTreeModel.id,
        options: getLineChartDefaultDisplayProperties(),
      },
    ],
  };
};

export default getTreesDisplayPropertiesFromCompositeDefinition;
