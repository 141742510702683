// @flow
import React from 'react';
import {bcTypes} from 'bc/services/bcTypes';

import './StreamData.module.scss';

type PropTypes = {
  source: String,
  sources: Number,
  streams: Number,
  failure: Boolean,
  isActive: Boolean,
  onToggleSourceActive: Function,
};

export default class StreamData extends React.PureComponent {
  props: PropTypes;

  toggleSourceActive = () => {
    this.props.onToggleSourceActive(this.props.source);
  };

  render() {
    const {source, sources, streams, failure} = this.props;

    return (
      <div styleName={`container ${this.props.isActive ? 'active' : ''}`} onClick={this.toggleSourceActive}>
        <div styleName="img" className={`image-${bcTypes[source.toLowerCase()].iconStyle}`} />
        <h3 styleName="source-name">{bcTypes[source.toLowerCase()].name}</h3>
        <div>
          <p styleName="source-type">
            <strong>{sources}</strong> {sources === 1 ? 'Source' : 'Sources'}
          </p>
          <p styleName="source-type">
            <strong>{streams}</strong> {streams === 1 ? 'Stream' : 'Streams'}{' '}
            {failure ? <strong style={{display: 'inline', color: 'red'}}>!</strong> : ''}
          </p>
        </div>
      </div>
    );
  }
}
