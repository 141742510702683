// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {MAP_ITEMS_COLOR_PALETTE, useTooltipStyles} from 'topologyGeneral/services/mapService';

const CellInfoPanel = ({cell, isHidden}: PropTypes) => {
  if (isHidden || !cell) {
    return null;
  }

  const classes = useTooltipStyles();

  return (
    <Fragment>
      <Box display="flex" mb={0.25}>
        <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
          <Box
            className={classes.statusIndicator}
            bgcolor={cell.isError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
            mr={0.5}
          >
            &nbsp;
          </Box>
          <Box component="span" className={classes.title14Normal}>
            {cell.name}
          </Box>
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Band
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {cell.band || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              LAC
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {cell.lac || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              RAC
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {cell.rac || '-'}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Azimuth
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {cell.azimuth || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Status
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {cell.status || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              CI
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {cell.ci || '-'}
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

CellInfoPanel.propTypes = {
  cell: PropTypes.objectOf(PropTypes.any).isRequired,
  isHidden: PropTypes.bool,
};

CellInfoPanel.defaultProps = {
  isHidden: false,
};

export default CellInfoPanel;
