// @flow
import React from 'react';
import './NumericParameterInput.module.scss';
import {FunctionParameters} from 'anodot-objects-models';
import {Subject} from 'rxjs/Subject';

type PropTypes = {
  functionParameter: FunctionParameters,
  onValueChange: Function, // f = (value, isValid, [validationErrors]) => {...}
  value: number,
  onTouched: Function,
  isForceFocusIfAvailable: boolean,
};

export default class NumericParameterInput extends React.PureComponent {
  props: PropTypes;

  state = {
    isError: false,
    isPristine: true,
    immediateValue: '',
  };

  componentDidMount() {
    this.setState({immediateValue: this.props.value || ''});
    this.inputSubject = new Subject()
      .debounceTime(300)
      .distinctUntilChanged((x, y) => x === y)
      .map((x) => this.onChange(x));

    this.inputSubjectSubscription = this.inputSubject.subscribe();
    setTimeout(() => {
      this.inputSubject.next(this.props.value);
    }, 0);
  }

  componentWillUnmount() {
    this.inputSubjectSubscription.unsubscribe();
  }

  handleTyping = (e) => {
    let val = e.target.value;
    let reg = null;
    this.props.onTouched();
    if (this.props.functionParameter.type === 'double') {
      reg = /^-?\d*\.?\d*$/;
    } else {
      reg = /^-?\d*$/;
    }
    if (val === '' || reg.test(val)) {
      if (val === '') {
        val = null;
      }
      this.setState({isPristine: false});
      this.setState({immediateValue: val || ''});
      const isValid = this.props.functionParameter.validateValue(val);
      this.setState({isError: !isValid.isValid});
      this.validation = isValid.isValid;
      this.validationErrors = isValid.validationErrors;
      this.inputSubject.next(val);
    }
  };

  onChange = (val) => {
    this.props.onValueChange(val, this.validation, this.validationErrors);
  };

  handleBlur = () => {
    const {value} = this.props;
    this.setState({isPristine: false});
    const isValid = this.props.functionParameter.validateValue(value);
    this.setState({isError: !isValid.isValid});
    this.props.onTouched();
    this.props.onValueChange(value, this.validation, this.validationErrors);
  };

  render() {
    return (
      <div styleName="render-numeric">
        <input
          styleName={['value-input', this.state.isError ? 'error' : '', this.state.isPristine ? 'pristine' : ''].join(
            ' ',
          )}
          onChange={this.handleTyping}
          value={this.state.immediateValue}
          placeholder={this.props.functionParameter.name}
          type="text"
          onBlur={this.handleBlur}
          autoFocus={this.props.isForceFocusIfAvailable}
        />
      </div>
    );
  }
}
