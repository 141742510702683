import React, {useMemo} from 'react';
import Select from 'react-select2';
import {makeStyles} from '@material-ui/core';
import {DIRECTION_RIGHT_NO_OFFSET, THEME_TRANSPARENT} from './selectAndt/SelectAndt';
import OptionWithIcon from './selectAndt/innerComponents/OptionWithIcon';
import * as style from './selectAndt/SelectAndtStyling';
import Control from './selectAndt/innerComponents/Control';
import MenuList from './selectAndt/innerComponents/MenuList';

const useStyles = makeStyles(({palette}) => ({
  '@global': {
    '.dashboard-filter-wrapper': {
      '&:hover .icn-arrow16-triangledown': {
        visibility: 'visible',
      },
    },
  },
  icon: {
    color: palette.gray[400],
    visibility: 'hidden',
    '&:hover': {
      color: palette.gray[500],
    },
  },
  active: {
    visibility: 'visible',
  },
  dropdownIndicator: {
    display: 'flex',
    alignItems: 'flex-end',
    height: '18px',
  },
  dropdownIconWrapper: {
    marginLeft: '4px',
    marginBottom: '-2px',
  },
}));

const SimpleTransparentSelectAction = ({
  options,
  onChange,
  label,
  menuWidth,
}: {
  options: Array,
  onChange: Function,
  label: string,
  menuWidth: string,
}) => {
  const classes = useStyles();

  const components = useMemo(
    () => ({
      Control,
      MenuList,
      Option: OptionWithIcon,
      DropdownIndicator: (p) => (
        <div {...p.innerProps} className={classes.dropdownIndicator}>
          <div>{label}</div>
          <div className={classes.dropdownIconWrapper}>
            <i
              className={`icon icn-arrow16-triangledown ${classes.icon} ${p.selectProps.menuIsOpen && classes.active}`}
            />
          </div>
        </div>
      ),
    }),
    [label],
  );

  // eslint-disable-next-line max-len
  const styles = useMemo(() => style.simpleStyle(THEME_TRANSPARENT, DIRECTION_RIGHT_NO_OFFSET, menuWidth, undefined), [
    menuWidth,
  ]);

  return (
    <div>
      <Select
        isSearchable={false}
        className="select-menu"
        styles={styles}
        components={components}
        menuPlacement="auto"
        minMenuHeight={200}
        maxMenuHeight={300}
        maxHeight={300}
        optionHeight={40}
        hideSelectedOptions={false}
        animationDuration={1}
        captureMenuScroll
        menuShouldScrollIntoView={false}
        options={options}
        onChange={onChange}
      />
    </div>
  );
};

export default SimpleTransparentSelectAction;
