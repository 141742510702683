// @flow
import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {MAP_ITEMS_COLOR_PALETTE, useTooltipStyles} from 'topologyGeneral/services/mapService';

const LinkInfoPanel = ({link, isHidden}: PropTypes) => {
  if (isHidden || !link) {
    return null;
  }

  const classes = useTooltipStyles();

  return (
    <Fragment>
      <Box display="flex" mb={0.25}>
        <Box component="span" className={classes.title14Bold} mr={0.5}>
          {link.name}
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Node source
            </Box>
            {link.sourceNodeName ? (
              <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
                <Box
                  className={classes.statusIndicator}
                  bgcolor={link.isSourceNodeError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
                  mr={0.5}
                >
                  &nbsp;
                </Box>
                <Box component="span" className={classes.title14Normal}>
                  {link.sourceNodeName}
                </Box>
              </Box>
            ) : (
              <Box component="span" className={classes.title14Normal}>
                -
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Node destination
            </Box>
            {link.destNodeName ? (
              <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
                <Box
                  className={classes.statusIndicator}
                  bgcolor={link.isDestNodeError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
                  mr={0.5}
                >
                  &nbsp;
                </Box>
                <Box component="span" className={classes.title14Normal}>
                  {link.destNodeName}
                </Box>
              </Box>
            ) : (
              <Box component="span" className={classes.title14Normal}>
                -
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Status
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {link.status || '-'}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Interface source
            </Box>
            {link.sourceInterfaceName ? (
              <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
                <Box
                  className={classes.statusIndicator}
                  bgcolor={link.isSourceInterfaceError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
                  mr={0.5}
                >
                  &nbsp;
                </Box>
                <Box component="span" className={classes.title14Normal}>
                  {link.sourceInterfaceName}
                </Box>
              </Box>
            ) : (
              <Box component="span" className={classes.title14Normal}>
                -
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Interface destination
            </Box>
            {link.destInterfaceName ? (
              <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
                <Box
                  className={classes.statusIndicator}
                  bgcolor={link.isDestInterfaceError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
                  mr={0.5}
                >
                  &nbsp;
                </Box>
                <Box component="span" className={classes.title14Normal}>
                  {link.destInterfaceName}
                </Box>
              </Box>
            ) : (
              <Box component="span" className={classes.title14Normal}>
                -
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.nodeItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Type
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {link.type || '-'}
            </Box>
          </Box>
        </Box>
      </Box>
    </Fragment>
  );
};

LinkInfoPanel.propTypes = {
  link: PropTypes.objectOf(PropTypes.any),
  isHidden: PropTypes.bool,
};

LinkInfoPanel.defaultProps = {
  link: null,
  isHidden: false,
};

export default LinkInfoPanel;
