// @flow
import React from 'react';
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {useQueryParams} from 'use-query-params';
import {getTimeZoneName} from 'profile/store/selectors';
import IssuesList from 'topologyGeneral/components/sidePanel/issuesSubPanel/IssuesList';
import {getIsFetchingIssues} from 'topologyGeneral/store/selectors';
import {QUERY_PARAM_MAP} from 'topologyGeneral/services/sidePanelService';
import './IssuesSubPanel.module.scss';

const IssuesSubList = ({issues, isLoading}: PropTypes) => {
  const [, setQueryParams] = useQueryParams(QUERY_PARAM_MAP);
  const isFetchingIssues = useSelector(getIsFetchingIssues);
  const timeZoneName = useSelector(getTimeZoneName);

  const onIssueItemClicked = (item) => {
    setQueryParams({issueId: item.id});
  };

  return (
    <IssuesList
      onIssueItemClicked={onIssueItemClicked}
      issues={issues}
      isLoading={isLoading || isFetchingIssues}
      timeZoneName={timeZoneName}
    />
  );
};

IssuesSubList.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  // mapRef: PropTypes.objectOf(PropTypes.any).isRequired,
};

IssuesSubList.defaultProps = {
  issues: [],
  isLoading: false,
};

export default IssuesSubList;
