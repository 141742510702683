import React, {useCallback} from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';

const IntegrationApiKey = ({isCreate}: {isCreate: boolean}) => {
  const validate = useCallback(
    (value) => {
      return isCreate && !value && 'Integration API key is mandatory';
    },
    [isCreate],
  );
  const {
    input: {value, onChange, onBlur},
    meta,
  } = useField('integrationApiKey', {validate});
  return (
    <div>
      <div>Integration API Key</div>
      <Input
        type={isCreate ? 'text' : 'password'}
        automationId="integrationApiKey"
        isDisabled={!isCreate}
        isInvalid={meta.touched && meta.error}
        invalidMessage={meta.error}
        value={isCreate ? value : '************'}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default IntegrationApiKey;
