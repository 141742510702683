import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as api from 'bc/services/api';
import * as actions from '../actions';

const fetchAwsTemplates = makeAsyncEpic(actions.fetchAwsTemplates, api.fetchStreamTemplates);

const fetchAwsCurReports = makeAsyncEpic(actions.fetchAwsCurReports, api.fetchAwsCurReports);

const fetchAwsManifest = makeAsyncEpic(actions.fetchAwsCurManifest, api.fetchAwsCurManifest);

const setAwsTemplates = (action$) =>
  action$.ofType(actions.fetchAwsTemplates.success.TYPE).flatMap((action) => [actions.setAwsTemplates(action.payload)]);

const setAwsCurReports = (action$) =>
  action$
    .ofType(actions.fetchAwsCurReports.success.TYPE)
    .flatMap((action) => [actions.setAwsCurReports(action.payload)]);

const setAwsCurManifest = (action$) =>
  action$
    .ofType(actions.fetchAwsCurManifest.success.TYPE)
    .flatMap((action) => [actions.setAwsCurManifest(action.payload)]);

const awsCurEpic = combineEpics(
  fetchAwsCurReports,
  fetchAwsManifest,
  fetchAwsTemplates,
  setAwsCurReports,
  setAwsCurManifest,
  setAwsTemplates,
);

export default awsCurEpic;
