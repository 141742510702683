import {composeReducers} from 'common/utils/reducers';
import * as actions from 'bc/store/actions';

const defaultState = {
  timeDefinition: {
    timeColumn: null,
    timePattern: null,
    timeZone: null,
  },
  assets: {
    streamId: null,
    alerts: {
      items: [],
      total: 0,
    },
    composites: {
      items: [],
      total: 0,
    },
    dashboards: {
      items: [],
      total: 0,
    },
    dashboardsV2: {
      items: [],
      total: 0,
    },
  },
};

const viewStreamSummaryModalReducer = composeReducers((state = defaultState, {type, payload}) => {
  switch (type) {
    case actions.getViewStreamSummaryDimetricsTimeDefinition.TYPE:
      return {...state, timeDefinition: {...defaultState.timeDefinition, isLoading: true}};
    case actions.setViewStreamSummaryDimetricsTimeDefinition.TYPE:
      return {...state, timeDefinition: {...payload, isLoading: false}};
    case actions.setViewStreamSummaryAssets.TYPE:
      if (payload.assets) {
        return {...state, assets: {...state.assets, ...payload.assets}};
      }
      return {...state, assets: {...state.assets, [payload.assetType]: {...payload.asset, isLoading: false}}};
    case actions.fetchStreamAlerts.failure.TYPE:
      return {
        ...state,
        assets: {
          ...state.assets,
          alerts: {
            items: [],
            total: 0,
            error: payload,
            isLoading: false,
          },
        },
      };
    case actions.fetchStreamComposites.failure.TYPE:
      return {
        ...state,
        assets: {
          ...state.assets,
          composites: {
            items: [],
            total: 0,
            error: payload,
            isLoading: false,
          },
        },
      };
    case actions.fetchStreamDashboards.failure.TYPE:
      return {
        ...state,
        assets: {
          ...state.assets,
          dashboards: {
            items: [],
            total: 0,
            error: payload,
            isLoading: false,
          },
        },
      };
    case actions.fetchStreamDashboardsV2.failure.TYPE:
      return {
        ...state,
        assets: {
          ...state.assets,
          dashboardsV2: {
            items: [],
            total: 0,
            error: payload,
            isLoading: false,
          },
        },
      };
    default:
      return state;
  }
});

export default viewStreamSummaryModalReducer;
