// @flow
import React, {PureComponent} from 'react';
import calcWidthInPer from 'common/componentsV2/accordion/widthCalc';

import './SimpleRow.module.scss';

type PropTypes = {
  children: Node,
};

export default class SimpleRow extends PureComponent {
  props: PropTypes;

  render() {
    const style = {
      item: {
        width: calcWidthInPer(this.props.children.length),
      },
    };
    return (
      <div styleName="container">
        <div styleName="titles">
          {this.props.children.map((i, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <span className="ellipsis" style={style.item} key={index}>
              {i.props.children}
            </span>
          ))}
        </div>
      </div>
    );
  }
}
