// @flow
import React from 'react';
import {Provider} from 'react-redux';
import {getConfiguredStore} from 'configureStore';
import UsersGroupsSelect from './UsersGroupsSelect';
import {broadcast} from '../../common/utils/angularServices';

type PropTypes = {
  selectedOwner: Object,
  disabled: boolean,
  isSameGroup: boolean,
  resourceName: String,
};

/*
 We need to provide the store before exposing components, so we wrap with a provider
 */

const UsersGroupsSelectWrapper = (props: PropTypes) => {
  const onChange = (item) => {
    broadcast('andtUsersGroupsSelectChanged', item);
  };

  return (
    <Provider store={getConfiguredStore()}>
      <UsersGroupsSelect
        onChange={onChange}
        value={props.selectedOwner._id}
        disabled={props.disabled}
        isSameGroup={props.isSameGroup}
        resourceName={props.resourceName}
        showGroupSubText
      />
    </Provider>
  );
};

export default UsersGroupsSelectWrapper;
