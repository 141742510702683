// @flow
import React, {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import Highlighter from 'react-highlight-words';
import {Box} from '@material-ui/core';
import SearchBox from 'common/componentsV2/SearchBox';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import EmptyListImageComponent from 'common/componentsV2/EmptyListImageComponent';
import TinyScrollBox from 'common/componentsV2/boxTools/TinyScrollBox';
import {segmentClickEvent} from 'common/store/actions';
import {
  getTMLeftPanelSearchQuery,
  getTMLeftPanelAssetsFiltered,
  getTMFetchTopologyMapIsLoading,
  getTMLeftPanelSearchSelectedItemId,
  getTMLeftPanelSelectedIssueId,
  getTMLeftPanelIssuesListFiltered,
  getTMFetchTopologyIssuesIsLoading,
  getTMLeftPanelIssuesType,
  getTMLeftPanelSearchResultIsEmpty,
} from 'topologyLeaflet/store/selectors';
import {getTimeZoneName} from 'profile/store/selectors';
import {PANEL_SIZES} from 'topologyLeaflet/services/leftPanelService';
import IssuesList from 'topologyLeaflet/components/leftPanel/IssuesList';
import {TriangleSvg, CircleSvg, HexagonSvg} from 'topologyLeaflet/components/leftPanel/AssetSvgs';
import './LeftPanel.module.scss';

const EmptySearchResult = () => (
  <div styleName="empty-wrapper">
    <EmptyListImageComponent />
    <div styleName="empty-text">Empty Search Result</div>
  </div>
);

const AssetItem = ({
  item,
  selected,
  onClick,
  searchText,
}: {
  item: Object,
  selected: boolean,
  onClick: Function,
  searchText: String,
}) => {
  const getIconByType = () => {
    switch (item.type) {
      case 'ABR':
        return (
          <TriangleSvg
            fillColor={item.styles.colors.fill}
            strokeColor={item.styles.colors.stroke}
            strokeDashArray={item.styles.strokeDashArray}
            size={22}
          />
        );
      case 'RING':
        return (
          <CircleSvg
            fillColor={item.styles.colors.fill}
            strokeColor={item.styles.colors.stroke}
            strokeDashArray={item.styles.strokeDashArray}
            radius={10}
          />
        );
      default:
        return (
          <HexagonSvg
            fillColor={item.styles.colors.fill}
            strokeColor={item.styles.colors.stroke}
            strokeDashArray={item.styles.strokeDashArray}
            size={22}
          />
        );
    }
  };

  const onClickLocale = () => {
    onClick(item);
  };

  return (
    <Box styleName={`tp-asset-item ${selected ? 'selected' : ''}`} onClick={onClickLocale}>
      <Box width={40} flexShrink={0} display="flex" alignItems="center" justifyContent="center">
        {getIconByType()}
      </Box>
      <Box display="flex" flexDirection="column" flexGrow={1} fontWeight={500}>
        <Highlighter autoEscape searchWords={[searchText]} textToHighlight={item.name} />
        {item.additional ? (
          <Box color="gray.400">
            <Highlighter
              autoEscape
              searchWords={[searchText]}
              textToHighlight={`${item.additional.label}: ${item.additional.value}`}
            />
          </Box>
        ) : null}
      </Box>
      <Box styleName="arrow">
        <i className="icn-arrow16-arrowright" />
      </Box>
    </Box>
  );
};

const AssetsSection = ({
  isShowAll,
  onChangeShowAllClick,
  onAssetItemClick,
  setQueryParams,
  searchText,
}: {
  isShowAll: boolean,
  onChangeShowAllClick: Function,
  onAssetItemClick: Function,
  setQueryParams: Function,
  searchText: String,
}) => {
  const dispatch = useDispatch();
  const [smallAssetsList, setSmallAssetsList] = useState([]);
  const assets = useSelector(getTMLeftPanelAssetsFiltered);
  const searchSelectedItemId = useSelector(getTMLeftPanelSearchSelectedItemId);

  useEffect(() => {
    if (assets && assets.items) {
      setSmallAssetsList(assets.items.slice(0, 5));
    }
  }, [assets]);

  const onShowAllClick = () => {
    onChangeShowAllClick(true);
  };

  const onBackClick = () => {
    onChangeShowAllClick(false);
  };

  const onItemClick = (item) => {
    if (searchSelectedItemId === item.id) {
      setQueryParams({searchSelectedItemId: undefined});
    } else {
      setQueryParams({searchSelectedItemId: item.id});
      dispatch(segmentClickEvent({type: 'click', name: `On the Map - ${item.id}`}));
    }

    if (onAssetItemClick) {
      onAssetItemClick(item.id);
    }
  };

  if (!assets.total) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={0.25}>
        <Box display="flex" fontSize={16} fontWeight={500} color={`gray.${isShowAll ? 500 : 400}`}>
          <Box width={20} display={isShowAll ? 'flex' : 'none'} alignItems="center">
            <Tooltip content="All Results" type={TYPES.SMALL}>
              <i styleName="back-arrow" className="icn-arrow16-arrowleft" onClick={onBackClick} />
            </Tooltip>
          </Box>
          <span>{`On the map (${isShowAll ? assets.items.length : smallAssetsList.length}/${assets.total})`}</span>
        </Box>
        {!isShowAll && assets.total > 5 ? (
          <Box fontSize={14} fontWeight={500} color="blue.500" css={{cursor: 'pointer'}} onClick={onShowAllClick}>
            See All
          </Box>
        ) : null}
      </Box>
      {isShowAll ? (
        <TinyScrollBox
          width={PANEL_SIZES.tinyScrollBox}
          position="absolute"
          top={46}
          bottom={10}
          css={{overflowY: 'auto', overflowX: 'hidden'}}
        >
          <div>
            {assets.items.map((asset) => (
              <AssetItem
                item={asset}
                searchText={searchText}
                key={`assets-full-${asset.id}`}
                selected={searchSelectedItemId === asset.id}
                onClick={onItemClick}
              />
            ))}
          </div>
        </TinyScrollBox>
      ) : (
        smallAssetsList.map((asset) => (
          <AssetItem
            item={asset}
            searchText={searchText}
            key={`assets-small-${asset.id}`}
            selected={searchSelectedItemId === asset.id}
            onClick={onItemClick}
          />
        ))
      )}
    </Box>
  );
};

const IssuesSection = ({
  isShowAll,
  onChangeShowAllClick,
  onIssueItemClick,
  searchText,
  setQueryParams,
}: {
  isShowAll: boolean,
  onChangeShowAllClick: Function,
  onIssueItemClick: Function,
  searchText: String,
  setQueryParams: Function,
}) => {
  const [smallIssuesList, setSmallIssuesList] = useState([]);
  const timeZoneName = useSelector(getTimeZoneName);
  const issues = useSelector(getTMLeftPanelIssuesListFiltered);
  const issuesIsLoading = useSelector(getTMFetchTopologyIssuesIsLoading);
  const selectedIssueId = useSelector(getTMLeftPanelSelectedIssueId);
  const issuesType = useSelector(getTMLeftPanelIssuesType);

  useEffect(() => {
    if (issues && issues.items) {
      setSmallIssuesList(issues.items.slice(0, 5));
    }
  }, [issues]);

  const onShowAllClick = () => {
    onChangeShowAllClick(true);
  };

  const onBackClick = () => {
    onChangeShowAllClick(false);
  };

  const onItemClick = (item) => {
    const itemId = selectedIssueId === item.id ? undefined : item.id;
    setQueryParams({selectedIssueId: itemId});

    if (onIssueItemClick) {
      onIssueItemClick(itemId);
    }
  };

  if (!issues.total) {
    return null;
  }

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Box display="flex" fontSize={16} fontWeight={500} color={`gray.${isShowAll ? 500 : 400}`}>
          <Box width={20} display={isShowAll ? 'flex' : 'none'} alignItems="center">
            <Tooltip content="All Results" type={TYPES.SMALL}>
              <i styleName="back-arrow" className="icn-arrow16-arrowleft" onClick={onBackClick} />
            </Tooltip>
          </Box>
          <span>
            {`${issuesType === 'alerts' ? 'Alerts' : 'Anomalies'} (${
              isShowAll ? issues.items.length : smallIssuesList.length
            }/${issues.total})`}
          </span>
        </Box>
        {!isShowAll && issues.total > 5 ? (
          <Box fontSize={14} fontWeight={500} color="blue.500" css={{cursor: 'pointer'}} onClick={onShowAllClick}>
            See All
          </Box>
        ) : null}
      </Box>
      {isShowAll ? (
        <TinyScrollBox
          width={PANEL_SIZES.tinyScrollBox}
          position="absolute"
          top={52}
          bottom={10}
          css={{overflowY: 'auto', overflowX: 'hidden'}}
        >
          <Box width={PANEL_SIZES.issueWidth}>
            <IssuesList
              onIssueItemClicked={onItemClick}
              issues={issues.items}
              isLoading={issuesIsLoading}
              selectedIssueId={selectedIssueId}
              timeZoneName={timeZoneName}
            />
          </Box>
        </TinyScrollBox>
      ) : (
        <IssuesList
          textHighlight={searchText}
          onIssueItemClicked={onItemClick}
          issues={smallIssuesList}
          isLoading={issuesIsLoading}
          selectedIssueId={selectedIssueId}
          timeZoneName={timeZoneName}
        />
      )}
    </Box>
  );
};

type PropTypes = {
  setQueryParams: Function,
  onAssetItemClick: Function,
};

const TPSearchPanel = ({setQueryParams, onAssetItemClick}: PropTypes) => {
  const dispatch = useDispatch();
  const filter = useSelector(getTMLeftPanelSearchQuery);
  const topologyMapDataIsLoading = useSelector(getTMFetchTopologyMapIsLoading);
  const isEmptyResults = useSelector(getTMLeftPanelSearchResultIsEmpty);
  const [assetsShowAll, setAssetsShowAll] = useState(false);
  const [issuesShowAll, setIssuesShowAll] = useState(false);

  const onFilter = (val) => {
    let newSearchQuery = val;
    if (!newSearchQuery) {
      newSearchQuery = undefined;
    }
    setQueryParams({searchQuery: newSearchQuery});
    if (newSearchQuery && newSearchQuery.length > 3) {
      dispatch(segmentClickEvent({category: 'topology/search', name: `Search for - ${newSearchQuery}`}));
    }
  };

  const onChangeShowAllClick = useCallback((val) => {
    setAssetsShowAll(val);
    dispatch(segmentClickEvent({category: 'topology/search', name: 'See All - On the Map'}));
  }, []);

  const onChangeIssuesAllClick = useCallback((val) => {
    setIssuesShowAll(val);
    dispatch(segmentClickEvent({category: 'topology/search', name: 'See All - In the Alert'}));
  }, []);

  const onIssueItemClick = useCallback((itemId) => {
    dispatch(segmentClickEvent({category: 'topology/search', name: `In the Alert - ${itemId}`}));
  }, []);

  return (
    <Box styleName="tp-search-panel">
      <SearchBox onFilter={onFilter} filter={filter} placeHolder="Search for assets" automationId="tpSearchFilter" />

      {!topologyMapDataIsLoading ? (
        <TinyScrollBox
          mt={1.5}
          position="absolute"
          top={40}
          bottom={10}
          width={PANEL_SIZES.tinyScrollBox}
          css={{overflowY: 'auto', overflowX: 'hidden'}}
        >
          <Box width={PANEL_SIZES.issueWidth} display={isEmptyResults ? 'none' : 'block'}>
            <Box mt={2.5} display={issuesShowAll ? 'none' : 'block'}>
              <AssetsSection
                isShowAll={assetsShowAll}
                onChangeShowAllClick={onChangeShowAllClick}
                onAssetItemClick={onAssetItemClick}
                setQueryParams={setQueryParams}
                searchText={filter}
              />
            </Box>
            <Box mt={2.5} display={assetsShowAll ? 'none' : 'block'}>
              <IssuesSection
                isShowAll={issuesShowAll}
                onChangeShowAllClick={onChangeIssuesAllClick}
                onIssueItemClick={onIssueItemClick}
                searchText={filter}
                setQueryParams={setQueryParams}
              />
            </Box>
          </Box>
          <Box display={isEmptyResults ? 'block' : 'none'}>
            <EmptySearchResult />
          </Box>
        </TinyScrollBox>
      ) : null}
    </Box>
  );
};

export default TPSearchPanel;
