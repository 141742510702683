// @flow
import React, {useEffect, useState, useRef, useCallback} from 'react';
import {makeStyles} from '@material-ui/core';
import {getUniqueId} from 'common/utils/guid';

type PropsType = {
  isResize: boolean,
  heightGrid: Number,
  rowHeight: Number,
};

const useStyles = makeStyles(({palette}) => ({
  wrapper: {
    height: (props) => props.rowHeight,
    flexGrow: 1,
    position: 'relative',
  },
  point: {
    borderRadius: '100%',
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 5,
    height: 5,
    backgroundColor: palette.gray[300],
  },
}));

const BackgroundGrid = ({isResize, heightGrid, rowHeight}: PropsType) => {
  const classes = useStyles({rowHeight});
  const root = useRef();
  const [rows, setRows] = useState(0);

  const generateLayout = useCallback(() => {
    const HEIGHT_WITH_MARGIN = rowHeight + 12;
    const heightRoot = root.current.clientHeight;
    const height = heightRoot > heightGrid ? heightRoot : heightGrid;
    setRows(Math.floor((height + HEIGHT_WITH_MARGIN * 3) / HEIGHT_WITH_MARGIN) || 0);
  }, [isResize, heightGrid, rowHeight]);

  useEffect(() => {
    window.addEventListener('resize', generateLayout);
    return () => {
      window.removeEventListener('resize', generateLayout);
    };
  }, []);

  useEffect(() => {
    generateLayout();
  }, [isResize, heightGrid, rowHeight]);
  return (
    <div ref={root} className="height_1 width_1 position_absolute pt_1-5 pr_2">
      {new Array(rows).fill('').map((item, rootIndex) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={rootIndex} index={rootIndex} className="display_flex justifyContent_space-between mb_1-5">
          {new Array(12).fill('').map(() => (
            <div key={getUniqueId()} className={classes.wrapper}>
              <div className={classes.point} />
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default BackgroundGrid;
