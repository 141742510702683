import React from 'react';
import Accounts from './accounts/Accounts';
import GoogleAdsSchedular from './gadsSchedular/GoogleAdsSchedular';
import DimensionsAndMetrics from './gadsDimensionsAndMetrics/DimensionsAndMetrics';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

import './GoogleAdsStreamEditor.module.scss';

const GoogleAdsStreamEditor = () => (
  <div styleName="root" automation-id="googleAdsStreamEditor" className="shell-col">
    <StreamEditorSteps>
      <Accounts />
      <DimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <GoogleAdsSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default GoogleAdsStreamEditor;
