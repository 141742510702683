/* eslint-disable no-console */
// @flow
import React, {Fragment} from 'react';
import './StyleGuideComponents.module.scss';
import Button, {COLORS, WIDTHS, HEIGHTS} from 'common/componentsV2/Button';

export default class ButtonsSection extends React.PureComponent {
  render() {
    return (
      <Fragment>
        <h4>Buttons</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Basic Button</h5>
            <Button colorSchema={COLORS.BLUE_500} text="My Button" onClick={() => console.log('Basic Button')} />
          </div>

          <div styleName="item">
            <h5>Basic Button with Icon</h5>
            <Button
              colorSchema={COLORS.BLUE_500}
              text="My Button"
              onClick={() => console.log('Basic Button with Icon')}
              icon="icn-general16-placeholder"
            />
          </div>

          <div styleName="item">
            <h5>Button Just Icon</h5>
            <Button
              colorSchema={COLORS.GRAY_300}
              width={WIDTHS.NARROW}
              height={HEIGHTS.TIGHT28}
              text=""
              onClick={() => console.log('Button just Icon')}
              icon="icn-general16-placeholder"
            />
          </div>

          <div styleName="item">
            <h5>Button Width Wide</h5>
            <Button
              colorSchema={COLORS.BLUE_500}
              width={WIDTHS.WIDE}
              isLoading={false}
              text="My Button"
              onClick={() => console.log('Button Width Wide')}
              isDisabled={false}
            />
          </div>

          <div styleName="item">
            <h5>Button Width Wide with loader</h5>
            <Button
              colorSchema={COLORS.BLUE_500}
              width={WIDTHS.WIDE}
              isLoading
              text="My Button"
              onClick={() => console.log('Button Width Wide')}
            />
          </div>

          <div styleName="item">
            <h5>Basic Button Disabled</h5>
            <Button
              colorSchema={COLORS.BLUE_500}
              text="My Button"
              onClick={() => console.log('Basic Button')}
              isDisabled
            />
          </div>

          <div styleName="item">
            <h5>Button Narrow</h5>
            <Button
              colorSchema={COLORS.BLUE_500}
              width={WIDTHS.NARROW}
              text="My Button"
              onClick={() => console.log('Basic Button Narrow')}
            />
          </div>

          <div styleName="item">
            <h5>Button Tight 28px</h5>
            <Button
              colorSchema={COLORS.BLUE_500}
              height={HEIGHTS.TIGHT28}
              text="My Button"
              onClick={() => console.log('Button Tight 28px')}
            />
          </div>
        </div>

        <div styleName="presentation">
          <div styleName="item">
            <h5>Button Tight 30px</h5>
            <Button
              colorSchema={COLORS.BLUE_500}
              height={HEIGHTS.TIGHT30}
              text="My Button"
              onClick={() => console.log('Button Tight 30px')}
            />
          </div>

          <div styleName="item">
            <h5>Button Tall</h5>
            <Button
              colorSchema={COLORS.BLUE_500}
              height={HEIGHTS.TALL}
              text="My Button"
              onClick={() => console.log('Button Tall')}
            />
          </div>

          <div styleName="item">
            <h5>Button Fixed Width (300px)</h5>
            <Button
              colorSchema={COLORS.BLUE_500}
              text="My Button"
              fixedWidth={300}
              onClick={() => console.log('Button 300 px')}
            />
          </div>

          <div styleName="item">
            <h5>Button other color</h5>
            <Button colorSchema={COLORS.GREEN_500} text="My Button" onClick={() => console.log('Button other color')} />
          </div>
        </div>
      </Fragment>
    );
  }
}
