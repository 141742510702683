/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {getUniqueId} from 'common/utils/guid';

const facebookAdsDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  // gets sources ids used by items recursively
  const getSourceIdsArr = (sourcesIdArr, item) => {
    if (item.sourceColumn) {
      if (!sourcesIdArr.includes(item.sourceColumn)) {
        sourcesIdArr.push(item.sourceColumn);
      }
    } else if (item.transform && item.transform.input) {
      item.transform.input.forEach((a) => {
        getSourceIdsArr(sourcesIdArr, a);
      });
    }
    return sourcesIdArr;
  };

  const getOriginalName = (item) => {
    if (item.transform && item.transform.input) {
      return getSourceIdsArr([], item)[0];
    }
    if (item.sourceColumn) {
      return item.sourceColumn;
    }
    return item.name;
  };

  switch (type) {
    case actions.setSelectedFacebookAdsTemplate.TYPE:
      const stream = state.streams.data[getSelectedIndex()];

      const res = {
        schema: {
          columns: [],
          sourceColumns: [],
        },
        metrics: [],
        dimensions: [],
      };

      payload.dimensions.forEach((dimension) => {
        res.dimensions.push(dimension);
        res.schema.columns.push({
          id: getUniqueId(),
          sourceColumn: dimension,
          name: dimension,
          type: 'dimension',
        });
        res.schema.sourceColumns.push({
          id: dimension,
          name: dimension,
        });
      });
      payload.metrics.forEach((metric) => {
        res.metrics.push(metric);
        res.schema.columns.push({
          id: getUniqueId(),
          sourceColumn: metric,
          name: metric,
          type: 'metric',
        });
        res.schema.sourceColumns.push({
          id: metric,
          name: metric,
        });
      });

      // / updating the new schema with old schema modifications
      const addToSchemaArr = [];
      if (stream.schema && stream.schema.columns && stream.schema.columns.length) {
        stream.schema.columns.forEach((oldItem) => {
          const oldItemOriginalName = getOriginalName(oldItem);

          // first check if the item is user created (not showing in sourceColumns) or is "_ACCOUNT_NAME_" added
          if (oldItem.transform && oldItem.transform.input && oldItem.transform.input.length > 1) {
            let shouldPush = true;
            getSourceIdsArr([], oldItem).forEach((oldId) => {
              if (typeof stream.schema.sourceColumns.find((sourceItem) => sourceItem.id === oldId) === 'undefined') {
                shouldPush = false;
              }
            });
            if (shouldPush) {
              addToSchemaArr.push(oldItem);
            }
          } else if (
            typeof stream.schema.sourceColumns.find((sourceItem) => sourceItem.id === oldItemOriginalName) ===
              'undefined' ||
            (oldItem.transform && oldItem.transform.input && oldItem.transform.input.length > 1)
          ) {
            if (oldItem.transform && oldItem.transform.name === 'const') {
              addToSchemaArr.push(oldItem);
            }
          } else {
            res.schema.columns.forEach((newItem) => {
              if (newItem.type === oldItem.type && newItem.name === oldItemOriginalName) {
                // this item was in the previous schema
                if (!oldItem.transform || !oldItem.transform.input || !oldItem.transform.input.length > 1) {
                  // verify this is noy a transformed item using the new item
                  newItem.name = oldItem.name;
                  if (oldItem.transform) {
                    newItem.transform = oldItem.transform;
                  }
                  if (typeof oldItem.sourceColumn === 'undefined') {
                    delete newItem.sourceColumn;
                  }
                }
              }
            });
          }
        });

        // res.schema.columns.concat(addToSchemaArr)
        addToSchemaArr.forEach((item) => {
          res.schema.columns.push(item);
        });
      }

      return {...redWrapper(res), isStreamNeedUpdating: true};

    default:
      return state;
  }
});

export default facebookAdsDataStreamReducer;
