import {combineEpics} from 'redux-observable';
import {get as _get} from 'lodash';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';

import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

// ***accounts
const fetchGoogleAdsAccounts = makeAsyncEpic(actions.fetchGoogleAdsAccounts, api.fetchGoogleAdsAccounts);

const setDefaultGoogleAdsAccount = (action$, {getState}) =>
  action$
    .ofType(actions.fetchGoogleAdsAccounts.success.TYPE)
    .filter(
      (action) =>
        _get(action, 'payload[0].id') &&
        selectors.getSelectedDataStream(getState()) &&
        !selectors.getGoogleAdsSelectedAccount(getState()),
    )
    .flatMap((action) => {
      if (action.meta && action.meta.skip) {
        return [];
      }
      return [
        actions.setSelectedStreamKeyVal({
          clientCustomerId: _get(action, 'payload[0].id'),
          timezone: _get(action, 'payload[0].timeZone'),
        }),
      ];
    });

const fetchGoogleAdsTemplates = makeAsyncEpic(actions.fetchGoogleAdsTemplates, api.fetchStreamTemplates);

const googleAdsEpic = combineEpics(fetchGoogleAdsAccounts, setDefaultGoogleAdsAccount, fetchGoogleAdsTemplates);

export default googleAdsEpic;
