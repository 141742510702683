// @flow
import React from 'react';
import {Box} from '@material-ui/core';
import Checkbox from 'common/componentsV2/Checkbox';

type PropTypes = {
  failOnAbsence: Boolean,
  onSetSuppress: Function,
};

export default class FailOnAbsence extends React.PureComponent {
  props: PropTypes;

  handleChange = () => {
    const {onSetSuppress, failOnAbsence} = this.props;
    onSetSuppress(failOnAbsence);
  };

  render() {
    const {failOnAbsence} = this.props;

    return (
      <Box css={{'& label': {alignItems: 'flex-start'}}}>
        <Checkbox
          automationId="sendIfInfluencingMetricIsMissing"
          isChecked={!failOnAbsence}
          onChange={this.handleChange}
        >
          <div className="text16reg lineHeight_16 ml_0-5">Send the alert even if the influencing metric is missing</div>
        </Checkbox>
      </Box>
    );
  }
}
