import {combineEpics} from 'redux-observable';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as api from 'userEvents/services/api';
import * as actions from 'userEvents/store/actions';

const fetchUserEventsSources = makeAsyncEpic(actions.fetchUserEventsSources, api.fetchUserEventsSources);
const createUserEventsSource = makeAsyncEpic(actions.crateUserEventsSource, api.createUserEventsSource);
const removeUserEventsSource = makeAsyncEpic(actions.deleteUserEventsSource, api.deleteUserEventsSource);

const dataStreamEpic = combineEpics(fetchUserEventsSources, createUserEventsSource, removeUserEventsSource);

export default dataStreamEpic;
