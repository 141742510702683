import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {getUniqueId} from 'common/utils/guid';
import {isEqual} from 'lodash';

const coralogixDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const stream = state.streams.data[getSelectedIndex()];

  const getItemFromSchema = (itemName) => {
    let found = stream.schema.columns.find((item) => item.name === itemName);
    if (typeof found === 'undefined') {
      found = stream.uiState.unAssignedColumns.find((item) => item.name === itemName);
    }
    return found;
  };

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  switch (type) {
    case actions.setCoralogixLabels.TYPE: {
      if (
        !payload ||
        payload.length === 0 ||
        (!stream.uiState || !stream.uiState.labels || !isEqual(stream.uiState.labels, payload))
      ) {
        const res = {
          dimensions: ['_METRIC_NAME_'],
          schema: {
            columns:
              stream.schema && stream.schema.columns
                ? stream.schema.columns.filter((m) => !stream.dimensions.includes(m.sourceColumn))
                : [],
            sourceColumns:
              stream.schema && stream.schema.sourceColumns
                ? stream.schema.sourceColumns.filter((m) => !stream.dimensions.includes(m.id))
                : [],
          },
          uiState: {
            ...stream.uiState,
            labels: payload,
          },
        };

        res.schema.columns.push({
          id: getUniqueId(),
          sourceColumn: '_METRIC_NAME_',
          name: 'MetricName',
          type: 'dimension',
        });
        res.schema.sourceColumns.push({
          id: '_METRIC_NAME_',
          name: '_METRIC_NAME_',
        });
        const labelsArr = [];
        // eslint-disable-next-line
        for (const key of Object.keys(payload)) {
          labelsArr.push(key);
        }
        labelsArr.forEach((label) => {
          res.dimensions.push(label);
          res.schema.columns.push({
            id: getUniqueId(),
            sourceColumn: label,
            name: label,
            type: 'dimension',
          });
          res.schema.sourceColumns.push({
            id: label,
            name: label,
          });
        });

        return {...redWrapper(res)};
      }
      return state;
    }

    case actions.setCoralogixMeasures.TYPE: {
      if (
        !payload ||
        payload.length === 0 ||
        (!stream.uiState || !stream.uiState.measures || !isEqual(stream.uiState.measures, payload))
      ) {
        const res = {
          metrics: ['_DOC_COUNT_SUM_'],
          schema: {
            columns:
              stream.schema && stream.schema.columns
                ? stream.schema.columns.filter((m) => !stream.metrics.includes(m.sourceColumn))
                : [],
            sourceColumns:
              stream.schema && stream.schema.sourceColumns
                ? stream.schema.sourceColumns.filter((m) => !stream.metrics.includes(m.id))
                : [],
          },
          uiState: {
            ...stream.uiState,
            measures: payload,
          },
        };
        res.schema.columns.push({
          id: getUniqueId(),
          sourceColumn: '_DOC_COUNT_SUM_',
          name: 'Doc_Count',
          targetType: 'counter',
          type: 'metric',
        });
        res.schema.sourceColumns.push({
          id: '_DOC_COUNT_SUM_',
          name: '_DOC_COUNT_SUM_',
        });
        payload.forEach((measure) => {
          res.metrics.push(measure);
          res.schema.columns.push({
            id: getUniqueId(),
            sourceColumn: measure,
            targetType: measure.includes('.avg') ? 'gauge' : 'counter',
            name: measure,
            type: 'metric',
          });
          res.schema.sourceColumns.push({
            id: measure,
            name: measure,
          });
        });
        return {...redWrapper(res)};
      }
      return state;
    }

    case actions.setCoralogixDiametricsChange.TYPE: {
      const mod = {
        uiState: {...stream.uiState},
      };
      let allowed = true;

      const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
      };

      if (payload.source.droppableId === payload.destination.droppableId) {
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            mod.uiState.unAssignedColumns = reorder(
              stream.uiState.unAssignedColumns,
              payload.source.index,
              payload.destination.index,
            );
            break;
          }
          case 'dmMetrics': {
            mod.metrics = reorder(stream.metrics, payload.source.index, payload.destination.index);
            break;
          }
          case 'dmDimensions': {
            mod.dimensions = reorder(stream.dimensions, payload.source.index, payload.destination.index);
            break;
          }
          default:
            return state;
        }
      } else {
        if (!stream.uiState.unAssignedColumns) {
          stream.uiState.unAssignedColumns = [];
        }
        mod.schema = {
          columns: stream.schema.columns,
          sourceColumns: stream.schema.sourceColumns,
        };
        switch (payload.destination.droppableId) {
          case 'dmAllColumns': {
            mod.uiState.unAssignedColumns = [...stream.uiState.unAssignedColumns];
            mod.uiState.unAssignedColumns.splice(payload.destination.index, 0, getItemFromSchema(payload.draggableId));
            mod.schema.columns.splice(mod.schema.columns.findIndex((a) => a.name === payload.draggableId), 1);
            mod.schema.sourceColumns.splice(
              mod.schema.sourceColumns.findIndex((a) => a.name === payload.draggableId),
              1,
            );
            break;
          }
          case 'dmMetrics': {
            if (getItemFromSchema(payload.draggableId).type === 'metric') {
              mod.metrics = [...stream.metrics];
              mod.metrics.splice(payload.destination.index, 0, payload.draggableId);
              mod.schema.columns.push(getItemFromSchema(payload.draggableId));
              mod.schema.sourceColumns.push({id: payload.draggableId, name: payload.draggableId});
            } else {
              allowed = false;
            }
            break;
          }
          case 'dmDimensions': {
            if (getItemFromSchema(payload.draggableId).type === 'dimension') {
              mod.dimensions = [...stream.dimensions];
              mod.dimensions.splice(payload.destination.index, 0, payload.draggableId);
              mod.schema.columns.push(getItemFromSchema(payload.draggableId));
              mod.schema.sourceColumns.push({id: payload.draggableId, name: payload.draggableId});
            } else {
              allowed = false;
            }
            break;
          }
          default:
            return state;
        }

        if (allowed) {
          switch (payload.source.droppableId) {
            case 'dmAllColumns': {
              mod.uiState.unAssignedColumns = [...(mod.uiState.unAssignedColumns || stream.uiState.unAssignedColumns)];
              mod.uiState.unAssignedColumns.splice(
                mod.uiState.unAssignedColumns.findIndex((a) => a.name === payload.draggableId),
                1,
              );
              break;
            }
            case 'dmMetrics': {
              mod.metrics = [...stream.metrics];
              mod.metrics.splice(
                mod.metrics.findIndex((a) => (a.name ? a.name === payload.draggableId : a === payload.draggableId)),
                1,
              );
              break;
            }
            case 'dmDimensions': {
              mod.dimensions = [...stream.dimensions];
              mod.dimensions.splice(
                mod.dimensions.findIndex((a) => (a.name ? a.name === payload.draggableId : a === payload.draggableId)),
                1,
              );
              break;
            }
            default:
              return state;
          }
        }
      }

      return redWrapper(mod);
    }

    case actions.setCoralogixDiametricsClearAll.TYPE: {
      const mod = {
        metrics: ['_DOC_COUNT_SUM_'],
        dimensions: ['_METRIC_NAME_'],
        timeDefinition: null,
        uiState: {
          ...stream.uiState,
          unAssignedColumns: stream.schema.columns.filter(
            (item) => !['_DOC_COUNT_SUM_', '_METRIC_NAME_'].includes(item.sourceColumn),
          ),
        },
        schema: {
          columns: stream.schema.columns.filter((item) =>
            ['_DOC_COUNT_SUM_', '_METRIC_NAME_'].includes(item.sourceColumn),
          ),
          sourceColumns: stream.schema.sourceColumns.filter((item) =>
            ['_DOC_COUNT_SUM_', '_METRIC_NAME_'].includes(item.name),
          ),
        },
      };
      return redWrapper(mod);
    }

    case actions.removeCoralogixDiametrics.TYPE: {
      if (!stream.uiState.unAssignedColumns) {
        stream.uiState.unAssignedColumns = [];
      }
      const mod = {
        schema: {
          columns: [...stream.schema.columns],
          sourceColumns: [...stream.schema.sourceColumns],
        },
        uiState: {
          ...stream.uiState,
          unAssignedColumns: [...stream.uiState.unAssignedColumns],
        },
      };

      mod.uiState.unAssignedColumns.push(getItemFromSchema(payload));
      mod.schema.columns.splice(mod.schema.columns.findIndex((a) => a.name === payload), 1);
      mod.schema.sourceColumns.splice(mod.schema.sourceColumns.findIndex((a) => a.name === payload), 1);

      if (stream.metrics.indexOf(payload) >= 0) {
        mod.metrics = stream.metrics.filter((a) => a !== payload);
      } else if (stream.dimensions.indexOf(payload) >= 0) {
        mod.dimensions = stream.dimensions.filter((a) => a !== payload);
      }

      return redWrapper(mod);
    }

    default:
      return state;
  }
});

export default coralogixDataStreamReducer;
