// @flow
import React, {PureComponent} from 'react';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {getFormattedDateFull, getFormattedDateMini} from 'common/utils/dateService';

type PropTypes = {
  startDate: number,
  timeZone: string,
};

class IssueDate extends PureComponent {
  props: PropTypes;

  render() {
    const {startDate, timeZone} = this.props;
    const formattedDate = getFormattedDateFull(startDate, timeZone);
    const formattedMini = getFormattedDateMini(startDate, timeZone);

    return (
      <SmartTooltip placement="top" content={formattedDate} delay={400}>
        <span className="ellipsis">{formattedMini}</span>
      </SmartTooltip>
    );
  }
}

export default IssueDate;
