import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const tokenReducer = (state = [], {type, payload, meta}) => {
  switch (type) {
    case actions.fetchRefreshTokens.success.TYPE:
      return payload;
    case actions.refreshTokens.success.TYPE:
      return [...state, payload];
    case actions.revokeToken.success.TYPE: {
      const items = state.filter((token) => token._id !== meta.id);
      items.push(payload);
      return items;
    }
    default:
      return state;
  }
};

export default composeReducers(tokenReducer);
