// @flow
import React, {useCallback} from 'react';
import ToggleSwitch from 'common/componentsV2/toggleSwitch/ToggleSwitch';
import {ROLLUPS} from 'admin.customers/services/customersService';
import './EditCustomerForm.module.scss';
import {useField} from 'react-final-form';

const EditCustomerFormTimeScales = () => {
  const {
    input: {value: selectedOptions, onChange: setSelectedOptions},
  } = useField('customer.serverConfiguration.rollups');

  const toggleSelection = useCallback(
    (selectedOpt) => {
      let newSelection = [];
      if (selectedOptions.includes(selectedOpt)) {
        newSelection = selectedOptions.filter((opt) => opt !== selectedOpt);
      } else {
        newSelection = selectedOptions.concat(selectedOpt);
      }
      // Needed in order to keep the array ordered by constant definition
      setSelectedOptions(Object.keys(ROLLUPS).filter((opt) => newSelection.includes(opt)));
    },
    [selectedOptions, setSelectedOptions],
  );
  return (
    <div styleName="time-scales">
      {Object.keys(ROLLUPS).map((option) => (
        <div key={option}>
          <span styleName="label">{ROLLUPS[option].text}</span>
          <ToggleSwitch
            name={option}
            theme="blue"
            isChecked={selectedOptions && !!selectedOptions.find((opt) => opt === option)}
            disabled={ROLLUPS[option].readOnly}
            onToggle={() => toggleSelection(option)}
          />
        </div>
      ))}
    </div>
  );
};
export default EditCustomerFormTimeScales;
