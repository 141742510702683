import React from 'react';
import './FormDdlSelectIsNotOption.module.scss';

type PropTypes = {
  data: Object,
  isSelected: boolean,
};

const OptionComponentDefault = (props: PropTypes) => (
  <div className="form-option" styleName={['is-isnot-option', props.isSelected ? 'selected' : ''].join(' ')}>
    <div styleName="symbol">{props.data.symbol}</div>
    <div styleName="label">{props.data.label}</div>
    {props.isSelected && (
      <span styleName="checkbox" className="icon icn-general16-checkmark">
        {' '}
      </span>
    )}
  </div>
);

export default OptionComponentDefault;
