// @flow
/* eslint-disable */
import React from 'react';
import {Dropdown} from 'react-bootstrap';
import {omit} from 'lodash';

class DropdownButton extends React.PureComponent {
  getDropdownStyle = (locProps) => {
    let style = 'andt-dropdown';
    if (!locProps.value) {
      style += ' dropdown-null-value';
    }
    if (locProps.extraClassName) {
      style += ` ${locProps.extraClassName}`;
    }
    if (locProps.className) {
      style += ` ${locProps.className}`;
    }
    return style;
  };

  render() {
    const locProps = this.props;
    const props = omit(locProps, ['extraClassName', 'valueClassName', 'noCaret', 'className', 'title']);
    const valueStyle = locProps.valueClassName ? locProps.valueClassName : '';
    const title = locProps.title ? <span className="floating-title">{locProps.title}</span> : '';
    const noTitleClass = locProps.title ? '' : 'dropdown-value-no-title ';

    return (
      <Dropdown {...props} className={this.getDropdownStyle(locProps)}>
        <Dropdown.Toggle noCaret={locProps.noCaret}>
          <span className={`dropdown-value ${noTitleClass}${valueStyle}`}>
            {title}
            <span className="inner-value ellipsis">{locProps.value ? locProps.value : ''}</span>
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu>{locProps.children}</Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default DropdownButton;
