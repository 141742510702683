// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import DataTablePreviewModal from 'bc/components/streams/editor/common/DataTablePreviewModal';
import {get} from 'lodash';
import {fetchS3Preview as fetchS3PreviewAction} from 'bc/store/actions';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,

  // connect
  dataStream: Object,
  fetchS3Preview: Function,
  isLoading: Boolean,
  previewData: Object,
};

class S3FilePreview extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {
      dataStream: {dataSourceId, fileFormat, path, fileNameSuffix, fileNamePrefix, fileNamePattern, uiState},
      previewData,
      isLoading,
      fetchS3Preview,
    } = this.props;

    let fileName = null;
    if (uiState && uiState.analysisResult && uiState.analysisResult.fileName) {
      /* eslint-disable prefer-destructuring */
      fileName = uiState.analysisResult.fileName;
    }

    if (!previewData.rows && !isLoading) {
      fetchS3Preview({
        fileFormat,
        dataSourceId,
        path,
        fileNameSuffix,
        fileNamePrefix,
        fileNamePattern,
        fileName,
      });
    }
  }

  render() {
    const {isOpen, onClose, previewData, isLoading, fetchS3Preview} = this.props;

    return (
      <DataTablePreviewModal
        isOpen={isOpen}
        onClose={onClose}
        title="File Preview"
        subTitle={get(previewData, 'fileName', '')}
        previewData={previewData}
        isLoading={isLoading}
        fetchPreview={fetchS3Preview}
      />
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    previewData: selectors.getS3FilePreviewData(state),
    isLoading: selectors.getS3FilePreview(state).isLoading,
  }),
  {
    fetchS3Preview: fetchS3PreviewAction,
  },
)(S3FilePreview);
