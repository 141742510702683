import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const dataReducer = composeReducers(
  {
    fetchDnsTracker: makeAsyncReducer(actions.fetchDnsTrackerData, {
      defaultData: EMPTY_ARRAY,
    }),
  },
  (state = {}, {type}) => {
    switch (type) {
      default:
        return state;
    }
  },
);

export default dataReducer;
