import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';

const adobeDataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  switch (type) {
    case actions.setAdobeStreamUiBookmarkId.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      return stream
        ? redWrapper({
            uiState: {...stream.uiState, bookmarkId: payload},
            reportDescription: null,
            queryServiceState: null,
            reportId: null,
          })
        : state;
    }

    case actions.setAdobeStreamMetricsAndDimensions.TYPE: {
      return redWrapper();
    }

    default:
      return state;
  }
});

export default adobeDataStreamReducer;
