import React from 'react';
import Project from './Project';
import DataSets from './DataSets';
import ComposeQuery from './ComposeQuery';
import BqSchedular from './bqSchedular/BqSchedular';
import BqDimensionsAndMetrics from './bqDimensionsAndMetrics/BqDimensionsAndMetrics';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';
import './BigQueryEditor.module.scss';

// TODO: (Tomer.K) check spacing of the select option boxes

const BigQueryEditor = () => (
  <div className="shell-col" automation-id="bigQueryEditorSteps">
    <StreamEditorSteps>
      <div styleName="left-container">
        <Project />
        <DataSets />
        <ComposeQuery />
      </div>
      <BqDimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <BqSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default BigQueryEditor;
