const getEditorValidationMessage = (stream, isAll = false) => {
  const res = [];
  const isEmptyDimentrics = stream.dimensions.length === 0 && stream.metrics.length === 0;
  const isPristine = !stream.reportDescription || isEmptyDimentrics;

  if (!isPristine && stream.metrics.length <= 0) {
    res.push('No metrics declared in the selected bookmark');
  }

  if (!isAll && !stream.reportDescription) {
    res.push('Bookmark is required');
  }

  if (isAll) {
    return res;
  }
  return res[0];
};

export default getEditorValidationMessage;
