import {forEach, cloneDeep} from 'lodash';
import treeVisitor from './treeVisitor';

const nodeCleanableProperties = [
  '$$hashKey',
  'failures',
  'warnings',
  'treeId',
  'watchId',
  'needsValidation',
  'description',
  'activateOnCreate',
  'uiChartsLayout',
]; // 'uiIndex' is used in dashboard so we keep it
const paramCleanableProperties = [
  '$$hashKey',
  'defaultValue',
  'description',
  'mandatory',
  'type',
  'optionalValues',
  'validators',
  'displayType',
];

function cleanExpressionTree(tree) {
  if (!tree) {
    return;
  }
  const newTree = cloneDeep(tree);
  treeVisitor(newTree, 'children', function(nd) {
    forEach(nodeCleanableProperties, function(prop) {
      // eslint-disable-next-line no-param-reassign
      delete nd[prop];
    });
    forEach(nd.parameters, function(param) {
      forEach(paramCleanableProperties, function(prop) {
        // eslint-disable-next-line no-param-reassign
        delete param[prop];
      });
    });
  });
  // eslint-disable-next-line consistent-return
  return newTree;
}

export default cleanExpressionTree;
