import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {getAlertType} from 'alerts.management/store/selectors';
import {setSelectedAlertType} from 'alerts.management/store/actions';
import IconTabsBar from 'common/componentsV2/IconTabsBar';

import {ReactComponent as AnomalyOn} from 'alerts.management/images/type_anomaly_on.svg';
import {ReactComponent as AnomalyOff} from 'alerts.management/images/type_anomaly_off.svg';
import {ReactComponent as StaticOn} from 'alerts.management/images/type_static_on.svg';
import {ReactComponent as StaticOff} from 'alerts.management/images/type_static_off.svg';
import {ReactComponent as NoDataOn} from 'alerts.management/images/type_nodata_on.svg';
import {ReactComponent as NoDataOff} from 'alerts.management/images/type_nodata_off.svg';

const ALERT_TYPES = [
  {
    label: 'Anomaly',
    value: 'anomaly',
    onImage: AnomalyOn,
    offImage: AnomalyOff,
  },
  {
    label: 'Static',
    value: 'static',
    onImage: StaticOn,
    offImage: StaticOff,
  },
  {
    label: 'No Data',
    value: 'noData',
    onImage: NoDataOn,
    offImage: NoDataOff,
  },
];

const AlertType = ({alertDefinitionData}: {alertDefinitionData: Object}) => {
  const dispatch = useDispatch();
  const alertType = useSelector(getAlertType);

  useEffect(() => {
    if (alertDefinitionData.validation) {
      let computedAlertType = alertDefinitionData.type.replace('incident', 'anomaly');

      if (alertDefinitionData.staticAlert) {
        computedAlertType = 'static';
      } else if (alertDefinitionData.noDataAlert && alertDefinitionData.anomalyAlert === false) {
        computedAlertType = 'noData';
      }

      dispatch(
        setSelectedAlertType(computedAlertType, {
          isNewAlertEditor: true,
          isInit: true,
        }),
      );
    }
  }, [alertDefinitionData.validation]);

  return (
    <IconTabsBar
      automationId="alertType"
      value={alertType}
      items={ALERT_TYPES}
      onChange={(value) => dispatch(setSelectedAlertType(value, {isNewAlertEditor: true}))}
    />
  );
};

export default AlertType;
