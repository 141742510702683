/*
Event bus for react-angular communication
-----------------------------------------
React Example:
  import:
    import EventBus from 'common/utils/EventBus';
  subscribe: use componentDidMount
    EventBus.subscribe('id.of.event', callBackFunc);

Angular Example:
  subscribe: EventBus will be registered as global so use it directly
    EventBus.subscribe('id.of.event', callBackFunc);
    EventBus.dispatch('id.of.event', args);

*/

const eventCallbacksPairs = {
  // key:string -> value:[callbacks]
};

class EventBus {
  subscribe = (eventId, callback) => {
    if (!eventCallbacksPairs[eventId]) {
      eventCallbacksPairs[eventId] = [];
    }
    eventCallbacksPairs[eventId].push(callback);
  };

  unsubscribe = (eventId, callback) => {
    if (!eventCallbacksPairs[eventId]) {
      return;
    }
    eventCallbacksPairs[eventId] = eventCallbacksPairs[eventId].filter((c) => c !== callback);
  };

  dispatch = (eventId, args) => {
    (eventCallbacksPairs[eventId] || []).forEach((cb) => cb(args));
  };
}

export default new EventBus();
