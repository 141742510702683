// @flow
import React, {PureComponent} from 'react';
import './QueryEditorModal.module.scss';

type PropTypes = {
  guidelines: string,
};

export default class QueryGuideLines extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <div styleName="guidelines-wrapper">
        {this.props.guidelines.map((val, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index} styleName="items-wrapper">
            <div styleName="item-num">{index + 1}</div>
            <div styleName="item-text">{val}</div>
          </div>
        ))}
      </div>
    );
  }
}
