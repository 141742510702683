import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import Modal, {SIZES as MODAL_SIZES} from 'common/componentsV2/modal/Modal';
import AllAlertActions from './AllAlertActions';

const AllActionsModal = (props: PropTypes) => (
  <Fragment>
    <Modal
      onClose={props.onClose}
      isOpen={props.isModalOpen}
      size={MODAL_SIZES.LARGE}
      isCloseButtonHidden
      isStatic={false}
      height="485px"
    >
      <AllAlertActions onChange={props.onChange} onCloseModal={props.onClose} onNewAction={props.onNewAction} />
    </Modal>
  </Fragment>
);

AllActionsModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNewAction: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AllActionsModal;
