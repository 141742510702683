// @flow
import React from 'react';
import {fileDatePatterns} from 'bc/services/genericStorageService';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  onChange: Function,
  value: String,
};

export default class S3FileNamePattern extends React.PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);
    const options = Object.keys(fileDatePatterns).map((patternKey) => ({value: patternKey, label: patternKey}));
    this.state = {dropdownOptions: options};
  }

  onMenuItemClick = (item) => {
    this.props.onChange(item.value);
  };

  render() {
    const {value} = this.props;
    const optionCurrent = this.state.dropdownOptions.findIndex((option) => option.value === value);

    return (
      <SelectAndt
        id="fileNameDatePattern"
        automationId="fileNameDatePattern"
        options={this.state.dropdownOptions}
        type={TYPE_NO_SEARCH}
        theme={THEME_LIGHT}
        value={this.state.dropdownOptions[optionCurrent]}
        onChange={this.onMenuItemClick}
        placeholder="Filename Date Pattern"
      />
    );
  }
}
