import {combineEpics} from 'redux-observable';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/throttleTime';
import 'rxjs/add/observable/interval';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as api from 'userEvents/services/api';
import * as actions from 'userEvents/store/actions';

const fetchUserEventsCategories = makeAsyncEpic(actions.fetchUserEventsCategories, api.fetchUserEventsCategories);
const createUserEventsCategories = makeAsyncEpic(actions.createUserEventsCategories, api.createUserEventsCategories);
const deleteUserEventsCategory = makeAsyncEpic(actions.deleteUserEventsCategory, api.deleteUserEventsCategory);

const dataStreamsEpic = combineEpics(createUserEventsCategories, fetchUserEventsCategories, deleteUserEventsCategory);
export default dataStreamsEpic;
