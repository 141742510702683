import React from 'react';
import StreamsSummary from './StreamsSummary';
import StreamsBySource from './StreamsBySource';

type PropTypes = {
  reports: Object,
};

export default class StreamsView extends React.PureComponent {
  props: PropTypes;

  state = {
    activeSources: [],
  };

  handleToggleSourceActive = (item) => {
    if (this.state.activeSources.includes(item)) {
      this.setState((prevState) => ({
        activeSources: prevState.activeSources.filter((i) => i !== item),
      }));
    } else {
      this.setState((prevState) => ({
        activeSources: [...prevState.activeSources, item],
      }));
    }
  };

  render() {
    return (
      <div>
        <StreamsSummary activeSources={this.state.activeSources} onToggleSourceActive={this.handleToggleSourceActive} />
        <StreamsBySource activeSources={this.state.activeSources} reports={this.props.reports} />
      </div>
    );
  }
}
