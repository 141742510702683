// @flow

import React from 'react';

import './CreateNewEvent.module.scss';

type PropsType = {
  isLoading: boolean,
  submitName: String,
  onCreateNewItem: Function,
};

export default class CreateNewEvent extends React.PureComponent {
  props: PropsType;

  state = {
    name: '',
    img: '',
    disableBtn: true,
    imgFieldError: false,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isLoading !== this.props.isLoading) {
      this.checkValidation();
    }
  }

  handleInput = (e) => {
    this.isPristine = true;
    const input = e.target;

    if (input.name === 'img') {
      this.setState({img: input.value}, this.checkValidation);
    } else if (input.name === 'name') {
      this.setState({name: input.value}, this.checkValidation);
    }
  };

  checkValidation = () => {
    const {name, img} = this.state;
    const onlyWhiteSpace = /^\s*$/.test(name);

    this.setState({imgFieldError: false});

    if (name.length && !img.length && !onlyWhiteSpace) {
      this.setState({disableBtn: false});
      return;
    }
    if (name.length && img.length && !onlyWhiteSpace) {
      const re = new RegExp('^https://');

      this.setState({imgFieldError: true});

      if (re.test(img)) {
        this.setState({disableBtn: false});
        this.setState({imgFieldError: false});
        return;
      }
    }
    this.setState({disableBtn: true});
  };

  handleSubmit = (e) => {
    const {onCreateNewItem} = this.props;
    const {name, img} = this.state;

    this.setState({name: '', img: ''}, () => onCreateNewItem(name, img));
    e.preventDefault();
  };

  render() {
    const {name, img, disableBtn, imgFieldError} = this.state;
    const {isLoading, submitName} = this.props;

    return (
      <form styleName="form-container" onSubmit={this.handleSubmit}>
        <div styleName="input-fields">
          <div>
            <label>Name</label>
            <input name="name" type="text" onChange={this.handleInput} value={name} placeholder="set name" />
          </div>

          <div>
            <label>Image</label>
            <input
              name="img"
              type="text"
              onChange={this.handleInput}
              value={img}
              placeholder="https://example.com"
              styleName={imgFieldError ? 'error-field' : ''}
            />
            {imgFieldError && <span styleName="error-message">Please insert a valid url (https)</span>}
          </div>
        </div>

        <div styleName="submit-section">
          <button type="submit" className="btn btn-raised" disabled={disableBtn}>
            <span>{submitName}</span>
            {isLoading && <i className="icon ion-load-c spin" styleName="btn-loader" />}
          </button>
        </div>
      </form>
    );
  }
}
