import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';

export const totalTriggeredAlerts = composeReducers(
  makeAsyncReducer(actions.fetchTriggeredAlertsTotal, {defaultData: null}),
);

export const totalTriggeredAlertsLastDay = composeReducers(
  makeAsyncReducer(actions.fetchTriggeredAlertsTotalLastDay, {defaultData: null}),
);
