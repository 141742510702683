import React from 'react';
import S3Data from './s3Data/S3Data';
import S3DimensionsAndMetrics from './s3DimensionsAndMetrics/S3DimensionsAndMetrics';
import S3Schedular from './s3Schedular/S3Schedular';
import StreamEditorPanel from '../../StreamEditorPanel';

import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';
import './S3StreamEditor.module.scss';

const S3StreamEditor = () => (
  <div styleName="root" className="shell-col">
    <StreamEditorSteps>
      <S3Data />
      <S3DimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <S3Schedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default S3StreamEditor;
