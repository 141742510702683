// @flow
import React from 'react';
import './AddGroupComponent.module.scss';

type PropTypes = {
  onClick: Function,
};

export default class AddGroupComponent extends React.PureComponent {
  props: PropTypes;

  render() {
    const {onClick} = this.props;

    return (
      <div styleName="add-group-component" automation-id="addGroupButton" role="presentation" onClick={onClick}>
        <div styleName="center-row">
          <span>
            <i className="icn-action16-plusa" />
            Add Group
          </span>
        </div>
      </div>
    );
  }
}
