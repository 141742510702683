import {composeReducers} from 'common/utils/reducers';
import {alertConditionTypes} from 'alerts.management/services/alertsService';
import {omit} from 'lodash';
import * as actions from '../actions';

const EMPTY_INFO_OBJECT = {
  matchingProperties: [],
  validation: {
    passed: true,
  },
};

const influencingMetricsInfoReducer = composeReducers(
  // makeAsyncReducer(actions.fetchInfluencingMetricsInfo, {defaultData: EMPTY_INFO_OBJECT}),
  (state = {}, {type, payload, meta}) => {
    switch (type) {
      case actions.fetchInfluencingMetricsInfo.TYPE:
        return {...state, [meta]: {isLoading: true, data: EMPTY_INFO_OBJECT}};
      case actions.fetchInfluencingMetricsInfo.success.TYPE:
        return {...state, [meta]: {isLoading: false, data: payload}};
      case actions.fetchInfluencingMetricsInfo.failure.TYPE:
        return {...state, [meta]: {isLoading: false, error: payload, data: EMPTY_INFO_OBJECT}};

      case actions.setSelectedAlertType.TYPE:
        return {};

      case actions.addConditionToSelectedAlert.TYPE:
        if (payload.type === alertConditionTypes.influencingMetrics) {
          return {...state, [meta.id]: {data: EMPTY_INFO_OBJECT}};
        }
        return state;
      case actions.removeConditionFromSelectedAlert.TYPE:
        if (state[payload]) {
          return omit(state, payload);
        }
        return state;

      default:
        return state;
    }
  },
);

export default influencingMetricsInfoReducer;
