// @flow
import React, {Fragment, useMemo, useEffect} from 'react';
import {Box} from '@material-ui/core';
import Checkbox from 'common/componentsV2/Checkbox';
import {useField} from 'react-final-form';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import {optionsTime} from 'common/componentsV2/customDateRange/CustomDateRangeModal';

const TimeSettings = () => {
  const {
    input: {onChange, checked},
  } = useField('isTimeApply', {type: 'checkbox'});
  const {
    input: {value: startTimeValue, onChange: onChangeStartTimeValue},
  } = useField('startTime');
  const {
    input: {value: endTimeValue, onChange: onChangeEndTimeValue},
  } = useField('endTime');
  const {
    input: {
      value: {startDate, endDate},
    },
  } = useField('dateRange');

  const isSameDate = new Date(startDate).setHours(0, 0, 0, 0) === new Date(endDate).setHours(0, 0, 0, 0);
  const computedOptionsTime = useMemo(
    () =>
      optionsTime.map((option) => {
        if (isSameDate && option.value.hour < startTimeValue.value.hour) {
          return {...option, disabled: true};
        }
        return option;
      }),
    [startTimeValue.value],
  );

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    isSameDate && endTimeValue.value.hour < startTimeValue.value.hour && onChangeEndTimeValue(startTimeValue);
  }, [startTimeValue.value, endTimeValue.value, isSameDate]);

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center" width={1} mt={1}>
      <Box lineHeight="20px">
        <Checkbox isChecked={checked} onChange={onChange} text="Set time" />
      </Box>
      {checked && (
        <Fragment>
          <Box ml={1}>
            <SelectAndt
              automationId="addTimeCustomDateRange"
              onChange={onChangeStartTimeValue}
              value={startTimeValue}
              options={optionsTime}
              buttonWidth={120}
              optionHeight={40}
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_BLUE_LEAN}
            />
          </Box>
          <Box ml={21}>
            <SelectAndt
              automationId="addTimeCustomDateRange"
              onChange={onChangeEndTimeValue}
              value={endTimeValue}
              options={computedOptionsTime}
              buttonWidth={120}
              optionHeight={40}
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_BLUE_LEAN}
            />
          </Box>
        </Fragment>
      )}
    </Box>
  );
};

export default TimeSettings;
