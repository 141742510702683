// @flow
import React, {PureComponent} from 'react';
import AccordionSection from './AccordionSection';

type PropTypes = {
  allowMultipleOpen?: Boolean,
  children: Object,
};

export default class Accordion extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    allowMultipleOpen: false,
  };

  state = {
    openSections: {},
  };

  componentDidMount() {
    const openSections = {};
    this.props.children.forEach((child) => {
      if (child.props.isOpen) {
        openSections[child.props.label] = true;
      }
    });
    this.setState({openSections});
  }

  onClick = (label, onClick) => {
    const {
      props: {allowMultipleOpen},
      state: {openSections},
    } = this;
    const isOpen = !!openSections[label];

    if (allowMultipleOpen) {
      this.setState({
        openSections: {
          ...openSections,
          [label]: !isOpen,
        },
      });
    } else {
      this.setState({
        openSections: {
          [label]: !isOpen,
        },
      });
    }
    if (onClick) {
      onClick();
    }
  };

  render() {
    const {
      onClick,
      props: {children},
      state: {openSections},
    } = this;

    return (
      <div>
        {children.map((child) => (
          <AccordionSection
            key={child.props.titles}
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}
            titles={child.props.titles}
            onClick={() => onClick(child.props.label, child.props.onClick)}
            isLoading={child.props.isLoading}
          >
            {child.props.children}
          </AccordionSection>
        ))}
      </div>
    );
  }
}
