// @flow
import React from 'react';
import {pollingIntervals} from 'bc/services/dataStreamService';
import {intersection} from 'lodash';
import SelectAndt, {THEME_BLUE, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  pollingInterval: number,
  allowedValues: Array,
  onChange: Function,
  theme?: String,
};

const pollingIntervalsValues = Object.values(pollingIntervals);

class PollingInterval extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    theme: THEME_BLUE,
  };

  filteredPolValues = pollingIntervalsValues.filter((interval) => {
    const allowedValues = this.props.allowedValues ? this.props.allowedValues : Object.keys(pollingIntervals);
    return intersection(allowedValues, [interval.key]).length > 0;
  });

  render() {
    const {pollingInterval, onChange, theme} = this.props;
    const selectedIndex = this.filteredPolValues.findIndex((val) => val.key === pollingInterval);

    return (
      <div role="presentation" style={{maxWidth: '300px'}} onClick={(evt) => evt.preventDefault()}>
        <SelectAndt
          id="s3Polling"
          options={this.filteredPolValues}
          getOptionLabel={(val) => val.labelShort}
          getOptionValue={(val) => val.key}
          type={TYPE_NO_SEARCH}
          theme={theme}
          value={this.filteredPolValues[selectedIndex]}
          onChange={(val) => onChange(val.key)}
          placeholder=""
          automationId="pollingInterval"
        />
      </div>
    );
  }
}

export default PollingInterval;
