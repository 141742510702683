// @flow
import React from 'react';
import Button, {COLORS} from 'common/componentsV2/Button';
import StreamEditorUserActions from 'bc/components/streams/editor/StreamEditorUserActions';
import './WizardStepMenuItem.module.scss';

const PrevButton = (props: {onClick: Function}) => (
  <Button
    colorSchema={COLORS.GHOST_BLUE}
    icon="icn-arrow16-arrowleft"
    automation-id="streamEditorBackButton"
    extraClassName="prev-button"
    text="Previous"
    onClick={props.onClick}
  />
);

const MidButton = (props: {isActive: boolean, label: string}) => (
  <Button colorSchema={props.isActive ? COLORS.TRANSPARENT_BLUE : COLORS.TRANSPARENT} text={props.label} />
);

const NextButton = () => <StreamEditorUserActions />;

const WizardStepMenuItem = (props: {
  isFirst: boolean,
  isLast: boolean,
  isActive: boolean,
  label: string,
  onClick: Function,
}) => (
  <div styleName="root" automation-id="wizardStepMenuItem">
    {props.isFirst && !props.isActive ? <PrevButton onClick={props.onClick} /> : ''}
    {props.isFirst && props.isActive ? <div styleName="prev-dummy" /> : ''}

    <MidButton onClick={props.onClick} label={props.label} isActive={props.isActive} />

    {props.isLast && !props.isActive ? <NextButton onClick={props.onClick} /> : ''}
    {props.isLast && props.isActive ? <div styleName="next-dummy" /> : ''}
  </div>
);

export default WizardStepMenuItem;
