// @flow
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Gradient from 'common/componentsV2/Gradient';
/* eslint-disable-next-line import/no-extraneous-dependencies */
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from 'react-virtualized';
import get from 'lodash';
import useCrosshairSync from 'common/utils/useCrosshairSync';
import {TinyScrollBox, TypographyBox} from 'common/componentsV2/boxTools';
import MetricSimulation from './MetricSimulation';
import ListBottom from './ListBottom';
import LoadingPlaceholder from '../components/LoadingPlaceholder';

type PropTypes = {
  isSimulation: boolean,
  metrics: Array,
  autoSimulationState: Object,
  executeSimulation: Function,
  isLoading: boolean,
  isLoadingMore: boolean,
  isAnyLoading: boolean,
  totalAmount: number,
  isInvalid: boolean,
  timeScale: string,
  alert: Object,
  isZoomLoading: boolean,
};

const overscanIndicesGetter = ({
  // eslint-disable-next-line no-unused-vars
  direction,
  cellCount,
  // eslint-disable-next-line no-unused-vars
  scrollDirection,
  overscanCellsCount,
  startIndex,
  stopIndex,
}) => ({
  overscanStartIndex: Math.max(0, startIndex - overscanCellsCount),
  overscanStopIndex: Math.min(cellCount - 1, stopIndex + overscanCellsCount),
});

const metricsPlaceholder = Array(5)
  .fill('')
  .map((item, index) => ({isLoading: true, id: `loading-${index}`}));

const ChildrenRenderer = ({onMount, children}: {onMount: Function, children: React.Node}) => {
  useEffect(() => {
    if (onMount) {
      onMount();
    }
  }, []);
  return children;
};

const MetricsList = ({
  isSimulation,
  metrics,
  autoSimulationState,
  executeSimulation,
  isLoading,
  isLoadingMore,
  isAnyLoading,
  totalAmount,
  isInvalid,
  timeScale,
  isZoomLoading,
  alert,
}: PropTypes) => {
  let contentArr = metrics;
  if (isLoading) {
    if (isLoadingMore) {
      contentArr = metrics.concat(metricsPlaceholder);
    } else {
      contentArr = metricsPlaceholder;
    }
  }

  const cache = useMemo(
    () =>
      new CellMeasurerCache({
        defaultHeight: 300,
        minHeight: 115,
        fixedWidth: true,
      }),
    [],
  );

  const chartIds = useMemo(
    () =>
      metrics.map((metric, index) =>
        isSimulation ? `simulation-chart-${metric.id}-${index}` : `expression-builder-${metric.id}-${index}`,
      ),
    [metrics, isSimulation],
  );

  const mouseMoveHandler = useCrosshairSync(chartIds);

  const [overscanValue, setOverscanValue] = useState(0);
  const mouseOverHandler = useCallback(() => {
    if (overscanValue === 0) {
      setOverscanValue(5);
    }
  }, [overscanValue]);
  const mouseLeaveHandler = useCallback(() => {
    setOverscanValue(0);
  });
  const list = useRef();

  useEffect(() => {
    if (list.current && isLoading && !isLoadingMore) {
      list.current.scrollToPosition(0);
      if (overscanValue !== 0) {
        setOverscanValue(0);
      }
    }
  }, [isLoading, isLoadingMore]);

  const Row = useMemo(
    () => ({index, key, parent, style}: {index: number, key: string, parent: string, style: Object}) => {
      const metric = contentArr[index];
      if (index === contentArr.length) {
        return (
          <CellMeasurer cache={cache} columnIndex={0} key={`${key}_lastRow`} parent={parent} rowIndex={index}>
            {({measure, registerChild}) => (
              <ChildrenRenderer onMount={measure}>
                <div ref={registerChild} key={key} style={style}>
                  <div className="flexShrink_0">
                    {metrics.length > 0 && !isAnyLoading && (
                      <TypographyBox variant="h5" fontWeight="bold" mt={1}>
                        Showing top {metrics.length}
                      </TypographyBox>
                    )}
                  </div>
                  {metrics && totalAmount && !isAnyLoading ? (
                    <ListBottom
                      executeSimulation={executeSimulation}
                      isSimulation={isSimulation}
                      currentLength={metrics.length}
                      isInvalid={isInvalid}
                      totalAmount={totalAmount}
                      limit={50}
                    />
                  ) : null}
                </div>
              </ChildrenRenderer>
            )}
          </CellMeasurer>
        );
      }

      return (
        <CellMeasurer cache={cache} columnIndex={0} key={`${metric.id}_${index}`} parent={parent} rowIndex={index}>
          {({measure, registerChild}) => (
            <div ref={registerChild} style={style}>
              {metric.isLoading ? (
                <div className="flexShrink_0 flexGrow_1 mt_3" style={{height: '250px'}}>
                  <ChildrenRenderer onMount={measure}>
                    <LoadingPlaceholder />
                  </ChildrenRenderer>
                </div>
              ) : (
                <div className="flexShrink_0 flexGrow_1">
                  <MetricSimulation
                    isSimulation={isSimulation}
                    isZoomLoading={isZoomLoading}
                    index={index}
                    metric={metric}
                    anomalyId={get(alert, 'groupId')}
                    alertId={get(alert, 'id')}
                    timeScale={timeScale}
                    onEndLoad={measure}
                    mouseMoveHandler={mouseMoveHandler}
                  />
                </div>
              )}
            </div>
          )}
        </CellMeasurer>
      );
    },
    [
      contentArr,
      alert,
      timeScale,
      isSimulation,
      metrics,
      totalAmount,
      isAnyLoading,
      executeSimulation,
      autoSimulationState,
      isInvalid,
      isZoomLoading,
    ],
  );

  return (
    <div className="display_flex flexDirection_column position_relative height_1 mt_0-5">
      <Gradient />
      <div
        className="width_1 display_flex flexDirection_column flexGrow_1 flexShrink_0 pr_1"
        onMouseOver={mouseOverHandler}
        onMouseLeave={mouseLeaveHandler}
        key={isSimulation}
      >
        <AutoSizer>
          {({height, width}) => (
            <TinyScrollBox>
              <List
                ref={list}
                overscanRowCount={overscanValue}
                overscanIndicesGetter={overscanIndicesGetter}
                width={width}
                height={height}
                rowCount={contentArr.length + 1}
                rowHeight={cache.rowHeight}
                rowRenderer={Row}
              />
            </TinyScrollBox>
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

export default React.memo(MetricsList);
