// @flow
import React from 'react';
import SelectAndt, {THEME_BLUE, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  maxGoldenDelayMinutes: number,
  minValueAllowed: number,
  onChange: Function,
  theme?: String,
};

const hourDelayValues = (() => {
  const arr = [{key: 0, label: 'No delay'}, {key: 15, label: '15 Minutes'}, {key: 30, label: '30 Minutes'}];
  let scale = 0;

  while (scale < 48) {
    scale += 1;
    arr.push({
      key: scale * 60,
      label: `${scale} Hours`,
    });
  }
  return arr;
})();

export default class GaMaxDelay extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    theme: THEME_BLUE,
  };

  state = {
    dropdownOptions: [],
  };

  componentDidMount() {
    this.initFilteredValues(this.props.minValueAllowed);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.minValueAllowed !== this.props.minValueAllowed) {
      this.initFilteredValues(this.props.minValueAllowed);
    }
  }

  initFilteredValues = (minValueAllowed) => {
    const options = hourDelayValues.filter((item) => item.key >= minValueAllowed);
    options.unshift({
      key: null,
      label: 'Wait for Golden data',
    });
    this.setState({dropdownOptions: options});
  };

  render() {
    const {maxGoldenDelayMinutes, onChange} = this.props;
    const optionCurrent = this.state.dropdownOptions.findIndex((val) => val.key === maxGoldenDelayMinutes);

    return (
      <div role="presentation" onClick={(evt) => evt.preventDefault()}>
        <SelectAndt
          id="gaGolden"
          className="andt-dropdown"
          options={this.state.dropdownOptions}
          type={TYPE_NO_SEARCH}
          theme={this.props.theme}
          value={this.state.dropdownOptions[optionCurrent]}
          onChange={(item) => onChange(item.key)}
          getOptionValue={(val) => val.key}
          placeholder=""
          automationId="gaMaxDelay"
        />
      </div>
    );
  }
}
