// @flow
import React from 'react';
import './AlertInfoSideNavigation.module.scss';
import SideNavigationButton from './SideNavigationButton';

type PropTypes = {
  onChange: Function,
  values: Array,
  initialButton: number,
};

export default class AlertInfoSideNavigation extends React.PureComponent {
  props: PropTypes;

  constructor(props) {
    super(props);

    this.state = {
      selectedButton: this.props.initialButton,
    };
  }

  handleClick = (id) => {
    this.setState({selectedButton: id});
    this.props.onChange(id);
  };

  render() {
    const {values} = this.props;
    return (
      <div styleName="container">
        {values.map((value, i) => (
          <SideNavigationButton
            key={`button-${value.icon}`}
            onClick={() => this.handleClick(i)}
            isSelected={this.state.selectedButton === i}
            icon={value.icon}
            tooltipText={value.tooltipText}
          />
        ))}
      </div>
    );
  }
}
