// @flow
import React from 'react';
import './BigDropdownButton.module.scss';

type PropTypes = {
  placeholder: string,
  isPristine: boolean,
  label: string,
  shortBlueStyle: boolean,
  blueLean: boolean,
  isFullWidth: boolean,
};

const BigDropdownButton = (props: PropTypes) => (
  <div
    styleName={[
      'container',
      props.shortBlueStyle ? 'blue-style' : '',
      props.isPristine ? 'pristine' : '',
      props.blueLean ? 'blue-lean' : '',
      props.isFullWidth ? 'full' : '',
    ].join(' ')}
  >
    <div styleName={['label', props.isPristine ? 'pristine' : ''].join(' ')}>
      {props.isPristine ? props.placeholder : props.label}
    </div>
    <div styleName="ico" className="icon icn-arrow16-triangledown" />
  </div>
);

export default BigDropdownButton;
