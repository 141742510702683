// @flow
import React from 'react';
import './simpleCollectionTitles.module.scss';

type PropTypes = {
  onSorting: Function,
  titles: Array<string>,
};

export default class TitlesSorting extends React.PureComponent {
  props: PropTypes;

  state = {
    method: {},
  };

  onSorting = (title) => {
    const {method} = this.state;
    const {onSorting} = this.props;
    const newState = {method: {[title]: !method[title]}};

    this.setState(newState);
    onSorting(newState.method);
  };

  activeSort = (activeTitle) => {
    if (activeTitle) {
      return <i className="icon icn-icon-blackarrow-down" />;
    }
    if (activeTitle === false) {
      return <i className="icon icn-icon-blackarrow-down" styleName="arrow-up" />;
    }
    return null;
  };

  render() {
    const {method} = this.state;
    const {titles} = this.props;

    return (
      <div styleName="container">
        {titles.map((item) => (
          <div key={item.name}>
            <p className="ellipsis" onClick={() => this.onSorting(item.sortBy)}>
              {item.name}
            </p>

            <span onClick={() => this.onSorting(item.sortBy)}>{this.activeSort(method[item.sortBy])}</span>
          </div>
        ))}
      </div>
    );
  }
}
