import React from 'react';
import {useHistory} from 'react-router-dom';

import {Box} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {TilesType} from '../DropdownTiles';
import {OPTION_TILES_VALUES} from '../../services/dashboardService';

const StartByAddingATile = ({matchUrl}: {matchUrl: string}) => {
  const history = useHistory();
  return (
    <div className="display_flex flexDirection_column flexGrow_1">
      <div className="display_flex alignItems_center flexDirection_column">
        <TypographyBox variant="hintText" fontSize={18} mb={2}>
          Start by adding a tile
        </TypographyBox>
        <Box display="flex" width={300} flexWrap="wrap">
          <TilesType
            onSelect={(value) => {
              history.push(`${matchUrl}/${value}/${OPTION_TILES_VALUES.NEW}${history.location.search}`);
            }}
          />
        </Box>
      </div>
    </div>
  );
};

export default StartByAddingATile;
