import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const dataReducer = composeReducers(
  {
    fetchTrainTracker: makeAsyncReducer(actions.fetchTrainTrackerData, {
      defaultData: EMPTY_ARRAY,
      includeUpdateAt: true,
      shouldDestroyData: false,
    }),
  },
  (state = {}, {type}) => {
    switch (type) {
      default:
        return state;
    }
  },
);

export default dataReducer;
