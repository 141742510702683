// @flow
import React from 'react';
import './TokenParameterInput.module.scss';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import OptionComponentCheckbox from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentCheckbox';
import SmallDropdownButtonMulti from 'common/componentsV2/ddl/multiSelectFormDdl/SmallDropdownButtonMulti';

type PropTypes = {
  functionParameter: Object,
  functionProperties: Object,
  onValueChange: Function, // f = (value, isValid, [validationErrors]) => {...}
  value: Object,
  onTouched: Function,
  isFunctionPropertiesLoading: boolean,
};

export default class TokenParameterInput extends React.PureComponent {
  props: PropTypes;

  state = {
    isError: false,
    // errorMessage: '',
    isPristine: true,
    toggleOpen: undefined,
    isFirstOpen: true,
  };

  componentDidMount() {
    if ((this.props.functionParameter || {}).name === 'Group By' && typeof this.props.value === 'undefined') {
      this.setState((prev) => ({toggleOpen: !prev.toggleOpen}));
    }
  }

  getParsedValue = () => {
    let parsedValue = [];
    if (this.props.value) {
      if (!(this.props.value === '{properties:[]}' || this.props.value === '{properties:[""]}')) {
        parsedValue = JSON.parse(this.props.value).properties;
      }
    }
    return parsedValue;
  };

  onChange = (val) => {
    this.props.onTouched();
    this.setState({isPristine: false});
    this.props.onValueChange(JSON.stringify({properties: val}), true, []);
    const parsedValue = this.getParsedValue();
    // this.setState({isError: parsedValue === '' || parsedValue.length === 0});
    this.setState((prevState) => ({isError: (parsedValue === '' || !parsedValue.length) && !prevState.isPristine}));
    // if (parsedValue.length === 0 && !this.state.isPristine) {
    //   this.setState({errorMessage: 'Field cannot be empty'});
    // }
  };

  onToggle = (val) => {
    if (!val && !this.state.isFirstOpen) {
      const parsedValue = this.getParsedValue();
      this.onChange(parsedValue);
    } else {
      this.setState({isFirstOpen: false});
    }
  };

  getModifier = () => {
    if (this.state.isPristine) {
      return 'pristine';
    }
    if (this.state.isError) {
      return 'error';
    }
    return '';
  };

  render() {
    const parsedValue = this.getParsedValue();
    return (
      <div>
        <FormDdlSelect
          options={this.props.functionProperties ? this.props.functionProperties.properties : []}
          selected={parsedValue}
          button={<SmallDropdownButtonMulti value={parsedValue} modifier={this.getModifier()} />}
          onChange={this.onChange}
          width={250}
          isMulti
          optionComponent={<OptionComponentCheckbox />}
          toggleOpen={this.state.toggleOpen}
          isLoading={this.props.isFunctionPropertiesLoading}
          onToggle={this.onToggle}
          specialCharacterLastSorting="#"
        />
      </div>
    );
  }
}
/* TODO GABPAC -- tooltip on the param

customComponent={{
            Control: (p) => (
              <Tooltip content={this.state.errorMessage} type={TYPES.SMALL}>
                <div>
                  <components.Control className="andt-dropdown-control" {...p}>
                    {p.children}
                  </components.Control>
                </div>
              </Tooltip>
            ),
          }}
 */
