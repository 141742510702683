// @flow
import React, {useCallback, useEffect, useRef} from 'react';
import SelectAndt, {THEME_NOT_HIGHLIGHTED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import Checkbox from 'common/componentsV2/Checkbox';
import Info from 'common/componentsV2/Info';

import './SchedulerEditorModal.module.scss';

type PropTypes = {
  notificationDelayOption: Object,
  pollingInterval: String,
  notificationDelay: String,
  onChange: Function,
};

const getScale = (val) => {
  if (val) {
    return val.slice(-1);
  }
  return 'h';
};
const getValue = (val) => {
  if (val) {
    return val.slice(0, -1);
  }
  return 1;
};

const getMin = (notificationDelayOption, pollingInterval, notificationDelay) => {
  if (pollingInterval === 'daily') {
    return notificationDelayOption.dailyMin;
  }
  if ((pollingInterval === 'hourly' || pollingInterval.indexOf('m') === 0) && getScale(notificationDelay) === 'm') {
    return notificationDelayOption.minutesMin;
  }
  return notificationDelayOption.midMin;
};

const getMax = (notificationDelayOption, pollingInterval, notificationDelay) => {
  if ((pollingInterval === 'hourly' || pollingInterval.indexOf('m') === 0) && getScale(notificationDelay) === 'm') {
    return notificationDelayOption.minutesMax;
  }
  return notificationDelayOption.hoursMax;
};

const getOptions = (pollingInterval) => {
  if (pollingInterval === 'hourly' || pollingInterval.indexOf('m') === 0) {
    return [{text: 'Minutes', value: 'm'}, {text: 'Hours', value: 'h'}];
  }
  return [{text: 'Hours', value: 'h'}];
};

const NotificationDelay = ({notificationDelayOption, pollingInterval, notificationDelay, onChange}: PropTypes) => {
  const isFirstRun = useRef(true);

  const setNotificationDelayValue = useCallback(
    (scale) => {
      let newScale = scale;
      let notificationDelayValue = notificationDelayOption.midDefault;
      if (pollingInterval === 'hourly' || pollingInterval.indexOf('m') === 0) {
        if (scale === 'h') {
          notificationDelayValue = notificationDelayOption.hourDefault;
        } else {
          notificationDelayValue = notificationDelayOption.minutesMin;
        }
      } else {
        newScale = 'h';
        if (pollingInterval === 'daily') {
          notificationDelayValue = notificationDelayOption.dailyDefault;
        }
      }
      onChange(notificationDelayValue + newScale);
    },
    [pollingInterval, notificationDelayOption, onChange],
  );

  const saveValue = useCallback(
    (e, scale) => {
      onChange(e.target.value + scale);
    },
    [onChange],
  );

  const toggleRadio = useCallback(
    (checked) => {
      if (checked) {
        let newVal = notificationDelayOption.midDefault;
        if (pollingInterval === 'hourly' || pollingInterval.indexOf('m') === 0) {
          newVal = notificationDelayOption.hourDefault;
        } else if (pollingInterval === 'daily') {
          newVal = notificationDelayOption.dailyDefault;
        }
        onChange(`${newVal}h`);
      } else {
        onChange(null);
      }
    },
    [pollingInterval, onChange],
  );

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    if (notificationDelay && notificationDelay !== null) {
      setNotificationDelayValue(getScale(notificationDelay));
    }
  }, [pollingInterval]);

  const scaleOptions = getOptions(pollingInterval);
  const selectedIndex =
    scaleOptions.length > 1 ? scaleOptions.findIndex((val) => val.value === getScale(notificationDelay)) : 0;

  return (
    <div>
      <div styleName="titleWrapper">
        <div>
          <Checkbox
            text="Send notification when data is missing for"
            onChange={toggleRadio}
            isChecked={notificationDelay && notificationDelay !== null}
            isDisabled={false}
          />
          <div styleName="info">
            <Info
              text={
                <span>
                  Specify the maximal period you wish to wait until you receive a notification when the stream fails to
                  stream new data.
                </span>
              }
            />
          </div>
        </div>
      </div>

      {notificationDelay && notificationDelay !== null ? (
        <div styleName="slideWrapper">
          <div styleName="slideWrapper-text">
            <input
              type="number"
              min={getMin(notificationDelayOption, pollingInterval, notificationDelay)}
              max={getMax(notificationDelayOption, pollingInterval, notificationDelay)}
              onChange={(e) => saveValue(e, scaleOptions[selectedIndex].value)}
              styleName="textInput_2"
              automation-id="NotificationDelayNumber"
              value={getValue(notificationDelay)}
            />
          </div>
          <div styleName="slideWrapper-slider">
            <SelectAndt
              id="schedulerEditorModalHistoricalDateRange"
              automationId="NotificationDelayType"
              options={scaleOptions}
              getOptionLabel={(val) => val.text}
              getOptionValue={(val) => val.value}
              type={TYPE_NEW_NO_SEARCH}
              theme={THEME_NOT_HIGHLIGHTED}
              value={scaleOptions[selectedIndex]}
              onChange={(val) => setNotificationDelayValue(val.value)}
              placeholder="Date range"
              optionHeight={40}
              buttonWidth={180}
              isClearable={false}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NotificationDelay;
