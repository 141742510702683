// @flow
import React from 'react';
import './Title.module.scss';

type PropTypes = {
  type: string,
  children: any,
};

export const TYPES = {
  PAGE_TITLE: 'page-title',
  PAGE_LINE_COUNTER: 'page-line-counter',
};

export default class Title extends React.PureComponent {
  props: PropTypes;

  render() {
    const {type} = this.props;
    const titleStyle = ['andt-title', type || ''].join(' ');
    // TODO CR: add comment about the reason of using H's
    const ret = null;
    switch (type) {
      case TYPES.PAGE_TITLE:
        return <h1 styleName={titleStyle}>{this.props.children}</h1>;
      case TYPES.PAGE_LINE_COUNTER:
        return <h2 styleName={titleStyle}>{this.props.children}</h2>;
      default:
    }
    return ret;
  }
}
