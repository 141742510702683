// @flow
import React from 'react';
import {noop} from 'lodash';
import {GROUP_COLOR_SCHEMAS} from 'anodot-objects-models';
import AvatarBadge from 'common/componentsV2/AvatarBadge';
import SelectAndt, {
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
  DIRECTION_LEFT,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './GroupsItem.module.scss';

type PropTypes = {
  groupId: string,
  title: string,
  colorSchema?: string,
  className?: string,
  users?: Array,
  isHighlighted?: boolean,
  onClick?: Function,
  onMenuItemClick?: Function,
};

export const COLORS = GROUP_COLOR_SCHEMAS;

const EMPTY_ARRAY = [];
const groupMenuOptions = [
  {value: 'edit', label: 'Edit'},
  {value: 'duplicate', label: 'Duplicate'},
  {value: 'delete', label: 'Delete'},
];

export default class GroupsItem extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    colorSchema: COLORS.GRAY,
    className: '',
    users: EMPTY_ARRAY,
    isHighlighted: true,
    onClick: noop,
    onMenuItemClick: noop,
  };

  state = {
    menuIsOpen: false,
  };

  rootStyle = [];

  optionsMenuStyle = [];

  stopPropagation = (e) => e.stopPropagation();

  menuOptionClicked = (menuItem) => {
    this.props.onMenuItemClick({action: menuItem.value, groupId: this.props.groupId});
  };

  onMenuOpen = () => {
    this.setState({menuIsOpen: true});
  };

  onMenuClose = () => {
    this.setState({menuIsOpen: false});
  };

  itemClicked = () => {
    this.props.onClick(this.props.groupId);
  };

  renderUserAvatarList = () => {
    const {users, colorSchema} = this.props;

    if (users.length === 0) {
      return (
        <div styleName="avatar-wrapper empty-group">
          <span>Empty Group</span>
        </div>
      );
    }

    let paddingLeft = 0;
    if (users.length > 4) {
      paddingLeft = 32;
    } else if (users.length > 1 && users.length < 5) {
      paddingLeft = (users.length - 1) * 8;
    }

    return (
      <div styleName="avatar-wrapper" style={{paddingLeft: `${paddingLeft}px`}}>
        {users.map((user, index) => {
          if (index > 4) {
            return '';
          }

          if (users.length > 5 && index === 4) {
            const extraNumString = `+${users.length - 4}`;
            return (
              <div styleName="avatar-shift" key="group-item-5">
                <AvatarBadge text={extraNumString} colorSchema={colorSchema} isShadow isReverse />
              </div>
            );
          }

          return (
            <div styleName="avatar-shift" key={`avatar-${user.id}`}>
              <AvatarBadge text={user.abbr} tooltipText={user.name} colorSchema={colorSchema} isShadow />
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const {colorSchema, className, title, isHighlighted} = this.props;
    const {menuIsOpen} = this.state;

    this.rootStyle = ['root', colorSchema, isHighlighted ? 'highlighted' : ''];
    this.optionsMenuStyle = ['options-menu-wrapper', menuIsOpen ? 'show-menu-wrapper' : ''];

    return (
      <div
        className={className}
        styleName={this.rootStyle.join(' ')}
        automation-id={`groupItem-${title}`}
        onClick={this.itemClicked}
      >
        <div styleName="title">
          <div styleName="title-width" className="ellipsis">
            {title}
          </div>
          <div styleName={this.optionsMenuStyle.join(' ')} role="presentation" onClick={this.stopPropagation}>
            <SelectAndt
              automationId="groupItemMenu"
              type={TYPE_SIMPLE}
              theme={THEME_TRANSPARENT}
              options={groupMenuOptions}
              onChange={this.menuOptionClicked}
              direction={DIRECTION_LEFT}
              menuWidth={150}
              onMenuOpen={this.onMenuOpen}
              onMenuClose={this.onMenuClose}
              menuIsOpen={menuIsOpen}
            />
          </div>
        </div>
        {this.renderUserAvatarList()}
      </div>
    );
  }
}
