import {put, post, get} from 'common/utils/http';

const root = 'api/v1/users/';

export const fetchMe = () => get(`api/v1/me`);
export const signOut = () => post('api/v1/signout');
export const updateUserSettings = (payload) => put(root + payload._id, payload);
export const updateMeLastActive = () => post('api/v1/me/last_active');
export const updateUserNameOnly = ({payload}) => put(`${root}${payload.id}/rename`, payload);
export const updateMe = ({payload}) => put(`${root}${payload.id}`, payload);
