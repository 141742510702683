// @flow
import React from 'react';
import './SearchableDdl.module.scss';
import SelectAndt, {THEME_BLUE, TYPE_SEARCH} from '../componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  onChange: Function,
  items: Array,
  disabled: boolean,
  extraClassName: String,
  theme?: String,
  labelKey?: String,
  title?: String,
  className?: String,
  value: Object,
  dropDownTheme: String,
  automationId: String,
};

class SearchableDdl extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    theme: null,
    labelKey: null,
    title: null,
    className: null,
  };

  constructor(props) {
    super(props);
    if (this.props.labelKey) {
      this.labelKey = this.props.labelKey;
    }
  }

  searchVal = '';

  labelKey = 'label';

  onChange = (newValue) => {
    if (!Array.isArray(newValue)) {
      this.props.onChange(newValue);
    }
  };

  onClose = () => {
    this.searchVal = '';
  };

  render() {
    const themeClass = this.props.theme ? `${this.props.theme}-style` : '';
    const selectedIndex = this.props.items.findIndex((val) =>
      this.props.value ? val[this.labelKey] === this.props.value[this.labelKey] : null,
    );

    return (
      <div
        className={this.props.className ? this.props.className : ''}
        styleName="root"
        onClick={(event) => event.preventDefault()}
      >
        <SelectAndt
          automationId={this.props.automationId}
          className={this.props.extraClassName ? themeClass + this.props.extraClassName : themeClass}
          type={TYPE_SEARCH}
          theme={this.props.dropDownTheme ? this.props.dropDownTheme : THEME_BLUE}
          onChange={this.onChange}
          options={this.props.items}
          value={this.props.items[selectedIndex]}
          placeholder={this.props.title ? this.props.title : 'Choose Time Zone'}
          optionHeight={40}
          getOptionLabel={(val) => val[this.labelKey]}
          getOptionValue={(val) => val[this.labelKey]}
          isLoading={!this.props.items}
          disabled={!this.props.items || this.props.disabled}
        />
      </div>
    );
  }
}

export default SearchableDdl;
