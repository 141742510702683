// @flow
import React from 'react';
import PropTypes from 'prop-types';
import SmartTooltip from 'common/componentsV2/Tooltip';
import {getFormattedDateFull, getFormattedDateMini} from 'common/utils/dateService';

const IssueDate = ({startDate, timeZone}: PropTypes) => {
  const formattedDate = getFormattedDateFull(startDate, timeZone);
  const formattedMini = getFormattedDateMini(startDate, timeZone);

  return (
    <SmartTooltip placement="top" content={formattedDate} delay={400}>
      <span className="ellipsis">{formattedMini}</span>
    </SmartTooltip>
  );
};

IssueDate.propTypes = {
  startDate: PropTypes.number.isRequired,
  timeZone: PropTypes.string.isRequired,
};

export default IssueDate;
