import {makeAction, makeAsyncAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/topology/');

const macAsync = (actionName) => makeAsyncAction(actionName, 'anodot/topology/');

export const setIssuesList = mac('SET_ISSUES_LIST');
export const setMapBoundsIssueClicked = mac('SET_MAP_BOUNDS_ISSUE_CLICKED');
export const metricItemClicked = mac('METRIC_ITEM_CLICKED');
export const setIntoLeftPanelView = mac('SET_INTO_LEFT_PANEL_VIEW_KEY_VAL');
export const setMapBoundsSearchAssetClicked = mac('SET_MAP_BOUNDS_SEARCH_ASSET_CLICKED');

export const fetchTopologyMap = macAsync('FETCH_TOPOLOGY_MAP');
export const fetchTopologyAnomalies = macAsync('FETCH_TOPOLOGY_ANOMALIES');
export const getTopologyAnomalies = mac('GET_TOPOLOGY_ANOMALIES');
export const fetchTopologyAlerts = macAsync('FETCH_TOPOLOGY_ALERTS');
export const getTopologyAlerts = mac('GET_TOPOLOGY_ALERTS');

export const fetchMetricDataPoints = macAsync('FETCH_METRIC_DATA_POINTS');

export const getTopologySpecialIssue = mac('GET_TOPOLOGY_SPECIAL_ISSUE');
export const fetchTopologyAnomaly = macAsync('FETCH_TOPOLOGY_ANOMALY');
export const fetchTopologyAlert = macAsync('FETCH_TOPOLOGY_ALERT');
export const clearSpecialIssue = mac('CLEAR_SPECIAL_ISSUE');
