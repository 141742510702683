import {Observable} from 'rxjs/Observable';

let newFocusOptionSearchableEmitter;
const newFocusOptionSearchable = Observable.create((e) => {
  newFocusOptionSearchableEmitter = e;
  return e;
});

export const getFocusOptionSearchable = () => newFocusOptionSearchable;
export const getFocusOptionSearchableEmitter = () => newFocusOptionSearchableEmitter;
