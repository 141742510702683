import {combineEpics} from 'redux-observable';
import {push} from 'connected-react-router';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';

import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {getUniqueId} from 'common/utils/guid';
import {cloneDeep, get, isEmpty} from 'lodash';
import * as selectors from '../selectors';
import * as api from '../../services/api';
import * as actions from '../actions';

const fetchCubePreview = makeAsyncEpic(actions.fetchCubePreview, api.fetchCubePreview);
const fetchSchemas = makeAsyncEpic(actions.fetchSchemas, api.fetchSchemas);
const createCube = makeAsyncEpic(actions.createCube, api.createCube);
const updateCubes = makeAsyncEpic(actions.updateCubes, api.updateCubes);
const deleteAllCubes = makeAsyncEpic(actions.deleteAllCubes, api.deleteAllCubes);
const fetchCubesCount = makeAsyncEpic(actions.fetchCubesCount, api.fetchCubesCount);

const updateCubeDescription = (action$, {getState}) =>
  action$.ofType(actions.updateCubeDescription.TYPE).flatMap(({payload}) => {
    const streamSchemaId = selectors.getSelectedSchemaId(getState());
    const selectedSchemaTopLevelCubes = selectors.getSelectedSchemaTopLevelCubes(getState());
    const selectedTopLevelCube = selectors.getSelectedTopLevelCube(getState());
    const updatedTopLevels = cloneDeep(selectedSchemaTopLevelCubes);
    updatedTopLevels[selectedTopLevelCube.id].description = payload;
    return [
      actions.updateCubes({
        schemaId: streamSchemaId,
        topLevelCubes: updatedTopLevels,
      }),
    ];
  });

const editCube = (action$) =>
  action$
    .ofType(actions.editCube.TYPE)

    .flatMap(({payload}) => [
      actions.setSelectedCube(payload.cubeDetails.id),
      actions.setSelectedSchema(payload.streamSchemaId),
      actions.createEditableTopCube(payload.cubeDetails),
      push(`/bc/schemas/${payload.streamSchemaId}/cube/${payload.cubeDetails.id}`),
    ]);

const newCube = (action$) =>
  action$.ofType(actions.newCube.TYPE).flatMap(({payload}) => {
    const uniqueId = getUniqueId();
    return [
      actions.setSelectedCube(''),
      actions.setSelectedSchema(payload.streamSchemaId),
      actions.createEditableTopCube({
        id: uniqueId,
        name: 'new Cube',
        measure: null,
        dimensions: [],
        mandatoryDimensions: [],
      }),
      push(`/bc/schemas/${payload.streamSchemaId}/new-cube`),
    ];
  });
const createCubeSuccess = (action$) =>
  action$.ofType(actions.createCube.success.TYPE).flatMap(() => [push('/bc/schemas/all/cubes')]);

const duplicateCube = (action$) =>
  action$.ofType(actions.duplicateCube.TYPE).flatMap(({payload}) => {
    const uniqueId = getUniqueId();
    return [
      actions.createEditableTopCube({
        id: uniqueId,
        name: `${payload.cubeDetails.name} - copy`,
        measure: payload.cubeDetails.measure,
        dimensions: cloneDeep(payload.cubeDetails.dimensions),
        mandatoryDimensions: cloneDeep(payload.cubeDetails.mandatoryDimensions),
      }),
      push(`/bc/schemas/${payload.streamSchemaId}/new-cube`),
    ];
  });

const fetchEditableCubePreview = (action$, {getState}) =>
  action$.ofType(actions.setEditableTopCubeKeyVal.TYPE).flatMap((action) => {
    const selectedTopCube = selectors.getEditableTopCube(getState());
    const selectedSchemaId = selectors.getSelectedSchemaId(getState());

    const newTopLevelCubes = {
      [selectedTopCube.id]: selectedTopCube,
    };

    if (get(action.meta, 'isPreviewCube', false)) {
      return [
        actions.fetchCubePreview({
          schemaId: selectedSchemaId,
          topLevelCubes: newTopLevelCubes,
        }),
      ];
    }
    return [];
  });

const searchCube = (action$) =>
  action$
    .ofType(actions.searchCube.TYPE)
    .debounceTime(200)
    .distinctUntilChanged((x, y) => x.payload === y.payload)
    .map((x) => actions.setSearchStringCube(x.payload));

const deleteCube = (action$, {getState}) =>
  action$.ofType(actions.deleteCube.TYPE).flatMap((action) => {
    const getSchemas = selectors
      .getSchemas(getState())
      .data.filter(
        (d) => d.schemaCubesWrapper.cubes && d.schemaCubesWrapper.cubes.schemaId === action.payload.schemaId,
      )[0].schemaCubesWrapper;
    const tlc = {};
    Object.keys(getSchemas.cubes.topLevelCubes).forEach((key) => {
      if (key !== action.payload.topLevelCubes.id) {
        tlc[key] = getSchemas.cubes.topLevelCubes[key];
      }
    }); // TODO GABPAC - this was changed on the ESLint Cleanup - test results

    const payload = {
      schemaId: getSchemas.cubes.schemaId,
      topLevelCubes: tlc,
    };

    return isEmpty(tlc) ? [actions.deleteAllCubes(payload)] : [actions.updateCubes(payload)];
  });

const updateCubesSuccess = (action$) =>
  action$.ofType(actions.updateCubes.success.TYPE).flatMap(() => [push('/bc/schemas/all/cubes')]);

const deleteAllCubesSuccess = (action$) =>
  action$
    .ofType(actions.deleteAllCubes.success.TYPE)
    .flatMap(() => [actions.fetchSchemas(), push('/bc/schemas/all/cubes')]);

const cancelCubeEdition = (action$) =>
  action$
    .ofType(actions.cancelCubeEdition.TYPE)
    .flatMap(() => [actions.setSelectedSchema(''), actions.setSelectedCube(''), push('/bc/schemas/all/cubes')]);

const cubesEpic = combineEpics(
  fetchSchemas,
  updateCubesSuccess,
  fetchEditableCubePreview,
  fetchCubePreview,
  createCube,
  updateCubes,
  editCube,
  deleteCube,
  newCube,
  duplicateCube,
  deleteAllCubes,
  searchCube,
  updateCubeDescription,
  deleteAllCubesSuccess,
  createCubeSuccess,
  cancelCubeEdition,
  fetchCubesCount,
);
export default cubesEpic;
