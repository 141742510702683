// @flow
import React, {PureComponent, Fragment} from 'react';
import {Table, Column, Cell} from 'fixed-data-table-2';
import Loader from 'common/components/Loader';
import {get, omit, isEmpty} from 'lodash';
import ReactResizeDetector from 'react-resize-detector';
import './QueryEditorModal.module.scss';

const TextCell = (props: any) => (
  <Cell {...omit(props, 'previewData')}>{props.previewData.rows[props.rowIndex][props.columnKey]}</Cell>
);

type PropTypes = {
  previewData: Object,
  isPreviewDataLoading: boolean,
  previewDataError: Object,
};

export default class QueryPreviewTable extends PureComponent {
  props: PropTypes;

  state = {
    columnWidths: [],
    tableWidth: 790,
    tableHeight: 250,
  };

  componentDidMount() {
    this.timeoutHandler1 = setTimeout(() => {
      // wait for the modal animation
      document.getElementsByClassName('modal')[0].addEventListener('click', this.rootClicked, false);
    }, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.timeoutHandler1);
    clearTimeout(this.timeoutHandler2);
    document.getElementsByClassName('modal')[0].removeEventListener('click', this.rootClicked, false);
  }

  handleColumnResize = (newColWidth, colKey) => {
    this.setState((prevState) => {
      const locColWidths = prevState.columnWidths || [];
      locColWidths[colKey] = newColWidth;
      return {columnWidths: locColWidths};
    });
    this.forceUpdate();
  };

  handleResize = (width, height) => {
    this.setState({
      tableWidth: width || 790,
      tableHeight: height || 250,
    });
  };

  setInitialTableSize = (elm) => {
    if (!elm) {
      return;
    }
    this.timeoutHandler2 = setTimeout(() => {
      this.handleResize(elm.clientWidth, elm.clientHeight);
      this.forceUpdate();
    });
  };

  render() {
    const {isPreviewDataLoading, previewData, previewDataError} = this.props;
    const {tableWidth, tableHeight} = this.state;

    return (
      <Fragment>
        {isPreviewDataLoading && <Loader size="small" />}

        {isPreviewDataLoading === false && isEmpty(previewData) && isEmpty(previewDataError) && (
          <div styleName="info">Table not found</div>
        )}

        {!isEmpty(previewDataError) && (
          <div styleName="errors-container">
            <div styleName="error" automation-id="queryEditorErrorMessage">
              {previewDataError.uMessage}
            </div>
          </div>
        )}

        {typeof isPreviewDataLoading === 'undefined' && isEmpty(previewDataError) && isEmpty(previewData) && (
          <div styleName="info-wrap">
            <div styleName="info">Click Run to see results</div>
          </div>
        )}

        {!isPreviewDataLoading && !isEmpty(previewData) && (
          <div styleName="table" id="file-preview-table-container" ref={this.setInitialTableSize}>
            <ReactResizeDetector handleWidth handleHeight onResize={this.handleResize} />
            <Table
              rowHeight={20}
              rowsCount={get(previewData, 'rows.length', 0)}
              headerHeight={20}
              isColumnResizing={false}
              onColumnResizeEndCallback={this.handleColumnResize}
              width={tableWidth}
              height={tableHeight}
            >
              {get(previewData, 'header', []).map((r, i) => (
                <Column
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  columnKey={i}
                  header={<Cell>{r}</Cell>}
                  width={get(this.state, 'columnWidths', [])[i] || 100}
                  minWidth={20}
                  allowCellsRecycling
                  fixed={false}
                  isResizable
                  cell={<TextCell previewData={previewData} />}
                />
              ))}
            </Table>
          </div>
        )}
      </Fragment>
    );
  }
}
