import React from 'react';
import {Backdrop, Modal, Box} from '@material-ui/core';
import Button, {COLORS} from 'common/componentsV2/Button';

const NotificationModal = ({title, description, onClose}: {title: string, description: string, onClose: Function}) => (
  <Modal disableEnforceFocus open BackdropComponent={Backdrop} onClose={onClose}>
    <Box width={1} height={1} display="flex" justifyContent="center" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        width={400}
        bgcolor="white.500"
        borderRadius={6}
        css={{boxShadow: '0 2px 8px 0 rgba(0, 0, 0, 0.2)'}}
        padding={3}
      >
        <Box fontSize={24} mb={2} fontWeight={300}>
          {title}
        </Box>
        <div className="mb_1">{description}</div>
        <Box mt={2} alignSelf="center" display="flex">
          <div className="mr_1-5">
            <Button automationId="cancel" text="Got It" colorSchema={COLORS.GRAY_300} onClick={onClose} />
          </div>
        </Box>
      </Box>
    </Box>
  </Modal>
);

export default NotificationModal;
