import {combineReducers} from 'redux';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {bcErrorHandler} from 'bc/services/bcErrorCodes';
import {composeReducers} from '../../../common/utils/reducers';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

export default combineReducers({
  dataSets: composeReducers(
    makeAsyncReducer(actions.fetchBigQueryDataSets, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
  projects: composeReducers(
    makeAsyncReducer(actions.fetchBigQueryProjects, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
  preview: composeReducers(
    makeAsyncReducer(actions.fetchBigQueryPreview, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
  verifyQuery: composeReducers(
    makeAsyncReducer(actions.fetchBigQueryVerifyQuery, {defaultData: EMPTY_ARRAY, errorHandler: bcErrorHandler}),
    (state, {type}) => {
      switch (type) {
        case actions.setSelectedDataStream.TYPE:
          return {data: EMPTY_ARRAY};
        default:
          return state;
      }
    },
  ),
});
