// @flow
import React from 'react';
import './navigation-tabs.module.scss';

type PropsType = {
  tabsList: Array<Object>,
  onChange: Function,
};

export default class NavigationTabs extends React.PureComponent {
  props: PropsType;

  render() {
    const {tabsList, onChange} = this.props;
    const buttons = tabsList.map((tab) => (
      <button key={tab.value} type="button" onClick={() => onChange(tab)} styleName={tab.active ? 'active-tab' : ''}>
        {tab.label}
      </button>
    ));

    return <div styleName="navigation-bar">{buttons}</div>;
  }
}
