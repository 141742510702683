// @flow
import React, {Fragment} from 'react';
import {Modal} from 'react-bootstrap';

import './AlertsFromStreamModal.module.scss';
import Button, {COLORS} from '../../../../common/componentsV2/Button';

export default class AdblockStreamModal extends React.PureComponent {
  refreshPage = () => {
    window.location.reload();
  };

  render() {
    return (
      <Fragment>
        <Modal id="adblock-stream-modal" show dialogClassName="bc" bsSize="large">
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span>We detected an ad blocker</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div styleName="adblock-icon" className="image-adblock" />
            </div>
            <div styleName="row">
              <div styleName="col">
                <br />
                <br />
                In order to continue with creating this stream, you need to disable your ad blocker, since it may cause
                interference and lead to unexpected behavior.
                <br />
                <br />
                After you disable your ad blocker, please reload the page and try again.
                <br />
                <br />
                <br />
                <br />
              </div>
            </div>
            <div styleName="actions-footer-section center">
              <Button colorSchema={COLORS.BLUE_500} text="Reload & Try again" onClick={this.refreshPage} />
            </div>
          </Modal.Body>
        </Modal>
      </Fragment>
    );
  }
}
