import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';

const fetchFuCsvFormatAnalysis = makeAsyncEpic(actions.fetchFuCsvFormatAnalysis, api.fetchFuCsvFormatAnalysis);
const fetchFuPreview = makeAsyncEpic(actions.fetchFuPreview, api.fetchFuPreview);
const fetchFuFileProperties = makeAsyncEpic(actions.fetchFuFileProperties, api.fetchFuFileProperties);

const setFuStreamAnalysisSchema = (action$) =>
  action$
    .ofType(actions.fetchFuCsvFormatAnalysis.success.TYPE)
    .flatMap((action) => [actions.setFileStreamDiametricsAnalysisSchema(action.payload)]);

const setFuStreamFileFormat = (action$, {getState}) =>
  action$.ofType(actions.setFuStreamFileFormat.TYPE).flatMap((action) => [
    actions.fetchFuCsvFormatAnalysis({
      dataSourceId: selectors.getSelectedDataStream(getState()).dataSourceId,
      fileFormat: action.payload,
    }),
  ]);

const FileUploadEpic = combineEpics(
  fetchFuCsvFormatAnalysis,
  fetchFuPreview,
  fetchFuFileProperties,
  setFuStreamAnalysisSchema,
  setFuStreamFileFormat,
);
export default FileUploadEpic;
