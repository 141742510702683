import * as actions from 'bc.cubes/store/actions';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import {ASCENDANT, SORT_BY_TITLE} from '../../components/CubesSorting';

const EMPTY_ARRAY = [];

const cubesRoot = composeReducers(
  (
    state = {
      selectedSchemaId: '',
      selectedCubeId: '',
      isCreatingNewCube: false,
      expandedCube: '',
      searchCubeString: '',
      sort: SORT_BY_TITLE.value,
      order: ASCENDANT,
      editableTopCube: {},
    },
    {type, payload},
  ) => {
    const getSelectedSchemaIndex = (id = state.selectedItemId) =>
      state.schemas.data.findIndex((item) => item.id === id);

    const red = (item, pl) => ({...item, ...pl});

    const redWrapperSchemas = (_payload, index = getSelectedSchemaIndex()) => ({
      ...state,
      schemas: {
        ...state.schemas,
        data: reduceArrayItem(red, state.schemas.data, index, _payload || payload),
      },
    });

    switch (type) {
      case actions.setSelectedSchemaKeyVal.TYPE:
        return {...redWrapperSchemas(payload), isCubeNeedUpdating: true};

      case actions.setSelectedSchema.TYPE:
        return state.selectedSchemaId !== payload
          ? {
              ...state,
              selectedSchemaId: payload,
              selectedCubePreview: {},
            }
          : state;

      case actions.setSelectedCube.TYPE:
        return state.selectedCubeId !== payload
          ? {
              ...state,
              selectedCubeId: payload,
              selectedCubePreview: {},
            }
          : state;

      case actions.setExpandedCube.TYPE:
        return {
          ...state,
          expandedCube: payload === state.expandedCube ? '' : payload,
        };
      case actions.setSearchStringCube.TYPE:
        return {
          ...state,
          searchCubeString: payload,
        };
      case actions.setSortCube.TYPE:
        return {
          ...state,
          sort: payload.value,
        };
      case actions.setOrderCube.TYPE:
        return {
          ...state,
          order: payload,
        };
      case actions.createEditableTopCube.TYPE: {
        return {
          ...state,
          editableTopCube: payload,
        };
      }

      case actions.updateCubes.success.TYPE: {
        const newData = state.schemas.data.map((data) => {
          if (data.streamSchemaWrapper.schema.id === payload.cubes.schemaId) {
            return {
              streamSchemaWrapper: data.streamSchemaWrapper,
              schemaCubesWrapper: payload,
            };
          }
          return data;
        });
        const newSchemas = {
          ...state.schemas,
          data: newData,
        };

        return {
          ...state,
          schemas: newSchemas,
        };
      }
      case actions.setEditableTopCubeKeyVal.TYPE: {
        return {...state, editableTopCube: {...state.editableTopCube, ...payload}};
      }

      default:
        return state;
    }
  },
  {
    schemas: composeReducers(
      makeAsyncReducer(actions.fetchSchemas, {
        defaultData: EMPTY_ARRAY,
        shouldDestroyData: false,
        includeUpdateAt: true,
      }),
      (state = {}, {type, payload}) => {
        const getTopLevelCubesStreamIndex = (cubeId) => {
          let retIndex = -1;
          state.data.forEach((element, index) => {
            if (element.schemaCubesWrapper.cubes && element.schemaCubesWrapper.cubes.topLevelCubes) {
              Object.keys(element.schemaCubesWrapper.cubes.topLevelCubes).forEach((id) => {
                if (id === cubeId) {
                  retIndex = index;
                }
              });
            }
          });
          return retIndex;
        };

        const red = (item, payloadInner, isLoadingNumMetrics, id) => ({
          ...item,
          schemaCubesWrapper: {
            ...item.schemaCubesWrapper,
            cubes: {
              ...item.schemaCubesWrapper.cubes,
              topLevelCubes: {
                ...item.schemaCubesWrapper.cubes.topLevelCubes,
                [id]: {
                  ...item.schemaCubesWrapper.cubes.topLevelCubes[id],
                  uiState: {
                    ...item.schemaCubesWrapper.cubes.topLevelCubes[id].uiState,
                    numMetrics: payloadInner.numMetrics,
                    isLoadingNumMetrics,
                  },
                },
              },
            },
          },
        });

        switch (type) {
          case actions.fetchCubesCount.success.TYPE: {
            return {
              ...state,
              data: reduceArrayItem(
                red,
                state.data,
                getTopLevelCubesStreamIndex(payload.tlc.id),
                payload,
                false,
                payload.tlc.id,
              ),
            };
          }

          case actions.fetchCubesCount.TYPE: {
            return {
              ...state,
              data: reduceArrayItem(
                red,
                state.data,
                getTopLevelCubesStreamIndex(payload.topLevelCubes.id),
                payload,
                true,
                payload.topLevelCubes.id,
              ),
            };
          }
          default:
            return state;
        }
      },
    ),
  },
  {
    selectedCubePreview: makeAsyncReducer(actions.fetchCubePreview, {
      shouldDestroyData: false,
    }),
  },
);

export default cubesRoot;
