import {makeAction} from 'common/utils/simplifiedAsync';

const mac = (actionName) => makeAction(actionName, 'anodot/charts/ts/');

// *** callable actions on chart
// export const pushSeries = mac('PUSH_SERIES');
// export const startLoad = mac('START_LOAD');
// export const endLoad = mac('END_LOAD');
// export const resetZoom = mac('RESET_ZOOM');
// export const exportChartToImage = mac('EXPORT_CHART_TO_IMAGE');
// export const exportChartToCSV = mac('EXPORT_CHART_TO_CSV');
// export const syncChartVisual = mac('SYNC_CHART_VISUAL'); //TODO: rename to synchCrossHair
// export const adjustSize = mac('ADJUST_SIZE');
// export const addPlotLine = mac('ADD_PLOT_LINE');
// export const removePlotLine = mac('REMOVE_PLOT_LINE');
// export const removeAllSeries = mac('REMOVE_ALL_SERIES');
// export const onlyKeepSeriesForExpressionTrees = mac('ONNLY_KEEP_SERIES_FOR_EXPRESSION_TREE');
// export const removeSeriesForExpressionTree = mac('REMOVE_SERIES_FOR_EXPRESSION_TREE');
// export const setSeriesToEmptyDataForExpressionTree = mac('SET_SERIES_TO_EMPTY_DATA_FOR_EXPRESSION_TREE');
// export const setSeriesHover = mac('SET_SERIES_HOVER');
// export const setSeriesVisibility = mac('SET_SERIES_VISIBILITY');
// export const redraw = mac('REDRAW');
// export const setMobileState = mac('SET_MOBILE_STATE');

// *** dispatch events from chart
export const mouseOverChart = mac('MOUSE_OVER_CHART');
export const mouseOutChart = mac('MOUSE_OUT_CHART');
export const dateRangeChanged = mac('DATE_RANGE_CHANGE');
export const resetZoom = mac('RESET_ZOOM');
export const highChartCreated = mac('HIGHCHART_CREATED');
export const highChartDestroyed = mac('HIGHCHART_DESTROYED');
export const chartClicked = mac('CHART_CLICKED');
export const setTimeZoneToCharts = mac('SET_TIMEZONE_TO_CHARTS');
