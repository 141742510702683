// @flow
import React from 'react';
import Slider from 'react-rangeslider';
import {durationScales} from 'metrics/services/metricsService';
import ConditionContainer from './ConditionContainer';
import './MinDurationCondition.module.scss';

const MinDurationScale = (props: {scale: Object, onChange: Function}) => (
  <div styleName="buttons-container" automation-id="alertSettingsMinimumDurationButtons">
    {Object.values(durationScales).map((s) => (
      <button
        type="button"
        key={s.value}
        className={`btn btn-raised btn-outline btn-outline-large ${
          s.value === props.scale ? 'btn-outline-light' : 'btn-outline-dark'
        }`}
        onClick={() => props.onChange(s.value)}
      >
        {s.shortText}
      </button>
    ))}
  </div>
);

type PropTypes = {
  minDuration: Object,
  onMinDurationChange: Function,
  onMinDurationScaleChange: Function,
};

const formatDuration = (minDuration) => parseInt(minDuration.value / durationScales[minDuration.scale].seconds, 10);

const MinDurationSelector = (props: PropTypes) => (
  <ConditionContainer title={<span>Minimum Duration</span>}>
    <div styleName="empty-container">
      <div styleName="value-label">{formatDuration(props.minDuration)}</div>
    </div>
    <div styleName="input-container">
      <MinDurationScale onChange={props.onMinDurationScaleChange} scale={props.minDuration.scale} />
    </div>
    <div styleName="slider-container" automation-id="alertSettingsMinDurationSlider">
      <Slider
        value={props.minDuration.value}
        onChange={(v) => props.onMinDurationChange(v)}
        min={props.minDuration.min}
        max={props.minDuration.max}
        step={props.minDuration.step}
        tooltip={false}
      />
    </div>
  </ConditionContainer>
);

export default MinDurationSelector;
