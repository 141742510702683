// @flow
import React, {PureComponent} from 'react';
import DropdownButton from './DropdownButton';
import './DropdownButtonMore.module.scss';

type PropTypes = {
  id: string,
  children: any, // menu items
  valueChildren: any, // drop icon children
  shouldBubbleEvent?: boolean,
  disabled: boolean,
  onSelect: Function,
  dropup?: Boolean,
};

class DropdownButtonMore extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    shouldBubbleEvent: true,
    dropup: false,
  };

  onClick = (ev) => {
    if (!this.props.shouldBubbleEvent) {
      ev.stopPropagation();
    }
  };

  render() {
    const {id, children, onSelect, dropup, valueChildren, disabled} = this.props;

    return (
      <DropdownButton
        id={id}
        extraClassName="icon-dropdown btn-icon"
        noCaret
        placement="top"
        disabled={disabled || false}
        value={valueChildren || <i className="icon icn-icon-3-dots" styleName="more-icon" />}
        pullRight
        dropup={dropup}
        onSelect={onSelect}
        onClick={this.onClick}
      >
        {children}
        {/*
          example:
          <MenuItem key="investigate"
                    eventKey="investigate">Investigate</MenuItem>

          */}
      </DropdownButton>
    );
  }
}

export default DropdownButtonMore;
