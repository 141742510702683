// @flow
import React from 'react';
import SectionHeader from './SectionHeader';
import TimeScaleConditionComponent from '../TimeScaleCondition';
import './TimeScaleCondition.module.scss';

type PropTypes = {
  rollup: String,
  type: String,
  useAnomalyValues: Boolean,
  omitRollups: Array,
  onChange: Function,
};

class TimeScaleCondition extends React.PureComponent {
  props: PropTypes;

  render() {
    const {rollup, useAnomalyValues, omitRollups, onChange, type} = this.props;
    const yesNoBtns = [
      {value: false, text: 'BEFORE', disable: false},
      {value: true, text: 'DURING', disable: type === 'noData'},
    ];
    return (
      <div>
        <SectionHeader digit="3" title="Set Timing Properties" />
        <div styleName="time-scale">
          <TimeScaleConditionComponent
            rollup={rollup}
            onChange={(v) => onChange({rollup: v})}
            useContainer={false}
            omitRollups={omitRollups}
          />

          <div styleName="sub-title">
            {type === 'anomaly' ? 'Use values before or during the anomaly' : null}
            {type === 'noData' ? 'Use values before data stopped flowing' : null}
          </div>
          <div styleName="buttons-container-yes-no">
            {yesNoBtns.map((s) => (
              <button
                disabled={s.disable}
                type="button"
                key={s.value}
                className={`btn btn-raised btn-outline btn-outline-large ${
                  s.value === useAnomalyValues ? 'btn-outline-light' : 'btn-outline-dark'
                }`}
                onClick={() => onChange({useAnomalyValues: s.value})}
              >
                {s.text}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default TimeScaleCondition;
