import {combineEpics} from 'redux-observable';
import * as actions from 'alerts.console/store/actions';
import * as alertConsoleSelectors from 'alerts.console/store/selectors';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import * as api from 'feedback/services/api';

const findAlert = (state, payload) => {
  const ackAlertsGroups = alertConsoleSelectors.getAckAlerts(state).alertGroups;
  const noAckAlertsGroups = alertConsoleSelectors.getNoAckAlerts(state).alertGroups;
  const allAlertsGroups = [...ackAlertsGroups, ...noAckAlertsGroups];
  let alertFound = null;

  allAlertsGroups.find((alertGroup) =>
    alertGroup.alerts.forEach((alert) => {
      if (payload.triggeredId === alert.id) {
        alertFound = alert;
      }
    }),
  );
  return alertFound;
};

const setFeedbackRequest = makeAsyncEpic(actions.setFeedbackRequest, api.setAlertFeedbackPost);
const deleteFeedbackRequest = makeAsyncEpic(actions.deleteFeedbackRequest, api.deleteFeedback);
const updateFeedbackRequest = makeAsyncEpic(actions.updateFeedbackRequest, api.updateFeedback);

const setFeedback = (action$) =>
  action$
    .ofType(actions.setFeedback.TYPE)
    .switchMap(({payload, meta}) => [
      actions.setFeedbackRequest({...payload, triggeredId: undefined, triggerIds: [payload.triggeredId]}, meta),
    ]);

const deleteFeedback = (action$) =>
  action$.ofType(actions.deleteFeedback.TYPE).switchMap(({payload}) => [actions.deleteFeedbackRequest(payload.id)]);

const updateFeedback = (action$, {getState}) =>
  action$.ofType(actions.updateFeedback.TYPE).switchMap(({payload}) => {
    const state = getState();
    const alert = findAlert(state, payload);
    if (!alert) {
      return [];
    }
    return [actions.updateFeedbackRequest({...payload, triggeredId: undefined, triggerIds: [payload.triggeredId]})];
  });

const feedbackEpic = combineEpics(
  setFeedback,
  deleteFeedback,
  setFeedbackRequest,
  deleteFeedbackRequest,
  updateFeedback,
  updateFeedbackRequest,
);

export default feedbackEpic;
