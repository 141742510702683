// @flow
import React from 'react';
import './PanelHeader.module.scss';

type PropTypes = {
  title: string,
  isEditable: boolean,
  isEditHidden: boolean,
  onSelect: Function,
  children: any,
};

const getEditMode = (cb, disabled) => {
  if (!cb) {
    return null;
  }

  return (
    <button
      type="button"
      className="btn btn-icon btn-icon btn-dark-blue"
      automation-id="streamQueryMeasuresAndDimensionsPencil"
      disabled={disabled}
      onClick={cb}
    >
      <i className="icon icn-action16-edit" />
    </button>
  );
};

const PanelHeader = (props: PropTypes) => (
  <div styleName={props.isEditable ? 'root' : 'root disabled'}>
    <span className="ellipsis andt-section-header" styleName="title" title={props.title}>
      {props.title}
    </span>
    {props.children}
    {!props.isEditHidden && getEditMode(props.onSelect, !props.isEditable)}
  </div>
);

export default PanelHeader;
