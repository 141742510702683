import {createSelector} from 'reselect';
import {getCustomersData, getCustomersFilters} from 'admin.customers/store/selectors';
import {ALLOWED_FILTER_KEYS, DEFAULT_QUERY_PARAMS} from 'admin.customers/services/customersService';
import {get, omitBy} from 'lodash';
import {getSortedCustomerSectionCustomersList} from './selectors';

const EMPTY_ARRAY = [];

const filterKeyHeaders = {
  searchQuery: {
    id: 'searchQuery',
    label: 'Search:',
  },
  status: {
    id: 'status',
    label: 'Status:',
  },
  paying: {
    id: 'paying',
    label: 'Paying:',
  },
  selfService: {
    id: 'selfService',
    label: 'Self Service:',
  },
};

//* filters - searchQuery
export const getFiltersSearchQuery = createSelector(
  getCustomersFilters,
  (filters) => filters.searchQuery,
);

//* filters - status
export const getFiltersStatus = createSelector(
  getCustomersFilters,
  (filters) => filters.status,
);

//* filters - customers
export const getFiltersCustomers = createSelector(
  getCustomersFilters,
  (filters) => filters.customers,
);

//* filters - paying
export const getFiltersPaying = createSelector(
  getCustomersFilters,
  (filters) => filters.paying,
);

//* filters - self service
export const getFiltersSelfService = createSelector(
  getCustomersFilters,
  (filters) => filters.selfService,
);

export const getChangedFilters = createSelector(
  getCustomersFilters,
  (filters) => omitBy(filters, (value, key) => value === DEFAULT_QUERY_PARAMS[key]),
);

export const getSelectedFilterCustomers = createSelector(
  getCustomersData,
  getFiltersCustomers,
  (customersList, customers) =>
    customersList.filter((item) => customers && customers.split(',').indexOf(item._id) !== -1),
);

export const inFiltersOnlyOneGroupSelected = createSelector(
  getChangedFilters,
  (cngFilters) =>
    Object.keys(cngFilters).length === 1 && cngFilters.groups && cngFilters.groups.split(',').length === 1
      ? cngFilters.groups
      : null,
);

export const getFiltersButtonTooltipItems = createSelector(
  getChangedFilters,
  getSelectedFilterCustomers,
  (changedFilters) => {
    const filterItemsList = [];
    Object.keys(changedFilters).forEach((filterKey) => {
      const item = {
        id: get(filterKeyHeaders[filterKey], 'id', null),
        header: get(filterKeyHeaders[filterKey], 'label', null),
      };

      switch (filterKey) {
        case filterKeyHeaders.searchQuery.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey],
          });
          break;
        case filterKeyHeaders.status.id:
          filterItemsList.push({
            ...item,
            value: ALLOWED_FILTER_KEYS[filterKey][changedFilters[filterKey]].label,
          });
          break;
        case filterKeyHeaders.paying.id:
        case filterKeyHeaders.selfService.id:
          filterItemsList.push({
            ...item,
            value: ALLOWED_FILTER_KEYS[filterKey][changedFilters[filterKey]].label,
          });
          break;
        default:
          break;
      }
    });

    return filterItemsList;
  },
);

export const getFilteredCustomersSectionCustomersList = createSelector(
  getSortedCustomerSectionCustomersList,
  getCustomersFilters,
  (customerList, filters) => {
    if (!customerList.length) {
      return EMPTY_ARRAY;
    }

    return customerList.filter((customer) => {
      if (
        filters.searchQuery &&
        (customer.name.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) === -1 &&
          customer.id.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) === -1 &&
          (customer.apiToken || '').toLowerCase().indexOf(filters.searchQuery.toLowerCase()) === -1)
      ) {
        return false;
      }

      if (
        (filters.status === ALLOWED_FILTER_KEYS.status.enabled.value && customer.status) ||
        (filters.status === ALLOWED_FILTER_KEYS.status.disabled.value && !customer.status)
      ) {
        return false;
      }

      if (
        (filters.paying === ALLOWED_FILTER_KEYS.paying.true.value && !customer.paying) ||
        (filters.paying === ALLOWED_FILTER_KEYS.paying.false.value && customer.paying)
      ) {
        return false;
      }
      if (
        (filters.selfService === ALLOWED_FILTER_KEYS.selfService.true.value && !customer.selfService) ||
        (filters.selfService === ALLOWED_FILTER_KEYS.selfService.false.value && customer.selfService)
      ) {
        return false;
      }

      return true;
    });
  },
);

export const getFilteredCustomersSectionCustomersListCount = createSelector(
  getFilteredCustomersSectionCustomersList,
  (customersList) => customersList.length,
);
