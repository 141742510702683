// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import * as selectors from 'bc/store/selectors';
import DataTablePreviewModal from 'bc/components/streams/editor/common/DataTablePreviewModal';
import {get} from 'lodash';
import {fetchFuPreview as fetchFuPreviewAction} from 'bc/store/actions';

type PropTypes = {
  isOpen: boolean,
  onClose: Function,

  // connect
  dataStream: Object,
  fetchFuPreview: Function,
  isLoading: Boolean,
  previewData: Object,
};

class FuFilePreview extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    const {
      dataStream: {dataSourceId, fileFormat},
      previewData,
      isLoading,
      fetchFuPreview,
    } = this.props;

    if (!(previewData.rows || isLoading)) {
      fetchFuPreview({fileFormat, dataSourceId});
    }
  }

  render() {
    const {isOpen, onClose, dataStream, previewData, isLoading, fetchFuPreview} = this.props;

    return (
      <DataTablePreviewModal
        isOpen={isOpen}
        onClose={onClose}
        title="File Preview"
        subTitle={get(dataStream, 'uiState.analysisResult.fileName', '')}
        previewData={previewData}
        isLoading={isLoading}
        fetchPreview={fetchFuPreview}
      />
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    previewData: selectors.getFilePreviewData(state),
    isLoading: selectors.getFilePreview(state).isLoading,
  }),
  {
    fetchFuPreview: fetchFuPreviewAction,
  },
)(FuFilePreview);
