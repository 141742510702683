// @flow
import React, {Fragment} from 'react';
import {CURRENCY_OPTIONS} from 'impact/services/constants';
import SelectAndt, {THEME_BLUE_LEAN, TYPE_NEW_NO_SEARCH} from '../../common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  value: Number,
  setValue: Function,
};

const OPTIONS = Object.values(CURRENCY_OPTIONS).map((i) => ({...i, label: `${i.label}(${i.symbol})`}));

const CurrencySelector = (props: PropTypes) => {
  const setValue = (value) => {
    props.setValue({value, name: 'currency'});
  };
  return (
    <Fragment>
      <SelectAndt
        id="impactCurrencySelector"
        automationId="impactCurrencySelector"
        extraClassName="alerts-dropdown-btn"
        optionHeight={40}
        menuWidth={150}
        type={TYPE_NEW_NO_SEARCH}
        theme={THEME_BLUE_LEAN}
        options={OPTIONS}
        onChange={(i) => setValue(i.value)}
        value={CURRENCY_OPTIONS[props.value]}
      />
    </Fragment>
  );
};

export default CurrencySelector;
