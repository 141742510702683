// @flow
import React, {PureComponent} from 'react';
import TimeFormat from 'common/components/dateTime/TimeFormat';
import TimeZone from './TimeZone';
import 'moment-timezone';
import './TimeDefinition.module.scss';

type PropTypes = {
  timePattern: String,
  timeZone: String,
  isLoading: Boolean,
  onChange: Function,
  dateFormats: Array<Boolean>,
};

export default class TimeDefinition extends PureComponent {
  props: PropTypes;

  timeFormatChanged = (item) => {
    if (item.hasTimeZone) {
      this.props.onChange({timePattern: item.value, timeZone: null});
    } else {
      this.props.onChange({timePattern: item.value});
    }
  };

  render() {
    const {dateFormats, timePattern, onChange, timeZone, isLoading} = this.props;
    const selectedDateFormat = dateFormats.find((item) => item.value === timePattern) || undefined;

    return (
      <div styleName="root" automation-id="timeDefinition">
        <TimeFormat
          items={dateFormats}
          onChange={this.timeFormatChanged}
          selectedItem={selectedDateFormat}
          isLoading={isLoading}
          placeholder="Choose date format"
        />

        <TimeZone
          timeZone={timeZone}
          disabled={selectedDateFormat && selectedDateFormat.hasTimeZone}
          selectedItem={timeZone}
          onChange={onChange}
        />
      </div>
    );
  }
}
