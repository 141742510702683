// @flow
import React from 'react';
import DropdownButton from 'common/components/bootstrap/DropdownButton';
import MenuItem from 'common/components/bootstrap/MenuItem';

type PropTypes = {
  onChange: Function,
  value: String,
};

const timestampTypes = {
  epoch_seconds: 'Epoch Seconds',
  epoch_millis: 'Epoch Millis',
  epoch_micros: 'Epoch Micros',
};

class TimestampType extends React.PureComponent {
  props: PropTypes;

  onMenuItemClick = (item) => {
    this.props.onChange({timestampType: item});
  };

  render() {
    const {value} = this.props;
    return (
      <DropdownButton
        id="timestampTypePicker"
        title="Timestamp Type"
        value={timestampTypes[value] || ''}
        onSelect={(m) => this.onMenuItemClick(m)}
      >
        {Object.keys(timestampTypes).map((key) => (
          <MenuItem key={key} eventKey={key} active={value === key}>
            {timestampTypes[key]}
          </MenuItem>
        ))}
      </DropdownButton>
    );
  }
}

export default TimestampType;
