// @flow
import React from 'react';
import Slider from 'react-rangeslider';
import ConditionContainer from './ConditionContainer';
import './SignificanceCondition.module.scss';

type PropTypes = {
  significance: Object,
  onChange: Function,
};

const formatSignificance = (val) => Math.floor(val * 100) || 0;

const SignificanceSelector = (props: PropTypes) => (
  <ConditionContainer title="Significance Score">
    <div styleName="value-label">{formatSignificance(props.significance.minSignificance)}</div>
    <div styleName="slider-container" automation-id="alertSettingsSignificanceSlider">
      <Slider
        value={props.significance.minSignificance}
        onChange={(v) => props.onChange({minSignificance: v})}
        min={0}
        max={1}
        step={0.01}
        tooltip={false}
      />
    </div>
  </ConditionContainer>
);

export default SignificanceSelector;
