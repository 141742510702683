import {get, post, delete_} from 'common/utils/http';

const root = 'api/v1/dynamic-routing';

export const getDynamicRoutingFiles = () => get(root);
export const uploadDynamicRoutingFile = ({payload}) => post(root, payload);

export const viewDynamicRoutingFile = ({payload}) => get(`${root}/${payload.id}/csv`);
export const replaceDynamicRoutingFile = ({payload}) => post(`${root}/${payload.id}/csv`, payload.data);

export const deleteDynamicRoutingFile = ({payload}) => delete_(`${root}/${payload.id}`);
export const downloadFile = (id) => get(`${root}/${id}`, {responseType: 'arraybuffer'});
