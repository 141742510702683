import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const sendMail = composeReducers(makeAsyncReducer(actions.sendMail, {defaultData: null}));

const getChampions = composeReducers(
  makeAsyncReducer(actions.getChampions, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: true,
  }),
);

const sentMailSuccess = composeReducers((state = {}, {type, payload}) => {
  switch (type) {
    case actions.sendMail.success.TYPE: {
      return {
        payload,
      };
    }
    default:
      return state;
  }
});

export default combineReducers({
  sendMail,
  sentMailSuccess,
  getChampions,
});
