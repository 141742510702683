import {combineReducers} from 'redux';
import {intersection} from 'lodash';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';
import * as actions from 'admin.users/store/actions';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {groupErrorHandler, usersErrorHandler} from 'admin.users/services/errors';
import users from './subscribersReducer';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};
const TEMP_GROUP_ID = 'tempGroupId';

export default combineReducers({
  users,
  fetchUser: composeReducers(
    makeAsyncReducer(actions.fetchUser, {
      defaultData: EMPTY_OBJECT,
      errorHandler: usersErrorHandler,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleUserEditModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_OBJECT,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  createUsers: composeReducers(
    makeAsyncReducer(actions.createUsers, {
      defaultData: EMPTY_OBJECT,
      errorHandler: usersErrorHandler,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleNewUserModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_OBJECT,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  createGroup: composeReducers(
    makeAsyncReducer(actions.createGroup, {
      defaultData: EMPTY_OBJECT,
      errorHandler: groupErrorHandler,
    }),
  ),
  updateGroup: composeReducers(
    makeAsyncReducer(actions.updateGroup, {
      defaultData: EMPTY_OBJECT,
      errorHandler: groupErrorHandler,
    }),
  ),
  fetchGroupsEditNewUser: composeReducers(
    makeAsyncReducer(actions.fetchGroupsEditNewUser, {
      defaultData: EMPTY_ARRAY,
      errorHandler: groupErrorHandler,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleUserEditModal.TYPE:
        case actions.toggleNewUserModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_ARRAY,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  fetchGroups: composeReducers(
    makeAsyncReducer(actions.fetchGroups, {
      defaultData: EMPTY_ARRAY,
      shouldDestroyData: false,
      errorHandler: groupErrorHandler,
    }),
    (state = {}, {type, payload}) => {
      const red = (item, payloadInner) => ({...item, ...payloadInner});

      const findGroupIndexById = (id) => state.data.findIndex((item) => item._id === id);

      const updateGroupById = (id, obj) => {
        const index = findGroupIndexById(id);
        return {
          ...state,
          data: reduceArrayItem(red, state.data, index, obj),
        };
      };

      switch (type) {
        case actions.cleanFetchGroups.TYPE: {
          return {data: EMPTY_ARRAY};
        }
        case actions.createGroup.TYPE: {
          return {...state, data: [...state.data, {...payload.group, _id: TEMP_GROUP_ID}]};
        }
        case actions.createGroup.success.TYPE: {
          return updateGroupById(TEMP_GROUP_ID, payload);
        }
        case actions.deleteGroup.TYPE: {
          return {...state, data: state.data.filter((item) => item._id !== payload.groupId)};
        }
        case actions.updateGroup.TYPE: {
          return updateGroupById(payload.group.id, payload.group);
        }
        case actions.addGroupsToUser.success.TYPE: {
          const groupIdsToUpdate = payload.groups.map((gr) => gr._id);
          const oldGroups = state.data.filter((group) => intersection(groupIdsToUpdate, [group._id]).length === 0);
          return {...state, data: [...oldGroups, ...payload.groups]};
        }
        case actions.deleteUser.TYPE: {
          const groupsUpdated = state.data.map((gr) => ({
            ...gr,
            users: gr.users.filter((id) => id !== payload.userId),
          }));

          return {...state, data: groupsUpdated};
        }
        case actions.updateUser.TYPE: {
          const groupsUpdated = state.data.map((gr) => {
            const filteredUsers = gr.users.filter((id) => id !== payload._id);
            if (intersection(payload.groups, [gr._id]).length) {
              filteredUsers.push(payload._id);
            }

            return {
              ...gr,
              users: filteredUsers,
            };
          });

          return {...state, data: groupsUpdated};
        }

        case actions.createUsers.success.TYPE: {
          if (payload.groups) {
            const groupIdsToUpdate = payload.groups.map((gr) => gr._id);
            const oldGroups = state.data.filter((group) => intersection(groupIdsToUpdate, [group._id]).length === 0);
            return {...state, data: [...oldGroups, ...payload.groups]};
          }

          return state;
        }

        case actions.editUsersBulkApi.success.TYPE: {
          if (payload.groups && payload.groups.length) {
            const updatedGroupsIds = payload.groups.map((gr) => gr._id);
            const oldGroups = state.data.filter((gr) => intersection(updatedGroupsIds, [gr._id]).length === 0);
            return {
              ...state,
              data: [...oldGroups, ...payload.groups],
            };
          }
          return state;
        }

        case actions.deleteUsersBulkApi.success.TYPE: {
          if (payload.success && payload.usersDeleted.length) {
            const newGroups = state.data.map((gr) => ({
              ...gr,
              users: gr.users.filter((ur) => payload.usersDeleted.indexOf(ur) === -1),
            }));
            return {
              ...state,
              data: newGroups,
            };
          }
          return state;
        }

        default:
          return state;
      }
    },
  ),
  fetchOwnerDashboardsCount: composeReducers(
    makeAsyncReducer(actions.fetchOwnerDashboardsCount, {
      defaultData: EMPTY_OBJECT,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleDeleteGroupModal.TYPE:
        case actions.toggleDeleteUserModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_OBJECT,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  fetchOwnerAnoboardsCount: composeReducers(
    makeAsyncReducer(actions.fetchOwnerAnoboardsCount, {
      defaultData: EMPTY_OBJECT,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleDeleteGroupModal.TYPE:
        case actions.toggleDeleteUserModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_OBJECT,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  fetchOwnerAlertsCount: composeReducers(
    makeAsyncReducer(actions.fetchOwnerAlertsCount, {
      defaultData: EMPTY_OBJECT,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleDeleteGroupModal.TYPE:
        case actions.toggleDeleteUserModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_OBJECT,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  fetchOwnerChannelsCount: composeReducers(
    makeAsyncReducer(actions.fetchOwnerChannelsCount, {
      defaultData: EMPTY_OBJECT,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleDeleteUserModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_OBJECT,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  fetchOwnerCompositesCount: composeReducers(
    makeAsyncReducer(actions.fetchOwnerCompositesCount, {
      defaultData: EMPTY_OBJECT,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleDeleteUserModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_OBJECT,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  fetchOwnerRefreshTokensCount: composeReducers(
    makeAsyncReducer(actions.fetchOwnerRefreshTokensCount, {
      defaultData: EMPTY_OBJECT,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleDeleteUserModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_OBJECT,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  fetchOwnerStreamsCount: composeReducers(
    makeAsyncReducer(actions.fetchOwnerStreamsCount, {
      defaultData: EMPTY_OBJECT,
    }),
    (state = {}, {type, payload}) => {
      switch (type) {
        case actions.toggleDeleteUserModal.TYPE: {
          if (!payload.isOpen) {
            return {
              data: EMPTY_OBJECT,
            };
          }
          return state;
        }
        default:
          return state;
      }
    },
  ),
  editUsersBulkApi: composeReducers(
    makeAsyncReducer(actions.editUsersBulkApi, {
      defaultData: EMPTY_OBJECT,
      errorHandler: groupErrorHandler,
    }),
  ),
  deleteUsersBulkApi: composeReducers(
    makeAsyncReducer(actions.deleteUsersBulkApi, {
      defaultData: EMPTY_OBJECT,
      errorHandler: groupErrorHandler,
    }),
  ),
});
