import {combineReducers} from 'redux';
import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import * as actions from './actions';

const EMPTY_ARRAY = [];

const customersAndGlobal = composeReducers(
  makeAsyncReducer(actions.fetchCustomers, {defaultData: EMPTY_ARRAY}),
  (state, {type, payload}) => {
    switch (type) {
      case actions.fetchCustomers.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const defaults = composeReducers(
  makeAsyncReducer(actions.fetchDefaults, {defaultData: EMPTY_ARRAY}),
  (state, {type, payload}) => {
    switch (type) {
      case actions.fetchDefaults.success.TYPE:
        return {
          ...state,
          data: payload,
        };
      default:
        return state;
    }
  },
);

const updateCustomersAndGlobal = composeReducers(
  makeAsyncReducer(actions.updateCustomersAndGlobal, {defaultData: EMPTY_ARRAY}),
  (state, {type, payload}) => {
    switch (type) {
      case actions.updateCustomersAndGlobal.success.TYPE:
        return {
          ...state,
          success: true,
          data: payload,
        };
      case actions.updateCustomersAndGlobal.failure.TYPE:
        return {
          ...state,
          success: false,
          data: payload,
        };
      default:
        return state;
    }
  },
);

export default combineReducers({
  customersAndGlobal,
  defaults,
  updateCustomersAndGlobal,
});
