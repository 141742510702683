import React from 'react';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import {useDispatch} from 'react-redux';
import MetricExplorer from './MetricExplorer';
import {retrieveStoredState} from '../../store/actions';

type PropTypes = {
  onClose: Function,
  onSave: Function,
  isOpen: boolean,
};

const MetricExplorerPopup = ({onSave, onClose, isOpen}: PropTypes) => {
  const dispatch = useDispatch();
  const handleModalCancel = () => {
    dispatch(retrieveStoredState());
    onClose();
  };

  return isOpen ? (
    <div>
      <Modal isCloseButtonHidden size={SIZES.MAXWIDTH} height="90vh" onClose={handleModalCancel} isOpen>
        <MetricExplorer isModal onModalCancel={handleModalCancel} onModalSave={onSave} />
      </Modal>
    </div>
  ) : null;
};

export default MetricExplorerPopup;
