// @flow
import React, {PureComponent} from 'react';
import Highlighter from 'react-highlight-words';
import SmartTooltip, {TYPES} from 'common/componentsV2/Tooltip';
import IssueDate from 'topologyLeaflet/components/leftPanel/IssueDate';
import {ReactComponent as StatusIcon} from 'topologyLeaflet/images/open_alert.svg';
import './LeftPanel.module.scss';

type PropTypes = {
  className?: String,
  timeZoneName?: String,
  textHighlight?: String,
  item: Object,
  onClick: Function,
};

const getIssueTypeChip = (item) => {
  let chipElement = null;

  if (item.isStatic) {
    chipElement = <span styleName="chip static-chip">Static</span>;
  } else if (item.isNoData) {
    chipElement = <span styleName="chip no-data-chip">No Data</span>;
  }

  return chipElement;
};

export default class IssueItem extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    className: '',
    timeZoneName: null,
    textHighlight: '',
  };

  onClick = () => {
    this.props.onClick(this.props.item);
  };

  render() {
    const {className, item, timeZoneName, textHighlight} = this.props;

    return (
      <div styleName="issue-item" className={className} onClick={this.onClick}>
        <div styleName="icon-section">
          {item.isOpen && (
            <SmartTooltip placement="top" content="Open Issue" delay={400}>
              <i styleName="status-icon">
                <StatusIcon />
              </i>
            </SmartTooltip>
          )}
        </div>
        <div styleName="data-section">
          <SmartTooltip placement="top" content={item.name} delay={400} type={TYPES.LARGE}>
            <div styleName="name">
              <Highlighter autoEscape searchWords={[textHighlight]} textToHighlight={item.name} />
            </div>
          </SmartTooltip>
          <div styleName="started">
            Started
            <div styleName="date">
              <IssueDate startDate={item.startTime} timeZone={timeZoneName} />
            </div>
            {getIssueTypeChip(item)}
          </div>
        </div>
        <div styleName="summery-section">
          <div styleName="number">{item.total}</div>
          <div styleName="sub-text">{item.totalText}</div>
        </div>
      </div>
    );
  }
}
