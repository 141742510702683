// @flow

/* GABPAC - 04/11/19 - This is a container for Key and Value.
 * It will receive the expression, will react to click, outer click and child remove click
 * Since the events of the textInput are very complex, it will be dealt by the parent (and
 * that's why the parent sends the textInput as a Node
 */
import React from 'react';
import './Chip.module.scss';
import {Observable} from 'rxjs';
import ExpressionItem from './ExpressionItem';
import InnerChip from './InnerChip';
import Tooltip, {TYPES} from '../Tooltip';

// Different kind of chips, different CSS for each
export const TYPE = {
  MEASURE: 'measure',
  DIMENSION: 'dimension',
  FILTER: 'filter',
  LEGACY: 'legacy',
};

type PropTypes = {
  type: String,
  expressionItem: ExpressionItem,
  onClick: Function,
  onInnerChipRemoveClicked: Function,
  onClickOutside: Function,
  isFocus: Boolean,
  isShowChildrenOnly: Boolean,
  alternativeLabel?: String,
  displayOnly?: boolean,

  textInput: Node,
};

export default class Chip extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    alternativeLabel: 'Search',
    displayOnly: false,
  };

  componentDidMount() {
    this.mouseDown$ = Observable.fromEvent(document, 'mousedown');
    this.mouseDown$.subscribe((val) => this.handleClickOutside(val));
  }

  componentWillUnmount() {
    if (this.mouseDown$ && typeof this.mouseDown$.unsubscribe === 'function') {
      this.mouseEvent$.unsubscribe();
    }
  }

  handleClickOutside = (val) => {
    if (this.wrapper && !this.wrapper.contains(val.target)) {
      this.props.onClickOutside(this.props.expressionItem, val);
    }
  };

  handleClick = (val) => {
    if (!val.target.classList.contains('icon') && this.props.type !== TYPE.MEASURE) {
      this.props.onClick(this.props.expressionItem);
    }
  };

  render() {
    const innerChipTooltipContent = (
      <div>
        {this.props.expressionItem.itemValues.map((itemValue) => (
          <p key={itemValue.label}>{itemValue.label}</p>
        ))}
      </div>
    );

    return (
      <div
        ref={(wrapper) => {
          this.wrapper = wrapper;
        }}
        onClick={this.handleClick}
        styleName={['container', this.props.isShowChildrenOnly ? 'only-children' : '', this.props.type].join(' ')}
      >
        {!this.props.isShowChildrenOnly && (
          <Tooltip content={this.props.expressionItem.label || this.props.alternativeLabel} type={TYPES.SMALL}>
            <div className="value-chip" automation-id="metricExplorerExpressionChipValue" styleName="value">
              {this.props.expressionItem.label || this.props.alternativeLabel}
            </div>
          </Tooltip>
        )}
        {this.props.expressionItem.isNot && <div styleName="is-not">IS NOT</div>}
        {this.props.expressionItem.itemValues.length <= 3 ? (
          this.props.expressionItem.itemValues.map((itemValue: ExpressionItem) => (
            <InnerChip
              key={itemValue.value}
              expression={itemValue}
              onRemoveClicked={this.props.onInnerChipRemoveClicked}
              hasParent={!this.props.isShowChildrenOnly}
              type={this.props.type}
              displayOnly={this.props.displayOnly}
            />
          ))
        ) : (
          <Tooltip content={innerChipTooltipContent} type={TYPES.SMALL} placement="bottom">
            <div>
              <InnerChip
                title={`${this.props.expressionItem.itemValues.length} Values`}
                hasParent={!this.props.isShowChildrenOnly}
                type={this.props.type}
                displayOnly={this.props.displayOnly}
              />
            </div>
          </Tooltip>
        )}
        {this.props.isFocus && !this.props.isShowChildrenOnly ? this.props.textInput : null}
      </div>
    );
  }
}
