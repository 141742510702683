import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const EMPTY_ARRAY = [];

const allAlertActions = composeReducers(
  makeAsyncReducer(actions.fetchAllActions, {
    defaultData: EMPTY_ARRAY,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
  (state, {type, payload, meta}) => {
    switch (type) {
      case actions.createAction.success.TYPE:
        return {
          ...state,
          data: [...payload, ...state.data],
        };
      case actions.updateAction.success.TYPE:
        return {
          ...state,
          data: [payload[0], ...state.data.filter((action) => action.id !== payload[0].id)],
        };
      case actions.deleteAction.success.TYPE:
        return {
          ...state,
          data: [...state.data.filter((i) => i.id !== meta.id)],
        };
      default:
        return state;
    }
  },
);

const createAction = composeReducers(
  makeAsyncReducer(actions.createAction, {
    defaultData: null,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const updateAction = composeReducers(
  makeAsyncReducer(actions.updateAction, {
    defaultData: null,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const deleteAction = composeReducers(
  makeAsyncReducer(actions.deleteAction, {
    defaultData: null,
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

export default combineReducers({
  allAlertActions,
  createAction,
  updateAction,
  deleteAction,
});
