// @flow
import React from 'react';
import Button, {COLORS} from 'common/componentsV2/Button';
import {useHistory} from 'react-router-dom';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';

const DashboardNewButton = () => {
  const history = useHistory();
  return (
    <Button
      automationId="dashboardsNew"
      text="New Dashboard"
      colorSchema={COLORS.BLUE_500}
      onClick={() => history.push(`/dashboards/${OPTION_TILES_VALUES.CREATE_TYPE}`)}
      icon="icn-action16-plusa"
    />
  );
};

export default DashboardNewButton;
