import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {Observable} from 'rxjs';
import * as api from '../services/api';
import * as actions from './actions';
import {success} from '../../common/utils/notifications/notificationsService';

const fetchStreams = makeAsyncEpic(actions.fetchStreams, api.fetchStreams);

const fetchStats = makeAsyncEpic(actions.fetchStats, api.fetchStats);

const fetchStreamsReport = makeAsyncEpic(actions.fetchStreamsReport, api.fetchStreamsReport);
const fetchTaskLog = makeAsyncEpic(actions.fetchTaskLog, api.fetchTaskLog);
const fetchKibanaLog = makeAsyncEpic(actions.fetchKibanaLog, api.fetchKibanaLog);

const flushMetrics = makeAsyncEpic(actions.flushMetrics, api.flushMetrics);
const searchMetrics = makeAsyncEpic(actions.searchMetrics, api.searchMetrics);
const deleteMetrics = makeAsyncEpic(actions.deleteMetrics, api.deleteMetrics);
const getMetricsEps = makeAsyncEpic(actions.getMetricsEps, api.getMetricsEps);
const generateTemplates = makeAsyncEpic(actions.generateTemplates, api.generateTemplates);

const fetchAlertsErrors = makeAsyncEpic(actions.fetchAlertsErrors, api.fetchAlertsErrors);
const fetchCompositeErrors = makeAsyncEpic(actions.fetchCompositeErrors, api.fetchCompositeErrors);

const fetchStreamDimensions = (action$) =>
  action$.ofType(actions.fetchStreamDimensions.TYPE).flatMap((action) =>
    api
      .fetchStreamDimensions(action.payload, action.meta)
      .map((payload) => actions.fetchStreamDimensions.success(payload, action.meta))
      .catch((error) => Observable.of(actions.fetchStreamDimensions.failure(error, action.meta))),
  );

const generateTemplatesSuccess = (action$) =>
  action$.ofType(actions.generateTemplates.success.TYPE).flatMap(() => [
    success({
      title: 'Generate-Templates process Succeeded!',
      description: 'New Dashboards and Alerts was Created.',
    }),
  ]);

export default combineEpics(
  fetchStreams,
  fetchStreamDimensions,
  fetchStats,
  fetchStreamsReport,
  fetchTaskLog,
  fetchKibanaLog,
  flushMetrics,
  searchMetrics,
  deleteMetrics,
  getMetricsEps,
  fetchAlertsErrors,
  fetchCompositeErrors,
  generateTemplates,
  generateTemplatesSuccess,
);
