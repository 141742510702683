import {logErrorToServer} from 'common/utils/angularServices';
import * as api from './api';

let uploadFileRequest = null;

const uploadFileToAWS = function(file, signedRequest, successFunc, errorFunc, progressFunc, action, updateFileAction) {
  uploadFileRequest = new XMLHttpRequest();
  uploadFileRequest.open('PUT', signedRequest);
  uploadFileRequest.onreadystatechange = () => {
    if (uploadFileRequest.readyState === 4) {
      if (uploadFileRequest.status === 200 && successFunc) {
        successFunc({s3url: uploadFileRequest.responseURL.split('?')[0], fileName: file.name});
        if (action) {
          action({type: 'success', msg: 'File update successfully'});
        }
        if (updateFileAction) {
          updateFileAction();
        }
      } else {
        const err = {
          message: `AWS File upload failed, status:${uploadFileRequest.status}, fileName: ${file.name}, s3url: ${
            uploadFileRequest.responseURL.split('?')[0]
          }`,
        };
        logErrorToServer(err);
        if (errorFunc) {
          errorFunc({
            message: 'File upload failed',
            status: uploadFileRequest.status,
          });
        }
      }
      uploadFileRequest = null;
    }
  };
  uploadFileRequest.upload.onprogress = (event) => {
    if (progressFunc) {
      progressFunc(Math.round((event.loaded / event.total) * 1000) / 10);
    }
  };
  uploadFileRequest.send(file);
};

export const getSignedRequest = (
  file,
  bucket,
  path,
  successFunc,
  errorFunc,
  progressFunc,
  folder = null,
  action = null,
  updateFileAction,
) => {
  api
    .getS3SignedRequest({
      name: encodeURIComponent(file.name),
      type: encodeURIComponent(file.type),
      bucket,
      path,
      folder,
    })
    .promise.then((payload) => {
      if (payload) {
        uploadFileToAWS(file, payload, successFunc, errorFunc, progressFunc, action, updateFileAction);
      }
    });
};

export const getS3DeleteRequest = (file, bucket, path, successFunc, errorFunc, folder) => {
  api
    .deleteS3File({
      file,
      bucket,
      path,
      folder,
    })
    .promise.then(() => {
      successFunc();
    });
};

export const downloadFile = (file, bucket, path, successFunc, errorFunc, folder) => {
  api
    .getS3File({
      file,
      bucket,
      path,
      folder,
    })
    .promise.then((payload) => {
      const arr = payload.Body.data;
      const byteArray = new Uint8Array(arr);
      const a = window.document.createElement('a');

      // use created anchor to body.
      a.href = window.URL.createObjectURL(new Blob([byteArray], {type: 'application/octet-stream'}));
      a.download = file;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      successFunc();
    });
};

export const uploadFile = (file, bucket, path, successFunc, errorFunc, progressFunc) => {
  getSignedRequest(file, bucket, path, successFunc, errorFunc, progressFunc);
};

export const abortFileUpload = () => {
  if (!uploadFileRequest) {
    return;
  }
  uploadFileRequest.abort();
};
