import {createSelector} from 'reselect';
import {get} from 'lodash';

const getPercentage = (valueOf, allValues) => {
  if (isNaN(valueOf) || isNaN(allValues)) {
    return undefined;
  }
  if (allValues === 0) {
    return 0;
  }
  return Math.round((valueOf / allValues) * 100);
};

export const getRoot = (state) => state.insightsPanel;

// ****************view*****************
export const getView = createSelector(
  getRoot,
  (i) => i.view,
);
export const getPanel = createSelector(
  getView,
  (i) => i.panel,
);
export const getIsPanelOpen = createSelector(
  getPanel,
  (i) => i.isPanelOpen,
);

export const getNumLastDays = createSelector(
  getPanel,
  (i) => i.numLastDays,
);

// ****************Data*****************
export const getData = createSelector(
  getRoot,
  (i) => i.data,
);

// Open Alerts
export const getOpenAlerts = createSelector(
  getData,
  (i) => i.openAlerts,
);

export const getOpenAlertsData = createSelector(
  getOpenAlerts,
  (i) => ({
    all: get(i, 'data.total', 0),
    critical: get(i, 'data.critical', 0),
  }),
);

export const getIsLoadingOpenAlerts = createSelector(
  getOpenAlerts,
  (i) => i.isLoading,
);

// Alerts Distribution
export const getAlertsDistribution = createSelector(
  getData,
  (i) => i.alertsDistribution,
);

export const getAlertsDistributionData = createSelector(
  getAlertsDistribution,
  (i) => {
    const anomaly = get(i, 'data.byType.anomaly', null);
    const statics = get(i, 'data.byType.static', null);
    const noData = get(i, 'data.byType.noData', null);
    const critical = get(i, 'data.bySeverity.CRITICAL', 0);
    const high = get(i, 'data.bySeverity.HIGH', 0);
    const medium = get(i, 'data.bySeverity.MEDIUM', 0);
    const low = get(i, 'data.bySeverity.LOW', 0);
    const info = get(i, 'data.bySeverity.INFO', 0);
    const total = anomaly + statics + noData;

    return {
      byType: [
        {
          name: 'Anomalies',
          y: anomaly,

          percentage: getPercentage(anomaly, total),
          id: 'anomaly',
        },
        {
          name: 'Statics',
          y: statics,

          percentage: getPercentage(statics, total),
          id: 'static',
        },
        {
          name: 'No Data',
          y: noData,

          percentage: getPercentage(noData, total),
          id: 'noData',
        },
      ],
      bySeverity: [
        {
          name: 'Critical',
          y: critical,

          percentage: getPercentage(critical, total),
          id: 'CRITICAL',
        },
        {
          name: 'High',
          y: high,

          percentage: getPercentage(high, total),
          id: 'HIGH',
        },
        {
          name: 'Medium',
          y: medium,

          percentage: getPercentage(medium, total),
          id: 'MEDIUM',
        },
        {
          name: 'Low',
          y: low,

          percentage: getPercentage(low, total),
          id: 'LOW',
        },
        {
          name: 'Info',
          y: info,

          percentage: getPercentage(info, total),
          id: 'INFO',
        },
      ],
    };
  },
);

export const getIsLoadingAlertsDistribution = createSelector(
  getAlertsDistribution,
  (i) => i.isLoading,
);

// Metrics Usage
export const getMetricsUsage = createSelector(
  getData,
  (i) => i.metricsUsage,
);

export const getMetricsUsageData = createSelector(
  getMetricsUsage,
  (i) => i.data,
);

export const getMetricsUsageDataLimit = createSelector(
  getMetricsUsageData,
  (i) => get(i, 'limit', 0),
);

export const getMetricsUsageDataMonthlyUsage = createSelector(
  getMetricsUsageData,
  (i) => get(i, 'monthlyUsage', 0),
);

export const getIsLoadingMetricsUsage = createSelector(
  getMetricsUsage,
  (i) => i.isLoading,
);

// Feedback Distribution
export const getFeedbackDistribution = createSelector(
  getData,
  (i) => i.feedbackDistribution,
);

export const getFeedbackDistributionData = createSelector(
  getFeedbackDistribution,
  (i) => {
    const goodCatch = get(i, 'data.goodCatchCount', 0);
    const notInteresting = get(i, 'data.notInterestingCount', 0);
    const all = goodCatch + notInteresting;

    return [
      {
        id: 'goodCatch',
        y: goodCatch,
        percentage: getPercentage(goodCatch, all),
        name: 'Good Catch',
        type: 'goodCatch',
      },
      {
        id: 'notInteresting',
        y: notInteresting,
        percentage: getPercentage(notInteresting, all),
        name: 'Not Interesting',
        type: 'notInteresting',
      },
    ];
  },
);

export const getIsLoadinggeFeedbackDistribution = createSelector(
  getFeedbackDistribution,
  (i) => i.isLoading,
);

// Alerts Triggered
export const getAlertsTriggered = createSelector(
  getData,
  (i) => i.alertsTriggered,
);

export const getAlertsTriggeredData = createSelector(
  getAlertsTriggered,
  (i) => i.data,
);

export const getIsLoadingAlertsTriggered = createSelector(
  getAlertsTriggered,
  (i) => i.isLoading,
);

// Feedback Per Measure
export const getFeedbackPerMeasure = createSelector(
  getData,
  (i) => i.feedbackPerMeasure,
);

export const getFeedbackPerMeasureData = createSelector(
  getFeedbackPerMeasure,
  (feedbackPerMeasure) => {
    const feedbackPerMeasureDataGroups = get(feedbackPerMeasure, 'data.topMeasures', null);
    if (!feedbackPerMeasureDataGroups) {
      return [];
    }
    let primaryArr = [];
    let secondArr = [];
    feedbackPerMeasureDataGroups.forEach((item, index) => {
      const goodCatch = get(item, 'goodCatchCount', null);
      const notInteresting = get(item, 'notInterestingCount', null);
      const newItem = [
        {
          id: `goodCatch${item.name}`,
          y: goodCatch,
          name: item.name,
          type: 'goodCatch',
          typeName: 'Good Catch',
        },
        {
          id: `notInteresting${item.name}`,
          y: notInteresting,
          name: item.name,
          type: 'notInteresting',
          typeName: 'Not Interesting',
        },
      ];
      if (index !== 0 && index % 3 === 0) {
        primaryArr = [...primaryArr, secondArr];
        secondArr = [];
        secondArr.push(newItem);
      } else {
        secondArr.push(newItem);
      }
    });
    if (secondArr.length) {
      primaryArr = [...primaryArr, secondArr];
    }
    return primaryArr;
  },
);

export const getIsLoadingFeedbackPerMeasure = createSelector(
  getFeedbackPerMeasure,
  (i) => i.isLoading,
);

// Top Feedbackers
export const getTopFeedbackersData = createSelector(
  getFeedbackPerMeasure,
  (feedbackPerMeasure) => get(feedbackPerMeasure, 'data.topFeedbackers', []).filter((i) => i.name),
);

// Quick Feedback
export const getNoFeedbackAlerts = createSelector(
  getData,
  (data) => data.noFeedbackAlerts,
);

export const getNoFeedbackAlertsData = createSelector(
  getNoFeedbackAlerts,
  (noFeedbackAlerts) => {
    const newArr = [];
    if (noFeedbackAlerts.data && noFeedbackAlerts.data.alertGroups) {
      noFeedbackAlerts.data.alertGroups.forEach((alertGroup) => {
        alertGroup.alerts.forEach((alert) => {
          newArr.push({...alert});
        });
      });
    }
    return newArr;
  },
);

export const getNoFeedbackAlertsIsLoading = createSelector(
  getNoFeedbackAlerts,
  (noFeedbackAlerts) => noFeedbackAlerts.isLoading,
);
