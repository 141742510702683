// @flow
import React from 'react';
import {Modal} from 'react-bootstrap';
import HexagonalProgressBar from 'common/components/hexagon/HexagonalProgressBar';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import './ProgressModal.module.scss';

class ProgressModal extends React.PureComponent {
  props: {
    isOpen: boolean,
    onClose: Function,
    onAbort: Function,
    progress: number,
    fileName: string,
  };

  state = {
    isAbortConfirmModalOpen: false,
  };

  closeAbortConfirmModal = () => {
    this.setState({isAbortConfirmModalOpen: false});
  };

  openAbortConfirmModal = () => {
    if (this.props.progress === 100) {
      this.props.onClose();
    } else {
      this.setState({isAbortConfirmModalOpen: true});
    }
  };

  onConfirmAbortConfirmModal = () => {
    this.props.onAbort();
    this.props.onClose();
    // TODO: need add toast with message that file upload was aborted
  };

  render() {
    const {isOpen, onClose} = this.props;
    return (
      <div>
        <Modal show={isOpen} dialogClassName="bc file-upload-progress" onHide={onClose}>
          <Modal.Header bsClass="bc-modal-header">
            <Modal.Title>
              <span>Uploading File</span>
            </Modal.Title>
            <button
              className="btn btn-flat btn-icon-36 btn-secondary"
              onClick={this.openAbortConfirmModal}
              type="button"
            >
              <i className="icon icn-icon-table-delete" />
            </button>
          </Modal.Header>

          <Modal.Body>
            <HexagonalProgressBar percents={this.props.progress} />
            <span styleName="file-name">{this.props.fileName}</span>
          </Modal.Body>

          <Modal.Footer>
            <button
              className="btn btn-flat btn-primary"
              styleName="hex-cancel"
              onClick={this.openAbortConfirmModal}
              type="button"
            >
              <img
                src="../../../../../../../../../src/modules/andt.app/style/images/hexagon_cancel.png"
                alt="Cancel Button"
              />
            </button>
          </Modal.Footer>
        </Modal>
        {this.state.isAbortConfirmModalOpen && (
          <ConfirmationModal
            onClose={this.closeAbortConfirmModal}
            onConfirm={this.onConfirmAbortConfirmModal}
            title="Stop Uploading"
            message="Are you sure you want to stop uploading file?"
            isOpen={this.state.isAbortConfirmModalOpen}
          />
        )}
      </div>
    );
  }
}

export default ProgressModal;
