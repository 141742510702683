// @flow
import React from 'react';
import {connect} from 'react-redux';
import {isStreamEditable, isStreamRunning, excludeTemplatesNames} from 'bc/services/dataStreamService';
import {
  fetchStreamTemplates,
  applyTemplateOnSelectedStream as applyTemplateOnSelectedStreamAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import './GaTemplate.module.scss';

type PropTypes = {
  dataStream: Object,

  isLoading: boolean,
  templates: Array,
  selectedTemplate: Object,

  fetchStreamTemplates: Function,
  applyTemplateOnSelectedStream: Function,
};

class GaTemplates extends React.PureComponent {
  props: PropTypes;

  componentDidMount() {
    this.props.fetchStreamTemplates(this.props.dataStream.type);
  }

  render() {
    const {templates, isLoading, selectedTemplate, applyTemplateOnSelectedStream, dataStream} = this.props;

    let templatesArr = templates;
    if (dataStream.pollingInterval === 'm30' || dataStream.pollingInterval === 'm15') {
      templatesArr = templates.filter((item) => excludeTemplatesNames.indexOf(item.name) === -1);
    }

    const selectedIndex = templatesArr.findIndex((val) => (selectedTemplate ? selectedTemplate.id === val.id : null));
    const isEditable = isStreamEditable(dataStream.state) || isStreamRunning(dataStream.state);

    return (
      <div styleName="root">
        <SelectAndt
          id="gaTemplatesDdl"
          className="andt-dropdown"
          disabled={!isEditable}
          options={templatesArr}
          getOptionLabel={(val) => val.name}
          getOptionValue={(val) => val.id}
          type={TYPE_NO_SEARCH}
          theme={THEME_LIGHT}
          value={isLoading ? null : templatesArr[selectedIndex]}
          onChange={(option) => applyTemplateOnSelectedStream(option.id)}
          placeholder={isLoading ? 'Loading...' : 'Choose Template'}
          automationId="chooseTemplate"
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),

    isLoading: state.bc.googleAnalyticsStream.templates.isLoading,
    templates: selectors.getGoogleAnalyticsTemplatesItems(state),
    selectedTemplate: selectors.getGoogleAnalyticsQuerySelectedTemplate(state),
  }),
  {
    fetchStreamTemplates,
    applyTemplateOnSelectedStream: applyTemplateOnSelectedStreamAction,
  },
)(GaTemplates);
