import {createSelector} from 'reselect';
import {
  getUsersGroupsFilters,
  getSortedUserSectionUsersList,
  fetchGroupsData,
  getSelectedUsersCheckbox,
} from 'admin.users/store/selectors';
import {getCustomersData} from 'admin.customers/store/selectors';
import {allowedFilterKeys, DEFAULT_QUERY_PARAMS} from 'admin.users/services/usersAndGroupsService';
import {getAllowedRoles, isAnodot} from 'profile/store/selectors';
import {get, omitBy} from 'lodash';
import {THREE_STATES} from 'common/componentsV2/Checkbox';

const EMPTY_ARRAY = [];

const filterKeyHeaders = {
  searchQuery: {
    id: 'searchQuery',
    label: 'Search:',
  },
  status: {
    id: 'status',
    label: 'Status:',
  },
  groups: {
    id: 'groups',
    label: 'Groups',
  },
  customers: {
    id: 'customers',
    label: 'Customers:',
  },
  roles: {
    id: 'roles',
    label: 'Roles:',
  },
};

//* filters - searchQuery
export const getFiltersSearchQuery = createSelector(
  getUsersGroupsFilters,
  (filters) => filters.searchQuery,
);

//* filters - status
export const getFiltersStatus = createSelector(
  getUsersGroupsFilters,
  (filters) => filters.status,
);

//* filters - groups
export const getFiltersGroups = createSelector(
  getUsersGroupsFilters,
  (filters) => filters.groups,
);

//* filters - roles
export const getFiltersRoles = createSelector(
  getUsersGroupsFilters,
  (filters) => filters.roles,
);

//* filters - customers
export const getFiltersCustomers = createSelector(
  getUsersGroupsFilters,
  (filters) => filters.customers,
);

export const getChangedFilters = createSelector(
  getUsersGroupsFilters,
  (filters) => omitBy(filters, (value, key) => value === DEFAULT_QUERY_PARAMS[key]),
);

export const getFilterUserRolesWithCount = createSelector(
  getSortedUserSectionUsersList,
  getAllowedRoles,
  (userList, roles) => {
    if (!roles.length || !userList.length) {
      return EMPTY_ARRAY;
    }

    const rolesObgClone = roles.reduce((obj, item) => {
      // eslint-disable-next-line no-param-reassign
      obj[item.role] = {...item, count: 0};
      return obj;
    }, {});

    userList.forEach((user) => {
      if (rolesObgClone[user.userRole]) {
        rolesObgClone[user.userRole].count += 1;
      }
    });

    return Object.values(rolesObgClone);
  },
);

export const getSelectedFilterRoles = createSelector(
  getFilterUserRolesWithCount,
  getFiltersRoles,
  (roleList, role) => roleList.filter((item) => role && role.split(',').indexOf(item.role) !== -1),
);

export const getFilterGroupsWithCount = createSelector(
  getSortedUserSectionUsersList,
  fetchGroupsData,
  (userList, groups) => {
    if (!groups.length || !userList.length) {
      return EMPTY_ARRAY;
    }

    const groupsObgClone = groups.reduce((obj, item) => {
      // eslint-disable-next-line no-param-reassign
      obj[item.id] = {name: item.name, value: item.id, count: 0};
      return obj;
    }, {});

    userList.forEach((user) => {
      if (user.userGroups && user.userGroups.length) {
        user.userGroups.forEach((groupId) => {
          if (groupsObgClone[groupId]) {
            groupsObgClone[groupId].count += 1;
          }
        });
      }
    });

    return Object.values(groupsObgClone);
  },
);

export const getSelectedFilterGroups = createSelector(
  getFilterGroupsWithCount,
  getFiltersGroups,
  (groupsList, groups) => groupsList.filter((item) => groups && groups.split(',').indexOf(item.value) !== -1),
);

export const getSelectedFilterCustomers = createSelector(
  getCustomersData,
  getFiltersCustomers,
  (customersList, customers) =>
    customersList.filter((item) => customers && customers.split(',').indexOf(item._id) !== -1),
);

export const getFilteredUserSectionUsersList = createSelector(
  isAnodot,
  getSortedUserSectionUsersList,
  getUsersGroupsFilters,
  (isAndt, userList, filters) => {
    if (!userList.length) {
      return EMPTY_ARRAY;
    }

    return userList.filter((user) => {
      if (
        filters.searchQuery &&
        user.name.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) === -1 &&
        user.email.toLowerCase().indexOf(filters.searchQuery.toLowerCase()) === -1
      ) {
        return false;
      }

      if (
        (filters.status === allowedFilterKeys.status.enabled.value && user.status) ||
        (filters.status === allowedFilterKeys.status.disabled.value && !user.status)
      ) {
        return false;
      }

      if (filters.roles && filters.roles.split(',').indexOf(user.userRole) === -1) {
        return false;
      }

      if (
        !isAndt &&
        filters.groups &&
        !filters.groups.split(',').some((gr) => user.userGroups && user.userGroups.indexOf(gr) !== -1)
      ) {
        return false;
      }

      if (isAndt && filters.customers && filters.customers.split(',').indexOf(user.ownerOrganization) === -1) {
        return false;
      }

      return true;
    });
  },
);

export const getFilteredUserSectionUsersListCount = createSelector(
  getFilteredUserSectionUsersList,
  (userList) => userList.length,
);

export const inFiltersOnlyOneGroupSelected = createSelector(
  getChangedFilters,
  (cngFilters) =>
    Object.keys(cngFilters).length === 1 && cngFilters.groups && cngFilters.groups.split(',').length === 1
      ? cngFilters.groups
      : null,
);

const getGroupsOrCustomersTooltipValue = (list, labelKey) => {
  if (!list || !list.length) {
    return null;
  }

  let val = null;
  switch (list.length) {
    case 1:
      val = list[0][labelKey];
      break;
    case 2:
      val = `${list[0][labelKey]}, ${list[1][labelKey]}`;
      break;
    default:
      val = `${list[0][labelKey]}, ${list[1][labelKey]}, +${list[labelKey] - 2}`;
      break;
  }

  return val;
};

export const getFiltersButtonTooltipItems = createSelector(
  getChangedFilters,
  getSelectedFilterGroups,
  getSelectedFilterCustomers,
  isAnodot,
  (changedFilters, selectedGroups, selectedCustomers, isAndt) => {
    const filterItemsList = [];
    Object.keys(changedFilters).forEach((filterKey) => {
      const item = {
        id: get(filterKeyHeaders[filterKey], 'id', null),
        header: get(filterKeyHeaders[filterKey], 'label', null),
      };

      switch (filterKey) {
        case filterKeyHeaders.searchQuery.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey],
          });
          break;
        case filterKeyHeaders.status.id:
          filterItemsList.push({
            ...item,
            value: allowedFilterKeys[filterKey][changedFilters[filterKey]].label,
          });
          break;
        case filterKeyHeaders.groups.id:
          if (!isAndt) {
            filterItemsList.push({
              id: filterKeyHeaders.groups.id,
              header: filterKeyHeaders.groups.label,
              value: getGroupsOrCustomersTooltipValue(selectedGroups, 'name'),
            });
          }
          break;
        case filterKeyHeaders.customers.id:
          if (isAndt) {
            filterItemsList.push({
              id: filterKeyHeaders.customers.id,
              header: filterKeyHeaders.customers.label,
              value: getGroupsOrCustomersTooltipValue(selectedCustomers, 'name'),
            });
          }
          break;
        case filterKeyHeaders.roles.id:
          filterItemsList.push({
            ...item,
            value: changedFilters[filterKey],
          });
          break;
        default:
          break;
      }
    });

    return filterItemsList;
  },
);

export const getAllUsersCheckboxState = createSelector(
  getSelectedUsersCheckbox,
  getFilteredUserSectionUsersList,
  (selectedUsers, shownUsers) => {
    if (selectedUsers.length === shownUsers.length) {
      return THREE_STATES.FULL_CHECKED;
    }
    if (selectedUsers.length > 0 && selectedUsers.length < shownUsers.length) {
      return THREE_STATES.HALF_CHECKED;
    }
    return THREE_STATES.UNCHECKED;
  },
);
