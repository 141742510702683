import {composeReducers} from 'common/utils/reducers';
import {makeAsyncReducer} from 'common/utils/simplifiedAsync';
import {combineReducers} from 'redux';
import * as actions from '../actions';

const openAlerts = composeReducers(
  makeAsyncReducer(actions.getOpenAlerts, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const alertsDistribution = composeReducers(
  makeAsyncReducer(actions.getAlertsDistribution, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const metricsUsage = composeReducers(
  makeAsyncReducer(actions.fetchMetricsUsage, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const feedbackDistribution = composeReducers(
  makeAsyncReducer(actions.fetchFeedbackDistribution, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const alertsTriggered = composeReducers(
  makeAsyncReducer(actions.fetchAlertsTriggered, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const feedbackPerMeasure = composeReducers(
  makeAsyncReducer(actions.fetchFeedbackPerMeasure, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

const noFeedbackAlerts = composeReducers(
  makeAsyncReducer(actions.fetchNoFeedbackAlerts, {
    includeUpdateAt: true,
    shouldDestroyData: false,
  }),
);

export default combineReducers({
  openAlerts,
  alertsDistribution,
  metricsUsage,
  feedbackDistribution,
  alertsTriggered,
  feedbackPerMeasure,
  noFeedbackAlerts,
});
