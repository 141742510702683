// @flow
import React, {Fragment, useState} from 'react';
import PageLayout from 'common/componentsV2/PageLayout';
import {Box, makeStyles} from '@material-ui/core';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import * as selectors from '../store/selectors';
import UserEventsSources from './UserEventsSources';
import UserEventsCategories from './UserEventsCategories';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '800px',
    margin: '50px auto 0',
  },
  tab: {
    paddingBottom: '8px',
    marginRight: '32px',
    marginBottom: '24px',
    color: theme.palette.gray[600],
    fontWeight: 400,
    cursor: 'pointer',
  },
  active: {
    color: theme.palette.blue[500],
    borderBottom: `3px solid ${theme.palette.blue[500]}`,
  },
}));

const UserEvents = () => {
  const [tab, setTab] = useState(1);
  const classes = useStyles();

  const renderBody = () => {
    switch (tab) {
      case selectors.tabs[0].id:
        return <UserEventsSources />;
      case selectors.tabs[1].id:
        return <UserEventsCategories />;
      default:
        return '';
    }
  };

  return (
    <Fragment>
      <PageLayout shouldOverflowY>
        <Box className={classes.root}>
          {selectors.tabs.map((item) => (
            <TypographyBox
              key={item.id}
              variant="h1"
              className={`${classes.tab} ${tab === item.id ? classes.active : ''}`}
              onClick={() => setTab(item.id)}
            >
              {item.label}
            </TypographyBox>
          ))}
        </Box>
        {renderBody()}
      </PageLayout>
    </Fragment>
  );
};

export default UserEvents;
