// @flow
import React, {PureComponent} from 'react';
import './EditLiveStreamModal.module.scss';

type PropTypes = {
  title: String,
  children: Object,
};
export default class LiveStreamItemWrap extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <div styleName="vss-section-item">
        <div styleName="vss-section-sub-header">{this.props.title}</div>
        <div styleName="vss-section-text">{this.props.children}</div>
      </div>
    );
  }
}
