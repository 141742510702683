import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {get} from 'lodash';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

const fetchGoogleStorageProjects = makeAsyncEpic(actions.fetchGoogleStorageProjects, api.fetchGoogleStorageProjects);
const fetchGoogleStorageBuckets = makeAsyncEpic(actions.fetchGoogleStorageBuckets, api.fetchGoogleStorageBuckets);
const fetchGoogleStorageCsvFormatAnalysis = makeAsyncEpic(
  actions.fetchGoogleStorageCsvFormatAnalysis,
  api.fetchGoogleStorageCsvFormatAnalysis,
);
const fetchGoogleStoragePreview = makeAsyncEpic(actions.fetchGoogleStoragePreview, api.fetchGoogleStoragePreview);

const applyGoogleStorageStreamUiState = (action$, {getState}) =>
  action$
    .ofType(actions.applyGoogleStorageStreamUiState.TYPE)
    .switchMap(() => [selectors.getSelectedDataStream(getState())])
    .flatMap((stream) => [
      actions.fetchGoogleStorageCsvFormatAnalysis({
        projectId: stream.projectId,
        bucket: stream.bucket,
        dataSourceId: stream.dataSourceId,
        path: stream.path,
        fileNameSuffix: stream.fileNameSuffix,
        fileNamePrefix: stream.fileNamePrefix || '',
        fileNamePattern: stream.fileNamePattern || 'yyyyMMddHH',
      }),
      actions.setSelectedStreamKeyVal({
        dimensions: [],
        metrics: [],
        timeDefinition: {},
      }),
    ]);

const setGoogleStorageStreamAnalysisSchema = (action$) =>
  action$
    .ofType(actions.fetchGoogleStorageCsvFormatAnalysis.success.TYPE)
    .flatMap((action) => [actions.setGoogleStorageStreamDiametricsAnalysisSchema(action.payload)]);

const setGoogleStorageStreamUiStateDataValues = (action$, {getState}) =>
  action$
    .ofType(actions.setFileStreamDiametricsAnalysisSchema.TYPE)
    .switchMap(() => [selectors.getSelectedDataStream(getState())])
    .flatMap((stream) => [
      actions.setGoogleStorageStreamKeyVal({
        projectId: stream.projectId,
        bucket: stream.bucket,
        path: stream.path,
        fileNameSuffix: stream.fileNameSuffix,
        fileNamePrefix: stream.fileNamePrefix,
        fileNamePattern: stream.fileNamePattern,
      }),
    ]);

const setGoogleStorageStreamFileFormat = (action$, {getState}) =>
  action$.ofType(actions.setGoogleStorageStreamFileFormat.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());

    return [
      actions.fetchGoogleStorageCsvFormatAnalysis({
        dataSourceId: stream.dataSourceId,
        fileFormat: action.payload.fileFormat,
        projectId: stream.projectId,
        bucket: stream.bucket,
        path: stream.path,
        fileNameSuffix: stream.fileNameSuffix,
        fileNamePrefix: stream.fileNamePrefix || '',
        fileNamePattern: stream.fileNamePattern || 'yyyyMMddHH',
      }),
    ];
  });

const setGoogleStorageStreamProjectId = (action$, {getState}) =>
  action$.ofType(actions.setGoogleStorageStreamProjectId.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    return [
      actions.setGoogleStorageStreamKeyVal({
        uiState: {...stream.uiState, projectId: action.payload.projectId, bucket: null},
      }),
      actions.fetchGoogleStorageBuckets({dataSourceId: stream.dataSourceId, projectId: action.payload.projectId}),
    ];
  });

const fetchGoogleStorageProjectsSuccess = (action$, {getState}) =>
  action$.ofType(actions.fetchGoogleStorageProjects.success.TYPE).flatMap((action) => {
    const stream = selectors.getSelectedDataStream(getState());
    const projectId = get(stream.uiState, 'projectId', stream.projectId);
    if (action.payload && action.payload.length > 0) {
      if (!projectId) {
        return [actions.setGoogleStorageStreamProjectId({projectId: action.payload[0].projectId})];
      }
      return [actions.fetchGoogleStorageBuckets({dataSourceId: stream.dataSourceId, projectId})];
    }
    return [];
  });

const googleStorageEpic = combineEpics(
  setGoogleStorageStreamAnalysisSchema,
  setGoogleStorageStreamUiStateDataValues,
  setGoogleStorageStreamFileFormat,
  fetchGoogleStorageCsvFormatAnalysis,
  fetchGoogleStoragePreview,
  applyGoogleStorageStreamUiState,
  fetchGoogleStorageProjects,
  fetchGoogleStorageBuckets,
  fetchGoogleStorageProjectsSuccess,
  setGoogleStorageStreamProjectId,
);
export default googleStorageEpic;
