import React from 'react';
import {useDrop} from 'react-dnd';

type PropsType = {
  children: Node,
  itemData: Object,
  acceptType: string,
  landingIndicator: Node,
};

const DropZone = ({children, itemData, acceptType, landingIndicator}: PropsType) => {
  const [{isOver}, drop] = useDrop(() => ({
    accept: acceptType,
    drop: () => itemData,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop}>
      {children}
      <div style={{opacity: isOver ? '1' : '0'}}>{landingIndicator}</div>
    </div>
  );
};

export default DropZone;
