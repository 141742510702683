import React from 'react';
import {isEqual} from 'lodash';
import {useField} from 'react-final-form';

import DateRangesDdl, {THEME} from 'common/components/dateTime/DateRangesDdl';

const DateRanges = () => {
  const {
    input: {value, onChange},
  } = useField('dateRange', {isEqual});
  return <DateRangesDdl dateRange={value} onChange={onChange} theme={THEME.BLUE_LEAN} />;
};

export default DateRanges;
