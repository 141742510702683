import React from 'react';
import './SmallDropdownButtonMulti.module.scss';

type PropTypes = {
  value: Array,
  disable: boolean,
  modifier: string,
};

const SmallDropdownButtonMulti = (props: PropTypes) => {
  let buttonLabel = 'Select';
  if (props.value.length) {
    buttonLabel = (props.value || []).length > 1 ? `${props.value.length} Selected` : props.value;
  }
  return (
    <div className="form-button" styleName={['container', props.disable ? 'disable' : '', props.modifier].join(' ')}>
      <div styleName={['value', props.value.length > 0 ? 'with-selection' : ''].join(' ')}>{buttonLabel}</div>
      <div styleName="triangle" className="icon icn-arrow16-triangledown" />
    </div>
  );
};

export default SmallDropdownButtonMulti;
