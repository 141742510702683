// @flow
import React from 'react';
import './MultiButton.module.scss';
import MultiButtonUnit from './MultiButtonUnit';

type PropsType = {
  options: Array,
  value: Object,
  isDisabled: boolean,
  onChange: Function,
  automationId: string,

  labelKey?: string,
  valueKey?: string,
  colorKey?: string,
  extraClass?: string,
  extraStyleName?: string,
  title?: string,
};

const MultiButton = (props: PropsType) => {
  const {
    options,
    isDisabled,
    value,
    labelKey,
    valueKey,
    colorKey,
    automationId,
    extraClass,
    extraStyleName,
    title,
    onChange,
  } = props;

  const select = (key) => {
    onChange(key);
  };

  return (
    <div
      className={`${extraClass} ${extraStyleName || ''}`}
      styleName={`multi-button-wrapper ${extraStyleName || ''}`}
      automation-id={automationId}
    >
      {title && <div>{title}</div>}
      {options.map((item, index) => (
        <MultiButtonUnit
          automationId={item.automationId || `${automationId}_${index}`}
          value={item[labelKey]}
          color={item[colorKey]}
          isDisabled={isDisabled}
          onClick={() => select(item[valueKey])}
          isSelected={item[valueKey] === value}
          key={`${item[valueKey]}-MultiButton`}
        />
      ))}
    </div>
  );
};

MultiButton.defaultProps = {
  labelKey: 'label',
  valueKey: 'value',
  colorKey: 'color',
  extraClass: '',
  extraStyleName: '',
  title: '',
};

export default React.memo(MultiButton);
