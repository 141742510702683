import React, {PureComponent} from 'react';
import {components} from 'react-select2';

type PropTypes = {};

export default class MultiValueRemove extends PureComponent {
  props: PropTypes;

  render() {
    return (
      <div className="multi-value-remove">
        <components.MultiValueRemove {...this.props}>
          <div className="close-button">
            <div className="icon icn-general16-closeb" />
          </div>
        </components.MultiValueRemove>
      </div>
    );
  }
}
