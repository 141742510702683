// @flow
import React from 'react';
import './HexagonBorder.module.scss';

type PropTypes = {
  className: String,
  children: any,
};

const HexagonBorder = (props: PropTypes) => (
  <div styleName="outer-border" className={props.className ? props.className : ''}>
    <div styleName="inner-border">{props.children}</div>
  </div>
);

export default HexagonBorder;
