// @flow
import React from 'react';
import TabItem from './TabItem';
import './SideBarTabs.module.scss';

type PropTypes = {
  tabs: Array,
  onTabClick: Function,
  selectedTabId?: any,
  className?: String,
};

const EMPTY_ARRAY = [];

export default class SideBarTabs extends React.PureComponent {
  props: PropTypes;

  static defaultProps = {
    selectedTabId: null,
    className: '',
  };

  tabClicked = (tabId) => {
    this.props.onTabClick(tabId);
  };

  renderItems = () => {
    const {tabs, selectedTabId} = this.props;

    if (!tabs || !tabs.length) {
      return EMPTY_ARRAY;
    }

    return tabs.map((tab) => (
      <TabItem
        label={tab.label}
        isSelected={selectedTabId === tab.id}
        key={`tab-item-${tab.id}`}
        tabId={tab.id}
        onClick={this.tabClicked}
        titleBlock={tab.titleBlock}
        isVisible={tab.isVisible}
        marginTopTitle={tab.marginTopTitle}
      />
    ));
  };

  render() {
    return (
      <div styleName="root" className={this.props.className}>
        {this.renderItems()}
      </div>
    );
  }
}
