const getEditorValidationMessage = (stream, isAll = false) => {
  const isPristine = !stream.eventType;
  const res = [];
  if (!isPristine && (!stream.dimensions.length && !stream.metrics.length)) {
    res.push('At least one dimension or metric is required');
  }
  if (isPristine && !isAll) {
    res.push('Choose an event type');
  }

  if (isAll) {
    return res;
  }
  return res[0];
};

export default getEditorValidationMessage;
