// @flow

import React from 'react';
import {Popover} from 'react-bootstrap';
import SearchBox from 'common/components/SearchBox';

const PopoverItems = ({filter, onFilter, children, ...rest}: any) => (
  <Popover {...rest} id="popover-select">
    <SearchBox {...{filter, onFilter}} />
    {children}
  </Popover>
);

export default PopoverItems;
