import {useEffect, useState} from 'react';
import {debounce} from 'lodash';

const useHandleResize = (heightToConsider = [0]) => {
  const [bodyContainerHeight, setBodyContainerHeight] = useState(200);
  const handleResize = () => {
    const winClientHeight = window.document.body.clientHeight;
    const newBodyContainerHeight = winClientHeight - heightToConsider.reduce((a, b) => a + b);

    if (newBodyContainerHeight !== bodyContainerHeight) {
      setBodyContainerHeight(newBodyContainerHeight);
    }
  };
  const debouncedHandleResize = debounce(handleResize, 200);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  return bodyContainerHeight;
};

export default useHandleResize;
