import React from 'react';
import {useField} from 'react-final-form';
import Input from 'common/componentsV2/Input';

const validate = (value) => {
  return !value && 'SNS Topic ARN is mandatory';
};

const Topic = () => {
  const {
    input: {value, onChange, onBlur},
    meta,
  } = useField('topic', {validate});
  return (
    <div>
      <div>SNS Topic ARN</div>
      <Input
        placeHolder="arn:aws:sns:us-wes"
        automationId="topic"
        isInvalid={meta.touched && meta.error}
        invalidMessage={meta.error}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
    </div>
  );
};

export default Topic;
