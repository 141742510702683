// @flow
import React from 'react';
import CheckboxSection from '../components/CheckboxSection';
import './StyleGuideV2.module.scss';
import TooltipSection from '../components/TooltipSection';
import ButtonsSection from '../components/ButtonsSection';
import InputSection from '../components/InputSection';
import MultiButtonSection from '../components/MultiButtonSection';
import SnackbarSection from '../components/SnackbarSection';
import SwitchButtonSection from '../components/SwitchButtonSection';
import ModalSection from '../components/ModalSection';
import IconsSection from '../components/IconsSection';
import SpinnerSection from '../components/SpinnerSection';
import SeveritySection from '../components/SeveritySection';
import WarningSection from '../components/WarningSection';

export default class StyleGuideV2 extends React.Component {
  render() {
    return (
      <div styleName="root">
        <CheckboxSection />
        <TooltipSection />
        <ButtonsSection />
        <InputSection />
        <MultiButtonSection />
        <SnackbarSection />
        <SwitchButtonSection />
        <ModalSection />
        <SpinnerSection />
        <SeveritySection />
        <WarningSection />
        <IconsSection />
      </div>
    );
  }
}
