// @flow
import React from 'react';
import PageLayout from 'common/componentsV2/PageLayout';
import EmailDigestHeader from '../components/header/EmailDigestHeader';

import './EmailDigest.module.scss';

type PropTypes = {
  // connect
};

export default class EmailDigest extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <PageLayout header={<EmailDigestHeader />} shouldOverflowY>
        <div styleName="empty-state-container">
          <div>
            <p>Send Emails</p>
            <i className="icon icn-email-grey-9-icon" />
          </div>
        </div>
      </PageLayout>
    );
  }
}
