import {composeReducers} from 'common/utils/reducers';
import * as actions from '../actions';

const dataManager = composeReducers(
  (
    state = {
      isTimeZoneConfirmModalOpen: false,
      filterTextActual: '',
      filterTextInput: '',
      streamPreviewId: null,
      uploadFile: {
        uploadProgress: 0,
        fileName: '',
        fileUrl: '',
      },
    },
    {type, payload},
  ) => {
    switch (type) {
      case actions.setDataManagerFiltersIsOpen.TYPE:
        return {...state, isFiltersBarOpen: payload};
      case actions.updateDataMangerQueryParams.TYPE:
        return {...state, queryParams: payload};
      case actions.setDataManagerModalFilter.TYPE:
        return {...state, sourceFilter: payload};
      case actions.setDataManagerOpenedSources.TYPE:
        return {...state, openSources: payload};

      case actions.setIsTimeZoneConfirmModalOpen.TYPE:
        return {...state, isTimeZoneConfirmModalOpen: payload};
      case actions.setDataManagerFilterTextInput.TYPE:
        return {...state, filterTextInput: payload};
      case actions.setDataManagerFilterText.TYPE:
        return {...state, filterTextActual: payload};
      case actions.setDataManagerStreamPreviewId.TYPE:
        return {...state, streamPreviewId: payload};
      case actions.setFileUploadProgress.TYPE:
        return {...state, uploadFile: {...state.uploadFile, uploadProgress: payload}};
      case actions.setFileUploadName.TYPE:
        return {...state, uploadFile: {...state.uploadFile, fileName: payload, isLoading: !!payload}};
      case actions.setUploadedFileUrl.TYPE:
        return {...state, uploadFile: {...state.uploadFile, fileUrl: payload.s3url, isLoading: false}};
      case actions.createDataStream.success.TYPE:
        return {
          ...state,
          uploadFile: {
            fileName: '',
            fileUrl: '',
            uploadProgress: 0,
            isLoading: false,
          },
        };
      default:
        return state;
    }
  },
);

export default dataManager;
