// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import ConfirmationModal from 'common/components/modals/ConfirmationModal';
import {alertTypes} from 'alerts.management/services/alertsService';
import * as selectors from 'alerts.management/store/selectors';
import './AlertTypeCondition.module.scss';

type PropTypes = {
  alertType: String,
  isDirty: Boolean,
  onChangeType: Function,
};

class AlertTypeCondition extends PureComponent {
  props: PropTypes;

  state = {isModalOpen: false};

  handleChangeType = (type) => {
    if (this.props.isDirty) {
      this.setState({isModalOpen: true, type});
      return;
    }

    this.props.onChangeType(type);
  };

  closeModal = () => {
    this.setState({isModalOpen: false});
  };

  onConfirmModal = () => {
    this.props.onChangeType(this.state.type);
    this.setState({isModalOpen: false});
  };

  render() {
    return (
      <div styleName="main">
        {Object.values(alertTypes).map((t) => (
          <button
            type="button"
            key={t.value}
            automation-id={`alertSettings${t.value}Button`}
            className={`btn btn-raised btn-outline btn-outline-large ${
              t.value === this.props.alertType ? 'btn-outline-light' : 'btn-outline-dark'
            }`}
            onClick={() => this.handleChangeType(t.value)}
          >
            {t.text}
          </button>
        ))}

        <ConfirmationModal
          onClose={this.closeModal}
          onConfirm={this.onConfirmModal}
          title="Change Alert Type"
          message={
            <span>
              Some changes may be lost. <br />
              Do you wish to continue?
            </span>
          }
          isOpen={this.state.isModalOpen}
        />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    isDirty: selectors.simulationFiltersIsDirty(state).isPristine,
  }),
  {},
)(AlertTypeCondition);
