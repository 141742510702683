// @flow
import React from 'react';
import './UnitsSelect.module.scss';
import SelectAndt, {THEME_LIGHT, TYPE_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  onSearchValueChanged: Function,
  onChange: Function,
  isLoading: boolean,
  items: Array,
  defaultValue: Object,
};

class UnitsSelect extends React.PureComponent {
  props: PropTypes;

  state = {
    selectedValue: this.props.defaultValue ? this.props.defaultValue : '',
  };

  searchVal = '';

  onChange = (newValue) => {
    this.setState({selectedValue: newValue});
    this.props.onChange(newValue);
  };

  onClose = () => {
    this.searchVal = '';
    if (typeof this.props.onSearchValueChanged === 'function') {
      this.props.onSearchValueChanged(this.searchVal);
    }
  };

  render() {
    this.props.items.forEach((item) => {
      if (item.category) {
        // eslint-disable-next-line no-param-reassign
        item.headerClass = 'dropdown-no-icon';
      }
    });

    const selectedIndex = this.props.items.findIndex((val) => val === this.state.selectedValue);
    return (
      <div styleName="root" automation-id="unitsDropdown">
        <SelectAndt
          styleName="dropdown-selector"
          type={TYPE_SEARCH}
          theme={THEME_LIGHT}
          onChange={this.onChange}
          options={this.props.items}
          isLoading={this.props.isLoading}
          value={selectedIndex === -1 ? null : this.props.items[selectedIndex]}
          placeholder={
            this.state.selectedValue && this.state.selectedValue.catLabel ? this.state.selectedValue.catLabel : 'Select'
          }
          optionHeight={40}
          getOptionLabel={(val) => val.label}
          getOptionValue={(val) => val.label}
          menuListClass="unit-select"
          automationId="units"
        />
        <button
          type="button"
          styleName="clear-button"
          className="btn btn-flat btn-icon"
          disabled={selectedIndex === -1}
          onClick={() => this.onChange()}
        >
          <div className="icon icn-general16-closea" />
        </button>
      </div>
    );
  }
}

export default UnitsSelect;
