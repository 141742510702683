// @flow
import React from 'react';
import SearchableDdl from 'common/components/SearchableDdl';
import moment from 'moment';
import 'moment-timezone';
import './TimeZone.module.scss';

const BROWSER = 'Browser';
const clientTz = moment.tz.guess();
const timeZones = moment.tz.names().map((item) => ({
  label: item,
}));
timeZones.unshift({label: BROWSER});

type PropTypes = {
  timeZone: String,
  extraClassName: String,
  className: String,
  disabled: boolean,
  onChange: Function,
  theme: String,
};

const onSelectTimeZone = (tz, newTz, action) => {
  if (tz === newTz) {
    return;
  }

  if (newTz === BROWSER) {
    action({timeZone: clientTz});
  } else {
    action({timeZone: newTz});
  }
};

const TimeZone = (props: PropTypes) => {
  const timeZone = timeZones.find((val) => props.timeZone === val.label) || null;
  const selected = timeZone && timeZone.label ? timeZone : null;

  return (
    <div className={props.className || ''} styleName="root" automation-id="timeZone">
      <SearchableDdl
        automationId="timeZones"
        items={timeZones}
        disabled={props.disabled}
        extraClassName={props.extraClassName}
        value={selected}
        title="Choose time zone"
        dropDownTheme={props.theme ? props.theme : null}
        onChange={(newTz) => onSelectTimeZone(props.timeZone, newTz.label, props.onChange)}
      />
    </div>
  );
};

export default TimeZone;
