// @flow
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import ConfModal from 'common/componentsV2/modal/ConfModal';
import useAsyncAction from 'common/utils/useAsyncAction';
import * as actions from 'dashboards/store/actions';
import {getDeleteDashboard} from 'dashboards/store/selectors';
import useDashboardState from 'dashboards/hooks/useDashboardState';

const DashboardDeleteModal = React.memo(({onClose, dashboardId}: {onClose: Function, dashboardId: String}) => {
  const getDashboardData = useDashboardState(dashboardId);
  const dashboard = getDashboardData.data || {};
  const getDeleteDashboardSelector = useSelector(getDeleteDashboard);
  const deleteDashboard = useAsyncAction(actions.deleteDashboard, getDeleteDashboardSelector);
  const handleConfirm = useCallback(() => {
    deleteDashboard(null, {dashboardId, isV1: dashboard.isV1}).then(() => {
      onClose();
    });
  });
  return (
    <ConfModal
      isOpen
      onClose={onClose}
      onConfirm={handleConfirm}
      confirmText="Delete"
      title={`Delete ${dashboard.name}?`}
      isAnimation={false}
    >
      <div className="fontSize14 lineHeight_18">
        {`You are about to delete '${dashboard.name}'. Are you sure you want to proceed?`}
      </div>
    </ConfModal>
  );
});

export default DashboardDeleteModal;
