// @flow
import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import Loader from 'common/components/Loader';
import {fetchDataSourceTypes, fetchDataSourceIntegrationTypes, setFileUploadName} from 'bc/store/actions';
import {abortFileUpload} from 'bc/services/fileUploadToAwsService';
import NoData from 'common/components/NoData';
import * as selectors from '../../store/selectors';
import ProgressModal from './ProgressModal';
import GroupList from './GroupList';

import './DataSourceTypes.module.scss';

type PropTypes = {
  filteredIntegratedCollectors: Array,
  integratedDataSourceCategories: Array,
  fetchDataSourceTypes: Function,
  fetchDataSourceIntegrationTypes: Function,
  isLoading: boolean,

  fileName: string,
  progress: number,
  setFileUploadName: Function,
};

class DataSourceTypes extends PureComponent {
  props: PropTypes;

  componentDidMount() {
    this.props.fetchDataSourceTypes();
    this.props.fetchDataSourceIntegrationTypes();
  }

  closeProgressModal = () => {
    this.props.setFileUploadName('');
  };

  abortUpload = () => {
    abortFileUpload();
  };

  render() {
    const {filteredIntegratedCollectors, integratedDataSourceCategories, isLoading} = this.props;

    if (isLoading !== false && !filteredIntegratedCollectors.length) {
      return <Loader />;
    }

    return (
      <div styleName="root">
        {integratedDataSourceCategories
          ? integratedDataSourceCategories.map((sourceGroup) => {
              const catItems = filteredIntegratedCollectors.filter(
                (source) => source.displayCategory === sourceGroup.value,
              );

              return catItems.length ? (
                <GroupList
                  automation-id="dataCollectorSourceTypeGroup"
                  key={sourceGroup.value}
                  dataSourceTypes={catItems}
                  title={sourceGroup.displayText}
                />
              ) : null;
            })
          : null}

        {!filteredIntegratedCollectors.length && (
          <div styleName="no-items">
            <div styleName="main">No source types found</div>
            <div styleName="sub">Please refine your search</div>
            <NoData styleName="no-data-pos" text=" " />
          </div>
        )}

        {this.props.fileName ? (
          <ProgressModal
            onClose={this.closeProgressModal}
            onAbort={this.abortUpload}
            fileName={this.props.fileName}
            progress={this.props.progress}
            isOpen={this.props.fileName !== ''}
          />
        ) : null}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    filterText: selectors.getDataSourceTypesFilterText(state),
    filteredIntegratedCollectors: selectors.getFilteredIntegrationdDataSourceTypes(state),
    integratedDataSourceCategories: selectors.getIntegrationdDataSourceTypesCategories(state),
    isLoading: selectors.getIntegrationdDataSourceTypes(state).isLoading,

    fileName: selectors.getFileUploadName(state),
    progress: selectors.getFileUploadProgress(state),
  }),
  {
    fetchDataSourceTypes,
    fetchDataSourceIntegrationTypes,
    setFileUploadName,
  },
)(DataSourceTypes);
