// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {palette} from 'app/styles/theme';
import Spinner, {SIZES} from 'common/componentsV2/Spinner';
import EmptyListImageComponent from 'common/componentsV2/EmptyListImageComponent';
import IssueItem from 'topologyGeneral/components/sidePanel/issuesSubPanel/IssueItem';

const EmptyListIndication = () => (
  <Box
    display="flex"
    flexGrow={1}
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    height="100%"
    pt={6.25}
    pb={6.25}
    pr={0}
    pl={0}
  >
    <EmptyListImageComponent />
    <Box color="indigo.500" fontWeight={500} fontSize={18} width="100%" mt={1} textAlign="center" pr={0.75}>
      No Issues
    </Box>
  </Box>
);

const LoadingIndication = () => (
  <Box
    display="flex"
    flexGrow={1}
    alignItems="center"
    justifyContent="center"
    flexDirection="column"
    height="100%"
    pt={6.25}
    pb={6.25}
    pr={0}
    pl={0}
  >
    <Spinner color={palette.gray['500']} size={SIZES.BIG_60} />
  </Box>
);

const IssuesList = ({issues, isLoading, timeZoneName, textHighlight, onIssueItemClicked}: PropTypes) => {
  const locClick = (issue) => {
    onIssueItemClicked(issue);
  };

  if (isLoading) {
    return <LoadingIndication />;
  }

  if (!issues.length) {
    return <EmptyListIndication />;
  }

  return issues.map((issue) => (
    <IssueItem
      onClick={locClick}
      item={issue}
      key={issue.id}
      textHighlight={textHighlight}
      timeZoneName={timeZoneName}
    />
  ));
};

IssuesList.propTypes = {
  issues: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  timeZoneName: PropTypes.string,
  textHighlight: PropTypes.string,
  onIssueItemClicked: PropTypes.func.isRequired,
};

IssuesList.defaultProps = {
  issues: [],
  isLoading: false,
  timeZoneName: '',
  textHighlight: '',
};

export default IssuesList;
