// @flow
import React, {Fragment} from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {IMPACT_DEFAULT_VALUES} from 'impact/services/constants';
import Measure from 'impact/impactSettings/Measure';
import FactorSelectorSlider from 'impact/impactSettings/FactorSelectorSlider';
import FactorSelectorInput from 'impact/impactSettings/FactorSelectorInput';
import CurrencySelector from 'impact/impactSettings/CurrencySelector';
import SpikeSelector from 'impact/impactSettings/SpikeSelector';
import DropSelector from 'impact/impactSettings/DropSelector';
import ImpactSumLight from 'impact/impactSettings/ImpactSumLight';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Button, {COLORS, HEIGHTS} from 'common/componentsV2/Button';
import {makeStyles} from '@material-ui/core/styles';
import {palette} from 'app/styles/theme';

type PropTypes = {
  impact: Object,
  alert: Object,
  timeZone: String,
  onSetValue: Function,
  onRemoveImpact: Function,
  onSubmitImpact: Function,
  onDismiss: Function,
  onNoAlertLink: Function,
};

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 264,
    width: 715,
    borderRadius: 10,
    border: `solid 2px ${palette.gray['300']}`,
    padding: '16px 16px 12px 16px',
    marginBottom: 16,
  },
  removeBtn: {
    color: palette.gray['400'],
    cursor: 'pointer',
    '&:hover': {
      color: palette.gray['500'],
    },
  },
}));

const ImpactListItem = (props: PropTypes) => {
  const classes = useStyles();
  const {id, measure, factor, currency, spike, drop} = props.impact;

  return (
    <Fragment>
      <Grid container justify="space-between" className={classes.wrapper}>
        <Grid container item justify="space-between">
          {/* Left Side */}
          <Grid item sm={6}>
            <Box display="flex" alignItems="center" mb={2}>
              <TypographyBox variant="subtitle1" component="span">
                Set the Impact value of
              </TypographyBox>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
              <Box>
                <Measure measure={measure} />
              </Box>
              <Box display="flex" alignItems="center">
                <Box mr={1} style={{height: '32px'}}>
                  <FactorSelectorInput value={factor} setValue={props.onSetValue} />
                </Box>
                <Box>
                  <CurrencySelector value={currency} setValue={props.onSetValue} />
                </Box>
              </Box>
            </Box>

            <Box mb={0.5}>
              <FactorSelectorSlider value={factor} setValue={props.onSetValue} />
            </Box>

            <Box>
              <TypographyBox variant="subtitle3" mb={0.5}>
                Direction Behavior
              </TypographyBox>

              <Box display="flex" alignItems="center">
                <TypographyBox component="span" variant="subtitle1" mr={0.5}>
                  Spikes are:{' '}
                </TypographyBox>
                <SpikeSelector value={spike} setValue={props.onSetValue} />
                <Box component="span" mr={1} />
                <TypographyBox component="span" variant="subtitle1" mr={0.5}>
                  Drops are:{' '}
                </TypographyBox>
                <DropSelector value={drop} setValue={props.onSetValue} />
              </Box>
            </Box>
          </Grid>

          {/* Right Side */}
          <Grid container item sm={6} justify="flex-end">
            <ImpactSumLight
              alert={props.alert}
              impact={props.impact}
              timeZoneName={props.timeZone}
              onNoAlertLink={props.onNoAlertLink}
            />
          </Grid>
        </Grid>

        <Grid container item justify="space-between" style={{marginTop: 'auto'}}>
          {/* Bottom right Side */}
          <Grid alignItems="center" container item sm={6}>
            {id !== IMPACT_DEFAULT_VALUES.id && (
              <TypographyBox
                onClick={props.onRemoveImpact}
                className={classes.removeBtn}
                component="span"
                variant="subtitle1"
              >
                Remove Impact Value
              </TypographyBox>
            )}
          </Grid>
          {/* Bottom Left Side */}
          <Grid container item sm={6} justify="flex-end">
            <Button colorSchema={COLORS.GRAY_400} height={HEIGHTS.REGULAR} text="Not Now" onClick={props.onDismiss} />
            <Box ml={1.5}>
              <Button
                colorSchema={COLORS.BLUE_500}
                height={HEIGHTS.REGULAR}
                text="Set Impact Value"
                onClick={props.onSubmitImpact}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ImpactListItem;
