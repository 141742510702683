// @flow
import React, {useCallback, useMemo, useRef, useState} from 'react';
import {Editor} from 'react-draft-wysiwyg';
import {ContentState, EditorState} from 'draft-js';
import ColorPicker, {COLOR_NAME, COLORS_PICKER} from 'common/componentsV2/ColorPicker';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './TextEditor.scss';
import {palette} from 'app/styles/theme';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';

const optionsColor = [
  COLOR_NAME.EUCALIPTUS,
  COLOR_NAME.YELLOW_400,
  COLOR_NAME.ORANGE_400,
  COLOR_NAME.RED_400,
  COLOR_NAME.GRAY_400,
  COLOR_NAME.BLUE,
  COLOR_NAME.TOMATO,
  COLOR_NAME.LILACH_600,
  COLOR_NAME.EGGPLANT,
  COLOR_NAME.GRAY,
];

const optionsFontSize = [
  {
    value: 12,
    label: 'Small',
  },
  {
    value: 14,
    label: 'Normal',
  },
  {
    value: 18,
    label: 'Large',
  },
];

type PropsComponent = {
  currentState: Object,
  onChange: Function,
  expanded: boolean,
  onExpandEvent: Function,
};
export const createEditorState = (content) => EditorState.createWithContent(content);
export const createContentStateFromText = (content) => ContentState.createFromText(content);

const colorToHex = (value) => palette[COLORS_PICKER[value].color][COLORS_PICKER[value].contrast];
const colorToPicker = (value) => optionsColor.find((o) => COLORS_PICKER[o].color === value) || COLOR_NAME.BLACK;

const button = ({
  title,
  icon,
  onExpandEvent,
  expanded,
  isWrapper,
}: {
  title: string,
  icon: string,
  onExpandEvent: Function,
  expanded: boolean,
  isWrapper: boolean,
}) => (
  <div className={`${isWrapper ? 'textEditor-option-wrapper' : ''}`} onClick={onExpandEvent}>
    <div className={`textEditor-option ${expanded ? 'active' : ''} icon ${icon}`} title={title} />
  </div>
);

const ColorComponent = ({currentState, onChange, expanded, onExpandEvent}: PropsComponent) => {
  const [localColor, setLocalColor] = useState(colorToPicker(currentState.color));

  const handleChangeColor = useCallback((color) => {
    setLocalColor(color);
    onChange('color', colorToHex(color));
  }, []);

  return (
    <ColorPicker
      value={localColor}
      onChange={handleChangeColor}
      options={optionsColor}
      menuWidth={129}
      customButton={button({
        title: 'Color Picker',
        icon: 'icn-format_color_text_black_24dp2x1',
        expanded,
        onExpandEvent,
        isWrapper: true,
      })}
    />
  );
};

const FontSizeComponent = ({currentState, onChange, expanded, onExpandEvent}: PropsComponent) => {
  const handleChange = useCallback((fontSize) => {
    onChange(fontSize.value);
  }, []);

  const selected = useMemo(() => optionsFontSize.find((o) => o.value === currentState.fontSize), [currentState]);

  return (
    <FormDdlSelect
      button={button({title: 'Font Size', expanded, onExpandEvent, icon: 'icn-format_size_black_24dp2x1'})}
      options={optionsFontSize}
      selected={selected}
      optionComponent={<OptionComponentSimple />}
      onChange={handleChange}
      width={180}
      maxWidth={180}
    />
  );
};

type PropTypes = {
  value: string,
  onChange: Function,
  placeholder: string,
};

const toolbarConfig = {
  options: ['inline', 'colorPicker', 'fontSize', 'textAlign', 'list', 'link', 'emoji'],
  inline: {
    className: 'textEditor-option-wrapper textEditor-divider',
    options: ['bold', 'italic', 'underline'],
    bold: {icon: null, className: 'textEditor-option icon icn-format_bold_black_24dp2x1'},
    italic: {icon: null, className: 'textEditor-option icon icn-format_italic_black_24dp2x1'},
    underline: {icon: null, className: 'textEditor-option icon icn-format_underlined_black_24dp2x1'},
  },
  colorPicker: {
    className: 'textEditor-option-wrapper',
    component: ColorComponent,
  },
  fontSize: {
    component: FontSizeComponent,
  },
  textAlign: {
    className: 'textEditor-option-wrapper textEditor-divider-left textEditor-divider',
    options: ['left', 'center', 'right'],
    left: {icon: null, className: 'textEditor-option icon icn-format_align_left_black_24dp2x1'},
    center: {icon: null, className: 'textEditor-option icon icn-format_align_center_black_24dp2x1'},
    right: {icon: null, className: 'textEditor-option icon icn-format_align_right_black_24dp2x1'},
  },
  list: {
    className: 'textEditor-option-wrapper textEditor-divider',
    options: ['unordered'],
    unordered: {icon: null, className: 'textEditor-option icon icn-format_list_bulleted_black_24dp2x1'},
  },
  link: {
    className: 'textEditor-option-wrapper textEditor-divider',
    popupClassName: 'textEditor-link-popup',
    defaultTargetOption: '_blank',
    options: ['link', 'unlink'],
    link: {icon: null, className: 'textEditor-option icon icn-insert_link_black_24dp2x1'},
    unlink: {icon: null, className: 'textEditor-option icon icn-link_off_black_24dp2x1'},
  },
  emoji: {
    icon: null,
    className: 'textEditor-option-wrapper textEditor-option icon icn-insert_emoticon_black_24dp2x1',
  },
};

// value -> means EditorState Object from 'draft-js'
const TextEditor = ({value, onChange, placeholder}: PropTypes) => {
  const [isFocus, setIsFocus] = useState();
  const editorRef = useRef();

  const setEditorRef = (ref) => {
    editorRef.current = ref;
  };

  const handleFocus = useCallback(() => {
    setIsFocus(true);
    if (editorRef.current) {
      editorRef.current.focus();
    }
  }, [editorRef]);

  const handleBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  return (
    <div onClick={handleFocus}>
      <Editor
        editorRef={setEditorRef}
        editorState={value}
        onEditorStateChange={onChange}
        wrapperClassName={`textEditor-wrapper ${isFocus ? 'focus' : ''}`}
        toolbarClassName="textEditor-toolbar"
        editorClassName="textEditor-text-area"
        toolbar={toolbarConfig}
        onBlur={handleBlur}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextEditor;
