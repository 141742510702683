import {cloneDeep, defaultsDeep, find, assignIn, get} from 'lodash';
import {getUniqueId} from '../guid';
import getEmptyExpression from './getEmptyExpression';

const defaultTopValue = 10;

function getDefaultSortFunction(topOptions) {
  const defaultSortFunction = defaultsDeep(
    {
      function: 'alphanumeric',
      parameters: [{name: 'Top N', value: defaultTopValue}],
    },
    /* jslint latedef:false */ getEmptyFunction(),
  );

  const res = cloneDeep(defaultSortFunction);
  find(res.parameters, {name: 'Top N'}).value = get(topOptions, 'defaultValue', defaultTopValue);
  return res;
}

function getEmptyFunction(treeId) {
  const res = {
    children: [],
    description: '',
    function: '',
    id: getUniqueId(),
    parameters: [],
    type: 'function',
  };
  if (treeId) {
    res.treeId = treeId;
  }
  return res;
}

function getEmptyTree(id, expressionTreeModelDefaults, topOptions, context, newRoot) {
  const root = newRoot || getEmptyExpression();
  return assignIn(
    {},
    {
      compositeCount: 0,
      displayOnly: true,
      expressionTree: {
        root,
      },
      filter: getDefaultSortFunction(topOptions),
      id: id || getUniqueId(),
      name: {
        auto: true,
        prefix: null,
      },
      scalarTransforms: [defaultsDeep({function: 'current'}, getEmptyFunction())],
    },
    expressionTreeModelDefaults,
  );
}

export default getEmptyTree;
