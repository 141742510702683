// @flow
import React from 'react';
import PropTypes from 'prop-types';
import {Box} from '@material-ui/core';
import {useTooltipStyles, MAP_ITEMS_COLOR_PALETTE} from 'topologyGeneral/services/mapService';

const LinkTooltip = ({item}: PropTypes) => {
  const classes = useTooltipStyles();

  return (
    <Box className="gtp-tp-wrapper" pt={1.5} pb={1.5} pl={2} pr={2} borderRadius="6px">
      <Box display="flex">
        <Box component="span" className={classes.title14Bold} mr={0.5}>
          {item.name}
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.linkItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Node source
            </Box>
            {item.sourceNodeName ? (
              <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
                <Box
                  className={classes.statusIndicator}
                  bgcolor={item.isSourceNodeError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
                  mr={0.5}
                >
                  &nbsp;
                </Box>
                <Box component="span" className={classes.title14Normal}>
                  {item.sourceNodeName}
                </Box>
              </Box>
            ) : (
              <Box component="span" className={classes.title14Normal}>
                -
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.linkItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Node destination
            </Box>
            {item.destNodeName ? (
              <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
                <Box
                  className={classes.statusIndicator}
                  bgcolor={item.isDestNodeError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
                  mr={0.5}
                >
                  &nbsp;
                </Box>
                <Box component="span" className={classes.title14Normal}>
                  {item.destNodeName}
                </Box>
              </Box>
            ) : (
              <Box component="span" className={classes.title14Normal}>
                -
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.linkItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Interface source
            </Box>
            {item.sourceInterfaceName ? (
              <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
                <Box
                  className={classes.statusIndicator}
                  bgcolor={item.isSourceInterfaceError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
                  mr={0.5}
                >
                  &nbsp;
                </Box>
                <Box component="span" className={classes.title14Normal}>
                  {item.sourceInterfaceName}
                </Box>
              </Box>
            ) : (
              <Box component="span" className={classes.title14Normal}>
                -
              </Box>
            )}
          </Box>
        </Box>
        <Box className={classes.linkItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Interface destination
            </Box>
            {item.destInterfaceName ? (
              <Box component="span" className={classes.title14Normal} display="flex" alignItems="center">
                <Box
                  className={classes.statusIndicator}
                  bgcolor={item.isDestInterfaceError ? MAP_ITEMS_COLOR_PALETTE.red : MAP_ITEMS_COLOR_PALETTE.green}
                  mr={0.5}
                >
                  &nbsp;
                </Box>
                <Box component="span" className={classes.title14Normal}>
                  {item.destInterfaceName}
                </Box>
              </Box>
            ) : (
              <Box component="span" className={classes.title14Normal}>
                -
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" mt={1}>
        <Box className={classes.linkItemWrapper} pr={0.75} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Status
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {item.status || '-'}
            </Box>
          </Box>
        </Box>
        <Box className={classes.linkItemWrapper} pr={0} flexGrow={1}>
          <Box display="flex" flexDirection="column">
            <Box component="span" className={classes.title12BoldLight}>
              Type
            </Box>
            <Box component="span" className={classes.title14Normal}>
              {item.type || '-'}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

LinkTooltip.propTypes = {
  item: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default LinkTooltip;
