import {useCallback} from 'react';
import Highcharts from 'highcharts';

const useCrosshairSync = (chartIds) =>
  useCallback(
    (data, currentChartId) => {
      chartIds.forEach((chartId) => {
        const hchart = Highcharts.charts.find((chart) => chart && chart.renderTo.id === chartId);
        if (hchart && currentChartId !== chartId) {
          if (data) {
            const plotX = hchart.xAxis[0].translate(data.xValue, 0, 0, 0, 1, undefined, false);
            hchart.xAxis[0].drawCrosshair(null, {plotX, plotY: 0});
          } else {
            hchart.xAxis[0].hideCrosshair();
          }
        }
      });
    },
    [chartIds],
  );

export default useCrosshairSync;
