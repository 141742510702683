import React from 'react';
import * as dateRangeService from 'common/utils/dateRangeService';
import {useField} from 'react-final-form';
import TimeScaleSelector from 'common/componentsV2/TimeScaleSelector';
import './TimeScaleSelector.module.scss';

type PropTypes = {
  providedResolution: string,
};

const autoRangesMap = {
  short: '1d',
  medium: '3d',
  long: '1w',
  longlong: '1m',
  weekly: '3m',
};

const TimeScaleSelectorField = ({providedResolution}: PropTypes) => {
  const {
    input: {value, onChange},
  } = useField('timeScale');

  const {
    input: {onChange: onChangeDateRange},
  } = useField('dateRange');

  const {
    input: {value: isAutoDateRange},
  } = useField('isAutoDateRange');

  const onMultiButtonChange = (val) => {
    onChange(val);
    if (isAutoDateRange) {
      onChangeDateRange(dateRangeService.getDateValue(autoRangesMap[val]));
    }
  };

  return <TimeScaleSelector providedResolution={providedResolution} onChange={onMultiButtonChange} value={value} />;
};

export default TimeScaleSelectorField;
