/* eslint-disable no-param-reassign */
import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {getUniqueId} from 'common/utils/guid';
import * as actions from '../actions';
import * as api from '../../services/api';
import * as selectors from '../selectors';

import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/filter';
import 'rxjs/add/observable/of';

const fetchMParticleAnoToken = makeAsyncEpic(actions.fetchMParticleAnoToken, api.fetchMParticleAnoToken);
const fetchMParticleEventTypes = makeAsyncEpic(actions.fetchMParticleEventTypes, api.fetchMParticleEventTypes);
const fetchMParticleEventMetadata = makeAsyncEpic(actions.fetchMParticleEventMetadata, api.fetchMParticleEventMetadata);

const setMParticaleStreamEventType = (action$, {getState}) =>
  action$.ofType(actions.setMParticleStreamEventType.TYPE).flatMap((action) => [
    actions.fetchMParticleEventMetadata({
      dataSourceId: selectors.getSelectedDataStream(getState()).dataSourceId,
      eventType: action.payload.eventType,
    }),
  ]);

const fetchMParticleEventMetadataSuccess = (action$) =>
  action$.ofType(actions.fetchMParticleEventMetadata.success.TYPE).flatMap((action) => {
    const res = {
      schema: {
        columns: [],
        sourceColumns: [],
      },
      metrics: [],
      dimensions: [],
      uiState: {
        eventMetadata: action.payload,
        unAssignedColumns: [],
      },
    };
    action.payload.dimensions.forEach((dim) => {
      dim.possibleTypes = ['dimension'];
      res.uiState.unAssignedColumns.push(dim.path);
    });
    action.payload.metrics.forEach((metric) => {
      metric.possibleTypes = ['metric'];
      res.schema.columns.push({
        id: getUniqueId(),
        sourceColumn: metric.path,
        name: metric.name,
        type: 'metric',
      });
      res.schema.sourceColumns.push({
        id: metric.path,
        path: metric.path,
      });
      res.metrics.push(metric.path);
    });

    return [actions.setSelectedStreamKeyVal(res)];
  });

const mParticleEpic = combineEpics(
  fetchMParticleAnoToken,
  fetchMParticleEventTypes,
  fetchMParticleEventMetadata,
  setMParticaleStreamEventType,
  fetchMParticleEventMetadataSuccess,
);
export default mParticleEpic;
