import GoogleOAuth2 from 'google-oauth2-web-client';
import {bcTypes} from 'bc/services/bcTypes';
import {get} from 'lodash';

export const doBqAuthFlow = (clientId, createDataSource, me) => {
  let locOrigin = window.location.origin;
  if (locOrigin.indexOf('localhost:') === -1) {
    const baseDnsName = me.baseDNSName === 'anodot-test.com' ? 'ano-dev.com' : me.baseDNSName;
    locOrigin = `https://${me.clusterDNSPrefix}.${baseDnsName}`;
  }

  const go2 = new GoogleOAuth2({
    clientId,
    redirectUri: `${locOrigin}/api/v1/bc/signin/oauth/google/callback`,
    scope:
      // eslint-disable-next-line max-len
      'https://www.googleapis.com/auth/bigquery https://www.googleapis.com/auth/compute.readonly https://www.googleapis.com/auth/devstorage.read_only https://www.googleapis.com/auth/cloud-platform.read-only',
    responseType: 'code',
    accessType: 'offline',
    state: me.ownerOrganization || 'no-org-id',
    popupHeight: 600,
    popupWidth: 600,
  });
  go2.login(true, false);

  window.createDataSource = function(token) {
    createDataSource({
      code: token,
      type: bcTypes.bigquery.type,
      name: `BigQuery Data Source ${new Date().getTime().toString()}`,
      authenticationType: 'refresh_token',
      redirectURI: `${locOrigin}/api/v1/bc/signin/oauth/google/callback`,
    });
  };
};

export const getEditorValidationMessage = (stream, isAll = false) => {
  const isPristine = !get(stream, 'uiState.queryPreviewColumns');
  const res = [];
  if (!stream.projectId && !isAll) {
    return 'Project is mandatory';
  }
  if (!stream.query && !isAll) {
    return 'Valid query is mandatory';
  }
  if (!stream.metrics.length && !isAll) {
    return 'At least one measure is required';
  }
  if (!stream.dimensions.length && !isAll) {
    return 'At least one dimension is required';
  }
  if (!stream.timestampColumn && !isAll) {
    return 'Timestamp definition is required';
  }
  if (!isPristine && !stream.dimensions.length) {
    res.push('At least one dimension is required');
  }
  if (!isPristine && !stream.metrics.length) {
    res.push('At least one measure is required');
  }
  if (!isPristine && !stream.timestampColumn) {
    res.push('Timestamp definition is required');
  }

  if (isAll) {
    return res;
  }
  return res[0];
};
