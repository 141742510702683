import {get, post, delete_, put} from 'common/utils/http';

const root = 'api/v1/users/';
const customerRoot = 'api/v1/customers/';

export const impersonate = ({payload}) => post('api/v1/impersonate', payload);
export const unimpersonate = () => post('api/v1/unimpersonate');

export const fetchUsers = () => get(root);
export const fetchUser = ({payload}) => get(`${root}${payload}`);
export const updateUser = ({payload}) => put(`${root}${payload._id}`, payload);
export const deleteUser = ({payload}) =>
  delete_(`${root}${payload.userId}?replacementUserId=${payload.delegateUserId}`);
export const updateUserStatus = ({payload}) => put(`${root}${payload.userId}/status`, payload.body);
export const resetPasswordRequest = ({payload}) => post('api/v1/password/request/reset', payload);
export const editUsersBulkApi = ({payload}) => put(`${root}bulk`, payload);
export const deleteUsersBulkApi = ({payload}) =>
  post(`${root}bulk/delete`, {userIds: payload.userIds, replacementUserId: payload.delegateUserId});

export const createUsers = ({payload}) => post(`${customerRoot}create-users`, payload);

export const fetchGroups = ({payload}) => get(`${customerRoot}${payload.ownerOrganization}/groups`);
export const createGroup = ({payload}) => post(`${customerRoot}${payload.ownerOrganization}/groups`, payload.group);
export const addGroupsToUser = ({payload}) =>
  post(`${customerRoot}${payload.ownerOrganization}/groups/user/${payload.userId}`, payload.body);
export const updateGroup = ({payload}) =>
  put(`${customerRoot}${payload.ownerOrganization}/groups/${payload.group.id}`, payload.group);
export const deleteGroup = ({payload}) =>
  delete_(
    `${customerRoot}${payload.ownerOrganization}/groups/${payload.groupId}?replacementId=${payload.delegateUserId}`,
  );

export const fetchOwnerDashboardsCount = ({payload}) =>
  get(`api/v1/dashboards/total?schemaType=dashboards&ownerIds=${payload}`);
export const fetchOwnerAnoboardsCount = ({payload}) =>
  get(`api/v1/dashboards/total?schemaType=anoboards&ownerIds=${payload}`);
export const fetchOwnerAlertsCount = ({payload}) => get(`api/v1/alerts/total?ownerIds=${payload}`);
export const fetchOwnerChannelsCount = ({payload}) => get(`api/v1/channels/total?ownerIds=${payload}`);
export const fetchOwnerCompositesCount = ({payload}) => get(`api/v1/metrics/composite/total?ownerIds=${payload}`);
export const fetchOwnerRefreshTokensCount = ({payload}) => get(`api/v1/refresh-tokens/total?ownerIds=${payload}`);
export const fetchOwnerStreamsCount = ({payload}) => get(`api/v1/bc/data-streams/total?ownerIds=${payload}`);
