import SelectAndt, {THEME_BLUE_LEAN_MODIFIED, TYPE_NEW_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import React from 'react';

type PropTypes = {
  value: string,
  onChange: Function,
  placeholder: string,
};

const definitions = [
  {value: 1, label: '1 metric'},
  {value: 5, label: '5 metrics'},
  {value: 10, label: '10 metrics'},
  {value: 20, label: '20 metrics'},
  {value: 30, label: '30 metrics'},
  {value: 40, label: '40 metrics'},
  {value: 50, label: '50 metrics'},
];

const Show = ({onChange, value, placeholder}: PropTypes) => (
  <SelectAndt
    id="showNumber"
    automationId="show"
    extraClassName="alerts-dropdown-btn"
    options={definitions}
    optionHeight={40}
    menuWidth={170}
    type={TYPE_NEW_NO_SEARCH}
    theme={THEME_BLUE_LEAN_MODIFIED}
    onChange={(option) => onChange(option.value)}
    value={value && definitions.find((item) => item.value === value)}
    placeholder={placeholder || 'Auto'}
  />
);

export default Show;
