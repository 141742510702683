// @flow
import React from 'react';
import './EventExpressionBuilderDisplayContainer.module.scss';
import EventExpressionBuilder from './EventExpressionBuilder';
import EventsListing from './EventsListing';

type PropTypes = {};

export default class EventExpressionBuilderDisplayContainer extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <div styleName="container">
        <div styleName="expression-builder-container">
          <EventExpressionBuilder isExecuteOnUpdate />
        </div>
        <div styleName="preview">
          <EventsListing />
        </div>
      </div>
    );
  }
}
