// @flow
import React from 'react';
import {get, isObject} from 'lodash';
import Modal, {SIZES} from 'common/componentsV2/modal/Modal';
import TypographyBox from 'common/componentsV2/boxTools/TypographyBox';
import {Box} from '@material-ui/core';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import IconTabsBar from 'common/componentsV2/IconTabsBar';
import {Field, Form} from 'react-final-form';
import {ReactComponent as Title} from 'dashboards/images/titleTypeTextTile.svg';
import {ReactComponent as TitleActive} from 'dashboards/images/titleTypeTextTileActive.svg';
import {ReactComponent as Note} from 'dashboards/images/noteTypeTextTile.svg';
import {ReactComponent as NoteActive} from 'dashboards/images/noteTypeTextTileActive.svg';
import Input from 'common/componentsV2/Input';
import useAsyncAction from 'common/utils/useAsyncAction';
import {updateDashboard} from 'dashboards/store/actions';
import {OPTION_TILES_VALUES} from 'dashboards/services/dashboardService';
import {findFreeSpace, makeTextTilePayload, makeUpdateTilePayload} from 'dashboards/utils';
import AsyncButton from 'common/componentsV2/AsyncButton';
import useDashboardState from 'dashboards/hooks/useDashboardState';
import TextEditor, {createContentStateFromText, createEditorState} from 'common/componentsV2/textEditor/TextEditor';
import Button from 'common/componentsV2/Button';

const isRequired = (val) => val === undefined;

export const TEXT_TILES_VALUE = {
  TITLE: 'title',
  NOTE: 'note',
};

const TEXT_TILES_ITEMS = [
  {
    label: 'Title',
    value: TEXT_TILES_VALUE.TITLE,
    onImage: TitleActive,
    offImage: Title,
  },
  {
    label: 'Note',
    value: TEXT_TILES_VALUE.NOTE,
    onImage: NoteActive,
    offImage: Note,
  },
];
type PropTypes = {onClose: Function, data: Object, layout: Object, type: string, dashboardId: string};

const emptyContent = EditorState.createEmpty().getCurrentContent();

const AddTextTileModal = ({onClose, data, layout, dashboardId, type: modalType}: PropTypes) => {
  const dashboard = useDashboardState(dashboardId);
  const descriptionValue = get(data, 'freeText.text', '');
  const editorState =
    descriptionValue && isObject(descriptionValue)
      ? createEditorState(convertFromRaw(descriptionValue))
      : createEditorState(createContentStateFromText(descriptionValue));
  const initialValues = {
    type: data.type || TEXT_TILES_VALUE.TITLE,
    title: data.title.text,
    description: editorState,
    dashboardId,
  };
  const isNew = modalType === OPTION_TILES_VALUES.NEW;

  const updateDashboardAsync = useAsyncAction(updateDashboard, dashboard);
  const actionSubmit = (formValues) => {
    const isNote = formValues.type === TEXT_TILES_VALUE.NOTE;
    const noteSizeY = data.sizeY >= 2 ? data.sizeY : 2;
    const tile = makeTextTilePayload(
      {
        ...data,
        sizeY: isNote ? noteSizeY : 1,
      },
      {
        ...formValues,
        description: isNote ? convertToRaw(formValues.description.getCurrentContent()) : convertToRaw(emptyContent),
      },
      findFreeSpace(layout, {x: data.sizeX, y: isNote ? noteSizeY : 1}),
    );

    return (
      updateDashboardAsync(makeUpdateTilePayload(dashboard.data, tile, data.id, layout))
        .then(() => {
          onClose();
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error))
    );
  };

  return (
    <Modal isOpen onClose={() => onClose()} size={SIZES.MEDIUM} isAnimation={false} classStyle="overflowVisible">
      <TypographyBox variant="h2">{`${isNew ? 'Add' : 'Edit'} Text Tile`}</TypographyBox>
      <div className="text14med lineHeight_14 mt_1-5 mb_1">Tile Type</div>
      <Form initialValues={initialValues} onSubmit={actionSubmit}>
        {({handleSubmit, values, hasValidationErrors}) => (
          <form onSubmit={handleSubmit}>
            <Box width={200}>
              <Field name="type">
                {({input: {value, onChange}}) => (
                  <IconTabsBar
                    automationId="textTypeTile"
                    onChange={onChange}
                    items={TEXT_TILES_ITEMS}
                    value={value}
                    height={64}
                  />
                )}
              </Field>
            </Box>
            <Box mt={1.5}>
              <div className="text16reg lineHeight_16 mb_1">Title</div>
              <Field name="title" validate={isRequired}>
                {({input: {value, onChange}, meta: {invalid}}) => (
                  <Input
                    automationId="titleTextTypeTile"
                    type="text"
                    value={value}
                    onChange={onChange}
                    isInvalid={invalid}
                    invalidMessage="Required"
                    placeHolder="Enter Title Here"
                    fullSize
                  />
                )}
              </Field>
            </Box>
            {values.type === TEXT_TILES_VALUE.NOTE && (
              <Box mt={1}>
                <Field name="description">
                  {({input: {value, onChange}}) => (
                    <TextEditor value={value} onChange={onChange} placeholder="Content" />
                  )}
                </Field>
              </Box>
            )}
            <Box
              display="flex"
              width="100%"
              justifyContent="flex-end"
              mt={values.type === TEXT_TILES_VALUE.NOTE ? 3 : 22}
            >
              <Box mr={1.5}>
                <Button text="Cancel" onClick={onClose} colorBar="gray.200" />
              </Box>
              <AsyncButton type="submit" isDisabled={hasValidationErrors} isLoading={dashboard.isLoading}>
                Save
              </AsyncButton>
            </Box>
          </form>
        )}
      </Form>
    </Modal>
  );
};

export default AddTextTileModal;
