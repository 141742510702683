// @flow
import React from 'react';
import {connect} from 'react-redux';
import {setSelectedEditor} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import {isEditRunning, isStreamEditable} from 'bc/services/dataStreamService';
import {getEditorValidationMessage} from 'bc/services/sqlService';
import DimensionsAndMetrics from 'bc/components/streams/editor/common/DimensionsAndMetrics';
import {get} from 'lodash';

type PropTypes = {
  dataStream: Object,
  dimensions: Array,
  metrics: Array,
  timestampColumn: string,
  isLoading: boolean,
  setSelectedEditor: Function,
};

class SqlDimensionsAndMetrics extends React.PureComponent {
  props: PropTypes;

  setSelectedEditor = () => {
    this.props.setSelectedEditor({
      type: 'dm_sql',
      editorHeight: 'calc(100% - 210px)',
    });
  };

  render() {
    const {dataStream, dimensions, metrics, timestampColumn, isLoading} = this.props;

    const isEditHidden = !!dataStream.basedOnTemplateId;

    const isEditable =
      (isStreamEditable(dataStream.state) || isEditRunning(dataStream.id)) &&
      get(dataStream, 'uiState.editEnabled') &&
      (get(dataStream, 'uiState.tablesViewsMetadata') || get(dataStream, 'uiState.queryPreviewColumns'));

    return (
      <DimensionsAndMetrics
        dataStream={dataStream}
        dimensions={dimensions}
        metrics={metrics}
        timeDefinition={{name: timestampColumn}}
        isLoading={isLoading}
        isEditable={isEditable}
        isEditHidden={isEditHidden}
        validatorFunction={getEditorValidationMessage}
        onSelectEditor={() => this.setSelectedEditor()}
      />
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),
    dimensions: selectors.getSqlStreamSelectedDimensions(state),
    metrics: selectors.getSqlStreamSelectedMetrics(state),
    timestampColumn: selectors.getSelectedDataStream(state).timestampColumn,
    isLoading: selectors.getSqlDimensionsAndMetricsMetadataIsLoading(state),
  }),
  {
    setSelectedEditor,
  },
)(SqlDimensionsAndMetrics);
