// flow
import React, {Fragment, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Transition} from 'react-transition-group';
import * as selectors from 'insightsPanel/store/selectors';
import * as actions from 'insightsPanel/store/actions';
import {segmentClickEvent} from 'common/store/actions';
import PieChart from 'charts/pieChart/PieChart';
import {fadeOpacity, shouldFetchData} from 'insightsPanel/services/constants';
import {ALERTS_SEVERITY, ALERTS_TYPE} from 'charts/pieChart/pieChartSettingsService';

import './AlertsDistribution.module.scss';

type PropTypes = {
  title: String,
  onFilterAlerts: Function,
};

const AlertsDistribution = (props: PropTypes) => {
  const alertsDistribution = useSelector(selectors.getAlertsDistribution);
  const alertsDistributionData = useSelector(selectors.getAlertsDistributionData);
  const isLoading = useSelector(selectors.getIsLoadingAlertsDistribution);
  const isPanelOpen = useSelector(selectors.getIsPanelOpen);
  const numLastDays = useSelector(selectors.getNumLastDays);
  const dispatch = useDispatch();
  const [showData, setShowData] = useState(false);
  const isFirstRun = useRef(true);
  let timer;

  useEffect(() => {
    const params = {
      numLastDays: numLastDays.value,
    };
    if (isPanelOpen && shouldFetchData(alertsDistribution)) {
      dispatch(actions.getAlertsDistribution(params));
    }
  }, [isPanelOpen]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    const params = {
      numLastDays: numLastDays.value,
    };
    dispatch(actions.getAlertsDistribution(params));
  }, [numLastDays]);

  useEffect(() => {
    if (alertsDistributionData) {
      // for some reason Transition duration does not work.
      timer = setTimeout(() => setShowData(true), fadeOpacity.duration);
    }
  }, [alertsDistributionData]);

  useEffect(
    () => () => {
      clearTimeout(timer);
    },
    [isPanelOpen],
  );

  const segmentTracking = (name) => {
    dispatch(
      segmentClickEvent({
        category: 'Insights Panel',
        name,
      }),
    );
  };

  const clickOnType = (event) => {
    const type = event.point.id;
    props.onFilterAlerts({types: type});
    segmentTracking('Alert Distribution Widget - Filter by Type');
  };

  const clickOnSeverity = (event) => {
    const severity = event.point.id;
    props.onFilterAlerts({severities: severity});
    segmentTracking('Alert Distribution Widget - Filter by Type');
  };

  const tooltipFormatter = {
    formatter() {
      // eslint-disable-next-line react/no-this-in-sfc
      return `<div class="pie-chart-tooltip">${Math.round(this.percentage)}%${' '}(${this.y})${' - '}${this.key}</div>`;
    },
  };

  return (
    <div styleName={isLoading ? 'body gray loader' : 'body gray'}>
      {isLoading === false && alertsDistributionData && (
        <Transition in={showData} timeout={0}>
          {(state) => (
            <Fragment>
              <header
                styleName="header"
                style={{...fadeOpacity.defaultStyle, ...fadeOpacity.transitionStyles[state], marginBottom: '3px'}}
              >
                {props.title}
              </header>
              <div styleName="main-container">
                <div styleName="chart-wrapper">
                  <PieChart
                    onClickEvent={clickOnType}
                    id="insightsPanelAlertsDistributionByType"
                    height={77}
                    width={77}
                    data={alertsDistributionData.byType}
                    theme={ALERTS_TYPE}
                    tooltipFormatter={tooltipFormatter}
                  />
                  <span styleName="text">By Alert Type</span>
                </div>
                <div styleName="chart-wrapper">
                  <PieChart
                    onClickEvent={clickOnSeverity}
                    id="insightsPanelAlertsDistributionBySevirity"
                    height={77}
                    width={77}
                    data={alertsDistributionData.bySeverity}
                    theme={ALERTS_SEVERITY}
                    tooltipFormatter={tooltipFormatter}
                  />
                  <span styleName="text">By Alert Severity</span>
                </div>
              </div>
            </Fragment>
          )}
        </Transition>
      )}
    </div>
  );
};

export default AlertsDistribution;
