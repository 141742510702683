// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  createStreamTemplate,
  setSelectedStreamMetricsAndDimensions as setSelectedStreamMetricsAndDimensionsAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import SaveAsTemplateModal from './SaveAsTemplateModal';
import './DimensionsAndMetricsEditor.module.scss';

import GaDimetric from './gaDimetric/GaDimetric';

type PropTypes = {
  dataStream: Object,

  isUsingTemplate: boolean,
  basedOnTemplate: string,

  selectedDimensions: Array,
  possibleDimensions: Array,
  dimensionsInputVal: string,

  selectedMetrics: Array,
  possibleMetrics: Array,
  metricsInputVal: string,

  createStreamTemplate: Function,

  setSelectedStreamMetricsAndDimensions: Function,
};

class DimensionsAndMetricsEditor extends React.PureComponent {
  props: PropTypes;

  state = {isModalOpen: false};

  closeModal = (newTemplateName) => {
    this.setState({isModalOpen: false});
    if (newTemplateName) {
      const newTemplate = {
        createdBy: 'user',
        type: 'google_analytics', // TODO menny, move this to gaService
        name: newTemplateName,
        description: '',
        dimensions: this.props.selectedDimensions.map((i) => i.id),
        metrics: this.props.selectedMetrics.map((i) => i.id),
      };
      this.props.createStreamTemplate(newTemplate);
    }
  };

  openModal = () => {
    this.setState({isModalOpen: true});
  };

  clearAll = () => {
    const {dataStream, setSelectedStreamMetricsAndDimensions} = this.props;
    const metrics = [];
    const dimensions = [];
    if (dataStream.dimensions.includes('ga:segment')) {
      dimensions.push('ga:segment');
    }
    setSelectedStreamMetricsAndDimensions({metrics, dimensions});
  };

  addOrRemoveItem = (id, isMetric, isAdded) => {
    const {selectedMetrics, selectedDimensions, setSelectedStreamMetricsAndDimensions, dataStream} = this.props;
    const getIdListOf = (array) => array.map((i) => i.id);

    const metrics = getIdListOf(selectedMetrics);
    const dimensions = getIdListOf(selectedDimensions);

    const listToModify = isMetric ? metrics : dimensions;
    if (isAdded) {
      listToModify.push(id);
    } else {
      listToModify.splice(listToModify.indexOf(id), 1);
    }
    if (dataStream.dimensions.includes('ga:segment')) {
      dimensions.push('ga:segment');
    }
    setSelectedStreamMetricsAndDimensions({metrics, dimensions});
  };

  onAddMetric = (id) => this.addOrRemoveItem(id, true, true);

  onRemoveMetric = (id) => this.addOrRemoveItem(id, true, false);

  onAddDimension = (id) => this.addOrRemoveItem(id, false, true);

  onRemoveDimension = (id) => this.addOrRemoveItem(id, false, false);

  render() {
    const {possibleMetrics, selectedMetrics, possibleDimensions, selectedDimensions, isUsingTemplate} = this.props;
    const {openModal, clearAll, onAddMetric, onRemoveMetric, onAddDimension, onRemoveDimension, closeModal} = this;
    const {isModalOpen} = this.state;

    return (
      <div styleName="root">
        <div styleName="dm-container">
          <div styleName="dm-panel">
            <GaDimetric
              {...{
                possibleMetrics,
                selectedMetrics,
                possibleDimensions,
                selectedDimensions,
                isUsingTemplate,
                onAddMetric,
                onRemoveMetric,
                onAddDimension,
                onRemoveDimension,
              }}
              onClearAll={() => clearAll()}
              onSaveAsTemplate={openModal}
              isUsingTemplate={isUsingTemplate}
            />
            <a
              href="https://developers.google.com/analytics/devguides/reporting/core/dimsmets#mode=api"
              styleName="dm-copyright"
            >
              Taken from GA Reporting API v4
            </a>
          </div>
        </div>

        <SaveAsTemplateModal onClose={closeModal} isOpen={isModalOpen} />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    dataStream: selectors.getSelectedDataStream(state),

    isUsingTemplate: selectors.getGoogleAnalyticsQuerySelectedTemplate(state),
    basedOnTemplate: selectors.getGoogleAnalyticsStreamBaseTemplate(state),

    selectedDimensions: selectors.getGoogleAnalyticsStreamSelectedDimensions(state),
    possibleDimensions: selectors.getGoogleAnalyticsStreamPossibleDimensions(state),
    dimensionsInputVal: state.bc.googleAnalyticsStream.extraUiState.dimensionsInputVal,

    selectedMetrics: selectors.getGoogleAnalyticsStreamSelectedMetrics(state),
    possibleMetrics: selectors.getGoogleAnalyticsStreamPossibleMetrics(state),
    metricsInputVal: state.bc.googleAnalyticsStream.extraUiState.metricsInputVal,
  }),
  {
    createStreamTemplate,

    setSelectedStreamMetricsAndDimensions: setSelectedStreamMetricsAndDimensionsAction,
  },
)(DimensionsAndMetricsEditor);
