// @flow
import React from 'react';
import {serverAnomalyDirection} from 'alerts.management/services/alertsService';
import ConditionContainer from './ConditionContainer';
import './DirectionCondition.module.scss';

const directionArr = [
  {value: serverAnomalyDirection.both, text: 'UP & DOWN'},
  {value: serverAnomalyDirection.down, text: 'DOWN'},
  {value: serverAnomalyDirection.up, text: 'UP'},
];

const handleChange = (payload, action) => {
  action({direction: payload});
};

type PropTypes = {
  direction: String,
  onChange: Function,
};

const DirectionSelector = (props: PropTypes) => (
  <ConditionContainer title="Direction">
    <div styleName="empty-container" automation-id="dummy" />
    <div styleName="buttons-container" automation-id="alertSettingsDirectionButtons">
      {directionArr.map((s) => (
        <button
          type="button"
          key={s.value}
          className={`btn btn-raised btn-outline btn-outline-large ${
            s.value === props.direction ? 'btn-outline-light' : 'btn-outline-dark'
          }`}
          onClick={() => handleChange(s.value, props.onChange)}
        >
          <span>{s.text}</span>
        </button>
      ))}
    </div>
  </ConditionContainer>
);

export default DirectionSelector;
