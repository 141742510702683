// @flow
import React from 'react';
import {alertTypes} from 'alerts.management/services/alertsService';
import ConditionContainer from './ConditionContainer';
import './SendAlertOnCondition.module.scss';

const options = [{value: true, text: 'OPEN'}, {value: false, text: 'OPEN & CLOSE'}];

type PropTypes = {
  notifyOnlyOpen: boolean,
  onChange: Function,
  alertType: String,
};

const SendAlertOnCondition = (props: PropTypes) => {
  const subTitle =
    props.alertType === alertTypes.anomaly.value
      ? 'Select OPEN to only notify when anomaly is detected and waive notification of anomaly close.'
      : 'Select OPEN to only notify when threshold is breached, waive notification going back to normal state';

  return (
    <ConditionContainer title="Send Alert On" subTitle={subTitle}>
      <div styleName="buttons-container">
        {options.map((s) => (
          <button
            type="button"
            key={s.value}
            automation-id="alertSettingSendAlert{s.value}Button"
            className={`btn btn-raised btn-outline btn-outline-large ${
              s.value === props.notifyOnlyOpen ? 'btn-outline-light' : 'btn-outline-dark'
            }`}
            onClick={() => props.onChange(s.value)}
          >
            {s.text}
          </button>
        ))}
      </div>
    </ConditionContainer>
  );
};

export default SendAlertOnCondition;
