// @flow
import React from 'react';
import './ConditionContainer.module.scss';

type PropTypes = {
  children: any,
  title: Node,
  subTitle: String,
  onClose: Function,
  id: String,
  className: String,
  isDisabled?: Boolean,
};

const ConditionContainer = (props: PropTypes) => (
  <div styleName="main" automation-id={`editAlert${props.title}Group`}>
    <div styleName={props.isDisabled ? 'title disabled' : 'title'} className={props.className ? props.className : ''}>
      {props.title}
      {props.subTitle && <div styleName="sub-title">{props.subTitle}</div>}
    </div>
    {props.onClose && (
      <button
        type="button"
        className="btn btn-flat btn-icon-36 btn-secondary"
        styleName="close-btn"
        onClick={() => props.onClose(props.id)}
      >
        <i className="icon icon icn-general16-closea" styleName="remove-condition" />
      </button>
    )}
    {props.children}
  </div>
);

ConditionContainer.defaultProps = {
  isDisabled: false,
};

export default ConditionContainer;
