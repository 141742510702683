// @flow

import React, {PureComponent} from 'react';
import {get} from 'lodash';
import {alertConditionTypes, serverAnomalyDirection} from 'alerts.management/services/alertsService';
import AbsPercentageConditionTemplate from './AbsPercentageConditionTemplate';
import StaticThresholdCondition from './StaticThresholdCondition';
import InfluencingMetrics from './influencingMetrics/InfluencingMetrics';

type PropTypes = {
  type?: String,
  conditions: Array,
  setSelectedAlertMaxMetricsPercentage?: Function,
  setSelectedAlertMinMetricsPercentage?: Function,
  setSelectedAlertMaxMetricsAbs?: Function,
  setSelectedAlertMinMetricsAbs?: Function,
  setSelectedAlertStaticThreshold?: Function,
  removeConditionFromSelectedAlert?: Function,
  thresholdCondition?: Object,
  directionCondition?: Object,
};

export default class Conditions extends PureComponent {
  props: PropTypes;

  static defaultProps = {
    type: null,
    setSelectedAlertMaxMetricsPercentage: null,
    setSelectedAlertMinMetricsPercentage: null,
    setSelectedAlertMaxMetricsAbs: null,
    setSelectedAlertMinMetricsAbs: null,
    setSelectedAlertStaticThreshold: null,
    removeConditionFromSelectedAlert: null,
    thresholdCondition: null,
    directionCondition: null,
  };

  removeCondition = (type) => {
    this.props.removeConditionFromSelectedAlert(type);
  };

  render() {
    const {
      type,
      conditions,
      setSelectedAlertMaxMetricsPercentage,
      setSelectedAlertMinMetricsPercentage,
      setSelectedAlertMaxMetricsAbs,
      setSelectedAlertMinMetricsAbs,
      thresholdCondition,
      directionCondition,
      setSelectedAlertStaticThreshold,
    } = this.props;

    return conditions.map((c) => {
      if (c.type === alertConditionTypes.maxParticipatingMetrics) {
        return (
          <AbsPercentageConditionTemplate
            key={c.id}
            title="Maximum Metrics in an Anomaly"
            subTitle={
              <span>
                Trigger the alert if there is a maximum number of metrics in an anomaly. Set the maximum in absolute
                value, or percentage or both
              </span>
            }
            absValue={c.maxNumOfMetrics}
            percentValue={c.maxPercentageOfMetrics}
            onPercentChange={setSelectedAlertMaxMetricsPercentage}
            onAbsChange={setSelectedAlertMaxMetricsAbs}
            id={c.id}
            isAbsOnlyNaturalNumbers
            onClose={this.removeCondition}
          />
        );
      }
      if (c.type === alertConditionTypes.minParticipatingMetrics) {
        return (
          <AbsPercentageConditionTemplate
            key={c.id}
            title="Minimum Metrics in an Anomaly"
            subTitle={
              <span>
                Trigger the alert if there is a minimum number of metrics in an anomaly. Set the minimum in absolute
                value, or percentage or both
              </span>
            }
            absValue={c.minNumOfMetrics}
            percentValue={c.minPercentageOfMetrics}
            onPercentChange={setSelectedAlertMinMetricsPercentage}
            onAbsChange={setSelectedAlertMinMetricsAbs}
            id={c.id}
            isAbsOnlyNaturalNumbers
            onClose={this.removeCondition}
          />
        );
      }
      if (c.type === alertConditionTypes.staticThreshold) {
        return (
          <StaticThresholdCondition
            key={c.id}
            staticThreshold={thresholdCondition}
            direction={get(directionCondition, 'direction', serverAnomalyDirection.both)}
            alertType={type.value}
            onChange={setSelectedAlertStaticThreshold}
            id={c.id}
            onClose={this.removeCondition}
          />
        );
      }
      if (c.type === alertConditionTypes.influencingMetrics) {
        return <InfluencingMetrics key={c.id} id={c.id} />;
      }
      return null;
    });
  }
}
