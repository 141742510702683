// @flow
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import MultiButton from 'common/componentsV2/multiButton/MultiButton';
import {ALLOWED_FILTER_KEYS} from 'admin.customers/services/customersService';
import * as filterSelector from 'admin.customers/store/filterSelector';
import {StringParam, useQueryParam} from 'use-query-params';

const STATUS_ALERTS = Object.values(ALLOWED_FILTER_KEYS.paying);

const CustomerPayingFilter = () => {
  const isPaying = useSelector(filterSelector.getFiltersPaying);
  const [, setStatusQueryParam] = useQueryParam('paying', StringParam);

  const onChange = useCallback((val) => {
    setStatusQueryParam(val !== 'all' ? val : undefined);
  }, []);

  return (
    <MultiButton value={isPaying} options={STATUS_ALERTS} onChange={onChange} automationId="customersPayingFilter" />
  );
};

export default CustomerPayingFilter;
