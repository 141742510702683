import React, {useState, useCallback, useEffect} from 'react';
import useDebounce from 'common/utils/useDebounce';
import Input from 'common/componentsV2/Input';
import formatNumber from 'common/utils/formatNumber';

type PropTypes = {
  value: String,
  delay: Number,
  additionalProps: Object,
  multipleOf: Number,
  onChange: Function,
  onBlur: Function,
  onFocus: Function,
  allowEmpty: boolean,
  type: String,
  denyZero: any,
  style: Object,
  automationId: string,
  placeHolder: String,
};

export default (props: PropTypes) => {
  const [stateValue, setValue] = useState(props.value);
  const [editVisibility, setEditVisibility] = useState(false);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const {min, max} = props.additionalProps || {};

  const handleChange = (event) => {
    const {multipleOf, onChange, onBlur, allowEmpty, type, denyZero} = props;

    const {
      target: {value},
    } = event;

    if (allowEmpty && !value) {
      onChange(event);
      if (onBlur) {
        onBlur(event);
      }
      setValue(value);
      return;
    }

    if (type === 'number') {
      let newValue = value;

      if ((min || min === 0) && value < min) {
        newValue = min;
      } else if (max && value > max) {
        newValue = max;
      }

      newValue = multipleOf ? Math.round(newValue / multipleOf) * multipleOf : newValue;

      if (denyZero && +newValue === 0) {
        newValue = '';
      }

      /*
      let zerosCount = 0;
      const numberParts = newValue.toString().split('.');
      if (numberParts[1]) {
        numberParts[1].split('').every((digit) => {
          if (digit === '0') {
            zerosCount += 1;
            return true;
          }
          return false;
        });
      }

      const multiplier = Math.min(Math.max(10 ** (zerosCount + 1), 1000), 1000000);
      newValue = Math.round(newValue * multiplier) / multiplier;
      */

      onChange({
        target: {value: newValue},
      });
      if (onBlur) {
        onBlur({
          target: {value: newValue},
        });
      }
      setValue(newValue);
    } else {
      onChange(event);
      if (onBlur) {
        onBlur(event);
      }
    }
  };

  const [debouncedHandler, cancelDebouncedHandler] = useDebounce(handleChange, props.delay);

  const onChange = useCallback(
    (event) => {
      if (props.type === 'number') {
        let {value} = event.target;

        value = value.replace(/[^-0-9.]+/, '');
        value = value.slice(0, 1) + value.slice(1).replace(/[-]+/, '');
        if (value.includes('.')) {
          value = value.slice(0, value.indexOf('.') + 1) + value.slice(value.indexOf('.')).replace(/\./g, '');
        }

        const isInteger = props.multipleOf % 1 === 0;
        if (isInteger && value) {
          const parsedValue = parseInt(value, 10);
          value = isNaN(parsedValue) ? value : parsedValue;
        }
        if (value >= 0 && max && isInteger) {
          setValue(value.toString().slice(0, max.toString().length));
        } else if (value < 0 && (min || min === 0) && isInteger) {
          setValue(
            -Math.abs(value)
              .toString()
              .slice(0, min.toString().length),
          );
        } else {
          setValue(value);
        }
      } else {
        setValue(event.target.value);
      }
      if (event.persist) {
        event.persist();
      }
      debouncedHandler(event);
    },
    [debouncedHandler],
  );
  const onBlur = useCallback(
    (event) => {
      setEditVisibility(false);
      // eslint-disable-next-line no-unused-expressions
      cancelDebouncedHandler();
      handleChange(event);
    },
    [cancelDebouncedHandler, handleChange],
  );

  const onFocus = useCallback(
    (event) => {
      setEditVisibility(true);
      if (props.onFocus) {
        props.onFocus(event);
      }
    },
    [props.onFocus],
  );

  const value = useCallback(() => {
    if (props.type === 'number' && editVisibility) {
      return stateValue;
    }
    if (props.type === 'number' && !editVisibility) {
      return formatNumber(stateValue, 3, ',', '.');
    }
    return stateValue;
  }, [stateValue, editVisibility]);

  return (
    <Input
      automation-id={props.automationId}
      placeHolder={props.placeHolder || null}
      {...props}
      type={!editVisibility ? 'text' : props.type || 'text'}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
      value={value()}
    />
  );
};
