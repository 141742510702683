/* eslint-disable */
import Highcharts from 'highcharts';
import More from 'highcharts/highcharts-more';
import Exporting from 'highcharts/modules/exporting';
import ExportData from 'highcharts/modules/export-data';
import NoDataToDisplay from 'highcharts/modules/no-data-to-display';
import Boost from 'highcharts/modules/boost';
import {getGlobalSettings} from './timeSeries/services/timeSeriesHchartSettingsService';

const loadModules = () => {
  // Initialize modules
  More(Highcharts);
  Exporting(Highcharts);
  ExportData(Highcharts);
  NoDataToDisplay(Highcharts);
  Boost(Highcharts); // needs to be last according to highcharts docs
};

const patchHighcharts = (H = Highcharts) => {
  // fix bug column overlap: https://github.com/highcharts/highcharts/issues/5028
  // Highcharts.Axis.prototype.getClosest = function () {
  //
  //     var xValues = this.series.reduce(function (prev, series) {
  //         return prev.concat(series.processedXData);
  //     }, []).sort(function (a, b) {
  //         return a - b;
  //     });
  //
  //     var i = xValues.length || 1,
  //         distance,
  //         closestPointRange;
  //     while (--i) {
  //         distance = this.isLog ?
  //         this.val2lin(xValues[i]) - this.val2lin(xValues[i - 1]) :
  //         xValues[i] - xValues[i - 1];
  //
  //         if (distance > 0 && (closestPointRange === undefined || distance < closestPointRange)) {
  //             closestPointRange = distance;
  //         }
  //     }
  //     return closestPointRange;
  // };

  // http://jsfiddle.net/gh/get/library/pure/highcharts/highcharts/tree/samples/highcharts/yaxis/type-log-negative/
  // Pass error messages
  H.Axis.prototype.allowNegativeLog = true;

  // Override conversions
  H.Axis.prototype.log2lin = function(num) {
    const isNegative = num < 0;
    let adjustedNum = Math.abs(num);
    let result;
    if (adjustedNum < 10) {
      adjustedNum += (10 - adjustedNum) / 10;
    }
    result = Math.log(adjustedNum) / Math.LN10;
    return isNegative ? -result : result;
  };
  H.Axis.prototype.lin2log = function(num) {
    const isNegative = num < 0;
    const absNum = Math.abs(num);
    let result = Math.pow(10, absNum);
    if (result < 10) {
      result = (10 * (result - 1)) / (10 - 1);
    }
    return isNegative ? -result : result;
  };
};

export default () => {
  window.Highcharts = Highcharts; // for angular (saasweb) chartConstants to init properly
  Highcharts.setOptions(getGlobalSettings());
  loadModules();
  patchHighcharts(Highcharts);
};
