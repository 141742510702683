// @flow
import React, {Fragment, useEffect, useState} from 'react';
import Box from '@material-ui/core/Box';
import Slider from 'common/componentsV2/Slider';

type PropTypes = {
  value: Number,
  setValue: Function,
};

const FactorSelectorSlider = (props: PropTypes) => {
  const [localValue, setLocalValue] = useState(props.value);

  useEffect(() => {
    setLocalValue(props.value);
  }, [props.value]);

  const setValue = (value) => {
    props.setValue({value: Math.round(value * 10) / 10, name: 'factor'});
  };

  return (
    <Fragment>
      <Box flexGrow={1}>
        <Slider
          automationId="impactFactorSelectorSlider"
          isRed
          value={localValue}
          max={10}
          min={0}
          step={0.1}
          tooltipValueFormatter={(value) => parseFloat(value).toFixed(1)}
          onRelease={(value) => setValue(value)}
          onChange={setLocalValue}
        />
      </Box>
    </Fragment>
  );
};

export default FactorSelectorSlider;
