import React from 'react';
import GoogleStorageData from './googleStorageData/GoogleStorageData';
import GoogleStorageDimensionsAndMetrics from './googleStorageDimensionsAndMetrics/GoogleStorageDimensionsAndMetrics';
import GoogleStorageSchedular from './googleStorageSchedular/GoogleStorageSchedular';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';

import './GoogleStorageStreamEditor.module.scss';

const GoogleStorageStreamEditor = () => (
  <div styleName="root" className="shell-col">
    <StreamEditorSteps>
      <GoogleStorageData />
      <GoogleStorageDimensionsAndMetrics />
      <div className="shell-col">
        <StreamProperties />
        <GoogleStorageSchedular />
      </div>
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default GoogleStorageStreamEditor;
