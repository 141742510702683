import React from 'react';
import {useSelector} from 'react-redux';
import {Box} from '@material-ui/core';
import * as appSelectors from 'app/store/selectors';
import {TypographyBox} from 'common/componentsV2/boxTools';
import Button, {COLORS} from 'common/componentsV2/Button';
import bg from './bg.png';
import {ReactComponent as Shape} from './shape.svg';

const Page404 = () => {
  const appNavBarItems = useSelector(appSelectors.getAppNavBarItems);
  const alertConsole = appNavBarItems.find((item) => item.id === 'ALERTS_CONSOLE');

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      position="fixed"
      height={1}
      width={1}
      left={0}
      bgcolor="white.500"
      css={{
        background: `url(${bg}) no-repeat center center / 720px`,
        overflowX: 'auto',
        zIndex: 12,
      }}
    >
      <Box
        minWidth={633}
        minHeight={374}
        borderRadius={30}
        display="flex"
        flexDirection="column"
        alignItems="center"
        position="relative"
        p={3}
      >
        <Box mb={1.5} color="indigo.500" fontSize={36} lineHeight="36px" fontWeight="bold">
          Whoops!
        </Box>
        <TypographyBox variant="h3" color="indigo.500" mb={4}>
          We can’t find what you’re looking for
        </TypographyBox>
        <Shape />
        <Box mt={58} position="absolute">
          {alertConsole && (
            <a href={alertConsole.links[0]}>
              <Button colorSchema={COLORS.BLUE_500} text="Back home" />
            </a>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Page404;
