// @flow
import React from 'react';
import Table from 'admin.trainTracker/components/Table';
import PageLayout from 'common/componentsV2/PageLayout';
import PageHeader from 'admin.trainTracker/components/PageHeader';
import './TrainTrackerPage.module.scss';

type PropTypes = {};

export default class TrainTrackerPage extends React.PureComponent {
  props: PropTypes;

  render() {
    return (
      <PageLayout header={<PageHeader />}>
        <div styleName="root">
          <Table />
        </div>
      </PageLayout>
    );
  }
}
