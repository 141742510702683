import {createSelector} from 'reselect';
import {get} from 'lodash';

const convertStatusStrings = (state) => {
  if (state === 'RUNNING') {
    return 'Live';
  }
  if (state === 'FINISHED') {
    return 'Completed';
  }
  return state;
};

export const getRoot = (state) => state.adminCsPortal; // replace all moduleName with actual module

// ****************Views*****************

export const getView = createSelector(
  getRoot,
  (i) => i.view,
);

export const getTabsList = createSelector(
  getView,
  (view) => view.mainTabs,
);

export const getSelectedTab = createSelector(
  getTabsList,
  (tabs) => tabs.find((tab) => tab.active),
);

export const getSelectedCustomer = createSelector(
  getView,
  (view) => view.selectedCustomer,
);

export const getSelectedCustomerMetrics = createSelector(
  getSelectedCustomer,
  (s) => s.metrics || 0,
);

// ****************Data*****************

export const getData = createSelector(
  getRoot,
  (i) => i.data,
);

export const getStreams = createSelector(
  getData,
  (i) => i.fetchStreams,
);

export const getMergedStreams = createSelector(
  getStreams,
  (streamsList) => {
    const streams = get(streamsList, 'data', []);
    return streams.map((stream) => ({
      ...stream,
      name: stream.name,
      numMetrics: stream.numMetrics,
    }));
  },
);

export const getIsStreamsLoading = createSelector(
  getStreams,
  (i) => i.isLoading,
);

export const getStats = createSelector(
  getData,
  (i) => i.fetchStats,
);

export const getIsLoadingStats = createSelector(
  getStats,
  (i) => i.isLoading,
);

export const getMetrics = createSelector(
  getStats,
  (fetchStats) => ({
    isEpsBreached: get(fetchStats, 'metrics.origin.isEpsBreached', false),
    isMetricsBreached: get(fetchStats, 'metrics.isMetricsBreached', false),
    actualLimit: get(fetchStats, 'metrics.actualLimit', 0),
    cached: get(fetchStats, 'metrics.cached', false),
    indexed: get(fetchStats, 'metrics.indexed', 0),
    stream: get(fetchStats, 'metrics.origin.STREAM', 0),
    composite: get(fetchStats, 'metrics.origin.COMPOSITE', 0),
    alert: get(fetchStats, 'metrics.origin.ALERT', 0),
  }),
);

export const getRate = createSelector(
  getStats,
  (i) => i.rate,
);

export const getStreamsReport = createSelector(
  getData,
  (i) => i.fetchStreamsReport,
);

export const getStreamsReportData = createSelector(
  getStreamsReport,
  (i) => i.data,
);

export const getIsStreamsReportLoading = createSelector(
  getStreamsReport,
  (i) => i.isLoading,
);

export const getStreamsReportSummary = createSelector(
  getStreamsReportData,
  (reportsList) => {
    if (reportsList) {
      const scrubbedSources = [];
      reportsList.forEach((item) => {
        if (item && item.left && item.left.feed && item.left.status) {
          scrubbedSources.push({
            type: item.left.feed.source_type,
            title: item.left.feed.connection.title,
            failure: item.left.status !== 'OK',
          });
        }
      });
      const summaryCounter = {};
      scrubbedSources.forEach((item) => {
        if (summaryCounter[[item.type]]) {
          summaryCounter[[item.type]].streams += 1;
          if (!summaryCounter[[item.type]].sources.includes(item.title)) {
            summaryCounter[[item.type]].sources.push(item.title);
          }
          if (item.failure) {
            summaryCounter[[item.type]].failure = true;
          }
        } else {
          summaryCounter[[item.type]] = {streams: 1, sources: [item.title], failure: false};
        }
      });
      return summaryCounter;
    }
    return null;
  },
);

export const getStreamsBySource = createSelector(
  getStreamsReportData,
  (reportsList) => {
    if (reportsList) {
      const sources = {};
      reportsList.forEach((i) => {
        if (i && i.left) {
          const stream = {
            stream_title: i.left.title,
            stream_id: i.left.stream_id,
            stream_state: convertStatusStrings(i.left.state),
            stream_status: i.left.status,
            stream_ispaused: i.left.is_paused,
            stream_source: i.left.feed.source_type,
          };
          if (sources[i.left.feed.connection.title]) {
            sources[i.left.feed.connection.title].push(stream);
          } else {
            sources[i.left.feed.connection.title] = [stream];
          }
        }
      });
      const groupedSources = {};
      Object.keys(sources).forEach((i) => {
        const sourceType = sources[i][0].stream_source;
        if (groupedSources[sourceType]) {
          groupedSources[sourceType].push({[i]: sources[i]});
        } else {
          groupedSources[sourceType] = [{[i]: sources[i]}];
        }
      });
      return groupedSources;
    }
    return null;
  },
);

export const getSourceCount = createSelector(
  getStreamsBySource,
  (sourceList) => {
    let sourceCount = 0;
    if (sourceList) {
      Object.keys(sourceList).forEach((sourceType) => {
        sourceList[sourceType].forEach(() => {
          sourceCount += 1;
        });
      });
    }
    return sourceCount;
  },
);

export const getTaskLog = createSelector(
  getData,
  (i) => i.fetchTaskLog,
);

export const getTaskLogIsLoading = createSelector(
  getTaskLog,
  (i) => i.isLoading,
);

export const getKibanaLog = createSelector(
  getData,
  (i) => i.fetchKibanaLog,
);

export const flushMetrics = createSelector(
  getData,
  (i) => i.flushMetrics,
);

export const getIsFlushLoading = createSelector(
  flushMetrics,
  (i) => i.isLoading,
);

export const getIsFlushSuccess = createSelector(
  flushMetrics,
  (i) => (i.data && i.data === 'success') || (i.data && i.data === '["success"'), // the ["success" need to removed after BE will fix that
);

export const searchMetrics = createSelector(
  getData,
  (i) => i.searchMetrics,
);

export const getIsSearchLoading = createSelector(
  searchMetrics,
  (i) => i.isLoading,
);

export const deleteMetrics = createSelector(
  getData,
  (i) => i.deleteMetrics,
);

export const getMetricsEps = createSelector(
  getData,
  (i) => i.getMetricsEps,
);

export const getGenerateTemplates = createSelector(
  getData,
  (i) => i.generateTemplates,
);

export const getIsGetMetricsEpsLoading = createSelector(
  getMetricsEps,
  (i) => i.isLoading,
);

export const getIsGenerateTemplatesLoading = createSelector(
  getGenerateTemplates,
  (i) => i.isLoading,
);

export const getGenerateTemplatesSuccess = createSelector(
  getGenerateTemplates,
  (i) => i.success,
);

export const getStreamErrors = createSelector(
  getStreamsReportData,
  (streams) => {
    const failedStreams = [];
    if (streams) {
      const filteredStreams = streams.filter((stream) => stream.left);
      filteredStreams.forEach((stream) => {
        const streamData = stream.left;
        if (stream.left.failure_message) {
          failedStreams.push({
            stream_id: streamData.stream_id,
            stream_name: streamData.title,
            failure_message: streamData.failure_message,
            modify_time: streamData.modify_time,
          });
        }
      });
    }
    return failedStreams;
  },
);

export const getStreamErrorsCount = createSelector(
  getStreamErrors,
  (errors) => errors.length,
);

export const getAlertsErrors = createSelector(
  getData,
  (i) => i.fetchAlertsErrors,
);

export const getAlertsErrorsData = createSelector(
  getAlertsErrors,
  (i) => i.data,
);

export const getAreAlertErrorsLoading = createSelector(
  getAlertsErrors,
  (i) => i.isLoading,
);

export const getLeanAlertsErrors = createSelector(
  getAlertsErrorsData,
  (alerts) => {
    const failedAlerts = [];
    if (alerts) {
      const filteredAlerts = alerts.filter(
        (alert) =>
          (alert && alert.validation && alert.validation.alertValidation && !alert.validation.alertValidation.passed) ||
          (alert &&
            alert.validation &&
            alert.validation.compositeValidation &&
            !alert.validation.compositeValidation.passed),
      );
      filteredAlerts.forEach((alert) => {
        const {title} = alert;
        let type;
        if (!alert.validation.alertValidation.passed) {
          type = 'Alert';
          const {message} = alert.validation.alertValidation.failures[0];
          const {id} = alert.validation.alertValidation.failures[0];
          const totalAlertErrors = alert.validation.alertValidation.failures.length;
          let totalCompositeErrors = 0;
          if (!alert.validation.compositeValidation.passed) {
            type = 'Alert/Composite';
            const compositeFailures = alert.validation.compositeValidation.failures;
            const compositeFailuresKeys = Object.keys(compositeFailures);
            compositeFailuresKeys.forEach((key) => {
              totalCompositeErrors += compositeFailures[key].length;
            });
          }
          failedAlerts.push({
            alert_id: id,
            alert_name: title,
            alert_message: message,
            alert_type: type,
            total_alert_errors: totalAlertErrors,
            total_composite_errors: totalCompositeErrors,
          });
        } else if (!alert.validation.compositeValidation.passed) {
          type = 'Composite';
          const {failures} = alert.validation.compositeValidation;
          const {message} = failures[Object.keys(failures)[0]][0];
          const {id} = failures[Object.keys(failures)[0]][0];
          let totalCompositeErrors = 0;
          const compositeFailures = alert.validation.compositeValidation.failures;
          const compositeFailuresKeys = Object.keys(compositeFailures);
          compositeFailuresKeys.forEach((key) => {
            totalCompositeErrors += compositeFailures[key].length;
          });
          failedAlerts.push({
            alert_id: id,
            alert_name: title,
            alert_message: message,
            alert_type: type,
            total_alert_errors: 0,
            total_composite_errors: totalCompositeErrors,
          });
        }
      });
    }
    return failedAlerts;
  },
);

export const getAlertErrorMessages = createSelector(
  getAlertsErrorsData,
  (alerts) => {
    const messages = [];
    if (alerts) {
      const filteredAlerts = alerts.filter(
        (alert) =>
          (alert && alert.validation && alert.validation.alertValidation && !alert.validation.alertValidation.passed) ||
          (alert &&
            alert.validation &&
            alert.validation.compositeValidation &&
            !alert.validation.compositeValidation.passed),
      );
      filteredAlerts.forEach((alert) => {
        const data = {alert: {}, composite: {}};
        const {alertValidation} = alert.validation;
        const {compositeValidation} = alert.validation;
        if (!alertValidation.passed) {
          const {failures} = alertValidation;
          failures.forEach((failure) => {
            if (!Object.keys(data.alert).includes(failure.message)) {
              data.alert[[failure.message]] = 1;
            } else {
              data.alert[[failure.message]] += 1;
            }
          });
        }
        if (!compositeValidation.passed) {
          const {failures} = compositeValidation;
          const keys = Object.keys(failures);
          keys.forEach((key) => {
            const arr = failures[key];
            arr.forEach((err) => {
              if (!Object.keys(data.composite).includes(err.message)) {
                data.composite[[err.message]] = 1;
              } else {
                data.composite[[err.message]] += 1;
              }
            });
          });
        }
        messages.push(data);
      });
    }
    return messages;
  },
);

export const getAlertErrorsCount = createSelector(
  getLeanAlertsErrors,
  (alerts) => alerts.length,
);

export const getCompositeErrors = createSelector(
  getData,
  (i) => i.fetchCompositeErrors,
);

export const getCompositeErrorsData = createSelector(
  getCompositeErrors,
  (i) => i.data,
);

export const getAreCompositeErrorsLoading = createSelector(
  getCompositeErrors,
  (i) => i.isLoading,
);

export const getLeanCompositeErrors = createSelector(
  getCompositeErrorsData,
  (composites) => {
    const errors = [];
    if (composites) {
      const filteredErrors = composites.filter(
        (composite) => composite && composite.validationResultJS && !composite.validationResultJS.passed,
      );
      filteredErrors.forEach((error) => {
        const title = error.compositeTitle;
        const id = error.compositeId;
        const firstKey = Object.keys(error.validationResultJS.failures)[0];
        const {message} = error.validationResultJS.failures[firstKey][0];
        let totalErrors = 0;
        const compositeFailures = error.validationResultJS.failures;
        const compositeFailuresKeys = Object.keys(compositeFailures);
        compositeFailuresKeys.forEach((key) => {
          totalErrors += compositeFailures[key].length;
        });
        errors.push({
          composite_id: id,
          composite_title: title,
          composite_message: message,
          total_errors: totalErrors,
        });
      });
    }
    return errors;
  },
);

export const getCompositeErrorMessages = createSelector(
  getCompositeErrorsData,
  (composites) => {
    const messages = [];
    if (composites) {
      const filteredErrors = composites.filter(
        (composite) => composite && composite.validationResultJS && !composite.validationResultJS.passed,
      );
      filteredErrors.forEach((error) => {
        const data = {};
        const {failures} = error.validationResultJS;
        const keys = Object.keys(failures);
        keys.forEach((key) => {
          const arr = failures[key];
          arr.forEach((item) => {
            if (!Object.keys(data).includes(item.message)) {
              data[[item.message]] = 1;
            } else {
              data[[item.message]] += 1;
            }
          });
        });
        messages.push(data);
      });
    }
    return messages;
  },
);

export const getCompositeErrorsCount = createSelector(
  getLeanCompositeErrors,
  (errors) => errors.length,
);
