import * as actions from 'bc/store/actions';
import {composeReducers, reduceArrayItem} from 'common/utils/reducers';

const s3DataStreamReducer = composeReducers((state, {type, payload}) => {
  const getSelectedIndex = (id = state.selectedItemId) => state.streams.data.findIndex((item) => item.id === id);

  const red = (item, itemPayload) => ({...item, ...itemPayload});

  const redWrapper = (_payload, index = getSelectedIndex()) => ({
    ...state,
    streams: {
      ...state.streams,
      data: reduceArrayItem(red, state.streams.data, index, _payload || payload),
    },
  });

  switch (type) {
    case actions.setS3StreamFileFormat.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      return {...redWrapper({...stream, uiState: null, fileFormat: {...stream.fileFormat, ...payload}})};
    }

    case actions.setS3StreamKeyVal.TYPE: {
      const payloadInner = {...payload};
      return {...redWrapper(payloadInner), isStreamNeedUpdating: true};
    }

    case actions.setS3StreamFilePathAndNamePattern.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      return {...redWrapper({...stream, ...payload})};
    }

    case actions.setS3StreamUiKeyVal.TYPE: {
      const stream = state.streams.data[getSelectedIndex()];
      const streamModifications = {
        uiState: {
          ...stream.uiState,
          ...payload,
        },
      };
      return redWrapper(streamModifications);
    }

    default:
      return state;
  }
});

export default s3DataStreamReducer;
