import React from 'react';
import AdobeScheduler from './adobeScheduler/AdobeScheduler';
import Bookmarks from './Bookmarks';
import AdobeJsonViewer from './AdobeJsonViewer';
import StreamEditorPanel from '../../StreamEditorPanel';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';
import './AdobeAnalyticsStreamEditor.module.scss';

const AdobeAnalyticsStreamEditor = () => (
  <div className="shell-col">
    <StreamEditorSteps>
      <div styleName="left-container">
        <StreamProperties />
        <Bookmarks />
        <AdobeScheduler />
      </div>
      <AdobeJsonViewer />
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default AdobeAnalyticsStreamEditor;
