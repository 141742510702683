import {combineEpics} from 'redux-observable';
import {makeAsyncEpic} from 'common/utils/simplifiedAsync';
import {get} from 'lodash';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as api from '../../services/api';

const fetchAdobeBookmarks = makeAsyncEpic(actions.fetchAdobeBookmarks, api.fetchAdobeBookmarks);
const fetchAdobeReportDescription = makeAsyncEpic(actions.fetchAdobeReportDescription, api.fetchAdobeReportDescription);
const EMPTY_ARRAY = [];

const setAdobeStreamUiBookmarkId = (action$, {getState}) =>
  action$.ofType(actions.setAdobeStreamUiBookmarkId.TYPE).switchMap(() => {
    const stream = selectors.getSelectedDataStream(getState());
    return [
      actions.fetchAdobeReportDescription({
        dataSourceId: stream.dataSourceId,
        type: stream.type,
        bookmarkId: stream.uiState.bookmarkId,
      }),
    ];
  });

const setAdobeStreamReportDescriptionSuccess = (action$) =>
  action$.ofType(actions.fetchAdobeReportDescription.success.TYPE).switchMap((action) => {
    const {reportDescription} = action.payload;
    const metrics = get(action.payload.reportDescription, 'metrics', EMPTY_ARRAY);
    const dimensions = get(action.payload.reportDescription, 'elements', EMPTY_ARRAY);
    return [
      actions.setSelectedStreamKeyVal({reportDescription}),
      actions.setAdobeStreamMetricsAndDimensions({metrics, dimensions}),
    ];
    // return [actions.setSelectedStreamKeyVal({reportDescription})];
  });

const adobeEpic = combineEpics(
  fetchAdobeBookmarks,
  fetchAdobeReportDescription,
  setAdobeStreamUiBookmarkId,
  setAdobeStreamReportDescriptionSuccess,
);
export default adobeEpic;
