// @flow
import React from 'react';
import {connect} from 'react-redux';
import {
  setAuctionStreamDiametricsChange as setAuctionStreamDiametricsChangeAction,
  removeAuctionStreamDiametrics as removeAuctionStreamDiametricsAction,
  setAuctionStreamClearAllDiametrics as setAuctionStreamClearAllDiametricsAction,
  setStreamDiametricsEditorFilterTextInput as setStreamDiametricsEditorFilterTextInputAction,
} from 'bc/store/actions';
import * as selectors from 'bc/store/selectors';
import DimensionsAndMetricsEditor from 'bc/components/streams/editor/common/DimensionsAndMetricsEditor';

type PropTypes = {
  dimensions: Array<Object>,
  metrics: Array<Object>,
  filteredUnassignedColumns: Array<Object>,
  filterTextInput: String,
  setAuctionStreamDiametricsChange: Function,
  setAuctionStreamClearAllDiametrics: Function,
  removeAuctionStreamDiametrics: Function,
  setStreamDiametricsEditorFilterTextInput: Function,
  isStreamUpdating: Boolean,
};

class GoogleAucDimensionsAndMetricsEditor extends React.PureComponent {
  props: PropTypes;

  render() {
    const {
      metrics,
      dimensions,
      filterTextInput,
      filteredUnassignedColumns,
      removeAuctionStreamDiametrics,
      setStreamDiametricsEditorFilterTextInput,
      setAuctionStreamDiametricsChange,
      setAuctionStreamClearAllDiametrics,
      isStreamUpdating,
    } = this.props;

    return (
      <DimensionsAndMetricsEditor
        dimensions={dimensions}
        metrics={metrics}
        filteredUnassignedColumns={filteredUnassignedColumns}
        filterTextInput={filterTextInput}
        setStreamDiametricsChange={setAuctionStreamDiametricsChange}
        setStreamClearAllDiametrics={setAuctionStreamClearAllDiametrics}
        removeStreamDiametrics={removeAuctionStreamDiametrics}
        setMetricDimentionEditorFilterTextInput={setStreamDiametricsEditorFilterTextInput}
        isStreamUpdating={isStreamUpdating}
        isRemoveDateSection
      />
    );
  }
}

export default connect(
  (state) => ({
    dimensions: selectors.getAuctionsStreamSelectedDimensions(state),
    metrics: selectors.getAuctionsStreamSelectedMetrics(state),
    filteredUnassignedColumns: selectors.getFilteredAuctionsStreamUnAssignedColumns(state),
    filterTextInput: selectors.getStreamMetricsDimentionsFilterTextInput(state),
    isStreamUpdating: selectors.getIsDataStreamUpdating(state),
  }),
  {
    setAuctionStreamDiametricsChange: setAuctionStreamDiametricsChangeAction,
    setAuctionStreamClearAllDiametrics: setAuctionStreamClearAllDiametricsAction,
    removeAuctionStreamDiametrics: removeAuctionStreamDiametricsAction,
    setStreamDiametricsEditorFilterTextInput: setStreamDiametricsEditorFilterTextInputAction,
  },
)(GoogleAucDimensionsAndMetricsEditor);
