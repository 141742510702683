import {makeAsyncAction, makeAction} from 'common/utils/simplifiedAsync';

const ACTION_PREFIX = 'anodot/alertActions/';
const mac = (actionName) => makeAction(actionName, ACTION_PREFIX);
const macAsync = (actionName) => makeAsyncAction(actionName, ACTION_PREFIX);

export const fetchAlertActionsByIds = macAsync('FETCH_ALERT_ACTIONS_BY_IDS');
export const implementActionsToAlertByIds = macAsync('IMPLEMENT_TO_ALERT_ACTIONS_BY_IDS');
export const fetchAllActions = macAsync('FETCH_ALL_ACTIONS');
export const createAction = macAsync('CREATE_ACTION');
export const updateAction = macAsync('UPDATE_ACTION');
export const deleteAction = macAsync('DELETE_ACTION');

export const updateActionsToAlerts = mac('UPDATE_ACTIONS_TO_ALERT');
export const setAsPrimary = mac('SET_AS_PRIMARY');
export const removeActionFromAlert = mac('REMOVE_ACTION_FROM_ALERT');
export const editAlertAction = mac('EDIT_ALERT_ACTION');
export const cleanCreateAction = mac('CLEAN_CREATE_ACTION');
export const populateAction = mac('POPULATE_ACTION');
