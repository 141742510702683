import {createSelector} from 'reselect';

const EMPTY_OBJECT = {};

export const fetchDns = (state) => state.dnsTracker;

export const fetchDnsTrackerData = createSelector(
  fetchDns,
  (i) => i.data,
);

export const fetchDnsTracker = createSelector(
  fetchDnsTrackerData,
  (i) => i.fetchDnsTracker,
);

export const getDnsTrackerData = createSelector(
  fetchDnsTracker,
  (i) => i.data || EMPTY_OBJECT,
);

export const getDnsTrackerIsLoading = createSelector(
  fetchDnsTracker,
  (i) => i.isLoading,
);

export const fetchDnsTrackerView = createSelector(
  fetchDns,
  (i) => i.views,
);

export const fetchDnsTrackerViewPage = createSelector(
  fetchDnsTrackerView,
  (i) => i.dnsTrackerPage,
);

export const getDnsTrackerSearchString = createSelector(
  fetchDnsTrackerViewPage,
  (i) => i.searchString,
);

export const getDnsTrackerFilteredArr = createSelector(
  getDnsTrackerSearchString,
  getDnsTrackerData,
  (str, obj) => {
    const dnsKeyArr = [];

    Object.keys(obj).forEach((p) => {
      if (p.toLowerCase().indexOf(str.toLowerCase()) > -1) dnsKeyArr.push(`${p} : ${obj[p]}`);
    });
    return dnsKeyArr;
  },
);
