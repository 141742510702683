import {createSelector} from 'reselect';
import {selectors as commonSelectors} from 'common';

export const {getEmailDigest} = commonSelectors;

// data
export const getEmailDigestData = createSelector(
  getEmailDigest,
  (emailDigest) => emailDigest.data,
);

export const getLoader = createSelector(
  getEmailDigestData,
  (data) => data.sendMail.isLoading,
);

export const getError = createSelector(
  getEmailDigestData,
  (data) => data.sendMail.error,
);

export const getProccessSuccess = createSelector(
  getEmailDigestData,
  (data) => data.sentMailSuccess,
);

export const getChampions = createSelector(
  getEmailDigestData,
  (data) => data.getChampions,
);

export const getChampionsData = createSelector(
  getChampions,
  (champions) => champions.data || [],
);

export const getIsLoadingChampionsData = createSelector(
  getChampions,
  (champions) => champions.isLoading,
);
