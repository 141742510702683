// @flow

import React from 'react';
import Highlighter from 'react-highlight-words';
import './OptionFunctionWithInfo.module.scss';
import FunctionInformation from './FunctionInformation';

type PropTypes = {
  roleState: String,
  highlightText: String,
  data: Object,
  isSelected: boolean,
  getOptionLabel: Function,
};
export default class OptionFunctionWithInfo extends React.PureComponent {
  props: PropTypes;

  state = {};

  label = (option) => (this.props.getOptionLabel ? this.props.getOptionLabel(option) : option.label);

  render() {
    const isMulti = !!this.props.data.multi;
    if (this.props.data.header) {
      return (
        <div styleName="container header">
          <div styleName="name-and-count">
            <div styleName="label-container">{this.props.data.displayName}</div>
          </div>
        </div>
      );
    }
    return (
      // eslint-disable-next-line prefer-template,no-useless-concat
      <div styleName={'container' + ' ' + this.props.roleState}>
        <div styleName="name-and-count">
          <div styleName="label-container">
            <Highlighter
              autoEscape
              searchWords={[this.props.highlightText]}
              textToHighlight={this.label(this.props.data)}
            />
          </div>
          {isMulti && <span styleName="count">{`(${this.props.data.multi.length})`}</span>}
        </div>
        {this.props.isSelected && <i styleName="isSelected" className="icon icn-general16-checkmark" />}
        {isMulti && <div styleName="chevron" className="icon icn-arrow16-chevronright" />}
        {!isMulti && (
          <div styleName={`info ${this.props.isSelected ? 'withCheckmark' : ''}`}>
            <FunctionInformation data={this.props.data} />
          </div>
        )}
      </div>
    );
  }
}
