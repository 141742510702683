// @flow
import React, {useEffect, useState, useMemo} from 'react';
import {createStatHChart} from 'dashboards/services/dashboardService';
import {COLOR_NAME, COLORS_PICKER} from 'common/componentsV2/ColorPicker';
import {useTheme} from '@material-ui/core';
import {isEmpty} from 'lodash';

type PropsType = {
  id: string,
  isResize: boolean,
  isLoading: boolean,
  isShowGraph: boolean,
  dataPoints: Array,
  thresholds: Array<Object>,
  currentValue: Number,
};

const EMPTY_OBJECT = {};

const AreaSimpleChart = ({id, isResize, isLoading, isShowGraph, dataPoints, thresholds, currentValue}: PropsType) => {
  const theme = useTheme();
  const [hCharts, setHCharts] = useState(null);
  const isHChartsNotEmpty = useMemo(() => !isEmpty(hCharts), [hCharts]);

  useEffect(() => {
    if (!isLoading && isShowGraph && !isHChartsNotEmpty) {
      setHCharts(createStatHChart(id, dataPoints));
    }
  }, [isShowGraph, dataPoints, isHChartsNotEmpty, isLoading]);

  useEffect(() => {
    if (isHChartsNotEmpty && !isShowGraph) {
      setHCharts(hCharts.destroy());
    }
  }, [isHChartsNotEmpty, isShowGraph, hCharts]);

  useEffect(() => {
    if (isHChartsNotEmpty) {
      hCharts.reflow();
    }
  }, [hCharts, isResize, isHChartsNotEmpty]);

  useEffect(() => {
    if (isHChartsNotEmpty) {
      const thresholdValueArray = thresholds.map((t) => t.value);
      const thresholdLength = thresholds.length;
      const baseColor = (thresholds[thresholdLength - 1] || EMPTY_OBJECT).color || COLOR_NAME.BLUE;
      const currentColor = thresholdValueArray.some((v) => v <= currentValue)
        ? (thresholds.find((t) => t.value <= currentValue) || EMPTY_OBJECT).color || baseColor
        : baseColor;
      hCharts.update({
        plotOptions: {
          area: {
            lineColor: theme.palette[COLORS_PICKER[currentColor].color][COLORS_PICKER[currentColor].contrast],
          },
          series: {
            fillColor: {
              stops: [
                [0, `rgba(${COLORS_PICKER[currentColor].rgb}, .25)`],
                [1, `rgba(${COLORS_PICKER[currentColor].rgb}, .05)`],
              ],
            },
          },
        },
      });
    }
  }, [thresholds, hCharts, isHChartsNotEmpty]);

  return <div id={`container-${id}`} className="width_1 height_1" />;
};

export default React.memo(AreaSimpleChart);
