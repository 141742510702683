// @flow
import React from 'react';
import {useField} from 'react-final-form';
import {TinyScrollBox} from 'common/componentsV2/boxTools';
import './EditCustomerForm.module.scss';
import EditCustomerFormCustomerData from './EditCustomerFormCustomerData';
import EditCustomerFormSierra from './EditCustomerFormSierra';
import EditCustomerFormClientSettings from './EditCustomerFormClientSettings';
import EditCustomerFormServerSettings from './EditCustomerFormServerSettings';
import EditCustomerFormClientAdmin from './EditCustomerFormAdmin';

const EditCustomerFormContent = () => {
  const {
    input: {value: isNew},
  } = useField('isNew');
  return (
    <TinyScrollBox width="100%" pr={1.25} height={1} css={{overflowY: 'auto'}}>
      <div styleName="content">
        <EditCustomerFormCustomerData />
        <EditCustomerFormSierra />
        <EditCustomerFormClientSettings />
        <EditCustomerFormServerSettings />
        {isNew && <EditCustomerFormClientAdmin />}
      </div>
    </TinyScrollBox>
  );
};
export default EditCustomerFormContent;
