// @flow
import React from 'react';
import Highlighter from 'react-highlight-words';
import Tooltip, {TYPES} from 'common/componentsV2/Tooltip';
import './CustomersTable.module.scss';

type PropTypes = {
  customer: Object,
  searchString: string,
};

const CustomerRowHeader = ({customer, searchString}: PropTypes) => {
  return (
    <div styleName={['table-row-header', customer.status ? 'customer-disabled' : ''].join(' ')} role="none">
      <div styleName="col-name">
        <div className="ellipsis">
          <Highlighter autoEscape searchWords={[searchString]} textToHighlight={customer.name} />
        </div>
      </div>
      <div styleName="col-id">
        <Tooltip content={customer.id} type={TYPES.SMALL} delay={500}>
          <div className="ellipsis">
            <Highlighter autoEscape searchWords={[searchString]} textToHighlight={customer.id || ''} />
          </div>
        </Tooltip>
      </div>
      <div styleName="col-paying">{customer.paying && <i className="icon icn-general16-checkmark" />}</div>
      <div styleName="col-self-service">
        {customer.settings && customer.settings.sierra && customer.settings.sierra.selfService && (
          <i className="icon icn-general16-checkmark" />
        )}
      </div>
      <div styleName="col-tags">
        <Tooltip content={customer.tags.join()} type={TYPES.SMALL} delay={500}>
          <div className="ellipsis">
            <Highlighter
              autoEscape
              searchWords={[searchString]}
              textToHighlight={customer.tags ? customer.tags.join() : ''}
            />
          </div>
        </Tooltip>
      </div>
      <div styleName="col-status">{customer.status ? 'Disabled' : 'Active'}</div>
    </div>
  );
};

export default CustomerRowHeader;
