import errorHandler from 'common/utils/notifications/errorHandler';

export const groupsErrorCodes = {
  400: {
    title: 'Groups Error',
    settings: {
      // optional
      autoDismiss: 5, // default is 5 sec
      position: 'bl', // default is bottom-left(bl)
    },
  },
  401: {
    title: 'Groups Error',
  },
};

export const userErrorCodes = {
  300: {
    title: 'Users Error',
  },
  301: {
    title: 'Users Error',
  },
};

export const groupErrorHandler = errorHandler(groupsErrorCodes);

export const usersErrorHandler = errorHandler(userErrorCodes);
