import {get, post, put, delete_} from 'common/utils/http';

const root = 'api/v1/';

//* **data stream
export const fetchSchemas = () => get(`${root}stream-schemas/schemas`);
export const fetchCubePreview = ({payload}) =>
  post(`${root}stream-schemas/${payload.schemaId}/cubes/preview`, {topLevelCubes: payload.topLevelCubes});
export const createCube = ({payload}) =>
  post(`${root}stream-schemas/${payload.schemaId}/cubes/definitions`, {topLevelCubes: payload.topLevelCubes});
export const updateCubes = ({payload}) =>
  put(`${root}stream-schemas/${payload.schemaId}/cubes/definitions`, {topLevelCubes: payload.topLevelCubes});
export const deleteAllCubes = ({payload}) => delete_(`${root}stream-schemas/${payload.schemaId}/cubes`);
export const fetchCubesCount = ({payload}) =>
  post(`${root}stream-schemas/cubes/count/${payload.schemaId}`, payload.topLevelCubes);
