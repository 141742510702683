// @flow
import React, {Fragment} from 'react';
import Checkbox from 'common/componentsV2/Checkbox';
import './StyleGuideComponents.module.scss';

export default class CheckboxSection extends React.PureComponent {
  state = {
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  };

  onChangeCheckbox1 = () => {};

  onChangeCheckbox2 = (res) => {
    const newState = {};
    newState[res.eventKey] = res.value;
    this.setState(newState);
  };

  onChangeCheckbox3 = (checked) => {
    this.setState({
      checkbox3: checked,
    });
  };

  render() {
    return (
      <Fragment>
        <h4>Checkboxs</h4>
        <div styleName="presentation">
          <div styleName="item">
            <h5>Only Checkbox</h5>
            <Checkbox isChecked={false} onChange={this.onChangeCheckbox1} />
            <Checkbox isChecked onChange={this.onChangeCheckbox1} />
          </div>
          <div styleName="item">
            <h5>With Label</h5>
            <Checkbox
              isChecked={this.state.checkbox2}
              eventKey="checkbox2"
              text="Monday"
              onChange={this.onChangeCheckbox2}
            />
          </div>
          <div styleName="item">
            <h5>Label Diff Color</h5>
            <Checkbox
              isChecked={this.state.checkbox3}
              styleName="chk-violet"
              text="Wednesday"
              highlightText="esd"
              onChange={this.onChangeCheckbox3}
            />
          </div>
          <div styleName="item">
            <h5>Disabled</h5>
            <Checkbox
              isChecked={this.state.checkbox1}
              styleName="chk-violet"
              text="Wednesday"
              isDisabled
              onChange={this.onChangeCheckbox1}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}
