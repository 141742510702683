import React from 'react';
import KinesisDimensionsAndMetrics from './kinesisDimensionsAndMetrics/KinesisDimensionsAndMetrics';
import KinesisSchedular from './kinesisSchedular/KinesisSchedular';
import KinesisContext from './KinesisContext';
import KinesisAdvanceFiltering from './KinesisAdvanceFiltering';
import StreamEditorSteps from '../../StreamEditorSteps';
import StreamProperties from '../../StreamProperties';
import './KinesisStreamEditor.module.scss';
import StreamEditorPanel from '../../StreamEditorPanel';

const KinesisStreamEditor = () => (
  <div className="shell-col">
    <StreamEditorSteps>
      <div styleName="left-container">
        <StreamProperties />
        <KinesisContext />
        <KinesisSchedular />
      </div>
      <div styleName="middle-container">
        <KinesisAdvanceFiltering />
      </div>
      <KinesisDimensionsAndMetrics />
    </StreamEditorSteps>
    <StreamEditorPanel />
  </div>
);

export default KinesisStreamEditor;
