import {composeReducers} from 'common/utils/reducers';

const validationMeta = composeReducers(
  (
    state = {
      propNameLength: 50,
      propMaxColumnNumber: 30,
      tagMaxColumnNumber: 20,
    },
    {type},
  ) => {
    switch (type) {
      default:
        return state;
    }
  },
);

export default validationMeta;
