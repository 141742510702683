// @flow
import React from 'react';
import {fileDatePatterns} from 'bc/services/genericStorageService';
import SelectAndt, {THEME_LIGHT, TYPE_NO_SEARCH} from 'common/componentsV2/ddl/selectAndt/SelectAndt';

type PropTypes = {
  onChange: Function,
  value: String,
};

export default class GoogleStorageFileNamePattern extends React.PureComponent {
  props: PropTypes;

  onMenuItemClick = (item) => {
    this.props.onChange(item.value);
  };

  render() {
    const {value} = this.props;
    const options = Object.keys(fileDatePatterns).map((patternKey) => ({value: patternKey, label: patternKey}));
    const optionCurrent = options.findIndex((option) => option.value === value);
    return (
      <SelectAndt
        id="timestampDatePatternPicker"
        automationId="timestampDatePatternPicker"
        options={options}
        type={TYPE_NO_SEARCH}
        theme={THEME_LIGHT}
        value={options[optionCurrent]}
        onChange={this.onMenuItemClick}
        placeholder="Filename Date Pattern"
      />
    );
  }
}
