import React, {useCallback, useState} from 'react';
import {useDispatch} from 'react-redux';
import Collapse from '@material-ui/core/Collapse';
import {Box} from '@material-ui/core';
import {TypographyBox} from 'common/componentsV2/boxTools';
import {segmentClickEvent} from '../store/actions';

type PropTypes = {
  labelClosed: string,
  labelOpened: string,
  children: Object,
  byPass: boolean,
  automationId: string,
};

const ToggleButton = ({children, labelClosed, labelOpened, byPass, automationId}: PropTypes) => {
  const dispatch = useDispatch();
  const [isOpened, toggle] = useState(false);

  const handleClick = useCallback(() => {
    dispatch(segmentClickEvent({type: 'click', name: isOpened ? labelOpened : labelClosed}));
    toggle(!isOpened);
  }, [isOpened, labelOpened, labelClosed, toggle]);
  return (
    <div automation-id={`${automationId}Container`}>
      {!byPass && (
        <TypographyBox
          variant="h5"
          height={30}
          border="solid 2px"
          borderColor="gray.200"
          borderRadius={6}
          css={{cursor: 'pointer'}}
          display="flex"
          alignItems="center"
          justifyContent="center"
          bgcolor={!isOpened ? 'gray.100' : ''}
          onClick={handleClick}
          automation-id={`${automationId}Button`}
        >
          {isOpened ? labelOpened : labelClosed}
        </TypographyBox>
      )}
      <Collapse in={isOpened || byPass}>
        <Box mt={2} automation-id={`${automationId}Children`}>
          {children}
        </Box>
      </Collapse>
    </div>
  );
};

export default ToggleButton;
