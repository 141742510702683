import React, {useMemo, useCallback, useEffect} from 'react';
import {get, keyBy, isEqual} from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {useField} from 'react-final-form';
import {useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core';
import {getTriggeredAlerts} from 'dashboards/store/actions';
import {getTriggeredAlerts as getTriggeredAlertsSelector} from 'dashboards/store/selectors';
import SelectAndt, {
  DIRECTION_RIGHT,
  THEME_TRANSPARENT,
  TYPE_SIMPLE,
} from 'common/componentsV2/ddl/selectAndt/SelectAndt';
import OptionWithIcon from 'common/componentsV2/ddl/selectAndt/innerComponents/OptionWithIcon';
import Tooltip from 'common/componentsV2/Tooltip';
import * as dateRangeService from 'common/utils/dateRangeService';

const alertOptions = [
  {
    value: 'NO_TRIGGERS',
    label: 'No Alert Triggers',
  },
  {
    value: 'EDIT',
    label: 'Edit alert',
  },
];

const alertOptionsTriggered = [
  {
    value: 'SEE',
    label: 'See Alert Triggers',
  },
  {
    value: 'EDIT',
    label: 'Edit Alert',
  },
];

const useStyles = makeStyles(({palette}) => ({
  alertBell: {
    fontSize: 18,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    marginRight: 8,
    color: ({total}) => (total > 0 ? palette.blue[500] : palette.gray[350]),
  },
  dropdownIndicator: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const AlertBellContainer = ({dashboardData, tileId}: {dashboardData: Object, tileId: string}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const triggeredAlertsResponse = useSelector(getTriggeredAlertsSelector);
  const {
    input: {value: dateRange},
  } = useField('dateRange', {isEqual});

  const total = get(triggeredAlertsResponse, 'data.total', 0);
  const classes = useStyles({total});

  const alertMap = useMemo(() => keyBy(dashboardData.tileAlerts, 'tileId'), [dashboardData]);
  const {alertId} = alertMap[tileId] || {};

  useEffect(() => {
    if (alertId) {
      dispatch(getTriggeredAlerts({alertConfigurationIds: alertId, ...dateRange}));
    }
  }, [alertId, dateRange]);

  const dateRangeKey = useMemo(
    () =>
      get(Object.values(dateRangeService.rangeTypes).find((item) => item.value === dateRange.constRange), 'key', 'c'),
    [dateRange],
  );

  const onAlertAction = useCallback(
    (option) => {
      if (option.value === 'EDIT') {
        history.push(`/alert-manager/edit/${alertId}/settings?backUrl=${history.location.pathname}`);
      }
      if (option.value === 'SEE') {
        history.push(
          `/alerts-console?ref=email&alertConfigurationIds=${alertId}&size=100&index=0&startTime=${parseInt(
            dateRange.startDate / 1000,
            10,
          )}&endTime=${parseInt(dateRange.endDate / 1000, 10)}&constRange=${dateRangeKey}${
            dateRange.constRange === 'r'
              ? `&relativeLast=${dateRange.relativeLast || ''}&relativeNext=${dateRange.relativeNext || ''}`
              : ''
          }`,
        );
      }
    },
    [history, alertId, dateRange],
  );

  const dateRangeLabel = useMemo(() => {
    const {relativeLast, relativeNext, startDate, endDate} = dateRange;
    if (dateRange.constRange === 'r') {
      return dateRangeService.createRelativeLabel(relativeLast, relativeNext);
    }
    if (dateRange.constRange === 'c') {
      return dateRangeService.createCustomLabel(startDate, endDate);
    }

    return dateRangeService.rangeTypes[dateRangeKey].text;
  }, [dateRange]);

  return alertMap[tileId] ? (
    <div className={classes.alertBell}>
      <SelectAndt
        automationId="dashboardTileEdit"
        type={TYPE_SIMPLE}
        theme={THEME_TRANSPARENT}
        direction={DIRECTION_RIGHT}
        options={total > 0 ? alertOptionsTriggered : alertOptions}
        onChange={onAlertAction}
        optionHeight={40}
        menuWidth={150}
        customComponent={{
          Option: OptionWithIcon,
          DropdownIndicator: (p) => (
            <div className={classes.dropdownIndicator}>
              <Tooltip placement="top" content={`${total} Triggers / ${dateRangeLabel}`}>
                <i {...p.innerProps} className="icon icn-nav24-alertconsole" />
              </Tooltip>
            </div>
          ),
        }}
        closeMenuOnSelect={false}
        appendToBody={false}
      />
    </div>
  ) : null;
};

export default AlertBellContainer;
