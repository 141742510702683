// @flow
import React, {useMemo, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {get, isEqual, omit} from 'lodash';
import {OPTION_TILES_VALUES, OPTIONS_FILTER_BUTTON} from 'dashboards/services/dashboardService';
import OptionComponentSimple from 'common/componentsV2/ddl/multiSelectFormDdl/OptionComponentSimple';
import FormDdlSelect from 'common/componentsV2/ddl/multiSelectFormDdl/FormDdlSelect';
import {makeStyles} from '@material-ui/core';
import {rangeTypes, createRelativeLabel, createCustomLabel} from 'common/utils/dateRangeService';
import {displayDashboardSnackBar, updateDashboardUserSettings} from 'dashboards/store/actions';
import {getProfileId} from 'profile/store/selectors';
import {getDashboardUserSettings, getUpdateDashboardUserSettings} from 'dashboards/store/selectors';
import useAsyncAction from 'common/utils/useAsyncAction';
import Tooltip from 'common/componentsV2/Tooltip';
import {getTimeScale} from 'dashboards/hooks/useDashboardSync';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

type PropTypes = {
  position: string,
  isFullSize: boolean,
  onSave: Function,
  dashboardData: Object,
  formValues: Object,
  commonValues: Object,
  tileId: string,
  initializeTile: Function,
};

const useStyles = makeStyles(({palette}) => ({
  button: {
    fontSize: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    color: palette.blue[500],
  },
  fullSize: {
    backgroundColor: palette.gray[300],
    height: 35,
    color: ({counter}) => (+counter ? palette.blue[500] : palette.gray[500]),
    padding: 10,
  },
  tooltipWrapper: {
    '@global': {
      '.tooltip-arrow': {
        display: 'none !important',
      },
      '.tooltip-inner': {
        backgroundColor: `${palette.white[500]} !important`,
        boxShadow: 'none',
        padding: '0 !important',
        textAlign: 'start',
        color: palette.gray[500],
        fontWeight: 500,
        border: 'none',
      },
    },
  },
  propsListKey: {
    width: 80,
    display: '-webkit-box',
    '-webkit-line-clamp': 3,
    '-webkit-box-orient': 'vertical',
    flexShrink: 0,
    marginRight: 8,
    color: palette.gray[400],
    lineHeight: '16px',
    overflow: 'hidden',
  },
  propsListValue: {
    display: 'flex',
    flexWrap: 'wrap',
    minWidth: 56,
    maxWidth: 150,
    lineHeight: '16px',
    color: palette.gray[500],
    wordBreak: 'break-all',
    fontWeight: 500,
  },
  tooltipBox: {
    backgroundColor: palette.white[500],
    fontSize: 14,
    boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.2)',
    maxWidth: 230,
    borderRadius: 8,
    padding: 8,
  },
  timeRow: {
    marginBottom: 16,
    fontWeight: 500,
    color: palette.gray[500],
  },
}));

const compareFilter = (arr1, arr2) => {
  const diff = {};
  if (arr2 && arr1) {
    arr1.forEach((filter, index) => {
      if (arr2[index] && !isEqual(filter.map((item) => item.value), arr2[index].map((item) => item.value))) {
        diff[filter[0].key] = arr2[index].map((item) => item.value).join(', ');
      }
    });
  }
  return diff;
};

const compareDateRange = (obj1, obj2) => {
  if (obj1 && obj2) {
    const constRangeGlobal = obj1.constRange;
    const constRangeForm = obj2.constRange;
    const isPredefinedRange =
      (constRangeGlobal !== rangeTypes.c.value || constRangeGlobal !== rangeTypes.r.value) &&
      (constRangeForm !== rangeTypes.c.value || constRangeForm !== rangeTypes.r.value);
    if (isPredefinedRange) {
      return constRangeGlobal !== constRangeForm;
    }

    if (constRangeGlobal === rangeTypes.c.value && constRangeForm === rangeTypes.c.value) {
      return obj1.startDate !== obj2.startDate && obj1.endDate !== obj2.endDate;
    }

    if (constRangeGlobal === rangeTypes.r.value && constRangeForm === rangeTypes.r.value) {
      return obj1.relativeLast !== obj2.relativeLast && obj1.relativeNext !== obj2.relativeNext;
    }

    return !isEqual(obj1, obj2);
  }

  return false;
};

const FilterDDL = ({
  position,
  onSave,
  isFullSize,
  formValues,
  commonValues,
  dashboardData,
  tileId,
  initializeTile,
}: PropTypes) => {
  const [tileReset, setTileReset] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const userId = useSelector(getProfileId);
  const {data: dashboardUserSettingsData} = useSelector(getDashboardUserSettings);
  const updateDashboardUserSettingSelector = useSelector(getUpdateDashboardUserSettings);
  const actionUpdateDashboardUserSettings = useAsyncAction(
    updateDashboardUserSettings,
    updateDashboardUserSettingSelector,
  );
  const dashboardId = dashboardData._id;

  const filtersDiff = compareFilter(commonValues.filters, get(formValues, 'filters', EMPTY_ARRAY));
  const timeScaleDiff = commonValues.timeScale !== formValues.timeScale ? 1 : 0;
  const dateRangeDiff = compareDateRange(commonValues.dateRange, formValues.dateRange) ? 1 : 0;
  const counter = Object.keys(filtersDiff).length + timeScaleDiff + dateRangeDiff;

  const classes = useStyles({counter});

  const computedOptions = useMemo(
    () =>
      isFullSize
        ? OPTIONS_FILTER_BUTTON.filter((o) => o.value !== OPTION_TILES_VALUES.EDIT_FILTERS)
        : OPTIONS_FILTER_BUTTON.filter((o) => o.value !== OPTION_TILES_VALUES.SAVE_FILTERS),
    [isFullSize],
  );

  const buttonComponent = useMemo(
    () => (
      <div className={`${classes.button} ${isFullSize ? classes.fullSize : ''}`}>
        <i className="icon icn-general16-filter" />
        {counter ? <div className="ml_0-5">{counter}</div> : ''}
      </div>
    ),
    [isFullSize, counter],
  );

  useEffect(() => {
    if (tileReset && isFullSize && initializeTile) {
      initializeTile(true);
    }
  }, [tileReset]);

  const handleSelect = ({value}) => {
    if (value === OPTION_TILES_VALUES.RESTORE_FILTERS) {
      actionUpdateDashboardUserSettings({
        dashboardId,
        userId,
        ...dashboardUserSettingsData,
        tiles: omit(dashboardUserSettingsData.tiles, tileId),
      }).then(() => {
        setTileReset(tileReset + 1);
        dispatch(
          displayDashboardSnackBar({
            title: 'The tile has been restored',
            description:
              'The filters in this tile have been removed and this tile will use the default dashboard settings again.',
          }),
        );
      });
    }
    if (value === OPTION_TILES_VALUES.SAVE_FILTERS) {
      onSave();
      dispatch(
        displayDashboardSnackBar({
          title: 'Filters have been saved',
          description: 'This tile will always appear with the filters you applied',
        }),
      );
    }

    if (value === OPTION_TILES_VALUES.EDIT_FILTERS) {
      history.push(`/dashboards/${dashboardId}/${tileId}/${OPTION_TILES_VALUES.FULL_SIZE}`);
    }
  };

  const computedTimeFrame = () => {
    const {dateRange} = formValues;
    if (dateRangeDiff) {
      if (dateRange.constRange === rangeTypes.r.value) {
        return {timeFrame: createRelativeLabel(dateRange.relativeLast, dateRange.relativeNext)};
      }
      if (dateRange.constRange === rangeTypes.c.value) {
        return {timeFrame: createCustomLabel(dateRange.startDate, dateRange.endDate)};
      }

      return {
        timeFrame:
          // eslint-disable-next-line max-len
          (
            Object.values(rangeTypes).find((o) => o.value === dateRange.constRange || o.key === dateRange.constRange) ||
            EMPTY_OBJECT
          ).text || EMPTY_OBJECT,
      };
    }

    return EMPTY_OBJECT;
  };

  const tooltip = (
    <div className={classes.tooltipBox}>
      {(!!timeScaleDiff || !!dateRangeDiff) && (
        <div className={classes.timeRow}>
          {`${computedTimeFrame().timeFrame}/${getTimeScale(formValues.timeScale).text}`}
        </div>
      )}
      {Object.entries(filtersDiff).map(([key, value]) => (
        <div className="display_flex" key={key}>
          <div className={classes.propsListKey}>{key}</div>
          <div className={classes.propsListValue}>{value}</div>
        </div>
      ))}
    </div>
  );

  const renderBody = (
    <FormDdlSelect
      options={computedOptions}
      button={buttonComponent}
      optionComponent={<OptionComponentSimple />}
      onChange={handleSelect}
      width={220}
      position={position || 'left'}
      automationId="filterDashboardMenu"
    />
  );

  if (isFullSize) {
    return <React.Fragment>{renderBody}</React.Fragment>;
  }

  if (counter && !isFullSize) {
    return (
      <Tooltip content={tooltip} extraTtClass={classes.tooltipWrapper}>
        <div>{renderBody}</div>
      </Tooltip>
    );
  }

  return null;
};

export default React.memo(FilterDDL);
